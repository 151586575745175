import React, { forwardRef, useContext, useRef, useEffect } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, rgbaColor } from 'helpers/utils';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);
import { pieChartContext } from 'context/Context'


const ChartCode = function ChartOptions({ chartRef }) {
  const { location: loc } = useContext(pieChartContext)

  console.log("loc==>")

  // const chartRef = useRef(null)

  const getOption = () => ({

    legend: {
      left: 'left',
      textStyle: {
        color: getColor('600')
      }
    },
    series: [
      {
        type: 'pie',
        radius: window.innerWidth < 530 ? '45%' : '60%',
        label: {
          color: getColor('700')
        },
        center: ['50%', '55%'],
        data: Array.isArray(loc) ? loc.map(item => ({
          value: item.data,
          name: item.location,
          itemStyle: {
            color: item.get_color || getColor('primary')
          }
        })) : [],
        // data: [
        //   {
        //     value: 1048,
        //     name: 'Bar',
        //     itemStyle: {
        //       color: getColor('primary')
        //     }
        //   },
        //   {
        //     value: 735,
        //     name: 'Tennis Court',
        //     itemStyle: {
        //       color: getColor('danger')
        //     }
        //   },
        //   {
        //     value: 580,
        //     name: 'Pink House',
        //     itemStyle: {
        //       color: getColor('info')
        //     }
        //   },
        //   {
        //     value: 484,
        //     name: 'Garden Area',
        //     itemStyle: {
        //       color: getColor('success')
        //     }
        //   },
        //   {
        //     value: 300,
        //     name: 'Swimming Pool Area',
        //     itemStyle: {
        //       color: getColor('warning')
        //     }
        //   }
        // ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: rgbaColor(getColor('600'), 0.5)
          }
        }
      }
    ],
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    }
  });

  //------- Responsive on window resize -------

  const updateDimensions = () => {
    if (window.innerWidth < 530) {
      chartRef.current.getEchartsInstance().setOption({
        series: [{ radius: '45%' }]
      });
    }
    else
      chartRef.current.getEchartsInstance().setOption({
        series: [{ radius: '60%' }]
      });
  }

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption()}
      ref={chartRef}
      style={{ height: '20rem' }}
    />
  );
}
  ;

const PieBasicChart = ({ chartRef }) => {
  return (

    <FalconComponentCard dir="ltr" className="h-100">
      {/* <FalconComponentCard.Header title="Pie chart" light={false} /> */}
      <FalconComponentCard.Body
        ref={chartRef}
        // code={ChartCode}
        children={<ChartCode chartRef={chartRef} />}
        language="jsx"
        scope={{
          ReactEChartsCore,
          echarts,
          getColor,
          rgbaColor,
        }}

      />

    </FalconComponentCard>
  );
};

export default PieBasicChart;
