import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addAppointment } from 'services/appointmentService';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import SearchableDropdown from 'components/common/SearchableDropdown';
import pattern from 'components/configuration/form-pattern';
import PhoneNumber from 'components/common/PhoneNumber';
import CountryCodes, { defaultCountryCode } from 'components/configuration/country-code';
import ReactDatePicker from 'components/common/ReactDatePicker';
import ReactTimePicker from 'components/common/ReactTimePicker';
import SetImagePlaceholderUploder from 'components/doc-components/SetImagePlaceholderUploder';
import moment from 'moment';

import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import CountryCode from 'components/common/CountryCode';
import formPattern from 'components/configuration/form-pattern';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { appointmentadd, appointmentidentilist, getStaffList } from 'services/AppointmenttService'
import { loaderContext } from 'context/Context';
import { find } from 'lodash';




export default function CreateAppointment() {
  const [documentType, setDocumentType] = useState([]);
  const [hostCountryCode, setHostCountryCode] = useState(defaultCountryCode);
  const [staffList, setStaffList] = useState([])
  const [guestCountryCode, setGuestCountryCode] = useState(defaultCountryCode);
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const location = useLocation()
  const [countryCode, setCountryCode] = useState({});
  const [staffNameDiasable, setStaffDisable] = useState(false)
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    clearErrors,
    watch,
    formState: { errors }
  } = useForm();
  const startTime = watch('startTime')
  const staffName = watch("staff_name")
  console.log("locationnnn==", location)

  useEffect(async () => {
    checkModulePermission("Appointment")
    setIsLoading(true)

    try
    {
      const userId = localStorage.getItem("userId")
      const userType = localStorage.getItem("userType")
      console.log("user Id", userId)
      const { data: docType } = await appointmentidentilist();
      const { data } = await getStaffList();
      setStaffList(data?.data)
      if (userType == "2" || userType == "3")
      {
        const staffUser = data?.data?.find((staff) => staff.id == userId)
        console.log("Staff user", staffUser)
        setStaffDisable(true)
        setValue("staff_name", { label: staffUser?.name, value: staffUser?.id })
      }
      setDocumentType(
        docType?.data?.map(data => ({
          value: data.id,
          label: data.name
        }))
      );
    } catch (e)
    {
      errorMessage(e)
    }
    setIsLoading(false)

  }, []);
  useEffect(() => {
    if (staffName)
    {
      console.log("Staff Name================>", staffName)
      const staffData = staffList.find((staff) => staff.id === staffName.value)
      console.log()
      console.log("CountryCode", CountryCodes.find((country) => country.dial_code === staffData?.country_code))
      setGuestCountryCode(CountryCodes.find((country) => country.dial_code === staffData?.country_code))
      setValue("staff_mob_number", staffData?.phone)
      clearErrors("staff_mob_number")
    } else
    {
      setValue("staff_mob_number", "")
      setGuestCountryCode(defaultCountryCode)
    }

  }, [staffName, staffList])
  const onSubmit = async (appointmentData) => {
    setIsLoading(true)
    try
    {
      appointmentData.staff_id = appointmentData?.staff_name?.value
      appointmentData.staff_name = appointmentData?.staff_name?.label
      appointmentData.staff_country_code = guestCountryCode?.dial_code
      appointmentData.visitor_country_code = hostCountryCode?.dial_code
      appointmentData.doc_type_id = appointmentData?.doc_type_id?.value
      appointmentData.visitor_fname = `${appointmentData.visitor_fname} ${appointmentData.visitor_lname}`
      appointmentData.date_of_appointment = moment(appointmentData.date_of_appointment).format(process.env.REACT_APP_DATE_FORMAT)
      appointmentData.start_time = moment(appointmentData.start_time).format(process.env.REACT_APP_TIME_FORMAT_24)

      const { data } = await appointmentadd(appointmentData);
      toast.success(data.message, toastConfig);
      navigate('/appointment');
      console.log('appo', appointmentData)
    } catch (e)
    {
      if (e.response.data)
      {
        if (Array.isArray(e.response.data.message))
        {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else
        {
          toast.error(e.response.data.message, toastConfig)
        }
      } else
      {
        toast.error(e.message, toastConfig)
      }
    }
    setIsLoading(false)

  };



  const dateOfAppointment = watch('date_of_appointment');

  useEffect(() => {
    if (dateOfAppointment)
    {
      const currentTime = moment();
      const roundedTime = moment(Math.ceil(currentTime / (15 * 60 * 1000)) * (15 * 60 * 1000));
      setValue("start_time", roundedTime.toDate());
    }
  }, [dateOfAppointment]);

  // useEffect(() => {
  //   if (dateOfAppointment) {
  //     const currentDate = moment();
  //     const selectedDate = moment(dateOfAppointment);

  //     if (selectedDate.isSame(currentDate, 'day')) {
  //       const roundedTime = moment(Math.ceil(currentDate / (15 * 60 * 1000)) * (15 * 60 * 1000));
  //       setValue("start_time", roundedTime.toDate());
  //     } else {
  //       setValue("start_time", null);
  //     }
  //   }
  // }, [dateOfAppointment]);


  const onReset = () => {
    reset();
    setGuestCountryCode(defaultCountryCode)
    setHostCountryCode(defaultCountryCode)
    setValue("staff_name", "")
    setValue("doc_type_id", "")
  };
  const onStartTimeChange = (e) => {
    setValue('start_time', e);
    clearErrors('start_time')
  }
  return (
    <>
      <div className="row g-3 mb-3">
        <div className="">
          <div className="card overflow-hidden mb-3">
            <div className="card-header">
              <div className="row">
                <div className="col-8">
                  <h3 className="heading-color fw-600 mb-0">
                    {t('appointment.heading.addHeading')}
                  </h3>
                </div>
                <div className="col-4 table-btn">
                  <button
                    className="btn btn-primary btn-sm float-end"
                    onClick={() => {
                      navigate('/appointment');
                    }}
                  >
                    <FontAwesomeIcon icon="chevron-left" />
                    <span className="ms-1 d-sm-inline">
                      {t('appointment.btnLabel.back')}
                    </span>
                  </button>
                </div>
                <div className="col-12">
                  <hr />
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.onBehalfOf')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        name='staff_name'
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <SearchableDropdown
                              value={value}
                              onChange={onChange}
                              options={staffList.map((staff) => ({ label: staff?.name, value: staff?.id }))}
                              placeholder={t(
                                'appointment.placeholder.staffname'
                              )}
                              formFieldsDisabled={staffNameDiasable}
                              noOptionsMessage={staffList?.length > 0 ? () => t('appointment.error.dropdownError') : () => t('appointment.error.addStaffName')}
                              isClearable={true}
                            />
                          )
                        }}
                      />

                      {errors.staff_name && (
                        <small className="text-danger">
                          {errors.staff_name.type == 'required' && (
                            <span>{t('appointment.error.staffname')}</span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.hostMobileNo')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        name="employeeContactNo"
                        control={control}
                        rules={{ required: true, pattern: pattern.numberOnly }}
                        render={({ field: { value, onChange } }) => (
                          <PhoneNumber
                            dropdownDirection="bottom"
                            value={value}
                            onChange={onChange}
                            countryCode={hostCountryCode}
                            setCountryCode={setHostCountryCode}
                            error={errors.employeeContactNo}
                            isInputVisible={true}
                          />
                        )}
                      />
                      {errors.employeeContactNo && (
                        <small className="text-danger">
                          {errors.employeeContactNo.type === 'required' && (
                            <span>
                              {t('appointment.error.hostMobileNoError')}
                            </span>
                          )}
                          {errors.employeeContactNo.type === 'pattern' && (
                            <span>
                              {t('appointment.error.hostMobileNoPatternError')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div> */}

                  {/* <div className="col-md-4 row pe-0 mb-3">
                    <div className='form-group pe-0 col-md-6'>
                      <label className="form-label">
                        {t('appointment.formLabel.countrycode')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        name="countryCode"
                        control={control}
                        rules={{ required: true, pattern: pattern.numberOnly }}
                        render={({ field: { value, onChange } }) => (
                          <CountryCode
                            countryCode={countryCode}
                            setCountryCode={setCountryCode}
                            direction="bottom"
                            error={errors.countryCode}
                          />
                        )}
                      />
                      {errors.countryCode && (
                        <small>
                          {errors.countryCode.type === 'required' && (
                            <span className="text-danger">
                              {t('appointment.error.countrycode')}
                            </span>
                          )}
                          {errors.countryCode.type === 'pattern' && (
                            <span className="text-danger">
                              {t('appointment.error.countrycode')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                    <div className='form-group pe-0 col-md-6'>
                      <label className="form-label">
                        {t('appointment.formLabel.staffmobileno')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <input
                        type='text'
                        className={`form-control ${errors.visitorMobileNo && 'is-invalid'
                          }`}
                        placeholder={t('appointment.placeholder.visitorMobileNo')}
                        {...register('visitorMobileNo', { required: true, pattern: formPattern.phoneNumber })}
                      />
                      {errors.visitorMobileNo && (
                        <small>
                          {errors.visitorMobileNo.type === 'required' && (
                            <span className="text-danger">
                              {t('appointment.error.staffmbno')}
                            </span>
                          )}
                          {errors.visitorMobileNo.type === 'pattern' && (
                            <span className="text-danger">
                              {t('appointment.error.staffmbnoinvalid')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div> */}

                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.staffmobileno')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        name="staff_mob_number"
                        control={control}
                        rules={{ required: true, pattern: pattern.numberOnly }}
                        render={({ field: { value, onChange } }) => (
                          <PhoneNumber
                            dropdownDirection="bottom"
                            value={value}
                            onChange={onChange}
                            countryCode={guestCountryCode}
                            setCountryCode={setGuestCountryCode}
                            error={errors.staff_mob_number}
                            isInputVisible={true}
                            flagvisibleadmin
                            isDisable={true}
                          />
                        )}
                      />
                      {errors.staff_mob_number && (
                        <small className="text-danger">
                          {errors.staff_mob_number.type === 'required' && (
                            <span>
                              {t('appointment.error.staffMobileNoError')}
                            </span>
                          )}
                          {errors.staff_mob_number.type === 'pattern' && (
                            <span>
                              {t('appointment.error.staffMobileNoPatternError')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>


                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.guestMobileNo')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        name="visitor_mob_number"
                        control={control}
                        rules={{ required: true, pattern: pattern.numberOnly }}
                        render={({ field: { value, onChange } }) => (
                          <PhoneNumber
                            dropdownDirection="bottom"
                            value={value}
                            onChange={onChange}
                            countryCode={hostCountryCode}
                            setCountryCode={setHostCountryCode}
                            error={errors.visitor_mob_number}
                            isInputVisible={true}
                            flagvisibleadmin
                          />
                        )}
                      />
                      {errors.visitor_mob_number && (
                        <small className="text-danger">
                          {errors.visitor_mob_number.type === 'required' && (
                            <span>
                              {t('appointment.error.visitorMobileNoError')}
                            </span>
                          )}
                          {errors.visitor_mob_number.type === 'pattern' && (
                            <span>
                              {t('appointment.error.visitorMobileNoPatternError')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.firstName')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.visitor_fname ? 'is-invalid' : ''
                          }`}
                        placeholder={t('appointment.placeholder.firstName')}
                        {...register('visitor_fname', {
                          required: true,
                          pattern: pattern.notAllowSpaceInStart
                        })}
                      />
                      {errors.visitor_fname && (
                        <small className="text-danger">
                          {errors.visitor_fname.type == 'required' && (
                            <span>{t('appointment.error.firstNameError')}</span>
                          )}
                          {errors.visitor_fname.type == 'pattern' && (
                            <span>{t('appointment.error.firstNameInvalidError')}</span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.lastName')}
                        {/* <span className="text-danger ms-1">*</span> */}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.lastName ? 'is-invalid' : ''
                          }`}
                        placeholder={t('appointment.placeholder.lastName')}
                        {...register('visitor_lname', {
                          // required: true
                        })}
                      />
                      {/* {errors.lastName && (
                        <small className="text-danger">
                          {errors.lastName.type == 'required' && (
                            <span>{t('appointment.error.lastNameError')}</span>
                          )}
                        </small>
                      )} */}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.email')}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.visitor_email ? 'is-invalid' : ''
                          }`}
                        placeholder={t('appointment.placeholder.email')}
                        {...register('visitor_email', {

                          pattern: pattern.email
                        })}
                      />
                      {errors.visitor_email && (
                        <small className="text-danger">
                          {errors.visitor_email.type === 'required' && (
                            <span>{t('appointment.error.emailError')}</span>
                          )}
                          {errors.visitor_email.type === 'pattern' && (
                            <span>
                              {t('appointment.error.emailPatternError')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.companyFrom')}
                        {/* <span className="text-danger ms-1">*</span> */}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.visitor_from ? 'is-invalid' : ''
                          }`}
                        placeholder={t('appointment.placeholder.companyFrom')}
                        {...register('visitor_from', {
                          // required: true
                        })}
                      />
                      {/* {errors.companyFrom && (
                        <small className="text-danger">
                          {errors.companyFrom.type == 'required' && (
                            <span>
                              {t('appointment.error.companyFromError')}
                            </span>
                          )}
                        </small>
                      )} */}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.identityType')}
                        {/* <span className="text-danger ms-1">*</span> */}
                      </label>
                      <Controller
                        control={control}
                        // rules={{ required: true }}
                        name="doc_type_id"
                        render={({ field: { value, onChange } }) => (
                          <SearchableDropdown
                            options={documentType}
                            isClearable={false}
                            onChange={onChange}
                            // hasError={errors.doc_type_id}
                            placeholder={t(
                              'appointment.placeholder.identityType'
                            )}
                            value={value}
                            noOptionsMessage={() =>
                              t('appointment.error.dropdownError')
                            }
                          />
                        )}
                      />
                      {/* {errors.doc_type_id && (
                        <small className="text-danger">
                          {errors.doc_type_id.type == 'required' && (
                            <span>
                              {t('appointment.error.identityTypeError')}
                            </span>
                          )}
                        </small>
                      )} */}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.identificationNo')}
                        {/* <span className="text-danger ms-1">*</span> */}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.documentNo ? 'is-invalid' : ''
                          }`}
                        placeholder={t(
                          'appointment.placeholder.identificationNo'
                        )}
                        {...register('doc_id_number', {
                          // required: true
                        })}
                      />
                      {/* {errors.documentNo && (
                        <small className="text-danger">
                          {errors.documentNo.type == 'required' && (
                            <span>
                              {t('appointment.error.identificationNoError')}
                            </span>
                          )}
                        </small>
                      )} */}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.dateOfAppointment')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="date_of_appointment"
                        render={({ field: { value, onChange } }) => (
                          <ReactDatePicker
                            value={value}
                            selected={value}
                            onChange={onChange}
                            hasErrors={errors.date_of_appointment}
                            placeholder={t(
                              'appointment.placeholder.dateOfAppointment'
                            )}
                            minDate={moment().toDate()}
                            dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                          />
                        )}
                      />
                      {errors.date_of_appointment && (
                        <small className="text-danger">
                          {errors.date_of_appointment.type == 'required' && (
                            <span>
                              {t('appointment.error.dateOfAppointmentError')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.startTime')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="start_time"
                        render={({ field: { value, onChange } }) => (
                          <ReactTimePicker
                            value={value}
                            selected={value}
                            onChange={onStartTimeChange}
                            hasErrors={errors.start_time}
                            placeholder={t('appointment.placeholder.startTime')}
                            minTime={
                              value && moment(value).isSame(moment(dateOfAppointment), 'day')
                                ? moment().toDate()
                                : moment().startOf('day').toDate()
                            }
                          />
                        )}
                      />
                      {errors.start_time && (
                        <small className="text-danger">
                          {errors.start_time.type == 'required' && (
                            <span>{t('appointment.error.startTimeError')}</span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.endTime')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="endTime"
                        render={({ field: { value, onChange } }) => (
                          <ReactTimePicker
                            value={value}
                            selected={value}
                            onChange={onChange}
                            hasErrors={errors.endTime}
                            placeholder={t('appointment.placeholder.endTime')}
                            minTime={startTime && { hour: moment(startTime).add(15, 'minutes').hour(), minute: moment(startTime).add(15, 'minutes').minutes() }}
                          />
                        )}
                      />
                      {errors.endTime && (
                        <small className="text-danger">
                          {errors.endTime.type == 'required' && (
                            <span>{t('appointment.error.endTimeError')}</span>
                          )}
                        </small>
                      )}
                    </div>
                  </div> */}
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.purpose')}
                        {/* <span className="text-danger ms-1">*</span> */}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.purpose ? 'is-invalid' : ''
                          }`}
                        placeholder={t('appointment.placeholder.purpose')}
                        {...register('purpose', {
                          // required: true
                        })}
                      />
                      {/* {errors.title && (
                        <small className="text-danger">
                          {errors.title.type == 'required' && (
                            <span>{t('appointment.error.purposeError')}</span>
                          )}
                        </small>
                      )} */}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.notes')}
                        {/* <span className="text-danger ms-1">*</span> */}
                      </label>
                      <textarea
                        rows="4"
                        className={`form-control ${errors.notes ? 'is-invalid' : ''
                          }`}
                        {...register('notes', {
                          // required: true
                        })}
                      ></textarea>

                      {errors.notes && (
                        <small className="text-danger">
                          {errors.notes.type == 'required' && (
                            <span>{t('appointment.error.notesError')}</span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-12">
                    <div className="mb-3">
                      <label className="form-label ">
                        {t('appointment.formLabel.imageUpload')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        name="guestPhoto"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <SetImagePlaceholderUploder
                            onChange={onChange}
                            value={value}
                            setFile={setValue}
                            setName="guestPhoto"
                            hasError={errors.guestPhoto}
                          />
                        )}
                      />

                      {errors.guestPhoto && (
                        <small className="text-danger">
                          {errors.guestPhoto.type == 'required' && (
                            <span>
                              {t('appointment.error.imageUploadError')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div> */}

                  <div className="text-start col-12">
                    <div className="mb-3 text-end">
                      <button type="submit" className="btn btn-primary me-1">
                        {t('appointment.btnLabel.submit')}
                      </button>
                      <button
                        type="reset"
                        className="btn btn-danger"
                        onClick={onReset}
                      >
                        {t('appointment.btnLabel.reset')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
