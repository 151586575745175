import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import ReactTable from 'components/common/ReactTable';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Swalcolor from 'components/configuration/sweetalert-config';
import { encodeData } from 'components/common/encodeParams';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { departmentlist, departmentdelete } from 'services/masterModuleService'
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import moment from 'moment';

export default function DepartmentMaster() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [editedRowData, setEditedRowData] = useState({})
  const [departmentData, setDepartmentData] = useState([]);
  const { isLoading, setIsLoading } = useContext(loaderContext)

  useEffect(async () => {
    setIsLoading(true)
    try {
      checkModulePermission("Department")
      const { data } = await departmentlist();
      setDepartmentData(data.data);
    } catch (e) {
      errorMessage(e)
    }
    setIsLoading(false)
  }, []);

  const onEditRow = (id) => {
    const editData = departmentData.find((department) => department.id === id)
    console.log("editdataaaa", editData)
    setEditedRowData(editData)
    navigate('/masters/department-master/edit-department', { state: { data: editData } })
  };

  const onDeleteRow = (id) => {
    Swal.fire({
      title: t(
        'departmentMaster.deleteDepartment.sweetAlert.confirmationTitle'
      ),
      text: t('departmentMaster.deleteDepartment.sweetAlert.confirmationText'),
      icon: 'warning',
      showCancelButton: Swalcolor.confirmation.showCancelButton,
      confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
      cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
      confirmButtonText: t(
        'departmentMaster.deleteDepartment.sweetAlert.confirmationBtnLabel'
      )
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        try {
          const { data } = await departmentdelete({ id: id });
          console.log(data);
          setDepartmentData(departmentData.filter(data => data.id != id));
          toast.success(data.message, toastConfig)
          Swal.fire({
            icon: 'success',
            title: t(
              'departmentMaster.deleteDepartment.sweetAlert.simpleTitle'
            ),
            text: data.data.result,
            confirmButtonColor: Swalcolor.simple.confirmButtonColor
          });
        } catch (e) {
          errorMessage(e)
        }
        setIsLoading(false)
      }
    });
  };

  const columns = [
    {
      Header: t('masters.departmentstable.no'),
      // accessor: 'id'
      accessor: (row, index) => index + 1,
    },
    {
      Header: t('masters.departmentstable.departmentname'),
      accessor: 'name'
    },
    {
      Header: t('masters.departmentstable.status'),
      accessor: 'status'
    },
    {
      Header: t('masters.departmentstable.createdby'),
      accessor: 'created_by'
    },
    {
      Header: t('masters.departmentstable.createdon'),
      accessor: 'created_on',
      Cell: ({ row }) => (
        <div>
          {moment.utc(row?.original?.created_on).local().format(process.env.REACT_APP_DATE_TIME_FORMAT)}
        </div>
      ),
    },
    {
      Header: t('masters.departmentstable.action'),
      Cell: ({ row }) => (
        <div>
          <span
            className="btn p-0 me-2"
            title="Edit"
            onClick={() => onEditRow(row.original.id)}
          >
            <FontAwesomeIcon icon="edit" />
          </span>
          <span
            className="btn p-0"
            title="Delete"
            onClick={() => onDeleteRow(row.original.id)}
          >
            <FontAwesomeIcon icon="trash-alt" />
          </span>
        </div>
      ),
      disableSortBy: true
    }
  ];

  return (
    <>
      <div className="row g-3 mb-3">
        <div className="">
          <div className="card overflow-hidden mb-3">
            <div className="card-header">
              <div className="row">
                <div className="col-xl-8 col-lg-6 col-md-5 heading-text">
                  <h3 className="heading-color fw-600 mb-0">
                    {t('masters.departments.departmentheading')}
                  </h3>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-7 table-btn" >
                  <button
                    className="btn btn-primary btn-sm float-end"
                    onClick={() => {
                      navigate('/masters/department-master/create-department');
                    }}
                  >
                    {/* <FontAwesomeIcon icon="plus" /> */}
                    <span className="ms-1 d-sm-inline ">
                      {t('masters.departments.departmentcreatebtn')}
                    </span>
                  </button>
                </div>
                <div className="col-12" >
                  <hr />
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <ReactTable
                columns={columns}
                data={departmentData}
                pageSizeOption={[5, 10, 15, 20]}
                headerClass="btn-primary"
                isSearchBoxVisible={true}
                ispaginationvisible={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
