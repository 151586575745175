import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import formPattern from 'components/configuration/form-pattern';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { useState } from 'react';
import ReactDateAndTimePicker from 'components/common/ReactDateAndTimePicker';
import { createevent, editevent } from 'services/manageEventsService'
import moment from 'moment';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
// import { geteventlist } from 'services/manageEventsService'
import { loaderContext } from 'context/Context';
import { getExternalLocations } from 'services/bookingService';


function EditEvent() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isLoading, setIsLoading } = useContext(loaderContext)



    const [locationList, setLocationList] = useState([])
    const location = useLocation();

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        formState: { errors }
    } = useForm();


    console.log("location", location)


    useEffect(async () => {
        checkModulePermission("Events List")
        setIsLoading(true)
        try {
            const { data } = await getExternalLocations();
            const locationlist = data?.data?.map((location) => ({ label: location.name, value: location.id }))
            setLocationList(locationlist)
            setValue("name", location?.state?.name)
            setValue("location_id", locationlist.find((loc) => loc.value == location?.state?.location_id))
            setValue("date", moment(location?.state?.date).toDate())
            setValue("no_of_guest", location?.state?.guest_no)
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }, [])


    const onSubmit = async (accessData) => {
        setIsLoading(true)
        try {
            accessData.location_id = accessData.location_id.value
            const { data } = await editevent({ event_id: location?.state?.event_id, ...accessData });
            toast.success(data.message, toastConfig);
            navigate('/manageevents/event-approval');
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
        console.log(accessData);
    };
    const onReset = () => {
        reset();
        setValue('location_id', '');
    };

    return (
        <>
            <div className="mb-3 row g-3">
                <div className="card overflow-hidden mb-3">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-8">
                                <h3 className="heading-color fw-600">
                                    {t('editEvent.heading')}
                                </h3>
                            </div>
                            <div className="col-4 table-btn" >
                                <button
                                    className="btn btn-primary btn-sm float-end"
                                    onClick={() => {
                                        navigate('/manageevents/event-approval');
                                    }}
                                >
                                    <span className="ms-1 d-sm-inline">
                                        {t('eventLocation.btnLabel.back')}
                                    </span>
                                </button>
                            </div>

                            <div className="col-12 mb-3">
                                <hr />
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <div className='row'>

                                <div className="form-group col-md-4 mb-3">
                                    <label className="form-label">
                                        {t('editEvent.label.eventName')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <input
                                        type='text'
                                        className={`form-control ${errors.name && 'is-invalid'
                                            }`}
                                        placeholder={t('editEvent.placeholder.eventName')}
                                        {...register('name', { required: true, pattern: formPattern.alphabetWithSpace })}
                                    />
                                    {errors.name && (
                                        <small>
                                            {errors.name.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('editEvent.error.eventNameReq')}
                                                </span>
                                            )}
                                            {errors.name.type === 'pattern' && (
                                                <span className="text-danger">
                                                    {t('editEvent.error.eventNameInvalid')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>


                                <div className="form-group col-md-4">
                                    <label className="form-label">
                                        {t('editEvent.label.eventLocation')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <Controller
                                        name="location_id"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                        
                                                <SearchableDropdown
                                                    value={value}
                                                    onChange={onChange}
                                                    options={locationList}
                                                    placeholder={t(
                                                        'editEvent.placeholder.eventLocation'
                                                    )}
                                                    // isClearable={true}
                                                    hasError={errors.location_id}
                                                />
                                            
                                        )}
                                    />
                                    {errors.location_id && (
                                        <small>
                                            {errors.location_id.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('editEvent.error.eventLocationReq')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-md-4">
                                    <label className="form-label">
                                        {t('editEvent.label.dateAndTime')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <Controller
                                        name='date'
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <ReactDateAndTimePicker
                                                value={value}
                                                onChange={onChange}
                                                hasErrors={errors.date}
                                                placeholder={t("editEvent.placeholder.dateAndTime")}
                                                dateFormat="dd/MM/yyyy HH:mm a"
                                            />
                                        )}
                                    />
                                    {errors.date && (
                                        <small>
                                            {errors.date.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('editEvent.error.dateAndTimeReq')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-md-4 mb-3">
                                    <label className="form-label">
                                        {t('editEvent.label.noOfGuest')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <input
                                        className={`form-control ${errors.no_of_guest ? 'is-invalid' : ''
                                            }`}
                                        type="text"
                                        placeholder={t('editEvent.placeholder.noOfGuest')}
                                        {...register('no_of_guest', {
                                            required: true,
                                            pattern: formPattern.numberOnly
                                        })}
                                    />
                                    {errors.no_of_guest && (
                                        <small className="text-danger">
                                            {errors.no_of_guest.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('editEvent.error.noOfGuestReq')}
                                                </span>
                                            )}
                                            {errors.no_of_guest.type === 'pattern' && (
                                                <span>{t('editEvent.error.noOfGuestInvalid')}</span>
                                            )}
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="mb-3 mt-3 text-end">
                                <button type="submit" className="btn btn-primary me-1">
                                    {t('editEvent.label.submitBtn')}
                                </button>
                                <button
                                    type="reset"
                                    className="btn btn-danger"
                                    onClick={onReset}
                                >
                                    {t('editEvent.label.resetBtn')}
                                </button>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}

export default EditEvent