// import React, { useState, useEffect } from 'react';
// import FalconComponentCard from 'components/common/FalconComponentCard';
// import Flex from 'components/common/Flex';
// import { useDropzone } from 'react-dropzone';
// // import cloudUpload from 'assets/img/icons/cookie.png';

// const singleFileCode = `import { useDropzone } from 'react-dropzone';
// import Flex from 'components/common/Flex';

// function SingleFileExample() {
//   const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
//     accept: '.csv',
//     onDrop: acceptedFiles => {
//          setValue("fileName", acceptedFiles)
//          }
//   });
//   const files = acceptedFiles.map(file => (
//     <li key={file.path}>
//       {file.path} - {file.size} bytes
//     </li>
//   ));

//   return (
//     <>

//       <div {...getRootProps({ className: 'dropzone-area py-3 rounded-3' })} style={{border: "2px dotted #000"}}>
//         <input {...getInputProps({multiple:false})} />
//         <Flex justifyContent="center">
//           <img src=`${process.env.REACT_APP_BASEROUTE_URL}/admin-images/csv-file.png` alt="" width={80} className="me-2" />
//           <p className="fs--1 fw-bold my-auto text-dark">Upload CSV file</p>
//         </Flex>
//       </div>
//       <div className="mt-2">
//         {acceptedFiles.length > 0 && (
//           <>
//           <ul>{files}</ul>
//           </>
//         )}
//       </div>
//     </>
//   );
// }`;

// const FileUploader = ({ setValue, value, onChange, setFile, placeholder }) => {
//   return (
//     <>
//       <FalconComponentCard className="p-0 shadow-none col-sm-12">
//         <FalconComponentCard.Body
//           className="bg-white p-0"
//           code={singleFileCode}
//           onChange={onChange}
//           language="jsx"
//           scope={{
//             useDropzone,
//             Flex,
//             value,
//             onChange,
//             setFile,
//             placeholder,
//             setValue
//           }}
//         />
//       </FalconComponentCard>
//     </>
//   );
// };

// export default FileUploader;

import React, { useState, useEffect } from 'react';
import { useCSVReader, usePapaParse } from 'react-papaparse';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse'

export default function CsvFileUploder({
  setValue,
  setName,
  hasError,
  errorMsg,
  showButton,
  showplaceholderImage,
  placeholderMessage,
  placeholderMessageClassName,
  borderType,
  setFile,
  className,
  removeFileName,
  setRemoveFileName
}) {
  const { readString } = usePapaParse();
  const { CSVReader } = useCSVReader();
  const [fileName, setFileName] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    removeFileName == true && setFileName('');
  }, [removeFileName]);
  useEffect(() => {
    fileName == '' && setRemoveFileName(false);
  }, [fileName]);
  return (
    <>
      <CSVReader
        onUploadAccepted={(results, file) => {
          console.log("Casv", file, results)
          setFile(file)
          setFileName(file.name);
          Papa.parse(file, {
            header: true,
            complete: results => {
              setValue(setName, results.data);
            },
          });
        }}
      >
        {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
          <>
            <div
              className={`rounded ${className}`}
              style={borderType}
              {...(!showButton && { ...getRootProps() })}
            >
              <div className="d-flex justify-content-center">
                {showplaceholderImage && (
                  <img
                    src={`${process.env.REACT_APP_BASEROUTE_URL}/admin-images/csv-file.png`}
                    alt="csv file"
                    width={80}
                    className="me-2 my-4"
                  />
                )}
                <p
                  className={
                    placeholderMessageClassName && placeholderMessageClassName
                  }
                >
                  {fileName ? fileName : placeholderMessage}
                </p>
              </div>
            </div>
            {hasError && (
              <small className="text-danger">
                <span>{errorMsg}</span>
              </small>
            )}
            {showButton && (
              <div className="d-flex mb-3 mt-2">
                <a href={`${process.env.REACT_APP_BASEROUTE_URL}/index.csv`} download="sample.csv">
                  <button
                    type="button"
                    className="btn-primary border-0 py-2 px-3 rounded"
                  >
                    {t('manageGroupVisit.btnLabel.sampleDownload')}
                  </button>
                </a>
              </div>
            )}
          </>
        )}
      </CSVReader>
    </>
  );
}
CsvFileUploder.defaultProps = {
  setValue: '',
  setName: '',
  hasError: '',
  errorMsg: '',
  showButton: true,
  removeFileName: false,
  showplaceholderImage: true,
  placeholderMessage: '',
  placeholderMessageClassName: '',
  header: true,
};
