import { toast } from "react-toastify"
import toastConfig from "components/configuration/toast-config"

export const errorMessage = (err) => {
    // console.clear();
    if (err.response) {
        if (err.response.data) {
            if (Array.isArray(err.response.data.message)) {
                err.response.data.message.map(errors => toast.error(errors, toastConfig))
            } else {
                toast.error(err.response.data.message, toastConfig)
            }
        } else {
            toast.error(err.message, toastConfig)
        }
    } else {
        toast.error(err.message, toastConfig)
    }
}