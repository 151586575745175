import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';
import ReactTable from 'components/common/ReactTable';
import { useForm, Controller } from 'react-hook-form';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactDatePicker from 'components/common/ReactDatePicker';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import './report.css'
import { listidenti } from 'services/manageEventsService'
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { genderOptions } from 'components/configuration/common-options';
import { visitordetailreportlist } from 'services/reportsService'
import { loaderContext } from 'context/Context';
import moment from 'moment';






function VisitordetailReport() {

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const { isLoading, setIsLoading } = useContext(loaderContext)

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [visitordetaildata, setVisitorDetailData] = useState([]);

    const [idtypeOption, setIdTypeOption] = useState([]);
    // const [genderOption, setGenderOption] = useState([{ label: "male", value: "male" }, { label: "female", value: "female" }]);



    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        watch
    } = useForm();

    useEffect(async () => {
        checkModulePermission("Visitor Details Report")
        setIsLoading(true)
        try {
            const { data } = await listidenti()
            const { data: visitordetail } = await visitordetailreportlist({})
            setIdTypeOption(data?.data?.map((ident) => ({ label: ident?.name, value: ident?.id })))
            setVisitorDetailData(visitordetail?.data)
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }, [])

    const columns = [

        {
            Header: t('visitordetailreport.visitordetailreporttable.visitorname'),
            accessor: 'visitor_name',
            Cell: ({ row }) => {
                return row?.original?.visitor_name ? row?.original?.visitor_name : "--"
            }
        },
        {
            Header: t('visitordetailreport.visitordetailreporttable.visitormbno'),
            accessor: 'phone_number',
            Cell: ({ row }) => {
                return row?.original?.phone_number ? `${row?.original?.country_code}-${row?.original?.phone_number}` : '--'
            }
        },
        {
            Header: t('visitordetailreport.visitordetailreporttable.visitoremail'),
            accessor: 'email',
            Cell: ({ row }) => {
                return row?.original?.email ? row?.original?.email : "--"
            }
        },
        {
            Header: t('visitordetailreport.visitordetailreporttable.visitoridno'),
            accessor: 'document_id_number',
            Cell: ({ row }) => {
                return row?.original?.document_id_number ? row?.original?.document_id_number : "--"
            }
        },
        {
            Header: t('visitordetailreport.visitordetailreporttable.visitoridtype'),
            accessor: 'document_id_type',
            Cell: ({ row }) => {
                return row?.original?.document_id_type ? row?.original?.document_id_type : "--"
            }
        },
        {
            Header: t('visitordetailreport.visitordetailreporttable.gender'),
            accessor: 'gender',
            Cell: ({ row }) => {
                return row?.original?.gender ? row?.original?.gender : "--"
            }
        },
        {
            Header: t('visitordetailreport.visitordetailreporttable.dob'),
            accessor: 'dob',
            Cell: ({ row }) => {
                return row?.original?.dob ? moment.utc(row?.original?.dob).local().format(process.env.REACT_APP_FILTER_DATE) : "--"
            }
        },

    ];

    const onSubmit = async (visitordetaildata) => {
        console.log(visitordetaildata);
        setIsLoading(true)

        if (visitordetaildata?.visitor_id_type || visitordetaildata?.gender) {
            try {
                visitordetaildata.visitor_id_type = visitordetaildata.visitor_id_type?.value
                visitordetaildata.gender = visitordetaildata.gender?.value
                const { data } = await visitordetailreportlist(visitordetaildata)
                setVisitorDetailData(data?.data)
            } catch (e) {
                if (e.response.data) {
                    if (Array.isArray(e.response.data.message)) {
                        e.response.data.message.map(errors => toast.error(errors, toastConfig))
                    } else {
                        toast.error(e.response.data.message, toastConfig)
                    }
                } else {
                    toast.error(e.message, toastConfig)
                }
            }
        }
        setIsLoading(false)

    };

    const downloadPDF = () => {
        const docDefinition = {
            pageOrientation: 'landscape',
            pageSize: { width: 800, height: 600 },
            content: [
                { text: 'User Data', style: 'header' },
                { text: '\n' },
                {
                    table: {
                        headerRows: 1,
                        widths: Array.from({ length: columns.length }, () => 'auto'),
                        body: [
                            columns.map((column) => column.Header),
                            ...visitordetaildata.map((row) => columns.map((column) => {
                                if (column.accessor === 'phone_number') {
                                    const phoneNumber = row['phone_number'];
                                    const countryCode = row['country_code'];
                                    return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
                                } else {
                                    return row[column.accessor] !== undefined ? row[column.accessor] : '';
                                }
                            })),
                        ],
                    },
                },
            ],
            defaultStyle: {
                actionHeader: false,
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                },
            },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.download('visitordetail_data.pdf');
    };

    const downloadCSV = () => {
        const csvData = [
            columns.map((column) => column.Header),
            ...visitordetaildata.map((row) => columns.map((column) => {
                if (column.accessor === 'phone_number') {
                    const phoneNumber = row['phone_number'];
                    const countryCode = row['country_code'];
                    return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
                } else {
                    return row[column.accessor] !== undefined ? row[column.accessor] : '';
                }
            })),
        ]
            .map((row) => row.join(','))
            .join('\n');

        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'visitordetail_data.csv');
    };


    return (
        <div className="row g-3 mb-3">
            {/* <div className=""> */}
            <div className="card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-9 data-table" style={{ height: "fit-content" }}>
                <div className="card-header">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-4 heading-text">
                            <h3 className="heading-color fw-600 mb-0">
                                {t('visitordetailreport.heading')}
                            </h3>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-8 table-btn" >
                            <button
                                className="btn btn-primary btn-sm float-end"
                                onClick={() => downloadPDF()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline ">
                                    {t('visitordetailreport.exportpdfbtn')}
                                </span>
                            </button>

                            <button
                                className="btn mx-1 btn-primary btn-sm float-end"
                                onClick={() => downloadCSV()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline ">
                                    {t('visitordetailreport.exportcsvbtn')}
                                </span>
                            </button>
                        </div>

                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <ReactTable
                        columns={columns}
                        data={visitordetaildata}
                        pageSizeOption={[5, 10, 15, 20]}
                        headerClass="btn-primary"
                        isSearchBoxVisible
                        widthClass="table-width"
                        ispaginationvisible={true}
                    />
                </div>
            </div>


            <div className='card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-2 filter-table' style={{ padding: "0rem .8rem 0rem .8rem", height: "fit-content" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row' style={{ backgroundColor: "rgb(247, 247, 247)", paddingTop: ".8rem", height: "3rem", color: "black", marginBottom: '1rem' }}>
                        <span>{t('visitordetailreport.filterheading')}</span>
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('visitordetailreport.filterform.idtype')}
                        </label>
                        <Controller
                            name="visitor_id_type"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={idtypeOption}
                                    hasError={errors.visitor_id_type}
                                    placeholder={t('visitordetailreport.placeholder.idtype')}
                                />
                            )}
                        />
                        {/* {errors.visitor_id_type && (
                            <small>
                                {errors.visitor_id_type.type === 'required' && (
                                    <span className="text-danger">
                                        {t('visitordetailreport.error.idtype')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('visitordetailreport.filterform.gender')}
                        </label>
                        <Controller
                            name="gender"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={genderOptions}
                                    hasError={errors.gender}
                                    placeholder={t('visitordetailreport.placeholder.gender')}
                                />
                            )}
                        />

                        {/* {errors.gender && (
                            <small>
                                {errors.gender.type === 'required' && (
                                    <span className="text-danger">
                                        {t('visitordetailreport.error.gender')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>

                    <div className="text-start col-12">
                        <div className="mb-3 mt-4 text-start">
                            <button type="submit" className="col-lg-12 btn btn-primary me-1">
                                {t('visitordetailreport.filterform.filterbtn')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {/* </div> */}
        </div>
    )
}

export default VisitordetailReport