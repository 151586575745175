// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import React from 'react'
// import { Modal } from 'react-bootstrap';
// import Webcam from 'react-webcam';
// import "../../../src/assets/css/capture.css"
// import { t } from 'i18next';


// export default function CaptureDocumentModal({ isModalOpen, setIsModalOpen, setUserPhoto }) {
//     const videoConstraints = {
//         facingMode: "environment"
//     };
//     const webcamRef = React.useRef(null);
//     const capture = React.useCallback(async () => {
//         const imageSrc = webcamRef.current.getScreenshot();
//         setUserPhoto(imageSrc)
//         setIsModalOpen(false)
//     }, [webcamRef]);
//     return (
//         <Modal show={isModalOpen} fullscreen={true} onHide={() => setIsModalOpen(false)} style={{ backgroundColor: "none" }}>
//             <Modal.Body className='modalbody' style={{ height: "100%", backgroundColor: "none" }}>
//                 <div className='position-absolute' style={{ right: "0%" }}>
//                     <i closeButton className="fa  fa-times-circle modal-close-btn" aria-hidden="true" onClick={() => setIsModalOpen(false)}></i>
//                 </div>
//                 <div className='d-flex align-items-center justify-content-center' style={{ flexDirection: "column", height: "inherit" }}>
//                     <div style={{ display: "block" }}>
//                         <Webcam
//                             audio={false}
//                             style={{ height: "34.81rem", maxWidth: "100%" }}
//                             ref={webcamRef}
//                             screenshotFormat="image/jpeg"
//                             videoConstraints={videoConstraints}
//                         // mirrored={true}
//                         />
//                     </div>
//                     <div className="capture-cropper">

//                     </div>
//                     <button className="btn btn-primary" onClick={capture} style={{ position: "absolute", bottom: "15%" }}>
//                         {t("navbarMenu.docmodal.heading")}
//                     </button>
//                 </div>
//             </Modal.Body>
//         </Modal>
//     )
// }







//----------- final one-------------------- //

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import Webcam from 'react-webcam';
import "../../../src/assets/css/capture.css"
import { t } from 'i18next';
import useWindowDimensions from '../common/useWindow';

export default function CaptureDocumentModal({ isModalOpen, setIsModalOpen, setUserPhoto }) {
    const videoConstraints = {
        facingMode: "environment"
    };
    const webcamRef = React.useRef(null);
    const { width } = useWindowDimensions();

    // const capture = React.useCallback(async () => {
    //     const imageSrc = webcamRef.current.getScreenshot();
    //     let cropWidth, cropHeight, cropX, cropY;
    //     if (width >= 768) {
    //         cropWidth = 37 * 16; // 37 * 16 pixels
    //         cropHeight = 21 * 16; // 21 * 16 pixels
    //     } else if (width >= 375) {
    //         cropWidth = width * 0.9; // 37 * 16 pixels
    //         cropHeight = 16 * 16; // 21 * 16 pixels
    //     } else {
    //         cropWidth = width * 0.9; // 90% of the screen width
    //         cropHeight = 12 * 16; // 12rem in pixels (assuming 1rem = 16px)
    //     }

    //     const roi = {
    //         x: width >= 768 ? 100 : 0,
    //         y: width >= 768 ? 100 : 0,
    //         width: cropWidth,
    //         height: cropHeight
    //         // width: width >= 768 ? 37 * 16 : width * 0.9 * .6,
    //         // height: width >= 768 ? 21 * 16 : width * 0.5625 * .6,
    //     };

    //     const img = new Image();
    //     img.src = imageSrc;

    //     img.onload = function () {
    //         const tempCanvas = document.createElement('canvas');
    //         tempCanvas.width = roi.width;
    //         tempCanvas.height = roi.height;

    //         const tempCtx = tempCanvas.getContext('2d');

    //         tempCtx.drawImage(img, roi.x, roi.y, roi.width, roi.height, 0, 0, roi.width, roi.height);
    //         const croppedImageSrc = tempCanvas.toDataURL('image/jpeg');

    //         setUserPhoto(croppedImageSrc);
    //         setIsModalOpen(false);
    //     };
    // }, [webcamRef, width]);

    const capture = React.useCallback(async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        let cropWidth, cropHeight, cropX, cropY;
        if (width >= 768) {
            cropWidth = 37 * 16; // 37 * 16 pixels
            cropHeight = 21 * 16; // 21 * 16 pixels
            cropX = 0; // Start cropping from the left
            cropY = 0; // Start cropping from the top
        } else if (width >= 375) {
            cropWidth = width * 0.9;
            cropHeight = 14 * 16;
            cropX = 0; // Start cropping from the left
            cropY = 0; // Start cropping from the top
        } else if (width >= 275) {
            cropWidth = width * 0.9;
            cropHeight = 12 * 16;
            cropX = 0; // Start cropping from the left
            cropY = 0; // Start cropping from the top
        } else {
            cropWidth = width * 0.9;
            cropHeight = 12 * 16;
            cropX = 0; // Start cropping from the left
            cropY = 0; // Start cropping from the top
        }

        const img = new Image();
        img.src = imageSrc;

        img.onload = function () {
            if (img.width > cropWidth) {
                cropX = (img.width - cropWidth) / 2; // Adjust cropX if image width is greater than cropWidth
            }
            if (img.height > cropHeight) {
                cropY = (img.height - cropHeight) / 2; // Adjust cropY if image height is greater than cropHeight
            }

            const roi = {
                x: cropX,
                y: cropY,
                width: cropWidth,
                height: cropHeight
            };

            const tempCanvas = document.createElement('canvas');
            tempCanvas.width = roi.width;
            tempCanvas.height = roi.height;

            const tempCtx = tempCanvas.getContext('2d');

            tempCtx.drawImage(img, roi.x, roi.y, roi.width, roi.height, 0, 0, roi.width, roi.height);
            const croppedImageSrc = tempCanvas.toDataURL('image/jpeg');
            console.log("croped", croppedImageSrc)
            setUserPhoto(croppedImageSrc);

            setIsModalOpen(false);
        };
    }, [webcamRef, width]);
    return (
        <Modal show={isModalOpen} fullscreen={true} onHide={() => setIsModalOpen(false)} style={{ backgroundColor: "none" }}>
            <Modal.Body className='modalbody' style={{ height: "100%", backgroundColor: "none" }}>
                <div className='position-absolute' style={{ right: "0%" }}>
                    <i closeButton className="fa  fa-times-circle modal-close-btn" aria-hidden="true" onClick={() => setIsModalOpen(false)}></i>
                </div>
                <div className='d-flex align-items-center justify-content-center' style={{ flexDirection: "column", height: "inherit" }}>
                    <div style={{ display: "block" }}>
                        <Webcam
                            audio={false}
                            style={{ height: "34.81rem", maxWidth: "100%" }}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                        // mirrored={true}
                        />
                    </div>
                    <div className="capture-cropper">

                    </div>
                    <button className="btn btn-primary" onClick={capture} style={{ position: "absolute", bottom: "15%" }}>
                        {t("navbarMenu.docmodal.heading")}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}










