import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import ReactTable from 'components/common/ReactTable';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Swalcolor from 'components/configuration/sweetalert-config';
import { encodeData } from 'components/common/encodeParams';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import moment from 'moment';
import OutsideClickHandler from 'react-outside-click-handler';
import { dataTool } from 'echarts';
import EventApproveModal from './EventApproveModal';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { geteventlist, eventlistapprove, guestdelete, singleguestadd, deleteevent } from 'services/manageEventsService'
import { Modal, Button, Form, } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import formPattern from 'components/configuration/form-pattern';
import CountryCode from 'components/common/CountryCode';
import countryCodes, {
    defaultCountryCode
} from 'components/configuration/country-code';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import pattern from 'components/configuration/form-pattern';

function EventsApproval() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
    const [approveUserId, setApproveUserId] = useState("");
    const [events, setEvents] = useState([])
    const [eventMemebers, setEventMembers] = useState([]);
    const [pageNo, setPageNO] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [guestData, setGuestData] = useState([])
    const [countryCode, setCountryCode] = useState(defaultCountryCode);
    const [eventID, setEventID] = useState([])



    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        setError,
        watch
    } = useForm();


    const columns = [
        {
            Header: t('eventsApproval.label.no'),
            // accessor: 'event_id',
            accessor: (row, index) => index + 1,
            // Cell: ({ row }) => {
            //     return (
            //       <div {...row.getToggleRowExpandedProps()}>{`${row.original.event_id}`}</div> 
            //     );
            // }
        },
        {
            Header: t('eventsApproval.label.memberName'),
            accessor: 'member_name',
            Cell: ({ row }) => {
                return (
                    <div {...row.getToggleRowExpandedProps()}>{`${row.original.member_name}`}</div>
                );
            }
        },
        {
            Header: t('eventsApproval.label.memberNo'),
            accessor: 'member_number',
            Cell: ({ row }) => {
                return (
                    <div {...row.getToggleRowExpandedProps()}>{`${row.original.member_number}`}</div>
                );
            }
        },
        {
            Header: t('eventsApproval.label.eventLocation'),
            accessor: 'location',
            Cell: ({ row }) => {
                return (
                    <div {...row.getToggleRowExpandedProps()}>{`${row.original.location}`}</div>
                );
            }
        },
        {
            Header: t('eventsApproval.label.eventname'),
            accessor: 'name',
            Cell: ({ row }) => {
                return (
                    <div {...row.getToggleRowExpandedProps()}>{`${row.original.name}`}</div>
                );
            }
        },
        {
            Header: t('eventsApproval.label.noOfPeople'),
            accessor: 'guest_no',
            Cell: ({ row }) => {
                return (
                    <div {...row.getToggleRowExpandedProps()}>{`${row.original.guest_no}`}</div>
                );
            }
        },
        {
            Header: t('eventsApproval.label.eventDate'),
            accessor: 'date',
            Cell: ({ row }) => {
                return (
                    <div {...row.getToggleRowExpandedProps()}>{moment(row?.original?.date).format(process.env.REACT_APP_DATE_TIME_FORMAT)}</div>
                );
            }
        },
        {
            Header: t('eventsApproval.label.status'),
            accessor: 'status',
            Cell: ({ row }) => {
                return (
                    <>
                        <div {...row.getToggleRowExpandedProps()}>{`${row.original.status === 0 ? t('eventsApproval.label.pending') : row.original.status === 1 ? t('eventsApproval.label.approval') : t('eventsApproval.label.decline')}`}</div>
                    </>
                );
            }
        },
        {
            Header: t('eventsApproval.label.createdOn'),
            accessor: 'created_on',
            Cell: ({ row }) => {
                return (
                    <div {...row.getToggleRowExpandedProps()}>{moment.utc(row?.original?.created_on).local().format(process.env.REACT_APP_DATE_TIME_FORMAT)}</div>
                );
            }
        },
        {
            Header: t('eventsApproval.label.action'),
            Cell: ({ row }) => (
                <>
                    <div>
                        {row.original.is_edit_enable === 0 && (
                            <>
                                <span
                                    className="btn p-0 me-1"
                                    title="Edit"
                                    onClick={() => onEditRow(row.original.event_id)}
                                >
                                    <FontAwesomeIcon icon="edit" />
                                </span>
                                <span
                                    className="btn p-0"
                                    title="Delete"
                                    onClick={() => onDeleteRow(row.original.event_id)}
                                >
                                    <FontAwesomeIcon icon="trash-alt" />
                                </span>
                            </>
                        )}



                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <span
                            className="btn p-0 me-1"
                            title="View"
                            onClick={() => onView(row.original.event_id)}
                        >
                            <VisibilityIcon />
                        </span>
                        {row.original.status === 0 && <span
                            className="btn p-0 me-1"
                            title="Approve"
                            onClick={() => onApproveClick(row.original.event_id)}
                        >
                            <CheckCircleOutlineIcon />
                        </span>}
                        {row.original.status === 0 && <span
                            className="btn p-0"
                            title="Decline"
                            onClick={() => onDeclineClick(row.original.event_id)}
                        >
                            <CancelIcon />
                        </span>}
                    </div>
                </>
            ),
            // content: data => {
            //     const [isOpen, setIsOpen] = useState(false);
            //     return (<div>
            //         <span
            //             className="btn p-0 me-2"
            //             title="Edit"
            //             onClick={() => setIsOpen(!isOpen)}
            //         >
            //             <FontAwesomeIcon icon="ellipsis-h" />
            //         </span>
            //         {
            //             isOpen &&
            //             <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
            //                 <div className='card position-absolute p-2' style={{ left: "79%", minWidth: "15%", boxShadow: "0 0 6px 0 rgba(0,0,0,0.45) inset" }}>
            //                     <div className='pt-2 px-3' style={{ cursor: "pointer" }} onClick={() => onApproveClick(data)}>
            //                         <span>Approve</span>
            //                     </div>
            //                     <div className='py-2 px-3' style={{ cursor: "pointer" }} onClick={() => onDeclineClick(data)}>
            //                         <span>Decline</span>
            //                     </div>
            //                 </div >
            //             </OutsideClickHandler>
            //         }
            //     </div >)
            // },
            disableSortBy: true
        }
    ];

    const onEditRow = (id) => {
        const newData = events?.find((event) => event.event_id === id)
        navigate(
            `/manageevents/edit-event`, { state: newData }
        );
    };
    const onDeleteRow = (id) => {
        Swal.fire({
            title: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationTitle'
            ),
            text: t('departmentMaster.deleteDepartment.sweetAlert.confirmationText'),
            icon: 'warning',
            showCancelButton: Swalcolor.confirmation.showCancelButton,
            confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
            cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
            confirmButtonText: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationBtnLabel'
            )
        }).then(async result => {
            if (result.isConfirmed) {
                setIsLoading(true)
                try {
                    const { data } = await deleteevent({ event_id: id });
                    console.log(data);
                    setEvents(events.filter(data => data.event_id != id));
                    Swal.fire({
                        icon: 'success',
                        title: t(
                            'departmentMaster.deleteDepartment.sweetAlert.simpleTitle'
                        ),
                        text: data.data.result,
                        confirmButtonColor: Swalcolor.simple.confirmButtonColor
                    });
                } catch (e) {
                    errorMessage(e)
                }
                setIsLoading(false)
            }
        });
    };

    const subColumns = [
        {
            Header: t('eventbooking.eventdetail.no'),
            accessor: (row, index) => index + 1
        },
        {
            Header: t('eventbooking.eventdetail.guestname'),
            accessor: 'name',
            Cell: ({ row }) => <div style={row?.original?.is_blacklist == 1 ? { color: "var(--warning)", fontWeight: 'bold' } : {}}>{row?.original?.name}</div>
        },
        {
            Header: t('eventbooking.eventdetail.guestmbno'),
            // accessor: ({ row }) => `${row.country_code} ${row.phone}`,
            Cell: ({ row }) => row.original.phone ? `${row.original.country_code} ${row.original.phone}` : "--"
        },
        {
            Header: t('eventbooking.eventdetail.guestemail'),
            accessor: 'email',
            Cell: ({ row }) => row.original.email ? row.original.email : '--'
        },
        {
            Header: t('eventbooking.eventdetail.vehicleno'),
            accessor: 'vehicle_reg_no',
            Cell: ({ row }) => row.original.vehicle_reg_no ? row.original.vehicle_reg_no : '--'
        },
        {
            Header: t('eventsApproval.label.action'),
            Cell: ({ row }) => (
                <div>
                    <span
                        className="btn p-0 me-2"
                        title="Delete"
                        onClick={() => DeleteGuest(row.original.event_guest_id, row.original.event_id)}
                    >
                        <FontAwesomeIcon icon="trash-alt" />
                    </span>
                </div>
            )
        }
    ]

    const onView = (id) => {
        const newData = events.find((event) => event.event_id === id)
        navigate('/manageevents/event-view', { state: { data: newData } })
    }
    const onApproveClick = (id) => {
        console.log("id", id)
        setIsApprovalModalOpen(true);
        setApproveUserId(id)
    }
    const onDeclineClick = async (id) => {
        setIsLoading(true)
        try {
            const { data } = await eventlistapprove({ event_id: id ? id : 0, status: 2, comment: '' })
            toast.success(data.message, toastConfig)
            const newEvent = events.map((event) => {
                if (event?.event_id == id) {
                    event.status = 2
                }
                return event
            })
            setEvents(newEvent)
        } catch (error) {
            toast.error(data.message, toastConfig)
        }
        setIsLoading(false)
    }


    const handleOpen = () => {
        setIsModalOpen(true)
    }
    console.log("event==>", events)
    const onSubmit = async (guestdata) => {
        setIsLoading(true)
        try {
            const id = eventID
            console.log("id====>", id)
            const { data } = await singleguestadd({ country_code: countryCode?.dial_code, event_id: id, ...guestdata })

            guestdata.country_code = countryCode?.dial_code
            setGuestData([...guestData, data?.data[0].event_guest]);
            toast.success(data.message, toastConfig)
            setEvents(events?.map((eve) => {
                if (eve.event_id === eventID) {
                    eve.guest_no = data?.data[0].guest_no
                }
                return eve
            }))
            reset()
            setIsModalOpen(false)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }

    const handleReset = () => {
        reset();
        setCountryCode(defaultCountryCode)
    }
    const DeleteGuest = async (id, eventId) => {
        Swal.fire({
            title: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationTitle'
            ),
            text: t('departmentMaster.deleteDepartment.sweetAlert.confirmationText'),
            icon: 'warning',
            showCancelButton: Swalcolor.confirmation.showCancelButton,
            confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
            cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
            confirmButtonText: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationBtnLabel'
            )
        }).then(async result => {
            if (result.isConfirmed) {
                setIsLoading(true)
                try {
                    setGuestData(guestData?.filter((guest) => guest.event_guest_id !== id))
                    const { data } = await guestdelete({ event_id: eventId, event_guest_id: id })
                    toast.success(data.message, toastConfig)
                    Swal.fire({
                        icon: 'success',
                        title: t(
                            'departmentMaster.deleteDepartment.sweetAlert.simpleTitle'
                        ),
                        text: data.data.result,
                        confirmButtonColor: Swalcolor.simple.confirmButtonColor
                    });
                } catch (e) {
                    errorMessage(e)
                }
                setIsLoading(false)
            }
        });

    }



    useEffect(async () => {
        checkModulePermission("Events List")
        setIsLoading(true)
        try {
            const { data } = await geteventlist()
            setEvents(data?.data)
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, [])



    const renderRowSubComponent = React.useCallback(({ row }) => {
        setIsLoading(true)
        if (guestData.length <= 0 || eventID != row?.original?.event_id) {
            const newGuestData = row?.original?.guest_list
            setGuestData(newGuestData)
            setEventID(row?.original?.event_id)
        }
        setIsLoading(false)
        return (
            <div className="bg-img card nested-table-body overflow-hidden" >
                <ReactTable
                    columns={subColumns}
                    data={guestData ? guestData : []}
                    // pageSizeOption={[5, 10, 15, 20]}
                    headerClass="btn-primary"
                    isSearchBoxVisible={true}
                    isPaginationRequired={true}
                    ispaginationvisible={true}
                    component={
                        <>
                            <div className="col-4 table-btn" style={{ marginLeft: "34rem", marginTop: "-.7rem" }}>
                                <button
                                    className="btn btn-primary btn-sm float-end"
                                    onClick={() => handleOpen()}
                                >
                                    <span className="ms-1 d-sm-inline ">
                                        {t('eventLocation.btnLabel.add')}
                                    </span>
                                </button>
                            </div>
                        </>
                    }
                />
            </div>
        )
    }, [guestData])



    return (
        <>
            <div className="row g-3 mb-3">
                <div className="">
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="heading-color fw-600 mb-0">
                                        {t('eventLocation.view')}
                                    </h3>
                                </div>
                                <div className="col-12" >
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <ReactTable
                                columns={columns}
                                data={events}
                                pageSizeOption={[5, 10, 15, 20]}
                                headerClass="btn-primary"
                                isSearchBoxVisible={true}
                                isPaginationRequired={true}
                                // widthClass="table-width"
                                ispaginationvisible={true}
                                RenderRowSubComponent={
                                    renderRowSubComponent
                                }
                                pageNo={pageNo}
                                setPageNO={setPageNO}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {
                isModalOpen && (
                    <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} size="xl" centered={true}>
                        <Modal.Header closeButton className="bg-light border-bottom-0">
                            <Modal.Title>
                                <h2>{t('eventGuestModal.heading')}</h2>
                            </Modal.Title>
                        </Modal.Header>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Modal.Body>
                                <div className='row'>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('eventGuestModal.label.guestname')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.name && 'is-invalid'
                                                }`}
                                            placeholder={t('eventGuestModal.placeholder.guestname')}
                                            {...register('name', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                        />
                                        {errors.name && (
                                            <small>
                                                {errors.name.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('eventGuestModal.error.guestname')}
                                                    </span>
                                                )}
                                                {errors.name.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('eventGuestModal.error.guestnameInvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>


                                    <div className='form-group pe-0 col-md-4'>
                                        <label className="form-label">
                                            {t('bookGuest.label.countryCode')}
                                            {/* <span className="text-danger ms-1">*</span> */}
                                        </label>
                                        <CountryCode
                                            countryCode={countryCode}
                                            setCountryCode={setCountryCode}
                                            direction="bottom"
                                        />
                                    </div>

                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('eventGuestModal.label.guestno')}
                                            {/* <span className="text-danger ms-1">*</span> */}
                                        </label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.phone && 'is-invalid'
                                                }`}
                                            placeholder={t('eventGuestModal.placeholder.guestno')}
                                            {...register('phone', { pattern: formPattern.phoneNumber })}
                                        />
                                        {errors.phone && (
                                            <small>
                                                {/* {errors.phone.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('eventGuestModal.error.guestno')}
                                                    </span>
                                                )} */}
                                                {errors.phone.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('eventGuestModal.error.guestnoInvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>

                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('eventGuestModal.label.guestemail')}
                                            {/* <span className="text-danger ms-1">*</span> */}
                                        </label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.email && 'is-invalid'
                                                }`}
                                            placeholder={t('eventGuestModal.placeholder.guestemail')}
                                            {...register('email', { pattern: formPattern?.email })}
                                        />
                                        {errors.email && (
                                            <small>
                                                {/* {errors.email.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('eventGuestModal.error.guestemail')}
                                                    </span>
                                                )} */}
                                                {errors.email.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('eventGuestModal.error.guestemailInvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="bg-light px-card border-top-0">
                                <button
                                    className='btn btn-primary'
                                    type='submit'
                                >
                                    {t('eventapproval.submit')}
                                </button>
                                <button
                                    className="btn btn-danger "
                                    type="reset"
                                    onClick={handleReset}
                                >
                                    {t('eventapproval.reset')}
                                </button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                )
            }


            <EventApproveModal
                isModalVisible={isApprovalModalOpen}
                setIsModalVisible={setIsApprovalModalOpen}
                approveUserId={approveUserId}
                events={events}
                setEvents={setEvents}
            />
        </>
    );
}

export default EventsApproval