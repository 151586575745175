import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBox from 'components/common/SearchBox';
import '../../assets/css/pagination.css';
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useExpanded,
  useGroupBy
} from 'react-table';
import { t } from 'i18next';
import {
  getDateFormate,
  getTimeFormate,
  getDateAndTimeFormate
} from './helper-function/reactTableHelper';
import moment from 'moment';
import VisitsReport from 'components/pages/reports/VisitsReport';
import NestedSosTable from 'components/pages/reports/sosreport/NestedSosTable';
import EventsnestedTable from 'components/pages/reports/eventreport/EventsnestedTable';
import Nestedtimevisittable from 'components/pages/reports/timevisitreport/Nestedtimevisittable';
import '../../assets/css/ReactTable.css'
import { useLocation, useNavigate, useNavigation, useParams } from 'react-router-dom';


export default function ReactTables({
  columns,
  data,
  pageSizeOption,
  isPageNumberIndecatorRequired,
  isPageSizeDropdownRequired,
  isPaginationRequired,
  ispaginationvisible,
  innerTableSearch,
  headerClass,
  widthClass,
  showHeader,
  isSearchBoxVisible,
  isSearchBoxVisibleFrontdesk,
  isaddButtonVisible,
  component,
  tablefrontdesk,
  hedingName,
  bodyClass,
  RenderRowSubComponent,
  pageNo = 0,
  setPageNO = () => { },
  setTrProp = () => { }
}) {
  const [filterInput, setFilterInput] = useState('');
  const [entry, setEntry] = useState('10');
  const navigate = useNavigate()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, expanded },
    toggleRowExpanded
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: pageNo, pageSize: pageSizeOption[0], hiddenColumns: columns.map(column => {
          if (column.show === false) return column.accessor || column.id;
        }),
      } // Pass our hoisted table state
    },
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination
  );
  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setGlobalFilter(value || undefined);
    setFilterInput(value === undefined ? '' : value);
  };
  // useEffect(() => {
  //   setTimeout(() => {
  //     setPageSize(entry && entry != '' ? parseInt(entry) : 1)
  //   }, 1000);
  // }, [entry])

  const [datafound, setDataFound] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const parsedEntry = entry !== '' ? parseInt(entry) : null;

      if (parsedEntry === null || isNaN(parsedEntry) || parsedEntry == 0) {
        setDataFound(false);
      } else {
        setDataFound(true);
        setPageSize(parsedEntry);
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [entry]);

  console.log("data....", datafound)

  const PAGE_RANGE_DISPLAYED = 3;

  // const startPage = Math.max(0, pageIndex - Math.floor((PAGE_RANGE_DISPLAYED - 1) / 2));
  // const endPage = Math.min(pageCount - 1, startPage + PAGE_RANGE_DISPLAYED - 1);


  return (
    <div>



      {/* {isSearchBoxVisible && (

        <div className='d-flex flex-wrap gap-2 justify-content-between tablesearch mt-1 mb-2'>
          <div className="d-flex d-grid gap-2 align-items-center">
            <span className="showentris">{t('masters.departments.departmentshow')}</span>
            <input type="text" name value={entry} className="form-control sortlist" onChange={(e) => setEntry((e.target.value))}></input>
            <span className="showentris">{t('masters.departments.departmententries')}</span>
          </div>


          <div className='d-flex d-grid gap-1 align-items-center searchbox'>
            <SearchBox onChange={handleFilterChange} value={filterInput} />
          </div>
        </div>
      )} */}

      {isSearchBoxVisible && (
        <>
          <div className='d-flex flex-wrap gap-2 justify-content-between tablesearch mt-1 mb-2'>
            <div className="d-flex d-grid gap-2 align-items-center">
              <span className="showentris">{t('masters.departments.departmentshow')}</span>
              <input type="text" name value={entry} className="form-control sortlist-frontdesk" onChange={(e) => setEntry((e.target.value))}></input>
              <span className="showentris">{t('masters.departments.departmententries')}</span>
            </div>

            {component && <div className="d-flex justify-content-end">
              {component && component}
            </div>}

            <div className='d-flex d-grid gap-1 align-items-center searchbox'>
              <SearchBox onChange={handleFilterChange} value={filterInput} />
            </div>

          </div>
        </>
      )}

      {
        isSearchBoxVisibleFrontdesk && (
          <>
            <div className=" mb-3 mt-1 row ">
              <div className='col-lg-6 col-md-12'>
                <h3 className="heading-nestedtable-color fw-600">{t(hedingName)}</h3>
              </div>

              <div className="col-lg-6 col-md-12  searchablebox">
                <SearchBox onChange={handleFilterChange} value={filterInput} />
              </div>
            </div>
            <div className="col-md-12 mb-2" style={{ marginTop: "-1rem" }}>
              <hr />
            </div>

          </>
        )
      }
      {showHeader && (
        <>
          <div className="mb-3 mt-1 row ">
            {hedingName && <div className='col-lg-6 col-md-6'>
              <h3 className="heading-color-frontdesk fw-600">{t(hedingName)}</h3>
            </div>}
            {component && <div className="col-lg-6 col-md-6 d-flex justify-content-space-around">
              {component && component}
            </div>}
          </div>

          {hedingName && <div className="col-md-12" >
            <hr />
          </div>}

          <div className={`d-flex flex-wrap gap-2 justify-content-between tablesearch-frontdesk  mb-3 ${innerTableSearch}`}>
            <div className="d-flex d-grid gap-2 align-items-center">
              <span className="showentris">{t('visitorLogout.search.show')}</span>
              <input type="text" name value={entry} className="form-control sortlist-frontdesk " onChange={(e) => setEntry((e.target.value))}></input>
              <span className="showentris">{t('visitorLogout.search.entries')}</span>
            </div>
            <div className="d-flex d-grid gap-1 align-items-center searchbox">
              <SearchBox onChange={handleFilterChange} value={filterInput} />
            </div>
          </div>
          {/* <div className="col-md-6">
           
          </div> */}
        </>
      )}

      {/* <div className="mb-3 row justify-content-end">
            {component}
            <div className="col-md-4">
              <SearchBox onChange={handleFilterChange} value={filterInput} />
            </div>
          </div> */}


      <div className="table-responsive scrollbar mb-4">
        <table
          {...getTableProps()}
          // className="table  overflow-hidden mb-0"
          className={`table  overflow-hidden mb-0 ${widthClass} `}
        >
          <thead className={headerClass}>
            {headerGroups.map((headerGroup, i) => (
              <React.Fragment key={i}>
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`${column.className && column.className}`}
                    >
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      {!column.disableSortBy && (
                        <span className="ms-2">
                          <FontAwesomeIcon
                            icon={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? 'arrow-down'
                                  : 'arrow-up'
                                : 'sort'
                            }
                          />
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className={bodyClass}>
            {page.length >= 1 && datafound ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <React.Fragment key={i}>
                    <tr {...row.getRowProps()}
                      className={`${tablefrontdesk}`}
                    >
                      {row.cells.map((cell, index) => {
                        return cell.column.content ? (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className={`${cell.column.className && cell.column.className
                              } ${tablefrontdesk}`}
                            {...setTrProp(row)}
                          >
                            {cell.column.content(cell.row.original.id)}
                          </td>
                        ) : cell.column.isImage ? (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className={`${cell.column.className && cell.column.className
                              }`}
                            {...setTrProp(row)}
                          >
                            {cell.value ?
                              <img src={cell.value} height="32px" width="32px" />
                              :
                              <img src={`${process.env.REACT_APP_BASEROUTE_URL}/admin-images/avatar.png`} height="32px" width="32px" />
                            }
                          </td>
                        ) : cell.column.valueType === 'date' ||
                          cell.column.valueType === 'dateAndTime' ||
                          cell.column.valueType === 'time' ? (
                          <td
                            key={index}
                            className={`${cell.column.className && cell.column.className
                              }`}
                            {...setTrProp(row)}
                          >
                            {cell.column.valueType === 'date'
                              ? moment(
                                cell.render('Cell').props.cell.value
                              ).format(
                                process.env.REACT_APP_DISPLAY_DATE_FORMAT
                              )
                              : cell.column.valueType === 'dateAndTime'
                                ? moment(
                                  cell.render('Cell').props.cell.value
                                ).format(
                                  process.env.REACT_APP_DISPLAY_DATE_TIME_FORMAT
                                )
                                : cell.render('Cell').props.cell.value != "Invalid Date" ? moment(
                                  cell.render('Cell').props.cell.value
                                ).format(
                                  process.env.REACT_APP_DISPLAY_TIME_FORMAT
                                ) : "--:--"}
                          </td>
                        ) : (
                          <td
                            {...cell.getCellProps()}
                            key={index}
                            className={`${cell.column.className && cell.column.className
                              }`}
                            {...setTrProp(row)}
                            onClick={() => toggleRowExpanded(row)}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>

                    {row.isExpanded ? (
                      <>
                        <tr className={row.isExpanded ? 'expanded' : 'collapsed'}>
                          <td colSpan={columns.length}>
                            <RenderRowSubComponent row={row} />
                            {/* {renderRowSubComponent(row)} */}
                          </td>
                        </tr>
                      </>
                    ) : null}
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <td colSpan={`${columns.length + 1}`}>
                  <h5 className="text-center text-danger">
                    {t('table.dataNotFound')}
                  </h5>
                </td>
              </tr>
            )}

          </tbody>
        </table>
      </div>




      {
        ispaginationvisible && (
          <>
            <div className={`paginationinfo ${innerTableSearch}`}>
              <p style={{ color: "#939aac" }}>
                {t('visitorLogout.search.showing')} {pageIndex * pageSize + 1} {t('visitorLogout.search.to')} {' '}
                {Math.min((pageIndex + 1) * pageSize, data.length)} {t('visitorLogout.search.of')} {data.length} {t('visitorLogout.search.entries')}
              </p>
            </div>

            <div className={`main-pagination ${innerTableSearch}`}>
              <div className="d-flex flex-wrap align-items-center">

              </div>
              {!canPreviousPage && !canNextPage && isPaginationRequired ? null : (
                <div className="d-flex flex-wrap">


                  <div className="d-flex pagination">
                    <button
                      type='button'
                      onClick={() => { previousPage(); setPageNO(pageIndex - 1) }}
                      className={`btn-previous btn-sm btn-falcon-default  ${!canPreviousPage && 'disabled'
                        }`}

                    >
                      {t('visitorLogout.pagination.paginationBtn')}

                    </button>


                    {Array.from({ length: pageCount }, (_, i) => {
                      const isActive = i === pageIndex;
                      if (pageCount <= 3) {
                        return (
                          <span
                            key={i}
                            className={`pagination-btn ${isActive ? 'active' : ''}`}
                            onClick={() => { gotoPage(i); setPageNO(i); }}
                          >
                            {i + 1}
                          </span>
                        );
                      }
                      const startPage = Math.max(0, pageIndex - Math.floor((PAGE_RANGE_DISPLAYED - 1) / 2));
                      const endPage = Math.min(pageCount - 1, startPage + PAGE_RANGE_DISPLAYED - 1);

                      if (i === 3 && pageIndex === 0) {
                        // Show ellipsis after the first three pages when pageIndex is 0
                        return (
                          <>
                            <span key={`ellipsis-after-3`} className="pagination-btn">...</span>
                            <span className="pagination-btn">{pageCount}</span>
                          </>
                        )
                      }
                      if (i === startPage - 1 && startPage > 1) {
                        return (
                          <>
                            <span className="pagination-btn" onClick={() => { gotoPage(0); setPageNO(0); }}>1</span>
                            <span key={`ellipsis-start`} className="pagination-btn">...</span>
                          </>
                        );
                      }

                      if (i >= startPage && i <= endPage) {
                        return (
                          <span
                            key={i}
                            className={`pagination-btn ${isActive ? 'active' : ''}`}
                            onClick={() => { gotoPage(i); setPageNO(i); }}
                          >
                            {i + 1}
                          </span>
                        );
                      }

                      if (i === endPage + 1 && endPage < pageCount - 1 && pageCount > PAGE_RANGE_DISPLAYED && pageIndex !== pageCount - 1 && pageIndex !== 0 && pageCount - pageIndex > 1) {
                        return <span key={`ellipsis-end`} className="pagination-btn">...</span>;
                      }

                      return null;
                    })}

                    {pageIndex !== pageCount - 1 && pageIndex !== pageCount - 2 && pageIndex !== 0 && pageCount > PAGE_RANGE_DISPLAYED && (
                      <span className="pagination-btn" onClick={() => { gotoPage(pageCount - 1); setPageNO(pageCount - 1); }}>
                        {pageCount}
                      </span>
                    )}


                    <button
                      type='button'
                      onClick={() => { nextPage(); setPageNO(pageIndex + 1) }} disabled={!canNextPage}
                      className={`btn-next btn-sm btn-falcon-default  ${!canNextPage && 'disabled'
                        }`}
                    >
                      {t('visitorLogout.pagination.paginationbtn')}

                    </button>
                  </div>


                </div>

              )}
            </div>
          </>
        )
      }




      {/* <div className="d-flex flex-wrap align-items-center justify-content-between">
        <div className="d-flex flex-wrap align-items-center">
          {isPageNumberIndecatorRequired && (
            <div className="me-3 d-flex flex-wrap align-items-center">
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
            </div>
          )}
          {isPageSizeDropdownRequired && (
            <div>
              <div className="d-flex flex-wrap align-items-center">
                <div className="me-2">Page size : </div>
                <div>
                  <select
                    className="form-select"
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {pageSizeOption.map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
        {!canPreviousPage && !canNextPage && isPaginationRequired ? null : (
          <div className="d-flex flex-wrap">
            <div
              onClick={() => gotoPage(0)}
              className={`px-3 py-2 border rounded pagination-btn ${!canPreviousPage && 'disabled'
                }`}
              title="Go to first page"
            >
              <span>{'<<'}</span>
            </div>{' '}
            <div
              onClick={() => previousPage()}
              className={`px-3 py-2 border rounded pagination-btn ${!canPreviousPage && 'disabled'
                }`}
              title="Go to previous page"
            >
              <span>{'<'}</span>
            </div>{' '}
            <div
              onClick={() => nextPage()}
              className={`px-3 py-2 border rounded pagination-btn ${!canNextPage && 'disabled'
                }`}
              title="Go to next page"
            >
              <span>{'>'}</span>
            </div>{' '}
            <div
              onClick={() => gotoPage(pageCount - 1)}
              className={`px-3 py-2 border rounded pagination-btn ${!canNextPage && 'disabled'
                }`}
              title="Go to last page"
            >
              <span>{'>>'}</span>
            </div>
          </div>
        )}
      </div> */}
    </div >
  );
}
ReactTables.defaultProps = {
  pageSizeOption: [5, 10, 15, 20],
  isPaginationRequired: true,
  isPageNumberIndecatorRequired: true,
  isPageSizeDropdownRequired: true,
  isSearchBoxVisible: true,
  ispaginationvisible: true,
};
