import { guestRegistrationEndPoints } from "./config"
import httpServices from "./httpServices"

export const getDestinationList = async () => {
    return await httpServices.get(guestRegistrationEndPoints.getDestinations)
}
export const registerMultipleGuests = async (guestData) => {
    return await httpServices.post(guestRegistrationEndPoints.registerGuest, guestData)
}
export const registerSingleGuests = async (guestData) => {
    return await httpServices.post(guestRegistrationEndPoints.registerSingleGuest, guestData)
}

export const getGuestRegistrationOTP = async (guestNoOrEmail) => {
    return await httpServices.post(guestRegistrationEndPoints.guestRegistrationOtp, guestNoOrEmail)
}
export const bypassadminotp = async (data) => {
    return await httpServices.post(guestRegistrationEndPoints.sendbypassotp, data)
}
export const getGuestRegistrationOTPbyDoc = async (data) => {
    return await httpServices.post(guestRegistrationEndPoints.sendotpbyguestdocument, data)
}
export const getGuestRegistrationVerifyOTPbyDoc = async (data) => {
    return await httpServices.post(guestRegistrationEndPoints.verifysendbypassotp, data)
}

export const getListOfIdentificationType = async () => {
    return await httpServices.get(guestRegistrationEndPoints.identificationTypeList)
}
export const getMemberVerify = async (MemberData) => {
    return await httpServices.post(guestRegistrationEndPoints.getMemberDetails, MemberData)
}
export const checkguestentry = async (data) => {
    return await httpServices.post(guestRegistrationEndPoints.checkvalidguest, data)
}