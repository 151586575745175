import React from 'react';
import DatePicker from 'react-datepicker';
import { setMinutes, setHours, getMonth, getYear } from 'date-fns';


export default function ReactTimePicker({
  value,
  onChange,
  hasErrors,
  placeholder,
  disabled,
  dateFormat,
  minTime,
  maxTime,
  isclockshow
}) {

  return (
    <div className="position-relative">
      <DatePicker
        selected={value}
        onChange={e => onChange(e)}
        className={`form-control col-12 ${hasErrors && 'is-invalid'}`}
        timeIntervals={15}
        dateFormat={dateFormat}
        showTimeSelect
        showTimeSelectOnly
        value={value}
        placeholderText={placeholder}
        disabled={disabled}
        minTime={minTime}
        // minTime={setHours(setMinutes(new Date(), minTime.minute), minTime.hour)}
        maxTime={setHours(setMinutes(new Date(), maxTime.minute), maxTime.hour)}
        onKeyDown={e => {
          e.preventDefault();
        }}
        portalId="root-portal"
      />
      <span className={`form-icon ${hasErrors && 'me-4'}`}>
        {isclockshow && (
          <svg
            className="svg-inline--fa fa-clock fa-w-16"
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="clock"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
            ></path>
          </svg>
        )}

      </span>
    </div>
  );
}
ReactTimePicker.defaultProps = {
  disabled: false,
  dateFormat: 'h:mm aa',
  hasErrors: false,
  minTime: { hour: 0, minute: 0 },
  maxTime: { hour: 23, minute: 59 },
  isclockshow: true,
};
