import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck'
import React, { useEffect } from 'react'

export default function Dashboard2() {
    useEffect(() => {
        checkModulePermission("Dashboard")
    }, [])
    return (
        <div className="card overflow-hidden mb-3">
            <div className="card-header">
                Dashboard
            </div>
        </div>
    )
}
