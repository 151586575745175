import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthSimpleLayout from './AuthSimpleLayout';
import is from 'is_js';
import MainLayout from './MainLayout';
import About from '../components/pages/about/About';
import Home from '../components/pages/home/Home';
import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import FontAwesome from 'components/doc-components/FontAwesome';
import Calendar from 'components/app/calendar/Calendar';
import ReactIcons from 'components/doc-components/ReactIcons';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import SimpleLogin from 'components/authentication/simple/Login';
import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword';
import SimplePasswordReset from 'components/authentication/simple/PasswordReset';
import Dashboard from 'components/dashboards/default';
import AppContext from 'context/Context';
import HomepageLayout from './HomepageLayout';
import VerifyOtp from 'components/authentication/simple/VerifyOtp';
import DepartmentMaster from 'components/pages/master/departmentMaster/DepartmentMaster';
import Appointment from 'components/pages/Appointment/Appointment';
import CreateAppointment from 'components/pages/Appointment/CreateAppointment';
import AuthenticateRoute from 'components/common/AuthenticateRoute';
import AuthenticateAuthRoute from 'components/common/AuthenticateAuthRoute';
import Profile from 'components/pages/profile/Profile';
import EventsApproval from 'components/pages/manageevents/eventsApproval/EventsApproval';
import SingleBooking from 'components/pages/adminbookings/SingleBooking';
import EventsBooking from 'components/pages/adminbookings/EventsBooking';
import SummaryReport from 'components/pages/reports/SummaryReport';
import QrCodeCounterReport from 'components/pages/reports/QrCodeCounterReport';
import BlackListReport from 'components/pages/reports/BlackListReport';
import BookingsReport from 'components/pages/reports/BookingsReport';
import BypassReport from 'components/pages/reports/BypassReport';
import ChangelogsReport from 'components/pages/reports/ChangelogsReport';
import EventsReport from 'components/pages/reports/eventreport/EventsReport';
import SOSReport from 'components/pages/reports/sosreport/SOSReport';
import TimesvisitReport from 'components/pages/reports/timevisitreport/TimesvisitReport';
import VisitordetailReport from 'components/pages/reports/VisitordetailReport';
import VisitsReport from 'components/pages/reports/VisitsReport';
import EmailSettings from 'components/pages/applicationsettings/EmailSettings';
import Managefrontdesk from 'components/pages/applicationsettings/Managefrontdesk';
import ParameterSettings from 'components/pages/applicationsettings/ParameterSettings';
import SMSSettings from 'components/pages/applicationsettings/SMSSettings';
import WhatsappSettings from 'components/pages/applicationsettings/WhatsappSettings';
import SosMessage from 'components/pages/admin/sosmessage/SosMessage';
import ListBlackVisitor from 'components/pages/admin/blacklistvisitor/ListBlackVisitor';
import SupplierContractors from 'components/pages/admin/suppliercontractors/SupplierContractors';
import CasualWorkers from 'components/pages/admin/casualworkers/CasualWorkers';
import UserrolesMaster from 'components/pages/master/userrolesmaster/UserrolesMaster';
import EditUserrolesMaster from 'components/pages/master/userrolesmaster/EditUserrolesMaster';
import AccessControll from 'components/pages/master/accesscontrol/AccessControl';
import BookAGuest from 'components/pages/manageevents/bookAGuest/BookAGuest';
import CreateEvent from 'components/pages/manageevents/createEvent/CreateEvent';
import EventLocations from 'components/pages/manageevents/eventLocation/EventLocations';
import GuestSummary from 'components/pages/reports/GuestSummary';
import GuestVisitReport from 'components/pages/reports/GuestVisitReport';
import GuestBookingReport from 'components/pages/reports/GuestBookingReport';
import VisitHoursExceeded from 'components/pages/reports/VisitHoursExceededReport';
import BackupManagement from 'components/pages/admin/backupmanagement/BackupManagement';
import SystemUsers from 'components/pages/admin/systemusers/SystemUsers';
import BroadCastMessage from 'components/pages/admin/broadcastmessage/BroadCastMessage';
import MessageTemplate from 'components/pages/admin/messagetemplate/MessageTemplate';
import CreateSystemUser from 'components/pages/admin/systemusers/CreateSystemUser';
import CreateSupplierContractor from 'components/pages/admin/suppliercontractors/CreateSupplierContractor';
import EditSystemUser from 'components/pages/admin/systemusers/EditSystemUser'
import EditSupplierContractor from 'components/pages/admin/suppliercontractors/EditSupplierContractor';
import EditCasualWorkers from 'components/pages/admin/casualworkers/EditCasualWorkers'
import CreateLocation from 'components/pages/manageevents/eventLocation/CreateLocation';
import EditLocation from 'components/pages/manageevents/eventLocation/EditLocation';
import GuestBooking from 'components/pages/guestbooking/GuestBooking';
import EventBooking from 'components/pages/eventbooking/EventBooking';
import EditAppointment from 'components/pages/Appointment/EditAppointment';
import UiLayout from './UiLayout';
import EventView from 'components/pages/manageevents/eventsApproval/EventView';
import AddAccessMapping from 'components/pages/master/accessmapping/AddAccessMapping';
import AccessMapping from 'components/pages/master/accessmapping/AccessMapping';
import EditAccessMapping from 'components/pages/master/accessmapping/EditAccessMapping';
import AddAccessControl from 'components/pages/master/accesscontrol/AddAccessControl';
import EditAccessControl from 'components/pages/master/accesscontrol/EditAccessControl';
import Error403 from 'components/errors/Error403';
import Loader from 'components/common/Loader';
import { loaderContext } from 'context/Context';
import BookingEvent from 'components/pages/manageevents/EventBooking/BookingEvent';
import Dashboard2 from 'components/dashboards/default/Dashboard2';
import CreatDepartment from 'components/pages/master/departmentMaster/CreateDepartment';
import EditDepartment from 'components/pages/master/departmentMaster/EditDepartment';
import FrontDeskLayout from './FrontDeskLayout';
import GuestRegistration from 'components/pages/guestregistration/GuestRegistration';
import VisitorLogout from 'components/pages/visitorLogout/VisitorLogout';
import VisitorAppointments from 'components/pages/visitorappointments/VisitorAppointments';
import Bookings from 'components/pages/bookings/Bookings';
import Events from 'components/pages/events/Events';
import VisitorRegistration from 'components/pages/visitorRegistration/VisitorRegistration';
import Aide from 'components/pages/admin/aide/Aide';
import EditAide from 'components/pages/admin/aide/EditAide';
import EditBookAGuest from 'components/pages/manageevents/bookAGuest/EditBookAGuest';
import EditEvent from 'components/pages/manageevents/eventsApproval/EditEvent';
import FullGuestBooking from 'components/pages/fullguestbooking.js/FullGuestBooking';
import Member from 'components/pages/admin/member/Member';
import EditMember from 'components/pages/admin/member/EditMember';


const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const isUserGuard = localStorage.getItem("isGuard")
  console.log("ISGUARD========>", isUserGuard === "true")
  const [isLoading, setIsLoading] = useState(false)
  const [memberNumber, setMemberNumber] = useState("")
  useContext(AppContext);
  const getFormatedPermission = (permissionArray) => {
    let finalPermission = [];
    permissionArray?.forEach((per) => {
      if (Array.isArray(per)) {
        per.forEach((permission) => {
          finalPermission.push(permission)
        })
      } else {
        finalPermission.push(per)
      }
    })
    return finalPermission
  }
  const permissionArray = JSON.parse(localStorage.getItem("userPrmission"))?.permission?.map((per) => {
    if (per?.child?.length <= 0) {
      return per.display_name
    } else {
      return per.child.map((permission) => permission.display_name)
    }
  })
  const newPermission = getFormatedPermission(permissionArray)
  console.log("permissions", newPermission)

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);
  // const allRoutes = [
  //   {
  //     name: "Dashboard",
  //     path: "/dashboard",
  //     element: <Dashboard />
  //   },
  //   {
  //     name: "Department",
  //     path: "/masters/department",
  //     element: <DepartmentMaster />
  //   },
  //   {
  //     name: "User Roles",
  //     path: "/masters/user-roles",
  //     element: <UserrolesMaster />
  //   },
  //   {
  //     name: "User Roles",
  //     path: "/masters/user-roles/edituserrolesmaster",
  //     element: <EditUserrolesMaster />
  //   },
  //   {
  //     name: "Access Mapping",
  //     path: "/masters/access-mapping",
  //     element: <AccessMapping />
  //   },
  //   {
  //     name: "Access Mapping",
  //     path: "/masters/add-access-mapping",
  //     element: <AddAccessMapping />
  //   },
  //   {
  //     name: "Access Mapping",
  //     path: "/masters/edit-access-mapping",
  //     element: <EditAccessMapping />
  //   },
  //   {
  //     name: "Access Control",
  //     path: "/masters/access-control",
  //     element: <AccessControll />
  //   },
  //   {
  //     name: "Access Control",
  //     path: "/masters/edit-access-control",
  //     element: <EditAccessControl />
  //   },
  //   {
  //     name: "Access Control",
  //     path: "/masters/add-access-control",
  //     element: <AddAccessControl />
  //   },
  //   {
  //     name: "System Users",
  //     path: "/admin/system-users",
  //     element: <SystemUsers />
  //   },
  //   {
  //     name: "System Users",
  //     path: "/createsystemuser",
  //     element: <CreateSystemUser />
  //   },
  //   {
  //     name: "System Users",
  //     path: "/editsystemuser",
  //     element: <EditSystemUser />
  //   },
  //   // {
  //   //   name: "Backup Management",
  //   //   path: "/admin/backup-management",
  //   //   element: <BackupManagement />
  //   // },
  //   // {
  //   //   name: "Message Template",
  //   //   path: "/admin/message-template",
  //   //   element: <MessageTemplate />
  //   // },
  //   // {
  //   //   name: "SOS Message",
  //   //   path: "/admin/sos-message",
  //   //   element: <SosMessage />
  //   // },
  //   // {
  //   //   name: "Blacklist Visitor",
  //   //   path: "/admin/listblack-visitor",
  //   //   element: <ListBlackVisitor />
  //   // },
  //   {
  //     name: "Supplier & Contractors",
  //     path: "/admin/supplier-contractor",
  //     element: <SupplierContractors />
  //   },
  //   {
  //     name: "Supplier & Contractors",
  //     path: "/editsuppliercontractor",
  //     element: <EditSupplierContractor />
  //   },
  //   {
  //     name: "Supplier & Contractors",
  //     path: "/create-supplier-contractor",
  //     element: <CreateSupplierContractor />
  //   },
  //   {
  //     name: "Casual Workers",
  //     path: "/admin/casual-workers",
  //     element: <CasualWorkers />
  //   },
  //   {
  //     name: "Casual Workers",
  //     path: "/editcasualworkers",
  //     element: <EditCasualWorkers />
  //   },

  //   {
  //     name: "Book A Guest",
  //     path: "/manageevents/book-guest",
  //     element: <BookAGuest />
  //   },
  //   {
  //     name: "Create Event",
  //     path: "/manageevents/create-event",
  //     element: <CreateEvent />
  //   },
  //   {
  //     name: "Events Approval",
  //     path: "/manageevents/event-approval",
  //     element: <EventsApproval />
  //   },
  //   {
  //     name: "Events Approval",
  //     path: "/manageevents/event-view",
  //     element: <EventView />
  //   },
  //   {
  //     name: "Event Locations",
  //     path: "/manageevents/event-locations",
  //     element: <EventLocations />
  //   },
  //   {
  //     name: "Event Locations",
  //     path: "/manageevents/event-locations/add-location",
  //     element: <CreateLocation />
  //   },
  //   {
  //     name: "Event Locations",
  //     path: "/manageevents/event-locations/edit-location",
  //     element: <EditLocation />
  //   },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "booking/single-booking",
  //   //   element: <SingleBooking />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "booking/events-booking",
  //   //   element: <EventsBooking />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "/appointment",
  //   //   element: <Appointment />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "/appointment/create-appointment",
  //   //   element: <CreateAppointment />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "/appointment/edit-appointment/:id",
  //   //   element: <EditAppointment />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "reports/summary-report",
  //   //   element: <SummaryReport />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "reports/visits-report",
  //   //   element: <VisitsReport />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "reports/bookings-report",
  //   //   element: <BookingsReport />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "reports/events-report",
  //   //   element: <EventsReport />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "reports/blacklist-report",
  //   //   element: <BlackListReport />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "reports/sos-report",
  //   //   element: <SOSReport />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "reports/broadcast-report",
  //   //   element: <BroadcastReport />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "reports/bypass-report",
  //   //   element: <BypassReport />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "reports/visitordetail-report",
  //   //   element: <VisitordetailReport />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "reports/changelogs-report",
  //   //   element: <ChangelogsReport />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "reports/timesvisit-report",
  //   //   element: <TimesvisitReport />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "reports/guest-summary",
  //   //   element: <GuestSummary />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "reports/guest-visitreport",
  //   //   element: <GuestVisitReport />
  //   // },
  //   // {
  //   //   name: "Event Locations",
  //   //   path: "reports/visit-hoursexceeded",
  //   //   element: <VisitHoursExceeded />
  //   // },
  //   {
  //     name: "Email Settings",
  //     path: "/manageevents/event-locations/edit-location",
  //     element: <EditLocation />
  //   },
  //   {
  //     name: "SMS Settings",
  //     path: "/manageevents/event-locations/edit-location",
  //     element: <EditLocation />
  //   },
  //   {
  //     name: "WhatsApp Settings",
  //     path: "/manageevents/event-locations/edit-location",
  //     element: <EditLocation />
  //   },
  //   {
  //     name: "Event Locations",
  //     path: "/manageevents/event-locations/edit-location",
  //     element: <EditLocation />
  //   },
  //   {
  //     name: "Event Locations",
  //     path: "/manageevents/event-locations/edit-location",
  //     element: <EditLocation />
  //   },

  // ]

  return (
    <>
      {isLoading && <Loader />}
      <loaderContext.Provider value={{ isLoading, setIsLoading, memberNumber, setMemberNumber }}>
        <Routes>
          <Route element={<AuthenticateRoute />}>
            <Route element={<ErrorLayout />}>
              <Route path="errors/404" element={<Error404 />} />
              <Route path="errors/500" element={<Error500 />} />
              <Route path="errors/403" element={<Error403 />} />
            </Route>

            {/* //--- MainLayout Starts  */}

            <Route element={<MainLayout />}>

              {/*Profile*/}

              <Route path="user/profile" element={<Profile />} />

              {/*Dashboard*/}

              <Route path="dashboard" element={newPermission.includes('Dashboard') ? <Dashboard /> : <Dashboard2 />} />
              <Route path="about" element={<About />} />
              {/* ---------------------------------------important routes------------------------------------- */}

              {/* masters */}

              <Route path="masters/department-master" element={<DepartmentMaster />} />
              <Route path="masters/department-master/create-department" element={<CreatDepartment />} />
              <Route path="masters/department-master/edit-department" element={<EditDepartment />} />
              <Route path="masters/user-roles" element={<UserrolesMaster />} />
              <Route path="masters/user-roles/edituserrolesmaster" element={<EditUserrolesMaster />} />
              <Route path="masters/access-mapping" element={<AccessMapping />} />
              <Route path="masters/add-access-mapping" element={<AddAccessMapping />} />
              <Route path="masters/edit-access-mapping" element={<EditAccessMapping />} />
              <Route path="masters/access-control" element={<AccessControll />} />
              <Route path="masters/edit-access-control" element={<EditAccessControl />} />
              <Route path="masters/add-access-control" element={<AddAccessControl />} />

              {/* ADMIN */}

              <Route path="admin/system-users" element={<SystemUsers />} />
              <Route path="/createsystemuser" element={<CreateSystemUser />} />
              <Route path="/editsystemuser" element={<EditSystemUser />} />
              <Route path="admin/backup-management" element={<BackupManagement />} />
              <Route path="admin/message-template" element={<MessageTemplate />} />
              <Route path="admin/broadcast-message" element={<BroadCastMessage />} />
              <Route path="admin/sos-message" element={<SosMessage />} />
              <Route path="admin/listblack-visitor" element={<ListBlackVisitor />} />
              <Route path="admin/supplier-contractor" element={<SupplierContractors />} />
              <Route path="/editsuppliercontractor" element={<EditSupplierContractor />} />
              <Route path="/create-supplier-contractor" element={<CreateSupplierContractor />} />
              <Route path="admin/casual-workers" element={<CasualWorkers />} />
              <Route path="/editcasualworkers" element={<EditCasualWorkers />} />
              <Route path="admin/members" element={<Member />} />
              <Route path="/edit-members" element={<EditMember />} />

              {/* members aide added by parth */}
              <Route path="admin/aide-masters" element={<Aide />} />
              <Route path="/editaide" element={<EditAide />} />

              {/* manageEvents */}

              <Route path="manageevents/book-guest" element={<BookAGuest />} />
              <Route path="manageevents/editbook-guest" element={<EditBookAGuest />} />
              <Route path="manageevents/create-event" element={<CreateEvent />} />
              <Route path="manageevents/event-approval" element={<EventsApproval />} />
              <Route path="manageevents/edit-event" element={<EditEvent />} />
              <Route path="manageevents/event-view" element={<EventView />} />
              <Route path="manageevents/event-locations" element={<EventLocations />} />
              <Route path="manageevents/event-locations/add-location" element={<CreateLocation />} />
              <Route path="manageevents/event-locations/edit-location" element={<EditLocation />} />
              <Route path="manageevents/event-booking" element={<BookingEvent />} />

              {/* Booking */}

              {/* <Route path="booking/single-booking" element={<SingleBooking />} />
            <Route path="booking/events-booking" element={<EventsBooking />} /> */}

              {/* appointment */}

              <Route path="/appointment" element={<Appointment />} />
              <Route path="/appointment/create-appointment" element={<CreateAppointment />} />
              <Route path="/appointment/edit-appointment" element={<EditAppointment />} />

              {/* reports */}

              <Route path="reports/summary-report" element={<SummaryReport />} />
              <Route path="reports/visits-report" element={<VisitsReport />} />
              <Route path="reports/visitor-appointmentreport" element={<BookingsReport />} />
              <Route path="reports/events-report" element={<EventsReport />} />
              <Route path="reports/blacklist-report" element={<BlackListReport />} />
              <Route path="reports/sos-report" element={<SOSReport />} />
              {/* <Route path="reports/broadcast-report" element={<BroadcastReport />} /> */}
              <Route path="reports/bypass-report" element={<BypassReport />} />
              <Route path="reports/visitordetail-report" element={<VisitordetailReport />} />
              <Route path="reports/changelogs-report" element={<ChangelogsReport />} />
              <Route path="reports/timesvisit-report" element={<TimesvisitReport />} />
              <Route path="reports/guest-summary" element={<GuestSummary />} />
              <Route path="reports/guest-visitreport" element={<GuestVisitReport />} />
              <Route path="reports/guest-bookingreport" element={<GuestBookingReport />} />
              <Route path="reports/visit-hoursexceeded" element={<VisitHoursExceeded />} />
              <Route path="reports/qr-code" element={<QrCodeCounterReport />} />


              {/* applicationsettings */}

              <Route path="applicationsettings/email-settings" element={<EmailSettings />} />
              <Route path="applicationsettings/sms-settings" element={<SMSSettings />} />
              <Route path="applicationsettings/whatsapp-settings" element={<WhatsappSettings />} />
              <Route path="applicationsettings/manage-frontdesk" element={<Managefrontdesk />} />
              <Route path="applicationsettings/parameter-settings" element={<ParameterSettings />} />

              {/*Landing Page */}
              {/* <Route path="/welcome-page" element={<LandingPage />} /> */}

              {/* guest booking */}

              {/* <Route path="booking/upload-event" element={<UploadEventGuest />} />
            <Route path="booking/event-detail" element={<EventDetails />} /> */}

              {/* ----------------------------------------important routes end----------------------------------------- */}

              <Route path="icons/font-awesome" element={<FontAwesome />} />
              <Route path="icons/react-icons" element={<ReactIcons />} />
              {/*App*/}
              <Route path="app/calendar" element={<Calendar />} />
            </Route>

            {/* //--- MainLayout end  */}
            <Route element={<FrontDeskLayout />}>
              <Route path="/guest-registration" element={<GuestRegistration />} />
              <Route path="/visitor-logout" element={<VisitorLogout />} />
              <Route path="/visitor-appointments" element={<VisitorAppointments />} />
              <Route path="/bookings" element={<Bookings />} />
              {/* <Route path="/registerguestbooking" element={<RegisterGuestBooking />} /> */}
              <Route path="/events" element={<Events />} />
              <Route
                path="visitor-registration"
                element={<VisitorRegistration />}
              />
              <Route
                path="visitor-registration/:id"
                element={<VisitorRegistration />}
              />
            </Route>
            {/* <Navigate to="/errors/404" /> */}
            <Route path="*" element={<Navigate to="/errors/404" replace />} />
          </Route>
          {/*- ------------- Authentication ---------------------------  */}

          {/*- ------------- simple ---------------------------  */}
          <Route element={<AuthenticateAuthRoute />}>
            <Route element={<AuthSimpleLayout />}>
              <Route path="login" element={<SimpleLogin />} />
              <Route path="forgot-password" element={<SimpleForgetPassword />} />
              <Route path="reset-password" element={<SimplePasswordReset />} />
              <Route path="verify-otp" element={<VerifyOtp />} />
            </Route>
            <Route element={<HomepageLayout />}>
              <Route path="/" element={<Home />} />
            </Route>
            <Route path="*" element={<Navigate to="/errors/404" replace />} />
          </Route>
          <Route element={<UiLayout />}>
            <Route path="booking/guest-booking/:memberId" element={<GuestBooking />} />
            <Route path="booking/fullguest-booking/:memberId" element={<FullGuestBooking />} />
            <Route path="booking/event-booking/:memberId" element={<EventBooking />} />
          </Route>

        </Routes>
      </loaderContext.Provider>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
