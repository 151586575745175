import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Section from 'components/common/Section';
import Footer from 'components/footer/Footer';
import '../assets/css/authentication.css';

const AuthSimpleLayout = () => (
  <Section className="py-0 login-bg">
    <Row className="flex-center min-vh-100 py-6">
      <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
        <div className="authentication-card">
          <Card.Body className="p-4 p-sm-5">
            <Outlet />
          </Card.Body>
        </div>
      </Col>
      <Footer footerContentColor="text-white" contentPosition="center" />
    </Row>
  </Section>
);

export default AuthSimpleLayout;
