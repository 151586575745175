import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { setMinutes, setHours, getMonth, getYear } from 'date-fns';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ReactDateAndTimePicker({
  value,
  onChange,
  hasErrors,
  placeholder,
  disabled,
  dateFormat,
  timeFormat,
  timeIntervals,
  style,
  showTimeSelect,
  minDate,
  maxDate,
  minTime,
  maxTime,
  selected,
  peekNextMonth,
  showMonthDropdown,
  showYearDropdown,
}) {
  return (
    <>
      <div style={style && style}>
        <DatePicker
          value={value}
          selected={value}
          onChange={e => onChange(e)}
          formatWeekDay={day => day.slice(0, 3)}
          dateFormat={dateFormat}
          className={`form-control col-12 ${hasErrors && 'is-invalid'}`}
          placeholderText={placeholder}
          disabled={disabled}
          minDate={minDate && minDate}
          maxDate={maxDate && maxDate}
          minTime={minTime && minTime}
          maxTime={maxTime && maxTime}
          showTimeSelect={showTimeSelect}
          timeIntervals={timeIntervals}
          timeFormat={timeFormat}
          timeCaption="time"
          onKeyDown={e => {
            e.preventDefault();
          }}
          peekNextMonth={peekNextMonth}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          dropdownMode="select"
          portalId="root-portal"
        />
      </div>
    </>
  );
}
ReactDateAndTimePicker.defaultProps = {
  placeholder: 'dd/mm/yyyy hh:mm',
  dateFormat: 'dd/MM/yyyy h:mm aa',
  timeFormat: 'h:mm aa',
  hasErrors: false,
  showTimeSelect: true,
  minTimeHour: 24,
  minTimeMinute: 59,
  peekNextMonth: false,
  showMonthDropdown: false,
  showYearDropdown: false
};
