import { reportEndPoints } from "./config"
import httpServices from "./httpServices"

export const summaryreportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listsummaryreport, data)
}
export const visitsreportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listvisitreport, data)
}
export const bookingsreportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listbookingreport, data)
}
export const eventsreportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listevent, data)
}
export const blacklistreportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listblacklist, data)
}
export const sosreportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listsos, data)
}
export const bypassreportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listbypass, data)
}
export const visitordetailreportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listvisitordetail, data)
}
export const changelogreportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listchangelog, data)
}
export const times6reportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listtimevisit, data)
}
export const guestsummaryreportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listguestsummary, data)
}
export const guestvisitreportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listguestvisit, data)
}
export const guestbookingreportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listguestbooking, data)
}
export const visithoursreportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listvisithours, data)
}
export const qrreportlist = async (data) => {
    return await httpServices.post(reportEndPoints.listqr, data)
}
export const listnumberofvisit = async () => {
    return await httpServices.get(reportEndPoints.numbervisit)
}