import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import toastConfig from 'components/configuration/toast-config';
import { toast } from 'react-toastify';
import { decodeData } from 'components/common/encodeParams';
import _ from 'lodash'
import { compareData } from 'components/common/helper-function/matchData';
import { trimData } from 'components/common/helper-function/trimBodyData';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { tableName } from 'components/configuration/common-options';
import { useLocation } from 'react-router-dom';
import { addlocation } from 'services/manageEventsService'
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import pattern from 'components/configuration/form-pattern';


export default function EditLocation() {
    const [statusOption, setStatusOption] = useState([{ label: "Active", value: "Active" }, { label: "Inactive", value: "Inactive" }])
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();
    const location = useLocation();
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        clearErrors,
        formState: { errors }
    } = useForm();

    console.log("location", location)

    useEffect(() => {
        checkModulePermission("Event Locations")
        const documentData = location?.state?.data
        setValue('name', documentData.name)
        setValue("status", statusOption.find((status) => status.value == documentData.status))

    }, [])

    const onSubmit = async (editData) => {
        console.log(editData)

        const eventData = location?.state?.data
        setIsLoading(true)
        try {
            editData.status = editData.status.label
            const { data } = await addlocation({ is_update: 1, ...eventData, ...editData, });
            toast.success(data?.message, toastConfig)
            navigate('/manageevents/event-locations')
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    };

    const resetValue = () => {
        reset();
        setValue("status", "")

    };

    return (
        <>
            <div className="row g-3 mb-3">
                <div className="col-xxl-12">
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="heading-color fw-600 mb-0">
                                        {t('eventLocation.editEventLocation')}
                                    </h3>
                                </div>
                                <div className="col-4 table-btn">
                                    <button
                                        className="btn btn-primary float-end btn-sm"
                                        onClick={() => navigate('/manageevents/event-locations')}
                                    >
                                        <FontAwesomeIcon icon="chevron-left" />
                                        <span className="ms-1">
                                            {t('eventLocation.btnLabel.back')}
                                        </span>
                                    </button>
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                {t('eventLocation.label.eventLocation')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                className={`form-control ${errors.name ? 'is-invalid' : ''
                                                    }`}
                                                type="text"
                                                autoComplete="off"
                                                placeholder={t(
                                                    'eventLocation.placeholder.eventLocation'
                                                )}
                                                {...register('name', { required: true, pattern: pattern.userName })}
                                            />
                                            {errors.name && (
                                                <small className="text-danger">
                                                    {errors.name.type == 'required' && (
                                                        <span>
                                                            {t('eventLocation.error.eventLocationReq')}
                                                        </span>
                                                    )}
                                                    {errors.name.type == 'pattern' && (
                                                        <span>
                                                            {t('eventLocation.error.eventLocationinvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                    </div>


                                    <div className="col-12 col-md-6">
                                        <label className="form-label">
                                            {t('eventLocation.label.status')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <Controller
                                            control={control}
                                            rules={{ required: true }}
                                            name="status"
                                            render={({ field: { onChange, value } }) => (
                                                <SearchableDropdown
                                                    options={statusOption}
                                                    isClearable={false}
                                                    onChange={onChange}
                                                    hasError={errors.status}
                                                    value={value}
                                                    placeholder={t(
                                                        'eventLocation.placeholder.status'
                                                    )}
                                                // noOptionsMessage={() =>
                                                //   t('eventLocation.error.selectCompanyMsg')
                                                // }
                                                />
                                            )}
                                        />
                                        {errors.status && (
                                            <small className="text-danger">
                                                {errors.status.type == 'required' && (
                                                    <span>
                                                        {t('eventLocation.error.statusReq')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>

                                    <div className="col-12 ">
                                        <div className='mb-3 mt-2 text-end'>
                                            <button className="btn btn-primary me-1" type="submit">
                                                {t('eventLocation.btnLabel.update')}
                                            </button>
                                            <button
                                                className="btn btn-danger"
                                                onClick={resetValue}
                                            // onClick={() => navigate('/manageevents/event-locations')}
                                            >
                                                {t('eventLocation.btnLabel.reset')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
