import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from 'components/common/ReactTable';
// import { getVisitors, logOutVisitor } from 'services/frontDeskServices';
import toastConfig from 'components/configuration/toast-config';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { eventdata, eventnestedtabledata } from '../visitorLogout/Config'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getEventList } from 'services/event'
import { getstatus } from 'services/event'
import { loaderContext } from 'context/Context';
import { useContext } from 'react';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { Form, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import formPattern from 'components/configuration/form-pattern';
import { json } from 'is_js';
import './event.css'



export default function Events() {
    var [eventData, setEventData] = useState([]);
    const [render, setRender] = useState(false);
    const [vehicleOpen, setVehicleOpen] = useState(false);
    const [markdata, setMarkData] = useState({});
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useContext(loaderContext)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        setError,
        watch
    } = useForm();

    const getEventData = async () => {
        setIsLoading(true)
        try {
            const { data } = await getEventList();
            setEventData(data.data)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    useEffect(() => {
        checkModulePermission("guard")

        getEventData();
    }, [])



    const handlestatus = async (row) => {
        // console.log("row", row)
        // setIsLoading(true)
        setVehicleOpen(true)
        setMarkData({
            event_id: row.original.event_id,
            event_guest_id: row.original.event_guest_id,
            status: 1,
            row,
        })
        // try {
        //     const { data } = await getstatus({
        //         event_id: row.original.event_id,
        //         event_guest_id: row.original.event_guest_id,
        //         status: 1
        //     })
        //     row.original.guest_arrive_datetime = data?.data[0]?.guest_arrive_datetime
        //     row.original.status = 1;
        //     setRender(!render)
        //     toast.success(data?.message, toastConfig)
        // } catch (e) {
        //     if (e.response.data) {
        //         if (Array.isArray(e.response.data.message)) {
        //             e.response.data.message.map(errors => toast.error(errors, toastConfig))
        //         } else {
        //             toast.error(e.response.data.message, toastConfig)
        //         }
        //     } else {
        //         toast.error(e.message, toastConfig)
        //     }
        // }
        // setIsLoading(false)
    }




    // useEffect(async () => {
    //     try {
    //         const { data } = await getVisitors();
    //         const logInData = data.data.results.filter(data => data.timeOut == null && data.id != dataId);
    //         const addSNo = logInData.map((data, index) => ({
    //             sNo: index + 1,
    //             ...data
    //         }));
    //         setVisitorData(
    //             addSNo.map(
    //                 visitorData => (
    //                     (visitorData.timeIn = moment(
    //                         visitorData.timeIn,
    //                         process.env.REACT_APP_TIME_FORMAT_SET_12 === 'false'
    //                             ? process.env.REACT_APP_TIME_FORMAT_24
    //                             : process.env.REACT_APP_TIME_FORMAT_12
    //                     ).toDate()),
    //                     { ...visitorData }
    //                 )
    //             )
    //         );
    //         console.log('run');
    //     } catch (e) {
    //         toast.error(e.message, toastConfig);
    //     }
    // }, [dataId]);
    // console.log(visitorData);
    // const onLogout = async id => {
    //     try {
    //         const logoutData = {
    //             id: id
    //         };
    //         const { data } = await logOutVisitor(logoutData);
    //         setDataId(id);
    //         toast.success(data.data.result, toastConfig);
    //     } catch (e) {
    //         toast.error(e.message, toastConfig);
    //     }
    // };

    const columns = [
        {
            Header: t('event.id'),
            accessor: (row, index) => index + 1,
            headerClass: 'text-white',
        },
        {
            Header: t('event.dateandtime'),
            accessor: 'date',
            headerClass: 'text-white',
            Cell: ({ row }) => {
                return row?.original?.date ? moment(row?.original?.date).format(process.env.REACT_APP_FRONTDESKLISTING_DATE_TIME) : "--"
            },
        },
        {
            Header: t('event.eventname'),
            accessor: 'name',
            headerClass: 'text-white',

        },
        {
            Header: t('event.member'),
            accessor: 'member_name',
            headerClass: 'text-white',
        },
        {
            Header: t('event.destination'),
            accessor: 'location',
            headerClass: 'text-white',
        },
        {
            Header: t('event.action'),
            headerClass: 'text-white',
            className: 'text-center',
            isExpand: true,
            Cell: ({ row }) => {
                return (<span
                    {...row.getToggleRowExpandedProps()}
                >
                    {row.isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </span>)
            },
            disableSortBy: true,
        }
    ];
    const subColumns = [
        {
            Header: t('nestedtable.no'),
            accessor: (row, index) => index + 1,
            headerClass: 'text-white',
        },
        {
            Header: t('nestedtable.name'),
            accessor: 'name',
            headerClass: 'text-white',
            Cell: ({ row }) => <div style={row?.original?.is_blacklist == 1 ? { color: "var(--warning)", fontWeight: 'bold' } : {}}>{row?.original?.name}</div>
        },
        {
            Header: t('nestedtable.timein'),
            accessor: 'guest_arrive_datetime',
            headerClass: 'text-white',
            Cell: ({ row }) => {
                if (row.original.guest_arrive_datetime) {
                    var testDateUtc = moment.utc(row.original.guest_arrive_datetime);
                    var localDate = moment(testDateUtc).local();
                    return moment(localDate).format(process.env.REACT_APP_TIME_FORMAT_24)
                } else {
                    return "--"
                }
            }
        },
        {
            Header: t('nestedtable.vehicleno'),
            accessor: 'vehicle_reg_no',
            headerClass: 'text-white',
            Cell: ({ row }) => {
                return row.original.vehicle_reg_no ? row.original.vehicle_reg_no : '--'
            }
        },

        {
            Header: t('nestedtable.action'),
            accessor: 'status',
            headerClass: 'text-white',
            className: 'text-end',
            Cell: ({ row }) => (
                <>
                    {row.original.status === 1 ? (
                        <p style={{ color: "#939AAC", fontWeight: "bold", margin: "0rem 0rem" }} > {t('nestedtable.arrived')}  </p>
                    ) : <button
                        onClick={() => handlestatus(row)}
                        style={{
                            backgroundColor: "#33664a",
                            color: "white",
                            border: "1px solid green",
                            borderRadius: "3px",

                        }}
                    >
                        {t('nestedtable.markarrived')}
                    </button>}
                </>
            ),
            disableSortBy: true,
        }
    ]

    const renderRowSubComponent = React.useCallback(({ row }) => {
        let index = 1;
        const newGuestData = row.original.guest_list.map((guest) => { guest.no = index; index++; return guest })
        return (
            <div className="bg-img card nested-table-body overflow-hidden" >
                <ReactTable
                    columns={subColumns}
                    data={newGuestData ? newGuestData : []}
                    headerClass="btn-primary-frontdesk"
                    innerTableSearch="innertable"
                    hedingName="nestedtable.tableheading"
                    showHeader={false}
                />
            </div>
        )
    }, [render])

    const handleCloseModal = () => {
        setValue("vehicle_reg_no", "")
        setVehicleOpen(false)
    }
    const onSubmit = async (vehdata) => {
        console.log("vehdata", vehdata)
        console.log("==><==markdata", markdata)
        const { row, ...restData } = markdata
        setIsLoading(true);
        try {

            const { data } = await getstatus({ ...restData, ...vehdata })
            row.original.guest_arrive_datetime = data?.data[0]?.guest_arrive_datetime
            row.original.status = 1;
            row.original.vehicle_reg_no = data?.data[0]?.vehicle_reg_no
            setRender(!render)
            toast.success(data?.message, toastConfig)
            handleCloseModal();
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false);
    }





    return (
        <>
            <div className="container-fluid event-mainn" >
                <div className=" bg-img card table-body  overflow-hidden" >
                    <ReactTable
                        columns={columns}
                        data={eventData}
                        headerClass="btn-primary-frontdesk"
                        hedingName="event.tableheading"
                        isSearchBoxVisibleFrontdesk={false}
                        isSearchBoxVisible={false}
                        ispaginationvisible={true}
                        widthClass="table-width-frontdesk"
                        RenderRowSubComponent={
                            renderRowSubComponent
                        }
                        showHeader={true}
                    />

                </div>

                {vehicleOpen && (
                    <Modal show={vehicleOpen} onHide={handleCloseModal} >
                        <Modal.Header className="heading-color-frontdesk fw-600 guestregistermodalheader" >{t('event.vehheading')}
                            <i closeButton className="fa  fa-times-circle modal-close-btn" aria-hidden="true" onClick={handleCloseModal}></i>
                        </Modal.Header>
                        <Modal.Body className='modalbody'>
                            <Form action='post' onSubmit={handleSubmit(onSubmit)}>
                                <div className="row g-3 mb-3">
                                    <div className='table-body'>
                                        <div className="overfolw-hidden mb-3">
                                            <div className="card-body pt-0" style={{ marginTop: "-2rem" }}>
                                                <div className='gap-2' style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                                    <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                                        <label className="form-label">
                                                            {t('event.label.vehicleno')}
                                                            <span className="text-danger ms-1">*</span>
                                                        </label>
                                                        <input
                                                            className={`form-control ${errors.vehicle_reg_no && 'is-invalid'
                                                                }`}
                                                            type="text"
                                                            {...register('vehicle_reg_no', { required: true, pattern: formPattern.notAllowSpaceInStartfront })}
                                                        />
                                                        {errors.vehicle_reg_no && (
                                                            <small>
                                                                {errors.vehicle_reg_no.type === 'required' && (
                                                                    <span className="text-danger">
                                                                        {t('event.error.vehicleno')}
                                                                    </span>
                                                                )}
                                                                {errors.vehicle_reg_no.type === 'pattern' && (
                                                                    <span className="text-danger">
                                                                        {t('event.error.vehiclenoinvalid')}
                                                                    </span>
                                                                )}
                                                            </small>
                                                        )}
                                                    </div>

                                                    <div className='front-event-btn'>
                                                        <button
                                                            className="btn btn-primary-frontdesk btn-lg"
                                                            type="submit"
                                                        >
                                                            {t('bookings.label.submit')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                    </Modal>
                )}
            </div>
        </>
    );
}
