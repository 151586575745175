export default {
  confirmation: {
    showCancelButton: true,
    confirmButtonColor: 'var(--primary)',
    cancelButtonColor: 'var(--danger)'
  },
  simple: {
    confirmButtonColor: 'var(--primary)'
  }
};
