import { manageEventsEndPoints } from "services/config"
import httpServices from "services/httpServices"




export const getlocation = async () => {
    return await httpServices.get(manageEventsEndPoints.listlocation)
}
export const getlocationactive = async () => {
    return await httpServices.get(manageEventsEndPoints.listlocationactive)
}
export const deletelocation = async (id) => {
    return await httpServices.post(manageEventsEndPoints.locationdel, id)
}
export const addlocation = async (eventdata) => {
    return await httpServices.post(manageEventsEndPoints.addeventlocation, eventdata)
}


export const geteventlist = async () => {
    return await httpServices.get(manageEventsEndPoints.listevent)
}
export const eventlistapprove = async (approveeventdata) => {
    return await httpServices.post(manageEventsEndPoints.approveevent, approveeventdata)
}
export const createevent = async (eventData) => {
    return await httpServices.post(manageEventsEndPoints.createevent, eventData)
}
export const singleguestadd = async (guestData) => {
    return await httpServices.post(manageEventsEndPoints.addsingleguest, guestData)
}
export const guestdelete = async (id) => {
    return await httpServices.post(manageEventsEndPoints.deleteguest, id)
}
export const deleteevent = async (id) => {
    return await httpServices.post(manageEventsEndPoints.deleteevent, id)
}
export const editevent = async (editeventData) => {
    return await httpServices.post(manageEventsEndPoints.editevent, editeventData)
}


export const bookaguest = async (guestData) => {
    return await httpServices.post(manageEventsEndPoints.bookaguest, guestData)
}
export const editbookaguest = async (editguestData) => {
    return await httpServices.post(manageEventsEndPoints.editbookguest, editguestData)
}
export const deletebookaguest = async (id) => {
    return await httpServices.post(manageEventsEndPoints.deletebookguest, id)
}
export const listbookaguest = async () => {
    return await httpServices.post(manageEventsEndPoints.listbookguest)
}
export const listidenti = async () => {
    return await httpServices.get(manageEventsEndPoints.listidentification)
}
