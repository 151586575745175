import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';
import ReactTable from 'components/common/ReactTable';
import { useForm, Controller } from 'react-hook-form';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactDatePicker from 'components/common/ReactDatePicker';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import './report.css'
import { changelogreportlist } from 'services/reportsService'
import { loaderContext } from 'context/Context';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import moment from 'moment';





function ChangelogsReport() {

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [changelogdata, setChangeLogData] = useState([]);
    const [menuSubMenuList, setMenuSubmenuList] = useState([]);
    const [menuList, setMenuList] = useState([]);
    const [submenuList, setSubmenuList] = useState([]);
    const [menuOption, setMenuOption] = useState([{ label: "menu1", value: "menu1" }, { label: "menu2", value: "menu2" }]);
    const [submenuOption, setSubMenuOption] = useState([{ label: "submenu1", value: "submenu1" }, { label: "submenu2", value: "submenu2" }]);
    const [actionOption, setActionOption] = useState([{ label: "Update", value: "Update" }, { label: "Insert", value: "Insert" }, { label: "Delete", value: "Delete" }]);

    const getFormatedPermission = () => {
        let menues = [];
        JSON.parse(localStorage.getItem("userPrmission"))?.permission?.map((per) => {
            if (per.name != "dashboard" && per.name != "reports") {
                menues.push({
                    menu: per.display_name,
                    subMenus: per?.child ? per?.child : []
                })
            }
        })
        return menues;
    }

    useEffect(async () => {
        checkModulePermission("Change Logs Report")
        setIsLoading(true)
        try {
            const { data } = await changelogreportlist({})
            const newPermission = getFormatedPermission()
            setMenuSubmenuList(newPermission)
            setMenuList(newPermission.map((permission) => ({ label: permission?.menu, value: permission?.menu })))
            setChangeLogData(data?.data)
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }, [])


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        watch
    } = useForm();
    const menu = watch("menu");
    useEffect(() => {
        if (menu) {
            const submenu = menuSubMenuList.find((m) => m.menu == menu.value).subMenus
            setSubmenuList(submenu.map((sub) => ({ label: sub?.display_name, value: sub.display_name })))
        }
    }, [menu])

    const columns = [

        {
            Header: t('changelogreport.changelogreporttable.module'),
            accessor: 'module_name',
            Cell: ({ row }) => {
                return row?.original?.module_name ? row?.original?.module_name : "--"
            }
        },
        {
            Header: t('changelogreport.changelogreporttable.submodule'),
            accessor: 'sub_module_name',
            Cell: ({ row }) => {
                return row?.original?.sub_module_name ? row?.original?.sub_module_name : "--"
            }
        },
        {
            Header: t('changelogreport.changelogreporttable.action'),
            accessor: 'action_name',
            Cell: ({ row }) => {
                return row?.original?.action_name ? row?.original?.action_name : "--"
            }
        },
        {
            Header: t('changelogreport.changelogreporttable.column'),
            accessor: 'column_name',
            Cell: ({ row }) => {
                return row?.original?.column_name ? row?.original?.column_name : "--"
            }
        },
        {
            Header: t('changelogreport.changelogreporttable.oldrecord'),
            accessor: 'old_records',
            Cell: ({ row }) => {
                return row?.original?.old_records ? row?.original?.old_records : '--'
            }
        },
        {
            Header: t('changelogreport.changelogreporttable.newrecord'),
            accessor: 'new_records',
            Cell: ({ row }) => {
                return row?.original?.new_records ? row?.original?.new_records : "--"
            }
        },
        {
            Header: t('changelogreport.changelogreporttable.changedby'),
            accessor: 'changed_by',
            Cell: ({ row }) => {
                return row?.original?.changed_by ? row?.original?.changed_by : "--"
            }
        },
        {
            Header: t('changelogreport.changelogreporttable.date'),
            accessor: 'date',
            Cell: ({ row }) => {
                return row?.original?.date ? moment.utc(row?.original?.date).local().format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : "--"
            }
        },
    ];

    const onSubmit = async (data) => {
        setIsLoading(true)
        if (data?.action || data?.menu || data?.sub_menu) {
            try {
                data.action = data?.action?.value
                data.menu = data?.menu?.value
                data.sub_menu = data?.sub_menu?.value
                const { data: reportData } = await changelogreportlist(data)
                setChangeLogData(reportData?.data)
            } catch (error) {
                errorMessage(error)
            }
        }

        setIsLoading(false)
    };

    const downloadPDF = () => {
        const docDefinition = {
            pageOrientation: 'landscape',
            pageSize: { width: 900, height: 600 },
            content: [
                { text: 'User Data', style: 'header' },
                { text: '\n' },
                {
                    table: {
                        headerRows: 1,
                        widths: Array.from({ length: columns.length }, () => 'auto'),
                        body: [
                            columns.map((column) => column.Header),
                            ...changelogdata.map((row) => columns.map((column) => {
                                if (column.accessor === 'date') {
                                    return row[column.accessor] && row[column.accessor] != "" ? moment.utc(row[column.accessor]).local().format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : '--';
                                } else {
                                    return row[column.accessor] !== undefined ? row[column.accessor] : '';
                                }
                            })),
                        ],
                    },
                },
            ],
            defaultStyle: {
                actionHeader: false,
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                },
            },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.download('changelog_data.pdf');
    };

    const downloadCSV = () => {
        const csvData = [
            columns.map((column) => column.Header),
            ...changelogdata.map((row) => columns.map((column) => {
                if (column.accessor === 'date') {
                    return row[column.accessor] && row[column.accessor] != "" ? moment.utc(row[column.accessor]).local().format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : '--';
                } else {
                    return row[column.accessor] !== undefined ? row[column.accessor] : '';
                }
            })),
        ]
            .map((row) => row.join(','))
            .join('\n');

        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'changelog_data.csv');
    };


    return (
        <div className="row g-3 mb-3">
            {/* <div className=""> */}
            <div className="card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-9 data-table" style={{ height: "fit-content" }}>
                <div className="card-header">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-4 heading-text">
                            <h3 className="heading-color fw-600 mb-0">
                                {t('changelogreport.heading')}
                            </h3>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-8 table-btn" >
                            <button
                                className="btn btn-primary btn-sm float-end"
                                onClick={() => downloadPDF()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline ">
                                    {t('changelogreport.exportpdfbtn')}
                                </span>
                            </button>

                            <button
                                className="btn mx-1 btn-primary btn-sm float-end"
                                onClick={() => downloadCSV()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline ">
                                    {t('changelogreport.exportcsvbtn')}
                                </span>
                            </button>
                        </div>

                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <ReactTable
                        columns={columns}
                        data={changelogdata}
                        pageSizeOption={[5, 10, 15, 20]}
                        headerClass="btn-primary"
                        isSearchBoxVisible
                        widthClass="table-width"
                        ispaginationvisible={true}
                    />
                </div>
            </div>


            <div className='card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-2 filter-table' style={{ padding: "0rem .8rem 0rem .8rem", height: "fit-content" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row' style={{ backgroundColor: "rgb(247, 247, 247)", paddingTop: ".8rem", height: "3rem", color: "black", marginBottom: '1rem' }}>
                        <span>{t('changelogreport.filterheading')}</span>
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('changelogreport.filterform.menu')}
                        </label>
                        <Controller
                            name="menu"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={menuList}
                                    hasError={errors.menu}
                                // placeholder={t('changelogreport.placeholder.idtype')}
                                />
                            )}
                        />
                        {/* {errors.menu && (
                            <small>
                                {errors.menu.type === 'required' && (
                                    <span className="text-danger">
                                        {t('changelogreport.error.menu')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('changelogreport.filterform.submenu')}
                        </label>
                        <Controller
                            name="sub_menu"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={submenuList}
                                    hasError={errors.sub_menu}
                                    noOptionsMessage={menu ? () => t('changelogreport.placeholder.noOptions') : () => t('changelogreport.placeholder.selectmenuFirst')}
                                    placeholder={t('changelogreport.placeholder.submenu')}
                                />
                            )}
                        />

                        {/* {errors.submenu && (
                            <small>
                                {errors.submenu.type === 'required' && (
                                    <span className="text-danger">
                                        {t('changelogreport.error.submenu')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('changelogreport.filterform.action')}
                        </label>
                        <Controller
                            name="action"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={actionOption}
                                    hasError={errors.action}
                                    placeholder={t('changelogreport.placeholder.action')}
                                />
                            )}
                        />

                        {/* {errors.action && (
                            <small>
                                {errors.action.type === 'required' && (
                                    <span className="text-danger">
                                        {t('changelogreport.error.action')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>

                    <div className="text-start col-12">
                        <div className="mb-3 mt-4 text-start">
                            <button type="submit" className="col-lg-12 btn btn-primary me-1">
                                {t('changelogreport.filterform.filterbtn')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {/* </div> */}
        </div>
    )
}

export default ChangelogsReport