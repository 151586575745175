import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export default function AuthenticateRoute() {
  const authToken = localStorage.getItem('authToken');

  return authToken && authToken != 'undefined' ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
}
