import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import SearchableDropdown from 'components/common/SearchableDropdown';
import '../../../../assets/css/searchable-dropdown.css';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { addlocation } from 'services/manageEventsService'
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import pattern from 'components/configuration/form-pattern';


export default function CreateLocation() {
    const [statusOption, setStatusOption] = useState([{ label: "Active", value: "Active" }, { label: "Inactive", value: "Inactive" }])
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        clearErrors,
        formState: { errors }
    } = useForm();
    const { t } = useTranslation();
    useEffect(() => {
        checkModulePermission("Event Locations")
    }, [])

    const onSubmit = async (locationData) => {
        setIsLoading(true)
        try {
            locationData.status = locationData.status.label;
            const { data } = await addlocation({ is_update: 0, ...locationData })
            toast.success(data?.message, toastConfig)
            setValue("name", '')
            setValue("status", '')
            navigate('/manageevents/event-locations')
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
        console.log(locationData)
    };
    const resetValue = () => {
        reset()
        setValue("name", '')
        setValue("status", '')
        // navigate('/manageevents/event-locations')
    };

    return (
        <>
            <div className="row g-3 mb-3">
                <div className="">
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="heading-color fw-600">
                                        {t('eventLocation.addEventLocation')}
                                    </h3>
                                </div>
                                <div className="col-4 table-btn">
                                    <button
                                        className="btn btn-primary float-end btn-sm"
                                        onClick={() => navigate('/manageevents/event-locations')}
                                    >
                                        <FontAwesomeIcon icon="chevron-left" />
                                        <span className="ms-1">
                                            {t('eventLocation.btnLabel.back')}
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                {t('eventLocation.label.eventLocation')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                className={`form-control ${errors.name ? 'is-invalid' : ''
                                                    }`}
                                                type="text"
                                                placeholder={t(
                                                    'eventLocation.placeholder.eventLocation'
                                                )}
                                                {...register('name', { required: true, pattern: pattern.userName })}
                                            />
                                            {errors.name && (
                                                <small className="text-danger">
                                                    {errors.name.type == 'required' && (
                                                        <span>
                                                            {t('eventLocation.error.eventLocationReq')}
                                                        </span>
                                                    )}
                                                    {errors.name.type == 'pattern' && (
                                                        <span>
                                                            {t('eventLocation.error.eventLocationinvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                {t('eventLocation.label.status')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                control={control}
                                                rules={{ required: true }}
                                                name="status"
                                                render={({ field: { value, onChange } }) => (
                                                    <SearchableDropdown
                                                        options={statusOption}
                                                        isClearable={false}
                                                        onChange={onChange}
                                                        hasError={errors.status}
                                                        placeholder={t(
                                                            'eventLocation.placeholder.status'
                                                        )}
                                                        value={value}
                                                    // noOptionsMessage={() =>
                                                    //   t('eventLocation.error.selectCompanyMsg')
                                                    // }
                                                    />
                                                )}
                                            />
                                            {errors.status && (
                                                <small className="text-danger">
                                                    {errors.status.type == 'required' && (
                                                        <span>
                                                            {t('eventLocation.error.statusReq')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                    </div>

                                    <div className="text-start col-12">
                                        <div className="mb-3 text-end">
                                            <button type="submit" className="btn btn-primary me-1">
                                                {t('eventLocation.btnLabel.add')}
                                            </button>
                                            <button
                                                type="reset"
                                                className="btn btn-danger"
                                                onClick={resetValue}
                                            >
                                                {t('eventLocation.btnLabel.reset')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
