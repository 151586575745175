import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import toastConfig from 'components/configuration/toast-config';
import { toast } from 'react-toastify';
import { decodeData } from 'components/common/encodeParams';
import _ from 'lodash'
import { compareData } from 'components/common/helper-function/matchData';
import { trimData } from 'components/common/helper-function/trimBodyData';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { tableName } from 'components/configuration/common-options';
import { departmentadd } from 'services/masterModuleService'
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import pattern from 'components/configuration/form-pattern';

export default function EditDepartment() {
  // const [editDepartment, setEditDepartment] = useState({});
  const [departmentData, setDepartmentData] = useState({})
  const [statusOption, setStatusOption] = useState([{ label: "Active", value: "Active" }, { label: "Inactive", value: "Inactive" }])
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const params = useParams();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm();
  const location = useLocation();

  console.log("location", location)


  useEffect(() => {
    checkModulePermission("Department")
    const editData = location?.state?.data
    setValue('name', editData?.name)
    setValue('status', statusOption.find((status) => status.value == editData.status))
  }, []);



  const onSubmit = async (editData) => {
    setIsLoading(true)
    try {
      editData.status = editData?.status?.value
      const { data } = await departmentadd({ is_update: 1, id: location?.state?.data?.id, ...editData });
      toast.success(data.message, toastConfig);
      setValue('name', " ")
      setValue('status', " ")
      navigate('/masters/department-master');
    } catch (e) {
      if (e.response.data) {
        if (Array.isArray(e.response.data.message)) {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else {
          toast.error(e.response.data.message, toastConfig)
        }
      } else {
        toast.error(e.message, toastConfig)
      }
    }
    setIsLoading(false)
  };

  const resetValue = () => {
    reset();
    setValue("name", "")
    setValue("status", "")
  };

  return (
    <>
      <div className="row g-3 mb-3">
        <div className="col-xxl-12">
          <div className="card overflow-hidden mb-3">
            <div className="card-header">
              <div className="row">
                <div className="col-8">
                  <h3 className="heading-color fw-600 mb-0">
                    {t('departmentMaster.heading.editHeading')}
                  </h3>
                </div>
                <div className="col-4 table-btn">
                  <button
                    className="btn btn-primary float-end btn-sm"
                    onClick={() => navigate('/masters/department-master')}
                  >
                    <FontAwesomeIcon icon="chevron-left" />
                    <span className="ms-1">
                      {t('departmentMaster.btnLabel.back')}
                    </span>
                  </button>
                </div>
                <div className="col-12">
                  <hr />
                </div>
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">


                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('departmentMaster.formLabel.department')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <input
                        className={`form-control ${errors.name ? 'is-invalid' : ''
                          }`}
                        type="text"
                        autoComplete="off"
                        placeholder={t(
                          'departmentMaster.placeholder.departmentName'
                        )}
                        {...register('name', { required: true, pattern: pattern.notAllowSpaceInStart })}
                      />
                      {errors.name && (
                        <small className="text-danger">
                          {errors.name.type == 'required' && (
                            <span>
                              {t('departmentMaster.error.departmentError')}
                            </span>
                          )}
                          {errors.name.type == 'pattern' && (
                            <span>
                              {t('departmentMaster.error.departmentinvalidError')}
                            </span>
                          )}
                        </small>

                      )}
                    </div>
                  </div>


                  <div className="col-12 col-md-6">
                    <label className="form-label">
                      {t('departmentMaster.formLabel.status')}
                      <span className="text-danger ms-1">*</span>
                    </label>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="status"
                      render={({ field: { onChange, value } }) => (
                        <SearchableDropdown
                          options={statusOption}
                          isClearable={false}
                          onChange={onChange}
                          hasError={errors.status}
                          value={value}
                          placeholder={t(
                            'departmentMaster.placeholder.selectstatus'
                          )}

                        />
                      )}
                    />
                    {errors.status && (
                      <small className="text-danger">
                        {errors.status.type == 'required' && (
                          <span>
                            {t('departmentMaster.error.statusError')}
                          </span>
                        )}
                      </small>
                    )}
                  </div>

                  <div className="col-12 ">
                    <div className='mb-3 text-end'>
                      <button className="btn btn-primary me-1" type="submit">
                        {t('departmentMaster.btnLabel.update')}
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={resetValue}
                      // onClick={() => navigate('/master/department-master')}
                      >
                        {t('departmentMaster.btnLabel.reset')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
