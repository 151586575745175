import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';
import ReactTable from 'components/common/ReactTable';
import { useForm, Controller } from 'react-hook-form';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactDatePicker from 'components/common/ReactDatePicker';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import './report.css'
import { blacklistreportlist } from 'services/reportsService'
import { loaderContext } from 'context/Context';
import moment from 'moment';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { getListOfregister } from 'services/adminmoduleService'





function SummaryReport() {

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const { isLoading, setIsLoading } = useContext(loaderContext)

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [blacklistdata, setBlackListData] = useState([]);
    const [blacklistedbyOption, setBlacklistedByOption] = useState([]);
    // const [dateRange, setDateRange] = useState([null, null]);
    // const [startDate, endDate] = dateRange;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(async () => {
        checkModulePermission("Blacklist Report")
        setIsLoading(true)
        try {
            const { data } = await blacklistreportlist({})
            const { data: register } = await getListOfregister()
            setBlacklistedByOption(register?.data?.map((register) => ({ label: register?.name, value: register?.id })))
            setBlackListData(data?.data)
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }, [])


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        watch
    } = useForm();


    const columns = [

        {
            Header: t('blacklistreport.blacklistreporttable.visitorguestname'),
            accessor: 'visitor_name',
            Cell: ({ row }) => {
                return row?.original?.visitor_name ? row?.original?.visitor_name : "--"
            }
        },
        {
            Header: t('blacklistreport.blacklistreporttable.visitortype'),
            accessor: 'visitor_type',
            Cell: ({ row }) => {
                return row?.original?.visitor_type ? row?.original?.visitor_type : "--"
            }
        },
        {
            Header: t('blacklistreport.blacklistreporttable.mobileno'),
            accessor: 'phone_number',
            Cell: ({ row }) => {
                return row?.original?.phone_number ? `${row?.original?.country_code}-${row?.original?.phone_number}` : '--'
            }
        },
        {
            Header: t('blacklistreport.blacklistreporttable.idno'),
            accessor: 'document_id_number',
            Cell: ({ row }) => {
                return row?.original?.document_id_number ? row?.original?.document_id_number : "--"
            }
        },
        {
            Header: t('blacklistreport.blacklistreporttable.idtype'),
            accessor: 'document_id_type',
            Cell: ({ row }) => {
                return row?.original?.document_id_type ? row?.original?.document_id_type : "--"
            }
        },
        {
            Header: t('blacklistreport.blacklistreporttable.blacklistedon'),
            accessor: 'blacklisted_on',
            Cell: ({ row }) => {
                return row?.original?.blacklisted_on ? moment.utc(row?.original?.blacklisted_on).local().format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : "--"
            },
        },
        {
            Header: t('blacklistreport.blacklistreporttable.blacklistedby'),
            accessor: 'blacklisted_by',
            Cell: ({ row }) => {
                return row?.original?.blacklisted_by ? row?.original?.blacklisted_by : "--"
            }
        },
        {
            Header: t('blacklistreport.blacklistreporttable.reason'),
            accessor: 'reason',
            Cell: ({ row }) => {
                return row?.original?.reason ? row?.original?.reason : "--"
            }
        },

    ];

    const onSubmit = async (blacklistdata) => {
        console.log(blacklistdata);
        setIsLoading(true)

        if (blacklistdata?.blacklisted_by || (startDate && endDate)) {
            try {
                blacklistdata.blacklisted_by = blacklistdata.blacklisted_by?.value
                if (startDate && endDate) {
                    blacklistdata.date_range = `${moment(startDate).format(process.env.REACT_APP_FILTER_DATE)}-${moment(endDate).format(process.env.REACT_APP_FILTER_DATE)}`;
                }
                const { data } = await blacklistreportlist(blacklistdata)
                setBlackListData(data?.data)
            } catch (e) {
                if (e.response.data) {
                    if (Array.isArray(e.response.data.message)) {
                        e.response.data.message.map(errors => toast.error(errors, toastConfig))
                    } else {
                        toast.error(e.response.data.message, toastConfig)
                    }
                } else {
                    toast.error(e.message, toastConfig)
                }
            }
        }
        setIsLoading(false)

    };

    const downloadPDF = () => {
        const docDefinition = {
            pageOrientation: 'landscape',
            pageSize: { width: 700, height: 600 },
            content: [
                { text: 'User Data', style: 'header' },
                { text: '\n' },
                {
                    table: {
                        headerRows: 1,
                        widths: Array.from({ length: columns.length }, () => 'auto'),
                        body: [
                            columns.map((column) => column.Header),
                            ...blacklistdata.map((row) => columns.map((column) => {
                                if (column.accessor === 'blacklisted_on') {
                                    return row[column.accessor] && row[column.accessor] != "" ? moment.utc(row[column.accessor]).local().format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : '--';
                                } else if (column.accessor === 'phone_number') {
                                    const phoneNumber = row['phone_number'];
                                    const countryCode = row['country_code'];
                                    return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
                                } else {
                                    return row[column.accessor] !== undefined ? row[column.accessor] : '';
                                }
                            })),
                        ],
                    },
                },
            ],
            defaultStyle: {
                actionHeader: false,
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                },
            },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.download('blacklist_data.pdf');
    };

    const downloadCSV = () => {
        const csvData = [
            columns.map((column) => column.Header),
            ...blacklistdata.map((row) => columns.map((column) => {
                if (column.accessor === 'blacklisted_on') {
                    return row[column.accessor] && row[column.accessor] != "" ? moment.utc(row[column.accessor]).local().format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : '--';
                } else if (column.accessor === 'phone_number') {
                    const phoneNumber = row['phone_number'];
                    const countryCode = row['country_code'];
                    return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
                } else {
                    return row[column.accessor] !== undefined ? row[column.accessor] : '';
                }
            })),
        ]
            .map((row) => row.join(','))
            .join('\n');

        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'blacklist_data.csv');
    };


    return (
        <div className="row g-3 mb-3">
            {/* <div className=""> */}
            <div className="card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-9 data-table" style={{ height: "fit-content" }}>
                <div className="card-header">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-4 heading-text">
                            <h3 className="heading-color fw-600 mb-0">
                                {t('blacklistreport.heading')}
                            </h3>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-8 table-btn" >
                            <button
                                className="btn btn-primary btn-sm float-end"
                                onClick={() => downloadPDF()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline ">
                                    {t('blacklistreport.exportpdfbtn')}
                                </span>
                            </button>

                            <button
                                className="btn mx-1 btn-primary btn-sm float-end"
                                onClick={() => downloadCSV()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline ">
                                    {t('blacklistreport.exportcsvbtn')}
                                </span>
                            </button>
                        </div>

                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <ReactTable
                        columns={columns}
                        data={blacklistdata}
                        pageSizeOption={[5, 10, 15, 20]}
                        headerClass="btn-primary"
                        isSearchBoxVisible
                        widthClass="table-width"
                        ispaginationvisible={true}
                    />
                </div>
            </div>


            <div className='card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-2 filter-table' style={{ padding: "0rem .8rem 0rem .8rem", height: "fit-content" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row' style={{ backgroundColor: "rgb(247, 247, 247)", paddingTop: ".8rem", height: "3rem", color: "black", marginBottom: '1rem' }}>
                        <span>{t('blacklistreport.filterheading')}</span>
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('blacklistreport.filterform.blacklistedby')}
                        </label>
                        <Controller
                            name="blacklisted_by"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={blacklistedbyOption}
                                    hasError={errors.blacklisted_by}
                                    placeholder={t('blacklistreport.placeholder.blacklist')}
                                />
                            )}
                        />
                        {/* {errors.blacklisted_by && (
                            <small>
                                {errors.blacklisted_by.type === 'required' && (
                                    <span className="text-danger">
                                        {t('blacklistreport.error.blacklisterror')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('blacklistreport.filterform.daterange')}
                        </label>
                        <Controller
                            name="date_range"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <>
                                    {/* <ReactDatePicker
                                    value={value}
                                    // onChange={onChange}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    placeholder={t(
                                        'blacklistreport.placeholder.date'
                                    )}
                                    hasErrors={errors.date_range}
                                    dateFormat="dd-MM-yyyy"
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange={true}
                                // isClearable={true}
                                /> */}
                                    <ReactDatePicker
                                        selected={startDate}
                                        value={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        placeholder={t('summaryreport.placeholder.startdate')}
                                        dateFormat="dd/MM/yyyy"
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxDate={endDate || undefined}
                                    />
                                    {/* <span className="mx-2">-</span> */}
                                    <br />
                                    <ReactDatePicker
                                        selected={endDate}
                                        value={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        placeholder={t('summaryreport.placeholder.enddate')}
                                        dateFormat="dd/MM/yyyy"
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate || undefined}
                                    />
                                </>
                            )}
                        />
                        {/* {errors.date_range && (
                            <small>
                                {errors.date_range.type === 'required' && (
                                    <span className="text-danger">
                                        {t('blacklistreport.error.dateerror')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>

                    <div className="text-start col-12">
                        <div className="mb-3 mt-4 text-start">
                            <button type="submit" className="col-lg-12 btn btn-primary me-1">
                                {t('blacklistreport.filterform.filterbtn')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {/* </div> */}
        </div>
    )
}

export default SummaryReport