import React, { useContext, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { rolelistpermission, permissionupdate } from 'services/masterModuleService'
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';

function EditUserrolesMaster() {
    const { t } = useTranslation();


    const [permissionData, setPermissionData] = useState([]);
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const navigate = useNavigate();
    const location = useLocation();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        setError,
        watch
    } = useForm();

    useEffect(async () => {
        checkModulePermission("User Roles")
        setIsLoading(true)
        try {
            const { data } = await rolelistpermission({ id: location?.state?.id });
            setPermissionData(data?.data)
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, [])

    console.log("location", location)

    const handleswitch = async (checkData) => {
        console.log("checkdata", checkData)
        setIsLoading(true)
        try {
            const { data } = await permissionupdate({
                role_id: location?.state?.id,
                permission_id: checkData?.childId ? checkData?.childId : checkData?.parentId,
                status: checkData?.checked == 1 ? 0 : 1
            })

            setPermissionData(permissionData.map((moduleName) => {
                if (moduleName?.id == checkData?.parentId) {
                    if (moduleName?.child?.length <= 0) {
                        moduleName.checked = checkData?.checked == 1 ? 0 : 1
                    } else {
                        moduleName?.child?.map((permission) => {
                            if (permission?.id == checkData?.childId) {
                                permission.checked = checkData?.checked == 1 ? 0 : 1
                            }
                            return permission
                        })
                    }

                }
                return moduleName
            }))

        } catch (error) {
            console.log(error)
        }
        setIsLoading(false)
    }

    console.log('perm', permissionData)

    return (
        <div className="row g-3 mb-3">
            <div className="">
                <div className="card overflow-hidden mb-3">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-8">
                                <h3 className="heading-color fw-600 mb-0">
                                    {`${t('userroles.userroleedit.userroleeditheading')} ${location?.state?.roleName}`}
                                </h3>
                            </div>
                            <div className="col-4 table-btn">
                                <button
                                    className="btn btn-primary float-end btn-sm"
                                    onClick={() => navigate('/masters/user-roles')}
                                >
                                    <FontAwesomeIcon icon="chevron-left" />
                                    <span className="ms-1">
                                        {t('userroles.btnlabel.back')}
                                    </span>
                                </button>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>


                            <div className='mx-4'>

                                <div className='row'>
                                    <div className='col-lg-12 mt-3'>
                                        {permissionData.map((mast, index) => (
                                            <div className='col-lg-12 mt-3' key={mast.id}>

                                                {mast?.is_checkbox == 1 && (
                                                    <>
                                                        <div className="form-check form-check-inline" key={mast.id}>
                                                            <input
                                                                className="form-check-input"
                                                                checked={mast.checked}
                                                                type="checkbox"
                                                                value={mast.id}
                                                                {...register('master', {
                                                                    required: true
                                                                })}
                                                                onChange={() => handleswitch({ parentId: mast?.id, childId: undefined, checked: mast?.checked })}
                                                            />
                                                            <label className='form-label edituserroleheading'>
                                                                {mast.display_name}
                                                            </label>
                                                        </div>

                                                    </>
                                                )}

                                                {mast?.is_checkbox == 0 && <label className='form-label edituserroleheading'>
                                                    {mast.display_name}
                                                </label>}


                                                <div className='form-group mb-3 mb-sm-0'>
                                                    {mast?.child?.map((perm, index) => (
                                                        <>
                                                            <div className="form-check form-check-inline" key={mast.id}>
                                                                <input
                                                                    className="form-check-input"
                                                                    checked={perm.checked}
                                                                    type="checkbox"
                                                                    value={perm.id}
                                                                    {...register('master', {
                                                                        required: true
                                                                    })}
                                                                    onChange={() => handleswitch({ parentId: mast?.id, childId: perm?.id, checked: perm?.checked })}
                                                                />
                                                                <label className="form-check-label ">
                                                                    {perm.display_name}
                                                                </label>
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditUserrolesMaster