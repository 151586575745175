import { emailReminder, notification } from 'components/configuration/statics/formData/parameterSettings';
import React, { useContext, useEffect, useState } from 'react'
import { useForm, Controller, useFieldArray } from "react-hook-form"
import { Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ReactTimePicker from 'components/common/ReactTimePicker';
import { workingHours } from 'components/configuration/statics/formData/workingHours';
import { generalSettingService, getNotificationSettingsList, updateNotificationSettings, workingHoursService } from 'services/parameterSetupService';
import { SettingsSystemDaydreamOutlined } from '@mui/icons-material';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { update } from 'lodash';
import moment from 'moment';
import toastConfig from 'components/configuration/toast-config';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';

function ParameterSettings() {
    const { t } = useTranslation()
    const [notificationSett, setNotificationSett] = useState([]);
    const [emailReminders, setEmailReminders] = useState([])
    const [workingHours, setWorkingHours] = useState([])
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        formState: { errors }
    } = useForm();
    const getParameterSettingsData = async () => {
        setIsLoading(true)
        try {
            const { data } = await getNotificationSettingsList();
            const { data: workingHoursData } = await workingHoursService({ is_update: 0 })
            const { data: generalSettingData } = await generalSettingService({ is_update: 0 })
            setNotificationSett(data?.data?.filter((notiData) => notiData?.notification_type === 1))
            setEmailReminders(data?.data?.filter((notiData) => notiData?.notification_type === 0))
            setWorkingHours(workingHoursData?.data)
            reset(generalSettingData?.data[0])
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        checkModulePermission("Parameter Settings")
        getParameterSettingsData()
    }, [])

    const {
        handleSubmit: handleTimeSubmit,
        control: timeControl,
        formState: { errors: timeErrors }
    } = useForm();

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control: timeControl, // control props comes from useForm (optional: if you are using FormContext)
        name: "workingHours", // unique name for your Field Array
    });
    const onTimeSubmit = async () => {
        setIsLoading(true)
        try {
            const { data } = await workingHoursService({ is_update: 1, working_hour: workingHours })
            toast.success(data?.message, toastConfig)
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }
    const onSubmit = async formData => {
        setIsLoading(true)
        try {
            const { data } = await generalSettingService({ is_update: 1, ...formData })
            toast.success(data?.message, toastConfig);
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }
    const changeStatus = async (updateData) => {
        setIsLoading(true)
        try {
            const { data } = await updateNotificationSettings({ id: updateData?.id, status: updateData?.status === 1 ? 0 : 1, notification_type: updateData?.notification_type })
            if (updateData?.notification_type === 1) {
                setNotificationSett(notificationSett.map((notiSett) => {
                    if (notiSett.id === updateData?.id) {
                        notiSett.status = notiSett?.status === 1 ? 0 : 1
                    }
                    return notiSett
                }))
            } else {
                setEmailReminders(emailReminders.map((emailRemind) => {
                    if (emailRemind.id === updateData?.id) {
                        emailRemind.status = emailRemind?.status === 1 ? 0 : 1
                    }
                    return emailRemind
                }))
            }
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }
    const setStartTime = (time, workingData) => {
        setWorkingHours(workingHours.map((workHour) => {
            if (workHour?.id === workingData?.id) {
                workHour.start_time = moment(time).format("HH:mm:ss")
            }
            return workHour
        }))
    }
    const setEndTime = (time, workingData) => {
        setWorkingHours(workingHours.map((workHour) => {
            if (workHour?.id === workingData?.id) {
                workHour.end_time = moment(time).format("HH:mm:ss")
            }
            return workHour
        }))
    }
    return (
        <>
            <div className="mb-3 g-3">
                <div className="card overflow-hidden mb-3 bg-img">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="heading-color fw-500 p-0 py-2">
                                    {t('parameterSettings.heading')}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row g-3'>
                    <div className='col-md-6 col-12'>
                        <div className='card'>
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="heading-color p-0 fw-400">
                                            {t('parameterSettings.generalSettings')}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <hr className='m-0' />
                            </div>
                            <div className='card-body'>
                                <div className='row mx-1'>
                                    <div className='col-8'>
                                        <p className='mb-0 fw-600'>{t('parameterSettings.section')}</p>
                                    </div>
                                    <div className='col-4'>
                                        <p className='mb-0 fw-600'>{t('parameterSettings.input')}</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>
                                <form className="mx-1">
                                    <div className='row mx-1'>
                                        <div className='col-8'>
                                            <p className='mb-0'>{t('parameterSettings.maximumNo')}</p>
                                        </div>
                                        <div className='col-4'>
                                            <input
                                                className={`form-control w-100 ${errors.year_month_per_visit ? 'is-invalid' : ''
                                                    }`}
                                                style={{ maxHeight: "2rem" }}
                                                type="text"
                                                size="2rem"
                                                {...register('year_month_per_visit', {
                                                    required: true
                                                })}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                    <div className='row mx-1'>
                                        <div className='col-8'>
                                            <p className='mb-0'>{t('parameterSettings.maximumPeriod')}</p>
                                        </div>
                                        <div className='col-4'>
                                            <input
                                                className={`form-control w-100 ${errors.period_month_per_visit ? 'is-invalid' : ''
                                                    }`}
                                                type="text"
                                                style={{ maxHeight: "2rem" }}
                                                placeholder={t('emailSettings.placeholder.email')}
                                                {...register('period_month_per_visit', {
                                                    required: true
                                                })}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-12">
                                        <hr />
                                    </div>
                                    <div className="mt-4">
                                        <button type="submit" className="btn-sm btn-primary me-1" onClick={handleSubmit(onSubmit)}>
                                            {t('parameterSettings.submitBtn')}
                                        </button>
                                    </div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-12'>
                        <div className='card'>
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="heading-color p-0 fw-400">
                                            {t('parameterSettings.emailReminders')}
                                        </h5>
                                        <p className="mb-0">{t("parameterSettings.theReport")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <hr className='m-0' />
                            </div>
                            <div className='card-body'>
                                <div className='row mx-1'>
                                    <div className='col-6'>
                                        <p className='mb-0 fw-600'>{t('parameterSettings.system')}</p>
                                    </div>
                                    <div className='col-6'>
                                        <p className='mb-0 fw-600'>{t('parameterSettings.sendNotification')}</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>
                                {emailReminders.map((emailRemind, index) => (
                                    <div key={index}>
                                        <div className='row mx-1'>
                                            <div className='col-6'>
                                                <p className='mb-0'>{t(emailRemind?.label_name)}</p>
                                            </div>
                                            <div className='col-6'>
                                                <Form.Check // prettier-ignore
                                                    checked={emailRemind?.status === 1 ? true : false}
                                                    type="switch"
                                                    id="custom-switch"
                                                    color='var(--primary)'
                                                    onChange={() => changeStatus(emailRemind)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <hr />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-12'>
                        <div className='card'>
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="heading-color p-0 fw-400">
                                            {t('parameterSettings.notifications')}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <hr className='m-0' />
                            </div>
                            <div className='card-body'>
                                <div className='row mx-1'>
                                    <div className='col-6'>
                                        <p className='mb-0 fw-600'>{t('parameterSettings.notification')}</p>
                                    </div>
                                    <div className='col-6'>
                                        <p className='mb-0 fw-600'>{t('parameterSettings.sendNotification')}</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>
                                {notificationSett.map((notification, index) => (
                                    <div key={index}>
                                        <div className='row mx-1'>
                                            <div className='col-6'>
                                                <p className='mb-0'>{t(notification?.label_name)}</p>
                                            </div>
                                            <div className='col-6'>
                                                <Form.Check // prettier-ignore
                                                    checked={notification?.status === 1 ? true : false}
                                                    type="switch"
                                                    id="custom-switch"
                                                    color='var(--primary)'
                                                    onChange={() => changeStatus(notification)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <hr />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-12'>
                        <div className='card'>
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="heading-color p-0 fw-400">
                                            {t('parameterSettings.workingHours')}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <hr className='m-0' />
                            </div>
                            <div className='card-body'>
                                <div className='row mx-1'>
                                    <div className='col-4'>
                                        <p className='mb-0 fw-600'>{t('parameterSettings.day')}</p>
                                    </div>
                                    <div className='col-4'>
                                        <p className='mb-0 fw-600'>{t('parameterSettings.startTime')}</p>
                                    </div>
                                    <div className='col-4'>
                                        <p className='mb-0 fw-600'>{t('parameterSettings.endTime')}</p>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>
                                {workingHours.map((working, index) => (
                                    <div key={index}>
                                        <div className='row mx-1'>
                                            <div className='col-4'>
                                                <p className='mb-0'>{t(working.day)}</p>
                                            </div>
                                            <div className='col-4'>
                                                <ReactTimePicker
                                                    selected={moment(working?.start_time, "HH:mm:ss").toDate().getTime()}
                                                    onChange={e => setStartTime(e, working)}
                                                    placeholder={t('parameterSettings.time')}
                                                    value={moment(working?.start_time, "HH:mm:ss").toDate().getTime()}
                                                    isclockshow={false}
                                                />
                                            </div>
                                            <div className='col-4'>
                                                <ReactTimePicker
                                                    selected={moment(working?.end_time, "HH:mm:ss").toDate().getTime()}
                                                    onChange={e => setEndTime(e, working)}
                                                    placeholder={t('parameterSettings.time')}
                                                    value={moment(working?.end_time, "HH:mm:ss").toDate().getTime()}
                                                    isclockshow={false}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <hr />
                                        </div>

                                    </div>
                                ))}
                                <div className="mt-4">
                                    <button type="submit" className="btn-sm btn-primary me-1" onClick={() => onTimeSubmit()}>
                                        {t('parameterSettings.submitBtn')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ParameterSettings