import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CsvFileUploder from 'components/doc-components/CsvFileUploder';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate } from 'react-router-dom';
import { blackupload } from 'services/adminmoduleService'
import { loaderContext } from 'context/Context';
import '../../../pages/admin/blacklistvisitor/modal.css'


export default function UploadBlackList({ show, handleClose, setblacklist, blacklist }) {
    const { t } = useTranslation();
    const [submitDisable, setSubmitDisable] = useState(false);
    const { isLoading, setIsLoading } = useContext(loaderContext)


    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        clearErrors,
        watch,
        getValues,
        formState: { errors }
    } = useForm();
    const fileData = watch('visitor_list');

    useEffect(async () => {
        if (fileData) {
            clearErrors('visitor_list');
        }
    }, [fileData]);

    const onSubmit = async (csvFileData) => {
        setIsLoading(true)
        try {
            console.log("csv", csvFileData)
            // const formData = { csvFileData }
            const formDataObject = new FormData();
            formDataObject.append("visitor_list", csvFileData?.visitor_list)
            const { data } = await blackupload(formDataObject)
            const updatedData = data?.data[0]?.blacklist_visitor
            setblacklist([...updatedData, ...blacklist])
            setSubmitDisable(true);
            toast.success(data.message, toastConfig)
            handleClose();
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    };
    const onClose = () => {
        reset();
        handleClose();
        setSubmitDisable(false);
    };

    const uploadCsvFile = (file) => {
        setValue('visitor_list', file.target.files[0])
    }
    return (
        <>
            <Modal dialogClassName='modal-width' show={show} onHide={onClose} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>{t('blackListVisitor.modal.tittle.uploadBlacklistVisitor')}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <div className="p-3">
                            <div>
                                <p style={{ color: "#33664a" }}> {t('blackListVisitor.modal.text.para1')}</p>
                                <p style={{ color: "#33664a" }}>{t('blackListVisitor.modal.text.para2')}</p>
                                <a href={`${process.env.REACT_APP_BASEROUTE_URL}/blacklist.csv`} download="blacklist_sample.csv" style={{ color: "#33664a" }}>{t('blackListVisitor.modal.text.para3')}</a>
                            </div>
                            {/* <div className="d-flex justify-content-start">
                                <a href="/index.csv" download="sample.csv">
                                    <button
                                        type="button"
                                        className="btn-primary border-0 py-2 px-3 rounded"
                                    >
                                        {t('blackListVisitor.modal.btnLabel.sampleDownload')}
                                    </button>
                                </a>
                            </div> */}
                            <br />
                            <div className="mt-1">
                                {/* <input type='file' className='form-control' style={{ height: "auto" }} /> */}
                                {/* <Controller
                                    name="visitor_list"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { } }) => (
                                        <CsvFileUploder
                                            setValue={setValue}
                                            setFile={(file) => setFile(file)}
                                            setName="visitor_list"
                                            showButton={false}
                                            placeholderMessageClassName="py-5 fs-1 fw-0"
                                            className="p-3"
                                            showplaceholderImage={false}
                                            placeholderMessage="Drop your file here"
                                            borderType={{
                                                border: `2px dashed ${errors.visitor_list ? '#e63757' : '#d8e2ef'
                                                    }`
                                            }}
                                        />
                                    )}
                                /> */}
                                <Controller
                                    name="visitor_list"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange } }) => (
                                        <input type='file' onChange={(e) => uploadCsvFile(e)}
                                            className={`form-control ${errors.visitor_list && 'is-invalid'} file-input`} style={{ height: "auto" }} />
                                    )}
                                />
                                {errors.visitor_list && (
                                    <small className="text-danger">
                                        {errors.visitor_list.type == 'required' && (
                                            <span>{t('blackListVisitor.modal.error.fileError')}</span>
                                        )}
                                    </small>
                                )}
                            </div>
                        </div>
                        {/* <hr className="mt-3" /> */}
                        <div className="d-flex justify-content-end m-3">
                            {/* <button
                                className="btn btn-secondary me-1"
                                type="button"
                                onClick={onClose}
                            >
                                {t('blackListVisitor.modal.btnLabel.close')}
                            </button> */}
                            <button
                                className="btn btn-primary"
                                type="submit"
                            // disabled={submitDisable ? true : false}
                            >
                                {t('blackListVisitor.modal.btnLabel.addBlackList')}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}
