import { appointmentEndPoints } from "./config"
import httpServices from "./httpServices"

export const getappointment = async () => {
    return await httpServices.post(appointmentEndPoints.listappointment)
}
export const appointmentadd = async (appointmentData) => {
    return await httpServices.post(appointmentEndPoints.addappointment, appointmentData)
}
export const appointmentupdate = async (updatedData) => {
    return await httpServices.post(appointmentEndPoints.updateappointment, updatedData)
}
export const appointmentdelete = async (id) => {
    return await httpServices.post(appointmentEndPoints.deleteappointment, id)
}
export const appointmentidentilist = async () => {
    return await httpServices.get(appointmentEndPoints.listidentification)
}
export const getStaffList = async () => {
    return await httpServices.get(appointmentEndPoints.staffList)
}