import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import formPattern from 'components/configuration/form-pattern';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { useState } from 'react';
import { loaderContext } from 'context/Context';
import ReactDateAndTimePicker from 'components/common/ReactDateAndTimePicker';
import moment from 'moment';
import { createNewEvent, getExternalLocations } from 'services/bookingService';
import _ from 'lodash';
import pattern from 'components/configuration/form-pattern';
import { Controller, useForm } from 'react-hook-form';
import CryptoJS from 'crypto-js';
import { getMemberVerify } from 'services/commonService';

export default function BookingEvent() {
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const [locationList, setLocationList] = useState([])
    const [memberData, setMemberData] = useState({})
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        setError,
        clearErrors,
        control,
        watch,
        formState: { errors }
    } = useForm();
    const memberName = watch("member_name")
    const getLocationList = async () => {
        setIsLoading(true)
        try {
            const { data } = await getExternalLocations();
            setLocationList(data?.data?.map((location) => ({ label: location.name, value: location.id })))
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        getLocationList()
    }, [])
    const onSubmit = async (formData) => {
        setIsLoading(true)
        try {
            formData.location_id = formData?.location_id?.value
            formData.date = moment(formData?.date).format(process.env.REACT_APP_DATE_TIME_FORMAT)
            formData.member_phone = memberData?.Mobile
            formData.member_email = memberData?.Email
            const { data } = await createNewEvent(formData)
            toast.success(data?.message, toastConfig)
            onReset()
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }
    const onReset = () => {
        setValue("name", "")
        setValue("date", "")
        setValue("location_id", "")
        setValue("no_of_guest", "")
        setValue("member_name", "")
        setValue("member_number", "")
        clearErrors()
    };
    const verifyMemberDetails = async () => {
        setIsLoading(true)
        const memberId = watch("member_number")
        if (memberId && memberId != "") {
            try {
                const hash = CryptoJS.SHA256(`${process.env.REACT_APP_SECRET_KEY}${memberId}`).toString();
                const { data: memberData } = await getMemberVerify({ Search: memberId, Hash: hash })
                if (memberData?.MemberNumber != "") {
                    setValue("member_name", `${memberData.FirstName} ${memberData.lastName}`)
                    setMemberData(memberData)
                } else {
                    toast.error("Invalid", toastConfig)
                }
            } catch (error) {
                errorMessage(error)
            }
        }
        setIsLoading(false)
    }
    return (
        <>
            <div className="row g-3 mb-3">
                <div className="">
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="heading-color fw-600">
                                        {t('eventbooking.heading')}
                                    </h3>
                                </div>
                                <div className="col-4 table-btn">

                                </div>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <form autoComplete="off">
                                    <div className='row'>
                                        <div className="form-group col-md-3 mb-3">
                                            <label className="form-label">
                                                {t('eventbooking.eventbookingform.memberno')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.member_number ? 'is-invalid' : ''}`}
                                                placeholder={t('eventbooking.placeholder.memberno')}
                                                {...register('member_number', { required: true })}
                                                onBlur={() => verifyMemberDetails()}
                                            />
                                            {errors.member_number && (
                                                <small>
                                                    {errors.member_number.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('eventbooking.error.memberNumberReq')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>

                                        <div className="form-group col-md-3 mb-3">
                                            <label className="form-label">
                                                {t('eventbooking.eventbookingform.membername')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.member_name ? 'is-invalid' : ''}`}
                                                placeholder={t('eventbooking.placeholder.membername')}
                                                {...register('member_name')}
                                                disabled={true}
                                            />
                                            {/* {errors.member_name && (
                                                <small>
                                                    {errors.member_name.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('eventbooking.error.memberNameReq')}
                                                        </span>
                                                    )}
                                                </small>
                                            )} */}
                                        </div>

                                        <div className="form-group col-md-3">
                                            <label className="form-label">
                                                {t('eventbooking.eventbookingform.eventname')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                                placeholder={t('eventbooking.placeholder.eventname')}
                                                {...register('name', { required: true, pattern: formPattern.alphabetWithSpace })}
                                            />
                                            {errors.name && (

                                                <small>
                                                    {errors.name.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('eventbooking.error.nameReq')}
                                                        </span>
                                                    )}
                                                    {errors.name.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('eventbooking.error.nameInvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label className="form-label">
                                                {t('eventbooking.eventbookingform.noofguest')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.no_of_guest ? 'is-invalid' : ''}`}
                                                placeholder={t('eventbooking.placeholder.noofguest')}
                                                {...register('no_of_guest', { required: true, pattern: formPattern.numberOnly })}
                                            />
                                            {errors.no_of_guest && (
                                                <small>
                                                    {errors.no_of_guest.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('eventbooking.error.noGuestReq')}
                                                        </span>
                                                    )}
                                                    {errors.no_of_guest.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('eventbooking.error.noGuestInvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label className="form-label">
                                                {t('eventbooking.eventbookingform.eventlocation')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                name="location_id"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <SearchableDropdown
                                                            value={value}
                                                            onChange={onChange}
                                                            options={locationList}
                                                            hasError={errors.location_id}
                                                            placeholder={t(
                                                                'createEvent.placeholder.eventLocation'
                                                            )}
                                                            isClearable={true}
                                                        />
                                                    )
                                                }}
                                            />
                                            {errors.location_id && (
                                                <small className="text-danger">
                                                    {errors.location_id.type === 'required' && (
                                                        <span>{t('eventbooking.error.locationIdReq')}</span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-3 mb-3">
                                            <label className="form-label">
                                                {t('eventbooking.eventbookingform.dateandtimeofevent')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                name="date"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange } }) => (
                                                    <ReactDateAndTimePicker
                                                        value={value}
                                                        onChange={onChange}
                                                        hasErrors={errors.date}
                                                        placeholder={t("eventbooking.placeholder.dateandtimeofevent")}
                                                        dateFormat={process.env.REACT_APP_DATE_TIME_PICKER_FORMAT}
                                                        minDate={moment().toDate()}
                                                        minTime={
                                                            value && moment(value).isSame(moment(), 'day')
                                                                ? moment().toDate()
                                                                : moment().startOf('day').toDate()
                                                        }
                                                        maxTime={
                                                            value && moment(value).isSame(moment(), 'day')
                                                                ? moment().endOf('day').toDate()
                                                                : moment().endOf('day').toDate()
                                                        }
                                                        showTimeSelect
                                                    />
                                                )}
                                            />
                                            {errors.date && (
                                                <small>
                                                    {errors.date.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('eventbooking.error.dateandtimeofevent')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>

                                    </div>


                                </form>
                                <div className="mb-3 mt-3 text-end">
                                    <button disabled={(memberName && memberName != "") ? false : true} onClick={handleSubmit(onSubmit)} className="btn btn-primary me-1" >
                                        {t('eventbooking.eventbookingform.submit')}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => onReset()}
                                    >
                                        {t('eventbooking.eventbookingform.reset')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
