export const identityTypeOptions = Object.freeze([
  { value: 'National ID', label: 'National ID' },
  { value: 'Alien ID', label: 'Alien ID' },
  { value: 'Passport', label: 'Passport' },
  { value: 'Driving License', label: 'Driving License' },
  { value: 'Company ID', label: 'Company ID' },
  { value: 'Other Documents', label: 'Other Documents' }
]);

export const genderOptions = Object.freeze([
  { value: 'MALE', label: 'Male' },
  { value: 'FEMALE', label: 'Female' },
  { value: 'OTHER', label: 'Other' }
]);
// export const genderOptions = Object.freeze([
//   { value: 0, label: 'Male' },
//   { value: 1, label: 'Female' },
//   { value: 2, label: 'Other' }
// ]);


//memebers aide cat options added by parth 
export const categoryOptions = Object.freeze([
  { value: 'AIDE', label: 'Aide' },
  { value: 'RESIDENT', label: 'resident' },
]);



export const labelOptions = Object.freeze([
  { value: 'Business', label: 'Business' },
  { value: 'Important', label: 'Important' },
  { value: 'Personal', label: 'Personal' },
  { value: 'Must Attend', label: 'Must Attend' }
]);

export const statusOptions = Object.freeze([
  { value: '1', label: 'Active' },
  { value: '0', label: 'in-Active' }
]);

export const appointmentType = Object.freeze([
  { value: 'appointment', label: 'appointment' },
  { value: 'schedule', label: 'schedule' }
]);

export const parameterSetupType = Object.freeze({
  configurationSettings: "Configuration settings",
  maillingDetails: "Mailling details",
  applicationSettings: "Application settings",
  proxySettings: "Proxy settings",
  smsGateway: "Sms gateway",
  whatsAppSettings: "Whatsapp settings",
  telegramSettings: "Telegram settings",
  manageFrontdesk: "Manage frontdesk"
});

export const tableName = Object.freeze({
  companyMaster: "Company Master",
  departmentMaster: "Department Master",
  employeeMaster: "Employee Master",
  deviceMaster: "Device Master",
  ipCameraMaster: "IP Camera Master",
  passTemplate: "Pass Template",
  accessControl: "Access Control",
  assetMaster: "Asset Master",
  vipVisits: "VIP Visits",
  bypasssVisitor: "Bypass Visitor",
  BlacklistVisitor: "Blacklist Visitor",
  MessageTemeplate: "Message Temeplate",
  sosManager: "SOS Manager",
  backupManagement: "Backup Management",
  broadcast: "BroadCast",
  summary: "Summary",
  visits: "Visits",
  blacklist: "Blacklist",
  systemUser: "System User",
  group: "Group",
  specialVipVisit: "Special/VIP Visit",
  sos: "SOS",
  visitorsDump: "Visitor Dump",
  audit: "Audit",
  appointment: "Appointment",
  configurationSettings: "Configuration Settings",
  maillingDetails: "Mailling Details",
  applicationSettings: "Application Settings",
  proxySettings: "Proxy Settings",
  smsGateway: "Sms Gateway",
  whatsApp: "WhatsApp",
  telegram: "Telegram",
  manageFrontdesk: "Manage Frontdesk"
})