import { visitorAppointmentEndPoints } from "./config"
import httpServices from "./httpServices"

export const listVisitorAppointments = async () => {
    return await httpServices.get(visitorAppointmentEndPoints.listVisitorAppointment)
}
export const visitorRegisterData = async (visitorAppointmentData) => {
    return await httpServices.post(visitorAppointmentEndPoints.visitorAppointmentRegisterData, visitorAppointmentData)
}
export const visitorBypassData = async (visitorBypassData) => {
    return await httpServices.post(visitorAppointmentEndPoints.visitorBypass, visitorBypassData)
}
