import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import Section from 'components/common/Section';
import { authenticateToken } from 'services/authenticationService';

export default function Home() {
  const navigation = useNavigate();
  const { t } = useTranslation();
  // useEffect(async () => {
  //   //  const {data}=await authenticateToken({authToken:localStorage.getItem("authToken")});
  //   //  if(data.message==="valid")
  //   //  {
  navigation("/dashboard", { replace: true })
  //   //  }
  // }, [])
  return (
    <Section className="home-page">
      <Row>
        <Col lg={6}>
          <div>
            <h1 className="heading-color fw-bold homepage-info text-uppercase">
              {t("homePage.heading")}
            </h1>
            <p className="homepage-description">
              {t("homePage.description")}{' '}
            </p>
          </div>
        </Col>
      </Row>
    </Section>
  );
}
