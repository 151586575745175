import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import formPattern from 'components/configuration/form-pattern';
import InputPassword from 'components/common/InputPassword';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactTimePicker from 'components/common/ReactTimePicker';
import ReactDatePicker from 'components/common/ReactDatePicker';
import CountryCode from 'components/common/CountryCode';
import { useState } from 'react';
import ReactDateAndTimePicker from 'components/common/ReactDateAndTimePicker';
import CsvFileUploder from 'components/doc-components/CsvFileUploder';
import { createevent, getlocation, getlocationactive } from 'services/manageEventsService'
import moment from 'moment';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import pattern from 'components/configuration/form-pattern';
import { getMemberVerify } from 'services/commonService';
import CryptoJS from 'crypto-js';


function CreateEvent() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [countryCode, setCountryCode] = useState({});
    const [file, setFile] = useState()
    const [removeFileName, setRemoveFileName] = useState(false);
    const [listLocation, setListLocation] = useState([])
    const [memberData, setMemberData] = useState({})
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        setError,
        clearErrors,
        control,
        watch,
        formState: { errors }
    } = useForm();
    const members = watch("members")
    const memberName = watch("member_name")
    useEffect(() => {
        setValue("no_of_guest", members?.length)
    }, [members])
    useEffect(async () => {
        checkModulePermission("Create Event")
        setIsLoading(true)
        try {
            const { data: location } = await getlocationactive()
            setListLocation(location?.data?.map((list) => ({ label: list.name, value: list.id })))
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (file) {
            clearErrors("members")
        }
    }, [file])

    const onSubmit = async (accessData) => {
        console.log("file", file)
        console.log("accessData", accessData)
        setIsLoading(true)
        try {
            if (file) {
                // accessData.guest_list = accessData.members
                // accessData.location_id = accessData.location_id.value
                const formDataObject = new FormData();
                formDataObject.append("guest_list", file)
                formDataObject.append("name", accessData.name)
                formDataObject.append("member_name", accessData.member_name)
                formDataObject.append("member_number", accessData.member_number)
                formDataObject.append("member_phone", memberData?.Mobile)
                formDataObject.append("member_email", memberData?.Email)
                formDataObject.append("location_id", accessData?.location_id?.value)
                formDataObject.append("date", moment(accessData?.date).format(process.env.REACT_APP_DATE_TIME_FORMAT))
                formDataObject.append("no_of_guest", accessData.no_of_guest)
                const { data } = await createevent(formDataObject);
                toast.success(data.message, toastConfig);
                reset();
                setValue("location_id", "")
                setRemoveFileName(true)
            }
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
        console.log(accessData);
    };
    const onReset = () => {
        setRemoveFileName(true)
        reset();
        setValue('location_id', '');
    };
    const verifyMemberDetails = async () => {
        setIsLoading(true)
        const memberId = watch("member_number")
        console.log("Member Id", memberId)
        if (memberId && memberId != "") {
            try {
                const hash = CryptoJS.SHA256(`${process.env.REACT_APP_SECRET_KEY}${memberId}`).toString();
                const { data: memberData } = await getMemberVerify({ Search: memberId, Hash: hash })
                if (memberData?.MemberNumber != "") {
                    setValue("member_name", `${memberData.FirstName} ${memberData.lastName}`)
                    clearErrors("member_name")
                    setMemberData(memberData)
                } else {
                    toast.error("Invalid", toastConfig)
                }
            } catch (error) {
                errorMessage(error)
            }
        }
        setIsLoading(false)
    }



    return (
        <>
            <div className="mb-3 row g-3">
                <div className="card overflow-hidden mb-3">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="heading-color fw-600">
                                    {t('createEvent.heading')}
                                </h3>
                            </div>
                            <div className="col-12 mb-3">
                                <hr />
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className='row'>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('createEvent.label.step1')}
                                        </label>
                                        <a href={`${process.env.REACT_APP_BASEROUTE_URL}/index.csv`} className='btn btn-primary' download="sample.csv">
                                            {t('createEvent.btnLabel.sampleDownload')}
                                        </a>
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('createEvent.label.step2')}
                                        </label>
                                        <div className='col-7'>
                                            <Controller
                                                name="members"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { } }) => (
                                                    <CsvFileUploder
                                                        setValue={setValue}
                                                        setFile={(file) => setFile(file)}
                                                        removeFileName={removeFileName}
                                                        setRemoveFileName={setRemoveFileName}
                                                        setName="members"
                                                        hasError={errors.members}
                                                        errorMsg={t('manageGroupVisit.error.fileError')}
                                                        showButton={false}
                                                        placeholderMessageClassName="fs--1 fw-bold my-auto text-dark filetext"
                                                        placeholderMessage={t(
                                                            'manageGroupVisit.placeholder.uploadcsv'
                                                        )}

                                                        borderType={{
                                                            border: `2px dashed ${errors.members ? '#e63757' : '#d8e2ef'
                                                                }`
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('createEvent.label.eventName')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.name && 'is-invalid'
                                                }`}
                                            placeholder={t('createEvent.placeholder.eventName')}
                                            {...register('name', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                        />
                                        {errors.name && (
                                            <small>
                                                {errors.name.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('createEvent.error.eventNameReq')}
                                                    </span>
                                                )}
                                                {errors.name.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('createEvent.error.eventNameInvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('createEvent.label.memberNo')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.member_number && 'is-invalid'
                                                }`}
                                            placeholder={t('createEvent.placeholder.memberNo')}
                                            {...register('member_number', { required: true, pattern: pattern.userName })}
                                            onBlur={() => verifyMemberDetails()}
                                        />
                                        {errors.member_number && (
                                            <small>
                                                {errors.member_number.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('createEvent.error.memberNoReq')}
                                                    </span>
                                                )}
                                                {errors.member_number.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('createEvent.error.memberNoinvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('createEvent.label.memberName')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.member_name && 'is-invalid'
                                                }`}
                                            disabled={true}
                                            placeholder={t('createEvent.placeholder.memberName')}
                                            {...register('member_name')}
                                        />
                                        {/* {errors.member_name && (
                                            <small>
                                                {errors.member_name.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('createEvent.error.memberNameReq')}
                                                    </span>
                                                )}
                                                {errors.member_name.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('createEvent.error.memberNameInvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )} */}
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className="form-label">
                                            {t('createEvent.label.eventLocation')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <Controller
                                            name="location_id"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => {
                                                return (
                                                    <SearchableDropdown
                                                        value={value}
                                                        onChange={onChange}
                                                        options={listLocation}
                                                        placeholder={t(
                                                            'createEvent.placeholder.eventLocation'
                                                        )}
                                                        isClearable={true}
                                                        hasError={errors.location_id}
                                                    />
                                                )
                                            }}
                                        />
                                        {errors.location_id && (
                                            <small>
                                                {errors.location_id.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('createEvent.error.eventLocationReq')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className="form-label">
                                            {t('createEvent.label.dateAndTime')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <Controller
                                            name='date'
                                            rules={{ required: true }}
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <ReactDateAndTimePicker
                                                    value={value}
                                                    onChange={onChange}
                                                    hasErrors={errors.date}
                                                    placeholder={t("createEvent.placeholder.dateAndTime")}
                                                    dateFormat={process.env.REACT_APP_DATE_TIME_PICKER_FORMAT}
                                                    minDate={moment().toDate()}
                                                    minTime={
                                                        value && moment(value).isSame(moment(), 'day')
                                                            ? moment().toDate()
                                                            : moment().startOf('day').toDate()
                                                    }
                                                    maxTime={
                                                        value && moment(value).isSame(moment(), 'day')
                                                            ? moment().endOf('day').toDate()
                                                            : moment().endOf('day').toDate()
                                                    }
                                                    showTimeSelect
                                                />
                                            )}
                                        />
                                        {errors.date && (
                                            <small>
                                                {errors.date.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('createEvent.error.dateAndTimeReq')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('createEvent.label.noOfGuest')}
                                            {/* <span className="text-danger ms-1">*</span> */}
                                        </label>
                                        <input
                                            className={`form-control ${errors.no_of_guest ? 'is-invalid' : ''
                                                }`}
                                            type="text"
                                            placeholder={t('createEvent.placeholder.noOfGuest')}
                                            {...register('no_of_guest', {
                                                pattern: formPattern.numberOnly
                                            })}
                                        />
                                        {errors.no_of_guest && (
                                            <small className="text-danger">
                                                {/* {errors.noOfGuest.type === 'required' && (
                                                    <span>{t('createEvent.error.noOfGuestReq')}</span>
                                                )} */}
                                                {errors.no_of_guest.type === 'pattern' && (
                                                    <span>{t('createEvent.error.noOfGuestInvalid')}</span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                </div>

                                <div className="mb-3 mt-3 text-end">
                                    <button type="submit" disabled={(memberName && memberName != "") ? false : true} className="btn btn-primary me-1">
                                        {t('createEvent.btnLabel.register')}
                                    </button>
                                    <button
                                        type="reset"
                                        className="btn btn-danger"
                                        onClick={onReset}
                                    >
                                        {t('createEvent.btnLabel.reset')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateEvent