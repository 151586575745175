import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import ReactTable from 'components/common/ReactTable';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Swalcolor from 'components/configuration/sweetalert-config';
import { encodeData } from 'components/common/encodeParams';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { useForm, Controller } from 'react-hook-form';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { sendsos } from 'services/adminmoduleService'
import { loaderContext } from 'context/Context';



export default function SystemUsers() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isLoading, setIsLoading } = useContext(loaderContext)


    useEffect(() => {
        checkModulePermission("SOS Message")
    }, [])
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        setError,
        watch
    } = useForm();

    const onSubmit = async (sos) => {
        setIsLoading(true)
        try {
            const { data } = await sendsos(sos)
            toast.success(data.message, toastConfig)
            reset()
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }

    const resetValue = () => {
        reset();
    }

    return (
        <>
            <div className="row g-3 mb-3">
                <div className="">
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="heading-color fw-600 mb-0">
                                        {t('sosmessage.heading')}
                                    </h3>
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>

                                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                    <div className='row'>
                                        <div className="form-group col-lg-8   mb-3" >
                                            <label>
                                                {t('sosmessage.message')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <textarea
                                                className={`form-control ${errors.message && 'is-invalid'
                                                    }`}
                                                type="text"
                                                rows="6"
                                                {...register('message', {
                                                    required: true,
                                                    // pattern: pattern.fullName
                                                })}
                                            />
                                            {errors.message && (
                                                <small>
                                                    {errors.message.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('sosmessage.error')}
                                                        </span>
                                                    )}

                                                </small>
                                            )}
                                        </div>
                                        <div className="text-start col-8 ">
                                            <div className="mt-2 mb-3 text-end">
                                                <button type="submit" className="btn btn-primary me-1">
                                                    <FontAwesomeIcon icon="" />
                                                    {t('sosmessage.send')}
                                                </button>
                                                <button
                                                    type="reset"
                                                    className="btn btn-danger"
                                                    onClick={resetValue}
                                                >
                                                    {t('sosmessage.reset')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
