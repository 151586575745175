import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import countryCodes, {
    defaultCountryCode
} from 'components/configuration/country-code';
import pattern from 'components/configuration/form-pattern';
import ReactDatePicker from 'components/common/ReactDatePicker';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { genderOptions } from 'components/configuration/common-options';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import moment from 'moment';
import ReactTable from 'components/common/ReactTable';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import PhoneNumber from 'components/common/PhoneNumber';
import { casualworkerslist, dropdownmembercategory, listsupplier, membersadd, membersdelete, memberslist } from 'services/adminmoduleService'
import '../../../../assets/css/general.css'
import { casualworkeradd, casualworkerdelete, dropdownvisitor, dropdowncompanytype, getListOfDepartment } from 'services/adminmoduleService'
import Swalcolor from 'components/configuration/sweetalert-config';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';

import '../casualworkers/casual.css'
import { loaderContext } from 'context/Context';
import CountryCode from 'components/common/CountryCode';
import formPattern from 'components/configuration/form-pattern';
import { verifyAccessCard } from 'services/commonService';


export default function Member() {

    const { t } = useTranslation();

    const [membersData, setMembersData] = useState([]);
    const [memberCategoryList, setMemberCategoryList] = useState([]);
    const navigate = useNavigate();
    const [countryCode, setCountryCode] = useState(defaultCountryCode);
    const { isLoading, setIsLoading } = useContext(loaderContext)




    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        setError,
        watch,
    } = useForm();

    console.log("membersData", membersData)



    useEffect(async () => {
        checkModulePermission("Add Members")
        setIsLoading(true)
        try
        {
            const { data } = await memberslist();
            const { data: membCategory } = await dropdownmembercategory()
            setMembersData(data?.data);
            setMemberCategoryList(membCategory?.data?.map((mem) => ({ label: mem.name, value: mem.id })))
        } catch (e)
        {
            errorMessage(e)
        }
        setIsLoading(false)
    }, []);




    const onEditRow = (id) => {
        const newData = membersData?.find((member) => member.id === id)
        navigate(
            `/edit-members`, { state: newData }
        );
    };
    const onDeleteRow = (id) => {
        Swal.fire({
            title: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationTitle'
            ),
            text: t('departmentMaster.deleteDepartment.sweetAlert.confirmationText'),
            icon: 'warning',
            showCancelButton: Swalcolor.confirmation.showCancelButton,
            confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
            cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
            confirmButtonText: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationBtnLabel'
            )
        }).then(async result => {
            if (result.isConfirmed)
            {
                setIsLoading(true)
                try
                {
                    const { data } = await membersdelete({ id });
                    console.log(data);
                    setMembersData(membersData.filter(data => data.id != id));
                    Swal.fire({
                        icon: 'success',
                        title: t(
                            'departmentMaster.deleteDepartment.sweetAlert.simpleTitle'
                        ),
                        text: data.data.result,
                        confirmButtonColor: Swalcolor.simple.confirmButtonColor
                    });
                } catch (e)
                {
                    errorMessage(e)
                }
                setIsLoading(false)
            }
        });
    };








    const columns = [

        {
            Header: t('members.table.no'),
            accessor: (row, index) => index + 1,
        },
        {
            Header: t('members.table.membername'),
            accessor: 'member_name',
            Cell: ({ row }) => {
                return row?.original?.member_name ? row?.original?.member_name : '--'
            }
        },
        {
            Header: t('members.table.memberno'),
            accessor: 'member_no',
            Cell: ({ row }) => {
                return row?.original?.member_no ? row?.original?.member_no : '--'
            }
        },
        {
            Header: t('members.table.phone'),
            accessor: 'phone',
            Cell: ({ row }) => {
                return row?.original?.phone ? `${row?.original?.country_code}-${row?.original?.phone}` : '--'
            }
        },
        {
            Header: t('members.table.email'),
            accessor: 'email',
            Cell: ({ row }) => {
                return row?.original?.email ? row?.original?.email : '--'
            }
        },
        {
            Header: t('members.table.category'),
            accessor: 'category',
            Cell: ({ row }) => {
                return row?.original?.category ? row?.original?.category : '--'
            }
        },

        {
            Header: t('members.table.expiry'),
            accessor: 'expiry_date',
            Cell: ({ row }) => (
                <div>
                    {moment.utc(row?.original?.expiry_date).local().format(process.env.REACT_APP_FILTER_DATE) ? moment.utc(row?.original?.expiry_date).local().format(process.env.REACT_APP_FILTER_DATE) : '--'}
                </div>
            ),
        },
        {
            Header: t('members.table.action'),
            Cell: ({ row }) => (
                <div>
                    <span
                        className="btn p-0 me-2"
                        title="Edit"
                        onClick={() => onEditRow(row.original.id)}
                    >
                        <FontAwesomeIcon icon="edit" />
                    </span>
                    <span
                        className="btn p-0"
                        title="Delete"
                        onClick={() => onDeleteRow(row.original.id)}
                    >
                        <FontAwesomeIcon icon="trash-alt" />
                    </span>
                </div>
            ),
            disableSortBy: true
        },

    ];






    const onSubmitVisitorData = async (memberData) => {
        setIsLoading(true)
        try
        {

            if (memberData?.expiry_date)
            {
                memberData.expiry_date = moment(memberData?.expiry_date).format(process.env.REACT_APP_DATE_FORMAT)
            }
            memberData.country_code = countryCode?.dial_code;
            memberData.category = memberData?.category?.value
            const { data } = await membersadd(memberData)
            console.log("==", data?.data)
            const newData = data?.data[0]?.only_members_detail
            setMembersData([newData, ...membersData])
            reset();
            setValue("category", "")
            toast.success(data?.message, toastConfig)

        } catch (e)
        {
            if (e.response.data)
            {
                if (Array.isArray(e.response.data.message))
                {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else
                {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else
            {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    };


    const onReset = () => {
        reset();
        setValue('category', '');
        setCountryCode(defaultCountryCode)
    };


    //added by parth
    const getAccessCardNumber = async () => {
        setIsLoading(true)
        try
        {
            const { data } = await verifyAccessCard({ host_type: selectedHostType })
            setValue("access_card_no", data?.data[0]?.access_card_no)
            clearErrors("access_card_no")
        } catch (e)
        {
            if (e.response.data)
            {
                if (Array.isArray(e.response.data.message))
                {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else
                {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else
            {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }

    const [selectedHostType, setSelectedHostType] = useState();

    //handle selected host type for members encoding by parth
    const handleSelectHostType = (event) => {
        setSelectedHostType(event.target.value);
    };


    return (
        <>
            <form
                encType="multipart/form-data"
                autoComplete="off"
                onSubmit={handleSubmit(onSubmitVisitorData)}
                className="mainn-registerguest"
            >

                <div className="row ">
                    <div>
                        <div className="card overfolw-hidden mb-3">
                            <div className="card-header pb-0">
                                <div className="row">
                                    <h3 className="heading-color fw-600">
                                        {t('members.heading')}
                                    </h3>
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3 mb-3">
                                <div className='table-body'>
                                    <div className="card-body pt-0">
                                        <div>
                                            <div className='row'>
                                                <div className="form-group col-md-4 mb-3">
                                                    <label className="form-label">
                                                        {t('members.label.membername')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className={`form-control ${errors.member_name && 'is-invalid'
                                                            }`}
                                                        placeholder={t('members.placeholder.membername')}
                                                        {...register('member_name', { required: true, pattern: pattern.notAllowSpaceInStartfront })}
                                                    />
                                                    {errors.member_name && (
                                                        <small>
                                                            {errors.member_name.type === 'required' && (
                                                                <span className="text-danger">
                                                                    {t('members.error.membername')}
                                                                </span>
                                                            )}
                                                            {errors.member_name.type === 'pattern' && (
                                                                <span className="text-danger">
                                                                    {t('members.error.membernameinvalid')}
                                                                </span>
                                                            )}
                                                        </small>
                                                    )}
                                                </div>
                                                <div className="form-group col-md-4 mb-3">
                                                    <label className="form-label">
                                                        {t('members.label.memberno')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className={`form-control ${errors.member_no && 'is-invalid'
                                                            }`}
                                                        placeholder={t('members.placeholder.memberno')}
                                                        {...register('member_no', { required: true, pattern: pattern.notAllowSpaceInStartfront })}
                                                    />
                                                    {errors.member_no && (
                                                        <small>
                                                            {errors.member_no.type === 'required' && (
                                                                <span className="text-danger">
                                                                    {t('members.error.memberno')}
                                                                </span>
                                                            )}
                                                            {errors.member_no.type === 'pattern' && (
                                                                <span className="text-danger">
                                                                    {t('members.error.membernoinvalid')}
                                                                </span>
                                                            )}
                                                        </small>
                                                    )}
                                                </div>
                                                <div className="col-md-4 row pe-0 mb-3">
                                                    <div className='form-group pe-0 col-md-6'>
                                                        <label className="form-label">
                                                            {t('members.label.countrycode')}
                                                            {/* <span className="text-danger ms-1">*</span> */}
                                                        </label>
                                                        <CountryCode
                                                            countryCode={countryCode}
                                                            setCountryCode={setCountryCode}
                                                            direction="bottom"
                                                        />
                                                    </div>
                                                    <div className='form-group pe-0 col-md-6'>
                                                        <label className="form-label">
                                                            {t('members.label.mobileno')}
                                                            <span className="text-danger ms-1">*</span>
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className={`form-control ${errors.phone && 'is-invalid'
                                                                }`}
                                                            placeholder={t('members.placeholder.mobileno')}
                                                            {...register('phone', { required: true, pattern: formPattern.phoneNumber })}
                                                        />
                                                        {errors.phone && (
                                                            <small>
                                                                {errors.phone.type === 'required' && (
                                                                    <span className="text-danger">
                                                                        {t('members.error.mobileno')}
                                                                    </span>
                                                                )}
                                                                {errors.phone.type === 'pattern' && (
                                                                    <span className="text-danger">
                                                                        {t('members.error.mobilenoinvalid')}
                                                                    </span>
                                                                )}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>


                                                <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                                    <label className="form-label">
                                                        {t('members.label.accesscard')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                    <input
                                                        className={`form-control ${errors.access_card_no && 'is-invalid'
                                                            }`}
                                                        type="text"
                                                        placeholder={t('members.placeholder.accesscard')}
                                                        // disabled={formFieldsDisabled}
                                                        {...register('access_card_no', { required: true, pattern: pattern.notAllowSpaceInStartfront })}
                                                    />
                                                    {errors.access_card_no && (
                                                        <small>
                                                            {errors.access_card_no.type === 'required' && (
                                                                <span className="text-danger">
                                                                    {t('members.error.accesscard')}
                                                                </span>
                                                            )}
                                                            {errors.access_card_no.type === 'pattern' && (
                                                                <span className="text-danger">
                                                                    {t('members.error.accesscardinvalid')}
                                                                </span>
                                                            )}
                                                        </small>
                                                    )}
                                                </div>


                                                <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                                    <label className="form-label">
                                                        {t('members.label.email')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className={`form-control ${errors.email && 'is-invalid'
                                                            }`}
                                                        placeholder={t('members.placeholder.email')}
                                                        {...register('email', { required: true, pattern: formPattern.email })}
                                                    />
                                                    {errors.email && (
                                                        <small>
                                                            {errors.email.type === 'required' && (
                                                                <span className="text-danger">
                                                                    {t('members.error.email')}
                                                                </span>
                                                            )}
                                                            {errors.email.type === 'pattern' && (
                                                                <span className="text-danger">
                                                                    {t('members.error.emailinvalid')}
                                                                </span>
                                                            )}
                                                        </small>
                                                    )}
                                                </div>

                                                <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                                    <label>
                                                        {t('members.label.expirydate')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>

                                                    <Controller
                                                        name="expiry_date"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        render={({ field: { value, onChange } }) => (
                                                            <ReactDatePicker
                                                                value={value}
                                                                onChange={onChange}
                                                                placeholder={t(
                                                                    'members.placeholder.expirydate'
                                                                )}
                                                                hasErrors={errors.expiry_date}
                                                                // maxDate={moment().toDate()}
                                                                minDate={moment().toDate()}
                                                                dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                                                            // formFieldsDisabled={formFieldsDisabled}
                                                            />
                                                        )}
                                                    />
                                                    {errors.expiry_date && (
                                                        <small>
                                                            {errors.expiry_date.type === 'required' && (
                                                                <span className="text-danger">
                                                                    {t('members.error.expirydate')}
                                                                </span>
                                                            )}
                                                        </small>
                                                    )}
                                                </div>



                                                <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                                    <label>
                                                        {t('members.label.category')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                    <Controller
                                                        name="category"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        render={({ field: { value, onChange } }) => (
                                                            <SearchableDropdown
                                                                value={value}
                                                                onChange={onChange}
                                                                options={memberCategoryList}
                                                                hasError={errors.category}
                                                                placeholder={t('members.placeholder.category')}
                                                            // formFieldsDisabled={formFieldsDisabled}
                                                            />
                                                        )}
                                                    />
                                                    {errors.category && (
                                                        <small>
                                                            {errors.category.type === 'required' && (
                                                                <span className="text-danger">
                                                                    {t('members.error.category')}
                                                                </span>
                                                            )}
                                                        </small>
                                                    )}
                                                </div>


                                            </div>




                                            <div className="col-md-12">
                                                <div className="row justify-center">
                                                    <div className="col-12 d-flex justify-content-end form-group mb-3">
                                                        <div className="d-flex gap-2">
                                                            <select value={selectedHostType} onChange={handleSelectHostType} className="form-control mt-auto mb-auto">
                                                                <option value="">Select Option</option>
                                                                <option value="2">Guest Gate</option>
                                                                <option value="3">Members Only Gate</option>
                                                                <option value="4">Serengeti Gate</option>
                                                                <option value="5">Control Room</option>
                                                                <option value="6">Mail Room</option>
                                                            </select>
                                                            <button
                                                                className="btn btn-primary btn-lg"
                                                                type="button"
                                                                onClick={() => getAccessCardNumber()}
                                                            >
                                                                {t('casualworkers.casualworkerformlabel.verifyCard')}
                                                            </button>
                                                            <button type="submit" className="btn btn-primary me-1">
                                                                {t('members.btnLabel.add')}
                                                            </button>
                                                            <button
                                                                type="reset"
                                                                className="btn btn-danger"
                                                                onClick={onReset}
                                                            >
                                                                {t('members.btnLabel.reset')}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>






                                        <div style={{ marginTop: "8rem", marginLeft: "-1rem", padding: ".6rem" }}>
                                            <ReactTable
                                                columns={columns}
                                                data={membersData}
                                                pageSizeOption={[5, 10, 15, 20]}
                                                headerClass="btn-primary"
                                                isSearchBoxVisible
                                                ispaginationvisible={true}
                                            />
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </form>
        </>
    );
}
