import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
// import logo from 'assets/logo/logo.png';
import logo from 'assets/logo/Centenary_Logo2.png'
import { useTranslation } from 'react-i18next';
import nameLogo from 'assets/logo/name-logo.png';
import '../../assets/css/navbar.css'

const Logo = ({ at, width, className, textClass, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
      style={{ padding: "0rem 0rem" }}
    >
      <div
        className={classNames(
          'd-flex  smalltopnav',
          {
            'align-items-center': at === 'navbar-vertical',
            'align-items-center py-3': at === 'navbar-top',
            'flex-center fw-bolder fs-5 mb-4': at === 'auth'
          },
          className
        )}
      // style={{ marginLeft: "2rem" }}
      >
        <img className="me-2" src={logo} alt="Logo" width={80} />
        <span className={classNames('font-sans-serif', textClass)}>
          <h5 className="fw-bold logo-text" >{t('header.headertext')}</h5>
          {/* <img className="me-2" src={nameLogo} alt="Logo" width={100} /> */}
        </span>
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
