import { masterEndPoints } from "./config"
import httpServices from "./httpServices"

export const departmentlist = async () => {
    return await httpServices.get(masterEndPoints.listdepartment)
}
export const departmentadd = async (departmentData) => {
    return await httpServices.post(masterEndPoints.adddepartment, departmentData)
}
export const departmentdelete = async (id) => {
    return await httpServices.post(masterEndPoints.deletedepartment, id)
}
export const rolelist = async () => {
    return await httpServices.get(masterEndPoints.listrole)
}
export const getrolelist = async () => {
    return await httpServices.get(masterEndPoints.getrolelist)
}
export const rolelistpermission = async (id) => {
    return await httpServices.post(masterEndPoints.listpermission, id)
}
export const permissionupdate = async (permissionData) => {
    return await httpServices.post(masterEndPoints.updatepermission, permissionData)
}