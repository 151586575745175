import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import countryCodes, {
  defaultCountryCode
} from 'components/configuration/country-code';
import pattern from 'components/configuration/form-pattern';
import ReactDatePicker from 'components/common/ReactDatePicker';
import SearchableDropdown from 'components/common/SearchableDropdown';
import {
  categoryOptions,
  genderOptions
} from 'components/configuration/common-options';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import moment from 'moment';
import ReactTable from 'components/common/ReactTable';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneNumber from 'components/common/PhoneNumber';
import {
  dropdownmembercategory,
  membersupdate
} from 'services/adminmoduleService';
import '../../../../assets/css/general.css';
import Swalcolor from 'components/configuration/sweetalert-config';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import '../casualworkers/casual.css';
import { loaderContext } from 'context/Context';
import CountryCode from 'components/common/CountryCode';
import formPattern from 'components/configuration/form-pattern';

export default function EditMember() {
  const { t } = useTranslation();

  const location = useLocation();
  const [memberCategoryList, setMemberCategoryList] = useState([]);
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState(defaultCountryCode);
  const { isLoading, setIsLoading } = useContext(loaderContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
    clearErrors,
    setError,
    watch
  } = useForm();

  console.log('membereditdata==========>', location?.state);

  useEffect(async () => {
    checkModulePermission('Add Members');
    setIsLoading(true);
    try {
      const { data: membCategory } = await dropdownmembercategory();
      const category = membCategory?.data?.map(mem => ({
        label: mem.name,
        value: mem.id
      }));
      setMemberCategoryList(category);

      setValue('member_name', location?.state?.member_name);
      setValue('member_no', location?.state?.member_no);
      setValue('phone', location?.state?.phone);
      setValue('access_card_no', location?.state?.access_card_no);
      setValue(
        'expiry_date',
        location?.state?.expiry_date
          ? moment(location?.state?.expiry_date).toDate()
          : ''
      );
      setValue(
        'category',
        category?.find(cate => cate?.label == location?.state?.category)
      );
      setValue('email', location?.state?.email);
      setCountryCode(
        countryCodes?.find(
          country => country?.dial_code === location?.state?.country_code
        )
      );
    } catch (e) {
      errorMessage(e);
    }
    setIsLoading(false);
  }, []);

  const onSubmitVisitorData = async memberData => {
    setIsLoading(true);
    try {
      if (memberData?.expiry_date) {
        memberData.expiry_date = moment(memberData?.expiry_date).format(
          process.env.REACT_APP_DATE_FORMAT
        );
      }
      memberData.country_code = countryCode?.dial_code;
      memberData.category = memberData?.category?.value;
      const { data } = await membersupdate({
        id: location?.state?.id,
        ...memberData
      });
      reset();
      setValue('category', '');
      toast.success(data?.message, toastConfig);
      navigate('/admin/members');
    } catch (e) {
      if (e.response.data) {
        if (Array.isArray(e.response.data.message)) {
          e.response.data.message.map(errors =>
            toast.error(errors, toastConfig)
          );
        } else {
          toast.error(e.response.data.message, toastConfig);
        }
      } else {
        toast.error(e.message, toastConfig);
      }
    }
    setIsLoading(false);
  };

  const onReset = () => {
    reset();
    setValue('category', '');
    setCountryCode(defaultCountryCode);
  };

  return (
    <>
      <form
        encType="multipart/form-data"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitVisitorData)}
        className="mainn-registerguest"
      >
        <div className="row ">
          <div>
            <div className="card overfolw-hidden mb-3">
              <div className="card-header pb-0">
                <div className="row">
                  <div className="col-8">
                    <h3 className="heading-color fw-600">
                      {t('members.editheading')}
                    </h3>
                  </div>

                  <div className="col-4 table-btn">
                    <button
                      className="btn btn-primary float-end btn-sm"
                      onClick={() => navigate('/admin/members')}
                    >
                      <FontAwesomeIcon icon="chevron-left" />
                      <span className="ms-1">
                        {t('eventLocation.btnLabel.back')}
                      </span>
                    </button>
                  </div>
                </div>
                <div className="col-12">
                  <hr />
                </div>
              </div>
              <div className="row g-3 mb-3">
                <div className="table-body">
                  <div className="card-body pt-0">
                    <div>
                      <div className="row">
                        <div className="form-group col-md-4 mb-3">
                          <label className="form-label">
                            {t('members.label.membername')}
                            <span className="text-danger ms-1">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.member_name && 'is-invalid'
                            }`}
                            placeholder={t('members.placeholder.membername')}
                            {...register('member_name', {
                              required: true,
                              pattern: pattern.notAllowSpaceInStartfront
                            })}
                          />
                          {errors.member_name && (
                            <small>
                              {errors.member_name.type === 'required' && (
                                <span className="text-danger">
                                  {t('members.error.membername')}
                                </span>
                              )}
                              {errors.member_name.type === 'pattern' && (
                                <span className="text-danger">
                                  {t('members.error.membernameinvalid')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>
                        <div className="form-group col-md-4 mb-3">
                          <label className="form-label">
                            {t('members.label.memberno')}
                            <span className="text-danger ms-1">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.member_no && 'is-invalid'
                            }`}
                            placeholder={t('members.placeholder.memberno')}
                            {...register('member_no', {
                              required: true,
                              pattern: pattern.notAllowSpaceInStartfront
                            })}
                          />
                          {errors.member_no && (
                            <small>
                              {errors.member_no.type === 'required' && (
                                <span className="text-danger">
                                  {t('members.error.memberno')}
                                </span>
                              )}
                              {errors.member_no.type === 'pattern' && (
                                <span className="text-danger">
                                  {t('members.error.membernoinvalid')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>
                        <div className="col-md-4 row pe-0 mb-3">
                          <div className="form-group pe-0 col-md-6">
                            <label className="form-label">
                              {t('members.label.countrycode')}
                              {/* <span className="text-danger ms-1">*</span> */}
                            </label>
                            <CountryCode
                              countryCode={countryCode}
                              setCountryCode={setCountryCode}
                              direction="bottom"
                            />
                          </div>
                          <div className="form-group pe-0 col-md-6">
                            <label className="form-label">
                              {t('members.label.mobileno')}
                              <span className="text-danger ms-1">*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.phone && 'is-invalid'
                              }`}
                              placeholder={t('members.placeholder.mobileno')}
                              {...register('phone', {
                                required: true,
                                pattern: formPattern.phoneNumber
                              })}
                            />
                            {errors.phone && (
                              <small>
                                {errors.phone.type === 'required' && (
                                  <span className="text-danger">
                                    {t('members.error.mobileno')}
                                  </span>
                                )}
                                {errors.phone.type === 'pattern' && (
                                  <span className="text-danger">
                                    {t('members.error.mobilenoinvalid')}
                                  </span>
                                )}
                              </small>
                            )}
                          </div>
                        </div>

                        <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                        <label className="form-label">
                                            {t('members.label.accesscard')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.access_card_no && 'is-invalid'
                                                }`}
                                            type="text"
                                            placeholder={t('members.placeholder.accesscard')}
                                            // disabled={formFieldsDisabled}
                                            {...register('access_card_no', { required: true, pattern: pattern.notAllowSpaceInStartfront })}
                                        />
                                        {errors.access_card_no && (
                                            <small>
                                                {errors.access_card_no.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('members.error.accesscard')}
                                                    </span>
                                                )}
                                                {errors.access_card_no.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('members.error.accesscardinvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>

                        <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                          <label className="form-label">
                            {t('members.label.email')}
                            <span className="text-danger ms-1">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              errors.email && 'is-invalid'
                            }`}
                            placeholder={t('members.placeholder.email')}
                            {...register('email', {
                              required: true,
                              pattern: formPattern.email
                            })}
                          />
                          {errors.email && (
                            <small>
                              {errors.email.type === 'required' && (
                                <span className="text-danger">
                                  {t('members.error.email')}
                                </span>
                              )}
                              {errors.email.type === 'pattern' && (
                                <span className="text-danger">
                                  {t('members.error.emailinvalid')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>

                        <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                          <label>
                            {t('members.label.expirydate')}
                            <span className="text-danger ms-1">*</span>
                          </label>

                          <Controller
                            name="expiry_date"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                              <ReactDatePicker
                                value={value}
                                onChange={onChange}
                                placeholder={t(
                                  'members.placeholder.expirydate'
                                )}
                                hasErrors={errors.expiry_date}
                                // maxDate={moment().toDate()}
                                minDate={moment().toDate()}
                                dateFormat={
                                  process.env.REACT_APP_DATE_PICKER_FORMAT
                                }
                                // formFieldsDisabled={formFieldsDisabled}
                              />
                            )}
                          />
                          {errors.expiry_date && (
                            <small>
                              {errors.expiry_date.type === 'required' && (
                                <span className="text-danger">
                                  {t('members.error.expirydate')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>

                        <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                          <label>
                            {t('members.label.category')}
                            <span className="text-danger ms-1">*</span>
                          </label>
                          <Controller
                            name="category"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                              <SearchableDropdown
                                value={value}
                                onChange={onChange}
                                options={memberCategoryList}
                                hasError={errors.category}
                                placeholder={t('members.placeholder.category')}
                                // formFieldsDisabled={formFieldsDisabled}
                              />
                            )}
                          />
                          {errors.category && (
                            <small>
                              {errors.category.type === 'required' && (
                                <span className="text-danger">
                                  {t('members.error.category')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="mb-3 mt-3 text-end">
                        <button type="submit" className="btn btn-primary me-1">
                          {t('members.btnLabel.update')}
                        </button>
                        <button
                          type="reset"
                          className="btn btn-danger"
                          onClick={onReset}
                        >
                          {t('members.btnLabel.reset')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
