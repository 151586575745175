import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import ReactTable from 'components/common/ReactTable';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import Swalcolor from 'components/configuration/sweetalert-config';
import { encodeData } from 'components/common/encodeParams';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { departmentlist, departmentdelete } from 'services/masterModuleService'
import SearchableDropdown from 'components/common/SearchableDropdown';
import { accessupdate, dropdownlist } from "services/accessControlService"
import { useLocation } from 'react-router';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import pattern from 'components/configuration/form-pattern';

export default function EditAccessControl() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()
    const { isLoading, setIsLoading } = useContext(loaderContext)

    const [device, setDevice] = useState([])
    const [door, setDoor] = useState([])

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        clearErrors,
        setError,
        watch,
        formState: { errors }
    } = useForm();

    const selectedHost = watch("hosttype");

    console.log("loca", location)

    // useEffect(async () => {
    //     try {
    //         const { data } = await dropdownlist();
    //         setDoor(data?.data[0]?.door_type?.map((door) => ({ label: door.type, value: door.type })))
    //         setDevice(data?.data[0]?.device_list?.map((device) => ({ label: device.device_name, value: device.device_id })))
    //     } catch (e) {
    //         errorMessage(e)
    //     }
    // }, []);


    useEffect(() => {
        checkModulePermission("Access Control")
        setValue("name", location?.state?.door_name)
        setValue("device_name", location?.state?.door_ip)
        setValue("door_type", location?.state?.door_type)
    }, [])


    const onSubmit = async (newData) => {
        setIsLoading(true)
        try {
            console.log("dev", device)
            newData.device_id = location?.state?.device_id
            const { data } = await accessupdate({ id: location?.state?.id, ...newData })
            toast.success(data?.message, toastConfig)
            navigate('/masters/access-control')
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    };
    const resetValue = () => {
        reset();
        setValue("hosttype", "")
    };

    return (
        <>
            <div className="row g-3 mb-3">
                <div className="">
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="heading-color fw-600 mb-0">
                                        {t('accesscontrol.editheading')}
                                    </h3>
                                </div>
                                <div className="col-4 table-btn">
                                    <button
                                        className="btn btn-primary float-end btn-sm"
                                        onClick={() => navigate('/masters/access-control')}
                                    >
                                        <FontAwesomeIcon icon="chevron-left" />
                                        <span className="ms-1">
                                            {t('accesscontrol.back')}
                                        </span>
                                    </button>
                                </div>
                                <div className="col-12" >
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                {t('accesscontrol.label.doorname')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                className={`form-control ${errors.name ? 'is-invalid' : ''
                                                    }`}
                                                type="text"
                                                placeholder={t(
                                                    'accesscontrol.placeholder.doorname'
                                                )}
                                                {...register('name', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                            />
                                            {errors.name && (
                                                <small className="text-danger">
                                                    {errors.name.type == 'required' && (
                                                        <span>
                                                            {t('accesscontrol.error.doorname')}
                                                        </span>
                                                    )}
                                                    {errors.name.type == 'pattern' && (
                                                        <span>
                                                            {t('accesscontrol.error.doornameinvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                {t('accesscontrol.label.devicename')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                control={control}
                                                rules={{ required: true }}
                                                name="device_name"
                                                render={({ field: { value, onChange } }) => (
                                                    <input
                                                        className={`form-control ${errors.name ? 'is-invalid' : ''
                                                            }`}
                                                        options={device}
                                                        isClearable={false}
                                                        onChange={onChange}
                                                        hasError={errors.device_name}
                                                        placeholder={t(
                                                            'accesscontrol.placeholder.devicename'
                                                        )}
                                                        value={value}
                                                        disabled={true}
                                                    // noOptionsMessage={() =>
                                                    //   t('departmentMaster.error.selectCompanyMsg')
                                                    // }
                                                    />
                                                )}
                                            />
                                            {errors.device_name && (
                                                <small className="text-danger">
                                                    {errors.device_name.type == 'required' && (
                                                        <span>
                                                            {t('accesscontrol.error.devicename')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                    </div>




                                    <div className="col-12 col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                {t('accesscontrol.label.doortype')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                control={control}
                                                rules={{ required: true }}
                                                name="door_type"
                                                render={({ field: { value, onChange } }) => (
                                                    <input
                                                        className={`form-control ${errors.name ? 'is-invalid' : ''
                                                            }`}
                                                        options={door}
                                                        isClearable={false}
                                                        onChange={onChange}
                                                        hasError={errors.door_type}
                                                        placeholder={t(
                                                            'accesscontrol.placeholder.doortype'
                                                        )}
                                                        value={value}
                                                        disabled={true}
                                                    // noOptionsMessage={() =>
                                                    //   t('departmentMaster.error.selectCompanyMsg')
                                                    // }
                                                    />
                                                )}
                                            />
                                            {errors.door_type && (
                                                <small className="text-danger">
                                                    {errors.door_type.type == 'required' && (
                                                        <span>
                                                            {t('accesscontrol.error.doortype')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                    </div>

                                    <div className="text-start col-12">
                                        <div className="mb-3 text-end">
                                            <button type="submit" className="btn btn-primary me-1">
                                                {t('accesscontrol.update')}
                                            </button>
                                            <button
                                                type="reset"
                                                className="btn btn-danger"
                                                onClick={resetValue}
                                            >
                                                {t('accesscontrol.reset')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* <div className="card-body pt-0">
                            <ReactTable
                                columns={columns}
                                data={departmentData}
                                pageSizeOption={[5, 10, 15, 20]}
                                headerClass="btn-primary"
                                isSearchBoxVisible={false}
                            />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}
