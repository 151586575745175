import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toastConfig from 'components/configuration/toast-config';
import pattern from 'components/configuration/form-pattern';
import PhoneNumber from 'components/common/PhoneNumber';
import { useState } from 'react';
import { defaultCountryCode } from 'components/configuration/country-code';
import { forgotPassword } from 'services/authenticationService';
import { loaderContext } from 'context/Context';


const ForgetPasswordForm = ({ hasLabel }) => {
  const [countryCode, setCountryCode] = useState(defaultCountryCode);
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control
  } = useForm();
  const navigate = useNavigate();

  // Handler
  const onSubmit = async formData => {
    setIsLoading(true)
    try {
      const { data } = await forgotPassword(formData);
      console.log("Dsts", data)
      toast.success(data?.message, toastConfig)
      navigate('/verify-otp', { state: { email: formData.email } });
    } catch (e) {
      if (e.response.data) {
        if (Array.isArray(e.response.data.message)) {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else {
          toast.error(e.response.data.message, toastConfig)
        }
      } else {
        toast.error(e.message, toastConfig)
      }
    }
    setIsLoading(false)
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {/* <div className="form-group mb-3">
        {hasLabel && (
          <div className="form-label">
            {t('forgotPasswordFormLabel.phoneNoLabel')}
          </div>
        )}
        <Controller
          name="phoneNo"
          control={control}
          rules={{ required: true, pattern: pattern.phoneNumber }}
          render={({ field: { value, onChange } }) => (
            <PhoneNumber
              value={value}
              onChange={onChange}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              error={errors.phoneNo}
              dropdownDirection="bottom"
            />
          )}
        />
        {errors.phoneNo && (
          <small className="text-danger text-align-start">
            {errors.phoneNo.type === 'required' && (
              <span>{t('forgotPasswordFormError.phoneNoRequired')}</span>
            )}
            {errors.phoneNo.type === 'pattern' && (
              <span>{t('forgotPasswordFormError.phoneNoPattern')}</span>
            )}
          </small>
        )}
      </div> */}
      <div className="mb-3 form-group">
        {hasLabel && (
          <div className="form-label">{t('loginFormLabel.emailLabel')}</div>
        )}
        <input
          className={`form-control ${errors.email && 'is-invalid'}`}
          placeholder={
            !hasLabel ? t('forgotPasswordFormLabel.emailLabel') : ''
          }
          type="email"
          {...register('email', { required: true, pattern: pattern.email })}
        />
        {errors.email && (
          <small className="text-danger">
            {errors.email.type === 'required' && (
              <span>{t('loginFormError.emailRequired')}</span>
            )}
            {errors.email.type === 'pattern' && (
              <span>{t('loginFormError.emailPattern')}</span>
            )}
          </small>
        )}
      </div>

      <div className="mb-3 form-group">
        <Button className="w-100 btn-lg" type="submit">
          {t('forgotPasswordFormLabel.sendOtpBtnLabel')}
        </Button>
      </div>
    </form>
  );
};
ForgetPasswordForm.propTypes = {
  hasLabel: PropTypes.bool
};

ForgetPasswordForm.defaultProps = {
  hasLabel: false
};
export default ForgetPasswordForm;
