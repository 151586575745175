export default{
    loginFormError: {
        emailRequired:"ઈ-મેઈલ જરૂરી છે!",
        emailPattern:"કૃપા કરીને, માન્ય ઇમેઇલ લખો!",
        passwordRequired:"પાસવર્ડ જરૂરી છે!"
    },
    loginFormLabel:{
        emailLabel:"ઈ-મેઈલ સરનામું",
        passwordLabel:"પાસવર્ડ",
        loginbtnLabel:"પ્રવેશ કરો",
        forgotPasswordLinkLabel:"પાસવર્ડ ભૂલી ગયા છો?"
    },
    loginFormPlaceholder:{
        emailPlaceholder:"ઈ-મેઈલ સરનામું",
        passwordPlaceholder:"પાસવર્ડ"
    }
}
