// export default {
//   email:
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/,
//   phoneNumber: /^[0-9]*$/,
//   otp: /^\d+$/,
//   password: /^[a-zA-Z0-9_.-]*$/,
//   numberAndAlphabet: /^[a-zA-Z0-9 _.-]*$/,
//   numberOnly: /^\d+$/,
//   alphabetWithSpace: /^[a-zA-Z ]*$/,
//   IPv4: /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/,
//   visitorName: /^[a-zA-Z ]*$/,
//   designation: /^[a-zA-Z ]*$/,
//   fullName: /^[a-zA-Z ]*$/,
//   userName: /^[^-\s][a-zA-Z0-9_\s-]+$/,
//   notAllowSpaceInStart: /^[a-zA-Z][a-zA-Z ]*$/,
//   // notAllowSpaceInStart: /^[a-zA-Z0-9][a-zA-Z0-9 ]*$/
// };

export default {
  email: /^(?! )[^\s@]+@[^\s@]+\.[^\s@]+$/,
  phoneNumber: /^[0-9]*$/,
  otp: /^\d+$/,
  password: /^(?! )[a-zA-Z0-9_.-]*$/,
  numberAndAlphabet: /^(?! )[a-zA-Z0-9 _.-]*$/,
  numberOnly: /^\d+$/,
  alphabetWithSpace: /^(?! )[a-zA-Z ]*$/,
  IPv4: /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/,
  visitorName: /^(?! )[a-zA-Z ]*$/,
  designation: /^(?! )[a-zA-Z ]*$/,
  fullName: /^(?! )[a-zA-Z ]*$/,
  userName: /^(?! )[^-\s][a-zA-Z0-9_\s-]+$/,
  notAllowSpaceInStart: /^[a-zA-Z][a-zA-Z ]*$/,
  notAllowSpaceInStartfront: /^[^-\s][a-zA-Z0-9_\s-]+$/,
  noSpace: /^\S*$/
};
