import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import formPattern from 'components/configuration/form-pattern';
import InputPassword from 'components/common/InputPassword';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactTimePicker from 'components/common/ReactTimePicker';
import ReactDatePicker from 'components/common/ReactDatePicker';
import CountryCode from 'components/common/CountryCode';
import { useState } from 'react';
import Wizard from 'components/common/Wizard';
import { AuthWizardContext, loaderContext } from 'context/Context';
import ReactDateAndTimePicker from 'components/common/ReactDateAndTimePicker';
import moment from 'moment';
import { eventBooking } from 'context/Context';
import ReactTables from 'components/common/ReactTable';
import { createNewEvent, getExternalEventList, updateExternalEvent } from 'services/bookingService';
import { update } from 'lodash';
import { Alert } from 'react-bootstrap';
import _ from 'lodash';
import pattern from 'components/configuration/form-pattern';




function EventCreate({ register, errors, setValue, handleSubmit, reset, control, Controller, clearErrors }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [countryCode, setCountryCode] = useState({});
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const { memberData, locationList } = useContext(eventBooking)
    const [submitedData, setSubmitedData] = useState({});
    const [isSubmitPress, setIsSubmitPress] = useState(false)
    const [events, setEvents] = useState([])
    // const [updateEvent, setUpdateEvent] = useState({})
    const [updateEventId, setUpdateEventId] = useState("")
    const { user, setUser, step, setStep } = useContext(AuthWizardContext);

    // const onSubmit = async accessData => {
    //     try {
    //         const { data } = await addAccess(accessData);
    //         toast.success(data.data.result, toastConfig);
    //         navigate('/master/access-control');
    //     } catch (e) {
    //         errorMessage(e)
    //     }
    //     console.log(accessData);
    // };
    useEffect(() => {
        if (step == 1 && user?.event_id) {
            setUpdateEventId(user?.event_id)
        }
    }, [step])
    const getEvents = async () => {
        setIsLoading(true)
        try {
            const { data } = await getExternalEventList({ member_number: memberData?.MemberNumber })
            setEvents(data?.data)
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)

    }
    useEffect(() => {
        if (events.length === 0 && memberData?.MemberNumber) {
            getEvents()
        }
    }, [memberData])
    const columns = [
        {
            Header: t('eventbooking.label.no'),
            accessor: (row, index) => index + 1
        },
        {
            Header: t('eventbooking.label.eventname'),
            accessor: 'name',
        },
        {
            Header: t('eventbooking.label.eventLocation'),
            accessor: 'location',
        },
        {
            Header: t('eventbooking.label.noOfPeople'),
            accessor: 'guest_no',
        },
        {
            Header: t('eventbooking.label.status'),
            accessor: 'status',
            Cell: ({ row }) => {
                return (
                    <div>{`${row.original.status === 0 ? t('eventbooking.label.pending') : row.original.status === 1 ? t('eventbooking.label.approval') : t('eventbooking.label.decline')}`}</div>
                );
            }
        },
        {
            Header: t('eventbooking.label.eventDate'),
            accessor: 'date',
            Cell: ({ value }) => {
                const eventDate = new Date(value);
                const currentDate = new Date();
                if (eventDate > currentDate) {
                    return <span style={{ color: 'green' }}>{value}</span>;
                } else {
                    return <span style={{ color: 'orange' }}>{value}</span>;
                }
            },
        },
        {
            Header: t('eventbooking.label.action'),
            Cell: ({ row }) => (
                <div>
                    {
                        moment().toDate() <= moment(row?.original?.date).subtract(1, "hour") ? row.original.status === 1 && <span
                            className="btn p-0 me-2"
                            title="Edit"
                            onClick={() => editEvent(row.original.event_id)}
                        >
                            <FontAwesomeIcon icon="edit" />
                        </span> :
                            <span
                                className="btn p-0 me-2"
                                title="View"
                                onClick={() => viewEvent(row.original.event_id)}
                            >
                                <FontAwesomeIcon icon="eye" />
                            </span>
                    }
                </div>
            ),
            disableSortBy: true
        }
    ];
    const viewEvent = (id) => {
        setUser({ ...user, eventData: { event_id: id, event_notEdit: true } })
        setStep(3)

    }
    const editEvent = (id) => {
        // const updateEvent = events.find((event) => event?.event_id == id)
        // console.log("Update Event", id, updateEvent)
        // setValue("name", updateEvent?.name)
        // setValue("no_of_guest", updateEvent?.guest_no)
        // setValue("location_id", locationList.find(location => location?.value == updateEvent?.location_id))
        // setValue("date", moment(updateEvent?.date).toDate())
        // // setUpdateEvent(updateEvent)
        // setUpdateEventId(id)
        // setSubmitedData({})
        // setUser({ ...user, eventData: updateEvent })
        // window.scrollTo(0, 0)
        // clearErrors()
        const updateEvent = events.find((event) => event?.event_id == id)
        setUser({ ...user, eventData: updateEvent })
        setStep(step + 1);
    }
    // useEffect(() => {
    //     if (updateEvent && Object.keys(updateEvent)?.length > 0) {
    //         setValue("name", updateEvent?.name)
    //         setValue("no_of_guest", updateEvent?.guest_no)
    //         setValue("location_id", locationList.find(location => location?.value == updateEvent?.location_id))
    //         setValue("date", moment(updateEvent?.date).toDate())
    //     }
    // }, [updateEventId])
    useEffect(() => {
        setValue("member_name", `${memberData?.FirstName} ${memberData?.lastName}`)
        setValue("member_number", memberData?.MemberNumber)
    }, [memberData])
    const setDataToServer = async () => {
        setIsLoading(true)
        try {
            const { data } = await createNewEvent(submitedData)
            setEvents([...events, data?.data[0]?.event_details])
            toast.success(data?.message, toastConfig)
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }
    const updateEventToServer = async (formData) => {
        setIsLoading(true)
        console.log("FormDsata", formData)
        try {
            formData.event_id = updateEventId
            formData.location_id = formData?.location_id?.value
            formData.date = moment(formData?.date).format(process.env.REACT_APP_DATE_TIME_FORMAT)
            const { data } = await updateExternalEvent(formData)
            toast.success(data?.message, toastConfig)
            setEvents(events.map((event) => {
                if (event?.event_id == updateEventId) {
                    return data?.data[0]?.event_details;
                }
                return event
            }))
            setValue("name", "")
            setValue("no_of_guest", "")
            setValue("location_id", "")
            setValue("date", "")
            setUser(_.omit(user, "eventData"))
            // setUpdateEvent({})
            setUpdateEventId("")
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)

    }
    // const setDataToServerWithPandingApproval = async () => {
    //     try {
    //         submitedData.id = updateEventId
    //         const { data } = await updateExternalEvent(submitedData)
    //         toast.success(data?.message, toastConfig)
    //         setEvents((event) => {
    //             if (event?.event_id == updateEventId) {
    //                 return submitedData;
    //             }
    //             return event
    //         })
    //         setSubmitedData({})
    //         setUpdateEvent({})
    //         setUpdateEventId("")
    //     } catch (e) {
    //         errorMessage(e)
    //     }
    // }
    const onClickNext = () => {
        if (user?.eventData?.status == 1) {
            // setUser({ ...user, ...updateEvent });
            setStep(step + 1);
        } else if (user?.eventData?.status == 0) {
            toast.error("Event is not approved yet.", toastConfig)
        } else {
            toast.error("Event is decilne.", toastConfig)
        }
    }
    // const changeStep = () => {
    //     setUser({ ...user, ...data });
    //     setStep(step + 1);
    // }
    useEffect(() => {
        if (isSubmitPress) {
            // if (Object.keys(submitedData).length > 0) {
            setDataToServer()
            // } 
            // else {
            //     if ((updateEvent?.status === 1 || updateEvent?.status === 2) && getBothObectAreSame(updateEvent, submitedData)) {
            //         setDataToServerWithPandingApproval()
            //     } else {
            //         changeStep()
            //     }
            // }
            setValue("name", "")
            setValue("no_of_guest", "")
            setValue("location_id", "")
            setValue("date", "")
            setSubmitedData({})
            // setUpdateEvent({})
            setUpdateEventId("")
        }
    }, [isSubmitPress])
    // const getBothObectAreSame = (updateEvent, submitedData) => {
    //     if (updateEvent?.member_name == submitedData?.member_name && updateEvent?.member_number == submitedData?.member_number && updateEvent?.name == submitedData?.name && updateEvent?.no_of_guest == submitedData?.no_of_guest && updateEvent?.location_id == submitedData?.location_id && updateEvent?.date == submitedData?.date) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }
    const onSubmit = (data) => {
        data.location_id = data?.location_id?.value
        data.date = moment(data?.date).format(process.env.REACT_APP_DATE_TIME_FORMAT)
        setSubmitedData(data)
        setIsSubmitPress(true)
        console.log(data)

    }
    const onReset = () => {
        setValue("name", "")
        setValue("date", "")
        setValue("location_id", "")
        setValue("no_of_guest", "")
        clearErrors()
    };
    const addNewEvent = () => {
        setUser(_.omit(user, "eventData"))
        setValue("name", "")
        setValue("date", "")
        setValue("location_id", "")
        setValue("no_of_guest", "")
    }
    console.log("Events", events)
    return (
        <>
            {/* <div className="mb-3 row g-3">
                <div className="card overflow-hidden mb-3">
                    <div className="card-header"> */}
            {/* <div className="row">
                <form autoComplete="off">
                    {(user?.eventData?.event_id && user?.eventData?.event_id != "") && <Alert key={user?.eventData?.status == 0 ? "warning" : user?.eventData?.status == 1 ? "success" : "danger"} variant={user?.eventData?.status == 0 ? "warning" : user?.eventData?.status == 1 ? "success" : "danger"}>
                        {user?.eventData?.status == 0 ? "Event is pending for approval." : user?.eventData?.status == 1 ? "Event was approved." : "Event was declined."}
                    </Alert>}
                    <div className='row'>

                        <div className="form-group col-md-3 mb-3">
                            <label className="form-label">
                                {t('eventbooking.eventbookingform.membername')}
                                <span className="text-danger ms-1">*</span>
                            </label>
                            <input
                                type='text'
                                className="form-control"
                                placeholder={t('eventbooking.placeholder.membername')}
                                disabled={true}
                                {...register('member_name', { required: true })}
                            />
                            {errors.member_name && (
                                <small>
                                    {errors.member_name.type === 'required' && (
                                        <span className="text-danger">
                                            {t('eventbooking.error.memberNameReq')}
                                        </span>
                                    )}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-3 mb-3">
                            <label className="form-label">
                                {t('eventbooking.eventbookingform.memberno')}
                                <span className="text-danger ms-1">*</span>
                            </label>
                            <input
                                type='text'
                                className="form-control"
                                placeholder={t('eventbooking.placeholder.memberno')}
                                disabled={true}
                                {...register('member_number', { required: true })}
                            />
                            {errors.member_number && (
                                <small>
                                    {errors.member_number.type === 'required' && (
                                        <span className="text-danger">
                                            {t('eventbooking.error.memberNumberReq')}
                                        </span>
                                    )}
                                </small>
                            )}
                        </div>

                        <div className="form-group col-md-3">
                            <label className="form-label">
                                {t('eventbooking.eventbookingform.eventname')}
                                <span className="text-danger ms-1">*</span>
                            </label>
                            <input
                                type='text'
                                className="form-control"
                                placeholder={t('eventbooking.placeholder.eventname')}
                                {...register('name', { required: true, pattern: formPattern.alphabetWithSpace })}
                            />
                            {errors.name && (

                                <small>
                                    {errors.name.type === 'required' && (
                                        <span className="text-danger">
                                            {t('eventbooking.error.nameReq')}
                                        </span>
                                    )}
                                    {errors.name.type === 'pattern' && (
                                        <span className="text-danger">
                                            {t('eventbooking.error.nameInvalid')}
                                        </span>
                                    )}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-3">
                            <label className="form-label">
                                {t('eventbooking.eventbookingform.noofguest')}
                                <span className="text-danger ms-1">*</span>
                            </label>
                            <input
                                type='text'
                                className="form-control"
                                placeholder={t('eventbooking.placeholder.noofguest')}
                                {...register('no_of_guest', { required: true, pattern: formPattern.numberOnly })}
                            />
                            {errors.no_of_guest && (
                                <small>
                                    {errors.no_of_guest.type === 'required' && (
                                        <span className="text-danger">
                                            {t('eventbooking.error.noGuestReq')}
                                        </span>
                                    )}
                                    {errors.no_of_guest.type === 'pattern' && (
                                        <span className="text-danger">
                                            {t('eventbooking.error.noGuestInvalid')}
                                        </span>
                                    )}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-3 mb-3">
                            <label className="form-label">
                                {t('eventbooking.eventbookingform.eventlocation')}
                                <span className="text-danger ms-1">*</span>
                            </label>
                            <Controller
                                name="location_id"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <SearchableDropdown
                                            value={value}
                                            onChange={onChange}
                                            options={locationList}
                                            placeholder={t(
                                                'createEvent.placeholder.eventLocation'
                                            )}
                                            isClearable={true}
                                        />
                                    )
                                }}
                            />
                            {errors.location_id && (
                                <small className="text-danger">
                                    {errors.location_id.type === 'required' && (
                                        <span>{t('eventbooking.error.locationIdReq')}</span>
                                    )}
                                </small>
                            )}
                        </div>
                        <div className="form-group col-md-3 mb-3">
                            <label className="form-label">
                                {t('eventbooking.eventbookingform.dateandtimeofevent')}
                                <span className="text-danger ms-1">*</span>
                            </label>
                            <Controller
                                name="date"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, onChange } }) => (
                                    <ReactDateAndTimePicker
                                        value={value}
                                        onChange={onChange}
                                        hasErrors={errors.date}
                                        placeholder={t("eventbooking.placeholder.dateandtimeofevent")}
                                        dateFormat={process.env.REACT_APP_DATE_TIME_PICKER_FORMAT}
                                        minDate={moment().toDate()}
                                    />
                                )}
                            />
                            {errors.date && (
                                <small>
                                    {errors.date.type === 'required' && (
                                        <span className="text-danger">
                                            {t('eventbooking.error.dateandtimeofevent')}
                                        </span>
                                    )}
                                </small>
                            )}
                        </div>

                    </div>


                </form>
                <div className="mb-3 mt-3 text-end">
                    {(user?.eventData?.event_id && user?.eventData?.event_id != "") && <button onClick={() => addNewEvent()} className="btn btn-primary me-1" >
                        Add New Event
                    </button>}
                    <button onClick={user?.eventData?.event_id && user?.eventData?.event_id != "" ? handleSubmit(updateEventToServer) : handleSubmit(onSubmit)} className="btn btn-primary me-1" >
                        {user?.eventData?.event_id && user?.eventData?.event_id != "" ? "Update" : t('eventbooking.eventbookingform.submit')}
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={user?.eventData?.event_id && user?.eventData?.event_id != "" ? () => onClickNext() : () => onReset()}
                    >
                        {user?.eventData?.event_id && user?.eventData?.event_id != "" ? "Next" : t('eventbooking.eventbookingform.reset')}
                    </button>
                </div>
            </div> */}
            <div className="row">
                {/* <div className="row">
                    <div className="col-12">
                        <h3 className="heading-color fw-600 mb-0 ps-0">
                            {t('eventbooking.events')}
                        </h3>
                    </div>
                    <div className="col-12" >
                        <hr />
                    </div>
                </div> */}
                <ReactTables
                    columns={columns}
                    data={events.length > 0 ? events : []}
                    pageSizeOption={[5, 10, 15, 20]}
                    headerClass="btn-primary"
                    isSearchBoxVisible={true}
                    isPaginationRequired={true}
                    ispaginationvisible={true}
                />
            </div>
            {/* </div>
                </div>
            </div> */}
        </>
    )
}

export default EventCreate