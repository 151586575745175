import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";

const Footer = ({ footerContentColor, contentPosition, }) => {
  const { t } = useTranslation()
  return (
    <footer className="footer">
      <Row className={`justify-content-${contentPosition} text-center fs--1 mt-4 mb-3 `}>
        <Col sm="auto">
          <p className={`mb-0  ${footerContentColor}`}>
            {t("footer.description")}{' '}
          </p>
        </Col>
      </Row>
    </footer>
  )
};
Footer.propTypes = {
  footerContentColor: PropTypes.string,
  contentPosition: PropTypes.string
}
Footer.defaultProps = {
  contentPosition: "center"
}

export default Footer;
