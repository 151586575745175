import React, { useState, useEffect, useMemo, useContext } from 'react';
import ReactTable from 'components/common/ReactTable';
// import { getVisitors, logOutVisitor } from 'services/frontDeskServices';
import toastConfig from 'components/configuration/toast-config';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { data } from './Config'
import { dataLogout, listLogout } from 'services/logoutService'
import { loaderContext } from 'context/Context';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';


export default function VisitorLogout() {
  const [visitorData, setVisitorData] = useState([]);
  const [visitorCount, setVisitorCount] = useState();
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const { t } = useTranslation();
  const [dataId, setDataId] = useState();
  const getLogoutUsers = async () => {
    setIsLoading(true)
    try
    {
      const { data } = await listLogout();
      console.log("dataaaaa", data)
      // const logInData = data.data[0].visitor_list.filter(data => data.timeOut == null && data.id != dataId);
      const logInData = data.data[0]?.visitor_list
      setVisitorCount({ inCount: data.data[0].total_in_count, outCount: data.data[0].total_logout_count, totalCount: data.data[0].today_total_visitor })
      console.log("logindatttt", logInData)
      const addSNo = logInData.map((data, index) => ({
        sNo: index + 1,
        ...data
      }));
      setVisitorData(
        addSNo.map(
          (visitorData, index) => {
            const testDateUtc = moment(visitorData?.arrival_time);
            const localDate = moment(testDateUtc).local();
            visitorData.arrival_time = moment(
              localDate,
              process.env.REACT_APP_TIME_FORMAT_SET_12 === 'false'
                ? process.env.REACT_APP_TIME_FORMAT_24
                : process.env.REACT_APP_TIME_FORMAT_12
            ).toDate(),
              visitorData.no = index + 1
            return visitorData
          }
        )
      )
      console.log('run');
    } catch (e)
    {
      toast.error(e.message, toastConfig);
    }
    setIsLoading(false)
  }
  useEffect(() => {
    checkModulePermission("guard")
  }, [])

  useEffect(async () => {
    getLogoutUsers()
  }, [dataId]);

  console.log("visitordatttt", visitorData);

  const onLogout = async row => {
    console.log("idddd", row?.id)
    setIsLoading(true)
    const visitorbyid = visitorData.find((data) => data.id === row?.id)
    try
    {
      const logoutData = {
        id: row?.id,
        visitor_type: visitorbyid.visitor_type
      };
      const { data } = await dataLogout(logoutData);
      console.log("logoutdatttaaaaataaaataaa", logoutData)
      row.logout_time = data?.data[0]?.logout_time
      setDataId(row?.id);
      toast.success(data?.message, toastConfig);
    } catch (e)
    {
      toast.error(e.message, toastConfig);
    }
    setIsLoading(false)
  };

  const columns = [
    {
      Header: t('visitorLogout.tableHead.sNo'),
      accessor: (row, index) => index + 1,
      headerClass: 'text-white',
    },
    {
      Header: t('visitorLogout.tableHead.visitorName'),
      accessor: 'name',
      headerClass: 'text-white',
      Cell: ({ row }) => {
        return (
          <div>
            <img
              className="me-2 rounded-pill"
              src={row.original.visitorImg}
              height={35}
              width={35}
            ></img>
            <span className="class-for-name">{row.original.name}</span>
          </div>
        );
      }
    },
    {
      Header: t('visitorLogout.tableHead.accessCardNo'),
      accessor: 'access_card_no',
      headerClass: 'text-white',
      Cell: ({ row }) => {
        return (
          <div>
            {row?.original?.access_card_no ? row?.original?.access_card_no : "--"}
          </div>
        );
      }

    },
    {
      Header: t('visitorLogout.tableHead.visitorType'),
      accessor: 'visitor_type_name',
      headerClass: 'text-white',
      Cell: ({ row }) => {
        return (
          <div>
            {row?.original?.visitor_type_name ? row?.original?.visitor_type_name : "--"}
          </div>
        );
      }
    },
    {
      Header: 'Document Number',
      accessor: 'doc_id_number',
      headerClass: 'text-white',
      Cell: ({ row }) => {
        return (
          <div>
            {row?.original?.doc_id_number ? row?.original?.doc_id_number : "--"}
          </div>
        );
      }
    },
    {
      Header: t('visitorLogout.tableHead.timeIn'),
      accessor: 'arrival_time',
      sortType: 'datetime',
      valueType: 'time',
      headerClass: 'text-white',
      Cell: ({ row }) => (
        moment(row?.original?.arrival_time).format(process.env.REACT_APP_DISPLAY_TIME_FORMAT)
      )
      
    },
    {
      Header: t('visitorLogout.tableHead.timeOut'),
      headerClass: 'text-white',
      className: 'text-center',
      // Cell: ({ row }) => (
      //   <div>
      //     <button
      //       className="btn-logout btn-danger border-0 btn-sm"
      //       onClick={() => onLogout(row.original.id)}
      //     >
      //       {t('visitorLogout.btnLabel.logout')}
      //     </button>
      //   </div>
      // )
      Cell: ({ row }) => {
        if (row?.original?.logout_time)
        {
          var testDateUtc = moment(row?.original?.logout_time);
          var localDate = moment(testDateUtc);
        }
        return (<>
          {(!row?.original?.logout_time) ?
            <div>
              <button
                className="btn-logout btn-danger border-0 btn-sm"
                onClick={() => onLogout(row?.original)}
              >
                {t('visitorLogout.btnLabel.logout')}
              </button>
            </div> :
            <div>
              {moment(localDate).format(process.env.REACT_APP_DISPLAY_TIME_FORMAT)}
            </div>
          }
        </>)
      }
    }
  ];

  return (
    <>
      <div className="container-fluid mainn" >
        <div className=" bg-img card table-body overflow-hidden">
          {/* <h5>Visitor Logout</h5> */}
          <ReactTable
            columns={columns}
            data={visitorData.map((data) => { data.timeIn = new Date(data.timeIn); return data })}
            headerClass="btn-primary-frontdesk"
            isSearchBoxVisibleFrontdesk={false}
            isSearchBoxVisible={false}
            ispaginationvisible={true}
            showHeader={true}
            widthClass="table-width-frontdesk"
            hedingName="visitorLogout.heading.visitorLog"
            component={
              <>
                <div className="d-flex  justify-content-center text-center  col-4">
                  <div style={{ maxWidth: "2.6rem" }}>
                    <p className="heading-color-frontdesk fw-bold  m-0 ">
                      {t('visitorLogout.label.totalIn')}
                    </p>
                    <span>{visitorCount?.inCount}</span>
                  </div>
                </div>
                <div className="d-flex justify-content-center text-center col-4">
                  <div style={{ maxWidth: "2.6rem" }}>
                    <p className="heading-color-frontdesk fw-bold  m-0 ">
                      {t('visitorLogout.label.totalOut')}
                    </p>
                    <span>{visitorCount?.outCount}</span>
                  </div>
                </div>
                <div className="d-flex justify-content-center text-center col-4">
                  <div style={{ maxWidth: "6.6rem" }}>
                    <p className="heading-color-frontdesk fw-bold  m-0 ">
                      {t('visitorLogout.label.todaysVisitor')}
                    </p>
                    <span>{visitorCount?.totalCount}</span>
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    </>
  );
}
