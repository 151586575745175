import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import formPattern from 'components/configuration/form-pattern';
import InputPassword from 'components/common/InputPassword';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactTimePicker from 'components/common/ReactTimePicker';
import ReactDatePicker from 'components/common/ReactDatePicker';
import CountryCode from 'components/common/CountryCode';
import { useState } from 'react';
import ReactDateAndTimePicker from 'components/common/ReactDateAndTimePicker';
import CsvFileUploder from 'components/doc-components/CsvFileUploder';
import { createevent } from 'services/manageEventsService'
import { AuthWizardContext } from 'context/Context';
import ReactTable from 'components/common/ReactTable';
import moment from 'moment';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
// import { geteventlist } from 'services/manageEventsService'
import { loaderContext } from 'context/Context';


function EventView() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [countryCode, setCountryCode] = useState({});
    const { user, setUser, step, setStep, } = useContext(AuthWizardContext);
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const [removeFileName, setRemoveFileName] = useState(false);
    const [eventdetailData, setEventDetailData] = useState([]);


    const [locationList, setLocationList] = useState([])
    const location = useLocation();

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        formState: { errors }
    } = useForm();

    console.log("user", user)
    console.log("location", location)
    console.log("evendetaildata", eventdetailData)

    const columns = [

        {
            Header: t('eventbooking.eventdetail.no'),
            accessor: (row, index) => index + 1
        },
        {
            Header: t('eventbooking.eventdetail.guestname'),
            accessor: 'name',
            Cell: ({ row }) => row.original.name ? row.original.name : '--'
        },
        {
            Header: t('eventbooking.eventdetail.guestmbno'),
            // accessor: ({ row }) => `${row.country_code} ${row.phone}`,
            Cell: ({ row }) => row.original.phone ? `${row.original.country_code} ${row.original.phone}` : "--"
        },
        {
            Header: t('eventbooking.eventdetail.guestemail'),
            accessor: 'email',
            Cell: ({ row }) => row.original.email ? row.original.email : '--'
        },
        {
            Header: t('eventbooking.eventdetail.vehicleno'),
            accessor: 'vehicle_reg_no',
            Cell: ({ row }) => row.original.vehicle_reg_no ? row.original.vehicle_reg_no : '--'
        },
    ];



    useEffect(() => {
        checkModulePermission("Events List")
        setValue("name", location?.state?.data.name)
        setValue("eventnumber", location?.state?.data.event_id)
        setValue("member_name", location?.state?.data.member_name)
        setValue("member_number", location?.state?.data.member_number)
        setValue("location_id", { label: location?.state?.data.location, value: location?.state?.data.location_id })
        setValue("date", moment(location?.state?.data.date, "DD-MM-YYYY HH:mm:ss a").toDate())
        setValue("no_of_guest", location?.state?.data.guest_no)
        setLocationList([{ label: location?.state?.data.location, value: location?.state?.data.location_id }])
    }, [])


    // const onSubmit = async (accessData) => {
    //     try {
    //         accessData.guest_list = accessData.members
    //         accessData.location_id = accessData.location_id.label
    //         const { data } = await createevent(accessData);
    //         toast.success(data.data.result, toastConfig);
    //         // navigate('/master/access-control');
    //     } catch (e) {
    //         toast.error(data.data.result, toastConfig);
    //     }
    //     console.log(accessData);
    // };
    // const onReset = () => {
    //     reset();
    //     setValue('accessControlName', '');
    // };
    return (
        <>
            <div className="mb-3 row g-3">
                <div className="card overflow-hidden mb-3">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-8">
                                <h3 className="heading-color fw-600">
                                    {t('createEvent.view')}
                                </h3>
                            </div>
                            <div className="col-4 table-btn" >
                                <button
                                    className="btn btn-primary btn-sm float-end"
                                    onClick={() => {
                                        navigate('/manageevents/event-approval');
                                    }}
                                >
                                    <span className="ms-1 d-sm-inline">
                                        {t('eventLocation.btnLabel.back')}
                                    </span>
                                </button>
                            </div>

                            <div className="col-12 mb-3">
                                <hr />
                            </div>
                        </div>
                        <form autoComplete="off">
                            <div className='row'>
                                {/* <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('createEvent.label.step1')}
                                        </label>
                                        <a href="/index.csv" className='btn btn-primary' download="sample.csv">
                                            {t('createEvent.btnLabel.sampleDownload')}
                                        </a>
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('createEvent.label.step2')}
                                        </label>
                                        <div className='col-7'>
                                            <Controller
                                                name="members"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { } }) => (
                                                    <CsvFileUploder
                                                        setValue={setValue}
                                                        removeFileName={removeFileName}
                                                        setRemoveFileName={setRemoveFileName}
                                                        setName="members"
                                                        hasError={errors.members}
                                                        errorMsg={t('manageGroupVisit.error.fileError')}
                                                        showButton={false}
                                                        placeholderMessageClassName="fs--1 fw-bold my-auto text-dark filetext"
                                                        placeholderMessage={t(
                                                            'manageGroupVisit.placeholder.uploadcsv'
                                                        )}

                                                        borderType={{
                                                            border: `2px dashed ${errors.members ? '#e63757' : '#d8e2ef'
                                                                }`
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div> */}



                                <div className="form-group col-md-4 mb-3">
                                    <label className="form-label">
                                        {t('createEvent.label.eventName')}
                                        {/* <span className="text-danger ms-1">*</span> */}
                                    </label>
                                    <input
                                        type='text'
                                        disabled
                                        className={`form-control ${errors.name && 'is-invalid'
                                            }`}
                                        placeholder={t('createEvent.placeholder.eventName')}
                                        {...register('name', { required: true, pattern: formPattern.alphabetWithSpace })}
                                    />
                                    {errors.name && (
                                        <small>
                                            {errors.name.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('createEvent.error.eventNameReq')}
                                                </span>
                                            )}
                                            {errors.name.type === 'pattern' && (
                                                <span className="text-danger">
                                                    {t('createEvent.error.eventNameInvalid')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>

                                <div className="form-group col-md-4 mb-3">
                                    <label className="form-label">
                                        {t('createEvent.label.eventNumber')}
                                        {/* <span className="text-danger ms-1">*</span> */}
                                    </label>
                                    <input
                                        type='text'
                                        disabled
                                        className={`form-control ${errors.eventnumber && 'is-invalid'
                                            }`}
                                        placeholder={t('createEvent.placeholder.eventNumber')}
                                        {...register('eventnumber', { required: true, pattern: formPattern.alphabetWithSpace })}
                                    />
                                    {errors.eventnumber && (
                                        <small>
                                            {errors.eventnumber.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('createEvent.error.eventNumber')}
                                                </span>
                                            )}
                                            {errors.eventnumber.type === 'pattern' && (
                                                <span className="text-danger">
                                                    {t('createEvent.error.eventNumber')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>


                                <div className="form-group col-md-4 mb-3">
                                    <label className="form-label">
                                        {t('createEvent.label.memberName')}
                                        {/* <span className="text-danger ms-1">*</span> */}
                                    </label>
                                    <input
                                        type='text'
                                        disabled
                                        className={`form-control ${errors.member_name && 'is-invalid'
                                            }`}
                                        placeholder={t('createEvent.placeholder.memberName')}
                                        {...register('member_name', { required: true, pattern: formPattern.alphabetWithSpace })}
                                    />
                                    {errors.member_name && (
                                        <small>
                                            {errors.member_name.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('createEvent.error.memberNameReq')}
                                                </span>
                                            )}
                                            {errors.member_name.type === 'pattern' && (
                                                <span className="text-danger">
                                                    {t('createEvent.error.memberNameInvalid')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-md-4 mb-3">
                                    <label className="form-label">
                                        {t('createEvent.label.memberNo')}
                                        {/* <span className="text-danger ms-1">*</span> */}
                                    </label>
                                    <input
                                        type='text'
                                        disabled
                                        className={`form-control ${errors.member_number && 'is-invalid'
                                            }`}
                                        placeholder={t('createEvent.placeholder.memberNo')}
                                        {...register('member_number', { required: true })}
                                    />
                                    {errors.member_number && (
                                        <small>
                                            {errors.member_number.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('createEvent.error.memberNoReq')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-md-4">
                                    <label className="form-label">
                                        {t('createEvent.label.eventLocation')}
                                        {/* <span className="text-danger ms-1">*</span> */}
                                    </label>
                                    <Controller
                                        name="location_id"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => {
                                            return (
                                                <SearchableDropdown
                                                    value={value}
                                                    onChange={onChange}
                                                    options={[{ label: "Voter ID", value: "voterId" }]}
                                                    placeholder={t(
                                                        'createEvent.placeholder.eventLocation'
                                                    )}
                                                    isClearable={true}
                                                    hasError={errors.location_id}
                                                    formFieldsDisabled={true}
                                                />
                                            )
                                        }}
                                    />
                                    {errors.location_id && (
                                        <small>
                                            {errors.location_id.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('createEvent.error.eventLocationReq')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-md-4">
                                    <label className="form-label">
                                        {t('createEvent.label.dateAndTime')}
                                        {/* <span className="text-danger ms-1">*</span> */}
                                    </label>
                                    <Controller
                                        name='date'
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <ReactDateAndTimePicker
                                                value={value}
                                                onChange={onChange}
                                                hasErrors={errors.date}
                                                placeholder={t("createEvent.placeholder.dateAndTime")}
                                                dateFormat="dd/MM/yyyy HH:mm a"
                                                disabled
                                            />
                                        )}
                                    />
                                    {errors.date && (
                                        <small>
                                            {errors.date.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('createEvent.error.dateAndTimeReq')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-md-4 mb-3">
                                    <label className="form-label">
                                        {t('createEvent.label.noOfGuest')}
                                        {/* <span className="text-danger ms-1">*</span> */}
                                    </label>
                                    <input
                                        className={`form-control ${errors.no_of_guest ? 'is-invalid' : ''
                                            }`}
                                        type="text"
                                        disabled
                                        placeholder={t('createEvent.placeholder.noOfGuest')}
                                        {...register('no_of_guest', {
                                            pattern: formPattern.numberOnly
                                        })}
                                    />
                                    {errors.no_of_guest && (
                                        <small className="text-danger">
                                            {/* {errors.noOfGuest.type === 'required' && (
                                                    <span>{t('createEvent.error.noOfGuestReq')}</span>
                                                )} */}
                                            {errors.no_of_guest.type === 'pattern' && (
                                                <span>{t('createEvent.error.noOfGuestInvalid')}</span>
                                            )}
                                        </small>
                                    )}
                                </div>
                            </div>

                            {/* <div className="mb-3 mt-3 text-end">
                                    <button type="submit" className="btn btn-primary me-1">
                                        {t('createEvent.btnLabel.register')}
                                    </button>
                                    <button
                                        type="reset"
                                        className="btn btn-danger"
                                        onClick={onReset}
                                    >
                                        {t('createEvent.btnLabel.reset')}
                                    </button>
                                </div> */}
                        </form>

                        <div className="pt-4">
                            <ReactTable
                                columns={columns}
                                data={location?.state?.data?.guest_list ? location?.state?.data?.guest_list : []}
                                pageSizeOption={[5, 10, 15, 20]}
                                headerClass="btn-primary"
                                isSearchBoxVisible
                                ispaginationvisible={true}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default EventView