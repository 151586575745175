
export const checkModulePermission = (moduleName) => {
    const newPermission = returnPermissionedModule()
    const isGuard = localStorage.getItem("isGuard")
    if (moduleName == "guard" && isGuard == "true") {
        return true
    } else {
        if (isGuard == "true") {
            window.location.replace("/errors/403")
        } else {
            if (newPermission.includes(moduleName) || moduleName == "Dashboard") {
                return true
            } else {
                window.location.replace("/errors/403")
            }
        }
    }
    console.log("isGuesd", isGuard)

}
const getFormatedPermission = (permissionArray) => {
    let finalPermission = [];
    permissionArray?.forEach((per) => {
        if (Array.isArray(per)) {
            per.forEach((permission) => {
                finalPermission.push(permission)
            })
        } else {
            finalPermission.push(per)
        }
    })
    return finalPermission
}
const returnPermissionedModule = () => {

    const permissionArray = JSON.parse(localStorage.getItem("userPrmission"))?.permission?.map((per) => {
        if (per?.child?.length <= 0) {
            return per.display_name
        } else {
            return per.child.map((permission) => permission.display_name)
        }
    })
    return getFormatedPermission(permissionArray)
}
