import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, set } from 'react-hook-form';
import Swal from 'sweetalert2';
import countryCodes, {
    defaultCountryCode
} from 'components/configuration/country-code';
import PhoneNumber from 'components/common/PhoneNumber';
import pattern from 'components/configuration/form-pattern';
import team3 from 'assets/img/team/2.jpg';
import './css/guest-registration.css';
import ReactDatePicker from 'components/common/ReactDatePicker';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { genderOptions, destinationOptions } from 'components/configuration/common-options';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sweetalertConfig from 'components/configuration/sweetalert-config';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Button } from 'bootstrap';
import { use } from 'echarts';
import ReactTable from 'components/common/ReactTable';
import { checkguestentry, getDestinationList, getGuestRegistrationOTP, getGuestRegistrationOTPbyDoc, getListOfIdentificationType, registerGuests, registerMultipleGuests, registerSingleGuests } from 'services/guestRegistrationService';
import { Modal } from 'react-bootstrap';
import GuestEditModal from './GuestEditModal';
import Webcam from 'react-webcam';
import CapturePhotoModal from '../../common/CapturePhotoModal';
// import Camera from 'components/common/Camera';
import CaptureDocumentModal from '../../common/CaptureDocumentModal';
import CryptoJS from 'crypto-js';
import { getMemberVerify } from 'services/guestRegistrationService'
import { scanDoc } from 'services/commonScanDocServices'
import axios from 'axios';
import { commonScanDocEndPoints } from 'services/config';
// import { Callbacks } from 'jquery';
import { getmanagefrontdeskfield } from 'services/visitorRegistrationService'
import { loaderContext } from 'context/Context';
import { useContext } from 'react';
//verifyMemberAccessCard added by parth
import { verifyAccessCard, verifyMemberAccessCard } from 'services/commonService';
import PhoneNumberFrontdesk from 'components/common/PhoneNumberFrontdesk';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import BypassModal from '../visitorRegistration/BypassModal';
import momenttz from 'moment-timezone'
import GuestBypassModal from './GuestBypassModal';
import Guest7TimeBypassModal from './Guest7TimeBypassModal';



const GuestRegistration = () => {
    const [countryCode, setCountryCode] = useState(defaultCountryCode);
    // const [towersList, setTowersList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [showAssetModel, setShowAssetModel] = useState(false);
    const [showBypassModal, setShowBypassModal] = useState(false);
    const [show7TimeBypassModal, setShow7TimeBypassModal] = useState(false);
    const [isWhatsApp, setIsWhatsApp] = useState(false)
    const [visitorType, setVisitorType] = useState([])
    const [allGuestData, setAllGuestData] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [memberData, setMemberData] = useState("");
    const [email, setEmail] = useState("");
    const [visitorDocNo, setVisitorDocNo] = useState("");
    const [docType, setDocType] = useState([])
    const [destinations, setDestinations] = useState([]);
    const [membersData, setMembersData] = useState({});
    const [lastMemberData, setLastMemberData] = useState("");
    const [identitySection, setIdentitySection] = useState(true);
    const [editedRowData, setEditedRowData] = useState({})
    const [byPassType, setByPassType] = useState();
    const [submitPress, setSubmitPress] = useState(false);
    const [clickVerfiy, setClickVerify] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userPhoto, setUserPhoto] = useState("");
    const [frontDocPhoto, setFrontDocPhoto] = useState("");
    const [backDocPhoto, setBackDocPhoto] = useState("");
    const [isCaptureFrontDocModalOpen, setIsCaptureFrontDocModalOpen] = useState(false)
    const [isCaptureBackDocModalOpen, setIsCaptureBackDocModalOpen] = useState(false)
    const [isCapturePhotoModalOpen, setIsCapturePhotoModalOpen] = useState(false);
    const [formFieldsDisabled, setFormFieldsDisabled] = useState(true);
    const [isVisible, setIsVisible] = useState(false)
    const [requiredField, setRequiredField] = useState([])
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const [frontdocerror, setFrontDocError] = useState(false)
    const [backdocerror, setBackDocError] = useState(false)
    const [guestData, setGuestData] = useState([]);
    const [showBypassBtn, setShowBypassBtn] = useState();
    const [isPhoneRequired, setIsPhoneRequired]= useState(false)
    const [repeatPhone, setRepeatPhone]=useState("")
    const [repeatEmail, setRepeatEmail]=useState("")
    const [bypassguest, setByPassGuest]=useState([])
    const [hideSubmit, setHideSubmit]= useState(true)
    const [hideAddBypass, setHideAddBypass]= useState(true)
    




    //selected host type for members encoding added by parth
    const [selectedHostType, setSelectedHostType] = useState();

    //handle selected host type for members encoding by parth
    const handleSelectHostType = (event) => {
        setSelectedHostType(event.target.value);
    };
    const params = useParams();
    const { t } = useTranslation();
    const isMobile = navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i) ? true : false
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        watch
    } = useForm();
    const resetByPassVisitor = () => {
        reset();
        setEmail("")
        setPhoneNumber("")
        setValue("memberShipNameNumber",memberNumber)
        clearErrors("memberShipNameNumber")
        setIsWhatsApp(false)
        setFrontDocPhoto("")
        setBackDocPhoto("")
        setUserPhoto('')
    };
    const phone = watch("phone");
    const documentType = watch("document_type");

    console.log("memberd======>", membersData)
    
    // useEffect(() => {
    //     if (documentType) {
    //         setFrontDocPhoto("");
    //         setBackDocPhoto("");
    //         setValue("name", "")
    //         setValue("document_id_number", "")
    //         setValue("nationality", "")
    //         setValue("dob", "")
    //         setValue("gender", "")
    //     }
    // }, [documentType])
    useEffect(async () => {
        checkModulePermission("guard")
        setIsLoading(true)
        try {
            const { data } = await getDestinationList();
            const { data: identificationType } = await getListOfIdentificationType();
            setDestinations(data?.data?.map((destination) => ({ label: destination.name, value: destination.id })))
            // setMembersData({ member_name: "Member Test", member_profile: team3, member_number: "2356897410" })
            // // const newDocTypeData = identificationType?.data?.sort((a, b) => {
            // //     return a.id - b.id;
            // // });
            setDocType(identificationType?.data?.map((doc) => ({ documentName: doc.name, id: doc.id })))
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }, [])

   
    useEffect(() => {
       console.log("submitpressss======>", visitorDocNo, submitPress)
        if (submitPress) {
            console.log("visitordocno======>", visitorDocNo)
            if (email != "") {
                
                if (!pattern.email.test(email)) {
                    setError("phone", { type: "pattern", message: "emailInvalid" })
                } else {
                    clearErrors("phone");
                    setValue("phone", email)
                }
            } else if (phoneNumber != "") {
                
                if (!pattern.phoneNumber.test(phoneNumber)) {
                    setError("phone", { type: "pattern", message: "phoneNoInvalid" })
                } else {
                    clearErrors("phone");
                    setValue("phone", phoneNumber)
                }
            }else if(visitorDocNo != ""){
                setValue("phone", visitorDocNo)
                clearErrors("phone");
            } else {
                setError("phone", { type: "required" })
            }
        }
    }, [email, phoneNumber,visitorDocNo, submitPress])
    const onEditRow = (row) => {
        const guestData = allGuestData.find((guestData) => guestData.id === row)
        // guestData.destination_id = destinations?.find((destination) => destination?.value === guestData?.destination_id)
        // guestData.gender = genderOptions?.find((gender) => gender?.label === guestData?.gender)
        setEditedRowData(guestData)
        handleOpenModal(true)
    }
    const onGuestDataEdit = (data) => {
        setAllGuestData(allGuestData.map((guest) => {
            if (guest.id === data.id) {
                return data
            } else {
                return guest
            }
        }))
        setIsModalOpen(false)
    }
    const onDeleteRow = (row) => {
        setAllGuestData(allGuestData.filter((guest => guest.id != row)))
    }

    useEffect(() => {
        if (isVisible) {
            setIsVisible(false)
        }
    }, [email, phoneNumber])


    useEffect(async () => {
        setIsLoading(true)
        try {
            const { data } = await getmanagefrontdeskfield();
            console.log("---====", data)
            setRequiredField(data.data)
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false)
    }, [])

    console.log("required", requiredField)

    console.log("allguestData", allGuestData)

    const columns = [
        {
            Header: t('guestRegistration.guestDetails.label.no'),
            accessor: (row, index) => index + 1,
            headerClass: 'text-white',
        },
        {
            Header: t('guestRegistration.guestDetails.label.name'),
            accessor: 'name',
            headerClass: 'text-white',
            Cell: ({ row }) => {
                return (
                    <div>
                        <img
                            className="me-2 rounded-pill"
                            src={row.original.profile_photo}
                            height={35}
                            width={35}
                        ></img>
                        <span className="class-for-name">{row.original.name}</span>
                    </div>
                );
            }
        },
        {
            Header: t('guestRegistration.guestDetails.label.number'),
            headerClass: 'text-white',
            accessor: 'phone',
            Cell: ({ row }) => {
                return row?.original?.phone ? `${row?.original?.country_code}-${row?.original?.phone}` : '--'
            }

        },
        {
            Header: t('guestRegistration.guestDetails.label.guestemailaddress'),
            headerClass: 'text-white',
            accessor: 'email',
            Cell: ({ row }) => {
                return row.original.email ? row.original.email : '--'
            }
        },
        {
            Header: t('guestRegistration.guestDetails.label.guestidentificationno'),
            headerClass: 'text-white',
            accessor: 'document_id_number',
            Cell: ({ row }) => {
                return row.original.document_id_number ? row.original.document_id_number : '--'
            }
        },
        {
            Header: t('guestRegistration.guestDetails.label.action'),
            headerClass: 'text-white',
            disableSortBy: true,
            content: data => (
                <div className="d-flex flex-wrap align-items-center">
                    <span
                        className="btn p-0"
                        title="Edit"
                        onClick={() => onEditRow(data)}
                    >
                        <FontAwesomeIcon icon="edit" />
                    </span>
                    <span
                        className="btn p-0 ms-2"
                        title="Delete"
                        onClick={() => onDeleteRow(data)}
                    >
                        <FontAwesomeIcon icon="trash-alt" />
                    </span>
                </div>
            ),
        }
    ];
    const memberNumber = watch("memberShipNameNumber")
    useEffect(() => {
        if (submitPress) {
            if (memberData === "" && !clickVerfiy) {
                setError("memberShipNameNumber", { type: "required" })
            }
        }
    }, [submitPress])

    const getDataFrontDocument = async () => {
        setIsLoading(true)
        try {
            const frontImg = frontDocPhoto
            const backImg = backDocPhoto
            console.log("front img", frontImg)
            const { data } = await axios.post(commonScanDocEndPoints.imageIdentity, { front: frontImg.split(',')[1], back: backImg.split(',')[1], type: documentType }, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            const documentData = data?.data[0]?.document_data;
            setValue("gender", genderOptions.find((gender) => gender.value === documentData?.gender))
            setValue("name", documentData?.name)
            setValue("document_id_number", documentData?.id_card_number)
            setValue("nationality", documentData?.nationality)
            { (documentData?.dob && documentData?.dob != "") && setValue("dob", moment(documentData?.dob, process.env.REACT_APP_DISPLAY_DATE_FORMAT).toDate()) }
            clearErrors("gender")
            clearErrors("name")
            clearErrors("document_id_number")
            clearErrors("nationality")
            clearErrors("dob")
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const getDataFromPassport = async () => {
        setIsLoading(true)
        try {
            const frontImg = frontDocPhoto
            const { data } = await axios.post(commonScanDocEndPoints.imageIdentity, { front: frontImg.split(',')[1], type: documentType }, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            const documentData = data?.data[0]?.document_data;
            console.log(documentData)

            const dob = documentData?.dob
                ? moment(documentData.dob, process.env.REACT_APP_DISPLAY_DATE_FORMAT).toDate()
                : moment('1980-01-01').toDate();
            setValue("gender", genderOptions.find((gender) => gender.value === documentData?.gender))
            setValue("name", documentData?.name)
            setValue("document_id_number", documentData?.id_card_number)
            setValue("nationality", documentData?.nationality)
            setValue("dob", dob)
            clearErrors("gender")
            clearErrors("name")
            clearErrors("document_id_number")
            clearErrors("nationality")
            clearErrors("dob")
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (documentType != "4") {
            if (documentType != "3") {
                if (frontDocPhoto && frontDocPhoto != "" && backDocPhoto && backDocPhoto != "") {
                    getDataFrontDocument()
                }
            } else {
                if (frontDocPhoto && frontDocPhoto != "") {
                    getDataFromPassport()
                }
            }
        }
    }, [frontDocPhoto, backDocPhoto])


    useEffect(() => {
        if (frontDocPhoto != "") {
            setFrontDocError(false)
        }
        if (backDocPhoto != "") {
            setBackDocError(false)
        }
    }, [frontDocPhoto, backDocPhoto])

    // useEffect(() => {
    //     if (documentType == "4") {
    //         if (frontDocPhoto && frontDocPhoto != "" && backDocPhoto && backDocPhoto != "") {
    //             getDataFrontDocument()
    //         }
    //     } else {
    //         if (frontDocPhoto && frontDocPhoto != "") {
    //             getDataFromPassport()
    //         }
    //     }
    // }, [frontDocPhoto, backDocPhoto])

    useEffect(() => {
        if (clickVerfiy || submitPress) {
            if (memberNumber != "") {
                if (!pattern.notAllowSpaceInStartfront.test(memberNumber)) {
                    setError("memberShipNameNumber", { type: "pattern" })
                } else {
                    clearErrors("memberShipNameNumber")
                }
                // setValue("memberShipNameNumber", memberData)
            } else {
                setError("memberShipNameNumber", { type: "required" })
            }
        }

    }, [clickVerfiy, memberNumber])
    console.log("regex", /^\d+$/.test(phoneNumber))
    console.log("visitedcount", showBypassBtn)

    useEffect(() => {
        if(visitorDocNo != ""){
          setIsPhoneRequired(false)
        }else{
          setIsPhoneRequired(true)
        }
       }, [visitorDocNo])

    const onSendOTP = async () => {

        if(visitorDocNo != ""){
            if (memberData === "" && !clickVerfiy) {
                setError("memberShipNameNumber", { type: "required" })
            } else if (allGuestData?.find((guest) => (guest?.document_id_number == visitorDocNo))) {
                toast.error("This guest is already available in below table.", toastConfig)
            } else if (!pattern.phoneNumber.test(phoneNumber) && !pattern.email.test(email)) {
                setError("phone", { type: "required" })
            }
            else if (!errors.phone) {
                setIsLoading(true)
                try {
                    const { data } = await getGuestRegistrationOTPbyDoc({document_id_number:visitorDocNo})
                    console.log("data", data)
    
                    setShowBypassBtn(data?.data[0].visited_count)
                    setIsVisible(true)
                    data?.data[0]?.visited_count == 1 &&  toast.warning('This guest visited  max exceeded you can bypass', toastConfig)
                    toast.success(data?.data[0]?.otp, toastConfig)
                    toast.success(data?.message, toastConfig)
                    if (!isVisible) {
                        if (data?.data[0]?.guest_details) {
                            const newData = data?.data[0]?.guest_details;
                            const { otp, ...restData } = newData;
                            console.log("Reset Data", restData)
                            restData.destination_id = destinations.find((destination) => destination?.value == restData.destination_id);
                            restData.gender = genderOptions.find((gedner) => gedner?.label === restData.gender)
                            setValue("name", restData?.guest_name)
                            // setCountryCode((restData?.guest_country_code && restData?.guest_country_code != "") ? countryCodes.find(country => country.dial_code == restData?.guest_country_code) : defaultCountryCode)
                            clearErrors("name")
                            setValue("document_id_number", restData?.doc_id_number)
                            clearErrors("document_id_number")
                            // setValue("phone",restData?.guest_phone)
                            // setValue("email", restData?.guest_email)
                            setRepeatPhone(restData?.guest_phone)
                            setRepeatEmail(restData?.guest_email)

                            setValue("nationality", restData?.nationality)
                            clearErrors("nationality")
                            { (restData?.dob && restData?.dob != "") && setValue("dob", moment(restData?.dob).toDate()) }
                            clearErrors("dob")
                            setValue("gender", restData?.gender)
                            clearErrors("gender")
                            setValue("document_type", restData?.doc_id_type?.toString())
                            clearErrors("document_type")
                            setValue("destination_id", restData?.destination_id)
                            clearErrors("destination_id")
                            setValue("vehicle_reg_no", restData?.vehicle_reg_no)
                            clearErrors("vehicle_reg_no")
                            // setValue("access_card_no", restData.access_card_no)
                            clearErrors("access_card_no")
                            setProfilePhoto(restData?.profile_photo ? `data:image/jpeg;base64,${restData?.profile_photo}` : "")
                            setFrontDocPhoto(restData?.front ? `data:image/jpeg;base64,${restData?.front}` : "")
                            setBackDocPhoto(restData?.back ? `data:image/jpeg;base64,${restData?.back}` : "")
                            // reset(restData)
                            // setSubmitPress(true)
                            console.log('click otp btn');
                        } else {
                            setValue("gender", "")
                            setValue("destination_id", "")
                            setValue("name", "")
                            setValue("document_id_number", "")
                            setValue("nationality", "")
                            setValue("dob", "")
                            setValue("vehicle_reg_no", "")
                            setValue("access_card_no", "")
                            setValue("document_type", "")
                            setValue("otp", "")
                            setUserPhoto("");
                            setFrontDocPhoto("");
                            setBackDocPhoto("");
                            clearErrors();
                        }
                    }
                } catch (e) {
                    if (e.response.data) {
                        if (Array.isArray(e.response.data.message)) {
                            e.response.data.message.map(errors => toast.error(errors, toastConfig))
                        } else {
                            toast.error(e.response.data.message, toastConfig)
                        }
                    } else {
                        toast.error(e.message, toastConfig)
                    }
    
                }
                setIsLoading(false)
            }
        }else{
            if (memberData === "" && !clickVerfiy) {
                setError("memberShipNameNumber", { type: "required" })
            } else if (allGuestData?.find((guest) => (guest?.email == email && guest?.phone == phoneNumber))) {
                toast.error("This guest is already available in below table.", toastConfig)
            } else if (!pattern.phoneNumber.test(phoneNumber) && !pattern.email.test(email)) {
                setError("phone", { type: "required" })
            }
            else if (!errors.phone) {
                setIsLoading(true)
                try {
                    const { data } = await getGuestRegistrationOTP(email ? { email } : { phone: phoneNumber, country_code: countryCode.dial_code })
                    console.log("data", data)
    
                    setShowBypassBtn(data?.data[0].visited_count)
                    setIsVisible(true)
                    data?.data[0]?.visited_count == 1 &&  toast.warning('This guest visited  max exceeded you can bypass', toastConfig)
                    toast.success(data?.data[0]?.otp, toastConfig)
                    toast.success(data?.message, toastConfig)
                    if (!isVisible) {
                        if (data?.data[0]?.guest_details) {
                            const newData = data?.data[0]?.guest_details;
                            const { otp, ...restData } = newData;
                            console.log("Reset Data", restData)
                            restData.destination_id = destinations.find((destination) => destination.value == restData.destination_id);
                            restData.gender = genderOptions.find((gedner) => gedner.label === restData.gender)
                            setValue("name", restData.guest_name)
                            // setCountryCode((restData?.guest_country_code && restData?.guest_country_code != "") ? countryCodes.find(country => country.dial_code == restData?.guest_country_code) : defaultCountryCode)
                            clearErrors("name")
                            setValue("document_id_number", restData.doc_id_number)
                            clearErrors("document_id_number")
                            setValue("nationality", restData.nationality)
                            clearErrors("nationality")
                            { (restData?.dob && restData?.dob != "") && setValue("dob", moment(restData.dob).toDate()) }
                            clearErrors("dob")
                            setValue("gender", restData.gender)
                            clearErrors("gender")
                            setValue("document_type", restData?.doc_id_type?.toString())
                            clearErrors("document_type")
                            setValue("destination_id", restData.destination_id)
                            clearErrors("destination_id")
                            setValue("vehicle_reg_no", restData.vehicle_reg_no)
                            clearErrors("vehicle_reg_no")
                            // setValue("access_card_no", restData.access_card_no)
                            clearErrors("access_card_no")
                            setProfilePhoto(restData?.profile_photo ? `data:image/jpeg;base64,${restData?.profile_photo}` : "")
                            setFrontDocPhoto(restData?.front ? `data:image/jpeg;base64,${restData?.front}` : "")
                            setBackDocPhoto(restData?.back ? `data:image/jpeg;base64,${restData?.back}` : "")
                            // reset(restData)
                            // setSubmitPress(true)
                            console.log('click otp btn');
                        } else {
                            setValue("gender", "")
                            setValue("destination_id", "")
                            setValue("name", "")
                            setValue("document_id_number", "")
                            setValue("nationality", "")
                            setValue("dob", "")
                            setValue("vehicle_reg_no", "")
                            setValue("access_card_no", "")
                            setValue("document_type", "")
                            setValue("otp", "")
                            setUserPhoto("");
                            setFrontDocPhoto("");
                            setBackDocPhoto("");
                            clearErrors();
                        }
                    }
                } catch (e) {
                    if (e.response.data) {
                        if (Array.isArray(e.response.data.message)) {
                            e.response.data.message.map(errors => toast.error(errors, toastConfig))
                        } else {
                            toast.error(e.response.data.message, toastConfig)
                        }
                    } else {
                        toast.error(e.message, toastConfig)
                    }
    
                }
                setIsLoading(false)
            }
        }
    };

    const checkTotalEntryTime=async(guestentryData)=>{
        console.log("guestdat===>", guestentryData)
        setIsLoading(true)
     try {
       const {data} = await checkguestentry(guestentryData)
       setIsLoading(false)
        return data?.data[0]?.visited_count == 0 ?false :true
    } catch (e) {
        if (e?.response?.data) {
        if (Array.isArray(e.response.data.message)) {
            e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else {
            toast.error(e.response.data.message, toastConfig)
        }
    } else {
        toast.error(e.message, toastConfig)
    }
    setIsLoading(false)
    return 'Error'
}
}

    const onSubmitData = () => {
        setSubmitPress(true);
        handleSubmit(onSubmitVisitorData)
    }
    const onSubmitServerData = () => {
        setSubmitPress(true);
        handleSubmit(submitDataToServer)
    }
    const onSubmitVisitorData = async visitorData => {
       
        let isError = false;

        if (memberData === "" && !clickVerfiy) {
            setError("memberShipNameNumber", { type: "required" })
            isError = true
        }
        else if (documentType == 3 && frontDocPhoto == "") {
            setFrontDocError(true)
            isError = true
        }
        else if (documentType != 3) {
            if (frontDocPhoto == "") {
                setFrontDocError(true);
                isError = true
            }
            if (backDocPhoto == "") {
                setBackDocError(true);
                isError = true
            }
        }
        if (!isError) {

            
            visitorData.id = allGuestData.length + 1
            visitorData.destination_id = visitorData?.destination_id?.value;
            visitorData.gender = visitorData?.gender?.label;
            visitorData.email = visitorDocNo != "" ? repeatEmail:email;
            visitorData.phone = visitorDocNo != "" ?repeatPhone: phoneNumber;
            visitorData.profile_photo = userPhoto;
            visitorData.front = frontDocPhoto;
            visitorData.back = backDocPhoto;
            visitorData.is_whatsapp = isWhatsApp;
            visitorData.country_code = countryCode?.dial_code;
            visitorData.dob = moment(visitorData.dob).format(process.env.REACT_APP_DATE_FORMAT)
            visitorData.is_bypass = 0
            setByPassGuest(visitorData)
          
            const data = await checkTotalEntryTime(visitorData)
            if(data != 'Error'){
                if(data){
                    setHideSubmit(false)
                    setShow7TimeBypassModal(true)
                }else{
                    setAllGuestData([...allGuestData, visitorData])
                    setLastMemberData(memberData)
                    setEmail("");
                    setIsWhatsApp(false)
                    setVisitorDocNo("");
                    setPhoneNumber("");
                    setValue("gender", "")
                    setValue("phone", "")
                    setValue("name", "")
                    setValue("document_id_number", "")
                    setValue("nationality", "")
                    setValue("dob", "")
                    setValue("vehicle_reg_no", "")
                    setValue("access_card_no", "")
                    setValue("document_type", "")
                    setValue("otp", "")
                    setValue("destination_id", "")
                    setSubmitPress(false);
                    setUserPhoto("");
                    setIsVisible(false)
                    setFrontDocPhoto("");
                    setBackDocPhoto("");
                    clearErrors();
                    clearErrors('phone')
                    setBackDocError(false)
                    setFrontDocError(false)
                    setCountryCode(defaultCountryCode)
                }
            }
           
        }
    };

    // useEffect(() => {
    //     if (memberData === "" && !clickVerfiy) {
    //         setFormFieldsDisabled(true)
    //     } else {
    //         setFormFieldsDisabled(false)
    //     }
    // }, [memberData, clickVerfiy])

    const submitDataToServer = async visitorData => {
       
        console.log("VisitorDataServer", visitorData)
        let isError = false;
        if (memberData === "" && !clickVerfiy) {
            setError("memberShipNameNumber", { type: "required" })
        }
        else if (documentType == 3 && frontDocPhoto == "") {
            setFrontDocError(true)
            isError = true
        }
        else if (documentType != 3) {
            if (frontDocPhoto == "") {
                setFrontDocError(true);
                isError = true
            }
            if (backDocPhoto == "") {
                setBackDocError(true);
                isError = true
            }
        }
        // else if (!submitPress) {
        //     setError("phone", { type: 'required' })
        // } 
        if (!isError) {
            setIsLoading(true)
            try {
                
                visitorData.destination_id = visitorData?.destination_id?.value;
                visitorData.gender = visitorData?.gender?.label;
                visitorData.email = visitorDocNo != "" ? repeatEmail: email;
                visitorData.phone = visitorDocNo != "" ? repeatPhone:phoneNumber;
                visitorData.profile_photo = userPhoto;
                visitorData.front = frontDocPhoto;
                visitorData.back = backDocPhoto;
                visitorData.is_whatsapp = isWhatsApp;
                visitorData.dob = visitorData?.dob ? moment(visitorData?.dob).format(process.env.REACT_APP_DATE_FORMAT) : ""
                visitorData.country_code = countryCode?.dial_code;
                const formData = {};
                formData.member_name = `${membersData?.FirstName} ${membersData?.lastName}`
                formData.member_number = membersData?.MemberNumber
                formData.member_email = membersData?.Email
                formData.member_phone = membersData?.Mobile
                formData.guest =  visitorData
                setGuestData(formData)
                
                    const guestEntry = await checkTotalEntryTime(visitorData)
                    if(guestEntry != 'Error'){
                        if(guestEntry){
                            setHideAddBypass(false)
                            setShow7TimeBypassModal(true)
                        }else{
                            setIsLoading(true)
                            const { data } = await registerSingleGuests(formData)
                            setIsVisible(false)
                            setLastMemberData(memberData)
                            reset();
                            setEmail("");
                            setVisitorDocNo("");
                            setPhoneNumber("");
                            setRepeatEmail("");
                            setRepeatPhone("");
                            setIsWhatsApp(false)
                            setValue("memberShipNameNumber", "")
                            setValue("gender", "")
                            setValue("destination_id", "")
                            setSubmitPress(false);
                            setAllGuestData([])
                            setUserPhoto("");
                            setFrontDocPhoto("");
                            setBackDocPhoto("");
                            setBackDocError(false)
                            setFrontDocError(false)
                            setMembersData({})
                            setCountryCode(defaultCountryCode)
                            clearErrors();
                            if (data?.data[0]?.visited_count == 1) {
                                toast.warning(data?.data[0]?.visited_message, toastConfig)
                            } else {
                                toast.success(data?.message, toastConfig)
                            }
                            setIsLoading(false)
                        }
                    }
                    
            
            } catch (e) {
                if (e.response.data) {
                    if (Array.isArray(e.response.data.message)) {
                        e.response.data.message.map(errors => toast.error(errors, toastConfig))
                    } else {
                        toast.error(e.response.data.message, toastConfig)
                    }
                } else {
                    toast.error(e.message, toastConfig)
                }
            }
            setIsLoading(false)
        }
    }
    const submitAllRecordsToServer = async () => {
        setIsLoading(true)
        try {

            const formData = {};
            formData.member_name = `${membersData?.FirstName} ${membersData?.lastName}`
            formData.member_number = membersData?.MemberNumber
            formData.member_email = membersData?.Email
            formData.member_phone = membersData?.Mobile
            formData.guest = allGuestData;
            allGuestData.country_code = countryCode.dial_code;
            const { data } = await registerMultipleGuests(formData)
            console.log("fffffffff", formData)
            setLastMemberData(memberData)
            setAllGuestData([])
            setValue("memberShipNameNumber", "")
            setMembersData({})
            clearErrors('memberShipNameNumber')
            setIsVisible(false)
            if (data?.data[0]?.visited_count == 1) {
                toast.warning(data?.data[0]?.visited_message, toastConfig)
            } else {
                toast.success(data?.message, toastConfig)
            }

        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const verifyMember = async () => {
        setIsLoading(true)
        try {

            if (memberNumber != "") {
                if (!pattern.notAllowSpaceInStartfront.test(memberNumber)) {
                    setError("memberShipNameNumber", { type: "pattern" })
                } else {
                    clearErrors("memberShipNameNumber")
                }
                // setValue("memberShipNameNumber", memberData)
            } else {
                setError("memberShipNameNumber", { type: "required" })
            }

            if (clickVerfiy && data?.Message == "VALID") {
                setFormFieldsDisabled(false)
            } else {
                setFormFieldsDisabled(true)
            }
            if (memberNumber != "") {
                console.log(process.env.REACT_APP_SECRET_KEY, memberNumber)
                const hash = CryptoJS.SHA256(`${process.env.REACT_APP_SECRET_KEY}${memberNumber}`).toString();
                console.log(hash)
                const { data } = await getMemberVerify({ Search: memberNumber, Hash: hash })
                if (data?.MemberNumber != "") {
                    setMembersData(data)
                    setClickVerify(true)
                    setFormFieldsDisabled(false);
                    toast.success(t("guestRegistration.memberVerification.success"), toastConfig)
                } else {
                    setFormFieldsDisabled(true);
                    setMembersData({})
                    toast.error(data?.FirstName, toastConfig)
                }
                console.log(data)
            }
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }

        }
        setIsLoading(false)

    }

    //members encoding function added by parth
    const encodeMembersFrontdesk = async () => {
        setIsLoading(true)
        console.log(selectedHostType);
        try {
            const { data } = await verifyMemberAccessCard({ host_type: selectedHostType, memberName: membersData?.FirstName + ' ' + membersData?.lastName, memberNo: membersData?.MemberNumber })
            console.log('parth' + data?.data + data?.message + data?.success);
            //setValue("access_card_no", data?.data[0]?.access_card_no)
            clearErrors("access_card_no")
            if (data?.success) {
                toast.success('Member card encoded', toastConfig)

            } else {
                toast.error(data?.message, toastConfig)

            }
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handleOpenModal = (data) => {
        console.log(data)
        setIsModalOpen(true);
    };

    const onIdentityByPass = () => {
        setShowBypassModal(true)
        setByPassType("indentity")
    }
    const sendOtp = () => {
        setSubmitPress(true)
        onSendOTP()
    }
    console.log("errors", errors)
    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                const newUrl = URL.createObjectURL(file);
                setUserPhoto(newUrl);
            }
        }
    };
    const setProfilePhoto = (data) => {
        setUserPhoto(data);
    }
    const setFrontDocImage = async (data) => {
        setFrontDocPhoto(data);
    }
    const setBackDocImage = async (data) => {
        setBackDocPhoto(data)
    }

    const frontsideClick = () => {
        if (documentType && documentType != '') {
            setIsCaptureFrontDocModalOpen(true)
        } else {
            toast.error("Please select ID Type first.", toastConfig)
        }
    }
    const backsideClick = () => {
        if (documentType && documentType != '') {
            setIsCaptureBackDocModalOpen(true)
        } else {
            toast.error("Please select ID Type first.", toastConfig)
        }
    }
    const getAccessCardNumber = async () => {
        setIsLoading(true)
        try {
            const { data } = await verifyAccessCard({ host_type: 2 })
            setValue("access_card_no", data?.data[0]?.access_card_no)
            clearErrors("access_card_no")
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }


    // const onBypass = (guestData) => {
    //     let isError = false;
    //     if (documentType == 3 && frontDocPhoto == "") {
    //         setFrontDocError(true)
    //         isError = true
    //     }
    //     else if (documentType != 3) {
    //         if (frontDocPhoto == "") {
    //             setFrontDocError(true);
    //             isError = true
    //         }
    //         if (backDocPhoto == "") {
    //             setBackDocError(true);
    //             isError = true
    //         }
    //     }
    //     if (!isError) {
    //         if (documentType === "3") {
    //             if (frontDocPhoto != "") {
    //                 setShowBypassModal(true)
    //                 guestData.gender = guestData?.gender?.label
    //                 guestData.destination_id = guestData?.destination_id?.value
    //                 guestData.profile_photo = userPhoto
    //                 guestData.front = frontDocPhoto;
    //                 guestData.timezone = momenttz.tz.guess()
    //                 guestData.dob = moment(guestData.dob).format(process.env.REACT_APP_DATE_FORMAT)
    //                 guestData.country_code = countryCode?.dial_code
    //                 setGuestData(guestData);
    //                 setByPassType("OTP")
    //             }
    //         } else {
    //             if (frontDocPhoto != "" && backDocPhoto != "") {
    //                 setShowBypassModal(true)
    //                 guestData.gender = guestData?.gender?.label
    //                 guestData.destination_id = guestData?.destination_id?.value
    //                 guestData.profile_photo = userPhoto
    //                 guestData.front = frontDocPhoto;
    //                 guestData.back = backDocPhoto;
    //                 guestData.timezone = momenttz.tz.guess()
    //                 guestData.dob = moment(guestData.dob).format(process.env.REACT_APP_DATE_FORMAT)
    //                 guestData.country_code = countryCode?.dial_code
    //                 setGuestData(guestData);
    //                 setByPassType("OTP")
    //             }
    //         }
    //     }

    // }

    // const onBypass = (visitorData) => {

    //     let isError = false;
    //     if (memberData === "" && !clickVerfiy) {
    //         setError("memberShipNameNumber", { type: "required" })
    //     }
    //     else if (documentType == 3 && frontDocPhoto == "") {
    //         setFrontDocError(true)
    //         isError = true
    //     }
    //     else if (documentType != 3) {
    //         if (frontDocPhoto == "") {
    //             setFrontDocError(true);
    //             isError = true
    //         }
    //         if (backDocPhoto == "") {
    //             setBackDocError(true);
    //             isError = true
    //         }
    //     }
    //     // else if (!submitPress) {
    //     //     setError("phone", { type: 'required' })
    //     // } 
    //     if (!isError) {
    //         try {
    //             visitorData.destination_id = visitorData?.destination_id?.value;
    //             visitorData.gender = visitorData?.gender?.label;
    //             // visitorData.email = email;
    //             // visitorData.phone = phoneNumber;
    //             visitorData.email = visitorDocNo != "" ? repeatEmail: email;
    //             visitorData.phone = visitorDocNo != "" ? repeatPhone:phoneNumber;
    //             visitorData.profile_photo = userPhoto;
    //             visitorData.front = frontDocPhoto;
    //             visitorData.back = backDocPhoto;
    //             visitorData.is_whatsapp = isWhatsApp;
    //             visitorData.dob = visitorData?.dob ? moment(visitorData?.dob).format(process.env.REACT_APP_DATE_FORMAT) : ""
    //             visitorData.country_code = countryCode?.dial_code;

    //             const formData = {};
    //             formData.member_name = `${membersData?.FirstName} ${membersData?.lastName}`
    //             formData.member_number = membersData?.MemberNumber
    //             formData.member_email = membersData?.Email
    //             formData.member_phone = membersData?.Mobile
    //             formData.guest = [...allGuestData, visitorData]
    //             setGuestData(formData)
    //             setShowBypassModal(true)

    //         } catch (e) {
    //             if (e.response.data) {
    //                 if (Array.isArray(e.response.data.message)) {
    //                     e.response.data.message.map(errors => toast.error(errors, toastConfig))
    //                 } else {
    //                     toast.error(e.response.data.message, toastConfig)
    //                 }
    //             } else {
    //                 toast.error(e.message, toastConfig)
    //             }
    //         }
    //     }
    // }

    const resetDocumentPhotos = () => {
        setFrontDocPhoto('');
        setBackDocPhoto('');
        setValue('dob', '');
        setValue('gender', '');
        setValue("document_id_number", "")
        setValue('nationality', '')
        setValue("name", "")
    };

    const handleDocTypeChange = () => {
        resetDocumentPhotos();
    };


    return (
        <>
            <div className='d-flex flex-column mainn gap-3'>
                <div className='d-flex flex-wrap justify-content-between verification-container mt-3'>
                    <div className='card overfolw-hidden col-xl-4 col-12 p-4 guestVerification'>
                        <div className="h-100">
                            <div>
                                <h3 className="heading-color-frontdesk fw-600">{t("guestRegistration.memberVerification.title")}</h3>
                                <hr className='mt-0' />
                                <div>
                                    <div className="row align-items-end">
                                        <div className="">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    {t('guestRegistration.memberVerification.label.numberName')}
                                                </label>
                                                <div className='d-flex d-grid gap-3'>
                                                    <input
                                                        style={{ maxHeight: "2.5rem" }}
                                                        className={`form-control ${errors.memberShipNameNumber && 'is-invalid'
                                                            }`}
                                                        type="text"
                                                        placeholder={t("guestRegistration.memberVerification.placeholder.numberName")}
                                                        {...register('memberShipNameNumber', { onChange: setMemberData, value: memberData, required: true, pattern: pattern.notAllowSpaceInStartfront })}
                                                    />

                                                    <button
                                                        className="btn btn-primary-frontdesk verify"
                                                        // type="reset"
                                                        onClick={() => verifyMember()}
                                                    >
                                                        {t("guestRegistration.memberVerification.verify")}
                                                    </button>
                                                </div>
                                                {errors.memberShipNameNumber && (
                                                    <>
                                                        <small>
                                                            {errors.memberShipNameNumber.type === 'required' && (
                                                                <span className="text-danger">
                                                                    {t(
                                                                        'guestRegistration.memberVerification.error.memberShipNameNumberReq'
                                                                    )}
                                                                </span>
                                                            )}
                                                            {errors.memberShipNameNumber.type === 'pattern' && (
                                                                <span className="text-danger">
                                                                    {t(
                                                                        'guestRegistration.memberVerification.error.memberShipNameNumberInvalid'
                                                                    )}
                                                                </span>
                                                            )}
                                                        </small>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h3 className="heading-color-frontdesk fw-600 mt-4">{t("guestRegistration.memberVerification.memberSummary")}</h3>
                                <hr className='mt-0' />
                                <div className="row d-flex mt-3">
                                    <div className="col-7 mb-3">
                                        <h5 className="text-dark">{t('guestRegistration.memberVerification.label.membersProfile')} : </h5>
                                    </div>
                                    {membersData?.Photourl && <div className="col-5 mb-3">
                                        <img src={membersData?.Photourl} className='member-profile-pic' />
                                    </div>}
                                    <div className="col-7 mb-3">
                                        <h5 className="text-dark">{t('guestRegistration.memberVerification.label.name')} : </h5>
                                    </div>
                                    {(membersData?.FirstName != "" || membersData?.lastName != "") && <div className="col-5">
                                        <h5 className="fw-bold text-dark">{membersData?.FirstName} {membersData?.lastName}</h5>
                                    </div>}
                                    <div className="col-7">
                                        <h5 className="text-dark">{t("guestRegistration.memberVerification.label.number")} : </h5>
                                    </div>
                                    {membersData?.MemberNumber && <div className="col-5">
                                        <h5 className="fw-bold text-dark">{membersData?.MemberNumber}</h5>
                                    </div>}
                                    <div className="col-7 mt-2">
                                        <h5 className="text-dark">{t("guestRegistration.memberVerification.label.memberstatus")} : </h5>
                                    </div>
                                    {membersData?.MemberStatus && <div className="col-5 mt-2">
                                        <h5 style={membersData?.MemberStatus === "Active" ? { color: "#5FB751", fontWeight: "bold" } : { color: "red", fontWeight: "bold" }}>{membersData?.MemberStatus}</h5>
                                    </div>}
                                </div>
                                {/* members encoding select box and button added by parth */}
                                <div className='p-1 col-xxl-4 col-xl-4 col-lg-4 w-100 mb-2'>
                                    <select value={selectedHostType} onChange={handleSelectHostType} className="form-control mr-2">
                                        <option value="">Select Option</option>
                                        <option value="2">Guest Gate</option>
                                        <option value="3">Members Only Gate</option>
                                        <option value="4">Serengeti Gate</option>
                                        <option value="5">Control Room</option>
                                        <option value="6">Mail Room</option>
                                    </select>
                                    <button
                                        className="btn btn-primary-frontdesk addBtn ml-auto mt-2"
                                        onClick={() => encodeMembersFrontdesk()}
                                    >
                                        Encode Member Card
                                    </button>
                                </div>
                            </div>

                            <div>
                                <h3 className="heading-color-frontdesk fw-600 mt-4">{t("guestRegistration.memberVerification.guestssummary")}</h3>
                                <hr className='mt-0' />
                                <div className="row d-flex mt-3">
                                    <div className="col-7">
                                        <h5 className="text-dark">{t('guestRegistration.memberVerification.label.guests')} : </h5>
                                    </div>
                                    <div className="col-5">
                                        <h5 className="text-dark">{allGuestData.length}</h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <form
                        encType="multipart/form-data"
                        autoComplete="off"
                        className='col-xl-8 col-12 memberVerification'
                        style={{ padding: '0rem' }}
                    > */}
                    <div className="card p-2 overfolw-hidden col-xl-8 col-12 memberVerification">
                        <div>
                            <div className="card-header pb-0 ">
                                <div className="row ">
                                    <h3 className="heading-color-frontdesk fw-600">
                                        {t('guestRegistration.guestVerification.title')}
                                    </h3>
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-3">
                                <div>
                                    <div className="row m-0">
                                        <div className="form-group p-0 col-12" style={{display:"flex", flexWrap:"wrap"}}>
                                            <label>
                                                {t('guestRegistration.guestVerification.label.waNumber')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <div className="d-flex flex-wrap p-0 col-12 gap-3">
                                                <div className='d-flex' style={{ flexDirection: "column" }}>
                                                    <Controller
                                                        name="phone"
                                                        control={control}
                                                        rules={{ required: isPhoneRequired }}
                                                        render={({ field: { onChange, value } }) => (
                                                            <PhoneNumberFrontdesk
                                                                value={value}
                                                                onChange={onChange}
                                                                phoneNumber={phoneNumber}
                                                                email={email}
                                                                visitordocno={visitorDocNo}
                                                                setVisitorDocNo={setVisitorDocNo}
                                                                setPhoneNumber={setPhoneNumber}
                                                                firstError={errors?.phone?.type === 'required' || errors?.phone?.message == "phoneNoInvalid"}
                                                                secondError={errors?.phone?.type === 'required' || errors?.phone?.message == "emailInvalid"}
                                                                thirdError={errors?.phone?.type === 'required'}
                                                                setEmail={setEmail}
                                                                countryCode={countryCode}
                                                                firstPlaceholder={t("guestRegistration.guestVerification.placeholder.waNumber")}
                                                                secondPlaceholder={t("guestRegistration.guestVerification.placeholder.email")}
                                                                setCountryCode={setCountryCode}
                                                                dropdownDirection="bottom"
                                                                saperate={true}
                                                                wantemail={true}
                                                                wantVisitorDoc={true}
                                                                formFieldsDisabled={formFieldsDisabled}
                                                                flagvisible={true}
                                                            />
                                                        )}
                                                    />
                                                    {errors.phone && (
                                                        <>
                                                            <small>
                                                                {errors.phone.type === 'required' && (
                                                                    <span className="text-danger">
                                                                        {t(
                                                                            'guestRegistration.guestVerification.error.guestContactNoReq'
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </small>
                                                            <small>
                                                                {errors.phone.type === 'pattern' && (
                                                                    <span className="text-danger">
                                                                        {t(`guestRegistration.guestVerification.error.${errors.phone.message}`)}
                                                                    </span>
                                                                )}
                                                            </small>
                                                        </>
                                                    )}
                                                </div>

                                               

                                                {/* {
                                                    isVisible ? (
                                                        <button
                                                            className="btn btn-primary-frontdesk sendotp "
                                                            onClick={() => sendOtp()}
                                                        >
                                                            {t('guestRegistration.guestVerification.label.resentOtp')}
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-primary-frontdesk sendotp "
                                                            onClick={() => sendOtp()}
                                                        >
                                                            {t('guestRegistration.guestVerification.label.sentOtp')}
                                                        </button>
                                                    )
                                                } */}


                                                {/* <button
                                                    className="btn btn-primary sendotp "
                                                    onClick={() => sendOtp()}
                                                >
                                                    {t('guestRegistration.guestVerification.label.sentOtp')}
                                                </button> */}



                                            </div>

                                            <div>
                                                <label className='pt-2' style={{ color: 'var("--primary")' }}>
                                                    {t('guestRegistration.guestVerification.error.emailReq')}
                                                </label>
                                            </div>

                                          

                                            {/* <div className="form-group col-lg-4 col-md-6 col-sm-6 mb-3">
                                            <label>
                                                    {t('guestRegistration.guestVerification.label.docno')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                           <input
                          className={`form-control ${errors.visitor_doc_number && 'is-invalid'
                            }`}
                          type="text"
                          disabled={formFieldsDisabled}
                          {...register('visitor_doc_number', {
                            required: true,
                            pattern: pattern.fullName
                          })}
                        />
                          
                        </div> */}
                        

                        {
                                                    isVisible ? (
                                                        <button
                                                            className="btn btn-primary-frontdesk sendotp "
                                                            onClick={() => sendOtp()}
                                                            style={{width:"100%"}}
                                                        >
                                                            {t('guestRegistration.guestVerification.label.resentOtp')}
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-primary-frontdesk sendotp "
                                                            onClick={() => sendOtp()}
                                                            style={{width:"100%"}}
                                                        >
                                                            {t('guestRegistration.guestVerification.label.sentOtp')}
                                                        </button>
                                                    )
                                                }
                                             



                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card-header pb-0">
                                <div className="row">
                                    <h4 className="heading-color-frontdesk fw-600">
                                        {t('guestRegistration.guestVerification.newEntry')}
                                    </h4>
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <form
                                encType="multipart/form-data"
                                autoComplete="off"
                            >
                                <div className="card-body pt-0">
                                    <div className="row nationality-section">
                                        {identitySection && <div
                                            className="col-lg-12 col-xl-4 col-xxl-6 mt-xl-3 rounded-3"
                                        >
                                            <div className="documentid-data">
                                                <div className="row align-items-center">
                                                    {<div className="col-lg-12 col-xl-12">
                                                        <label className="form-label">
                                                            {t('guestRegistration.guestVerification.label.idType')}
                                                            <span className="text-danger ms-1">*</span>
                                                        </label>
                                                        <div className='form-group mb-3 mb-sm-0'>
                                                            {docType.map((docType, index) => (
                                                                <div className="form-check form-check-inline" key={docType.id}>
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        value={docType.id}
                                                                        onClick={() => handleDocTypeChange()}
                                                                        disabled={formFieldsDisabled}
                                                                        {...register('document_type', {
                                                                            required: true
                                                                        })}
                                                                    />
                                                                    <label className="form-check-label">
                                                                        {docType.documentName}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {errors.document_type && (
                                                            <small>
                                                                {errors.document_type.type === 'required' && (
                                                                    <span className="text-danger">
                                                                        {t(
                                                                            'guestRegistration.guestVerification.error.idType'
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </small>
                                                        )}
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>}
                                        {isMobile && <input
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            id="icon-button-file"
                                            type="file"
                                            capture="environment"
                                            onChange={(e) => handleCapture(e.target)}
                                        />}
                                        <div className="col-lg-12 col-xl-8 col-xxl-6" style={{ marginLeft: "-1rem" }}>
                                            <div className="row g-3">
                                                <div className=" col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-4 mt-3 mt-sm-0 mt-xl-4 d-flex justify-content-center">
                                                    <label htmlFor='icon-button-file'>
                                                        <div className="scan-box-visitor" onClick={() => setIsCapturePhotoModalOpen(true)}>
                                                            <img
                                                                src={`${userPhoto != "" ? userPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/user.png`}`}
                                                                style={{ maxWidth: '80px', height: "100%", width: "100%", aspectRatio: "auto" }}
                                                            />
                                                            <h4>{t('guestRegistration.guestVerification.label.capturePhoto')}</h4>
                                                        </div>
                                                    </label>
                                                </div>


                                                <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-4 mt-3 mt-sm-0 mt-xl-4 d-flex justify-content-center">
                                                    <div>
                                                        <div className="scan-box-visitor" onClick={() => frontsideClick()}>
                                                            <img
                                                                src={`${frontDocPhoto != "" ? frontDocPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/scanner.png`}`}
                                                                style={{ objectFit: "contain", aspectRatio: "auto" }}
                                                            />
                                                            <h4>{documentType != "3" ? t('guestRegistration.guestVerification.label.frontSide') : t('guestRegistration.guestVerification.label.scanDoc')}</h4>

                                                        </div>
                                                        {frontdocerror && (
                                                            <small className='frontdocerror'>
                                                                <span className="text-danger">
                                                                    {t('commondocerror.frontdocerror')}
                                                                    {/* Front Doc is required. */}
                                                                </span>
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>


                                                {documentType != "3" && <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-4 mt-3 mt-sm-0 mt-xl-4 d-flex justify-content-center">
                                                    <div>
                                                        <div className="scan-box-visitor" onClick={() => backsideClick()}>
                                                            <img
                                                                src={`${backDocPhoto != "" ? backDocPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/scanner.png`}`}
                                                                style={{ objectFit: "contain", aspectRatio: "auto" }}
                                                            />
                                                            <h4>{t('guestRegistration.guestVerification.label.backSide')}</h4>
                                                        </div>
                                                        {backdocerror && (
                                                            <small className='frontdocerror'>
                                                                <span className="text-danger">
                                                                    {t('commondocerror.backdocerror')}
                                                                    {/* Back Doc is required. */}
                                                                </span>
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="row">
                                            <h5 className="mb-3">
                                                {t('guestRegistration.guestVerification.guestInfo')}
                                            </h5>
                                            <div className="form-group col-lg-4 col-md-6 col-sm-6 mb-3">
                                                <label>
                                                    {t('guestRegistration.guestVerification.label.name')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                                <input
                                                    className={`form-control ${errors.name && 'is-invalid'
                                                        }`}
                                                    type="text"
                                                    disabled={formFieldsDisabled}
                                                    {...register('name', {
                                                        required: true,
                                                        pattern: pattern.fullName
                                                    })}
                                                />
                                                {errors.name && (
                                                    <small>
                                                        {errors.name.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('guestRegistration.guestVerification.error.name')}
                                                            </span>
                                                        )}
                                                        {errors.name.type === 'pattern' && (
                                                            <span className="text-danger">
                                                                {t('guestRegistration.guestVerification.error.validName')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>

                                            <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                                <label className="form-label">
                                                    {t('guestRegistration.guestVerification.label.docId')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                                <input
                                                    className={`form-control ${errors.document_id_number && 'is-invalid'
                                                        }`}
                                                    type="text"
                                                    disabled={formFieldsDisabled}
                                                    {...register('document_id_number', {
                                                        required: true,
                                                        pattern: pattern.notAllowSpaceInStartfront
                                                    })}
                                                />
                                                {errors.document_id_number && (
                                                    <small>
                                                        {errors.document_id_number.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t(
                                                                    'guestRegistration.guestVerification.error.docId'
                                                                )}
                                                            </span>
                                                        )}
                                                        {errors.document_id_number.type === 'pattern' && (
                                                            <span className="text-danger">
                                                                {t(
                                                                    'guestRegistration.guestVerification.error.docIdInvalid'
                                                                )}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>
                                            <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                                <label className="form-label">
                                                    {t('guestRegistration.guestVerification.label.nationality')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                                <input
                                                    className={`form-control ${errors.nationality && 'is-invalid'
                                                        }`}
                                                    type="text"
                                                    disabled={formFieldsDisabled}
                                                    {...register('nationality', { required: true, pattern: pattern.notAllowSpaceInStartfront })}
                                                />
                                                {errors.nationality && (
                                                    <small>
                                                        {errors.nationality.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('guestRegistration.guestVerification.error.nationality')}
                                                            </span>
                                                        )}
                                                        {errors.nationality.type === 'pattern' && (
                                                            <span className="text-danger">
                                                                {t('guestRegistration.guestVerification.error.nationalityInvalid')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>

                                            <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                                <label>
                                                    {t('guestRegistration.guestVerification.label.dob')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                                {/* <input
                                                    className={`form-control ${errors.visitorBirthDate && 'is-invalid'
                                                        }`}
                                                    type="text"
                                                    {...register('visitorBirthDate', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                                /> */}
                                                <Controller
                                                    name="dob"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange } }) => (
                                                        <ReactDatePicker
                                                            value={value}
                                                            onChange={onChange}
                                                            placeholder={t(
                                                                'guestRegistration.guestVerification.placeholder.dob'
                                                            )}
                                                            hasErrors={errors.dob}
                                                            maxDate={moment().toDate()}
                                                            dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                                                            formFieldsDisabled={formFieldsDisabled}
                                                        />
                                                    )}
                                                />
                                                {errors.dob && (
                                                    <small>
                                                        {errors.dob.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('guestRegistration.guestVerification.error.dob')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>
                                            <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                                <label>
                                                    {t('guestRegistration.guestVerification.label.gender')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                                <Controller
                                                    name="gender"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange } }) => (
                                                        <SearchableDropdown
                                                            value={value}
                                                            onChange={onChange}
                                                            options={genderOptions}
                                                            hasError={errors.gender}
                                                            placeholder={""}
                                                            formFieldsDisabled={formFieldsDisabled}
                                                        />
                                                    )}
                                                />
                                                {errors.gender && (
                                                    <small>
                                                        {errors.gender.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('guestRegistration.guestVerification.error.gender')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-1">
                                                <label>
                                                    {t('guestRegistration.guestVerification.label.destination')}
                                                    {
                                                        requiredField?.find((require) => require?.label_value == "destination")?.mandatory_status == 1 ? <span className="text-danger ms-1">*</span> : ''
                                                    }
                                                    {/* <span className="text-danger ms-1">*</span> */}
                                                </label>
                                                <Controller
                                                    name="destination_id"
                                                    control={control}
                                                    rules={{ required: requiredField?.find((require) => require?.label_value == "destination")?.mandatory_status == 1 ? true : false, pattern: pattern.notAllowSpaceInStartfront }}
                                                    render={({ field: { value, onChange } }) => (
                                                        <SearchableDropdown
                                                            value={value}
                                                            onChange={onChange}
                                                            options={destinations}
                                                            hasError={errors.destination_id}
                                                            placeholder={t('guestRegistration.guestVerification.placeholder.selectdestination')}
                                                            formFieldsDisabled={formFieldsDisabled}
                                                        />
                                                    )}
                                                />
                                                {errors.destination_id && (
                                                    <small>
                                                        {errors.destination_id.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('guestRegistration.guestVerification.error.destination')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>
                                            <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-1">
                                                <label>
                                                    {t('guestRegistration.guestVerification.label.vehicleRegistration')}
                                                    {
                                                        requiredField?.find((require) => require?.label_value == "vehicle_reg_no" && require?.frontdesks_type == 0)?.mandatory_status == 1 ? <span className="text-danger ms-1">*</span> : ''
                                                    }
                                                </label>
                                                <input
                                                    className={`form-control ${errors.vehicle_reg_no && 'is-invalid'
                                                        }`}
                                                    type="text"
                                                    disabled={formFieldsDisabled}
                                                    {...register('vehicle_reg_no', { required: requiredField?.find((require) => require?.label_value == "vehicle_reg_no" && require?.frontdesks_type == 0)?.mandatory_status == 1 ? true : false, pattern: pattern.notAllowSpaceInStartfront })}
                                                />
                                                {errors.vehicle_reg_no && (
                                                    <small>
                                                        {errors.vehicle_reg_no.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('guestRegistration.guestVerification.error.vehicle')}
                                                            </span>
                                                        )}
                                                        {errors.vehicle_reg_no.type === 'pattern' && (
                                                            <span className="text-danger">
                                                                {t('guestRegistration.guestVerification.error.vehicleinvalid')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>
                                            <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-1">
                                                <label>
                                                    {t('guestRegistration.guestVerification.label.accessCard')}
                                                    {
                                                        requiredField?.find((require) => require?.label_value == "access_card_no" && require?.frontdesks_type == 0)?.mandatory_status == 1 ? <span className="text-danger ms-1">*</span> : ''
                                                    }
                                                </label>
                                                <input
                                                    className={`form-control ${errors.access_card_no && 'is-invalid'
                                                        }`}
                                                    type="text"
                                                    disabled={formFieldsDisabled}
                                                    {...register('access_card_no', { required: requiredField?.find((require) => require?.label_value == "access_card_no" && require?.frontdesks_type == 0)?.mandatory_status == 1 ? true : false, pattern: pattern.notAllowSpaceInStartfront })}
                                                />
                                                {errors.access_card_no && (
                                                    <small>
                                                        {errors.access_card_no.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('guestRegistration.guestVerification.error.accesscard')}
                                                            </span>
                                                        )}
                                                        {errors.access_card_no.type === 'pattern' && (
                                                            <span className="text-danger">
                                                                {t('guestRegistration.guestVerification.error.accesscardinvalid')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>
                                            <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-1">
                                                <label>
                                                    {t('guestRegistration.guestVerification.label.otp')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                                <input
                                                    className={`form-control ${errors.otp && 'is-invalid'
                                                        }`}

                                                    type="text"
                                                    disabled={formFieldsDisabled}
                                                    {...register('otp', { required: true, pattern: pattern.otp })}
                                                />
                                                {errors.otp && (
                                                    <small>
                                                        {errors.otp.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('guestRegistration.guestVerification.error.otp')}
                                                            </span>
                                                        )}
                                                        {errors.otp.type === 'pattern' && (
                                                            <span className="text-danger">
                                                                {t('guestRegistration.guestVerification.error.validOtp')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form >
                        </div>
                        <div className="col-md-12">
                            <div className="form-btn d-flex align-items-center flex-wrap justify-content-end gap-2 pb-3 p-0">
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        checked={isWhatsApp}
                                        type="checkbox"
                                        onChange={() => setIsWhatsApp(!isWhatsApp)}
                                    />
                                    <label className="form-check-label mb-0">
                                        Send QR code
                                    </label>
                                </div>
                                {!isWhatsApp && <div className='p-2 col-xxl-2 col-xl-2 col-lg-2'>
                                    <button
                                        className="btn btn-primary-frontdesk addBtn"
                                        onClick={() => getAccessCardNumber()}
                                    >
                                        {t('guestRegistration.guestVerification.label.verifyAccessCard')}
                                    </button>
                                </div>}
                                <div className='p-2 col-xxl-2 col-xl-2 col-lg-2'>
                                    <button
                                        className="btn btn-primary-frontdesk addBtn"
                                        onClick={handleSubmit(onSubmitVisitorData)}
                                        onMouseEnter={() => setSubmitPress(true)}

                                    >
                                        {t('guestRegistration.guestVerification.label.addGuest')}
                                    </button>
                                </div>
                                <div className='p-2 col-xxl-2 col-xl-2 col-lg-2'>
                                    <button
                                        className="btn btn-primary-frontdesk addBtn"
                                        onClick={handleSubmit(submitDataToServer)}
                                    >
                                        {t('guestRegistration.guestVerification.label.submit')}
                                    </button>
                                </div>
                                {/* {showBypassBtn == 1 && (
                                    <div className='p-2 col-xxl-2 col-xl-2 col-lg-2'>
                                        <button
                                            type="button"
                                            onClick={handleSubmit(onBypass)}
                                            className="btn btn-primary-frontdesk btn-lg"
                                        >
                                            {t('visitorRegistration.label.byPass')}
                                        </button>
                                    </div>
                                )} */}

                            </div>
                        </div>
                    </div>

                </div >
                <div className='card table-body'>
                    <div className="pb-0 ">
                        <div className="row ">
                            <h3 className="heading-color-frontdesk fw-600">
                                {t('guestRegistration.guestDetails.title')}
                            </h3>
                            <div className='bg-img'>
                                <ReactTable
                                    columns={columns}
                                    data={allGuestData}
                                    headerClass="btn-primary-frontdesk"
                                    isSearchBoxVisibleFrontdesk={false}
                                    isSearchBoxVisible={false}
                                    ispaginationvisible={true}
                                    widthClass="table-width-frontdesk"
                                    showHeader={true}
                                />
                            </div>
                            <div className='d-flex justify-content-end mt-3'>
                                <button
                                    className="btn btn-primary-frontdesk submitBtn px-3"
                                    onClick={allGuestData.length > 0 ? () => submitAllRecordsToServer() : () => toast.error("Please add atleast one guest.", toastConfig)}
                                >
                                    {t('guestRegistration.guestVerification.label.submit')}
                                </button>
                            </div>
                        </div>
                        <GuestEditModal
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                            identitySection={identitySection}
                            docType={docType}
                            genderOptions={genderOptions}
                            guestData={editedRowData}
                            destinations={destinations}
                            onGuestDataEdit={onGuestDataEdit}
                            getAccessCardNumber={getAccessCardNumber}
                        />
                        <GuestBypassModal
                            show={showBypassModal}
                            handleClose={() => setShowBypassModal(false)}
                            setIdentitySection={setIdentitySection}
                            byPassType={byPassType}
                            guestData={guestData}
                            resetbypass={resetByPassVisitor}
                        // appointmentId={appointmentId}
                        // isAppointment={location?.state?.isAppointment}
                        />

<Guest7TimeBypassModal
                            show={show7TimeBypassModal}
                            handleClose={() => setShow7TimeBypassModal(false)}
                            setIdentitySection={setIdentitySection}
                            byPassType={byPassType}
                            guestData={guestData}
                            allguest={allGuestData}
                            bypassGuest={bypassguest}
                            setAllGuest={setAllGuestData}
                            resetbypass={resetByPassVisitor}
                            hidesubmit={hideSubmit}
                            hideaddbypass={hideAddBypass}
                        />
                        <CapturePhotoModal
                            isModalOpen={isMobile === true ? false : isCapturePhotoModalOpen}
                            setIsModalOpen={setIsCapturePhotoModalOpen}
                            setUserPhoto={setProfilePhoto}
                        />
                        <CaptureDocumentModal
                            isModalOpen={isCaptureFrontDocModalOpen}
                            setIsModalOpen={setIsCaptureFrontDocModalOpen}
                            setUserPhoto={setFrontDocImage}
                        />
                        <CaptureDocumentModal
                            isModalOpen={isCaptureBackDocModalOpen}
                            setIsModalOpen={setIsCaptureBackDocModalOpen}
                            setUserPhoto={setBackDocImage}
                        />
                    </div>
                </div>
            </div >
        </>
    )
}

export default GuestRegistration