import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { Controller, useForm } from 'react-hook-form';
import { resetServerContext } from 'react-beautiful-dnd';
import pattern from 'components/configuration/form-pattern';
// import { authenticateUser } from 'services/frontDeskServices';
import Swal from 'sweetalert2';
import sweetalertConfig from 'components/configuration/sweetalert-config';
import '../visitorRegistration/bypass-alert.css';
import InputPassword from 'components/common/InputPassword';
import { bypassVisitor, guestbypass } from 'services/visitorRegistrationService'
import { visitorBypassData } from 'services/visitorAppointmentService'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { loaderContext } from 'context/Context';
import { useContext } from 'react';

export default function GuestBypassModal({ show, handleClose, setIdentitySection, byPassType, guestData, resetbypass, isAppointment, appointmentId }) {
  const { t } = useTranslation();
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const navigate = useNavigate();
  const {
    register: registerBypass,
    handleSubmit: handleSubmitBypass,
    formState: { errors: errorsBypass },
    reset,
    control
  } = useForm();

  console.log("guestdata====>", guestData)

  const onBypassSubmit = async bypassData => {
    // try {
    Swal.fire({
      title: t('visitorRegistration.sweetAlert.bpconfirmationTitle'),
      ...sweetalertConfig.confirmation,
      cancelButtonText: t('visitorRegistration.sweetAlert.bpBtnNo'),
      confirmButtonText: t('visitorRegistration.sweetAlert.bpBtnYes')
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        try {
          const { otp, ...restData } = guestData
          bypassData = { ...bypassData, ...restData }
          console.log("bypassDataaaaaaaaaaaaaaaa", bypassData)
          const { data } = await guestbypass(bypassData);
          toast.success(data.message, toastConfig);
          byPassType === "indentity" && setIdentitySection(false)
          onClose()
          resetbypass()
          navigate('/guest-registration')
        } catch (e) {
          // toast.error(e.message, toastConfig);
          if (e.response.data) {
            if (Array.isArray(e.response.data.message)) {
              e.response.data.message.map(errors => toast.error(errors, toastConfig))
            } else {
              toast.error(e.response.data.message, toastConfig)
            }
          } else {
            toast.error(e.message, toastConfig)
          }
        }
        setIsLoading(false)
      }
    });

    // } catch (e) {
    //   toast.error(e.message, toastConfig);
    // }

    console.log('click');
  };
  const onClose = () => {
    handleClose();
    reset();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <form autoComplete="off">
        <Modal.Header
          closeVariant='white'
          style={{ backgroundColor: 'var(--secondary)' }}
          closeButton
        >
          <Modal.Title>
            <h5 className='text-white'>{t('visitorRegistration.label.bpManagerLogin')}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-3">
            <label className="col-form-label">
              {t('visitorRegistration.label.bpEmail')}
              <span className="">:</span>
            </label>
            <input
              type="text"
              className={`form-control ${errorsBypass.admin_email && 'is-invalid'}`}
              {...registerBypass('admin_email', {
                required: true,
                pattern: pattern.email
              })}
            />
            {errorsBypass.admin_email && (
              <small>
                {errorsBypass.admin_email.type === 'required' && (
                  <span className="text-danger">
                    {t('visitorRegistration.error.bpEmail')}
                  </span>
                )}
                {errorsBypass.admin_email.type === 'pattern' && (
                  <span className="text-danger">
                    {t('visitorRegistration.error.bpEmailPattern')}
                  </span>
                )}
              </small>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="col-form-label">
              {t('visitorRegistration.label.bpPassword')}
              <span className="">:</span>
            </label>
            <Controller
              name="admin_password"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => {
                return (
                  <InputPassword
                    value={value}
                    onChange={onChange}
                    isInvalid={errorsBypass.admin_password ? true : false}
                  />
                )
              }}
            />
            {errorsBypass.admin_password && (
              <small>
                {errorsBypass.admin_password.type === 'required' && (
                  <span className="text-danger">
                    {t('visitorRegistration.error.bpPassword')}
                  </span>
                )}
              </small>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-danger"
              onClick={onClose}
            >
              {t('visitorRegistration.label.bpClose')}
            </button>
            <button
              type="button"
              className="btn btn-primary ms-2"
              onClick={handleSubmitBypass(onBypassSubmit)}
            >
              {t('visitorRegistration.label.bpLogin')}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
