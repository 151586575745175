import { guestBookingEndPoints } from "./config"
import httpServices from "./httpServices"

export const getGuestslist = async () => {
    return await httpServices.post(guestBookingEndPoints.listGuest)
}
// export const getGuestById = async (guestData) => {
//     return await httpServices.post(guestBookingEndPoints.listGuest, guestData)
// }
export const registerGuest = async (registerGuestData) => {
    return await httpServices.post(guestBookingEndPoints.registerGuest, registerGuestData)
}