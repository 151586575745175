import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import SearchableDropdown from 'components/common/SearchableDropdown';
import '../../../../assets/css/searchable-dropdown.css';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { departmentadd } from 'services/masterModuleService'
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import pattern from 'components/configuration/form-pattern';

export default function CreatDepartment() {

  const [statusOption, setStatusOption] = useState([{ label: "Active", value: "Active" }, { label: "Inactive", value: "Inactive" }])
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    clearErrors,
    formState: { errors }
  } = useForm();
  const { t } = useTranslation();
  useEffect(() => {
    checkModulePermission("Department")
  }, [])

  const onSubmit = async (newData) => {
    console.log("newData", newData)
    setIsLoading(true)
    try {
      newData.status = newData?.status?.value
      const { data } = await departmentadd({ is_update: 0, ...newData })
      toast.success(data.message, toastConfig)
      setValue("name", "")
      setValue("status", "")
      navigate("/masters/department-master")
    } catch (e) {
      if (e.response.data) {
        if (Array.isArray(e.response.data.message)) {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else {
          toast.error(e.response.data.message, toastConfig)
        }
      } else {
        toast.error(e.message, toastConfig)
      }
    }
    setIsLoading(false)
  };
  const resetValue = () => {
    reset();
    setValue("name", "")
    setValue("status", "")
  };

  return (
    <>
      <div className="row g-3 mb-3">
        <div className="">
          <div className="card overflow-hidden mb-3">
            <div className="card-header">
              <div className="row">
                <div className="col-8">
                  <h3 className="heading-color fw-600">
                    {t('departmentMaster.heading.addHeading')}
                  </h3>
                </div>
                <div className="col-4 table-btn">
                  <button
                    className="btn btn-primary float-end btn-sm"
                    onClick={() => navigate('/masters/department-master')}
                  >
                    <FontAwesomeIcon icon="chevron-left" />
                    <span className="ms-1">
                      {t('departmentMaster.btnLabel.back')}
                    </span>
                  </button>
                </div>
              </div>
              <div className="col-12">
                <hr />
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('departmentMaster.formLabel.department')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <input
                        className={`form-control ${errors.name ? 'is-invalid' : ''
                          }`}
                        type="text"
                        placeholder={t(
                          'departmentMaster.placeholder.departmentName'
                        )}
                        {...register('name', { required: true, pattern: pattern.notAllowSpaceInStart })}
                      />
                      {errors.name && (
                        <small className="text-danger">
                          {errors.name.type == 'required' && (
                            <span>
                              {t('departmentMaster.error.departmentError')}
                            </span>
                          )}
                          {errors.name.type == 'pattern' && (
                            <span>
                              {t('departmentMaster.error.departmentinvalidError')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('departmentMaster.formLabel.status')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="status"
                        render={({ field: { value, onChange } }) => (
                          <SearchableDropdown
                            options={statusOption}
                            isClearable={false}
                            onChange={onChange}
                            hasError={errors.status}
                            placeholder={t(
                              'departmentMaster.placeholder.selectstatus'
                            )}
                            value={value}
                          // noOptionsMessage={() =>
                          //   t('departmentMaster.error.selectCompanyMsg')
                          // }
                          />
                        )}
                      />
                      {errors.status && (
                        <small className="text-danger">
                          {errors.status.type == 'required' && (
                            <span>
                              {t('departmentMaster.error.statusError')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="text-start col-12">
                    <div className="mb-3 text-end">
                      <button type="submit" className="btn btn-primary me-1">
                        {t('departmentMaster.btnLabel.add')}
                      </button>
                      <button
                        type="reset"
                        className="btn btn-danger"
                        onClick={resetValue}
                      >
                        {t('departmentMaster.btnLabel.reset')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
