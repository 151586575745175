import React from "react";
import platform from "platform";
import os from "os";
import { internalIpV4 } from "internal-ip"
import httpServices from "services/httpServices";
import axios from "axios";
import { isMobile, isBrowser } from "react-device-detect";

const browserData = () => {
    //const networkInterfaces = os.networkInterfaces();
    //const ip = await networkInterfaces['eth0'][0]['address']
    // console.log(networkInterfaces);
    // console.log(await getIPv4())
    return {
        browserName: platform.name,
        browserVersion: platform.version,
        os: platform.os.family,
        IPv4: localStorage.getItem("IPv4"),
        device: isMobile ? "Mobile" : "Desktop"
        //device:getDeviceName()
    }
}
const getDeviceName = () => {
    const { innerWidth: width, innerHeight: height } = window;
    if (width <= 480) {
        return "Mobile";
    }
    else {
        return "Desktop";
    }
}
export const getIPv4 = async () => {
    // try{
    //     const {data}= await axios.get("https://geolocation-db.com/json/")
    //     return data.IPv4;
    // }catch(err){
    //     console.log(err)
    // }
    return "abc"
}
export default browserData;