import React from 'react';
import { Audio ,Bars} from 'react-loader-spinner';
import '../../assets/css/loader.css';

export default function Loader() {
  return (
    <div className="loader">
        <div className='loader-element'>

        <Bars
            height="40"
            width="40"
            radius="9"
            color="var(--primary)"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
        />
        </div>
    </div>
  );
}
