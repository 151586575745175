import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import '../../../../assets/css/general.css'
import { blacksearchlist, blackadd, blacklisttype } from 'services/adminmoduleService'
import { loaderContext } from 'context/Context';
import pattern from 'components/configuration/form-pattern';
import ReactDatePicker from 'components/common/ReactDatePicker';
import moment from 'moment';


export default function AddBlackList({ show, handleClose, setblacklist, blacklist }) {
    const { t } = useTranslation();
    const [visitorData, setVisitorData] = useState([]);
    const [blacklisttypeoption, setBlackListTypeOption] = useState([])
    const [newData, setNewData] = useState()
    const [opendatefield, setOpenDateField] = useState(false)
    const { isLoading, setIsLoading } = useContext(loaderContext)

    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        unregister,
        watch,
        formState: { errors }
    } = useForm();


    useEffect(async () => {
        if (show == true) {
            setIsLoading(true)
            try {
                const { data } = await blacksearchlist();
                const { data: blacklistType } = await blacklisttype();
                setBlackListTypeOption(blacklistType?.data?.map((black) => ({ label: black.charAt(0).toUpperCase() + black.slice(1), value: black })))
                setVisitorData(
                    (data?.data || []).map(visitorList => ({
                        visitor_type: `${visitorList.visitor_type}`,
                        visitor_id: `${visitorList.value}`,
                        value: `${visitorList.name} ${visitorList.doc_id_number}  ${visitorList.phone} ${visitorList.email}`,
                        label: (
                            <div key={visitorList.value} className="d-flex flex-column pe-3">
                                {/* <p className="p-0 m-0">{`Name:-  ${visitorList.name}`}</p>
                                <p className="p-0 m-0">{`Doc-id-number:-  ${visitorList.doc_id_number}`}</p>
                                <p className="p-0 m-0">{`Email:-  ${visitorList.email}`}</p>
                                <p className="p-0 m-0">{`Phone:-  ${visitorList.phone}`}</p> */}
                                {`${visitorList?.name && visitorList?.name != "" ? visitorList?.name : "--"} | ${visitorList?.doc_id_number && visitorList?.doc_id_number != "" ? visitorList?.doc_id_number : "--"} | ${visitorList?.email && visitorList?.email != "" ? visitorList.email : "--"} | ${visitorList?.phone && visitorList?.phone != "" ? visitorList?.phone : "--"}`}
                            </div>
                        ),
                    }))
                );
            } catch (e) {
                errorMessage(e);
            }
            setIsLoading(false)
        }
    }, [show]);

    console.log("newData=====", newData)
    const selectedType = watch('blacklist_type')
    useEffect(() => {
        if (selectedType?.label === "Suspension") {
            setOpenDateField(true)
        } else {
            setOpenDateField(false)
        }
    }, [selectedType])

    console.log("selected", selectedType)

    const onSubmit = async (blacklistData) => {
        setIsLoading(true)
        try {
            const { blacklist_date, ...restData } = blacklistData
            restData.visitor_type = restData?.search?.visitor_type;
            restData.visitor_id = restData?.search?.visitor_id;
            restData.blacklist_type = restData?.blacklist_type?.value;

            if (restData?.blacklist_type?.value != "permanent") restData.blacklist_date = blacklist_date ? moment(blacklist_date).format(process.env.REACT_APP_DATE_FORMAT) : ""

            const { data } = await blackadd(restData);
            const updatedData = data?.data[0]?.blacklist_visitor
            setblacklist([updatedData, ...blacklist])
            toast.success(data?.message, toastConfig);
            reset();
            setValue('search', '');
            handleClose();
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    };
    const onClose = () => {
        setValue('search', '');
        reset();
        handleClose();
    };

    return (
        <>
            <Modal show={show} onHide={onClose} className='add-blacklist-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>{t('blackListVisitor.modal.tittle.addBlacklistVisitor')}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <div className="p-3">
                            <Controller
                                control={control}
                                rules={{ required: true }}
                                name="search"
                                render={({ field: { value, onChange } }) => (
                                    <SearchableDropdown
                                        onChange={onChange}
                                        searchLabel={false}
                                        searchValue={true}
                                        value={value}
                                        options={visitorData}
                                        hasError={errors.search}
                                        placeholder={
                                            <div>
                                                {/* <FontAwesomeIcon
                                                    icon="search"
                                                    style={{ color: '#b6c1d2' }}
                                                /> */}
                                                <span>
                                                    {t('blackListVisitor.modal.placeholder.search')}
                                                </span>
                                            </div>
                                        }

                                    />
                                )}
                            />
                            {errors.search && (
                                <small className="text-danger">
                                    {errors.search.type == 'required' && (
                                        <span>{t('blackListVisitor.modal.error.data')}</span>
                                    )}
                                </small>
                            )}
                            <div className="row mt-3">

                                <div className="col-12 col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label">
                                            {t('blackListVisitor.modal.formLabel.blackListType')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <Controller
                                            control={control}
                                            rules={{ required: true }}
                                            name="blacklist_type"
                                            render={({ field: { value, onChange } }) => (
                                                <SearchableDropdown
                                                    options={blacklisttypeoption}
                                                    isClearable={false}
                                                    onChange={onChange}
                                                    hasError={errors.blacklist_type}
                                                    placeholder={t(
                                                        'blackListVisitor.modal.placeholder.blackListType'
                                                    )}
                                                    value={value}
                                                // noOptionsMessage={() =>
                                                //   t('departmentMaster.error.selectCompanyMsg')
                                                // }
                                                />
                                            )}
                                        />
                                        {errors.blacklist_type && (
                                            <small className="text-danger">
                                                {errors.blacklist_type.type == 'required' && (
                                                    <span>
                                                        {t('blackListVisitor.modal.error.blackListType')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                </div>
                                {
                                    opendatefield && (
                                        <div className="col-12 col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    {t('blackListVisitor.modal.formLabel.blackListDate')}
                                                    {/* <span className="text-danger ms-1">*</span> */}
                                                </label>
                                                <Controller
                                                    control={control}
                                                    // rules={{ required: true }}
                                                    name="blacklist_date"
                                                    render={({ field: { value, onChange } }) => (
                                                        <ReactDatePicker
                                                            value={value}
                                                            selected={value}
                                                            onChange={onChange}
                                                            portalId=''
                                                            // hasErrors={errors.blacklist_date}
                                                            minDate={moment().toDate()}
                                                            placeholder={t(
                                                                'blackListVisitor.modal.placeholder.blackListDate'
                                                            )}
                                                            dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                                                        />
                                                    )}
                                                />
                                                {/* {errors.blacklist_date && (
                                                <small className="text-danger">
                                                    {errors.blacklist_date.type == 'required' && (
                                                        <span>
                                                            {t('blackListVisitor.modal.error.blackListDate')}
                                                        </span>
                                                    )}
                                                </small>
                                            )} */}
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                            <div>
                                <div className="mb-3">
                                    <label className="form-label">
                                        {t('blackListVisitor.modal.formLabel.reason')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <textarea
                                        className={`form-control  ${errors.reason && 'is-invalid'
                                            }`}
                                        type="textarea"
                                        rows="8"
                                        cols="50"
                                        placeholder={t('blackListVisitor.modal.placeholder.reason')}
                                        {...register('reason', { required: true, })}
                                    ></textarea>
                                    {errors.reason && (
                                        <small className="text-danger">
                                            {errors.reason.type == 'required' && (
                                                <span>
                                                    {t('blackListVisitor.modal.error.reasonError')}
                                                </span>
                                            )}
                                            {/* {errors.reason.type == 'pattern' && (
                                            <span>
                                                {t('blackListVisitor.modal.error.reasoninvalidError')}
                                            </span>
                                        )} */}
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                        <hr className="mt-1" />
                        <div className="d-flex justify-content-end m-3">
                            {/* <button
                                type="button"
                                className="btn btn-secondary me-1"
                                onClick={onClose}
                            >
                                {t('blackListVisitor.modal.btnLabel.close')}
                            </button> */}
                            <button className="btn btn-primary">
                                {t('blackListVisitor.modal.btnLabel.addBlackList')}
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}
