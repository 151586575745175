import React from 'react'
import { Card } from 'react-bootstrap';

function BroadCastMessage() {
    return (
        <Card>
            <Card.Body> Broad Cast Message</Card.Body>
        </Card>
    )
}

export default BroadCastMessage