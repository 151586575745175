import { visitorRegistrationEndPoints } from "./config"
import httpServices from "./httpServices"

export const getListOfVisitorType = async () => {
    return await httpServices.get(visitorRegistrationEndPoints.listVisitorType)
}
export const getListOfDepartment = async () => {
    return await httpServices.get(visitorRegistrationEndPoints.listDepartment)
}
export const getListOfEmployee = async () => {
    return await httpServices.get(visitorRegistrationEndPoints.listEmployee)
}
export const visitorRegister = async (visitorRegisterData) => {
    return await httpServices.post(visitorRegistrationEndPoints.visitorRegister, visitorRegisterData)
}
export const visitorRegistrationVerifyOTP = async (visitorNo) => {
    return await httpServices.post(visitorRegistrationEndPoints.verifyOtp, visitorNo)
}
export const visitorRegistrationVerifyOTPbyDoc = async (data) => {
    return await httpServices.post(visitorRegistrationEndPoints.sendotpbyvisitordocument, data)
}
export const bypassVisitor = async (bypassVisitorData) => {
    return await httpServices.post(visitorRegistrationEndPoints.bypass, bypassVisitorData)
}
export const guestbypass = async (guestbypassVisitorData) => {
    return await httpServices.post(visitorRegistrationEndPoints.guestbypass, guestbypassVisitorData)
}
export const getmanagefrontdeskfield = async () => {
    return await httpServices.get(visitorRegistrationEndPoints.getmanagefield)
}
export const getListOfVisitorFrom = async () => {
    return await httpServices.get(visitorRegistrationEndPoints.listVisitorFrom)
}