import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateAppointment, getAppointment } from 'services/appointmentService';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import SearchableDropdown from 'components/common/SearchableDropdown';
import pattern from 'components/configuration/form-pattern';
import PhoneNumber from 'components/common/PhoneNumber';
import { defaultCountryCode } from 'components/configuration/country-code';
import ReactDatePicker from 'components/common/ReactDatePicker';
import ReactTimePicker from 'components/common/ReactTimePicker';
import SetImagePlaceholderUploder from 'components/doc-components/SetImagePlaceholderUploder';
import countryCodes from 'components/configuration/country-code';
import moment from 'moment';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { appointmentupdate, appointmentidentilist, getStaffList } from 'services/AppointmenttService'
import { loaderContext } from 'context/Context';


export default function EditAppointment() {
  const [appointmentData, setAppointmentData] = useState({});
  const [behalfNameOptions, setBehalfNameOptions] = useState([]);
  const [identityTypeOptions, setIdentityTypeOptions] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const [staffNameDiasable, setStaffDisable] = useState(false)
  const [staffList, setStaffList] = useState([])
  const [hostCountryCode, setHostCountryCode] = useState(defaultCountryCode);
  const [guestCountryCode, setGuestCountryCode] = useState(defaultCountryCode);
  const { isLoading, setIsLoading } = useContext(loaderContext)


  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    clearErrors,
    watch,
    formState: { errors }
  } = useForm();
  const startTime = watch('startTime')
  const staffName = watch("staff_name")

  console.log("locat====", location)
  useEffect(() => {
    if (staffName)
    {
      const staffData = staffList.find((staff) => staff.id === staffName.value)
      console.log("CountryCode", countryCodes.find((country) => country.dial_code === staffData?.country_code))
      setGuestCountryCode(countryCodes.find((country) => country.dial_code === staffData?.country_code))
      setValue("staff_mob_number", staffData?.phone)
      clearErrors("staff_mob_number")
    } else
    {
      setValue("staff_mob_number", "")
      setGuestCountryCode(defaultCountryCode)
    }

  }, [staffName])

  // useEffect(async () => {
  //   checkModulePermission("Appointment")
  //   try {
  //     const { data } = await getAppointment(decodeData(params.id));
  //     const employee = await getEmployees();
  //     const docType = await getDocType();
  //     employee.data.data.results.map(
  //       employee =>
  //         employee.id == data.data.result.employeeId &&
  //         (data.data.result.employeeId = {
  //           value: employee.id,
  //           label: employee.employeeName
  //         })
  //     );
  //     docType.data.data.results.map(
  //       docType =>
  //         docType.id == data.data.result.documentType &&
  //         (data.data.result.documentType = {
  //           value: docType.id,
  //           label: docType.documentName
  //         })
  //     )
  //     data.data.result.guestCountryCode && setGuestCountryCode(
  //       countryCodes.find(
  //         code => code.dial_code == data.data.result.guestCountryCode
  //       )
  //     );
  //     data.data.result.employeeCountryCode && setHostCountryCode(
  //       countryCodes.find(
  //         code => code.dial_code == data.data.result.employeeCountryCode
  //       )
  //     );
  //     // data.result.appointmentStartDate = moment(
  //     //   data.result.appointmentStartDate,
  //     //   'DD-MM-YYYY'
  //     // ).toDate();
  //     // data.result.startTime = moment(data.result.startTime, 'HH:mm').toDate();
  //     // data.result.endTime = moment(data.result.endTime, 'HH:mm').toDate();
  //     data.data.result.appointmentStartDate = moment(
  //       data.data.result.appointmentStartDate,
  //       process.env.REACT_APP_DATE_FORMAT
  //     ).toDate();
  //     data.data.result.startTime = moment(
  //       data.data.result.startTime,
  //       process.env.REACT_APP_TIME_FORMAT_SET_12 === 'false'
  //         ? process.env.REACT_APP_TIME_FORMAT_24
  //         : process.env.REACT_APP_TIME_FORMAT_12
  //     ).toDate();
  //     data.data.result.endTime = moment(
  //       data.data.result.endTime,
  //       process.env.REACT_APP_TIME_FORMAT_SET_12 === 'false'
  //         ? process.env.REACT_APP_TIME_FORMAT_24
  //         : process.env.REACT_APP_TIME_FORMAT_12
  //     ).toDate();
  //     setAppointmentData(data.data.result);

  //     setBehalfNameOptions(
  //       employee.data.data.results.map(data => ({
  //         value: data.id,
  //         label: data.employeeName
  //       }))
  //     );
  //     setIdentityTypeOptions(
  //       docType.data.data.results.map(data => ({
  //         value: data.id,
  //         label: data.documentName
  //       }))
  //     );
  //   } catch (e) {
  //     errorMessage(e)
  //   }
  // }, []);

  // useEffect(() => {
  //   if (appointmentData) {
  //     reset(appointmentData);
  //   }
  // }, [appointmentData.id]);


  useEffect(async () => {
    checkModulePermission("Appointment")
    setIsLoading(true)
    try
    {
      const userId = localStorage.getItem("userId")
      const userType = localStorage.getItem("userType")
      const { data: docType } = await appointmentidentilist();
      const { data } = await getStaffList();
      setStaffList(data?.data)
      if (userType == "2" || userType == "3")
      {
        const staffUser = data?.data?.find((staff) => staff.id == userId)
        console.log("Staff user", staffUser)
        setStaffDisable(true)
        setValue("staff_name", { label: staffUser?.name, value: staffUser?.id })
      }
      setDocumentType(
        docType?.data?.map(data => ({
          value: data.id,
          label: data.name
        }))
      );
    } catch (e)
    {
      errorMessage(e)
    }
    setIsLoading(false)

  }, []);

  useEffect(() => {
    console.log("country", countryCodes?.find((country) => country?.dial_code === location?.state?.staff_country_code))
    setValue("staff_name", { label: location?.state?.staff_name, value: location?.state?.staff_id })
    setValue("staff_mob_number", location?.state?.staff_mob_number)
    setGuestCountryCode(countryCodes?.find((country) => country?.dial_code === location?.state?.staff_country_code))
    setHostCountryCode(countryCodes?.find((countryy) => countryy?.dial_code === location?.state?.country_code))
    setValue("visitor_mob_number", location?.state?.phone)
    const fullName = location?.state?.visitor_fname || '';
    const [visitorFirstName, visitorLastName] = fullName.split(' ');
    setValue("visitor_fname", visitorFirstName || '');
    setValue("visitor_lname", visitorLastName || '');
    setValue("visitor_email", location?.state?.visitor_email)
    setValue("visitor_from", location?.state?.visitor_from)
    setValue("doc_type_id", documentType?.find((doc) => doc.value === location?.state?.identification_type_id))
    setValue("doc_id_number", location?.state?.id_number)
    setValue("date_of_appointment", moment(location?.state?.date_of_appointment).toDate())
    setValue("start_time", moment(location?.state?.start_time, process.env.REACT_APP_TIME_FORMAT_24).toDate().getTime())
    setValue("purpose", location?.state?.purpose)
    setValue("notes", location?.state?.notes)
  }, [documentType])

  const onSubmit = async (appointmentData) => {

    setIsLoading(true)
    try
    {
      console.log("date=============", appointmentData)
      appointmentData.staff_id = appointmentData?.staff_name?.value
      appointmentData.staff_name = appointmentData?.staff_name?.label
      appointmentData.staff_country_code = guestCountryCode?.dial_code
      appointmentData.visitor_country_code = hostCountryCode?.dial_code
      appointmentData.doc_type_id = appointmentData?.doc_type_id?.value
      appointmentData.visitor_fname = `${appointmentData.visitor_fname} ${appointmentData.visitor_lname}`
      appointmentData.date_of_appointment = moment(appointmentData.date_of_appointment).format(process.env.REACT_APP_DATE_FORMAT)
      appointmentData.start_time = moment(appointmentData.start_time).format(process.env.REACT_APP_TIME_FORMAT_24)
      const { data } = await appointmentupdate({ id: location?.state?.id, ...appointmentData });
      toast.success(data.message, toastConfig);
      navigate('/appointment');
    } catch (e)
    {
      if (e.response.data)
      {
        if (Array.isArray(e.response.data.message))
        {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else
        {
          toast.error(e.response.data.message, toastConfig)
        }
      } else
      {
        toast.error(e.message, toastConfig)
      }
    }
    setIsLoading(false)

  };
  const onStartTimeChange = (e) => {
    setValue('start_time', e);
  }
  const onReset = () => {
    reset();
    setGuestCountryCode(defaultCountryCode)
    setHostCountryCode(defaultCountryCode)
    setValue("staff_name", "")
    setValue("doc_type_id", "")
  };

  // const dateOfAppointment = watch('date_of_appointment');
  // useEffect(() => {
  //   if (dateOfAppointment) {
  //     // const currentTime = moment();
  //     // const roundedTime = moment(Math.ceil(currentTime / (15 * 60 * 1000)) * (15 * 60 * 1000));
  //     // setValue("start_time", roundedTime.toDate());
  //     setValue("start_time", moment(location?.state?.start_time, process.env.REACT_APP_TIME_FORMAT_24).toDate().getTime());

  //   }
  // }, [dateOfAppointment]);

  // useEffect(() => {
  //   if (dateOfAppointment) {
  //     const currentDate = moment();
  //     const selectedDate = moment(dateOfAppointment);

  //     if (selectedDate.isSame(currentDate, 'day')) {
  //       const roundedTime = moment(Math.ceil(currentDate / (15 * 60 * 1000)) * (15 * 60 * 1000));
  //       setValue("start_time", roundedTime.toDate());

  //     } else {
  //       setValue("start_time", moment(location?.state?.start_time, process.env.REACT_APP_TIME_FORMAT_24).toDate().getTime());
  //     }
  //   }
  // }, [dateOfAppointment]);

  return (
    <>
      <div className="row g-3 mb-3">
        <div className="">
          <div className="card overflow-hidden mb-3">
            <div className="card-header">
              <div className="row">
                <div className="col-8">
                  <h3 className="heading-color fw-600 mb-0">
                    {t('appointment.heading.editHeading')}
                  </h3>
                </div>
                <div className="col-4 table-btn">
                  <button
                    className="btn btn-primary btn-sm float-end"
                    onClick={() => {
                      navigate('/appointment');
                    }}
                  >
                    <FontAwesomeIcon icon="chevron-left" />
                    <span className="ms-1 d-sm-inline ">
                      {t('appointment.btnLabel.back')}
                    </span>
                  </button>
                </div>
                <div className="col-12">
                  <hr />
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.onBehalfOf')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        name='staff_name'
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <SearchableDropdown
                              value={value}
                              onChange={onChange}
                              options={staffList.map((staff) => ({ label: staff?.name, value: staff?.id }))}
                              placeholder={t(
                                'appointment.placeholder.staffname'
                              )}
                              noOptionsMessage={staffList?.length > 0 ? () => t('appointment.error.dropdownError') : () => t('appointment.error.addStaffName')}
                              isClearable={true}
                              formFieldsDisabled={staffNameDiasable}
                            />
                          )
                        }}
                      />
                      {errors.staff_name && (
                        <small className="text-danger">
                          {errors.staff_name.type == 'required' && (
                            <span>{t('appointment.error.staffname')}</span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.hostMobileNo')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        name="employeeContactNo"
                        control={control}
                        rules={{ required: true, pattern: pattern.numberOnly }}
                        render={({ field: { value, onChange } }) => (
                          <PhoneNumber
                            dropdownDirection="bottom"
                            value={value}
                            onChange={onChange}
                            countryCode={hostCountryCode}
                            setCountryCode={setHostCountryCode}
                            error={errors.employeeContactNo}
                            isInputVisible={true}
                          />
                        )}
                      />
                      {errors.employeeContactNo && (
                        <small className="text-danger">
                          {errors.employeeContactNo.type === 'required' && (
                            <span>
                              {t('appointment.error.hostMobileNoError')}
                            </span>
                          )}
                          {errors.employeeContactNo.type === 'pattern' && (
                            <span>
                              {t('appointment.error.hostMobileNoPatternError')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div> */}

                  {/* <div className="col-md-4 row pe-0 mb-3">
                    <div className='form-group pe-0 col-md-6'>
                      <label className="form-label">
                        {t('appointment.formLabel.countrycode')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        name="countryCode"
                        control={control}
                        rules={{ required: true, pattern: pattern.numberOnly }}
                        render={({ field: { value, onChange } }) => (
                          <CountryCode
                            countryCode={countryCode}
                            setCountryCode={setCountryCode}
                            direction="bottom"
                            error={errors.countryCode}
                          />
                        )}
                      />
                      {errors.countryCode && (
                        <small>
                          {errors.countryCode.type === 'required' && (
                            <span className="text-danger">
                              {t('appointment.error.countrycode')}
                            </span>
                          )}
                          {errors.countryCode.type === 'pattern' && (
                            <span className="text-danger">
                              {t('appointment.error.countrycode')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                    <div className='form-group pe-0 col-md-6'>
                      <label className="form-label">
                        {t('appointment.formLabel.staffmobileno')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <input
                        type='text'
                        className={`form-control ${errors.visitorMobileNo && 'is-invalid'
                          }`}
                        placeholder={t('appointment.placeholder.visitorMobileNo')}
                        {...register('visitorMobileNo', { required: true, pattern: formPattern.phoneNumber })}
                      />
                      {errors.visitorMobileNo && (
                        <small>
                          {errors.visitorMobileNo.type === 'required' && (
                            <span className="text-danger">
                              {t('appointment.error.staffmbno')}
                            </span>
                          )}
                          {errors.visitorMobileNo.type === 'pattern' && (
                            <span className="text-danger">
                              {t('appointment.error.staffmbnoinvalid')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div> */}

                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.staffmobileno')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        name="staff_mob_number"
                        control={control}
                        rules={{ required: true, pattern: pattern.numberOnly }}
                        render={({ field: { value, onChange } }) => (
                          <PhoneNumber
                            dropdownDirection="bottom"
                            value={value}
                            onChange={onChange}
                            countryCode={guestCountryCode}
                            setCountryCode={setGuestCountryCode}
                            error={errors.staff_mob_number}
                            isInputVisible={true}
                            isDisable={true}
                          />
                        )}
                      />
                      {errors.staff_mob_number && (
                        <small className="text-danger">
                          {errors.staff_mob_number.type === 'required' && (
                            <span>
                              {t('appointment.error.staffMobileNoError')}
                            </span>
                          )}
                          {errors.staff_mob_number.type === 'pattern' && (
                            <span>
                              {t('appointment.error.staffMobileNoPatternError')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>


                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.guestMobileNo')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        name="visitor_mob_number"
                        control={control}
                        rules={{ required: true, pattern: pattern.numberOnly }}
                        render={({ field: { value, onChange } }) => (
                          <PhoneNumber
                            dropdownDirection="bottom"
                            value={value}
                            onChange={onChange}
                            countryCode={hostCountryCode}
                            setCountryCode={setHostCountryCode}
                            error={errors.visitor_mob_number}
                            isInputVisible={true}
                          />
                        )}
                      />
                      {errors.visitor_mob_number && (
                        <small className="text-danger">
                          {errors.visitor_mob_number.type === 'required' && (
                            <span>
                              {t('appointment.error.visitorMobileNoError')}
                            </span>
                          )}
                          {errors.visitor_mob_number.type === 'pattern' && (
                            <span>
                              {t('appointment.error.visitorMobileNoPatternError')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.firstName')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.visitor_fname ? 'is-invalid' : ''
                          }`}
                        placeholder={t('appointment.placeholder.firstName')}
                        {...register('visitor_fname', {
                          required: true,
                          pattern: pattern.notAllowSpaceInStart
                        })}
                      />
                      {errors.visitor_fname && (
                        <small className="text-danger">
                          {errors.visitor_fname.type == 'required' && (
                            <span>{t('appointment.error.firstNameError')}</span>
                          )}
                          {errors.visitor_fname.type == 'pattern' && (
                            <span>{t('appointment.error.firstNameInvalidError')}</span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.lastName')}
                        {/* <span className="text-danger ms-1">*</span> */}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.lastName ? 'is-invalid' : ''
                          }`}
                        placeholder={t('appointment.placeholder.lastName')}
                        {...register('visitor_lname', {
                          // required: true
                        })}
                      />
                      {/* {errors.lastName && (
                        <small className="text-danger">
                          {errors.lastName.type == 'required' && (
                            <span>{t('appointment.error.lastNameError')}</span>
                          )}
                        </small>
                      )} */}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.email')}
                        
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.visitor_email ? 'is-invalid' : ''
                          }`}
                        placeholder={t('appointment.placeholder.email')}
                        {...register('visitor_email', {

                          pattern: pattern.email
                        })}
                      />
                      {errors.visitor_email && (
                        <small className="text-danger">
                          {errors.visitor_email.type === 'required' && (
                            <span>{t('appointment.error.emailError')}</span>
                          )}
                          {errors.visitor_email.type === 'pattern' && (
                            <span>
                              {t('appointment.error.emailPatternError')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.companyFrom')}
                        {/* <span className="text-danger ms-1">*</span> */}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.visitor_from ? 'is-invalid' : ''
                          }`}
                        placeholder={t('appointment.placeholder.companyFrom')}
                        {...register('visitor_from', {
                          // required: true
                        })}
                      />
                      {/* {errors.companyFrom && (
                        <small className="text-danger">
                          {errors.companyFrom.type == 'required' && (
                            <span>
                              {t('appointment.error.companyFromError')}
                            </span>
                          )}
                        </small>
                      )} */}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.identityType')}
                        {/* <span className="text-danger ms-1">*</span> */}
                      </label>
                      <Controller
                        control={control}
                        // rules={{ required: true }}
                        name="doc_type_id"
                        render={({ field: { value, onChange } }) => (
                          <SearchableDropdown
                            options={documentType}
                            isClearable={false}
                            onChange={onChange}
                            // hasError={errors.doc_type_id}
                            placeholder={t(
                              'appointment.placeholder.identityType'
                            )}
                            value={value}
                            noOptionsMessage={() =>
                              t('appointment.error.dropdownError')
                            }
                          />
                        )}
                      />
                      {/* {errors.doc_type_id && (
                        <small className="text-danger">
                          {errors.doc_type_id.type == 'required' && (
                            <span>
                              {t('appointment.error.identityTypeError')}
                            </span>
                          )}
                        </small>
                      )} */}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.identificationNo')}
                        {/* <span className="text-danger ms-1">*</span> */}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.documentNo ? 'is-invalid' : ''
                          }`}
                        placeholder={t(
                          'appointment.placeholder.identificationNo'
                        )}
                        {...register('doc_id_number', {
                          // required: true
                        })}
                      />
                      {/* {errors.documentNo && (
                        <small className="text-danger">
                          {errors.documentNo.type == 'required' && (
                            <span>
                              {t('appointment.error.identificationNoError')}
                            </span>
                          )}
                        </small>
                      )} */}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.dateOfAppointment')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="date_of_appointment"
                        render={({ field: { value, onChange } }) => (
                          <ReactDatePicker
                            value={value}
                            selected={value}
                            onChange={onChange}
                            hasErrors={errors.date_of_appointment}
                            placeholder={t(
                              'appointment.placeholder.dateOfAppointment'
                            )}
                            minDate={moment().toDate()}
                            dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                          />
                        )}
                      />
                      {errors.date_of_appointment && (
                        <small className="text-danger">
                          {errors.date_of_appointment.type == 'required' && (
                            <span>
                              {t('appointment.error.dateOfAppointmentError')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.startTime')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="start_time"
                        render={({ field: { value, onChange } }) => (
                          <ReactTimePicker
                            value={value}
                            selected={value}
                            onChange={onStartTimeChange}
                            hasErrors={errors.start_time}
                            placeholder={t('appointment.placeholder.startTime')}
                          // minTime={
                          //   value && moment(value).isSame(moment(dateOfAppointment), 'day')
                          //     ? moment().toDate()
                          //     : moment().startOf('day').toDate()
                          // }
                          />
                        )}
                      />
                      {errors.start_time && (
                        <small className="text-danger">
                          {errors.start_time.type == 'required' && (
                            <span>{t('appointment.error.startTimeError')}</span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.endTime')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="endTime"
                        render={({ field: { value, onChange } }) => (
                          <ReactTimePicker
                            value={value}
                            selected={value}
                            onChange={onChange}
                            hasErrors={errors.endTime}
                            placeholder={t('appointment.placeholder.endTime')}
                            minTime={startTime && { hour: moment(startTime).add(15, 'minutes').hour(), minute: moment(startTime).add(15, 'minutes').minutes() }}
                          />
                        )}
                      />
                      {errors.endTime && (
                        <small className="text-danger">
                          {errors.endTime.type == 'required' && (
                            <span>{t('appointment.error.endTimeError')}</span>
                          )}
                        </small>
                      )}
                    </div>
                  </div> */}
                  <div className="col-12 col-md-4">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.purpose')}
                        {/* <span className="text-danger ms-1">*</span> */}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.purpose ? 'is-invalid' : ''
                          }`}
                        placeholder={t('appointment.placeholder.purpose')}
                        {...register('purpose', {
                          // required: true
                        })}
                      />
                      {/* {errors.title && (
                        <small className="text-danger">
                          {errors.title.type == 'required' && (
                            <span>{t('appointment.error.purposeError')}</span>
                          )}
                        </small>
                      )} */}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-3">
                      <label className="form-label">
                        {t('appointment.formLabel.notes')}
                        {/* <span className="text-danger ms-1">*</span> */}
                      </label>
                      <textarea
                        rows="4"
                        className={`form-control ${errors.notes ? 'is-invalid' : ''
                          }`}
                        {...register('notes', {
                          // required: true
                        })}
                      ></textarea>

                      {errors.notes && (
                        <small className="text-danger">
                          {errors.notes.type == 'required' && (
                            <span>{t('appointment.error.notesError')}</span>
                          )}
                        </small>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-12">
                    <div className="mb-3">
                      <label className="form-label ">
                        {t('appointment.formLabel.imageUpload')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <Controller
                        name="guestPhoto"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <SetImagePlaceholderUploder
                            onChange={onChange}
                            value={value}
                            setFile={setValue}
                            setName="guestPhoto"
                            hasError={errors.guestPhoto}
                          />
                        )}
                      />

                      {errors.guestPhoto && (
                        <small className="text-danger">
                          {errors.guestPhoto.type == 'required' && (
                            <span>
                              {t('appointment.error.imageUploadError')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                  </div> */}

                  <div className="text-start col-12">
                    <div className="mb-3 text-end">
                      <button type="submit" className="btn btn-primary me-1">
                        {t('appointment.btnLabel.submit')}
                      </button>
                      <button
                        type="reset"
                        className="btn btn-danger"
                        onClick={onReset}
                      >
                        {t('appointment.btnLabel.reset')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
