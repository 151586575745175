import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import ReactTable from 'components/common/ReactTable';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Swalcolor from 'components/configuration/sweetalert-config';
import { encodeData } from 'components/common/encodeParams';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { useForm, Controller } from 'react-hook-form';
import DownloadIcon from '@mui/icons-material/Download';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import ReactTimePicker from 'components/common/ReactTimePicker';
import { backuplist, savebackup, manualbackuplist, backupdelete } from 'services/adminmoduleService'
import moment from 'moment';
import SearchableDropdown from 'components/common/SearchableDropdown';

export default function BackupManagement() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [backup, setBackup] = useState([{ documentName: "daily", id: 1 }, { documentName: "weekly", id: 2 },])
    const [dayoption, setDayOption] = useState([{ label: "Monday", value: 1 }, { label: "Tuesday", value: 2 }, { label: "Wednesday", value: 3 }, { label: "Thursday", value: 4 }, { label: "Friday", value: 5 }, { label: "Saturday", value: 6 }, { label: "Sunday", value: 7 }])
    const [backupmanagemntdata, setBackupManagementData] = useState([])
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const [openday, setOpenDay] = useState(false)


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        setError,
        watch
    } = useForm();
    // useEffect(() => {
    //     checkModulePermission("Backup Management")
    //     setValue("backupType", backup[0].id)
    // }, [])



    useEffect(async () => {
        checkModulePermission("Backup Management")
        setIsLoading(true)
        try {
            const { data } = await backuplist();
            setBackupManagementData(data.data[0]?.dbbackup);
            setValue("backup_type", data?.data[0]?.setting?.backup_type)
            setValue("time", moment.utc(data?.data[0]?.setting?.time, process.env.REACT_APP_TIME_FORMAT_24).local().toDate().getTime())
            setValue("path", data?.data[0]?.setting?.path)
            setValue("day", dayoption?.find((day) => day?.value === data?.data[0]?.setting?.day))
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, []);

    const selectedVisitor = watch("backup_type");

    useEffect(() => {
        if (selectedVisitor === "weekly") {
            setOpenDay(true)
        } else {
            setOpenDay(false)
        }
    }, [selectedVisitor])

    const onDownloadRow = (id) => {
        const newData = backupmanagemntdata?.find((backpup) => backpup?.id === id)
        const link = document.createElement("a")
        link.setAttribute("href", newData?.to_download_path)
        link.click()
    };
    const onDeleteRow = (id) => {
        Swal.fire({
            title: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationTitle'
            ),
            text: t('departmentMaster.deleteDepartment.sweetAlert.confirmationText'),
            icon: 'warning',
            showCancelButton: Swalcolor.confirmation.showCancelButton,
            confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
            cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
            confirmButtonText: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationBtnLabel'
            )
        }).then(async result => {
            if (result.isConfirmed) {
                try {
                    const { data } = await backupdelete({ id });
                    setBackupManagementData(backupmanagemntdata.filter(data => data.id != id));
                    Swal.fire({
                        icon: 'success',
                        title: t(
                            'departmentMaster.deleteDepartment.sweetAlert.simpleTitle'
                        ),
                        text: data.data.result,
                        confirmButtonColor: Swalcolor.simple.confirmButtonColor
                    });
                } catch (e) {
                    errorMessage(e)
                }
            }
        });
    };

    const columns = [
        {
            Header: t('backupmanagement.backuptable.no'),
            accessor: (row, index) => index + 1
        },
        {
            Header: t('backupmanagement.backuptable.backup'),
            accessor: 'filename',
        },
        {
            Header: t('backupmanagement.backuptable.createdby'),
            accessor: 'created_by'
        },
        {
            Header: t('backupmanagement.backuptable.createdon'),
            accessor: 'created_on',
            Cell: ({ row }) => {
                return moment.utc(row?.original?.created_on).local().format(process.env.REACT_APP_DATE_TIME_FORMAT)
            }
        },
        {
            Header: t('backupmanagement.backuptable.action'),
            Cell: ({ row }) => (
                <div>
                    <span
                        className="btn p-0 me-0"
                        title="Download"
                        onClick={() => onDownloadRow(row.original.id)}
                    >
                        <DownloadIcon />
                        {/* <FontAwesomeIcon icon="fa-solid fa-download" /> */}
                    </span>
                    <span
                        className="btn p-0"
                        title="Delete"
                        onClick={() => onDeleteRow(row.original.id)}
                    >
                        <FontAwesomeIcon icon="trash-alt" />
                    </span>
                </div>
            ),
            disableSortBy: true
        },
    ];
    const onSubmit = async (newData) => {
        console.log("newData=====>", newData)
        setIsLoading(true)
        try {
            newData.time = moment.utc(newData.time).format(process.env.REACT_APP_TIME_FORMAT_24)
            newData.day = newData?.day?.value
            const { data } = await savebackup(newData)
            setValue("backup_type", data?.data[0]?.setting?.backup_type)
            setValue("time", moment.utc(data?.data[0]?.setting?.time, process.env.REACT_APP_TIME_FORMAT_24).local().toDate().getTime())
            setValue("path", data?.data[0]?.setting?.path)
            toast.success(data.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }

    const resetValue = () => {
        reset();
        setValue("day", "")
    }
    const onStartTimeChange = (e) => {
        setValue('time', e);
        clearErrors('time')
    }

    const handleManualBackup = async () => {
        setIsLoading(true);
        try {
            const { data } = await manualbackuplist();
            setBackupManagementData([data?.data[0]?.db_backup, ...backupmanagemntdata])
            const link = document.createElement("a")
            link.setAttribute("href", data?.data[0]?.db_backup?.to_download_path)
            link.click()
            toast.success(data.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false);
    }

    return (
        <>
            <div className="row g-3 mb-3">
                <div className="">
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-xl-8 col-lg-6 col-md-4 heading-text">
                                    <h3 className="heading-color fw-600 mb-0">
                                        {t('backupmanagement.backupmanagementheading')}
                                    </h3>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-8 table-btn" >
                                    <button
                                        className="btn btn-primary btn-sm float-end"
                                        onClick={() => handleManualBackup()}
                                    >
                                        <span className="ms-1 d-sm-inline">
                                            {t('backupmanagement.backupmanagementbtn')}
                                        </span>
                                    </button>
                                </div>

                                <div className="col-12">
                                    <hr />
                                </div>

                                <div style={{ padding: "0rem 0rem 0rem 1rem" }}>
                                    <span style={{ color: "#ffd131" }}>{t('backupmanagement.backuptextmain')}</span><br />
                                    <span style={{ color: "#ffd131" }}>{t('backupmanagement.backuptext')}</span>
                                </div>


                                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                    <div className='row'>
                                        <div className='col-lg-12 mt-4'>
                                            <label className='form-label backupedituserroleheading'>
                                                {t('backupmanagement.backupbasedon')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <div className='form-group mb-3 mb-sm-0'>
                                                {backup.map((docType, index) => (
                                                    <div className="form-check form-check-inline" key={docType.id}>
                                                        <input
                                                            className="form-check-input"
                                                            // checked={index === 0 && true}
                                                            type="radio"
                                                            value={docType.documentName}
                                                            {...register('backup_type', {
                                                                required: true
                                                            })}
                                                        />
                                                        <label className="form-check-label backupedituserroleheading">
                                                            {docType.documentName}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                            {errors.backup_type && (
                                                <small>
                                                    {errors.backup_type.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t(
                                                                'backupmanagement.error'
                                                            )}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <label className="form-label">
                                                {t('backupmanagement.time')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                control={control}
                                                rules={{ required: true }}
                                                name="time"
                                                render={({ field: { value, onChange } }) => (
                                                    <ReactTimePicker
                                                        value={value}
                                                        selected={value}
                                                        onChange={onStartTimeChange}
                                                        hasErrors={errors.time}
                                                        placeholder={t('appointment.placeholder.startTime')}
                                                    />
                                                )}
                                            />
                                            {errors.time && (
                                                <small>
                                                    {errors.time.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('backupmanagement.timeerror')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>

                                        {openday && (
                                            <div className='col-lg-6'>
                                                <label className="form-label">
                                                    {t('backupmanagement.day')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                                <Controller
                                                    control={control}
                                                    rules={{ required: true }}
                                                    name="day"
                                                    render={({ field: { value, onChange } }) => (
                                                        <SearchableDropdown
                                                            value={value}
                                                            onChange={onChange}
                                                            options={dayoption}
                                                            hasError={errors.day}
                                                        // placeholder={t('appointment.placeholder.startTime')}
                                                        />
                                                    )}
                                                />
                                                {errors.day && (
                                                    <small>
                                                        {errors.day.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t(
                                                                    'backupmanagement.dayerror'
                                                                )}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div>
                                        <label className="form-label">
                                            {t('backupmanagement.path')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.path && 'is-invalid'
                                                }`}
                                            type="text"
                                            {...register('path', {
                                                required: true
                                            })}
                                        />
                                        {errors.path && (
                                            <small>
                                                {errors.path.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t(
                                                            'backupmanagement.patherror'
                                                        )}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>



                                    <div className="text-start col-12">
                                        <div className="mt-2 mb-3 text-end">
                                            <button type="submit" className="btn btn-primary me-1">
                                                <CloudDownloadIcon className='me-1' />
                                                {t('backupmanagement.backuptable.savebtn')}
                                            </button>
                                            <button
                                                type="reset"
                                                className="btn btn-danger"
                                                onClick={resetValue}
                                            >
                                                {t('backupmanagement.backuptable.reset')}
                                            </button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <ReactTable
                                columns={columns}
                                data={backupmanagemntdata}
                                pageSizeOption={[5, 10, 15, 20]}
                                headerClass="btn-primary"
                                isSearchBoxVisible
                                ispaginationvisible={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
