import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import ReactTable from 'components/common/ReactTable';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Swalcolor from 'components/configuration/sweetalert-config';
import { encodeData } from 'components/common/encodeParams';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { listsupplier, supplierdelete } from 'services/adminmoduleService'
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import moment from 'moment';

export default function SystemUsers() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [supplierData, setSupplierData] = useState([]);
    const { isLoading, setIsLoading } = useContext(loaderContext)
    useEffect(async () => {
        checkModulePermission("Supplier & Contractors")
        setIsLoading(true)
        try {
            const { data } = await listsupplier();
            setSupplierData(data?.data);
            // console.log(data.data.results);
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, []);

    const onEditRow = (id) => {
        const newData = supplierData?.find((supp) => supp.id == id)
        navigate(`/editsuppliercontractor`, { state: newData });
    };
    const onDeleteRow = (id) => {
        Swal.fire({
            title: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationTitle'
            ),
            text: t('departmentMaster.deleteDepartment.sweetAlert.confirmationText'),
            icon: 'warning',
            showCancelButton: Swalcolor.confirmation.showCancelButton,
            confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
            cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
            confirmButtonText: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationBtnLabel'
            )
        }).then(async result => {
            if (result.isConfirmed) {
                setIsLoading(true)
                try {
                    const { data } = await supplierdelete({ id });
                    console.log(data);
                    setSupplierData(supplierData.filter(data => data.id != id));
                    Swal.fire({
                        icon: 'success',
                        title: t(
                            'departmentMaster.deleteDepartment.sweetAlert.simpleTitle'
                        ),
                        text: data.data.result,
                        confirmButtonColor: Swalcolor.simple.confirmButtonColor
                    });
                } catch (e) {
                    errorMessage(e)
                }
                setIsLoading(false)
            }
        });
    };

    const columns = [

        {
            Header: t('suppliercontractor.suppliercontractortable.no'),
            accessor: (row, index) => index + 1
        },
        {
            Header: t('suppliercontractor.suppliercontractortable.companyname'),
            accessor: 'company_name'
        },
        {
            Header: t('suppliercontractor.suppliercontractortable.companytype'),
            accessor: 'company_type'
        },
        {
            Header: t('suppliercontractor.suppliercontractortable.lpo'),
            accessor: 'lpo_number',
            Cell: ({ row }) => {
                return row.original.lpo_number ? row?.original?.lpo_number : '--'
            }
        },
        {
            Header: t('suppliercontractor.suppliercontractortable.status'),
            accessor: 'status'
        },
        {
            Header: t('suppliercontractor.suppliercontractortable.createdby'),
            accessor: 'created_by'
        },
        {
            Header: t('suppliercontractor.suppliercontractortable.createdon'),
            accessor: 'created_on',
            Cell: ({ row }) => (
                <div>
                    {moment.utc(row?.original?.created_on).local().format(process.env.REACT_APP_DATE_TIME_FORMAT)}
                </div>
            ),
        },
        {
            Header: t('suppliercontractor.suppliercontractortable.action'),
            Cell: ({ row }) => (
                <div>
                    <span
                        className="btn p-0 me-2"
                        title="Edit"
                        onClick={() => onEditRow(row.original.id)}
                    >
                        <FontAwesomeIcon icon="edit" />
                    </span>
                    <span
                        className="btn p-0"
                        title="Delete"
                        onClick={() => onDeleteRow(row.original.id)}
                    >
                        <FontAwesomeIcon icon="trash-alt" />
                    </span>
                </div>
            ),
            disableSortBy: true
        },
        // {
        //     Header: t('accesscontrol.accesscontroltable.action'),
        //     content: data => (
        //         <div>
        //             <span
        //                 className="btn p-0 me-2" style={{ display: 'flex' }}
        //                 title="Edit"
        //                 onClick={() => onEditRow(data)}
        //             >
        //                 <FontAwesomeIcon icon="edit" />
        //                 <p>{t('userroles.userrolestable.permission')}</p>
        //             </span>
        //             <span
        //                 className="btn p-0"
        //                 title="Delete"
        //                 onClick={() => onDeleteRow(data)}
        //             >
        //                 <FontAwesomeIcon icon="trash-alt" />
        //             </span>
        //         </div>
        //     ),
        //     disableSortBy: true
        // }
    ];

    return (
        <>
            <div className="row g-3 mb-3">
                <div className="">
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-xl-8 col-lg-6 col-md-6 heading-text">
                                    <h3 className="heading-color fw-600 mb-0">
                                        {t('suppliercontractor.heading')}
                                    </h3>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 table-btn" >
                                    <button
                                        className="btn btn-primary btn-sm float-end"
                                        onClick={() => {
                                            navigate('/create-supplier-contractor');
                                        }}
                                    >
                                        {/* <FontAwesomeIcon icon="plus" /> */}
                                        <span className="ms-1 d-sm-inline ">
                                            {t('suppliercontractor.createsupplierbtn')}
                                        </span>
                                    </button>
                                </div>
                                {/* <div className="col-4">
                                    <button
                                        className="btn btn-primary btn-sm float-end"
                                        onClick={() => {
                                            navigate('/master/department-master/create-department');
                                        }}
                                    >
                                        <FontAwesomeIcon icon="plus" />
                                        <span className="ms-1 d-sm-inline d-none">
                                            {t('masters.departments.departmentcreatebtn')}
                                        </span>
                                    </button>
                                </div> */}
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className="card-body table-small pt-0">
                            <ReactTable
                                columns={columns}
                                data={supplierData}
                                pageSizeOption={[5, 10, 15, 20]}
                                headerClass="btn-primary"
                                isSearchBoxVisible
                                ispaginationvisible={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
