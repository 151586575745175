import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import ReactTable from 'components/common/ReactTable';
import toastConfig from 'components/configuration/toast-config';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Swalcolor from 'components/configuration/sweetalert-config';
import { encodeData } from 'components/common/encodeParams';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddBlackList from 'components/pages/admin/blacklistvisitor/AddBlackList'
import UploadBlackList from 'components/pages/admin/blacklistvisitor/UploadBlackList'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import OutsideClickHandler from 'react-outside-click-handler';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import { listblack, blackdelete } from 'services/adminmoduleService'
import ViewBlackList from './ViewBlackList';
import moment from 'moment';


export default function ListBlackVisitor() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [listBlackData, setListBlackData] = useState([]);
    const [showDropdownModal, setShowDropdownModal] = useState(false);
    const [showAddBlackListCsvModal, setShowAddBlackListCsvModal] = useState(false);
    const [dropdown, setDropDown] = useState(false);
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const [showReason, setShowReason] = useState(false)
    const [reasonList, setReasonList] = useState([])

    useEffect(async () => {
        checkModulePermission("Blacklist Visitor")
        setIsLoading(true)
        try {
            const { data } = await listblack();
            setListBlackData(data?.data);
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, []);


    const onDeclineClick = (id) => {
        Swal.fire({
            title: t(
                'departmentMaster.deleteDepartment2.sweetAlert.confirmationTitle'
            ),
            text: t('departmentMaster.deleteDepartment2.sweetAlert.confirmationText'),
            icon: 'warning',
            showCancelButton: Swalcolor.confirmation.showCancelButton,
            confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
            cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
            confirmButtonText: t(
                'departmentMaster.deleteDepartment2.sweetAlert.confirmationBtnLabel'
            )
        }).then(async result => {
            if (result.isConfirmed) {
                try {
                    const { data } = await blackdelete({ id });
                    console.log(data);
                    setListBlackData(listBlackData.filter(data => data.id != id));
                    Swal.fire({
                        icon: 'success',
                        title: t(
                            'departmentMaster.deleteDepartment2.sweetAlert.simpleTitle'
                        ),
                        text: data.data.result,
                        confirmButtonColor: Swalcolor.simple.confirmButtonColor
                    });
                } catch (e) {
                    errorMessage(e)
                }
            }
        });
    };

    const whitelist = (id) => {
        const newData = listBlackData?.find((list) => list?.id === id)
        setReasonList(newData?.reason)
        setShowReason(true)
    }

    const columns = [
        {
            Header: t('blacklist.blacklistform.no'),
            accessor: (row, index) => index + 1
        },
        {
            Header: t('blacklist.blacklistform.fullname'),
            accessor: 'name',
            Cell: ({ row }) => {
                return row?.original?.name ? row?.original?.name : '--'
            }
        },
        {
            Header: t('blacklist.blacklistform.type'),
            accessor: 'visitor_type',
            Cell: ({ row }) => {
                return row?.original?.visitor_type ? row?.original?.visitor_type : '--'
            }
        },
        {
            Header: t('blacklist.blacklistform.phoneno'),
            accessor: 'phone',
            Cell: ({ row }) => {
                return row?.original?.phone ? `${row?.original?.country_code}-${row?.original?.phone}` : '--'
            }
        },
        {
            Header: t('blacklist.blacklistform.idno'),
            accessor: 'doc_id_number',
            Cell: ({ row }) => {
                return row?.original?.doc_id_number ? row?.original?.doc_id_number : '--'
            }
        },
        {
            Header: t('blacklist.blacklistform.idtype'),
            accessor: 'doc_type_id_name',
            Cell: ({ row }) => {
                return row?.original?.doc_type_id_name ? row?.original?.doc_type_id_name : '--'
            }
        },
        {
            Header: t('blacklist.blacklistform.blacklistby'),
            accessor: 'block_by'
        },
        {
            Header: t('blacklist.blacklistform.blacklisttype'),
            accessor: 'blacklist_type',
            Cell: ({ row }) => {
                return row.original.blacklist_type.charAt(0).toUpperCase() + row.original.blacklist_type.slice(1);
            }
        },
        {
            Header: t('blacklist.blacklistform.blacklistdate'),
            accessor: 'blacklist_date',
            Cell: ({ row }) => (
                row?.original?.blacklist_date ? moment.utc(row?.original?.blacklist_date).local().format(process.env.REACT_APP_DISPLAY_DATE_FORMAT) : '--'
            ),
        },

        {
            Header: t('blacklist.blacklistform.blackliston'),
            accessor: 'blocked_on',
            Cell: ({ row }) => (
                <div>
                    {moment.utc(row?.original?.blocked_on).local().format(process.env.REACT_APP_DATE_TIME_FORMAT)}
                </div>
            ),
        },

        {
            Header: t('blacklist.blacklistform.action'),
            className: 'text-end',
            Cell: ({ row }) => {
                const [isOpen, setIsOpen] = useState(false);
                return (
                    <div>
                        <span
                            className="btn p-0 me-2"
                            // title="Edit"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <FontAwesomeIcon icon="ellipsis-h" />
                        </span>
                        {
                            isOpen &&
                            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                                <div className='card position-absolute p-2' style={{ right: "3%", minWidth: "15%", boxShadow: "0 0 6px 0 rgba(0,0,0,0.45) inset" }}>
                                    <div className='pt-2 px-3' style={{ cursor: "pointer" }} onClick={() => whitelist(row.original.id)}>
                                        <span>{t('blackListVisitor.blacklistdropdown.viewblacklist')}</span>
                                    </div>
                                    <div className='py-2 px-3' style={{ cursor: "pointer" }} onClick={() => onDeclineClick(row.original.id)}>
                                        <span>{t('blackListVisitor.blacklistdropdown.whitelist')}</span>
                                    </div>
                                </div >
                            </OutsideClickHandler>
                        }
                    </div >
                )
            },
            // content: data => (
            //     <div>
            //         <button
            //             className="btn p-0 me-2"
            //             title="Edit"
            //             onClick={() => onEditRow()}
            //             id="dropdown-basic"
            //         >
            //             <MoreHorizIcon />
            //             <FontAwesomeIcon icon="edit" />
            //         </button>

            //     </div>

            //     <Dropdown>
            //         <Dropdown.Toggle variant='secondary' id="dropdown-basic" className='btn p-0 me-2' >
            //             <MoreHorizIcon />
            //         </Dropdown.Toggle>

            //         <Dropdown.Menu>
            //             <Dropdown.Item href="#/action-1">{t('blackListVisitor.blacklistdropdown.viewblacklist')}</Dropdown.Item>
            //             <Dropdown.Item href="#/action-2">{t('blackListVisitor.blacklistdropdown.whitelist')}</Dropdown.Item>
            //         </Dropdown.Menu>
            //     </Dropdown>

            // ),
            disableSortBy: true
        },

    ];

    return (
        <>
            <div className="row g-3 mb-3">
                <div className="">
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-xl-7 col-lg-6 col-md-4 heading-text">
                                    <h3 className="heading-color fw-600 mb-0">
                                        {t('blacklist.blacklistheading')}
                                    </h3>
                                </div>
                                <div className="col-xl-5 col-lg-6 col-md-8 table-btn" >
                                    <button
                                        className="btn mx-1 btn-primary btn-sm float-end"
                                        onClick={() => setShowAddBlackListCsvModal(true)}
                                    >

                                        {/* <FontAwesomeIcon icon="plus" /> */}
                                        <span className="ms-1 d-sm-inline ">
                                            {t('blacklist.uploadblacklistheading')}
                                        </span>
                                    </button>
                                    <UploadBlackList
                                        show={showAddBlackListCsvModal}
                                        handleClose={() => setShowAddBlackListCsvModal(false)}
                                        setblacklist={setListBlackData}
                                        blacklist={listBlackData}
                                    />

                                    <button
                                        className="btn btn-primary btn-sm float-end"
                                        onClick={() => setShowDropdownModal(true)}
                                    >
                                        {/* <FontAwesomeIcon icon="plus" /> */}
                                        <span className="ms-1 d-sm-inline ">
                                            {t('blacklist.addblacklistheading')}
                                        </span>
                                    </button>
                                    <AddBlackList
                                        show={showDropdownModal}
                                        handleClose={() => setShowDropdownModal(false)}
                                        setblacklist={setListBlackData}
                                        blacklist={listBlackData}
                                    />
                                </div>

                                <ViewBlackList
                                    show={showReason}
                                    handleClose={() => setShowReason(false)}
                                    setblacklist={setListBlackData}
                                    blacklist={listBlackData}
                                    reason={reasonList}
                                />
                                {/* <div className="col-4">
                                    <button
                                        className="btn btn-primary btn-sm float-end"
                                        onClick={() => {
                                            navigate('/master/department-master/create-department');
                                        }}
                                    >
                                        <FontAwesomeIcon icon="plus" />
                                        <span className="ms-1 d-sm-inline d-none">
                                            {t('masters.departments.departmentcreatebtn')}
                                        </span>
                                    </button>
                                </div> */}
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className="card-body table-small pt-0">
                            <ReactTable
                                columns={columns}
                                data={listBlackData}
                                pageSizeOption={[5, 10, 15, 20]}
                                headerClass="btn-primary"
                                isSearchBoxVisible
                                ispaginationvisible={true}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* {
                dropdown && (
                    <div className="mb-2">
                        {['start'].map(
                            (direction) => (
                                <DropdownButton
                                    // as={ButtonGroup}
                                    key={direction}
                                    id={`dropdown-button-drop-${direction}`}
                                    drop={direction}
                                    variant="secondary"
                                    title={` Drop ${direction} `}
                                >
                                    <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                                    <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                                    <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                                </DropdownButton>
                            ),
                        )}
                    </div>
                )
            } */}
        </>
    );
}
