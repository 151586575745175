import React, { useEffect, useState, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';
import ReactTable from 'components/common/ReactTable';
import { useForm, Controller } from 'react-hook-form';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactDatePicker from 'components/common/ReactDatePicker';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Dropdown from 'react-bootstrap/Dropdown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import OutsideClickHandler from 'react-outside-click-handler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NestedSosTable from './NestedSosTable';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import '../report.css'
import { sosreportlist } from 'services/reportsService'
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { loaderContext } from 'context/Context';
import moment from 'moment';
import { getListOfregister } from 'services/adminmoduleService'




function SOSReport() {

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [sosdata, setSosData] = useState([]);
    // const [dateRange, setDateRange] = useState([null, null]);
    // const [startDate, endDate] = dateRange;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [sentbyOption, setSentByOption] = useState([]);
    const [isOpen, setIsOpen] = useState({ id: null, open: false });



    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        watch
    } = useForm();

    useEffect(async () => {
        checkModulePermission("SOS Report")
        setIsLoading(true)
        try {
            const { data } = await sosreportlist({})
            const { data: register } = await getListOfregister()
            setSosData(data?.data)
            setSentByOption(register?.data?.map((register) => ({ label: register?.name, value: register?.id })))
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }, [])

    const columns = [

        {
            Header: t('sosreport.sosreporttable.datesent'),
            accessor: 'sent_date',
            Cell: ({ row }) => {
                return moment.utc(row?.original?.sent_date).local().format(process.env.REACT_APP_FILTER_DATE)
            }
        },
        {
            Header: t('sosreport.sosreporttable.noofaudience'),
            accessor: 'no_of_audience',
            Cell: ({ row }) => {
                return row?.original?.no_of_audience ? row?.original?.no_of_audience : "--"
            }
        },
        {
            Header: t('sosreport.sosreporttable.sentby'),
            accessor: 'sent_by',
            Cell: ({ row }) => {
                return row?.original?.sent_by ? row?.original?.sent_by : "--"
            }
        },
        {
            Header: t('sosreport.sosreporttable.sosmessage'),
            accessor: 'sos_message',
            Cell: ({ row }) => {
                return row?.original?.sos_message ? row?.original?.sos_message : "--"
            }
        },
        {
            Header: t('sosreport.sosreporttable.action'),
            className: 'text-end',
            isExpand: true,
            Cell: ({ row }) => {

                return (
                    <div>
                        <span
                            className="btn p-0 me-2"
                            onClick={() => {
                                setIsOpen({ id: row.original.id, open: true });
                            }}
                        >
                            <FontAwesomeIcon icon="ellipsis-h" />
                        </span>
                        {
                            (isOpen.open && row.original.id == isOpen.id) &&
                            <OutsideClickHandler onOutsideClick={() => setIsOpen({ id: row.original.id, open: false })}>
                                <div className='card position-absolute p-2' style={{ right: "6%", zIndex: "999", minWidth: "15%", boxShadow: "0 0 6px 0 rgba(0,0,0,0.45) inset" }} >
                                    <div className='px-3' style={{ cursor: "pointer", }}  {...row.getToggleRowExpandedProps()}>
                                        <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{t('sosreport.viewrecipient')}</span>
                                    </div>
                                </div >
                            </OutsideClickHandler>
                        }
                    </div>
                );
            },
            // Cell: ({ row }) => {
            //     const [isOpen, setIsOpen] = useState(false);

            //     return (<div>
            //         <span
            //             className="btn p-0 me-2"
            //             // title="Edit"
            //             onClick={() => {
            //                 setIsOpen(!isOpen);
            //                 setOpenRowId(row.original.id);
            //             }}
            //         // onClick={() => setIsOpen(!isOpen)}
            //         >
            //             <FontAwesomeIcon icon="ellipsis-h" />
            //         </span>
            //         {
            //             isOpen &&
            //             <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
            //                 <div className='card position-absolute p-2' style={{ right: "6%", zIndex: "999", minWidth: "15%", boxShadow: "0 0 6px 0 rgba(0,0,0,0.45) inset" }} >
            //                     <div className='px-3' style={{ cursor: "pointer", }}  {...row.getToggleRowExpandedProps()}>
            //                         <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{t('sosreport.viewrecipient')}</span>
            //                     </div>
            //                 </div >
            //             </OutsideClickHandler>
            //         }
            //     </div >)
            // },
            disableSortBy: true
        },
    ];


    const onSubmit = async (sosdata) => {
        console.log(sosdata);
        setIsLoading(true)
        if (sosdata?.sent_by || (startDate && endDate)) {
            try {
                sosdata.sent_by = sosdata.sent_by?.value
                if (startDate && endDate) {
                    sosdata.date_range = `${moment(startDate).format(process.env.REACT_APP_FILTER_DATE)}-${moment(endDate).format(process.env.REACT_APP_FILTER_DATE)}`;
                }

                const { data } = await sosreportlist(sosdata)
                setSosData(data?.data)
            } catch (e) {
                if (e.response.data) {
                    if (Array.isArray(e.response.data.message)) {
                        e.response.data.message.map(errors => toast.error(errors, toastConfig))
                    } else {
                        toast.error(e.response.data.message, toastConfig)
                    }
                } else {
                    toast.error(e.message, toastConfig)
                }
            }
        }
        setIsLoading(false)
    };


    // const downloadPDF = () => {
    //     const selectedRowData = sosdata.find(row => row.id === isOpen.id);
    //     if (selectedRowData) {
    //         const docDefinition = {
    //             pageOrientation: 'landscape',
    //             pageSize: { width: 700, height: 600 },
    //             content: [
    //                 { text: 'User Data', style: 'header' },
    //                 { text: '\n' },
    //                 { text: 'sos table', style: 'tableHeading' },
    //                 {
    //                     table: {
    //                         headerRows: 1,
    //                         widths: Array.from({ length: columns.length }, () => 'auto'),
    //                         body: [
    //                             columns.map((column) => column.Header),
    //                             ...sosdata.map((row) => columns.map((column) => {
    //                                 return row[column.accessor] !== undefined ? row[column.accessor] : '';
    //                             })),
    //                         ],
    //                     },
    //                 },
    //                 { text: '\n' },
    //                 { text: 'recipients_list', style: 'tableHeading' },

    //                 {
    //                     table: {
    //                         headerRows: 1,
    //                         widths: Array.from({ length: subColumns.length }, () => 'auto'),
    //                         body: [
    //                             subColumns.map((column) => column.Header),
    //                             ...selectedRowData.recipients_list?.map((row) => subColumns.map((column) => {
    //                                 if (column.accessor === 'time_delivered') {
    //                                     return row[column.accessor] && row[column.accessor] !== "" ? moment.utc(row[column.accessor]).local().format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : '--';
    //                                 } else if (column.accessor === 'phone_number') {
    //                                     const phoneNumber = row['phone_number'];
    //                                     const countryCode = row['country_code'];
    //                                     return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
    //                                 } else if (column.accessor === 'status') {
    //                                     return row[column.accessor] === 1 ? 'Delivered' :
    //                                         row[column.accessor] === 2 ? 'Queued' : '';
    //                                 } else {
    //                                     return row[column.accessor] !== undefined ? row[column.accessor] : '';
    //                                 }

    //                             })),
    //                         ],
    //                     },
    //                 },
    //             ],
    //             defaultStyle: {
    //                 actionHeader: false,
    //             },
    //             styles: {
    //                 header: {
    //                     fontSize: 18,
    //                     bold: true,
    //                 },
    //             },
    //         };

    //         const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    //         pdfDocGenerator.download('sos_data.pdf');
    //     }
    //     else {
    //         const docDefinition = {
    //             pageOrientation: 'landscape',
    //             pageSize: { width: 700, height: 600 },
    //             content: [
    //                 { text: 'User Data', style: 'header' },
    //                 { text: '\n' },
    //                 { text: 'sos table', style: 'tableHeading' },
    //                 {
    //                     table: {
    //                         headerRows: 1,
    //                         widths: Array.from({ length: columns.length }, () => 'auto'),
    //                         body: [
    //                             columns.map((column) => column.Header),
    //                             ...sosdata.map((row) => columns.map((column) => {
    //                                 return row[column.accessor] !== undefined ? row[column.accessor] : '';
    //                             })),
    //                         ],
    //                     },
    //                 },
    //                 { text: '\n' },
    //             ],
    //             defaultStyle: {
    //                 actionHeader: false,
    //             },
    //             styles: {
    //                 header: {
    //                     fontSize: 18,
    //                     bold: true,
    //                 },
    //             },
    //         };

    //         const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    //         pdfDocGenerator.download('sos_data.pdf');
    //     }
    // };

    const downloadPDF = () => {
        const docDefinition = {
            pageOrientation: 'landscape',
            pageSize: { width: 700, height: 600 },
            content: [
                { text: 'SOS Data', style: 'header' },
                { text: '\n' },
            ],
            defaultStyle: {
                actionHeader: false,
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                },
                tableHeading: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 10],
                },
            },
        };

        sosdata.forEach((row, index) => {
            const mainRowContent = [
                { text: `SOS - ${index + 1}`, style: 'tableHeading' },
                {
                    table: {
                        headerRows: 1,
                        widths: Array.from({ length: columns.length }, () => 'auto'),
                        body: [
                            columns.map((column) => column.Header),
                            columns.map((column) => {
                                return row[column.accessor] !== undefined ? row[column.accessor] : '';
                            }),
                        ],
                    },
                },
                { text: '\n' },
                { text: 'Recipients Table', style: 'tableHeading' },
                {
                    table: {
                        headerRows: 1,
                        widths: Array.from({ length: subColumns.length }, () => 'auto'),
                        body: [
                            subColumns.map((column) => column.Header),
                            ...(row.recipients_list || []).map((nestedRow) => subColumns.map((column) => {
                                if (column.accessor === 'time_delivered') {
                                    return nestedRow[column.accessor] && nestedRow[column.accessor] !== "" ? moment.utc(nestedRow[column.accessor]).local().format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : '--';
                                } else if (column.accessor === 'phone_number') {
                                    const phoneNumber = nestedRow['phone_number'];
                                    const countryCode = nestedRow['country_code'];
                                    return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
                                } else if (column.accessor === 'status') {
                                    return nestedRow[column.accessor] === 1 ? 'Delivered' :
                                        nestedRow[column.accessor] === 2 ? 'Queued' : '';
                                } else {
                                    return nestedRow[column.accessor] !== undefined ? nestedRow[column.accessor] : '';
                                }
                            })),
                        ],
                    },
                },
                { text: '\n' },
                { canvas: [{ type: 'line', x1: -20, y1: 0, x2: 650, y2: 0, lineWidth: 1 }] }
            ];
            docDefinition.content.push(...mainRowContent);
        });

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.download('sos_data.pdf');
    };

    console.log("sosdata", sosdata)



    const downloadCSV = () => {
        let csvMainData = [
            columns.map((column) => column.Header)
        ];

        sosdata.forEach((row) => {
            const rowData = columns.map((column) => {
                if (column.accessor === 'start_time') {
                    return row[column.accessor] && row[column.accessor] !== "" ? moment.utc(row[column.accessor], "HH:mm:ss").local().format(process.env.REACT_APP_TIME_FORMAT_24) : '--';
                } else {
                    return row[column.accessor] !== undefined ? row[column.accessor] : '';
                }
            });

            csvMainData.push(rowData);

            const nestedRows = [];
            if (row.recipients_list && row.recipients_list.length > 0) {
                const nestedHeader = subColumns.map((subColumn) => {
                    if (subColumn.Header != t('sosreport.sosnestedtable.no')) return subColumn.Header;
                });
                csvMainData.push(nestedHeader);
                row.recipients_list.forEach((recipient) => {
                    const nestedRowData = subColumns.map((subColumn) => {
                        if (subColumn.accessor === 'time_delivered') {
                            return recipient[subColumn.accessor] && recipient[subColumn.accessor] !== "" ? moment.utc(recipient[subColumn.accessor]).local().format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : '--';
                        } else if (subColumn.accessor === 'phone_number') {
                            const phoneNumber = recipient['phone_number'];
                            const countryCode = recipient['country_code'];
                            return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
                        } else if (subColumn.accessor === 'status') {
                            return recipient[subColumn.accessor] === 1 ? 'Delivered' : recipient[subColumn.accessor] === 2 ? 'Queued' : '';
                        } else {
                            return recipient[subColumn.accessor] !== undefined ? recipient[subColumn.accessor] : '';
                        }
                    });
                    nestedRows.push(nestedRowData);
                });
            }

            csvMainData.push(...nestedRows);


            csvMainData.push([]);
        });

        const csvLines = csvMainData.map((row) => row.join(',')).join('\n');

        const blob = new Blob([csvLines], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'sos_data.csv');
    };

    const subColumns = [

        {
            Header: t('sosreport.sosnestedtable.no'),
            accessor: (row, index) => index + 1
        },
        {
            Header: t('sosreport.sosnestedtable.name'),
            accessor: 'name',
            Cell: ({ row }) => {
                return row?.original?.name ? row?.original?.name : "--"
            }
        },
        {
            Header: t('sosreport.sosnestedtable.idno'),
            accessor: 'document_id_number',
            Cell: ({ row }) => {
                return row?.original?.document_id_number ? row?.original?.document_id_number : "--"
            }
        },
        {
            Header: t('sosreport.sosnestedtable.mobileno'),
            accessor: 'phone_number',
            Cell: ({ row }) => {
                return row?.original?.phone_number ? `${row?.original?.country_code}-${row?.original?.phone_number}` : '--'
            }
        },
        {
            Header: t('sosreport.sosnestedtable.emailaddress'),
            accessor: 'email',
            Cell: ({ row }) => {
                return row?.original?.email ? row?.original?.email : "--"
            }
        },
        {
            Header: t('sosreport.sosnestedtable.timedelivered'),
            accessor: 'time_delivered',
            Cell: ({ row }) => {
                return row.original.time_delivered ? moment.utc(row?.original?.time_delivered).local().format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : '--'
            }
        },
        {
            Header: t('sosreport.sosnestedtable.status'),
            accessor: 'status',
            className: 'text-end',
            Cell: ({ row }) => (
                <>
                    {row.original.status === 1 ? (
                        <button className='text-center' style={{ backgroundColor: "#81C784", borderRadius: "3px", color: "#2E7D32", border: '1px solid #81C784', fontWeight: "bold", margin: "0rem 0rem" }}> {t('sosreport.sosnestedtable.delivered')}</button>
                    ) : <button
                        style={{
                            backgroundColor: "#E0E0E0",
                            color: "#424242",
                            border: "1px solid #E0E0E0",
                            borderRadius: "3px"
                        }}
                    >
                        {t('sosreport.sosnestedtable.queued')}
                    </button>}
                </>
            ),
        },

    ];

    const renderRowSubComponent = React.useCallback(({ row }) => {
        const newGuestData = row.original?.recipients_list
        return (
            <div className="bg-img card nested-table-body overflow-hidden" >
                <ReactTable
                    columns={subColumns}
                    data={newGuestData ? newGuestData : []}
                    headerClass="btn-primary"
                    innerTableSearch="innertable"
                    hedingName="nestedtable.tableheading"
                    showHeader={false}
                    ispaginationvisible={true}
                />
            </div>
        )
    }, [])

    return (
        <div className="row g-3 mb-3">
            {/* <div className=""> */}
            <div className="card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-9 data-table" style={{ height: "fit-content" }}>
                <div className="card-header">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-4 heading-text">
                            <h3 className="heading-color fw-600 mb-0">
                                {t('sosreport.heading')}
                            </h3>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-8 table-btn" >
                            <button
                                className="btn btn-primary btn-sm float-end"
                                onClick={() => downloadPDF()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline">
                                    {t('sosreport.exportpdfbtn')}
                                </span>
                            </button>

                            <button
                                className="btn mx-1 btn-primary btn-sm float-end"
                                onClick={() => downloadCSV()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline">
                                    {t('sosreport.exportcsvbtn')}
                                </span>
                            </button>
                        </div>

                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <ReactTable
                        columns={columns}
                        data={sosdata}
                        pageSizeOption={[5, 10, 15, 20]}
                        headerClass="btn-primary"
                        isSearchBoxVisible
                        RenderRowSubComponent={
                            renderRowSubComponent
                        }
                        ispaginationvisible={true}
                    />
                </div>
            </div>


            <div className='card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-2 filter-table' style={{ padding: "0rem .8rem 0rem .8rem", height: "fit-content" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row' style={{ backgroundColor: "rgb(247, 247, 247)", paddingTop: ".8rem", height: "3rem", color: "black", marginBottom: '1rem' }}>
                        <span>{t('sosreport.filterheading')}</span>
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('sosreport.filterform.sentby')}
                        </label>
                        <Controller
                            name="sent_by"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={sentbyOption}
                                    hasError={errors.sent_by}
                                    placeholder={t('sosreport.placeholder.sentby')}
                                />
                            )}
                        />
                        {/* {errors.sent_by && (
                            <small>
                                {errors.sent_by.type === 'required' && (
                                    <span className="text-danger">
                                        {t('sosreport.error.sentbyerror')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('sosreport.filterform.daterange')}
                        </label>
                        <Controller
                            name="date_range"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <>
                                    {/* <ReactDatePicker
                                    value={value}
                                    // onChange={onChange}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    placeholder={t(
                                        'sosreport.placeholder.date'
                                    )}
                                    hasErrors={errors.date_range}
                                    dateFormat="dd-MM-yyyy"
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange={true}
                                // isClearable={true}
                                /> */}

                                    <ReactDatePicker
                                        selected={startDate}
                                        value={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        placeholder={t('summaryreport.placeholder.startdate')}
                                        dateFormat="dd/MM/yyyy"
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxDate={endDate || undefined}
                                    />
                                    {/* <span className="mx-2">-</span> */}
                                    <br />
                                    <ReactDatePicker
                                        selected={endDate}
                                        value={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        placeholder={t('summaryreport.placeholder.enddate')}
                                        dateFormat="dd/MM/yyyy"
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate || undefined}
                                    />
                                </>
                            )}
                        />
                        {/* {errors.date_range && (
                            <small>
                                {errors.date_range.type === 'required' && (
                                    <span className="text-danger">
                                        {t('sosreport.error.dateerror')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>

                    <div className="text-start col-12">
                        <div className="mb-3 mt-4 text-start">
                            <button type="submit" className="col-lg-12 btn btn-primary me-1">
                                {t('sosreport.filterform.filterbtn')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {/* </div> */}
        </div>
    )
}

export default SOSReport