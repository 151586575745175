import React, { useContext, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import routes from 'routes/routes';
import { capitalize } from 'helpers/utils';
import bgNavbar from 'assets/img/generic/bg-navbar.png';
import 'assets/css/navbar.css';
import { useTranslation } from 'react-i18next';
import { externalevent } from 'routes/routes'
import NavbarVertical from './NavbarVertical';
import "./externalStyle.css"


const NavbarExternal = () => {
    const { t } = useTranslation();
    const {
        config: {
            navbarPosition,
            navbarStyle,
            isNavbarVerticalCollapsed,
            showBurgerMenu,

        },
        setConfig
    } = useContext(AppContext);

    const HTMLClassList = document.getElementsByTagName('html')[0].classList;



    // useEffect(() => {
    //   if (isNavbarVerticalCollapsed) {
    //     HTMLClassList.add('navbar-vertical-collapsed');
    //   } else {
    //     HTMLClassList.remove('navbar-vertical-collapsed');
    //   }
    //   return () => {
    //     HTMLClassList.remove('navbar-vertical-collapsed-hover');
    //   };
    // }, [isNavbarVerticalCollapsed, HTMLClassList]);

    // //Control mouseEnter event
    // let time = null;
    // const handleMouseEnter = () => {
    //   if (isNavbarVerticalCollapsed) {
    //     time = setTimeout(() => {
    //       HTMLClassList.add('navbar-vertical-collapsed-hover');
    //     }, 100);
    //   }
    // };
    // const handleMouseLeave = () => {
    //   clearTimeout(time);
    //   HTMLClassList.remove('navbar-vertical-collapsed-hover');
    // };

    const NavbarLabel = ({ label }) => (
        <Nav.Item as="li" >
            <Row className=" navbar-vertical-label-wrapper" >
                <Col xs="auto" className="navbar-vertical-label navbar-vertical-label" style={{ color: "white", fontSize: ".8rem", paddingLeft: "1rem", paddingTop: "1rem" }}>
                    {label}
                    <hr style={{ color: "white", width: "10.6rem", marginLeft: "-2rem", marginTop: "3rem" }} />
                </Col>
                {/* <Col className="ps-0">
                    <hr className="mb-0 navbar-vertical-divider"></hr>
                </Col> */}
            </Row>
        </Nav.Item>
    );

    const handleBurgerMenu = () => {
        navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
        (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
            setConfig('showBurgerMenu', !showBurgerMenu);
    };

    return (
        <Navbar
            expand={navbarBreakPoint}
            className={classNames('navbar-vertical', {
                [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
            })}
            variant="light"
            style={{ zIndex: "1050" }}
        >
            <Flex alignItems="center">
                <ToggleButton />
                <Logo at="navbar-vertical" width={40} />
            </Flex>



            <Navbar.Collapse
                in={showBurgerMenu}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
                style={{
                    backgroundImage:
                        navbarStyle === 'vibrant'
                            ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
                            : 'none'
                }}
            >
                {/* <button
                    className="navbar-toggler-humburger-icon btn btn-primary d-flex flex-center"
                    onClick={handleBurgerMenu}
                    id="burgerMenu"
                    style={{ marginLeft: "2.5rem", marginTop: "1rem" }}
                >
                    <span className="navbar-toggle-icon">
                        <span className="toggle-line" />
                    </span>
                </button> */}
                <div className="navbar-vertical-content scrollbar external-navbar" style={{ width: "10rem", borderRadius: "0.5rem" }}>
                    <Nav className="flex-column" as="ul" >
                        <Fragment key={externalevent.label} >
                            {!externalevent.labelDisable && (
                                <NavbarLabel label={capitalize(t(externalevent.label))} />
                            )}
                            <NavbarVerticalMenu routes={externalevent.children} saperator={<hr style={{ borderColor: "var(--white)", borderWidth: "5px", width: "100%" }} />} />
                        </Fragment>
                    </Nav>
                </div>


            </Navbar.Collapse>
        </Navbar>
    );
};

NavbarExternal.propTypes = {
    label: PropTypes.string
};

export default NavbarExternal;
