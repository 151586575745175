import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';



export default function ViewBlackList({ show, handleClose, blacklist, reason }) {
    const { t } = useTranslation();
    const [visitorData, setVisitorData] = useState([]);
    const [newData, setNewData] = useState()


    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
    } = useForm();

    // useEffect(async () => {
    //     if (show == true) {
    //         setIsLoading(true)
    //         try {
    //             const { data } = await blacksearchlist();

    //             // setVisitorData(
    //             //     data?.data?.map(visitorList => ({
    //             //         visitor_type: `${visitorList.visitor_type}`,
    //             //         visitor_id: `${visitorList.value}`,
    //             //         value: `${visitorList.name} ${visitorList.doc_id_number}  ${visitorList.phone} ${visitorList.email}`,
    //             //         label: (
    //             //             <div className="d-flex flex-column pe-3">
    //             //                 <p className="p-0 m-0">{`Name:-  ${visitorList.name}`}</p>
    //             //                 <p className="p-0 m-0">{`Doc-id-number:-  ${visitorList.doc_id_number}`}</p>
    //             //                 <p className="p-0 m-0">{`Email:-  ${visitorList.email}`}</p>
    //             //                 <p className="p-0 m-0">{`Phone:-  ${visitorList.phone}`}</p>
    //             //             </div>

    //             //         ),
    //             //     })) || []
    //             // );


    //         } catch (e) {
    //             errorMessage(e)
    //         }
    //         setIsLoading(false)
    //     }
    // }, [show]);




    console.log("blacklist=====", blacklist)
    console.log("newData=====", newData)

    // const onSubmit = async (blacklistData) => {
    //     try {
    //         blacklistData.visitor_type = blacklistData?.search?.visitor_type;
    //         blacklistData.visitor_id = blacklistData?.search?.visitor_id;
    //         const { data } = await blackadd(blacklistData);
    //         const updatedData = data?.data[0]?.blacklist_visitor
    //         setblacklist([updatedData, ...blacklist])
    //         toast.success(data?.message, toastConfig);
    //         setValue('search', '');
    //         reset();
    //         handleClose();
    //     } catch (e) {
    //         if (e.response.data) {
    //             if (Array.isArray(e.response.data.message)) {
    //                 e.response.data.message.map(errors => toast.error(errors, toastConfig))
    //             } else {
    //                 toast.error(e.response.data.message, toastConfig)
    //             }
    //         } else {
    //             toast.error(e.message, toastConfig)
    //         }
    //     }
    // };
    const onClose = () => {
        setValue('search', '');
        reset();
        handleClose();
    };

    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>{t('blackListVisitor.modal.tittle.viewBlacklistVisitor')}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <form autoComplete="off">
                        <div className="p-3">
                            {/* <Controller
                                control={control}
                                rules={{ required: true }}
                                name="search"
                                render={({ field: { value, onChange } }) => (
                                    <SearchableDropdown
                                        onChange={onChange}
                                        searchLabel={false}
                                        searchValue={true}
                                        value={value}
                                        options={visitorData}
                                        hasError={errors.search}
                                        placeholder={
                                            <div>
                                               
                                                <span>
                                                    {t('blackListVisitor.modal.placeholder.search')}
                                                </span>
                                            </div>
                                        }

                                    />
                                )}
                            />
                            {errors.search && (
                                <small className="text-danger">
                                    {errors.search.type == 'required' && (
                                        <span>{t('blackListVisitor.modal.error.data')}</span>
                                    )}
                                </small>
                            )} */}
                            <div className="mb-3">
                                <textarea
                                    className={`form-control mt-3 ${errors.reason && 'is-invalid'
                                        }`}
                                    type="textarea"
                                    rows="8"
                                    cols="50"
                                    value={reason}
                                    placeholder={t('blackListVisitor.modal.placeholder.reason')}
                                    {...register('reason', { required: true })}
                                    disabled
                                ></textarea>
                                {errors.reason && (
                                    <small className="text-danger">
                                        {errors.reason.type == 'required' && (
                                            <span>
                                                {t('blackListVisitor.modal.error.reasonError')}
                                            </span>
                                        )}
                                    </small>
                                )}
                            </div>
                        </div>
                        <hr className="mt-1" />
                        {/* <div className="d-flex justify-content-end m-3">
                            <button
                                type="button"
                                className="btn btn-secondary me-1"
                                onClick={onClose}
                            >
                                {t('blackListVisitor.modal.btnLabel.close')}
                            </button>
                            <button className="btn btn-primary">
                                {t('blackListVisitor.modal.btnLabel.addBlackList')}
                            </button>
                        </div> */}
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}
