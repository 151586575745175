import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import formPattern from 'components/configuration/form-pattern';
import { whatsappgateway } from 'services/applicationSettingService'
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';

function WhatsappSettings() {
    const { t } = useTranslation();
    const [whatsappData, setWhatsappData] = useState()
    const navigate = useNavigate();
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        formState: { errors }
    } = useForm();

    useEffect(async () => {
        checkModulePermission("WhatsApp Settings")
        setIsLoading(true)
        try {
            const { data } = await whatsappgateway({ is_update: 0 })
            setWhatsappData(data?.data[0])
            reset(data?.data[0])
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, [])

    const onSubmit = async formData => {
        setIsLoading(true)
        try {
            const { data } = await whatsappgateway({ is_update: 1, ...formData })
            toast.success(data?.message, toastConfig);
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    };
    const onReset = () => {
        setValue("api_key", "")
        setValue("url", "")
        setValue("from_mobile", "")
        clearErrors()
    };
    return (
        <>
            <div className="mb-3 row g-3">
                <div className="card overflow-hidden mb-3">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="heading-color fw-600">
                                    {t('whatsAppSettings.heading.whatsAppSettings')}
                                </h3>
                            </div>
                            <div className="col-12 mb-3">
                                <hr />
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className='row'>
                                    <div className="form-group col-md-3 mb-3">
                                        <label className="form-label">
                                            {t('whatsAppSettings.label.senderid')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.api_key ? 'is-invalid' : ''
                                                }`}
                                            type="text"
                                            placeholder={t('whatsAppSettings.placeholder.senderid')}
                                            {...register('api_key', {
                                                required: true
                                            })}
                                        />
                                        {errors.api_key && (
                                            <small className="text-danger">
                                                {errors.api_key.type === 'required' && (
                                                    <span>{t('whatsAppSettings.error.senderError')}</span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-3 mb-3">
                                        <label className="form-label">
                                            {t('whatsAppSettings.label.url')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.url ? 'is-invalid' : ''
                                                }`}
                                            type="text"
                                            placeholder={t('whatsAppSettings.placeholder.url')}
                                            {...register('url', {
                                                required: true
                                            })}
                                        />
                                        {errors.url && (
                                            <small className="text-danger">
                                                {errors.url.type === 'required' && (
                                                    <span>{t('whatsAppSettings.error.urlError')}</span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-3 mb-3">
                                        <label className="form-label">
                                            {t('whatsAppSettings.label.mobileNo')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.from_mobile && 'is-invalid'
                                                }`}
                                            placeholder={t('whatsAppSettings.placeholder.mobileNo')}
                                            {...register('from_mobile', { required: true, pattern: formPattern.phoneNumber })}
                                        />
                                        {errors.from_mobile && (
                                            <small>
                                                {errors.from_mobile.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('whatsAppSettings.error.mobileNoError')}
                                                    </span>
                                                )}
                                                {errors.from_mobile.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('whatsAppSettings.error.mobileNoPattern')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="mb-3 mt-3 text-end">
                                    <button type="submit" className="btn btn-primary me-1">
                                        {t('whatsAppSettings.btnLabel.add')}
                                    </button>
                                    <button
                                        type="reset"
                                        className="btn btn-danger"
                                        onClick={onReset}
                                    >
                                        {t('whatsAppSettings.btnLabel.reset')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhatsappSettings