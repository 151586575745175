import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faHome } from '@fortawesome/free-solid-svg-icons';

export default function Error403() {
    const isGuard = localStorage.getItem("isGuard")
    return (
        <Card className="text-center">
            <Card.Body className="p-5">
                <div className="display-1 text-300 fs-error">403</div>
                <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                    The requested resource is forbidden.
                </p>
                {/* <hr />
            <p>
              Make sure the address is correct and that the page hasn't moved. If
              you think this is a mistake,
              <a href="mailto:info@exmaple.com" className="ms-1">
                contact us
              </a>
              .
            </p> */}
                <Link className="btn btn-primary btn-sm mt-3" to={isGuard == "true" ? "/guest-registration" : "/"}>
                    Click To Dashboard
                </Link>
            </Card.Body>
        </Card>
    );
}
