import React, { useEffect, useState, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';
import ReactTable from 'components/common/ReactTable';
import { useForm, Controller } from 'react-hook-form';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactDatePicker from 'components/common/ReactDatePicker';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Dropdown from 'react-bootstrap/Dropdown';
import OutsideClickHandler from 'react-outside-click-handler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nestedtimevisittable from './Nestedtimevisittable';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import '../report.css'
import { loaderContext } from 'context/Context';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { listnumberofvisit, times6reportlist } from 'services/reportsService'
import moment from 'moment';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import ReactYearPicker from 'components/common/ReactYearPicker';


function TimesvisitReport() {

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [timevisitdata, setTimeVisitData] = useState([]);
    // const [dateRange, setDateRange] = useState([null, null]);
    // const [startDate, endDate] = dateRange;
    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    const [isOpen, setIsOpen] = useState({ id: null, open: false });
    const [numberOption, setNumberOption] = useState([])


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        watch
    } = useForm();

    useEffect(async () => {
        checkModulePermission("6 Times Visit Report")
        setIsLoading(true)
        try {
            const { data } = await times6reportlist({})
            const { data: numbervisit } = await listnumberofvisit({})

            setTimeVisitData(data?.data)
            setNumberOption(numbervisit?.data?.map((novisit) => ({ label: novisit.name, value: novisit.id })))
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }, [])

    const columns = [

        {
            Header: t('timesvisitreport.timesvisitreporttable.guestname'),
            accessor: 'name',
            Cell: ({ row }) => {
                return row?.original?.name ? row?.original?.name : "--"
            }
        },
        {
            Header: t('timesvisitreport.timesvisitreporttable.mobileno'),
            accessor: 'phone_number',
            Cell: ({ row }) => {
                return row?.original?.phone_number ? `${row?.original?.country_code}-${row?.original?.phone_number}` : '--'
            }
        },
        {
            Header: t('timesvisitreport.timesvisitreporttable.emailaddress'),
            accessor: 'email',
            Cell: ({ row }) => {
                return row?.original?.email ? row?.original?.email : "--"
            }
        },
        {
            Header: t('timesvisitreport.timesvisitreporttable.noofvisit'),
            accessor: 'no_of_visits',
            Cell: ({ row }) => {
                return row?.original?.no_of_visits ? row?.original?.no_of_visits : "--"
            }
        },
        {
            Header: t('timesvisitreport.timesvisitreporttable.action'),
            className: 'text-end',
            isExpand: true,
            Cell: ({ row }) => {
                return (
                    <div>
                        <span
                            className="btn p-0 me-2"
                            onClick={() => {
                                setIsOpen({ id: row.original.id, open: true });
                            }}
                        >
                            <FontAwesomeIcon icon="ellipsis-h" />
                        </span>
                        {
                            (isOpen.open && row.original.id == isOpen.id) &&
                            <OutsideClickHandler onOutsideClick={() => setIsOpen({ id: row.original.id, open: false })}>
                                <div className='card position-absolute p-2' style={{ right: "6%", zIndex: "999", minWidth: "15%", boxShadow: "0 0 6px 0 rgba(0,0,0,0.45) inset" }}>
                                    <div className='px-3' style={{ cursor: "pointer" }} {...row.getToggleRowExpandedProps()}>
                                        <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{t('timesvisitreport.viewdetail')}</span>
                                    </div>
                                </div >
                            </OutsideClickHandler>
                        }
                    </div>
                );
            },
            // Cell: ({ row }) => {
            //     const [isOpen, setIsOpen] = useState(false);
            //     return (<div>
            //         <span
            //             className="btn p-0 me-2"
            //             onClick={() => {
            //                 setIsOpen(!isOpen);
            //                 setOpenRowId(row.original.id);
            //             }}
            //         >
            //             <FontAwesomeIcon icon="ellipsis-h" />
            //         </span>
            //         {
            //             isOpen &&
            //             <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
            //                 <div className='card position-absolute p-2' style={{ right: "6%", zIndex: "999", minWidth: "15%", boxShadow: "0 0 6px 0 rgba(0,0,0,0.45) inset" }}>
            //                     <div className='px-3' style={{ cursor: "pointer" }} {...row.getToggleRowExpandedProps()}>
            //                         <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{t('timesvisitreport.viewdetail')}</span>
            //                     </div>
            //                 </div >
            //             </OutsideClickHandler>
            //         }
            //     </div >)
            // },

            disableSortBy: true
        }
    ];

    const onSubmit = async (timedata) => {
        console.log("timedata", timedata);
        setIsLoading(true)

        if (timedata?.date || timedata?.no_of_visit) {
            try {
                // if (startDate && endDate) {
                //     timedata.date_range = `${moment(startDate).format(process.env.REACT_APP_FILTER_DATE)}-${moment(endDate).format(process.env.REACT_APP_FILTER_DATE)}`;
                // }
                if(timedata?.date){
                    timedata.date = moment(timedata?.date).format('YYYY')
                }
                timedata.no_of_visit = timedata?.no_of_visit?.value
                const { data } = await times6reportlist(timedata)
                setTimeVisitData(data?.data)
            } catch (e) {
                if (e.response.data) {
                    if (Array.isArray(e.response.data.message)) {
                        e.response.data.message.map(errors => toast.error(errors, toastConfig))
                    } else {
                        toast.error(e.response.data.message, toastConfig)
                    }
                } else {
                    toast.error(e.message, toastConfig)
                }
            }
        }
        setIsLoading(false)

    };

    // const downloadPDF = () => {
    //     const selectedRowData = timevisitdata.find(row => row.id === isOpen.id);

    //     if (selectedRowData) {
    //         const docDefinition = {
    //             pageOrientation: 'landscape',
    //             pageSize: { width: 700, height: 600 },
    //             content: [
    //                 { text: 'User Data', style: 'header' },
    //                 { text: '\n' },
    //                 { text: '6 times visit report', style: 'tableHeading' },
    //                 {
    //                     table: {
    //                         headerRows: 1,
    //                         widths: Array.from({ length: columns.length }, () => 'auto'),
    //                         body: [
    //                             columns.map((column) => column.Header),
    //                             ...timevisitdata.map((row) => columns.map((column) => {
    //                                 if (column.accessor === 'phone_number') {
    //                                     const phoneNumber = row['phone_number'];
    //                                     const countryCode = row['country_code'];
    //                                     return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
    //                                 } else {
    //                                     return row[column.accessor] !== undefined ? row[column.accessor] : '';
    //                                 }

    //                             })),
    //                         ],
    //                     },
    //                 },
    //                 { text: '\n' },
    //                 { text: '6 times visit list', style: 'tableHeading' },
    //                 {
    //                     table: {
    //                         headerRows: 1,
    //                         widths: Array.from({ length: subColumns.length }, () => 'auto'),
    //                         body: [
    //                             subColumns.map((column) => column.Header),
    //                             ...selectedRowData.visits_list?.map((row) => subColumns.map((column) => {
    //                                 if (column.accessor === 'time_in' || column.accessor === 'time_out') {
    //                                     return row[column.accessor] && row[column.accessor] !== "" ? moment.utc(row[column.accessor]).local().format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : '--';
    //                                 } else {
    //                                     return row[column.accessor] !== undefined ? row[column.accessor] : '';
    //                                 }
    //                             })),
    //                         ],
    //                     },
    //                 },
    //             ],
    //             defaultStyle: {
    //                 actionHeader: false,
    //             },
    //             styles: {
    //                 header: {
    //                     fontSize: 18,
    //                     bold: true,
    //                 },
    //             },
    //         };

    //         const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    //         pdfDocGenerator.download('timesvisit_data.pdf');
    //     } else {
    //         const docDefinition = {
    //             pageOrientation: 'landscape',
    //             pageSize: { width: 700, height: 600 },
    //             content: [
    //                 { text: 'User Data', style: 'header' },
    //                 { text: '\n' },
    //                 { text: '6 times visit report', style: 'tableHeading' },
    //                 {
    //                     table: {
    //                         headerRows: 1,
    //                         widths: Array.from({ length: columns.length }, () => 'auto'),
    //                         body: [
    //                             columns.map((column) => column.Header),
    //                             ...timevisitdata.map((row) => columns.map((column) => {
    //                                 if (column.accessor === 'phone_number') {
    //                                     const phoneNumber = row['phone_number'];
    //                                     const countryCode = row['country_code'];
    //                                     return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
    //                                 } else {
    //                                     return row[column.accessor] !== undefined ? row[column.accessor] : '';
    //                                 }

    //                             })),
    //                         ],
    //                     },
    //                 },
    //                 { text: '\n' },
    //             ],
    //             defaultStyle: {
    //                 actionHeader: false,
    //             },
    //             styles: {
    //                 header: {
    //                     fontSize: 18,
    //                     bold: true,
    //                 },
    //             },
    //         };

    //         const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    //         pdfDocGenerator.download('timesvisit_data.pdf');
    //     }
    // };

    const downloadPDF = () => {
        const docDefinition = {
            pageOrientation: 'landscape',
            pageSize: { width: 700, height: 600 },
            content: [
                { text: '6 Times visit Data', style: 'header' },
                { text: '\n' },
            ],
            defaultStyle: {
                actionHeader: false,
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                },
                tableHeading: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 10],
                },
            },
        };

        timevisitdata.forEach((row, index) => {
            const mainRowContent = [
                { text: `6 times visit - ${index + 1}`, style: 'tableHeading' },
                {
                    table: {
                        headerRows: 1,
                        widths: Array.from({ length: columns.length }, () => 'auto'),
                        body: [
                            columns.map((column) => column.Header),
                            columns.map((column) => {
                                if (column.accessor === 'phone_number') {
                                    const phoneNumber = row['phone_number'];
                                    const countryCode = row['country_code'];
                                    return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
                                } else {
                                    return row[column.accessor] !== undefined ? row[column.accessor] : '';
                                }
                            }),
                        ],
                    },
                },
                { text: '\n' },
                { text: '6 times visit list', style: 'tableHeading' },
                {
                    table: {
                        headerRows: 1,
                        widths: Array.from({ length: subColumns.length }, () => 'auto'),
                        body: [
                            subColumns.map((column) => column.Header),
                            ...(row.visits_list || []).map((nestedRow) => subColumns.map((column) => {
                                if (column.accessor === 'time_in' || column.accessor === 'time_out') {
                                    return nestedRow[column.accessor] && nestedRow[column.accessor] !== "" ? moment(nestedRow[column.accessor]).format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : '--';
                                } else {
                                    return nestedRow[column.accessor] !== undefined ? nestedRow[column.accessor] : '';
                                }
                            })),
                        ],
                    },
                },
                { text: '\n' },
                { canvas: [{ type: 'line', x1: -20, y1: 0, x2: 650, y2: 0, lineWidth: 1 }] }
            ];
            docDefinition.content.push(...mainRowContent);
        });

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.download('timesvisit_data.pdf');
    };

    const downloadCSV = () => {
        let csvMainData = [
            columns.map((column) => column.Header)
        ];

        timevisitdata.forEach((row) => {
            const rowData = columns.map((column) => {
                if (column.accessor === 'phone_number') {
                    const phoneNumber = row['phone_number'];
                    const countryCode = row['country_code'];
                    return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
                } else {
                    return row[column.accessor] !== undefined ? row[column.accessor] : '';
                }
            });

            csvMainData.push(rowData);

            const nestedHeader = subColumns.map((subColumn) => subColumn.Header);
            csvMainData.push(nestedHeader); // Push nested table header

            const nestedRows = [];

            const selectedRowData = timevisitdata.find(data => data.id === row.id);
            selectedRowData?.visits_list.forEach((visit) => {
                const nestedRowData = subColumns.map((subColumn) => {
                    if (subColumn.accessor === 'time_in' || subColumn.accessor === 'time_out') {
                        return visit[subColumn.accessor] && visit[subColumn.accessor] !== "" ? moment(visit[subColumn.accessor]).format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : '--';
                    } else {
                        return visit[subColumn.accessor] !== undefined ? visit[subColumn.accessor] : '';
                    }
                });

                nestedRows.push(nestedRowData);
            });

            csvMainData.push(...nestedRows);

            csvMainData.push([]);
        });

        const csvLines = csvMainData.map((row) => row.join(',')).join('\n');

        const blob = new Blob([csvLines], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, '6times-visit_data.csv');
    };


    const subColumns = [

        {
            Header: t('timesvisitreport.nestedtimevisittable.guestname'),
            accessor: 'name',
            Cell: ({ row }) => {
                return row?.original?.name ? row?.original?.name : "--"
            }
        },
        {
            Header: t('timesvisitreport.nestedtimevisittable.membername'),
            accessor: 'member_name',
            Cell: ({ row }) => {
                return row?.original?.member_name ? row?.original?.member_name : "--"
            }
        },
        {
            Header: t('timesvisitreport.nestedtimevisittable.memberno'),
            accessor: 'member_number',
            Cell: ({ row }) => {
                return row?.original?.member_number ? row?.original?.member_number : "--"
            }
        },
        {
            Header: t('timesvisitreport.nestedtimevisittable.location'),
            accessor: 'location',
            Cell: ({ row }) => {
                return row?.original?.location ? row?.original?.location : "--"
            }
        },
        {
            Header: t('timesvisitreport.nestedtimevisittable.timein'),
            accessor: 'time_in',
            Cell: ({ row }) => {
                return row?.original?.time_in ? moment(row?.original?.time_in).format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : "--"
            }
        },
        {
            Header: t('timesvisitreport.nestedtimevisittable.timeout'),
            accessor: 'time_out',
            Cell: ({ row }) => {
                return row?.original?.time_out ? moment(row?.original?.time_out).format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : "--"
            }
        },
        {
            Header: t('timesvisitreport.nestedtimevisittable.duration'),
            accessor: 'duration',
            Cell: ({ row }) => {
                return row?.original?.duration ? row?.original?.duration : "--"
            }
        },
    ];

    const renderRowSubComponent = React.useCallback(({ row }) => {
        const newGuestData = row.original?.visits_list
        return (
            <div className="bg-img card nested-table-body overflow-hidden" >
                <ReactTable
                    columns={subColumns}
                    data={newGuestData ? newGuestData : []}
                    headerClass="btn-primary"
                    innerTableSearch="innertable"
                    hedingName="nestedtable.tableheading"
                    showHeader={false}
                    ispaginationvisible={true}
                />
            </div>
        )
    }, [])

   

    return (
        <div className="row g-3 mb-3">
            {/* <div className=""> */}
            <div className="card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-9 data-table" style={{ height: "fit-content" }}>
                <div className="card-header">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-4 heading-text">
                            <h3 className="heading-color fw-600 mb-0">
                                {t('timesvisitreport.heading')}
                            </h3>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-8 table-btn" >
                            <button
                                className="btn btn-primary btn-sm float-end"
                                onClick={() => downloadPDF()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline ">
                                    {t('timesvisitreport.exportpdfbtn')}
                                </span>
                            </button>

                            <button
                                className="btn mx-1 btn-primary btn-sm float-end"
                                onClick={() => downloadCSV()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline ">
                                    {t('timesvisitreport.exportcsvbtn')}
                                </span>
                            </button>
                        </div>

                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <ReactTable
                        columns={columns}
                        data={timevisitdata}
                        pageSizeOption={[5, 10, 15, 20]}
                        headerClass="btn-primary"
                        isSearchBoxVisible
                        RenderRowSubComponent={
                            renderRowSubComponent
                        }
                        ispaginationvisible={true}
                    />
                </div>
            </div>


            <div className='card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-2 filter-table' style={{ padding: "0rem .8rem 0rem .8rem", height: "fit-content" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row' style={{ backgroundColor: "rgb(247, 247, 247)", paddingTop: ".8rem", height: "3rem", color: "black", marginBottom: '1rem' }}>
                        <span>{t('timesvisitreport.filterheading')}</span>
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('timesvisitreport.filterform.date')}
                        </label>
                        <Controller
                            name="date"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <>
                                <ReactYearPicker 
                                 value={value ? value : moment().toDate()}
                                 onChange={onChange}
                                 
                                />
           

                                    {/* <ReactDatePicker
                                        selected={startDate}
                                        value={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        placeholder={t('timesvisitreport.placeholder.startdate')}
                                        dateFormat="dd/MM/yyyy"
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxDate={endDate || undefined}
                                    /> */}
                                    {/* <span className="mx-2">-</span> */}
                                    {/* <br /> */}
                                    {/* <ReactDatePicker
                                        selected={endDate}
                                        value={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        placeholder={t('timesvisitreport.placeholder.enddate')}
                                        dateFormat="dd/MM/yyyy"
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate || undefined}
                                    /> */}
                                </>
                            )}
                        />
                        
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('timesvisitreport.label.noofvisit')}
                        </label>
                        <Controller
                            name="no_of_visit"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={numberOption}
                                    defaultValue={({ label: "6", value: "6" })}
                                    placeholder={t('timesvisitreport.placeholder.noofvisit')}
                                />
                            )}
                        />
                    </div>


                    <div className="text-start col-12">
                        <div className="mb-3 mt-4 text-start">
                            <button type="submit" className="col-lg-12 btn btn-primary me-1">
                                {t('timesvisitreport.filterform.filterbtn')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {/* </div> */}
        </div>
    )
}

export default TimesvisitReport