import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller } from 'react-hook-form';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { genderOptions } from 'components/configuration/common-options';
import { useNavigate } from 'react-router-dom';
import pattern from 'components/configuration/form-pattern';
import { dropdowncompanytype, supplieradd } from 'services/adminmoduleService'
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';

const CreateSupplierContractor = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showLpoNumber, setShowLpoNumber] = useState(false);
    const [companytype, setCompanyType] = useState([]);
    const { isLoading, setIsLoading } = useContext(loaderContext)

    useEffect(async () => {
        checkModulePermission("Supplier & Contractors")
        setIsLoading(true)
        try
        {
            const { data: company } = await dropdowncompanytype()
            setCompanyType(company?.data?.map((visit) => ({ label: visit.name, value: visit.id })))
        } catch (e)
        {
            errorMessage(e)
        }
        setIsLoading(false)
    }, [])


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        watch
    } = useForm();
    const [statusOption, setStatusOption] = useState([{ label: "Active", value: "Active" }, { label: "Inactive", value: "Inactive" }]);


    const selectedVisitor = watch("company_type");
    useEffect(() => {
        if (selectedVisitor?.label === "Supplier")
        {
            setShowLpoNumber(true);
        } else
        {
            setShowLpoNumber(false);
        }
    }, [selectedVisitor])

    const resetValue = () => {
        reset();
        setValue("company_type", "")
        setValue("status", "")

    };
    const onSubmit = async (newData) => {
        setIsLoading(true)
        try
        {
            console.log("supplier", newData)
            newData.company_type = newData.company_type?.value
            newData.status = newData.status?.value
            const { data } = await supplieradd(newData)
            toast.success(data?.message, toastConfig)
            navigate('/admin/supplier-contractor')
            reset()
        } catch (e)
        {
            if (e.response.data)
            {
                if (Array.isArray(e.response.data.message))
                {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else
                {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else
            {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }



    return (

        <div className='row g-3 mb-3'>
            <div className="">
                <div className="card overflow-hidden mb-3">
                    <div className="card-header">

                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <div className='row'>
                                <div className='col-8'>
                                    <h5 className="mt-3 mb-1">
                                        {t('suppliercontractor.createsupplier.heading')}
                                    </h5>
                                </div>

                                <div className="col-4 table-btn">
                                    <button
                                        className="btn btn-primary float-end btn-sm"
                                        onClick={() => navigate('/admin/supplier-contractor')}
                                    >
                                        <FontAwesomeIcon icon="chevron-left" />
                                        <span className="ms-1">
                                            {t('userroles.btnlabel.back')}
                                        </span>
                                    </button>
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>



                                <div className="form-group col-md-4 col-sm-6 mb-3">
                                    <label>
                                        {t('suppliercontractor.createsupplier.companyname')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <input
                                        className={`form-control ${errors.company_name && 'is-invalid'
                                            }`}
                                        type="text"
                                        placeholder={t('suppliercontractor.createsupplierplaceholder.companyname')}
                                        {...register('company_name', {
                                            required: true,
                                            pattern: pattern.notAllowSpaceInStart
                                        })}
                                    />
                                    {errors.company_name && (
                                        <small>
                                            {errors.company_name.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('suppliercontractor.createsuppliererror.companyerror')}
                                                </span>
                                            )}
                                            {errors.company_name.type === 'pattern' && (
                                                <span className="text-danger">
                                                    {t('suppliercontractor.createsuppliererror.companyseconderror')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-md-4 col-sm-6 mb-3">
                                    <label>
                                        {t('suppliercontractor.createsupplier.companytype')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <Controller
                                        name="company_type"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            <SearchableDropdown
                                                value={value}
                                                onChange={onChange}
                                                options={companytype}
                                                hasError={errors.company_type}
                                                placeholder={t('suppliercontractor.createsupplierplaceholder.selectcompanytype')}
                                            />
                                        )}
                                    />
                                    {errors.company_type && (
                                        <small>
                                            {errors.company_type.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('suppliercontractor.createsuppliererror.companytypeerror')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>

                                {/* {showLpoNumber && <div className="form-group col-md-4 col-sm-6 mb-3">
                                    <label>
                                        {t('suppliercontractor.createsupplier.lpoNumber')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <input
                                        className={`form-control ${errors.lpo_number && 'is-invalid'
                                            } `}
                                        type="text"
                                        placeholder={t('suppliercontractor.createsupplierplaceholder.lponumber')}
                                        {...register('lpo_number', { required: true })}
                                    />
                                    {errors.lpo_number && (
                                        <small>
                                            {errors.lpo_number.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('suppliercontractor.createsuppliererror.lpoerror')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                  </div>
                              } */}

                                <div className="form-group col-md-4 col-sm-6 mb-3">
                                    <label>
                                        {t('suppliercontractor.createsupplier.status')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <Controller
                                        name="status"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange } }) => (
                                            // <input className='form-group' value={value} onChange={onChange} />
                                            <SearchableDropdown
                                                value={value}
                                                onChange={onChange}
                                                options={statusOption}
                                                hasError={errors.status}
                                                placeholder={t('systemuser.createsystemuserformplaceholder.selectstatus')}
                                            />
                                        )}
                                    />
                                    {errors.status && (
                                        <small>
                                            {errors.status.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('suppliercontractor.createsuppliererror.statuserror')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>

                                <div className="col-12">
                                    <hr />
                                </div>

                                <h5 className="mt-3 mb-1">
                                    {t('suppliercontractor.contactperson')}
                                </h5>

                                <div className="form-group col-md-4 col-sm-6 mb-3">
                                    <label>
                                        {t('suppliercontractor.createsupplier.fullname')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <input
                                        className={`form-control ${errors.name && 'is-invalid'
                                            }`}
                                        type="text"
                                        placeholder={t('suppliercontractor.createsupplierplaceholder.fullname')}
                                        {...register('name', {
                                            required: true,
                                            pattern: pattern.notAllowSpaceInStart
                                        })}
                                    />
                                    {errors.name && (
                                        <small>
                                            {errors.name.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('suppliercontractor.createsuppliererror.fullnameerror')}
                                                </span>
                                            )}
                                            {errors.name.type === 'pattern' && (
                                                <span className="text-danger">
                                                    {t('suppliercontractor.createsuppliererror.fullnameseconderror')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-md-4 col-sm-6 mb-3">
                                    <label>
                                        {t('suppliercontractor.createsupplier.mobileno')}
                                        <span className="text-danger ms-1">*</span>
                                    </label>
                                    <input
                                        className={`form-control ${errors.phone && 'is-invalid'
                                            }`}
                                        type="text"
                                        placeholder={t('suppliercontractor.createsupplierplaceholder.mobileno')}
                                        {...register('phone', {
                                            required: true,
                                            pattern: pattern.phoneNumber
                                        })}
                                    />
                                    {errors.phone && (
                                        <small>
                                            {errors.phone.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('suppliercontractor.createsuppliererror.mobilenoerror')}
                                                </span>
                                            )}
                                            {errors.phone.type === 'pattern' && (
                                                <span className="text-danger">
                                                    {t('suppliercontractor.createsuppliererror.secondmobilenoerror')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group col-md-4 col-sm-6 mb-3">
                                    <label>
                                        {t('suppliercontractor.createsupplier.email')}

                                    </label>
                                    <input
                                        className={`form-control ${errors.email && 'is-invalid'
                                            }`}
                                        type="text"
                                        placeholder={t('suppliercontractor.createsupplierplaceholder.emailaddress')}
                                        {...register('email', {
                                            pattern: pattern.email
                                        })}
                                    />
                                    {errors.email && (
                                        <small>
                                            {errors.email.type === 'required' && (
                                                <span className="text-danger">
                                                    {t('suppliercontractor.createsuppliererror.emailerror')}
                                                </span>
                                            )}
                                            {errors.email.type === 'pattern' && (
                                                <span className="text-danger">
                                                    {t('suppliercontractor.createsuppliererror.emailseconderror')}
                                                </span>
                                            )}
                                        </small>
                                    )}
                                </div>

                                <div className="text-end col-12">
                                    <div className="d-flex justify-content-end mb-3 mt-4 text-start">
                                        <button type="submit" className="btn btn-primary me-1">
                                            {t('departmentMaster.btnLabel.add')}
                                        </button>
                                        <button type="reset" className="btn btn-danger me-1" onClick={resetValue}>
                                            {t('departmentMaster.btnLabel.reset')}
                                        </button>
                                    </div>

                                </div>


                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </div>


    )
}

export default CreateSupplierContractor