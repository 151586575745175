import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav, Collapse } from 'react-bootstrap';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import classNames from 'classnames';
import AppContext, { loaderContext } from 'context/Context';

const CollapseItems = ({ route }) => {
  console.log("INside route", route)
  const { pathname } = useLocation();


  const openCollapse = childrens => {
    const newChildrens = childrens.filter((child) => child)
    const checkLink = children => {
      if (children.to === pathname) {
        return true;
      }
      return (
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        children.children.some(checkLink)
      );
    };
    return newChildrens.some(checkLink);
  };

  const [open, setOpen] = useState(openCollapse(route.children));

  return (
    <Nav.Item as="li">
      <Nav.Link
        onClick={() => {
          setOpen(!open);
        }}
        className={classNames('dropdown-indicator cursor-pointer', {
          'text-500': !route.active
        })}
        aria-expanded={open}
      // {...route}
      >
        <NavbarVerticalMenuItem route={route} />
      </Nav.Link>
      <Collapse in={open}>
        <Nav className="flex-column nav" as="ul">
          <NavbarVerticalMenu routes={route.children} />
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool
  }).isRequired
};

const NavbarVerticalMenu = ({ routes, saperator }) => {
  const {
    config: { showBurgerMenu },
    setConfig
  } = useContext(AppContext);
  const { memberNumber } = useContext(loaderContext)

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };
  return routes.map(route => {
    if (route) {
      if (!route.children) {
        return (
          <>
            <Nav.Item as="li" key={route.name}
              onClick={handleNavItemClick}
              className={({ isActive }) =>
                isActive ? 'active nav-link' : 'nav-link'
              }>
              <NavLink
                end={route.exact}
                to={(route?.to?.includes("/booking/guest-booking") || route?.to?.includes("/booking/event-booking") || route?.to?.includes("/booking/fullguest-booking")) ? `${route.to}/${memberNumber}` : route.to}
                state={{ open: route?.to === '/authentication-modal' }}
                className={({ isActive }) =>
                  isActive ? 'active nav-link' : 'nav-link'
                }
              >
                <NavbarVerticalMenuItem route={route} />
              </NavLink>
            </Nav.Item>
            {saperator && saperator}
          </>
        );
      }
      return <CollapseItems route={route} key={route.name} />;
    }
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired
};

export default NavbarVerticalMenu;
