import { aideEndPoints, backupEndPoints, blacklistEndPoints, casualworkerEndPoints, commonreportEndPoints, membersEndPoints, messagetempEndPoints, sosEndPoints, suppliercontractorEndPoints, systemuserEndPoints } from "./config"
import httpServices from "./httpServices"

// system users //
export const systemuserlist = async () => {
    return await httpServices.get(systemuserEndPoints.listsystemuser)
}
export const systemuseradd = async (systemuserData) => {
    return await httpServices.post(systemuserEndPoints.addsystemuser, systemuserData)
}
export const getListOfDepartment = async () => {
    return await httpServices.get(systemuserEndPoints.listdepartment)
}
export const systemuserdelete = async (id) => {
    return await httpServices.post(systemuserEndPoints.deleteuser, id)
}
export const systemuserupdate = async (updatedData) => {
    return await httpServices.post(systemuserEndPoints.updateuser, updatedData)
}


// common report dropdownlist //
export const getListOfVisitortype = async () => {
    return await httpServices.get(commonreportEndPoints.listvisitortype)
}
export const getListOfDestination = async () => {
    return await httpServices.get(commonreportEndPoints.listdestination)
}
export const getListOfregister = async () => {
    return await httpServices.get(commonreportEndPoints.listregister)
}

// casual workers //
export const casualworkerslist = async () => {
    return await httpServices.get(casualworkerEndPoints.listcasualworker)
}
export const casualworkeradd = async (casualworkerData) => {
    return await httpServices.post(casualworkerEndPoints.addcasualworker, casualworkerData)
}
export const casualworkerdelete = async (id) => {
    return await httpServices.post(casualworkerEndPoints.deletecasualworker, id)
}
export const casualworkerupdate = async (updateData) => {
    return await httpServices.post(casualworkerEndPoints.updatecasualworker, updateData)
}
export const dropdownvisitor = async () => {
    return await httpServices.get(casualworkerEndPoints.visitortypedropdown)
}
export const dropdowncompanytype = async () => {
    return await httpServices.get(casualworkerEndPoints.companytypedropdown)
}

// Members //
export const memberslist = async () => {
    return await httpServices.get(membersEndPoints.listmember)
}
export const membersadd = async (membersData) => {
    return await httpServices.post(membersEndPoints.addmember, membersData)
}
export const membersdelete = async (id) => {
    return await httpServices.post(membersEndPoints.deletemember, id)
}
export const membersupdate = async (updateData) => {
    return await httpServices.post(membersEndPoints.updatemember, updateData)
}
export const dropdownmembercategory = async () => {
    return await httpServices.get(membersEndPoints.listmembercategory)
}



//members aide added by Parth
export const aideadd = async (aideData) => {
    return await httpServices.post(aideEndPoints.addaide, aideData)
}
export const aidelist = async () => {
    return await httpServices.get(aideEndPoints.listaide)
}
export const aidedelete = async (aideData) => {
    return await httpServices.post(aideEndPoints.deleteaide, aideData)
}
export const aideupdate = async (updateData) => {
    return await httpServices.post(aideEndPoints.updateaide, updateData)
}

// supplier&contractor //
export const listsupplier = async () => {
    return await httpServices.get(suppliercontractorEndPoints.listsupplier)
}
export const supplieradd = async (supplierData) => {
    return await httpServices.post(suppliercontractorEndPoints.addsupplier, supplierData)
}
export const supplierdelete = async (id) => {
    return await httpServices.post(suppliercontractorEndPoints.deletesupplier, id)
}
export const supplierupdate = async (updateData) => {
    return await httpServices.post(suppliercontractorEndPoints.updatesupplier, updateData)
}


// blacklist visitor //
export const listblack = async () => {
    return await httpServices.get(blacklistEndPoints.listblack)
}
export const blackadd = async (blackData) => {
    return await httpServices.post(blacklistEndPoints.addblack, blackData)
}
export const blacksearchlist = async () => {
    return await httpServices.get(blacklistEndPoints.searchlist)
}
export const blackupload = async (blackcsvData) => {
    return await httpServices.post(blacklistEndPoints.uploadblack, blackcsvData)
}
export const blackdelete = async (id) => {
    return await httpServices.post(blacklistEndPoints.deleteblack, id)
}
export const blacklisttype = async () => {
    return await httpServices.get(blacklistEndPoints.visitortype)
}

// message template //
export const listmessage = async () => {
    return await httpServices.get(messagetempEndPoints.listmessage)
}
export const updatemessage = async (msgData) => {
    return await httpServices.post(messagetempEndPoints.updatemessage, msgData)
}

// backup management //
export const backuplist = async () => {
    return await httpServices.get(backupEndPoints.listbackup)
}
export const manualbackuplist = async () => {
    return await httpServices.get(backupEndPoints.listmanualbackup)
}
export const savebackup = async (backupdata) => {
    return await httpServices.post(backupEndPoints.savebackup, backupdata)
}
export const backupdelete = async (id) => {
    return await httpServices.post(backupEndPoints.deletebackup, id)
}

// sos message //
export const sendsos = async (sosData) => {
    return await httpServices.post(sosEndPoints.sendsos, sosData)
}
