import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';
import ReactTable from 'components/common/ReactTable';
import { useForm, Controller } from 'react-hook-form';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactDatePicker from 'components/common/ReactDatePicker';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import './report.css'
import { getListOfDepartment, getListOfVisitortype, getListOfregister } from 'services/adminmoduleService'
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { visitsreportlist } from 'services/reportsService'
import moment from 'moment';
import { loaderContext } from 'context/Context';





function VisitsReport() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const { isLoading, setIsLoading } = useContext(loaderContext)

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [visitreportdata, setVisitReportData] = useState([]);
    const [departmentOption, setDepartmentOption] = useState([]);
    const [visitorTypeOption, setVisitorTypeOption] = useState([]);
    const [registerOption, setRegisterOption] = useState([]);
    const [isShowLpo, setIsShowLpo] = useState(false)
    const [visitorStatusOption, setVisitorStatusOption] = useState([{ label: "Login", value: "login" }, { label: "Logout", value: "logout" }]);
    // const [dateRange, setDateRange] = useState([null, null]);
    // const [startDate, endDate] = dateRange;

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        watch
    } = useForm();

    const visitor = watch("visitor_type")

    useEffect(() => {
        if (visitor?.label == "Supplier") {
            setIsShowLpo(true)
        } else {
            setIsShowLpo(false)
        }
    }, [visitor])


    useEffect(async () => {
        checkModulePermission("Visits Report")
        setIsLoading(true)
        try {
            const { data } = await getListOfDepartment()
            const { data: visitortype } = await getListOfVisitortype()
            const { data: visits } = await visitsreportlist({})
            const { data: register } = await getListOfregister()

            setDepartmentOption(data?.data?.map((depart) => ({ label: depart?.name, value: depart?.id })))
            setVisitorTypeOption(visitortype?.data?.map((visitor) => ({ label: visitor?.name, value: visitor?.id })))
            setRegisterOption(register?.data?.map((register) => ({ label: register?.name, value: register?.id })))
            setVisitReportData(visits?.data)
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }, [])
    const columns = [

        {
            Header: t('visitsreport.visitorreporttable.visitorname'),
            accessor: 'visitor_name',
            Cell: ({ row }) => {
                return row?.original?.visitor_name ? row?.original?.visitor_name : '--'
            }
        },
        {
            Header: t('visitsreport.visitorreporttable.mobileno'),
            accessor: 'phone_number',
            Cell: ({ row }) => {
                return row?.original?.phone_number ? `${row?.original?.country_code}-${row?.original?.phone_number}` : '--'
            }
        },
        {
            Header: t('visitsreport.visitorreporttable.idno'),
            accessor: 'document_id_number',
            Cell: ({ row }) => {
                return row?.original?.document_id_number ? row?.original?.document_id_number : '--'
            }
        },
        {
            Header: t('visitsreport.visitorreporttable.idtype'),
            accessor: 'document_id_type',
            Cell: ({ row }) => {
                return row?.original?.document_id_type ? row?.original?.document_id_type : '--'
            }
        },
        {
            Header: t('visitsreport.visitorreporttable.department'),
            accessor: 'department_name',
            Cell: ({ row }) => {
                return row?.original?.department_name ? row?.original?.department_name : '--'
            }
        },
        {
            Header: t('visitsreport.visitorreporttable.host'),
            accessor: 'host_name',
            Cell: ({ row }) => {
                return row?.original?.host_name ? row?.original?.host_name : '--'
            }
        },

        {
            Header: t('visitsreport.visitorreporttable.lponumber'),
            accessor: 'lpo_number',
            Cell: ({ row }) => {
                return row?.original?.lpo_number ? row?.original?.lpo_number : '--'
            },
            show: isShowLpo ? true : false

        },

        {
            Header: t('visitsreport.visitorreporttable.accesscardno'),
            accessor: 'access_card_no',
            Cell: ({ row }) => {
                return row?.original?.access_card_no ? row?.original?.access_card_no : '--'
            }
        },
        {
            Header: t('visitsreport.visitorreporttable.vehicleregno'),
            accessor: 'vehicle_reg_no',
            Cell: ({ row }) => {
                return row?.original?.vehicle_reg_no ? row?.original?.vehicle_reg_no : '--'
            }
        },
        {
            Header: t('visitsreport.visitorreporttable.date'),
            accessor: 'date',
            Cell: ({ row }) => {
                return row?.original?.date ? moment.utc(row?.original?.date).local().format(process.env.REACT_APP_FILTER_DATE) : '--'
            }
        },
        {
            Header: t('visitsreport.visitorreporttable.timein'),
            accessor: 'time_in',
            Cell: ({ row }) => {
                return row?.original?.time_in ? moment.utc(row?.original?.time_in).local().format(process.env.REACT_APP_TIME_FORMAT_24) : '--'
            },
        },
        {
            Header: t('visitsreport.visitorreporttable.timeout'),
            accessor: 'time_out',
            Cell: ({ row }) => {
                return row?.original?.time_out ? moment.utc(row?.original?.time_out).local().format(process.env.REACT_APP_TIME_FORMAT_24) : "--"
            },
        },
        {
            Header: t('visitsreport.visitorreporttable.duration'),
            accessor: 'duration',
            Cell: ({ row }) => {
                return row?.original?.duration ? row?.original?.duration : '--'
            }
        },
    ];

    const onSubmit = async (visitsData) => {
        console.log("visitsData", visitsData)
        setIsLoading(true)

        if (visitsData?.department_id || visitsData?.visitor_type || visitsData?.vehicle_reg_no || visitsData?.lpo_number || visitsData?.register_by || visitsData?.visitor_status || (startDate && endDate)) {
            try {
                visitsData.department_id = visitsData.department_id?.value
                visitsData.visitor_type = visitsData.visitor_type?.value
                visitsData.register_by = visitsData.register_by?.value
                visitsData.visitor_status = visitsData.visitor_status?.value
                if (startDate && endDate) {
                    visitsData.date_range = `${moment(startDate).format(process.env.REACT_APP_FILTER_DATE)}-${moment(endDate).format(process.env.REACT_APP_FILTER_DATE)}`;
                }
                const { data } = await visitsreportlist(visitsData)
                setVisitReportData(data?.data)
            } catch (e) {
                if (e.response.data) {
                    if (Array.isArray(e.response.data.message)) {
                        e.response.data.message.map(errors => toast.error(errors, toastConfig))
                    } else {
                        toast.error(e.response.data.message, toastConfig)
                    }
                } else {
                    toast.error(e.message, toastConfig)
                }
            }
        }
        setIsLoading(false)

    };

    const downloadPDF = () => {
        const docDefinition = {
            pageOrientation: 'landscape',
            pageSize: { width: 900, height: 600 },
            content: [
                { text: 'User Data', style: 'header' },
                { text: '\n' },
                {
                    table: {
                        headerRows: 1,
                        widths: Array.from({ length: columns.length }, () => 'auto'),
                        body: [
                            columns.map((column) => column.Header),
                            ...visitreportdata.map((row) => columns.map((column) => {
                                if (column.accessor === 'time_in' || column.accessor === 'time_out') {
                                    return row[column.accessor] && row[column.accessor] != "" ? moment.utc(row[column.accessor]).local().format(process.env.REACT_APP_TIME_FORMAT_24) : '--';
                                } else if (column.accessor === 'phone_number') {
                                    const phoneNumber = row['phone_number'];
                                    const countryCode = row['country_code'];
                                    return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
                                } else {
                                    return row[column.accessor] !== undefined ? row[column.accessor] : '';
                                }
                            })),
                        ],
                    },
                },
            ],
            defaultStyle: {
                actionHeader: false,
            },
            styles: {
                header: {
                    fontSize: 14,
                    bold: true,
                },
            },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.download('visits_data.pdf');
    };

    const downloadCSV = () => {
        const csvData = [
            columns.map((column) => column.Header),
            ...visitreportdata.map((row) => columns.map((column) => {
                if (column.accessor === 'time_in' || column.accessor === 'time_out') {
                    return row[column.accessor] && row[column.accessor] != "" ? moment.utc(row[column.accessor]).local().format(process.env.REACT_APP_TIME_FORMAT_24) : '--';
                } else if (column.accessor === 'phone_number') {
                    const phoneNumber = row['phone_number'];
                    const countryCode = row['country_code'];
                    return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
                } else {
                    return row[column.accessor] !== undefined ? row[column.accessor] : '';
                }
            })),
        ]
            .map((row) => row.join(','))
            .join('\n');

        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'visits_data.csv');
    };


    return (
        <div className="row g-3 mb-3">
            {/* <div className=""> */}
            <div className="card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-9 data-table" style={{ height: "fit-content" }}>
                <div className="card-header">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-4 heading-text">
                            <h3 className="heading-color fw-600 mb-0">
                                {t('visitsreport.heading')}
                            </h3>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-8 table-btn" >
                            <button
                                className="btn btn-primary btn-sm float-end"
                                onClick={() => downloadPDF()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline">
                                    {t('visitsreport.exportpdfbtn')}
                                </span>
                            </button>

                            <button
                                className="btn mx-1 btn-primary btn-sm float-end"
                                onClick={() => downloadCSV()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline ">
                                    {t('visitsreport.exportcsvbtn')}
                                </span>
                            </button>
                        </div>

                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <ReactTable
                        columns={columns}
                        data={visitreportdata}
                        pageSizeOption={[5, 10, 15, 20]}
                        headerClass="btn-primary"
                        widthClass="table-width"
                        isSearchBoxVisible
                        ispaginationvisible={true}
                        isShow={isShowLpo}
                    />
                </div>
            </div>


            <div className='card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-2 filter-table' style={{ padding: "0rem .8rem 0rem .8rem", height: "fit-content" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row' style={{ backgroundColor: "rgb(247, 247, 247)", paddingTop: ".8rem", height: "3rem", color: "black", marginBottom: '1rem' }}>
                        <span>{t('visitsreport.filterheading')}</span>
                    </div>
                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('visitsreport.filterform.department')}
                        </label>
                        <Controller
                            name="department_id"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={departmentOption}
                                    hasError={errors.department_id}
                                // placeholder={t('suppliercontractor.createsupplierplaceholder.selectcompanytype')}
                                />
                            )}
                        />
                        {/* {errors.department && (
                            <small>
                                {errors.department.type === 'required' && (
                                    <span className="text-danger">
                                        {t('visitsreport.error.departmenterror')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>
                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('visitsreport.filterform.visitortype')}
                        </label>
                        <Controller
                            name="visitor_type"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={visitorTypeOption}
                                    hasError={errors.visitor_type}
                                    placeholder={t('visitsreport.placeholder.visitortype')}
                                />
                            )}
                        />
                        {/* {errors.visitortype && (
                            <small>
                                {errors.visitortype.type === 'required' && (
                                    <span className="text-danger">
                                        {t('visitsreport.error.visitortypeerror')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>

                    {
                        isShowLpo && (
                            <div className="form-group col-12 mb-3">
                                <label className="form-label">
                                    {t('visitsreport.filterform.lponumber')}
                                    {/* <span className="text-danger ms-1">*</span> */}
                                </label>
                                <input
                                    type='text'
                                    className={`form-control ${errors.lpo_number && 'is-invalid'
                                        }`}
                                    placeholder={t('visitsreport.filterform.lponumber')}
                                    {...register('lpo_number')}
                                />
                            </div>
                        )
                    }

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('visitsreport.filterform.visitorstatus')}
                        </label>
                        <Controller
                            name="visitor_status"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={visitorStatusOption}
                                    hasError={errors.visitor_status}
                                    placeholder={t('visitsreport.placeholder.visitorstatus')}
                                />
                            )}
                        />
                        {/* {errors.visitorstatus && (
                            <small>
                                {errors.visitorstatus.type === 'required' && (
                                    <span className="text-danger">
                                        {t('visitsreport.error.visitorstatuserror')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>
                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('visitsreport.filterform.registerby')}
                        </label>
                        <Controller
                            name="register_by"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={registerOption}
                                    hasError={errors.register_by}
                                    placeholder={t('visitsreport.placeholder.registerby')}
                                />
                            )}
                        />
                        {/* {errors.registerby && (
                            <small>
                                {errors.registerby.type === 'required' && (
                                    <span className="text-danger">
                                        {t('visitsreport.error.registerbyerror')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label className="form-label">
                            {t('visitsreport.filterform.vehicleregno')}
                            {/* <span className="text-danger ms-1">*</span> */}
                        </label>
                        <input
                            type='text'
                            className={`form-control ${errors.vehicle_reg_no && 'is-invalid'
                                }`}
                            placeholder={t('visitsreport.filterform.vehicleregno')}
                            {...register('vehicle_reg_no')}
                        />
                    </div>



                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('visitsreport.filterform.daterange')}
                        </label>
                        <Controller
                            name="date_range"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <>
                                    {/* <ReactDatePicker
                                    value={value}
                                    // onChange={onChange}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    placeholder={t(
                                        'visitsreport.placeholder.date'
                                    )}
                                    hasErrors={errors.date_range}
                                    dateFormat="dd-MM-yyyy"
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange={true}
                                // isClearable={true}
                                /> */}
                                    <ReactDatePicker
                                        selected={startDate}
                                        value={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        placeholder={t('summaryreport.placeholder.startdate')}
                                        dateFormat="dd/MM/yyyy"
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxDate={endDate || undefined}
                                    />
                                    {/* <span className="mx-2">-</span> */}
                                    <br />
                                    <ReactDatePicker
                                        selected={endDate}
                                        value={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        placeholder={t('summaryreport.placeholder.enddate')}
                                        dateFormat="dd/MM/yyyy"
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate || undefined}
                                    />
                                </>
                            )}
                        />
                        {/* {errors.date && (
                            <small>
                                {errors.date.type === 'required' && (
                                    <span className="text-danger">
                                        {t('visitsreport.error.dateerror')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>

                    <div className="text-start col-12">
                        <div className="mb-3 mt-4 text-start">
                            <button type="submit" className="col-lg-12 btn btn-primary me-1">
                                {t('visitsreport.filterform.filterbtn')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {/* </div> */}
        </div>
    )
}

export default VisitsReport