import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { authenticateUser } from 'services/authenticationService';
import toastConfig from 'components/configuration/toast-config';
import pattern from 'components/configuration/form-pattern';
import InputPassword from 'components/common/InputPassword';
import { loaderContext } from 'context/Context';
import routes from 'routes/routes';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';


const LoginForm = ({ hasLabel }) => {
  console.log("Routes====>", routes)
  const { t } = useTranslation();
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const {
    handleSubmit,
    register,
    control,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();



  const onSubmit = async formData => {
    setIsLoading(true)
    try {
      const { data } = await authenticateUser({ ...formData, system_type: "admin" });
      if (data?.data[0]?.is_first_time_login == 1) {
        navigate('/reset-password', { state: { isLogin: 1, loginemail: formData?.email } })
      } else {

        // localStorage.setItem('authToken', data.result.token);
        // toast.success(data.result.message, toastConfig);
        console.log("User Data", data?.data[0])
        localStorage.setItem('authToken', data?.data[0]?.access_token);
        localStorage.setItem('userName', data?.data[0]?.name);
        localStorage.setItem('userId', data?.data[0]?.id);
        localStorage.setItem("isGuard", data?.data[0]?.is_gaurd_type)
        localStorage.setItem("userType", data?.data[0]?.user_type)
        { data?.data[0]?.role_list && localStorage.setItem('userPrmission', JSON.stringify({ permission: data?.data[0]?.role_list })) }
        { data?.data[0]?.dashboard_role_list && localStorage.setItem('dashPermission', JSON.stringify({ dashpermission: data?.data[0]?.dashboard_role_list })) }

        // localStorage.setItem('userData', JSON.stringify(data.data.result.userData));
        toast.success(data?.message, toastConfig);
      }

    } catch (e) {
      if (e.response.data) {
        if (Array.isArray(e.response.data.message)) {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else {
          toast.error(e.response.data.message, toastConfig)
        }
      } else {
        toast.error(e.message, toastConfig)
      }
    }
    setIsLoading(false)
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="mb-3 form-group">
        {hasLabel && (
          <div className="form-label">{t('loginFormLabel.emailLabel')}</div>
        )}
        <input
          className={`form-control ${errors.email && 'is-invalid'}`}
          placeholder={
            !hasLabel ? t('loginFormPlaceholder.emailPlaceholder') : ''
          }
          type="email"
          {...register('email', { required: true, pattern: pattern.email })}
        />
        {errors.email && (
          <small className="text-danger">
            {errors.email.type === 'required' && (
              <span>{t('loginFormError.emailRequired')}</span>
            )}
            {errors.email.type === 'pattern' && (
              <span>{t('loginFormError.emailPattern')}</span>
            )}
          </small>
        )}
      </div>

      <div className="mb-3 form-group">
        {hasLabel && (
          <div className="form-label">{t('loginFormLabel.passwordLabel')}</div>
        )}
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <InputPassword
              value={value}
              onChange={onChange}
              isInvalid={errors.password ? true : false}
              placeholder="loginFormPlaceholder.passwordPlaceholder"
            />
          )}
        />
        {errors.password && (
          <small className="text-danger">
            {errors.password.type === 'required' && (
              <span>{t('loginFormError.passwordRequired')}</span>
            )}
          </small>
        )}
      </div>

      <Row className="justify-content-end align-items-center">
        <Col xs="auto">
          <Link className="fs--1 mb-0 forgot-password" to={`/forgot-password`}>
            {t('loginFormLabel.forgotPasswordLinkLabel')}
          </Link>
        </Col>
      </Row>

      <div className="form-group">
        <Button type="submit" className="mt-3 w-100 mb-3 btn-lg ">
          {t('loginFormLabel.loginbtnLabel')}
        </Button>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  hasLabel: false
};

export default LoginForm;
