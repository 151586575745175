import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { visitorRegistration, vehicleRegister } from 'components/configuration/statics/formData/manageFrontDesk';
import React, { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getfrontdesk, frontdeskupdate } from 'services/applicationSettingService'
import { loaderContext } from 'context/Context';

function Managefrontdesk() {
    const { t } = useTranslation()
    const [frontdeskData, setFrontDeskData] = useState([])
    const [visitordata, setVisitorData] = useState([])
    const [guestdata, setGuestData] = useState([])
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm();

    useEffect(async () => {
        checkModulePermission("Manage FrontDesk")
        setIsLoading(true)
        try {
            const { data } = await getfrontdesk()
            setVisitorData(data.data.filter((visit) => visit.frontdesks_type === 1))
            setGuestData(data.data.filter((vehicle) => vehicle.frontdesks_type === 0))
            setFrontDeskData(data.data[0])
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, [])

    const handleswitch = async (visitorsData) => {
        setIsLoading(true)
        try {
            const { data } = await frontdeskupdate({
                frontdesks_type: visitorsData.frontdesks_type,
                id: visitorsData.id,
                status: visitorsData.mandatory_status == 1 ? 0 : 1
            })
            if (visitorsData.frontdesks_type == 1) {
                setVisitorData(visitordata.map((visitor) => {
                    if (visitor.id == visitorsData.id) {
                        visitor.mandatory_status = visitor.mandatory_status == 1 ? 0 : 1
                    }
                    return visitor
                }))
            } else {
                setGuestData(guestdata.map((vehicle) => {
                    if (vehicle.id == visitorsData.id) {
                        vehicle.mandatory_status = vehicle.mandatory_status == 0 ? 1 : 0
                    }
                    return vehicle
                }))
            }
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }

    console.log("frontdeskdata", frontdeskData)
    console.log("visitordata", visitordata)
    console.log("guestdata", guestdata)
    return (
        <>
            <div className="mb-3 g-3">
                <div className="card overflow-hidden mb-3 bg-img">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="heading-color fw-500 p-0 py-2">
                                    {t('manageFrontDesk.heading')}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row g-3'>
                    <div className='col-md-6 mb-3'>
                        <div className='card'>
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="heading-color p-0 fw-400">
                                            {t('manageFrontDesk.visitorRegistration')}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <hr className='m-0' />
                            </div>
                            <div className='card-body'>
                                <div className='row mx-1'>
                                    <div className='col-6'>
                                        <p className='mb-0 fw-600'>{t('manageFrontDesk.label')}</p>
                                    </div>
                                    <div className='col-6'>
                                        <p className='mb-0 fw-600'>{t('manageFrontDesk.mandatoryField')}</p>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <hr />
                                </div>
                                {visitordata.map((visitorData, index) => (
                                    <div key={index}>
                                        <div className='row mx-1'>
                                            <div className='col-6'>
                                                <p className='mb-0'>{t(visitorData.label_name)}</p>
                                            </div>
                                            <div className='col-6'>
                                                <Form.Check // prettier-ignore
                                                    checked={visitorData.mandatory_status}
                                                    type="switch"
                                                    id="custom-switch"
                                                    color='var(--primary)'
                                                    onChange={() => handleswitch(visitorData)}
                                                />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <hr />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 mb-3'>
                        <div className='card'>
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="heading-color p-0 fw-400">
                                            {t('manageFrontDesk.guestRegistration')}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <hr className='m-0' />
                            </div>
                            <div className='card-body'>
                                <div className='row mx-1'>
                                    <div className='col-6'>
                                        <p className='mb-0 fw-600'>{t('manageFrontDesk.label')}</p>
                                    </div>
                                    <div className='col-6'>
                                        <p className='mb-0 fw-600'>{t('manageFrontDesk.mandatoryField')}</p>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <hr />
                                </div>
                                {guestdata.map((guestData, index) => (
                                    <div key={index}>
                                        <div className='row mx-1'>
                                            <div className='col-6'>
                                                <p className='mb-0'>{t(guestData.label_name)}</p>
                                            </div>
                                            <div className='col-6'>
                                                <Form.Check // prettier-ignore
                                                    checked={guestData.mandatory_status}
                                                    type="switch"
                                                    id="custom-switch"
                                                    color='var(--primary)'
                                                    onChange={() => handleswitch(guestData)}
                                                />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <hr />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Managefrontdesk