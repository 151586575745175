import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import avatar from 'assets/img/team/avatar.png';
import { useForm, Controller } from 'react-hook-form';
import pattern from 'components/configuration/form-pattern';
import toastConfig from 'components/configuration/toast-config';
import { toast } from 'react-toastify';
import InputPassword from 'components/common/InputPassword';
import { changePassword } from 'services/commonService'
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';

export default function Profile() {
  const [userData, setUserData] = useState({})
  const { t } = useTranslation()
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("userData")));
  }, [localStorage.getItem("userData")])

  const onSubmit = async formData => {
    console.log(formData);
    if (formData.newPassword == formData.confirmPassword) {
      try {
        // const { data } = await changePassword(formData)
        // toast.success(data.data.result, toastConfig);
        // // toast.success(t('Password change successfully'), toastConfig)
        // onReset()
      } catch (e) {
        errorMessage(e)
      }
    } else {
      toast.error(t('profile.error.passwordError'), toastConfig)
    }
  }
  const onReset = () => {
    reset()
  }

  return (
    <div className="card overfolw-hidden mb-3">
      <div className='mb-3'>
        <div className="card-header">
          <h3 className="mb-0  heading-color fw-600">
            {t('profile.heading.profile')}
          </h3>
          <div className=''>
            <hr />
          </div>
        </div>
        <div className='row card-body'>
          <div className='col-md-2'>
            <div className='text-center mb-3'>
              <img className='rounded-circle' src={userData.profile ? userData.profile : avatar} height={100} width={100} />
            </div>
          </div>
          <div className='col-md-10'>
            <div className='row'>
              <div className='col-md-4'>
                <label>
                  <b>
                    {t('profile.label.name')}
                    <span>:</span>
                  </b>
                </label>
                <p className='mb-3'>{userData.firstName ? userData.firstName : "---"}</p>
              </div>
              <div className='col-md-4'>
                <label>
                  <b>
                    {t('profile.label.birthday')}
                    <span>:</span>
                  </b>
                </label>
                <p className='mb-3'>{userData.dob ? userData.dob : "---"}</p>
              </div>
              <div className='col-md-4'>
                <label>
                  <b>
                    {t('profile.label.gender')}
                    <span>:</span>
                  </b>
                </label>
                <p className='mb-3'>{userData.gender ? userData.gender == 1 ? "Male" : userData.gender == 2 ? "Female" : userData.gender == 3 ? "Others" : "---" : "---"}</p>
              </div>
              <div className='col-md-4'>
                <label>
                  <b>
                    {t('profile.label.email')}
                    <span>:</span>
                  </b>
                </label>
                <p className='mb-3'>{userData.email ? userData.email : "---"}</p>
              </div>
              <div className='col-md-4'>
                <label>
                  <b>
                    {t('profile.label.mobileNo')}
                    <span>:</span>
                  </b>
                </label>
                <p className='mb-3'>{userData.contactNo ? <span>+{userData.countryCode}-{userData.contactNo}</span> : "---"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mb-3'>
        <div className="card-header">
          <h3 className="mb-0  heading-color fw-600">
            {t('profile.heading.changePassword')}
          </h3>
          <div className=''>
            <hr />
          </div>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          autoComplete="off"
        >
          <div className='row card-body'>
            <div className="form-group col-md-4 mb-3">
              <label className="form-label">
                {t('profile.label.currentPassword')}
                <span className="text-danger ms-1">*</span>
              </label>
              <Controller
                name="currentPassword"
                control={control}
                rules={{ required: true, pattern: pattern.password }}
                render={({ field: { value, onChange } }) => (
                  <InputPassword
                    value={value}
                    onChange={onChange}
                    isInvalid={errors.currentPassword ? true : false}
                    placeholder='profile.placeholder.currentPassword'
                  />
                )}
              />
              {errors.currentPassword && (
                <small>
                  {errors.currentPassword.type === 'required' && (
                    <span className="text-danger">
                      {t('profile.error.currentPasswordError')}
                    </span>
                  )}
                  {errors.currentPassword.type === 'pattern' && (
                    <span className="text-danger">
                      {t('profile.error.passwordPatternError')}
                    </span>
                  )}
                </small>
              )}
            </div>
            <div className="form-group col-md-4 mb-3">
              <label className="form-label">
                {t('profile.label.newPassword')}
                <span className="text-danger ms-1">*</span>
              </label>
              <Controller
                name="newPassword"
                control={control}
                rules={{ required: true, pattern: pattern.password }}
                render={({ field: { value, onChange } }) => (
                  <InputPassword
                    value={value}
                    onChange={onChange}
                    isInvalid={errors.newPassword ? true : false}
                    placeholder='profile.placeholder.newPassword'
                  />
                )}
              />
              {errors.newPassword && (
                <small>
                  {errors.newPassword.type === 'required' && (
                    <span className="text-danger">
                      {t('profile.error.newPasswordError')}
                    </span>
                  )}
                  {errors.newPassword.type === 'pattern' && (
                    <span className="text-danger">
                      {t('profile.error.passwordPatternError')}
                    </span>
                  )}
                </small>
              )}
            </div>
            <div className="form-group col-md-4 mb-3">
              <label className="form-label">
                {t('profile.label.confirmPassword')}
                <span className="text-danger ms-1">*</span>
              </label>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{ required: true, pattern: pattern.password }}
                render={({ field: { value, onChange } }) => (
                  <InputPassword
                    value={value}
                    onChange={onChange}
                    isInvalid={errors.confirmPassword ? true : false}
                    placeholder='profile.placeholder.confirmPassword'
                  />
                )}
              />
              {errors.confirmPassword && (
                <small>
                  {errors.confirmPassword.type === 'required' && (
                    <span className="text-danger">
                      {t('profile.error.confirmPasswordError')}
                    </span>
                  )}
                  {errors.confirmPassword.type === 'pattern' && (
                    <span className="text-danger">
                      {t('profile.error.passwordPatternError')}
                    </span>
                  )}
                </small>
              )}
            </div>
            <div className="form-group">
              <button className="btn btn-primary" type="submit">
                {t('assetMaster.addAsset.label.submitBtn')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
