import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import countryCodes, {
    defaultCountryCode
} from 'components/configuration/country-code';
import pattern from 'components/configuration/form-pattern';
import ReactDatePicker from 'components/common/ReactDatePicker';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { genderOptions } from 'components/configuration/common-options';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import ReactTable from 'components/common/ReactTable';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import PhoneNumber from 'components/common/PhoneNumber';
import formPattern from 'components/configuration/form-pattern';
import Mobile from 'components/common/Mobile';
import { casualworkerslist, listsupplier } from 'services/adminmoduleService'
import CaptureDocumentModal from '../../../common/CaptureDocumentModal';
import CapturePhotoModal from '../../../common/CapturePhotoModal';
import '../../../../assets/css/general.css'
import { casualworkeradd, casualworkerdelete, dropdownvisitor, dropdowncompanytype, getListOfDepartment } from 'services/adminmoduleService'
import axios from 'axios';
import { commonScanDocEndPoints } from 'services/config';
import Swalcolor from 'components/configuration/sweetalert-config';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import { useContext } from 'react';
import { verifyAccessCard } from 'services/commonService';
import '../casualworkers/casual.css'



export default function CasualWorkers() {

    const [visitorType, setVisitorType] = useState([])
    const [docType, setDocType] = useState([{ documentName: "National ID", id: "1" }, { documentName: "Alien ID", id: "2" }, { documentName: "Passport", id: "3" }, { documentName: "Driving License", id: "5" }, { documentName: "Company ID", id: "6" }, { documentName: "Other Documents", id: "4" }])
    const [identitySection, setIdentitySection] = useState(true);
    const [submitPress, setSubmitPress] = useState(false)
    const params = useParams();
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [isCaptureFrontDocModalOpen, setIsCaptureFrontDocModalOpen] = useState(false)
    const [isCaptureBackDocModalOpen, setIsCaptureBackDocModalOpen] = useState(false)
    const [isCapturePhotoModalOpen, setIsCapturePhotoModalOpen] = useState(false);
    const [userPhoto, setUserPhoto] = useState("");
    const [backDocPhoto, setBackDocPhoto] = useState("");
    const [frontDocPhoto, setFrontDocPhoto] = useState("");
    const [companyType, setCompanyType] = useState([])
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const [frontdocerror, setFrontDocError] = useState(false)
    const [backdocerror, setBackDocError] = useState(false)





    const [casualworkerData, setCasualWorkerData] = useState([]);
    const navigate = useNavigate();
    const [countryCode, setCountryCode] = useState(defaultCountryCode);
    const [departmentList, setDepartmentList] = useState([]);
    //selected host type for members encoding added by parth
    const [selectedHostType, setSelectedHostType] = useState();

    //handle selected host type for members encoding by parth
    const handleSelectHostType = (event) => {
        setSelectedHostType(event.target.value);
    };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        setError,
        watch,
    } = useForm();

    console.log("casualdata", casualworkerData)

    const isMobile = navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i) ? true : false


    const documentType = watch("doc_id_type");
    // useEffect(() => {
    //     if (documentType) {
    //         setFrontDocPhoto("");
    //         setBackDocPhoto("");
    //         setValue("name", "")
    //         setValue("doc_id_number", "")
    //         setValue("nationality", "")
    //         setValue("dob", "")
    //         setValue("gender", "")
    //     }
    // }, [documentType])

    useEffect(async () => {
        checkModulePermission("Casual Workers")
        setIsLoading(true)
        try
        {
            const { data } = await casualworkerslist();
            // const { data: visitortype } = await dropdownvisitor();
            // const { data: company } = await dropdowncompanytype()
            const { data: company } = await listsupplier();
            const { data: department } = await getListOfDepartment()
            setCasualWorkerData(data?.data);
            setCompanyType(company?.data?.filter((comp) => comp?.status == "Active" && comp?.company_type == "Contractor").map((filterData) => ({ label: filterData?.company_name, value: filterData?.id })))
            setDepartmentList(department?.data?.map((dep) => ({ label: dep.name, value: dep.id })))

            // setVisitorType(visitortype?.data?.map((visit) => ({ label: visit.name, value: visit.id })))
            // setCompanyType(company?.data?.map((visit) => ({ label: visit.name, value: visit.id })))
            // console.log(data.data.results);
        } catch (e)
        {
            errorMessage(e)
        }
        setIsLoading(false)
    }, []);


    useEffect(() => {
        if (frontDocPhoto != "")
        {
            setFrontDocError(false)
        }
        if (backDocPhoto != "")
        {
            setBackDocError(false)
        }
    }, [frontDocPhoto, backDocPhoto])

    const onEditRow = (id) => {
        const newData = casualworkerData?.find((casual) => casual.id === id)
        navigate(
            `/editcasualworkers`, { state: newData }
            // `/masters/access-control/editaccesscontroll/${encodeData(rowdata)}`
        );
    };
    const onDeleteRow = (id) => {
        Swal.fire({
            title: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationTitle'
            ),
            text: t('departmentMaster.deleteDepartment.sweetAlert.confirmationText'),
            icon: 'warning',
            showCancelButton: Swalcolor.confirmation.showCancelButton,
            confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
            cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
            confirmButtonText: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationBtnLabel'
            )
        }).then(async result => {
            if (result.isConfirmed)
            {
                setIsLoading(true)
                try
                {
                    const { data } = await casualworkerdelete({ id });
                    console.log(data);
                    setCasualWorkerData(casualworkerData.filter(data => data.id != id));
                    Swal.fire({
                        icon: 'success',
                        title: t(
                            'departmentMaster.deleteDepartment.sweetAlert.simpleTitle'
                        ),
                        text: data.data.result,
                        confirmButtonColor: Swalcolor.simple.confirmButtonColor
                    });
                } catch (e)
                {
                    errorMessage(e)
                }
                setIsLoading(false)
            }
        });
    };


    const handleCapture = (target) => {
        if (target.files)
        {
            if (target.files.length !== 0)
            {
                const file = target.files[0];
                const newUrl = URL.createObjectURL(file);
                setUserPhoto(newUrl);
            }
        }
    };

    const setProfilePhoto = (data) => {
        setUserPhoto(data);
    }
    const setFrontDocImage = async (data) => {
        setFrontDocPhoto(data);
    }
    const setBackDocImage = async (data) => {
        setBackDocPhoto(data)
    }

    const frontsideClick = () => {
        if (documentType && documentType != '')
        {
            setIsCaptureFrontDocModalOpen(true)
        } else
        {
            toast.error("Please select ID Type first.", toastConfig)
        }
    }
    const backsideClick = () => {
        if (documentType && documentType != '')
        {
            setIsCaptureBackDocModalOpen(true)
        } else
        {
            toast.error("Please select ID Type first.", toastConfig)
        }
    }
    const getStatusBackGrounColor = (row) => {
        if (moment().startOf("day").toDate() <= moment(row?.original?.expiry_date).startOf("day").toDate() && moment().add(3, "days").startOf("day").toDate() >= moment(row?.original?.expiry_date).startOf("day").toDate())
        {
            return "var(--warning)"
        } else if (row?.original?.status == "Active")
        {
            return "var(--success)"
        } else
        {
            return "var(--danger)"
        }
    }
    const columns = [

        {
            Header: t('casualworkers.casualworkertable.no'),
            accessor: (row, index) => index + 1,
        },
        {
            Header: t('casualworkers.casualworkertable.fullname'),
            accessor: 'name'
        },
        {
            Header: t('casualworkers.casualworkertable.mobileno'),
            accessor: 'phone',
            Cell: ({ row }) => {
                return row?.original?.phone ? `${row?.original?.country_code}-${row?.original?.phone}` : '--'
            }
        },
        {
            Header: t('casualworkers.casualworkertable.idnumber'),
            accessor: 'doc_id_number'
        },
        {
            Header: t('casualworkers.casualworkertable.idtype'),
            accessor: 'identification_type'
        },
        {
            Header: t('casualworkers.casualworkertable.accesscardno'),
            accessor: 'access_card_no',
            Cell: ({ row }) => {
                return row?.original?.access_card_no ? row?.original?.access_card_no : '--'
            }
        },
        {
            Header: t('casualworkers.casualworkertable.status'),
            accessor: 'status',
            Cell: ({ row }) => {
                return <div className='text-center' style={{ backgroundColor: getStatusBackGrounColor(row), paddingLeft: "5px", paddingRight: "5px", borderRadius: "12px", color: "var(--white)" }}>{row?.original?.status}</div>
            },
        },
        {
            Header: t('casualworkers.casualworkertable.createdby'),
            accessor: 'created_by'
        },
        {
            Header: t('casualworkers.casualworkertable.createdon'),
            accessor: 'created_on',
            Cell: ({ row }) => (
                <div>
                    {moment.utc(row?.original?.created_on).local().format(process.env.REACT_APP_DATE_TIME_FORMAT)}
                </div>
            ),
        },
        {
            Header: t('casualworkers.casualworkertable.action'),
            Cell: ({ row }) => (
                <div>
                    <span
                        className="btn p-0 me-2"
                        title="Edit"
                        onClick={() => onEditRow(row.original.id)}
                    >
                        <FontAwesomeIcon icon="edit" />
                    </span>
                    <span
                        className="btn p-0"
                        title="Delete"
                        onClick={() => onDeleteRow(row.original.id)}
                    >
                        <FontAwesomeIcon icon="trash-alt" />
                    </span>
                </div>
            ),
            disableSortBy: true
        },

    ];

    useEffect(() => {
        console.log("doctype", documentType)
        if (documentType == "1" || documentType == "2" || documentType == "3")
        {
            if (documentType != "3")
            {
                if (frontDocPhoto && frontDocPhoto != "" && backDocPhoto && backDocPhoto != "")
                {
                    getDataFrontDocument()
                }
            } else
            {
                if (frontDocPhoto && frontDocPhoto != "")
                {
                    getDataFromPassport()
                }
            }
        }

    }, [frontDocPhoto, backDocPhoto])

    const getDataFromPassport = async () => {
        setIsLoading(true)
        try
        {
            const frontImg = frontDocPhoto
            const { data } = await axios.post(commonScanDocEndPoints.imageIdentity, { front: frontImg.split(',')[1], type: documentType }, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            const documentData = data?.data[0]?.document_data;
            setValue("gender", genderOptions.find((gender) => gender.value === documentData?.gender))
            setValue("name", documentData?.name)
            setValue("doc_id_number", documentData?.id_card_number)
            setValue("nationality", documentData?.nationality)
            setValue("dob", moment(documentData?.dob, "DD-MM-YYYY").toDate())
            clearErrors("name")
            clearErrors("doc_id_number")
            clearErrors("nationality")
            clearErrors("dob")
            clearErrors("gender")
        } catch (e)
        {
            if (e.response.data)
            {
                if (Array.isArray(e.response.data.message))
                {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else
                {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else
            {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }

    const getDataFrontDocument = async () => {
        setIsLoading(true)
        try
        {
            const frontImg = frontDocPhoto
            const backImg = backDocPhoto
            console.log("front img", frontImg)
            const { data } = await axios.post(commonScanDocEndPoints.imageIdentity, { front: frontImg.split(',')[1], back: backImg.split(',')[1], type: documentType }, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            const documentData = data?.data[0]?.document_data;
            setValue("gender", genderOptions.find((gender) => gender.value === documentData?.gender))
            setValue("name", documentData?.name)
            setValue("doc_id_number", documentData?.id_card_number)
            setValue("nationality", documentData?.nationality)
            setValue("dob", moment(documentData?.dob, "DD-MM-YYYY").toDate())
            clearErrors("name")
            clearErrors("doc_id_number")
            clearErrors("nationality")
            clearErrors("dob")
            clearErrors("gender")
        } catch (e)
        {
            if (e.response.data)
            {
                if (Array.isArray(e.response.data.message))
                {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else
                {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else
            {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }

    const resetByPassVisitor = () => {
        setProfilePhoto("")
        setFrontDocPhoto("")
        setBackDocPhoto("")
        setValue("gender", " ")
        setValue("company_name", " ")
        setValue("visitor_type_id", " ")
        setValue('department_id', '')
        setCountryCode(defaultCountryCode)
        reset();
    };


    const onSubmitVisitorData = async (visitorData) => {
        setIsLoading(true)
        let isError = false;
        if (documentType == "3" && frontDocPhoto == "")
        {
            setFrontDocError(true)
            isError = true
        }
        else if (documentType != "3")
        {
            if (frontDocPhoto == "")
            {
                setFrontDocError(true);
                isError = true
            }
            if (backDocPhoto == "")
            {
                setBackDocError(true);
                isError = true
            }
        }
        if (!isError)
        {
            try
            {
                console.log("visitorData", visitorData)

                // let response = await fetch("assets/img/team/2.jpg");
                // let filedata = await response.blob();
                // let metadata = {
                //     type: type
                // };
                // const newFile = new File([filedata], "profile.jpg", metadata);
                // visitorData.destination_id = visitorData?.destination_id?.value;
                // visitorData.email = email;
                // visitorData.phone = phoneNumber;

                visitorData.gender = visitorData?.gender?.label;
                visitorData.company_name = visitorData?.company_name?.value
                visitorData.department_id = visitorData?.department_id?.value
                visitorData.visitor_type_id = 4
                visitorData.profile_photo = userPhoto;
                visitorData.front = frontDocPhoto;
                visitorData.back = backDocPhoto;
                if (visitorData?.dob)
                {
                    visitorData.dob = moment(visitorData?.dob).format(process.env.REACT_APP_DATE_FORMAT)
                }
                if (visitorData?.expiry_date)
                {
                    visitorData.expiry_date = moment(visitorData.expiry_date).format(process.env.REACT_APP_DATE_FORMAT)
                }
                visitorData.country_code = countryCode?.dial_code;
                const { data } = await casualworkeradd(visitorData)
                console.log("==", data?.data)
                const newData = data?.data[0]?.casual_workers_detail
                setCasualWorkerData([newData, ...casualworkerData])
                reset();
                setValue("gender", "")
                setValue("company_name", "")
                setValue("department_id", "")
                setProfilePhoto("")
                setFrontDocPhoto("")
                setBackDocPhoto("")
                toast.success(data?.message, toastConfig)

            } catch (e)
            {
                if (e.response.data)
                {
                    if (Array.isArray(e.response.data.message))
                    {
                        e.response.data.message.map(errors => toast.error(errors, toastConfig))
                    } else
                    {
                        toast.error(e.response.data.message, toastConfig)
                    }
                } else
                {
                    toast.error(e.message, toastConfig)
                }
            }
        }
        setIsLoading(false)
        // setSubmitPress(true)
        // try {
        //     visitorData.visitorCountryCode = countryCode.dial_code.slice(1, countryCode.dial_code.length);
        //     visitorData.gender = visitorData.gender.value;
        //     visitorData.employeeId = visitorData.employeeId.value;
        //     visitorData.companyId = visitorData.companyId.value;
        //     visitorData.departmentId = visitorData.departmentId.value;
        //     visitorData.visitorType = visitorData.visitorType.value;
        //     visitorData.visitorBirthDate = moment(visitorData.visitorBirthDate).format(
        //         'YYYY-MM-DD'
        //     );
        //     visitorData.assets = listOfAssets.map(asset => {
        //         delete asset.id;
        //         return asset;
        //     });
        //     setAllGuestData(...allGuestData)
        //     const { data } = await addVisitorRegistration(visitorData);
        //     resetByPassVisitor();
        //     toast.success(data.data.result, toastConfig);
        // } catch (e) {
        //     toast.error(e.message, toastConfig);
        // }
    };

    const getAccessCardNumber = async () => {
        setIsLoading(true)
        try
        {
            const { data } = await verifyAccessCard({ host_type: selectedHostType })
            setValue("access_card_no", data?.data[0]?.access_card_no)
            clearErrors("access_card_no")
        } catch (e)
        {
            if (e.response.data)
            {
                if (Array.isArray(e.response.data.message))
                {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else
                {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else
            {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const setTrProps = (row) => {
        console.log(row)
        return {
            style: (moment().startOf("day").toDate() <= moment(row?.original?.expiry_date).startOf("day").toDate() && moment().add(3, "days").startOf("day").toDate() >= moment(row?.original?.expiry_date).startOf("day").toDate()) ? {
                backgroundColor: "#FF0000",
                color: "#FFFFFF"
            } : {}
        }
    }


    const resetDocumentPhotos = () => {
        setFrontDocPhoto('');
        setBackDocPhoto('');
        setValue('dob', '');
        setValue('gender', '');
        setValue('doc_id_number', '')
        setValue('nationality', '')
        setValue('name', '')
    };

    const handleDocTypeChange = () => {
        resetDocumentPhotos();
    };

    return (
        <>
            <form
                encType="multipart/form-data"
                autoComplete="off"
                onSubmit={handleSubmit(onSubmitVisitorData)}
                className="mainn-registerguest"
            >

                <div className="row ">
                    <div>
                        <div className="card overfolw-hidden mb-3">
                            <div className="card-header pb-0">
                                <div className="row">
                                    <h3 className="heading-color fw-600">
                                        {t('casualworkers.heading')}
                                    </h3>
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div>
                                    <div className="row m-0 pt-3 pb-1">
                                        <div className="form-group p-0 col-12 ">
                                            <label>
                                                {t('casualworkers.phone')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <div className="d-flex flex-wrap p-0 col-12 gap-3">
                                                <div className='d-flex' style={{ flexDirection: "column" }}>
                                                    <Controller
                                                        name="phone"
                                                        control={control}
                                                        rules={{ required: true, pattern: pattern.phoneNumber }}
                                                        render={({ field: { onChange, value } }) => (
                                                            <PhoneNumber
                                                                dropdownDirection="bottom"
                                                                value={value}
                                                                onChange={onChange}
                                                                countryCode={countryCode}
                                                                setCountryCode={setCountryCode}
                                                                error={errors.phone}
                                                                isInputVisible={true}
                                                            />
                                                            // <PhoneNumber
                                                            //     phoneNumber={value}
                                                            //     email={email}
                                                            //     setPhoneNumber={onChange}
                                                            //     firstError={errors.phone}
                                                            //     countryCode={countryCode}
                                                            //     firstPlaceholder={t("casualworkers.mobileno")}
                                                            //     setCountryCode={setCountryCode}
                                                            //     dropdownDirection="bottom"
                                                            //     error={errors.phone}
                                                            //     saperate={true}
                                                            //     wantemail={false}
                                                            //     disableboth={location?.state?.isAppointment === true ? true : false}
                                                            //     formFieldsDisabled={location?.state?.isAppointment === true ? true : false}
                                                            // />
                                                        )}
                                                    />
                                                    {errors.phone && (
                                                        <>
                                                            <small>
                                                                {errors.phone.type === 'required' && (
                                                                    <span className="text-danger">
                                                                        {t(
                                                                            'casualworkers.casualworkererror.mobileno'
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </small>
                                                            <small>
                                                                {errors.phone.type === 'pattern' && (
                                                                    <span className="text-danger">
                                                                        {t(`casualworkers.casualworkererror.mobileinvalid`)}
                                                                    </span>
                                                                )}
                                                            </small>
                                                        </>
                                                    )}
                                                </div>
                                            </div>



                                            {/* <div>
                        <label className='pt-2' style={{ color: 'var("--primary")' }}>
                          {t('guestRegistration.guestVerification.error.emailReq')}
                        </label>
                      </div> */}
                                        </div>
                                        {/* {errors.visitorContactNo && (
                                                <small className="ps-0">
                                                    {errors.visitorContactNo.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('visitorRegistration.error.phoneNoReq')}
                                                        </span>
                                                    )}
                                                    {errors.visitorContactNo.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('visitorRegistration.error.phoneNoInvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )} */}
                                    </div>
                                </div>
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row g-3 mb-3">
                    <div className='table-body'>
                        <div className="card overfolw-hidden mb-3">
                            {/* <div className="card-header pb-0">
                                <div className="row">
                                    <h3 className="heading-color fw-600">
                                        {t('casualworkers.heading')}
                                    </h3>
                                    <div className="col-12">
                                        <hr />
                                    </div>
                                </div>
                            </div> */}
                            <div className="card-body pt-0">
                                {/* <form
                encType="multipart/form-data"
                autoComplete="off"
                onSubmit={handleSubmit(onSubmitVisitorData)}
              > */}
                                <div className="row  nationality-section">
                                    {identitySection && <div
                                        className="col-lg-12 col-xl-4 col-xxl-6  rounded-3"
                                        style={{ paddingTop: "1rem" }}
                                    >
                                        <div className="documentid-data col-12">
                                            <div className="row align-items-center">
                                                {<div className="col-lg-12 col-xl-12">
                                                    <label className="form-label">
                                                        {t('casualworkers.casualworkerformlabel.selectidtype')}
                                                        <span className="text-danger ms-1">*</span>
                                                    </label>
                                                    <div className='form-group mb-3 mb-sm-0'>
                                                        {docType.map((docType, index) => (
                                                            <div className="form-check form-check-inline" key={docType.id}>
                                                                <input
                                                                    className="form-check-input"
                                                                    // checked={index === 0 && true}
                                                                    type="radio"
                                                                    value={docType.id}
                                                                    onClick={() => handleDocTypeChange()}
                                                                    {...register('doc_id_type', {
                                                                        required: true
                                                                    })}
                                                                />
                                                                <label className="form-check-label">
                                                                    {docType.documentName}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {errors.doc_id_type && (
                                                        <small>
                                                            {errors.doc_id_type.type === 'required' && (
                                                                <span className="text-danger">
                                                                    {t(
                                                                        'casualworkers.casualworkererror.iderror'
                                                                    )}
                                                                </span>
                                                            )}
                                                        </small>
                                                    )}
                                                </div>}

                                            </div>
                                        </div>
                                    </div>}


                                    {isMobile && <input
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="icon-button-file"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => handleCapture(e.target)}
                                    />}
                                    <div className="col-lg-12 col-xl-8 col-xxl-6 mt-sm-2" style={{ marginLeft: "-1rem" }}>
                                        <div className="row g-3">
                                            <div className=" col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-4 mt-3 mt-sm-0 mt-xl-4 d-flex justify-content-center">
                                                <label htmlFor='icon-button-file'>
                                                    <div className="scan-box-visitor" onClick={() => setIsCapturePhotoModalOpen(true)}>
                                                        <img
                                                            src={`${userPhoto != "" ? userPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/user.png`}`}
                                                            style={{ maxWidth: '80px', height: "100%", width: "100%", aspectRatio: "auto" }}
                                                        />
                                                        <h4>{t('casualworkers.casualworkerformlabel.capture')}</h4>
                                                    </div>
                                                </label>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-4 mt-3 mt-sm-0 mt-xl-4 d-flex justify-content-center">
                                                <div>
                                                    <div className="scan-box-visitor" onClick={() => frontsideClick()}>
                                                        <img
                                                            src={`${frontDocPhoto != "" ? frontDocPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/scanner.png`}`}
                                                            style={{ aspectRatio: "auto", objectFit: 'contain' }}
                                                        />
                                                        <h4>{documentType != "3" ? t('casualworkers.casualworkerformlabel.scanfront') : t('casualworkers.casualworkerformlabel.scanDoc')}</h4>
                                                    </div>
                                                    {frontdocerror && (
                                                        <small className='frontdocerror'>
                                                            <span className="text-danger">
                                                                {t('commondocerror.frontdocerror')}
                                                                {/* Front Doc is required. */}
                                                            </span>
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                            {documentType != "3" && <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-4 mt-3 mt-sm-0 mt-xl-4 d-flex justify-content-center">
                                                <div>
                                                    <div className="scan-box-visitor" onClick={() => backsideClick()}>
                                                        <img
                                                            src={`${backDocPhoto != "" ? backDocPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/scanner.png`}`}
                                                            style={{ objectFit: 'contain', aspectRatio: "auto" }}
                                                        />
                                                        <h4>{t('casualworkers.casualworkerformlabel.scanback')}</h4>
                                                    </div>
                                                    {backdocerror && (
                                                        <small className='frontdocerror'>
                                                            <span className="text-danger">
                                                                {t('commondocerror.backdocerror')}
                                                                {/* Back Doc is required. */}
                                                            </span>
                                                        </small>
                                                    )}
                                                </div>
                                            </div>}
                                        </div>
                                    </div>

                                    {/* <div className="col-lg-6 mt-3 mt-sm-0">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="scan-box">
                                                    <img
                                                        src=`${process.env.REACT_APP_BASEROUTE_URL}/admin-images/user.png`
                                                        className="img-fluid"
                                                        style={{ maxWidth: '10rem' }}
                                                    />
                                                    <h4 className='scan-box-text'>{t('casualworkers.casualworkerformlabel.capture')}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mt-3 mt-sm-0">
                                                <div className="scan-box">
                                                    <img
                                                        src=`${process.env.REACT_APP_BASEROUTE_URL}/admin-images/scanner.png`
                                                        className="img-fluid"
                                                        style={{ maxWidth: '10rem' }}
                                                    />
                                                    <h4 className='scan-box-text'>{t('casualworkers.casualworkerformlabel.scanfront')}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mt-3 mt-sm-0">
                                                <div className="scan-box">
                                                    <img
                                                        src=`${process.env.REACT_APP_BASEROUTE_URL}/admin-images/scanner.png`
                                                        className="img-fluid"
                                                        style={{ maxWidth: '10rem' }}
                                                    />
                                                    <h4 className='scan-box-text'>{t('casualworkers.casualworkerformlabel.scanback')}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>

                                <div className="col-md-12 mb-3">
                                    <div className='container-fluid'>
                                        <div className="row">

                                            <div className="col-xl-3 col-lg-4 col-md-4 mb-3">
                                                <label className="form-label">
                                                    {t('casualworkers.casualworkerformlabel.documentid')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                                <input
                                                    className={`form-control ${errors.doc_id_number && 'is-invalid'
                                                        }`}
                                                    type="text"
                                                    {...register('doc_id_number', {
                                                        required: true,
                                                        pattern: pattern.numberAndAlphabet
                                                    })}
                                                />
                                                {errors.doc_id_number && (
                                                    <small>
                                                        {errors.doc_id_number.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t(
                                                                    'casualworkers.casualworkererror.documentiderror'
                                                                )}
                                                            </span>
                                                        )}
                                                        {errors.doc_id_number.type === 'pattern' && (
                                                            <span className="text-danger">
                                                                {t(
                                                                    'casualworkers.casualworkererror.seconddociderror'
                                                                )}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>

                                            <div className="form-group col-xl-3 col-lg-4 col-md-4 mb-3">
                                                <label>
                                                    {t('casualworkers.casualworkerformlabel.nationality')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                                <input
                                                    className={`form-control ${errors.nationality && 'is-invalid'
                                                        }`}
                                                    type="text"
                                                    // disabled
                                                    {...register('nationality', {
                                                        required: true,
                                                        pattern: pattern.notAllowSpaceInStart
                                                    })}
                                                />
                                                {errors.nationality && (
                                                    <small>
                                                        {errors.nationality.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('casualworkers.casualworkererror.nationalityerror')}
                                                            </span>
                                                        )}
                                                        {errors.nationality.type === 'pattern' && (
                                                            <span className="text-danger">
                                                                {t('casualworkers.casualworkererror.secondnationalityerror')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>

                                            <div className="form-group col-xl-3 col-lg-4 col-md-4 mb-3">
                                                <label>
                                                    {t('casualworkers.casualworkerformlabel.fullname')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                                <input
                                                    className={`form-control ${errors.name && 'is-invalid'
                                                        }`}
                                                    type="text"
                                                    {...register('name', {
                                                        required: true,
                                                        pattern: pattern.notAllowSpaceInStart
                                                    })}
                                                />
                                                {errors.name && (
                                                    <small>
                                                        {errors.name.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('casualworkers.casualworkererror.fullnameerror')}
                                                            </span>
                                                        )}
                                                        {errors.name.type === 'pattern' && (
                                                            <span className="text-danger">
                                                                {t('casualworkers.casualworkererror.secondfullnameerror')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>

                                            <div className="form-group col-xl-3 col-lg-4 col-md-4 mb-3">
                                                <label>
                                                    {t('casualworkers.casualworkerformlabel.dateofbirth')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                                <Controller
                                                    name="dob"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange } }) => (
                                                        <ReactDatePicker
                                                            value={value}
                                                            onChange={onChange}
                                                            placeholder={t(
                                                                'casualworkers.date'
                                                            )}
                                                            hasErrors={errors.dob}
                                                            maxDate={moment().toDate()}
                                                            dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                                                        // formFieldsDisabled={formFieldsDisabled}
                                                        />
                                                    )}
                                                />
                                                {/* <input
                                                    className={`form-control ${errors.dob && 'is-invalid'
                                                        }`}
                                                    type="text"
                                                    {...register('dob', {
                                                        required: true,
                                                        pattern: pattern.numberAndAlphabet
                                                    })}
                                                /> */}

                                                {errors.dob && (
                                                    <small>
                                                        {errors.dob.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('casualworkers.casualworkererror.dateofbirtherror')}
                                                            </span>
                                                        )}
                                                        {errors.dob.type === 'pattern' && (
                                                            <span className="text-danger">
                                                                {t('casualworkers.casualworkererror.seconddoberror')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <div className='container-fluid'>
                                        <div className="row">



                                            <div className="form-group col-xl-3 col-lg-4 col-md-4 mb-3">
                                                <label>
                                                    {t('casualworkers.casualworkerformlabel.gender')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>

                                                <Controller
                                                    name="gender"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange } }) => (
                                                        <SearchableDropdown
                                                            value={value}
                                                            onChange={onChange}
                                                            options={genderOptions}
                                                            // placeholder={t(
                                                            //     'visitorRegistration.placeholder.gender'
                                                            // )}
                                                            hasError={errors.gender}
                                                        />
                                                    )}
                                                />
                                                {errors.gender && (
                                                    <small>
                                                        {errors.gender.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('casualworkers.casualworkererror.gendererror')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>

                                            <div className="form-group col-xl-3 col-lg-4 col-md-4 mb-3">
                                                <label>
                                                    {t('casualworkers.casualworkerformlabel.companytype')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                                <Controller
                                                    name="company_name"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange } }) => (
                                                        <SearchableDropdown
                                                            value={value}
                                                            onChange={onChange}
                                                            options={companyType}
                                                            hasError={errors.company_name}
                                                            placeholder={t('casualworkers.companytype')}
                                                        />
                                                    )}
                                                />
                                                {errors.company_name && (
                                                    <small>
                                                        {errors.company_name.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('casualworkers.casualworkererror.companyerror')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>

                                            <div className="form-group col-xl-3 col-lg-4 col-md-4 mb-3">
                                                <label>
                                                    {t('casualworkers.casualworkerformlabel.department')}
                                                    <span className="text-danger ms-1">*</span>
                                                </label>
                                                <Controller
                                                    name="department_id"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field: { value, onChange } }) => (
                                                        <SearchableDropdown
                                                            value={value}
                                                            onChange={onChange}
                                                            options={departmentList}
                                                            hasError={errors.department_id}
                                                            placeholder={t('casualworkers.department')}
                                                        />
                                                    )}
                                                />
                                                {errors.department_id && (
                                                    <small>
                                                        {errors.department_id.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('casualworkers.casualworkererror.departmenterror')}
                                                            </span>
                                                        )}
                                                        {/* {errors.departmentName.type === 'pattern' && (
                                                <span className="text-danger">
                                                    {t('guestRegistration.guestVerification.error.validName')}
                                                </span>
                                            )} */}
                                                    </small>
                                                )}
                                            </div>

                                            <div className="form-group col-xl-3 col-lg-4 col-md-4 mb-3">
                                                <label>
                                                    {t('casualworkers.casualworkerformlabel.refrenceno')}
                                                </label>
                                                <input
                                                    className={`form-control ${errors.referenceno && 'is-invalid'
                                                        } `}
                                                    type="text"
                                                    {...register('referenceno', { pattern: pattern.numberAndAlphabet })}
                                                />

                                                {errors.referenceno && (
                                                    <small>
                                                        {errors.referenceno.type === 'pattern' && (
                                                            <span className="text-danger">
                                                                {t('casualworkers.casualworkererror.refrenceerror')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>



                                <div className='col-md-12'>
                                    <div className='container-fluid'>
                                        <div className='row'>

                                            <div className="form-group col-xl-3 col-lg-4 col-md-4 mb-3">
                                                <label>
                                                    {t('casualworkers.casualworkerformlabel.accesscardno')}
                                                </label>
                                                <input
                                                    className='form-control'
                                                    type="text"
                                                    {...register('access_card_no')}
                                                />

                                                {/* {errors.accesscardno && (
                                                    <small>
                                                        {errors.accesscardno.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('visitorRegistration.error.birthDateReq')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )} */}
                                            </div>

                                            <div className="form-group col-xl-3 col-lg-4 col-md-4 mb-3">
                                                <label>
                                                    {t('casualworkers.casualworkerformlabel.visitortype')}
                                                </label>
                                                <Controller
                                                    name="visitor_type_id"
                                                    control={control}
                                                    // rules={{ required: true }}
                                                    render={({ field: { value, onChange } }) => (
                                                        // <input
                                                        //     className='form-control'
                                                        //     value={value}
                                                        //     onChange={onChange}
                                                        //     options={visitorType}
                                                        //     placeholder='casual workers'
                                                        //     disabled
                                                        //     {...register('visitortype')}
                                                        // />
                                                        <SearchableDropdown
                                                            value={value}
                                                            onChange={onChange}
                                                            options={visitorType}
                                                            formFieldsDisabled={true}
                                                            placeholder='casual worker'
                                                        />
                                                    )}
                                                />
                                                {/* {errors.gender && (
                                                    <small>
                                                        {errors.gender.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('visitorRegistration.error.genderReq')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )} */}
                                            </div>

                                            <div className="form-group col-xl-3 col-lg-4 col-md-4 mb-3">
                                                <label>
                                                    {t('casualworkers.casualworkerformlabel.expirydate')}
                                                </label>
                                                <Controller
                                                    name="expiry_date"
                                                    control={control}
                                                    // rules={{ required: true }}
                                                    render={({ field: { value, onChange } }) => (
                                                        <ReactDatePicker
                                                            value={value}
                                                            onChange={onChange}
                                                            placeholder={t(
                                                                'systemuser.createsystemuserformplaceholder.selectdate'
                                                            )}
                                                            // hasErrors={errors.expirydate}
                                                            // maxDate={moment().toDate()}
                                                            minDate={moment().toDate()}
                                                            dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}

                                                        />
                                                    )}
                                                />
                                                {/* <input
                                                    className='form-control'
                                                    type="text"
                                                    {...register('visitorName', {
                                                        required: true,
                                                        pattern: pattern.fullName
                                                    })}
                                                /> */}

                                                {/* {errors.expirydate && (
                                                    <small>
                                                        {errors.expirydate.type === 'required' && (
                                                            <span className="text-danger">
                                                                {t('visitorRegistration.error.birthDateReq')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )} */}
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-12">
                                    <div className="row justify-content-between">
                                        <div className="col-12 d-flex justify-content-end form-group mb-3">
                                            <div className="d-flex gap-2">
                                                <select value={selectedHostType} onChange={handleSelectHostType} className="form-control mt-auto mb-auto">
                                                    <option value="">Select Option</option>
                                                    <option value="2">Guest Gate</option>
                                                    <option value="3">Members Only Gate</option>
                                                    <option value="4">Serengeti Gate</option>
                                                    <option value="5">Control Room</option>
                                                    <option value="6">Mail Room</option>
                                                </select>
                                                <button
                                                    className="btn btn-primary btn-lg"
                                                    type="button"
                                                    onClick={() => getAccessCardNumber()}
                                                >
                                                    {t('casualworkers.casualworkerformlabel.verifyCard')}
                                                </button>
                                                <button
                                                    className="btn btn-primary btn-lg"
                                                    type="submit"
                                                    onClick={() => setSubmitPress(true)}
                                                >
                                                    {t('casualworkers.casualworkerformlabel.save')}
                                                </button>
                                                <button
                                                    className="btn btn-danger btn-lg"
                                                    type="reset"
                                                    onClick={() => resetByPassVisitor()}
                                                >
                                                    {t('casualworkers.casualworkerformlabel.reset')}
                                                </button>
                                            </div>


                                        </div>

                                    </div>
                                </div>

                                <div style={{ marginTop: "8rem", marginLeft: "-1rem", padding: ".6rem" }}>
                                    <ReactTable
                                        columns={columns}
                                        data={casualworkerData}
                                        pageSizeOption={[5, 10, 15, 20]}
                                        headerClass="btn-primary"
                                        isSearchBoxVisible
                                        ispaginationvisible={true}
                                    />
                                </div>

                                <CapturePhotoModal
                                    isModalOpen={isMobile === true ? false : isCapturePhotoModalOpen}
                                    setIsModalOpen={setIsCapturePhotoModalOpen}
                                    setUserPhoto={setProfilePhoto}
                                />
                                <CaptureDocumentModal
                                    isModalOpen={isCaptureFrontDocModalOpen}
                                    setIsModalOpen={setIsCaptureFrontDocModalOpen}
                                    setUserPhoto={setFrontDocImage}
                                />
                                <CaptureDocumentModal
                                    isModalOpen={isCaptureBackDocModalOpen}
                                    setIsModalOpen={setIsCaptureBackDocModalOpen}
                                    setUserPhoto={setBackDocImage}
                                />


                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
