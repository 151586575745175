import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Form,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import AppContext from 'context/Context';
import AddScheduleModal from 'components/app/calendar/AddScheduleModal';
import CalendarEventModal from 'components/app/calendar/CalendarEventModal';
import Flex from 'components/common/Flex';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { listmonthdashboard, eventsdashboardlist } from 'services/dashboardService'
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { t } from 'i18next';
import { loaderContext } from 'context/Context';




const CalendarManagement = ({ data }) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const calendarRef = useRef();
  const [title, setTitle] = useState('');
  const [day, setDay] = useState('');
  const [calendarApi, setCalendarApi] = useState({});
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const [isOpenEventModal, setIsOpenEventModal] = useState(false);
  const [modalEventContent, setModalEventContent] = useState({});
  const [scheduleStartDate, setScheduleStartDate] = useState();
  const [scheduleEndDate, setScheduleEndDate] = useState();
  const [eventdata, setEventData] = useState([])
  const { isLoading, setIsLoading } = useContext(loaderContext)


  const [monthOption, setMonthOption] = useState([]);
  const currentMonth = new Date().getMonth() + 1;

  const [selectedmonth, setSelectedMonth] = useState({
    label: new Date().toLocaleString('default', { month: 'long' }),
    value: currentMonth
  });


  // const handleEventClick = eventsInfo => {
  //   setModalEventContent({ event: calendarApi.getEventById(eventsInfo.id) });
  //   setIsOpenEventModal(true);
  // };

  // useEffect(() => {
  //   setCalendarApi(calendarRef.current.getApi());
  // }, []);

  // const getDate = () => {
  //   return calendarApi.getCurrentData().currentDate.toLocaleString('en-us', {
  //     weekday: 'long'
  //   });
  // };
  useEffect(async () => {
    setIsLoading(true)
    try {
      const { data } = await listmonthdashboard()
      const { data: event } = await eventsdashboardlist({ month: currentMonth })
      setEventData(event?.data[0]?.event_list)
      setMonthOption(data?.data[0]?.month_list?.map((ye) => ({ label: ye.month, value: ye.id })))
    } catch (error) {
      errorMessage(error)
    }
    setIsLoading(false)
  }, [])
  const handleYear = async (selectedOption) => {
    console.log("selectedoption", selectedOption)
    setIsLoading(true)
    try {
      setSelectedMonth(selectedOption)
      const { data } = await eventsdashboardlist({ month: selectedOption?.value })
      setEventData(data?.data[0]?.event_list)
      // setMonthOption(data?.data[0]?.month_list?.map((da) => da?.month))
    } catch (error) {
      errorMessage(error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <div className="overflow-hidden h-100">
        <div className="p-0 management-calendar">
          <Row className="g-3">
            {/* <Col md={7}>
              <div className="p-card">
                <Flex justifyContent="between">
                  <div className="order-md-1">
                    <OverlayTrigger
                      overlay={<Tooltip id="nextTooltip">Previous</Tooltip>}
                    >
                      <Button
                        variant="falcon-default"
                        size="sm"
                        className="me-1"
                        onClick={() => {
                          calendarApi.prev();
                          setTitle(calendarApi.getCurrentData().viewTitle);
                          setDay(getDate);
                        }}
                      >
                        <FontAwesomeIcon icon="chevron-left" />
                      </Button>
                    </OverlayTrigger>
                    <Button
                      size="sm"
                      variant="falcon-default"
                      onClick={() => {
                        calendarApi.today();
                        setTitle(calendarApi.getCurrentData().viewTitle);
                        setDay(getDate);
                      }}
                      className="px-sm-4"
                    >
                      Today
                    </Button>
                    <OverlayTrigger
                      overlay={<Tooltip id="nextTooltip">Next</Tooltip>}
                    >
                      <Button
                        variant="falcon-default"
                        size="sm"
                        className="ms-1"
                        onClick={() => {
                          calendarApi.next();
                          setTitle(calendarApi.getCurrentData().viewTitle);
                          setDay(getDate);
                        }}
                      >
                        <FontAwesomeIcon icon="chevron-right" />
                      </Button>
                    </OverlayTrigger>
                  </div>

                  <IconButton
                    variant="falcon-primary"
                    iconClassName="me-2"
                    icon="plus"
                    size="sm"
                    onClick={() => {
                      setIsOpenScheduleModal(!isOpenScheduleModal);
                    }}
                  >
                    New Schedule
                  </IconButton>
                </Flex>
              </div>
              <div className="calendar-outline px-3">
                <FullCalendar
                  ref={calendarRef}
                  headerToolbar={false}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  themeSystem="bootstrap"
                  direction={isRTL ? 'rtl' : 'ltr'}
                  height={360}
                  dateClick={info => {
                    setIsOpenScheduleModal(true);
                    setScheduleStartDate(info.date);
                  }}
                  events={data}
                />
              </div>
            </Col> */}
            <Col className="bg-light ">
              <div >
                {/* <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                  {title ||
                    `${calendarApi.currentDataManager?.data?.viewTitle}`}
                </h4>
                <p className="text-500 mb-0">
                  {day ||
                    `${new Date().toLocaleString('en-us', {
                      weekday: 'long'
                    })}`}
                </p> */}
                <div className="bg-light py-2">
                  <Row className="g-0 justify-content-start px-1">

                    <Col xs="auto">

                      <SearchableDropdown
                        value={selectedmonth}
                        options={monthOption?.length > 0 ? monthOption : []}
                        onChange={(selectedOption) => handleYear(selectedOption)}
                      />
                      {/* <Form.Select size="sm">
                        <option>January</option>
                        <option>February</option>
                        <option>March</option>
                        <option>April</option>
                        <option>May</option>
                        <option>June</option>
                        <option>July</option>
                        <option>August</option>
                        <option>September</option>
                        <option>October</option>
                        <option>November</option>
                        <option>December</option>
                      </Form.Select> */}
                    </Col>
                    {/* <Col xs="auto">
  <FalconLink title="Acquisition overview" className="px-0 fw-medium" />
</Col> */}
                  </Row>
                </div>
                <div style={{ padding: "0rem 0rem 0rem .3rem" }}>
                  <h5>{t('dashboardchart.eventchart.heading')}</h5>
                </div>
                <div>
                  <hr />
                </div>
                <ul
                  className="list-unstyled mt-3 scrollbar management-calendar-events"
                  id="management-calendar-events"
                >
                  {eventdata?.map((event, index) => (
                    <li>
                      <div className='border-start border-3 ps-3 mt-1 border-primary'>
                        <h6 className="mb-1 fw-semi-bold text-700">
                          {`${event?.name}-(${event?.location_name})`}
                          {/* Monthly Meeting for X Team-(Garden Area) */}
                        </h6>
                        <p className="fs--2 text-600 mb-0">
                          {event?.date}
                          {/* 01 sep,2023-02 sep,2023 */}
                        </p>
                      </div>
                      <div>
                        <hr />
                      </div>
                    </li>
                  ))}
                  {/* <li>
                    <div className='border-start border-3 ps-3 mt-1 border-primary'>
                      <h6 className="mb-1 fw-semi-bold text-700">
                        Monthly Meeting for X Team-(Garden Area)
                      </h6>
                      <p className="fs--2 text-600 mb-0">
                        01 sep,2023-02 sep,2023
                      </p>
                    </div>
                  </li>
                  <div>
                    <hr />
                  </div>
                  <li>
                    <div className='border-start border-3 ps-3 mt-1 border-success'>
                      <h6 className="mb-1 fw-semi-bold text-700">
                        Monthly Meeting for X Team-(Garden Area)
                      </h6>
                      <p className="fs--2 text-600 mb-0">
                        01 sep,2023-02 sep,2023
                      </p>
                    </div>
                  </li>
                  <div>
                    <hr />
                  </div>
                  <li>
                    <div className='border-start border-3 ps-3 mt-1 border-warning'>
                      <h6 className="mb-1 fw-semi-bold text-700">
                        Monthly Meeting for X Team-(Garden Area)
                      </h6>
                      <p className="fs--2 text-600 mb-0">
                        01 sep,2023-02 sep,2023
                      </p>
                    </div>
                  </li>
                  <div>
                    <hr />
                  </div>
                  <li>
                    <div className='border-start border-3 ps-3 mt-1 border-danger'>
                      <h6 className="mb-1 fw-semi-bold text-700">
                        Monthly Meeting for X Team-(Garden Area)
                      </h6>
                      <p className="fs--2 text-600 mb-0">
                        01 sep,2023-02 sep,2023
                      </p>
                    </div>
                  </li> */}

                  {/* {data?.map(events => (
                    <li
                      className="border-top pt-3 mb-3 pb-1 cursor-pointer"
                      onClick={() => handleEventClick(events)}
                      key={events.id}
                    >
                      <div
                        className={`border-start border-3 ps-3 mt-1 border-${events.color}`}
                      >
                        <h6 className="mb-1 fw-semi-bold text-700">
                          {events.title}
                        </h6>
                        <p className="fs--2 text-600 mb-0">
                          {events.startTime || ''} {events.endTime ? '- ' : ''}
                          {events.endTime || ''}
                        </p>
                      </div>
                    </li>
                  ))} */}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </div >

      <AddScheduleModal
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
        scheduleStartDate={scheduleStartDate}
        scheduleEndDate={scheduleEndDate}
        setScheduleStartDate={setScheduleStartDate}
        setScheduleEndDate={setScheduleEndDate}
      />

      <CalendarEventModal
        isOpenEventModal={isOpenEventModal}
        setIsOpenEventModal={setIsOpenEventModal}
        modalEventContent={modalEventContent}
      />
    </>
  );
};
CalendarManagement.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      color: PropTypes.string,
      classNames: PropTypes.string
    })
  )
};

export default CalendarManagement;
