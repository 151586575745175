import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import formPattern from 'components/configuration/form-pattern';
import InputPassword from 'components/common/InputPassword';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactTimePicker from 'components/common/ReactTimePicker';
import ReactDatePicker from 'components/common/ReactDatePicker';
import CountryCode from 'components/common/CountryCode';
import { useState } from 'react';
import ReactDateAndTimePicker from 'components/common/ReactDateAndTimePicker';
import CsvFileUploder from 'components/doc-components/CsvFileUploder';
import { AuthWizardContext, eventBooking, loaderContext } from 'context/Context';
import { createNewEvent, uploadGuestCsv } from 'services/bookingService';
import moment from 'moment';
import { useEffect } from 'react';


function UploadEventGuest({ register, errors, setValue, handleSubmit, reset, control, Controller, clearErrors, setError }) {
    const { t } = useTranslation();
    const [csvFile, setFile] = useState();
    const navigate = useNavigate();
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const [countryCode, setCountryCode] = useState({});
    const { memberData } = useContext(eventBooking)
    const [resetClicked, setResetClicked] = useState(false);
    const { user, setUser, step, setStep, } = useContext(AuthWizardContext);
    const [removeFileName, setRemoveFileName] = useState(false);

    // const onSubmit = async accessData => {
    //     try {
    //         const { data } = await addAccess(accessData);
    //         toast.success(data.data.result, toastConfig);
    //         navigate('/master/access-control');
    //     } catch (e) {
    //         errorMessage(e)
    //     }
    //     console.log(accessData);
    // };
    // useEffect(() => {
    //     if (csvFile) {
    //         clearErrors("members")
    //     } else {
    //         setError("members", { type: "required" })
    //     }
    // }, [csvFile])

    const onSubmit = async (data) => {
        setIsLoading(true)
        try {
            if (csvFile) {
                const formData = { ...user, ...data }
                const formDataObject = new FormData();
                formDataObject.append("event_id", user?.eventData?.event_id)
                formDataObject.append("guest_list", csvFile)
                const { data: response } = await uploadGuestCsv(formDataObject)
                toast.success(response?.message, toastConfig)
                setFile()
                setValue("members", "")
                clearErrors("members")
                // setUser(formData);
                setStep(step + 1);
            }
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
        // navigate('/visitor-registration', { state: { data: newData } })
    }
    const onNextClick = () => {
        setStep(step + 1);
        setFile()
        setValue("members", "")
        clearErrors("members")
    }
    const onReset = () => {
        setRemoveFileName(true)
        setFile()
        setValue('accessControlName', '');
    };
    return (
        <>
            {/* <div className="mb-3 row g-3">
                <div className="card overflow-hidden mb-3">
                    <div className="card-header"> */}
            <div className="row">
                {/* <div className="col-12">
                                <h3 className="heading-color fw-600">
                                    {t('eventbooking.heading')}
                                </h3>
                            </div>
                            <div className="col-12 mb-3">
                                <hr />
                            </div> */}
                <form autoComplete="off">

                    <div className='row'>
                        <div className="form-group col-md-6 mb-3">
                            <label className="form-label d-block">
                                {t('createEvent.label.step1')}
                            </label>
                            <a href={`${process.env.REACT_APP_BASEROUTE_URL}/index.csv`} className='btn btn-primary' download="sample.csv" style={{ overflow: "auto" }}>
                                {t('createEvent.btnLabel.sampleDownload')}
                            </a>
                        </div>
                        <div className="form-group col-md-4 mb-3">
                            <label className="form-label">
                                {t('createEvent.label.step2')}
                            </label>
                            <div className='col-7'>
                                <Controller
                                    name="members"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { } }) => (
                                        <CsvFileUploder
                                            setValue={setValue}
                                            setFile={(file) => { setFile(file); clearErrors("members") }}
                                            removeFileName={removeFileName}
                                            setRemoveFileName={setRemoveFileName}
                                            setName="members"
                                            hasError={errors.members}
                                            errorMsg={t('manageGroupVisit.error.fileError')}
                                            showButton={false}
                                            placeholderMessageClassName="fs--1 fw-bold my-auto text-dark filetext"
                                            placeholderMessage={t(
                                                'manageGroupVisit.placeholder.uploadcsv'
                                            )}

                                            borderType={{
                                                border: `2px dashed ${errors.members ? '#e63757' : '#d8e2ef'
                                                    }`
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                </form>
                <div className="mb-3 mt-3 text-end">
                    <button onClick={handleSubmit(onSubmit)} className="btn btn-primary me-1">
                        {t('eventbooking.eventbookingform.submit')}
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={() => onNextClick()}
                    >
                        {t('eventbooking.eventbookingform.next')}
                    </button>
                </div>
            </div>
            {/* </div>
                </div>
            </div> */}
        </>
    )
}

export default UploadEventGuest