import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getAppointments,
  deleteAppointment
} from 'services/appointmentService';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import ReactTable from 'components/common/ReactTable';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Swalcolor from 'components/configuration/sweetalert-config';
import moment from 'moment';
import { encodeData } from 'components/common/encodeParams';
import { appointmentType } from 'components/configuration/common-options';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReactDatePicker from 'components/common/ReactDatePicker';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { getappointment, appointmentdelete } from 'services/AppointmenttService'
import { loaderContext } from 'context/Context';



export default function Appointment() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [appointment, setAppointment] = useState([]);
  const [filterDate, setFilterDate] = useState(moment().toDate());
  const [filterAppointment, setFilterAppointment] = useState([])
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const [pageNo, setPageNO] = useState(0)


  // useEffect(async () => {
  //   checkModulePermission("Appointment")
  //   try {
  //     const { data } = await getAppointments();
  //     //  const allAppointment =  data.data.results.filter(data => data.appointmentType === appointmentType[0].value)
  //     const allAppointment = data.data.results
  //     setAppointment(
  //       allAppointment.map(
  //         appointment => (
  //           (appointment.appointmentStartDate = moment(
  //             appointment.appointmentStartDate,
  //             process.env.REACT_APP_DATE_FORMAT
  //           ).toDate()),
  //           (appointment.appointmentEndDate = moment(
  //             appointment.appointmentEndDate,
  //             process.env.REACT_APP_DATE_FORMAT
  //           ).toDate()),
  //           (appointment.startTime = moment(
  //             appointment.startTime,
  //             process.env.REACT_APP_TIME_FORMAT_SET_12 === 'false'
  //               ? process.env.REACT_APP_TIME_FORMAT_24
  //               : process.env.REACT_APP_TIME_FORMAT_12
  //           ).toDate()),
  //           (appointment.endTime = moment(
  //             appointment.endTime,
  //             process.env.REACT_APP_TIME_FORMAT_SET_12 === 'false'
  //               ? process.env.REACT_APP_TIME_FORMAT_24
  //               : process.env.REACT_APP_TIME_FORMAT_12
  //           ).toDate()),
  //           // console.log(Object.values(appointment) && Object.values(appointment).map(data => (data == null && Object.keys(appointment)null))),
  //           Object.values(appointment).map(value => value == null && { [Object.keys(appointment)]: "No Data" }),
  //           { ...appointment, [!Object.values(appointment) && Object.keys(appointment)]: "no data" }
  //         )
  //       )
  //     );
  //     // setAppointment(appointment.data.result);
  //   } catch (e) {
  //     errorMessage(e)
  //   }
  // }, []);


  useEffect(async () => {
    setIsLoading(true)
    checkModulePermission("Appointment")
    try {
      const { data } = await getappointment()
      setAppointment(data?.data)
      setFilterAppointment(data?.data)
    } catch (e) {
      errorMessage(e)
    }
    setIsLoading(false)
  }, [])

  const onEditRow = (id) => {
    const newData = appointment.find((app) => app.id === id)
    console.log("///", newData)
    navigate('/appointment/edit-appointment', { state: newData });
  };
  const onDeleteRow = (id) => {
    Swal.fire({
      title: t('appointment.deleteAppointment.sweetAlert.confirmationTitle'),
      text: t('appointment.deleteAppointment.sweetAlert.confirmationText'),
      icon: 'warning',
      showCancelButton: Swalcolor.confirmation.showCancelButton,
      confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
      cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
      confirmButtonText: t(
        'appointment.deleteAppointment.sweetAlert.confirmationBtnLabel'
      )
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        try {
          const { data } = await appointmentdelete({ id });
          const newData = data?.data[0]?.visitor_Appoitment_detail
          const oldEntryIndex = filterAppointment.findIndex(
            (entry) => entry.id === id
          );
          if (oldEntryIndex !== -1) {
            const updatedFilterAppointment = [...filterAppointment];
            updatedFilterAppointment[oldEntryIndex] = newData;
            setFilterAppointment(updatedFilterAppointment);
          }

          Swal.fire({
            icon: 'success',
            title: t('appointment.deleteAppointment.sweetAlert.simpleTitle'),
            text: data.data.result,
            confirmButtonColor: Swalcolor.simple.confirmButtonColor
          });
        } catch (e) {
          errorMessage(e)
        }
        setIsLoading(false)

      }
    });
  };



  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    setError,
    clearErrors,
    watch,
    formState: { errors }
  } = useForm();



  const columns = [
    {
      Header: t('appointment.tableHead.id'),
      accessor: (row, index) => index + 1
    },
    {
      Header: t('appointment.tableHead.staffname'),
      accessor: 'staff_name'
    },
    {
      Header: t('appointment.tableHead.visitorname'),
      accessor: 'visitor_fname'
    },
    {
      Header: t('appointment.tableHead.from'),
      accessor: 'visitor_from',
      Cell: ({ row }) => {
        return row?.original?.visitor_from ? row?.original?.visitor_from : '--'
      }
    },
    {
      Header: t('appointment.tableHead.bookedby'),
      accessor: 'create_by'
    },
    {
      Header: t('appointment.tableHead.appointmentdate'),
      accessor: 'date_of_appointment',
    },
    {
      Header: t('appointment.tableHead.appointmenttime'),
      accessor: 'start_time',
      Cell: ({ row }) => {
        return row?.original?.start_time ? moment(row?.original?.start_time, "HH:mm:ss").format(process.env.REACT_APP_TIME_FORMAT_24) : "--"
      },
    },
    {
      Header: t('appointment.tableHead.status'),
      accessor: 'status'
    },
    {
      Header: t('appointment.tableHead.action'),
      Cell: ({ row }) => (
        <div>
          {row.original.status != "Cancelled" && <span
            className="btn p-0 me-2"
            title="Edit"
            onClick={() => onEditRow(row.original.id)}
          >
            <FontAwesomeIcon icon="edit" />
          </span>}
          {row.original.status != "Cancelled" && <span
            className="btn p-0"
            title="cancel"
            onClick={() => onDeleteRow(row.original.id)}
          >
            <FontAwesomeIcon icon="times" />
          </span>}
          {/* <span
            className="btn p-0"
            title="Delete"
            onClick={() => onDeleteRow(row.original.id)}
          > */}
          {/* <FontAwesomeIcon icon="trash-alt" /> */}
          {/* <FontAwesomeIcon icon="times" />
          </span> */}
        </div>
      ),
      disableSortBy: true
    }
  ];

  const handleFilterChange = (date) => {
    setFilterDate(date);
  };

  const resetvalue = () => {
    reset()
    setValue('date_of_appointment', '');
    setFilterAppointment(appointment);
  };



  const filter = (data) => {
    const formattedFilterDate = data?.date_of_appointment ? moment(data?.date_of_appointment).format(process.env.REACT_APP_FILTER_DATE) : '';
    console.log("appointmnet", appointment)
    const filteredAppointments = appointment.filter((item) => {
      if (item.date_of_appointment) {
        const formattedAppointmentDate = moment(item.date_of_appointment).format(process.env.REACT_APP_FILTER_DATE);
        return formattedFilterDate === formattedAppointmentDate;
      }
      return false;
    });
    setFilterAppointment(filteredAppointments);
  }





  return (
    <>
      <div className="row g-3 mb-3">
        <div className="">
          <div className="card overflow-hidden mb-3">
            <div className="card-header">
              <div className="row">
                <div className="col-xl-8 col-lg-6 col-md-4 ">
                  <h3 className="heading-color fw-600 mb-0">
                    {t('appointment.heading.mainHeading')}
                  </h3>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-8 table-btn">
                  <button
                    className="btn btn-primary btn-sm float-end"
                    onClick={() => {
                      navigate('/appointment/create-appointment');
                    }}
                  >
                    <FontAwesomeIcon icon="plus" />
                    <span className="ms-1 d-sm-inline">
                      {t('appointment.btnLabel.createAppointment')}
                    </span>
                  </button>
                </div>
                <div className="col-12">
                  <hr />
                </div>

                <div className="col-md-6 col-12 form-group mb-3">
                  <div className="d-flex  flex-wrap gap-2">
                    <Controller
                      control={control}
                      name="date_of_appointment"
                      rules={{ required: true }}
                      render={({ field: { value, onChange } }) => (
                        <ReactDatePicker
                          value={value}
                          // selected={value}
                          onChange={onChange}
                          // onChange={handleFilterChange}
                          placeholder={t(
                            'appointment.placeholder.dateOfAppointment'
                          )}
                          hasErrors={errors.date_of_appointment}
                          dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                        />
                      )}
                    />
                    {/* <input
                      type="text"
                      style={{ maxWidth: "15rem" }}
                      className="form-control"
                      value={filterDate}
                      onChange={handleFilterChange}
                    /> */}
                    <div className="d-flex gap-2">
                      <button
                        className="btn btn-primary btn-lg"
                        type="reset"
                        onClick={() => resetvalue()}
                      >
                        <ReplayIcon />
                        {/* <FontAwesomeIcon icon="rotate-left" /> */}
                        {t('appointment.btnLabel.reset')}
                      </button>

                      <button
                        className="btn btn-primary btn-lg"
                        type="submit"
                        onClick={handleSubmit(filter)}
                      >
                        <FilterAltIcon />
                        {/* <FontAwesomeIcon icon="filter" /> */}
                        {t('appointment.btnLabel.filter')}
                      </button>

                    </div>
                  </div>
                </div>



              </div>
            </div>
            <div className="card-body pt-0">
              {/* <div className="d-flex justify-content-end me-1">
                <button
                  className="d-flex rounded-circle btn btn-primary p-2"
                  onClick={() => navigate('/appointment/appointment-calendar')}
                >
                  <svg
                    className="svg-inline--fa fa-calendar-alt fa-w-14"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="calendar-alt"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"
                    ></path>
                  </svg>
                </button>
              </div> */}
              <ReactTable
                isSearchBoxVisible={true}
                columns={columns}
                data={filterAppointment}
                pageSizeOption={[5, 10, 15, 20]}
                headerClass="btn-primary"
                ispaginationvisible={true}
                pageNo={pageNo}
                setPageNO={setPageNO}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
