import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import pattern from 'components/configuration/form-pattern';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { genderOptions, statusOptions } from 'components/configuration/common-options';
import CountryCode from 'components/common/CountryCode';
import { useEffect } from 'react';
import { verifyAccessCard } from 'services/commonService';

// import PhoneNumber from 'components/common/PhoneNumber';
import countryCodes, {
    defaultCountryCode
} from 'components/configuration/country-code';
import { departmentlist, departmentdelete, getrolelist } from 'services/masterModuleService'
import { systemuseradd, getListOfDepartment } from 'services/adminmoduleService'
import { rolelist } from 'services/masterModuleService'
import ReactDatePicker from 'components/common/ReactDatePicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';


const CreateSystemUser = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // const [countryCode, setCountryCode] = useState({});
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const [docType, setDocType] = useState([{ documentName: "email", id: "1", checked: false }, { documentName: "sms", id: "2", checked: false }, { documentName: "whatsapp", id: "3", checked: false }, { documentName: "all", id: "4", checked: false }]);
    const resetValue = () => {
        reset();
        setValue('department_id', '');
        setValue('gender', '');
        setValue('status', '');
        setValue('role', '');
        setDocType([{ documentName: "email", id: "1", checked: false }, { documentName: "sms", id: "2", checked: false }, { documentName: "whatsapp", id: "3", checked: false }, { documentName: "all", id: "4", checked: false }])
        setCountryCode(defaultCountryCode)
    };
    const [statusOption, setStatusOption] = useState([{ label: "Active", value: 1 }, { label: "In Active", value: 0 }])
    // const [gender, setGender] = useState([])
    const [departmentList, setDepartmentList] = useState([]);
    const [role, setRole] = useState([])
    const [phoneNumber, setPhoneNumber] = useState("");


    const [countryCode, setCountryCode] = useState(defaultCountryCode);
    //selected host type for members encoding added by parth
    const [selectedHostType, setSelectedHostType] = useState();

    //handle selected host type for members encoding by parth
    const handleSelectHostType = (event) => {
        setSelectedHostType(event.target.value);
    };
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        watch
    } = useForm();

    useEffect(async () => {
        checkModulePermission("System Users")
        setIsLoading(true)
        try
        {
            const { data: department } = await getListOfDepartment()
            const { data: role } = await getrolelist()
            setDepartmentList(department?.data?.map((dep) => ({ label: dep.name, value: dep.id })))
            setRole(role?.data?.map((rol) => ({ label: rol.name, value: rol.id })))
            // setGender(genderOptions?.find((gen) => ({ label: gen.label, value: gen.value })))
        } catch (e)
        {
            errorMessage(e)
        }
        setIsLoading(false)
    }, [])
    const getAccessCardNumber = async () => {
        setIsLoading(true)
        try
        {
            const { data } = await verifyAccessCard({ host_type: selectedHostType })
            setValue("access_card_no", data?.data[0]?.access_card_no)
            clearErrors("access_card_no")
        } catch (e)
        {
            if (e.response.data)
            {
                if (Array.isArray(e.response.data.message))
                {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else
                {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else
            {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const onSubmit = async (newData) => {
        setIsLoading(true)
        try
        {
            console.log("newData", newData)
            newData.department_id = newData.department_id.value
            newData.gender = newData.gender.value
            newData.role = newData.role.value
            newData.status = newData?.status?.value
            newData.user_prefered_notification = newData.user_prefered_notification?.join(",")
            newData.expiry_date = moment(newData.expiry_date).format(process.env.REACT_APP_DATE_FORMAT)
            const { data } = await systemuseradd({ country_code: countryCode.dial_code, ...newData })
            toast.success(data?.message, toastConfig)
            navigate('/admin/system-users')
        } catch (e)
        {
            if (e.response.data)
            {
                if (Array.isArray(e.response.data.message))
                {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else
                {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else
            {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)

    }

    const handleCheckboxChange = (index) => {
        const updatedDocTypes = [...docType];
        const selectedDocType = updatedDocTypes[index];

        selectedDocType.checked = !selectedDocType.checked;

        if (selectedDocType.documentName === "all")
        {
            updatedDocTypes.forEach((doc, i) => {
                doc.checked = selectedDocType.checked;
            });

        } else
        {
            const allCheckbox = updatedDocTypes.find(doc => doc.documentName === "all");
            const allChecked = updatedDocTypes.every(doc => doc.checked);
            allCheckbox.checked = allChecked;
        }

        console.log("selecteddoctype", selectedDocType);

        clearErrors('user_prefered_notification')
        setDocType(updatedDocTypes);
    };


    return (
        // <div className="row g-3 mb-3">
        <div className='row g-3 mb-3'>
            <div className="">
                <div className="card overflow-hidden mb-3">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-8">
                                <h3 className="heading-color fw-600 mb-0">
                                    {t('systemuser.createsystemuserheading')}
                                </h3>
                            </div>
                            <div className="col-4 table-btn">
                                <button
                                    className="btn btn-primary float-end btn-sm"
                                    onClick={() => navigate('/admin/system-users')}
                                >
                                    <FontAwesomeIcon icon="chevron-left" />
                                    <span className="ms-1">
                                        {t('userroles.btnlabel.back')}
                                    </span>
                                </button>
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>


                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className="row">
                                    {/* <h5 className="mb-3">
                                    {t('guestRegistration.guestVerification.guestInfo')}
                                </h5> */}
                                    <div className="form-group col-md-4 col-sm-6 mb-3">
                                        <label>
                                            {t('systemuser.createsystemuserform.departmentname')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <Controller
                                            name="department_id"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <SearchableDropdown
                                                    value={value}
                                                    onChange={onChange}
                                                    options={departmentList}
                                                    hasError={errors.department_id}
                                                    placeholder={t('systemuser.createsystemuserformplaceholder.selectdepartment')}
                                                />
                                            )}
                                        />
                                        {errors.department_id && (
                                            <small>
                                                {errors.department_id.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('systemuser.createsystemuserformerror.departmenterror')}
                                                    </span>
                                                )}
                                                {/* {errors.departmentName.type === 'pattern' && (
                                                <span className="text-danger">
                                                    {t('guestRegistration.guestVerification.error.validName')}
                                                </span>
                                            )} */}
                                            </small>
                                        )}
                                    </div>

                                    <div className="form-group col-md-4 col-sm-6 mb-3">
                                        <label className="form-label">
                                            {t('systemuser.createsystemuserform.fullname')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.name && 'is-invalid'
                                                }`}
                                            type="text"
                                            placeholder={t('systemuser.createsystemuserformplaceholder.name')}
                                            {...register('name', {
                                                required: true,
                                                pattern: pattern.notAllowSpaceInStart
                                            })}
                                        />
                                        {errors.name && (
                                            <small>
                                                {errors.name.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('systemuser.createsystemuserformerror.fullnameerror')}
                                                    </span>
                                                )}
                                                {errors.name.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('systemuser.createsystemuserformerror.fullNameInvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4 col-sm-6 mb-3">
                                        <label className="form-label">
                                            {t('systemuser.createsystemuserform.emailaddress')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.email && 'is-invalid'
                                                }`}
                                            type="text"
                                            placeholder={t('systemuser.createsystemuserformplaceholder.email')}
                                            {...register('email', { required: true, pattern: pattern.email })}
                                        />
                                        {errors.email && (
                                            <small>
                                                {errors.email.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('systemuser.createsystemuserformerror.emailerror')}
                                                    </span>
                                                )}
                                                {errors.email.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('systemuser.createsystemuserformerror.emailinvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>

                                    <div className="form-group col-md-2 col-sm-6 mb-3">
                                        <label>
                                            {t('systemuser.createsystemuserform.countrycode')}
                                            {/* <span className="text-danger ms-1">*</span> */}
                                        </label>
                                        <CountryCode
                                            countryCode={countryCode}
                                            setCountryCode={setCountryCode}
                                            direction="bottom"
                                        />
                                        {/* 
                                        <Controller
                                            name="countrycode"
                                            control={control}
                                            rules={{ required: true, pattern: pattern.phoneNumber }}
                                            render={({ field: { onChange, value } }) => (
                                                <PhoneNumber
                                                    value={value}
                                                    onChange={onChange}
                                                    phoneNumber={phoneNumber}
                                                    // email={email}
                                                    setPhoneNumber={setPhoneNumber}
                                                    // firstError={errors?.guestContactNo?.type === 'required' || errors?.guestContactNo?.message == "phoneNoInvalid"}
                                                    // secondError={errors?.guestContactNo?.type === 'required' || errors?.guestContactNo?.message == "emailInvalid"}
                                                    // setEmail={setEmail}
                                                    countryCode={countryCode}
                                                    // firstPlaceholder={t("guestRegistration.guestVerification.placeholder.waNumber")}
                                                    // secondPlaceholder={t("guestRegistration.guestVerification.placeholder.email")}
                                                    setCountryCode={setCountryCode}
                                                    dropdownDirection="bottom"
                                                    error={errors.countrycode}
                                                    saperate={true}
                                                />
                                            )}
                                        />
                                        {errors.countrycode && (
                                            <small>
                                                {errors.countrycode.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('systemuser.createsystemuserformerror.countryerror')}
                                                    </span>
                                                )}
                                            </small>
                                        )} */}
                                    </div>
                                    <div className="form-group col-md-2 col-sm-6 mb-3">
                                        <label>
                                            {t('systemuser.createsystemuserform.mobileno')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.phone && 'is-invalid'
                                                }`}
                                            type="text"
                                            placeholder={t('systemuser.createsystemuserformplaceholder.mobileno')}
                                            {...register('phone', { required: true, pattern: pattern.numberOnly, })}
                                        />

                                        {errors.phone && (
                                            <>
                                                <small>
                                                    {errors.phone.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.mobileerror')}
                                                        </span>
                                                    )}
                                                </small>
                                                <small>
                                                    {errors.phone.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.mobileinvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            </>
                                        )}
                                    </div>


                                    <div className='row'>
                                        <div className='col-lg-12 mt-4'>
                                            <label className='form-label edituserroleheading'>
                                                {t('systemuser.createsystemuserform.preferednotification')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <div className='col-12'>
                                                <hr />
                                            </div>
                                            <div className='form-group mb-3 mb-sm-0'>
                                                {docType.map((docType, index) => (
                                                    <div className="form-check form-check-inline" key={docType.id}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value={docType.documentName}
                                                            {...register('user_prefered_notification', {
                                                                required: true
                                                            })}
                                                            onChange={() => handleCheckboxChange(index)}
                                                            checked={docType.checked}
                                                        />
                                                        <label className="form-check-label">
                                                            {docType.documentName}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                            {errors.user_prefered_notification && (
                                                <small>
                                                    {errors.user_prefered_notification.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t(
                                                                'systemuser.createsystemuserformerror.preferederror'
                                                            )}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                    </div>



                                    <div className="row">
                                        <h5 className="mt-3 mb-1">
                                            {t('systemuser.createsystemuserform.adduser')}
                                        </h5>
                                        <div className='col-12'>
                                            <hr />
                                        </div>
                                        <div className="form-group col-md-4 col-sm-6 mb-3">
                                            <label>
                                                {t('systemuser.createsystemuserform.username')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                className={`form-control ${errors.username && 'is-invalid'
                                                    }`}
                                                type="text"
                                                placeholder={t('systemuser.createsystemuserformplaceholder.username')}
                                                {...register('username', {
                                                    required: true,
                                                    pattern: pattern.userName
                                                })}
                                            />
                                            {errors.username && (
                                                <small>
                                                    {errors.username.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.usernameerror')}
                                                        </span>
                                                    )}
                                                    {errors.username.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.usernameinvaliderror')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>

                                        <div className="form-group col-md-4 col-sm-6 mb-3">
                                            <label className="form-label">
                                                {t('systemuser.createsystemuserform.password')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                className={`form-control ${errors.password && 'is-invalid'
                                                    }`}
                                                type="text"
                                                placeholder={t('systemuser.createsystemuserformplaceholder.password')}
                                                {...register('password', {
                                                    required: true,
                                                    pattern: pattern.noSpace
                                                })}
                                            />
                                            {errors.password && (
                                                <small>
                                                    {errors.password.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.passworderror')}
                                                        </span>
                                                    )}
                                                    {errors.password.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.passwordinvaliderror')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-4 col-sm-6 mb-3">
                                            <label className="form-label">
                                                {t('systemuser.createsystemuserform.confirmpassword')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                className={`form-control ${errors.confirmpassword && 'is-invalid'
                                                    }`}
                                                type="text"
                                                placeholder={t('systemuser.createsystemuserformplaceholder.confirmpassword')}
                                                {...register('confirmpassword', { required: true, pattern: pattern.noSpace })}
                                            />
                                            {errors.confirmpassword && (
                                                <small>
                                                    {errors.confirmpassword.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.confirmpassworderror')}
                                                        </span>
                                                    )}
                                                    {errors.confirmpassword.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.confirmpasswordinvaliderror')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>

                                        <div className="form-group col-md-4 col-sm-6 mb-3">
                                            <label>
                                                {t('systemuser.createsystemuserform.gender')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                name="gender"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange } }) => (
                                                    // <input className='form-group' value={value} onChange={onChange} />
                                                    <SearchableDropdown
                                                        value={value}
                                                        onChange={onChange}
                                                        options={genderOptions}
                                                        hasError={errors.gender}
                                                        placeholder={t('systemuser.createsystemuserformplaceholder.selectgender')}
                                                    />
                                                )}
                                            />
                                            {errors.gender && (
                                                <small>
                                                    {errors.gender.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.gendererror')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-4 col-sm-6 mb-3">
                                            <label>
                                                {t('systemuser.createsystemuserform.status')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                name="status"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange } }) => (
                                                    // <input className='form-group' value={value} onChange={onChange} />
                                                    <SearchableDropdown
                                                        value={value}
                                                        onChange={onChange}
                                                        options={statusOption}
                                                        hasError={errors.status}
                                                        placeholder={t('systemuser.createsystemuserformplaceholder.selectstatus')}
                                                    />
                                                )}
                                            />
                                            {errors.status && (
                                                <small>
                                                    {errors.status.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.statuserror')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-4 col-sm-6 mb-1">
                                            <label>
                                                {t('systemuser.createsystemuserform.employeerole')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                name="role"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange } }) => (
                                                    <SearchableDropdown
                                                        value={value}
                                                        onChange={onChange}
                                                        options={role}
                                                        hasError={errors.role}
                                                        placeholder={t('systemuser.createsystemuserformplaceholder.selectrole')}
                                                    />
                                                )}
                                            />
                                            {errors.role && (
                                                <small>
                                                    {errors.role.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.employeeroleerror')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-4 col-sm-6 mb-3">
                                            <label>
                                                {t('Access Card Number')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                className={`form-control ${errors.username && 'is-invalid'
                                                    }`}
                                                type="text"
                                                placeholder={t('Access Card Number')}
                                                {...register('access_card_no')}
                                            />
                                            {errors.username && (
                                                <small>
                                                    {errors.username.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.usernameerror')}
                                                        </span>
                                                    )}
                                                    {errors.username.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.usernameinvaliderror')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-4 col-sm-6 mb-3">
                                            <label>
                                                {t('Staff ID')}
                                                {/* <span className="text-danger ms-1">*</span> */}
                                            </label>
                                            <input
                                                className={`form-control ${errors.username && 'is-invalid'
                                                    }`}
                                                type="text"
                                                placeholder={t('Staff ID')}
                                                {...register('staff_id')}
                                            />
                                            {errors.username && (
                                                <small>
                                                    {errors.username.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.usernameerror')}
                                                        </span>
                                                    )}
                                                    {errors.username.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.usernameinvaliderror')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-4 col-sm-6 mb-1">
                                            <label>
                                                {t('systemuser.createsystemuserform.expirydate')}
                                                {/* <span className="text-danger ms-1">*</span> */}
                                            </label>
                                            <Controller
                                                name="expiry_date"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange } }) => (
                                                    <ReactDatePicker
                                                        value={value}
                                                        onChange={onChange}
                                                        placeholder={t(
                                                            'systemuser.createsystemuserformplaceholder.selectdate'
                                                        )}
                                                        hasErrors={errors.expiry_date}
                                                        // maxDate={moment().toDate()}
                                                        minDate={moment().toDate()}
                                                        dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}

                                                    />
                                                )}
                                            />
                                            {/* <input
                                                className={`form-control ${errors.expiry_date && 'is-invalid'
                                                    }`}
                                                type="text"
                                                placeholder={t('systemuser.createsystemuserformplaceholder.selectdate')}
                                                {...register('expiry_date', { required: true })}
                                            /> */}
                                            {errors.expiry_date && (
                                                <small>
                                                    {errors.expiry_date.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('systemuser.createsystemuserformerror.expirydateerror')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>


                                    </div>
                                    <div className="col-12 d-flex justify-content-end form-group mb-3">
                                        <div className="d-flex gap-2">

                                            <select value={selectedHostType} onChange={handleSelectHostType} className="form-control mt-auto mb-auto">
                                                <option value="">Select Option</option>
                                                <option value="2">Guest Gate</option>
                                                <option value="3">Members Only Gate</option>
                                                <option value="4">Serengeti Gate</option>
                                                <option value="5">Control Room</option>
                                                <option value="6">Mail Room</option>
                                            </select>
                                            <button
                                                type="button"
                                                onClick={() => getAccessCardNumber()}
                                                className="btn btn-primary-frontdesk btn btn-primary me-1"
                                            >
                                                {t('visitorRegistration.label.verifyCard')}
                                            </button>
                                            <button type="submit" className="btn btn-primary me-1">
                                                {t('departmentMaster.btnLabel.add')}
                                            </button>
                                            <button
                                                type="reset"
                                                className="btn btn-danger"
                                                onClick={resetValue}
                                            >
                                                {t('departmentMaster.btnLabel.reset')}
                                            </button>
                                        </div>
                                    </div>



                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateSystemUser