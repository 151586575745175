import React, { useEffect, useRef, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { useTranslation } from 'react-i18next';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';
import TrafficSource from './traffic-source/TrafficSource';
import PieBasicChart from './pie-charts/PieBasicChart';
import BasicAreaLineChart from './BasicAreaLineChart';
import CalendarManagement from './calendar/CalendarManagement';
import '../default/chart.css'
import barChart from 'assets/img/illustrations/crm-bar-chart.png';
import lineChart from 'assets/img/illustrations/crm-line-chart.png';
import html2canvas from 'html2canvas';
import '../../dashboards/dashboard.css'
import { todaysummarydashboardlist } from 'services/dashboardService'
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { guestsummarydashboardlist, weeklyoverviewdashboardlist, visitorsummarydashboardlist, locationdashboardlist, departmentdashboardlist } from 'services/dashboardService'
import PieBasicChart1 from './pie-charts/PieBasicChart1';
import BasicAreaLineChart1 from '../BasicAreaLineChart1';
import download from 'downloadjs';
import TrafficSourceChart from './traffic-source/TrafficSourceChart';
import { pieChartContext, loaderContext } from 'context/Context'







// import { compareData } from 'components/common/helper-function/matchData';

const Dashboard = () => {
  const { t } = useTranslation();
  const [todaysummary, setTodaySummary] = useState()
  const [weeklycount, setWeeklyCount] = useState()
  const location2 = useLocation()
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const [location, setLocation] = useState([])
  const [depart, setDepart] = useState([])
  const userName = localStorage.getItem("userName")
  console.log("location2", location2.pathname)

  // const dashpermission = localStorage.getItem("dashPermission")
  // console.log("dashperm==>", dashpermission)

  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    const dashPermission = localStorage.getItem("dashPermission");

    if (dashPermission)
    {
      const parsedPermissions = JSON.parse(dashPermission);
      setUserPermissions(parsedPermissions.dashpermission[0]?.child?.map((chi) => chi.display_name));
    }
  }, []);

  console.log("dash===>", userPermissions)

  useEffect(async () => {
    console.log("in dashboard")
    checkModulePermission("Dashboard")
    setIsLoading(true)

    try
    {
      const { data } = await todaysummarydashboardlist()
      const { data: weekly } = await weeklyoverviewdashboardlist()
      const { data: loc } = await locationdashboardlist()
      const { data: department } = await departmentdashboardlist()
      setLocation(loc?.data[0].location_visit)
      setDepart(department?.data[0].department_visit)
      setWeeklyCount({ visitor: weekly?.data[0].total_visitor_count, guest: weekly?.data[0].total_guest_count, casual: weekly?.data[0].total_casual_worker_count, supllier: weekly?.data[0].total_supplier_count })
      setTodaySummary({ guestin: data?.data[0].guests_in, guestout: data?.data[0].guests_out, guestbalance: data?.data[0].guests_balance, visitorin: data?.data[0].visitors_in, visitorout: data?.data[0].visitors_out, visitorbalance: data?.data[0].visitors_balance, })

    } catch (error)
    {
      errorMessage(error)
    }
    setIsLoading(false)

  }, [])


  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem('authToken'))
    {
      navigate("/", { replace: true })
    }
  })




  // 1 for todays summarychart
  const getCurrentDate = () => {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, '0');
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const year = today.getFullYear();

    return `${day}/${month}/${year}`;
  };

  //2 for weeklyoverview chart

  const today = new Date();
  const dayOfWeek = today.getDay();

  const daysUntilMonday = (dayOfWeek + 6) % 7;
  const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - daysUntilMonday);

  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const suffix = (day >= 11 && day <= 13) ? 'th' :
      (day % 10 === 1) ? 'st' :
        (day % 10 === 2) ? 'nd' :
          (day % 10 === 3) ? 'rd' : 'th';

    return `${day}${suffix} ${month}`;
  };

  // export jpg or png weeklychart //
  const chartRef = useRef(null);
  const handleSaveAsImage = (imageType) => {
    if (chartRef.current)
    {
      const chartInstance = chartRef.current.getEchartsInstance();
      if (chartInstance)
      {
        const base64Image = chartInstance.getDataURL({
          type: `image/${imageType}`,
          pixelRatio: 2
        });

        if (base64Image)
        {
          download(base64Image, `chart.${imageType}`, `image/${imageType}`);
        } else
        {
          console.error('Failed to get base64 image data.');
        }
      } else
      {
        console.error('ECharts instance not found.');
      }
    } else
    {
      console.error('Chart ref not found.');
    }
  };

  // export jpg or png locationchart //

  const chartlocationRef = useRef(null);
  const handlelocationSaveAsImage = (imageType) => {
    if (chartlocationRef.current)
    {
      const chartInstance = chartlocationRef.current.getEchartsInstance();
      if (chartInstance)
      {
        const base64Image = chartInstance.getDataURL({
          type: `image/${imageType}`,
          pixelRatio: 2
        });

        if (base64Image)
        {
          download(base64Image, `chart.${imageType}`, `image/${imageType}`);
        } else
        {
          console.error('Failed to get base64 image data.');
        }
      } else
      {
        console.error('ECharts instance not found.');
      }
    } else
    {
      console.error('Chart ref not found.');
    }
  };

  // export jpg or png departmentchart //
  const chartdepartRef = useRef(null);
  const handledepartSaveAsImage = (imageType) => {
    if (chartdepartRef.current)
    {
      const chartInstance = chartdepartRef.current.getEchartsInstance();
      if (chartInstance)
      {
        const base64Image = chartInstance.getDataURL({
          type: `image/${imageType}`,
          pixelRatio: 2
        });

        if (base64Image)
        {
          download(base64Image, `chart.${imageType}`, `image/${imageType}`);
        } else
        {
          console.error('Failed to get base64 image data.');
        }
      } else
      {
        console.error('ECharts instance not found.');
      }
    } else
    {
      console.error('Chart ref not found.');
    }
  };

  // export jpg or png guestsummary //
  const chartguestsummaryRef = useRef(null);
  const handleguestsummarySaveAsImage = (imageType) => {
    if (chartguestsummaryRef.current)
    {
      const chartInstance = chartguestsummaryRef.current.getEchartsInstance();
      if (chartInstance)
      {
        const base64Image = chartInstance.getDataURL({
          type: `image/${imageType}`,
          pixelRatio: 2
        });

        if (base64Image)
        {
          download(base64Image, `chart.${imageType}`, `image/${imageType}`);
        } else
        {
          console.error('Failed to get base64 image data.');
        }
      } else
      {
        console.error('ECharts instance not found.');
      }
    } else
    {
      console.error('Chart ref not found.');
    }
  };

  // export jpg or png visitorsummary //
  const chartvisitorsummaryRef = useRef(null);
  const handlevisitorsummarySaveAsImage = (imageType) => {
    if (chartvisitorsummaryRef.current)
    {
      const chartInstance = chartvisitorsummaryRef.current.getEchartsInstance();
      if (chartInstance)
      {
        const base64Image = chartInstance.getDataURL({
          type: `image/${imageType}`,
          pixelRatio: 2
        });

        if (base64Image)
        {
          download(base64Image, `chart.${imageType}`, `image/${imageType}`);
        } else
        {
          console.error('Failed to get base64 image data.');
        }
      } else
      {
        console.error('ECharts instance not found.');
      }
    } else
    {
      console.error('Chart ref not found.');
    }
  };



  //3-4 for location and department visits chart
  const todays = new Date();

  const monthName = todays.toLocaleString('default', { month: 'long' });
  const year = todays.getFullYear();

  return (
    <>
      {(userPermissions && userPermissions.length > 0) ? <>
        {/* <Card>
        <Card.Body>I am Dashboard</Card.Body>
      </Card> */}

        <div className="mb-3 g-3">
          <div className="card overflow-hidden mb-3">
            <div className="card-header" style={{ padding: "0rem 0rem 0rem 1rem" }}>
              <div className="row">
                <div className="col-12 d-flex align-item-center">
                  <img src={barChart} width={90} alt="..." className="ms-n4" />
                  <div style={{ padding: ".8rem" }}>
                    <span className="heading-color fw-500 p-0 py-2">
                      {t('dashboard.welcomeheading')}
                    </span><br />
                    <span className="heading-color fw-600 p-0 py-2" style={{ fontSize: "1.2rem" }}>
                      <span className='fw-bold'>{t('dashboard.secuvizheading')}</span>  {t('dashboard.systemheading')}
                    </span>
                  </div>
                  <img
                    src={lineChart}
                    width={140}
                    alt="..."
                    className="ms-n4 d-md-none d-lg-block"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3 g-1 dashboard" >
          {userPermissions.includes("Today's Summary") && (
            <div className="card overflow-hidden mb-3 " style={{ height: "fit-content" }}>
              <div className="card-header">
                <div className="row">
                  <div className='col-12 mb-4'>
                    <span className="fw-600 p-0 py-2 chart-text">
                      {t('dashboardchart.todaysummary.heading')}  <span> ({getCurrentDate()})</span>
                      {/* Today's Summary(20/09/2023) */}
                    </span>
                  </div>
                </div>
                <div className='row' >
                  <div className='col-4 border-200 fw-600 border-md-200 border-bottom border-md-end todaysummary-text' >
                    <p>{t('dashboardchart.todaysummary.guestin')}</p>
                    <p>{todaysummary?.guestin}</p>
                  </div>
                  <div className='col-4 border-200 fw-600 border-md-200 border-bottom border-md-end todaysummary-text' >
                    <p>{t('dashboardchart.todaysummary.guestout')}</p>
                    <p>{todaysummary?.guestout}</p>
                  </div>
                  <div className='col-4 border-200 fw-600 border-md-200 border-bottom border-md-end todaysummary-text' >
                    <p>{t('dashboardchart.todaysummary.guestbalance')}</p>
                    <p>{todaysummary?.guestbalance}</p>
                  </div>
                  <div className='col-4 border-200 fw-600 border-md-200 border-bottom border-md-end todaysummary-text'>
                    <p>{t('dashboardchart.todaysummary.visitorin')}</p>
                    <p>{todaysummary?.visitorin}</p>
                  </div>
                  <div className='col-4 border-200 fw-600 border-md-200 border-bottom border-md-end todaysummary-text'>
                    <p>{t('dashboardchart.todaysummary.visitorout')}</p>
                    <p>{todaysummary?.visitorout}</p>
                  </div>
                  <div className='col-4 border-200 fw-600 border-md-200 border-bottom border-md-end todaysummary-text'>
                    <p>{t('dashboardchart.todaysummary.visitorbalance')}</p>
                    <p>{todaysummary?.visitorbalance}</p>
                  </div>

                </div>
              </div>
            </div>
          )}

          {userPermissions.includes('Weekly Visitors Chart') && (
            <div className="card overflow-hidden  mb-3" style={{ height: "fit-content" }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-4 ">
                    <h6 className="mb-0 chart-text">
                      {t('dashboardchart.todaysummary.weeklyheading')} ({formatDate(startDate)} - {formatDate(endDate)}, {endDate.getFullYear()})
                      {/* Weekly visitors overview(17th - 23rdsep, 2023) */}
                    </h6>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 g-1" >
                    <button
                      className="btn btn-primary  btn-sm float-end"
                      onClick={() => handleSaveAsImage('jpeg')}
                    >
                      <OpenInNewSharpIcon />
                      <span className="ms-1 d-sm-inline">
                        {t('dashboard.exportjpg')}
                      </span>
                    </button>

                    <button
                      className="btn mx-1 btn-primary  btn-sm float-end"
                      onClick={() => handleSaveAsImage('png')}
                    >
                      <OpenInNewSharpIcon />
                      <span className="ms-1 d-sm-inline ">
                        {t('dashboard.exportpng')}
                      </span>
                    </button>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-2 weekly-detail' >
                    <div>
                      <span>{weeklycount?.visitor}</span><br />
                      <span>{t('dashboardchart.weeklyoverview.visitors')}</span>
                    </div>
                    <hr />
                    <div>
                      <span>{weeklycount?.guest}</span><br />
                      <span>{t('dashboardchart.weeklyoverview.guest')}</span>
                    </div>
                    <hr />
                    <div>
                      <span>{weeklycount?.casual}</span><br />
                      <span>{t('dashboardchart.weeklyoverview.casual')}</span>
                    </div>
                    <hr />
                    <div>
                      <span>{weeklycount?.supllier}</span><br />
                      <span>{t('dashboardchart.weeklyoverview.supplier')}</span>
                    </div>
                  </div>


                  <div className='col-10'>
                    <TrafficSourceChart ref={chartRef} />
                  </div>

                </div>
              </div>
            </div>
          )}
        </div>



        <div className="row mb-3 g-1 dashboard" >
          {userPermissions.includes('Location Visits Chart') && (
            <div className="card overflow-hidden  mb-3" style={{ height: "fit-content" }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-4 heading-text">
                    <h6 className="mb-0 chart-text">
                      {t('dashboardchart.locationvisit.heading')} ({monthName}, {year})
                      {/* Location Visits(September,2023) */}
                    </h6>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8  g-1" >
                    <button
                      className="btn btn-primary btn-sm float-end"
                      onClick={() => handlelocationSaveAsImage('jpg')}
                    >
                      <OpenInNewSharpIcon />
                      {/* <FontAwesomeIcon icon="plus" /> */}
                      <span className="ms-1 d-sm-inline">
                        {t('dashboard.exportjpg')}
                      </span>
                    </button>

                    <button
                      className="btn mx-1 btn-primary btn-sm float-end"
                      onClick={() => handlelocationSaveAsImage('png')}
                    >
                      <OpenInNewSharpIcon />
                      {/* <FontAwesomeIcon icon="plus" /> */}
                      <span className="ms-1 d-sm-inline ">
                        {t('dashboard.exportpng')}
                      </span>
                    </button>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <pieChartContext.Provider value={{ location }}>
                      <PieBasicChart chartRef={chartlocationRef} />
                    </pieChartContext.Provider>
                  </div>
                </div>
              </div>
            </div>
          )}

          {userPermissions.includes('Department Visits Chart') && (
            <div className="card overflow-hidden  mb-3" style={{ height: "fit-content" }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-4 heading-text">
                    <h6 className="mb-0 chart-text">
                      {t('dashboardchart.departmentsvisit.heading')} ({monthName}, {year})
                      {/* Department Visits(September, 2023) */}
                    </h6>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8  g-1" >
                    <button
                      className="btn btn-primary btn-sm float-end"
                      onClick={() => handledepartSaveAsImage('jpg')}
                    >
                      <OpenInNewSharpIcon />
                      <span className="ms-1 d-sm-inline">
                        {t('dashboard.exportjpg')}
                      </span>
                    </button>

                    <button
                      className="btn mx-1 btn-primary btn-sm float-end"
                      onClick={() => handledepartSaveAsImage('png')}
                    >
                      <OpenInNewSharpIcon />
                      <span className="ms-1 d-sm-inline ">
                        {t('dashboard.exportpng')}
                      </span>
                    </button>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <pieChartContext.Provider value={{ depart }}>
                      <PieBasicChart1 chartRef={chartdepartRef} />
                    </pieChartContext.Provider>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>


        <div className="row mb-3 g-1 justify-content-between">
          {userPermissions.includes('Guest Summary Chart') && (
            <div className="card overflow-hidden col-xl-7 mb-3" style={{ height: "fit-content" }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-4 heading-text">
                    <h6 className="mb-0 chart-text">
                      {t('dashboardchart.guestsummary.heading')}
                      {/* Guest Summary */}
                    </h6>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8  g-1" >
                    <button
                      className="btn btn-primary btn-sm float-end"
                      onClick={() => handleguestsummarySaveAsImage('jpg')}
                    >
                      <OpenInNewSharpIcon />
                      {/* <FontAwesomeIcon icon="plus" /> */}
                      <span className="ms-1 d-sm-inline">
                        {t('dashboard.exportjpg')}
                      </span>
                    </button>

                    <button
                      className="btn mx-1 btn-primary btn-sm float-end"
                      onClick={() => handleguestsummarySaveAsImage('png')}
                    >
                      <OpenInNewSharpIcon />
                      {/* <FontAwesomeIcon icon="plus" /> */}
                      <span className="ms-1 d-sm-inline ">
                        {t('dashboard.exportpng')}
                      </span>
                    </button>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <BasicAreaLineChart chartRef={chartguestsummaryRef} />
                  </div>
                </div>
              </div>
            </div>
          )}

          {userPermissions.includes('Events') && (
            <div className="card overflow-hidden col-xl-4 mb-3" style={{ height: "fit-content" }}>
              <div className="card-header">
                <div className="row">
                  {/* <div className="col-xl-6 col-lg-6 col-md-4 heading-text">
                <h6 className="mb-0 chart-text">
                  Events
                </h6>
              </div> */}
                  {/* <div className="col-xl-6 col-lg-6 col-md-8 " >
                <button
                  className="btn btn-primary btn-sm float-end"
                  onClick={() => downloadJPG()}
                >
                  <OpenInNewSharpIcon />
                  <span className="ms-1 d-sm-inline">
                    {t('dashboard.exportjpg')}
                  </span>
                </button>

                <button
                  className="btn mx-1 btn-primary btn-sm float-end"
                  onClick={() => downloadPNG()}
                >
                  <OpenInNewSharpIcon />
                  <span className="ms-1 d-sm-inline ">
                    {t('dashboard.exportpng')}
                  </span>
                </button>
              </div> */}
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <CalendarManagement />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="row mb-3 g-1">
          {userPermissions.includes('Visitor Summary Chart') && (
            <div className="card overflow-hidden col-xl-7 mb-3" style={{ height: "fit-content" }}>
              <div className="card-header">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-4 heading-text">
                    <h6 className="mb-0 chart-text">
                      {t('dashboardchart.visitorsummary.heading')}
                      {/* Visitor Summary */}
                    </h6>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8  g-1" >
                    <button
                      className="btn btn-primary btn-sm float-end"
                      onClick={() => handlevisitorsummarySaveAsImage('jpg')}
                    >
                      <OpenInNewSharpIcon />
                      {/* <FontAwesomeIcon icon="plus" /> */}
                      <span className="ms-1 d-sm-inline">
                        {t('dashboard.exportjpg')}
                      </span>
                    </button>

                    <button
                      className="btn mx-1 btn-primary btn-sm float-end"
                      onClick={() => handlevisitorsummarySaveAsImage('png')}
                    >
                      <OpenInNewSharpIcon />
                      {/* <FontAwesomeIcon icon="plus" /> */}
                      <span className="ms-1 d-sm-inline ">
                        {t('dashboard.exportpng')}
                      </span>
                    </button>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <BasicAreaLineChart1 chartRef={chartvisitorsummaryRef} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </> :
        <>
          <Card className="d-flex w-100" style={{ height: "80vh" }}>
            <Card.Body className="p-5 h-100">
              <h2>{`Welcome, ${userName}`}</h2>
            </Card.Body>
          </Card>
        </>}
    </>
  );
};

export default Dashboard;
