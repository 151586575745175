import React from 'react';
import { useTranslation } from 'react-i18next';
import Flex from 'components/common/Flex';
import LoginForm from 'components/authentication/LoginForm';
import Logo from "../../../assets/logo/Centenary_Logo.png"
import '../css/login.css'

const Login = () => {
  const { t } = useTranslation();
  return (
    < >
      <Flex justifyContent="center" alignItems="center" className="mb-2" direction="column">
        <img src={Logo} className='login-company-logo' />
        <h2 className="mt-4">{t("loginPage.heading")}</h2>
        <p className='text-center text-black'>{t("loginPage.description")}</p>
      </Flex>
      <LoginForm />
    </>
  )
};

export default Login;
