import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import ReactTable from 'components/common/ReactTable';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Swalcolor from 'components/configuration/sweetalert-config';
import { encodeData } from 'components/common/encodeParams';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { rolelist } from 'services/masterModuleService'
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';

export default function UserrolesMaster() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [userroleData, setUserRoleData] = useState([]);
    const { isLoading, setIsLoading } = useContext(loaderContext)

    useEffect(async () => {
        checkModulePermission("User Roles")
        setIsLoading(true)
        try {
            const { data } = await rolelist();
            setUserRoleData(data.data);
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, []);

    const onEditRow = (id) => {
        console.log("id", id)
        const roleName = userroleData.find((role) => role?.id === id)?.name;
        navigate("/masters/user-roles/edituserrolesmaster", { state: { id, roleName } });
    };
    // const onDeleteRow = id => {
    //     Swal.fire({
    //         title: t(
    //             'departmentMaster.deleteDepartment.sweetAlert.confirmationTitle'
    //         ),
    //         text: t('departmentMaster.deleteDepartment.sweetAlert.confirmationText'),
    //         icon: 'warning',
    //         showCancelButton: Swalcolor.confirmation.showCancelButton,
    //         confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
    //         cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
    //         confirmButtonText: t(
    //             'departmentMaster.deleteDepartment.sweetAlert.confirmationBtnLabel'
    //         )
    //     }).then(async result => {
    //         if (result.isConfirmed) {
    //             try {
    //                 // const { data } = await deleteDepartment(id);
    //                 // console.log(data);
    //                 // setDepartmentData(departmentData.filter(data => data.id != id));
    //                 Swal.fire({
    //                     icon: 'success',
    //                     title: t(
    //                         'departmentMaster.deleteDepartment.sweetAlert.simpleTitle'
    //                     ),
    //                     text: data.data.result,
    //                     confirmButtonColor: Swalcolor.simple.confirmButtonColor
    //                 });
    //             } catch (e) {
    //                 errorMessage(e)
    //             }
    //         }
    //     });
    // };

    const columns = [
        {
            Header: t('userroles.userrolestable.no'),
            // accessor: 'no'
            accessor: (row, index) => index + 1,
        },
        {
            Header: t('userroles.userrolestable.rollname'),
            accessor: 'name'
        },
        {
            Header: t('userroles.userrolestable.status'),
            Cell: ({ row }) => (
                <div>
                    {row?.original?.status == 1 ? "Active" : "Inactive"}
                </div>
            ),
        },
        {
            Header: t('userroles.userrolestable.action'),
            Cell: ({ row }) => (
                <>
                    <div>
                        <span
                            className="btn p-0 me-2" style={{ display: 'flex' }}
                            title="Edit"
                            onClick={() => onEditRow(row.original.id)}
                        >
                            <FontAwesomeIcon icon="edit" />
                            <span style={{ marginLeft: ".7rem" }}>{t('userroles.userrolestable.permission')}</span>
                        </span>


                    </div>

                </>
            ),
            disableSortBy: true
        }
    ];

    return (
        <>
            <div className="row g-3 mb-3">
                <div className="">
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="heading-color fw-600 mb-0">
                                        {t('userroles.userrolesheading')}
                                    </h3>
                                </div>
                                {/* <div className="col-4">
                                    <button
                                        className="btn btn-primary btn-sm float-end"
                                        onClick={() => {
                                            navigate('/master/department-master/create-department');
                                        }}
                                    >
                                        <FontAwesomeIcon icon="plus" />
                                        <span className="ms-1 d-sm-inline d-none">
                                            {t('masters.departments.departmentcreatebtn')}
                                        </span>
                                    </button>
                                </div> */}
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <ReactTable
                                columns={columns}
                                data={userroleData}
                                pageSizeOption={[5, 10, 15, 20]}
                                headerClass="btn-primary"
                                isSearchBoxVisible={true}
                                ispaginationvisible={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
