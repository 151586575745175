import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import formPattern from 'components/configuration/form-pattern';
import InputPassword from 'components/common/InputPassword';
import { emailgateway } from 'services/applicationSettingService'
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';

function EmailSettings() {
    const { t } = useTranslation();
    const [emailData, setEmailData] = useState()
    const navigate = useNavigate();
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        clearErrors,
        formState: { errors }
    } = useForm();


    useEffect(async () => {
        checkModulePermission("Email Settings")
        setIsLoading(true)
        try {
            const { data } = await emailgateway({ is_update: 0 })
            setEmailData(data?.data[0])
            reset(data?.data[0])
            setValue("mailing_password_confirmation", data?.data[0]?.mailing_password)
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, [])

    const onSubmit = async (accessData) => {
        console.log("accessdata", accessData)
        setIsLoading(true)
        try {
            const { data } = await emailgateway({ is_update: 1, mailing_password_confirmation: accessData.mailing_password, ...accessData })
            toast.success(data?.message, toastConfig);
            // navigate('/master/access-control');
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
        console.log(accessData);
    };
    const onReset = () => {
        setValue("mailserver", "")
        setValue("outport", "")
        setValue("username", "")
        setValue("mailing_password", "")
        setValue("mailing_password_confirmation", "")
        setValue("driver", "")
        setValue("encryption", "")
        setValue("sendername", "")
        setValue("email", "")
        setValue("enable_ssl", "")
        setValue("required_mail_authentication", "")
        setValue('accessControlName', '');
        clearErrors()
    };
    return (
        <>
            <div className="mb-3 row g-3">
                <div className="card overflow-hidden mb-3">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="heading-color fw-600">
                                    {t('emailSettings.heading.emailSettings')}
                                </h3>
                            </div>
                            <div className="col-12 mb-3">
                                <hr />
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className='row'>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('emailSettings.label.mailServer')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.mailserver && 'is-invalid'
                                                }`}
                                            placeholder={t('emailSettings.placeholder.mailServer')}
                                            {...register('mailserver', { required: true })}
                                        />
                                        {errors.mailserver && (
                                            <small>
                                                {errors.mailserver.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('emailSettings.error.mailServerError')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('emailSettings.label.outport')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.outport && 'is-invalid'
                                                }`}
                                            placeholder={t('emailSettings.placeholder.outport')}
                                            {...register('outport', { required: true })}
                                        />
                                        {errors.outport && (
                                            <small>
                                                {errors.outport.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('emailSettings.error.outportError')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('emailSettings.label.userName')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.username && 'is-invalid'
                                                }`}
                                            placeholder={t('emailSettings.placeholder.userName')}
                                            {...register('username', { required: true })}
                                        />
                                        {errors.username && (
                                            <small>
                                                {errors.username.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('emailSettings.error.userNameError')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className="form-label">
                                            {t('emailSettings.label.password')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <Controller
                                            name="mailing_password"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => {
                                                return (
                                                    <InputPassword
                                                        value={value}
                                                        onChange={onChange}
                                                        isInvalid={errors.mailing_password ? true : false}
                                                        placeholder="emailSettings.placeholder.password"
                                                    />
                                                )
                                            }}
                                        />
                                        {errors.mailing_password && (
                                            <small>
                                                {errors.mailing_password.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('emailSettings.error.passwordError')}
                                                    </span>
                                                )}
                                                {errors.mailing_password.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('emailSettings.error.passwordPattern')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label className="form-label">
                                            {t('emailSettings.label.confirmpassword')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <Controller
                                            name="mailing_password_confirmation"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => {
                                                return (
                                                    <InputPassword
                                                        value={value}
                                                        onChange={onChange}
                                                        isInvalid={errors.mailing_password_confirmation ? true : false}
                                                        placeholder="emailSettings.placeholder.confirmpassword"
                                                    />
                                                )
                                            }}
                                        />
                                        {errors.mailing_password_confirmation && (
                                            <small>
                                                {errors.mailing_password_confirmation.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('emailSettings.error.confirmpasswordError')}
                                                    </span>
                                                )}
                                                {errors.mailing_password_confirmation.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('emailSettings.error.passwordPattern')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('emailSettings.label.driver')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.driver ? 'is-invalid' : ''
                                                }`}
                                            type="text"
                                            placeholder={t('emailSettings.placeholder.driver')}
                                            {...register('driver', {
                                                required: true
                                            })}
                                        />
                                        {errors.driver && (
                                            <small className="text-danger">
                                                {errors.driver.type === 'required' && (
                                                    <span>{t('emailSettings.error.driverError')}</span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('emailSettings.label.encryption')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.encryption ? 'is-invalid' : ''
                                                }`}
                                            type="text"
                                            placeholder={t('emailSettings.placeholder.encryption')}
                                            {...register('encryption', {
                                                required: true
                                            })}
                                        />
                                        {errors.encryption && (
                                            <small className="text-danger">
                                                {errors.encryption.type === 'required' && (
                                                    <span>{t('emailSettings.error.encryptionError')}</span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('emailSettings.label.senderName')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.sendername ? 'is-invalid' : ''
                                                }`}
                                            type="text"
                                            placeholder={t('emailSettings.placeholder.senderName')}
                                            {...register('sendername', {
                                                required: true
                                            })}
                                        />
                                        {errors.sendername && (
                                            <small className="text-danger">
                                                {errors.sendername.type === 'required' && (
                                                    <span>{t('emailSettings.error.senderNameError')}</span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('emailSettings.label.email')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.email ? 'is-invalid' : ''
                                                }`}
                                            type="text"
                                            placeholder={t('emailSettings.placeholder.email')}
                                            {...register('email', {
                                                required: true,
                                                pattern: formPattern.email
                                            })}
                                        />
                                        {errors.email && (
                                            <small className="text-danger">
                                                {errors.email.type === 'required' && (
                                                    <span>{t('emailSettings.error.emailError')}</span>
                                                )}
                                                {errors.email.type === 'pattern' && (
                                                    <span>{t('emailSettings.error.emailPattern')}</span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    {...register('required_mail_authentication')}
                                                />
                                                <label className="form-check-label">
                                                    {t('emailSettings.label.requiredMailAuthentication')}
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    {...register('enable_ssl')}
                                                />
                                                <label className="form-check-label">
                                                    {t('emailSettings.label.enableSSL')}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 mt-3 text-end">
                                    <button type="submit" className="btn btn-primary me-1">
                                        {t('emailSettings.btnLabel.save')}
                                    </button>
                                    <button
                                        type="reset"
                                        className="btn btn-danger"
                                        onClick={onReset}
                                    >
                                        {t('emailSettings.btnLabel.reset')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailSettings