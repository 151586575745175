import React, { FC, Suspense, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import '../src/assets/css/color.css';
import '../src/assets/css/general.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery/dist/jquery.min.js'; // Have to install and import jQuery because of bootstrap modal's dependency
import './i18n';
import { getIPv4 } from 'components/common/helper-function/BrowserData';

const App = () => {
  useEffect(async () => {
    console.log("process.env.BASE_URL", process.env.REACT_APP_BASEROUTE_URL)
    const ipv4 = await getIPv4();
    localStorage.setItem("IPv4", ipv4)
  }, [])
  return (
    <Router basename={process.env.REACT_APP_BASEROUTE_URL}>
      <Suspense fallback={null}>
        <Layout />
      </Suspense>
    </Router>
  );
};

export default App;
