import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigation } from 'react-router-dom';
import NavbarTop from 'components/navbar/top/NavbarTop';
import AppContext from 'context/Context';
import Footer from 'components/footer/Footer';
import classNames from 'classnames';
import axios from 'axios';
import FrontDeskTopNavBar from 'components/navbar/top/FrontDeskTopNavBar';


export default function FrontDeskLayout() {
    const { hash, pathname } = useLocation();
    const isKanban = pathname.includes('kanban');
    // const isChat = pathname.includes('chat');

    const {
        config: { isFluid, navbarPosition }
    } = useContext(AppContext);

    useEffect(() => {
        setTimeout(() => {
            if (hash) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
            }
        }, 0);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className='font-face'>
            <div className={classNames('content', { 'pb-0': isKanban })}>
                <FrontDeskTopNavBar />
                {/*------ Main Routes ------*/}
                <Outlet />
                {!isKanban && <Footer footerContentColor="main-screen-footer" />}
            </div>
        </div>
    );
}
