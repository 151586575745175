import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import ReactTable from 'components/common/ReactTable';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Swalcolor from 'components/configuration/sweetalert-config';
import { encodeData } from 'components/common/encodeParams';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { accesslist, accessdelete } from 'services/accessControlService'
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';



export default function AccessControl() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isLoading, setIsLoading } = useContext(loaderContext)

    const [accesscontrolData, setAccessControlData] = useState([]);

    useEffect(async () => {
        checkModulePermission("Access Control")
        setIsLoading(true)
        try {
            const { data } = await accesslist();
            setAccessControlData(data?.data);
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, []);

    const onEditRow = (id) => {
        const editdata = accesscontrolData.find((access) => access.id === id)
        navigate('/masters/edit-access-control', { state: editdata });
    };
    const onDeleteRow = (id) => {
        Swal.fire({
            title: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationTitle'
            ),
            text: t('departmentMaster.deleteDepartment.sweetAlert.confirmationText'),
            icon: 'warning',
            showCancelButton: Swalcolor.confirmation.showCancelButton,
            confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
            cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
            confirmButtonText: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationBtnLabel'
            )
        }).then(async result => {
            if (result.isConfirmed) {
                setIsLoading(true)
                try {
                    const { data } = await accessdelete({ id });
                    console.log(data);
                    setAccessControlData(accesscontrolData.filter(data => data.id != id));
                    Swal.fire({
                        icon: 'success',
                        title: t(
                            'departmentMaster.deleteDepartment.sweetAlert.simpleTitle'
                        ),
                        text: data.data.result,
                        confirmButtonColor: Swalcolor.simple.confirmButtonColor
                    });
                } catch (e) {
                    errorMessage(e)
                }
                setIsLoading(false)
            }
        });
    };

    const columns = [

        {
            Header: t('accesscontrol.accesscontroltable.no'),
            accessor: (row, index) => index + 1,
        },
        {
            Header: t('accesscontrol.accesscontroltable.doorname'),
            accessor: 'door_name'
        },
        {
            Header: t('accesscontrol.accesscontroltable.doorip'),
            accessor: 'door_ip'
        },
        {
            Header: t('accesscontrol.accesscontroltable.status'),
            accessor: 'status'
        },
        {
            Header: t('accesscontrol.accesscontroltable.action'),
            Cell: ({ row }) => (
                <div>
                    <span
                        className="btn p-0 me-2"
                        title="Edit"
                        onClick={() => onEditRow(row.original.id)}
                    >
                        <FontAwesomeIcon icon="edit" />
                    </span>
                    <span
                        className="btn p-0"
                        title="Delete"
                        onClick={() => onDeleteRow(row.original.id)}
                    >
                        <FontAwesomeIcon icon="trash-alt" />
                    </span>
                </div>
            ),
            disableSortBy: true
        }

    ];

    return (
        <>
            <div className="row g-3 mb-3">
                <div className="">
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-xl-8 col-lg-6 col-md-6 heading-text">
                                    <h3 className="heading-color fw-600 mb-0">
                                        {t('accesscontrol.accesscontrolheading')}
                                    </h3>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 table-btn" >
                                    <button
                                        className="btn btn-primary btn-sm float-end"
                                        onClick={() => {
                                            navigate('/masters/add-access-control');
                                        }}
                                    >
                                        <span className="ms-1 d-sm-inline">
                                            {t('accesscontrol.add')}
                                        </span>
                                    </button>
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <ReactTable
                                columns={columns}
                                data={accesscontrolData}
                                pageSizeOption={[5, 10, 15, 20]}
                                headerClass="btn-primary"
                                isSearchBoxVisible
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
