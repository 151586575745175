import httpServices from "./httpServices";
import { parameterSetup, visitor } from "./config";

//Parameter settings api
export const getNotificationSettingsList = async () => {
    return await httpServices.get(parameterSetup.getNotificationSettingsList)
}
export const updateNotificationSettings = async (data) => {
    return await httpServices.post(parameterSetup.updateNotificationSettingsList, data)
}
export const workingHoursService = async (data) => {
    return await httpServices.post(parameterSetup.workingHours, data)
}
export const generalSettingService = async (data) => {
    return await httpServices.post(parameterSetup.generalSettings, data)
}
