import toastConfig from 'components/configuration/toast-config';
import { t } from 'i18next'
import React from 'react'
import { Modal, Button, Form, } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


import { eventlistapprove } from 'services/manageEventsService'

export default function EventApproveModal({ isModalVisible, setIsModalVisible, approveUserId, events, setEvents, }) {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        setError,
        watch
    } = useForm();

    const onSubmit = async (datatext) => {

        try {
            const { data } = await eventlistapprove({ event_id: approveUserId ? approveUserId : 0, status: 1, comment: datatext.textarea })
            toast.success(data.message, toastConfig)
            setIsModalVisible(false)
            setEvents(events.map((event) => {
                if (event?.event_id == approveUserId) {
                    event.status = 1
                }
                return event
            }))

            navigate('/manageevents/event-approval');
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
    }
    return (
        <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)} size="xl" centered={true}>
            <Modal.Header closeButton className="bg-light border-bottom-0">
                <Modal.Title>
                    <h2>{t('eventapproval.heading')}</h2>
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <textarea
                        rows="8"
                        className={`form-control`}
                        {...register('textarea')}
                        placeholder={t("eventapproval.placeholder")}
                    ></textarea>
                </Modal.Body>
                <Modal.Footer className="bg-light px-card border-top-0">
                    <button className='btn btn-primary' type='submit'>{t('eventapproval.submit')}</button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
