import React, { useState, useEffect, useMemo } from 'react';
import ReactTable from 'components/common/ReactTable';
// import { getVisitors, logOutVisitor } from 'services/frontDeskServices';
import toastConfig from 'components/configuration/toast-config';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { bookingdata } from '../visitorLogout/Config'
import { Modal, Button, Form, } from 'react-bootstrap';
import '../../../assets/css/modal.css';
import 'font-awesome/css/font-awesome.min.css';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { listVisitorAppointments } from 'services/visitorAppointmentService'
import { visitorRegistrationVerifyOTP } from 'services/visitorRegistrationService'
import { loaderContext } from 'context/Context';
import { useContext } from 'react';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';


export default function VisitorAppointments() {
    const navigate = useNavigate();
    const [visitorData, setVisitorData] = useState([]);
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useContext(loaderContext)


    const { control, handleSubmit, formState: { errors }, reset } = useForm();

    // const [isModalOpen, setIsModalOpen] = useState(false);

    // const handleOpenModal = () => {
    //     setIsModalOpen(true);
    // };

    // const handleCloseModal = () => {
    //     reset();
    //     setIsModalOpen(false);
    // }

    useEffect(async () => {
        checkModulePermission("guard")

        setIsLoading(true)
        try {
            const { data } = await listVisitorAppointments();
            setVisitorData(data.data);
            console.log('run', data.data);
        } catch (e) {
            toast.error(e.message, toastConfig);
        }
        setIsLoading(false)
    }, []);


    const handleregister = async (id) => {
        setIsLoading(true)
        try {
            const newData = visitorData.find((userid) => userid.id === id)
            const { data } = await visitorRegistrationVerifyOTP({ phone: newData.phone, country_code: newData.country_code })
            toast.success(data?.data[0]?.otp, toastConfig)
            toast.success(data?.message, toastConfig)
            console.log("newDataaaa", newData)
            console.log(newData)
            navigate('/visitor-registration', { state: { data: data?.data[0]?.visitor_details ? data?.data[0]?.visitor_details : '', isAppointment: true, isVisible: true, id: newData?.id } })
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }

    const columns = [
        {
            Header: t('visitorappointments.no'),
            accessor: (row, index) => index + 1,
            headerClass: 'text-white',
        },
        {
            Header: t('visitorappointments.visitorname'),
            accessor: (row) => `${row.visitor_fname} ${row.visitor_lname}`,
            headerClass: 'text-white',
        },
        {
            Header: t('visitorappointments.hostname'),
            accessor: 'staff_name',
            headerClass: 'text-white',
        },
        {
            Header: t('visitorappointments.appointmentdate'),
            accessor: 'date_of_appointment',
            headerClass: 'text-white',
            Cell: ({ row }) => (
                moment(row.original.appointment_date).format('DD/MM/yyyy')
            )
        },
        {
            Header: t('visitorappointments.appointmenttime'),
            accessor: 'start_time',
            headerClass: 'text-white',
            // Cell: ({ row }) => {
            //     return row?.original?.start_time ? moment.utc(row?.original?.start_time, "HH:mm:ss").local().format(process.env.REACT_APP_TIME_FORMAT_24) : "--"
            // },
            Cell: ({ row }) => (
                moment(row.original.start_time, process.env.REACT_APP_TIME_FORMAT_12).format(process.env.REACT_APP_FRONTDESK_TIME_FORMAT_12)
            )
        },
        {
            Header: t('visitorappointments.action'),
            headerClass: 'text-white',
            className: 'text-center',
            Cell: ({ row }) => (
                <button
                    className="btn-register  border-0 btn-sm"
                    onClick={() => handleregister(row.original.id)}
                >
                    {t('bookings.btnregister')}
                </button>
            ),
            disableSortBy: true,
        }

    ];

    return (
        <>
            <div className="container-fluid event-mainn" >
                <div className=" bg-img card table-body overflow-hidden">
                    <ReactTable
                        columns={columns}
                        data={visitorData}
                        headerClass="btn-primary-frontdesk"
                        hedingName="visitorappointments.tableheading"
                        isSearchBoxVisibleFrontdesk={false}
                        isSearchBoxVisible={false}
                        ispaginationvisible={true}
                        widthClass="table-width-frontdesk"
                        showHeader={true}
                    />
                </div>
            </div>


            {/* {
                isModalOpen && (
                    <Modal show={isModalOpen} onHide={handleCloseModal} >
                        <div className='model-content'>
                            <Modal.Header className='modal-heading'>
                                <Modal.Title className='modal-title'>{t('bookings.bookingsmodal.modalheading')}</Modal.Title>
                                <i closeButton className="fa  fa-times-circle modal-close-btn" aria-hidden="true" onClick={handleCloseModal}></i>
                            </Modal.Header>
                            <Modal.Body className='modalbody'>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='row ps-4 pe-4'>
                                        <div className='col-6'>
                                            <label className='label-text'>{t('bookings.bookingsmodal.destination')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                name="destination"
                                                control={control}
                                                defaultValue=""
                                                // rules={{ required: true }}
                                                render={({ field }) => <input {...field} type="text" value="Pinks Rooms" className='form-control modal-input border-0' style={{ background: "#00000013" }} disabled />}
                                            />

                                        </div>
                                        <div className='col-6'>
                                            <label className='label-text'>{t('bookings.bookingsmodal.accesscardno')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                name="accesscardno"
                                                control={control}
                                                defaultValue=""
                                                rules={{ required: true, pattern: { value: /^[a-zA-Z0-9]+$/ } }}
                                                render={({ field }) => <input {...field} type="text" placeholder={t('bookings.bookingsmodal.accessfield')} className={`form-control modal-input  ${errors.accesscardno && 'is-invalid'
                                                    }`} />}
                                            />

                                            {errors.accesscardno && errors.accesscardno.type === "required" && <span className="text-danger error-message">{t('bookings.error.accesserrorrequired')}</span>}
                                            {errors.accesscardno && errors.accesscardno.type === "pattern" && <span className="text-danger error-message">{t('bookings.error.accesserrorpattern')}</span>}

                                        </div>
                                    </div>
                                    <div className='row ps-4 pe-4'>
                                        <div className='col-6'>
                                            <label className='label-text'>{t('bookings.bookingsmodal.otp')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                name="otp"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: true,
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                    },
                                                }}
                                                render={({ field }) => <input {...field} type="text" placeholder={t('bookings.bookingsmodal.otpfield')} className={`form-control modal-input  ${errors.otp && 'is-invalid'
                                                    }`} />}
                                            />
                                            {errors.otp && errors.otp.type === "required" && <span className="text-danger error-message ">{t('bookings.error.otprequired')}</span>}
                                            {errors.otp && errors.otp.type === "pattern" && <span className="text-danger error-message">{t('bookings.error.otppattern')}</span>}

                                        </div>
                                        <div className='col-6 modal-submit'>
                                            <button type='submit' className='btn w-100 modal-submit-btn'>{t('bookings.bookingsmodal.submitbtn')}</button>
                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </div>
                    </Modal>
                )
            } */}
        </>
    );
}
