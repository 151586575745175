import axios from 'axios';
import browserData from 'components/common/helper-function/BrowserData';
import momenttz from 'moment-timezone'



axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (error.response.status == 401) {
    localStorage.removeItem('authToken');
    window.location.replace('/login');
    return Promise.reject(error);
  } else if (!expectedError) {
    return Promise.reject(error);
  }
  return Promise.reject(error);
});
axios.interceptors.request.use(
  (request) => {
    const authToken = localStorage.getItem("authToken")
    request.headers['Authorization'] = `Bearer ${authToken}`;
    // request.withCredentials = true
    // request.headers['Access-Control-Allow-Headers'] = "base64:C/xN5AHCp1lYvjMg/h/q8K5REo1HQBXpO+qzKiYpMtg="
    // if (request.data) {
    //   if (request.data.logDetails) {
    //     const { logDetails, ...data } = request.data
    //     request.data = data
    //     request.headers["logData"] = JSON.stringify(logDetails)
    //   }
    // }
    // if (request.url?.includes("geolocation-db.com")) {
    //   return request;
    // }
    // request.headers['browserData'] = JSON.stringify(browserData());
    request.headers['Content-Type'] = "application/json";
    request.headers['Accept'] = "application/json";
    if (!request.url.includes("apisrv")) {
      request.headers['TimeZone'] = momenttz.tz.guess();
    }




    return request;
  }
);
export default {
  get: axios.get,
  post: axios.post,
  delete: axios.delete,
  patch: axios.patch,
  put: axios.put
};
