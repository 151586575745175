const baseUrl = process.env.REACT_APP_BASE_URL;
// const baseUrl = 'http://192.168.1.18/php/secuviz_backend/public/api/'
const memberUrl = process.env.REACT_APP_MEMBER_URL;
const mockUrl = "http://192.168.0.116:1080/"

export const authEndpoints = {
  login: `${baseUrl}signin`,
  // login: `${baseUrl}/user/login`,
  forgotPassword: `${baseUrl}forgot-password`,
  resetPassword: `${baseUrl}reset-password`,
  verifyOTP: `${baseUrl}verify-otp`,
  authenticateToken: `${baseUrl}/user/authenticate-token`,
  logout: `${baseUrl}logout`
};

export const parameterSetup = {
  //Parameter Settings
  getNotificationSettingsList: `${baseUrl}notification-setting-list`,
  updateNotificationSettingsList: `${baseUrl}update-notification-setting`,
  workingHours: `${baseUrl}update-working-hours`,
  generalSettings: `${baseUrl}general-setting-details`,
}

export const applicationSettingEndPoints = {
  smsgateway: `${baseUrl}sms-gateway-details`,
  whatsappgateway: `${baseUrl}whatsapp-gateway-details`,
  emailgateway: `${baseUrl}mailing-details`,
  getmanagefrontdesk: `${baseUrl}manage-frontdesk-list`,
  updatefrontdesk: `${baseUrl}update-manage-frontdesk`,
}

export const manageEventsEndPoints = {
  listlocation: `${baseUrl}location-list`,
  listlocationactive: `${baseUrl}get-location-list`,
  locationdel: `${baseUrl}delete-location`,
  addeventlocation: `${baseUrl}add-location`,
  listevent: `${baseUrl}get-event-list`,
  approveevent: `${baseUrl}update-event-status`,
  createevent: `${baseUrl}create-event`,
  deleteevent: `${baseUrl}delete-event`,
  editevent: `${baseUrl}update-event`,
  bookaguest: `${baseUrl}book-guest`,
  listbookguest: `${baseUrl}book-guest-list`,
  editbookguest: `${baseUrl}edit-book-guest`,
  deletebookguest: `${baseUrl}delete-book-guest`,
  listidentification: `${baseUrl}list-identification-types`,
  deleteguest: `${baseUrl}delete-event-guest`,
  addsingleguest: `${baseUrl}add-event-single-guest`
}

export const masterEndPoints = {
  listdepartment: `${baseUrl}department-list`,
  adddepartment: `${baseUrl}add-department`,
  deletedepartment: `${baseUrl}delete-department`,
  listrole: `${baseUrl}role`,
  getrolelist: `${baseUrl}get-role-list`,
  listpermission: `${baseUrl}list-permissions`,
  updatepermission: `${baseUrl}create-permission`,
}
export const accesscontrolEndPoints = {
  listaccess: `${baseUrl}access-control-list`,
  listdropdown: `${baseUrl}get-device-list`,
  addaccess: `${baseUrl}add-access-control`,
  deleteaccess: `${baseUrl}delete-access-control`,
  updateaccess: `${baseUrl}update-access-control`
}

export const accessmappingEndPoints = {
  listaccessmapping: `${baseUrl}access-mapping-list`,
  listaccessmappingdropdown: `${baseUrl}get-access-mapping-control-list`,
  addaccessmapping: `${baseUrl}create-access-mapping`,
  deleteaccessmapping: `${baseUrl}delete-access-mapping`,
  updateaccessmapping: `${baseUrl}update-access-mapping`
}

export const systemuserEndPoints = {
  listsystemuser: `${baseUrl}system-user-list`,
  addsystemuser: `${baseUrl}create-system-user`,
  listdepartment: `${baseUrl}list-department-types`,
  deleteuser: `${baseUrl}delete-system-user`,
  updateuser: `${baseUrl}update-system-user`,
}

export const membersEndPoints = {
  listmember: `${baseUrl}get-members-list`,
  addmember: `${baseUrl}add-members`,
  listmembercategory: `${baseUrl}get-only-members-category-list`,
  deletemember: `${baseUrl}delete-members`,
  updatemember: `${baseUrl}edit-members`,
}

export const casualworkerEndPoints = {
  listcasualworker: `${baseUrl}casual-workers-list`,
  addcasualworker: `${baseUrl}create-casual-workers`,
  deletecasualworker: `${baseUrl}delete-casual-workers`,
  updatecasualworker: `${baseUrl}update-casual-workers`,
  visitortypedropdown: `${baseUrl}get-visitor-type`,
  companytypedropdown: `${baseUrl}get-company-type`,
}
//members aide endpoints parth
export const aideEndPoints = {
  listaide: `${baseUrl}aide-list`,
  addaide: `${baseUrl}create-aide`,
  deleteaide: `${baseUrl}delete-aide`,
  updateaide: `${baseUrl}update-aide`,
}


export const suppliercontractorEndPoints = {
  listsupplier: `${baseUrl}supplier-contractor-list`,
  addsupplier: `${baseUrl}create-supplier-contractor`,
  deletesupplier: `${baseUrl}delete-supplier-contractor`,
  updatesupplier: `${baseUrl}update-supplier-contractor`,
}

export const blacklistEndPoints = {
  listblack: `${baseUrl}get-blacklist-visitor`,
  addblack: `${baseUrl}create-blacklist-visitor`,
  uploadblack: `${baseUrl}upload-blacklist-visitor`,
  deleteblack: `${baseUrl}delete-blacklist-visitor`,
  searchlist: `${baseUrl}get-search-visitor-list`,
  visitortype: `${baseUrl}get-blacklist-type`
}

export const appointmentEndPoints = {
  listappointment: `${baseUrl}visitor-appoitment-list`,
  addappointment: `${baseUrl}create-visitor-appoitment`,
  updateappointment: `${baseUrl}visitor-appoitment-update`,
  deleteappointment: `${baseUrl}delete-visitor-appoitment`,
  listidentification: `${baseUrl}visitor-identification-list`,
  staffList: `${baseUrl}get-staff-list`
}

export const messagetempEndPoints = {
  listmessage: `${baseUrl}message-template-list`,
  updatemessage: `${baseUrl}update-message-template`
}

export const backupEndPoints = {
  listbackup: `${baseUrl}db-backup-list`,
  listmanualbackup: `${baseUrl}manual-backup`,
  savebackup: `${baseUrl}db-backup`,
  deletebackup: `${baseUrl}delete-db-backup`
}

export const sosEndPoints = {
  sendsos: `${baseUrl}sos-message`
}

//Guest Booking endpoints
export const guestBookingEndPoint = {
  bookingGuest: `${baseUrl}book-guest-external`,
  fullbookingGuest: `${baseUrl}full-book-guest-external`,
  addEvents: `${baseUrl}create-event-external`,
  getExternalEventList: `${baseUrl}external-event-list`,
  updateExternalEvent: `${baseUrl}update-event-external`,
  uploadCsv: `${baseUrl}upload-csv-external-event`,
  getLocationsExternal: `${baseUrl}external-location-list`,
  getExternalIdentificationType: `${baseUrl}external-list-identification-types`,
  getExternalEventData: `${baseUrl}external-event-detail`,
  addExternalGuest: `${baseUrl}add-external-event-single-guest`
}

//Common endpoints
export const commonEndpoints = {
  identificationTypeList: `${baseUrl}list-identification-types`,
  getMemberDetails: `${memberUrl}apisrv/index`,
  verifyAccessCard: `${baseUrl}scan-card`,
  verifyMemberAccessCard: `${baseUrl}scan-member-card`,// verifyMemberAccessCard end point added by parth
};

export const commonScanDocEndPoints = {
  imageIdentity: `${baseUrl}verify-image-identity`
}

export const commonreportEndPoints = {
  listvisitortype: `${baseUrl}list-visitor-types`,
  listdestination: `${baseUrl}list-destinations`,
  listregister: `${baseUrl}get-register-list`,
}

// reports

export const reportEndPoints = {
  listsummaryreport: `${baseUrl}summary-report`,
  listvisitreport: `${baseUrl}visits-report`,
  listbookingreport: `${baseUrl}bookings-report`,
  listevent: `${baseUrl}events-report`,
  listblacklist: `${baseUrl}blacklist-report`,
  listsos: `${baseUrl}sos-report`,
  listbypass: `${baseUrl}bypass-report`,
  listvisitordetail: `${baseUrl}visitor-details-report`,
  listchangelog: `${baseUrl}change-log-report`,
  listtimevisit: `${baseUrl}6-times-visit-report`,
  listguestsummary: `${baseUrl}guest-summary-report`,
  listguestvisit: `${baseUrl}guest-visit-report`,
  listvisithours: `${baseUrl}visit-hours-exceeded-report`,
  listguestbooking: `${baseUrl}guest-bookings-report`,
  listqr: `${baseUrl}qrcode-counter-report`,
  numbervisit: `${baseUrl}get-no-of-visit-count-list`
};

// dashboard
export const dashboardEndPoints = {
  listdashboardtodaysummary: `${baseUrl}dashboard/today-summary`,
  listdashboardguestsummary: `${baseUrl}dashboard/guest-summary`,
  listdashboardweeklyoverview: `${baseUrl}dashboard/weekly-visitor-overview`,
  listdashboardvisitorsummary: `${baseUrl}dashboard/visitor-summary`,
  listeventdashboard: `${baseUrl}dashboard/events-summary`,
  listdashboardyear: `${baseUrl}dashboard/year-list`,
  listdashboardmonth: `${baseUrl}dashboard/month-list`,
  listdashboardlocation: `${baseUrl}dashboard/location-visit`,
  listdashboarddepartment: `${baseUrl}dashboard/department-visit`,
};


// frontdesk config //

export const guestRegistrationEndPoints = {
  getDestinations: `${baseUrl}list-destinations`,
  registerGuest: `${baseUrl}register-multiple-guest`,
  registerSingleGuest: `${baseUrl}register-single-guest`,
  guestRegistrationOtp: `${baseUrl}guest-registration-otp`,
  identificationTypeList: `${baseUrl}list-identification-types`,
  getMemberDetails: `${memberUrl}apisrv/index`,
  sendotpbyguestdocument: `${baseUrl}send-otp-guest-to-document`,
  checkvalidguest: `${baseUrl}check-valid-guest`,
  sendbypassotp: `${baseUrl}send-bypass-guest-registration-otp`,
  verifysendbypassotp: `${baseUrl}verify-bypass-guest-registration-otp`,
}
export const guestBookingEndPoints = {
  listGuest: `${baseUrl}list-guests`,
  registerGuest: `${baseUrl}guest/1`,
}
export const visitorRegistrationEndPoints = {
  listVisitorType: `${baseUrl}list-visitor-types`,
  listDepartment: `${baseUrl}list-department-types`,
  listEmployee: `${baseUrl}list-employees`,
  listVisitorFrom: `${baseUrl}list-visitor-from`,
  visitorRegister: `${baseUrl}visitor`,
  verifyOtp: `${baseUrl}send-visitor-otp`,
  bypass: `${baseUrl}verify-admin-details`,
  guestbypass: `${baseUrl}bypass-register-guest`,
  getmanagefield: `${baseUrl}manage-frontdesk-list`,
  sendotpbyvisitordocument: `${baseUrl}send-otp-visitor-to-document`
}
export const visitorAppointmentEndPoints = {
  listVisitorAppointment: `${baseUrl}visitor_appoitment`,
  visitorAppointmentRegisterData: `${baseUrl}update-visitor-appoitment`,
  visitorBypass: `${baseUrl}visitor-appoitment-bypass`
}
export const eventEndPoints = {
  listevent: `${baseUrl}event-list`,
  status: `${baseUrl}guest-arrive-status`
}
export const common = {
  getDocType: `${baseUrl}/get-document-types`,
  listsupplier: `${baseUrl}supplier-contractor-list`,
  verifyAccessCard: `${baseUrl}scan-card`
};
export const logoutEndPoints = {
  logout: `${baseUrl}logout-visitor`,
  listLogout: `${baseUrl}logout-list`
}


