import { accessmappingEndPoints } from "./config"
import httpServices from "./httpServices"

export const accessmappinglist = async () => {
    return await httpServices.get(accessmappingEndPoints.listaccessmapping)
}
export const accessmappingdropdownlist = async () => {
    return await httpServices.get(accessmappingEndPoints.listaccessmappingdropdown)
}
export const accessmappingadd = async (accessmappingData) => {
    return await httpServices.post(accessmappingEndPoints.addaccessmapping, accessmappingData)
}
export const accessmappingdelete = async (id) => {
    return await httpServices.post(accessmappingEndPoints.deleteaccessmapping, id)
}
export const accessmappingupdate = async (updatedaccessmappingData) => {
    return await httpServices.post(accessmappingEndPoints.updateaccessmapping, updatedaccessmappingData)
}