import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, getPastDates, rgbaColor } from 'helpers/utils';
import dayjs from 'dayjs';
import { tooltipFormatter } from 'helpers/echart-utils';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { weeklyoverviewdashboardlist } from 'services/dashboardService'
import download from 'downloadjs';


echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const fixedDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const getOptions = () => ({

  color: [
    getColor('primary'),
    rgbaColor(getColor('primary'), 0.8),
    rgbaColor(getColor('primary'), 0.6),
    rgbaColor(getColor('primary'), 0.4),
    rgbaColor(getColor('primary'), 0.2)
  ],
  // legend: {
  //   data: ['Visitors', 'Guest', 'Casuals', 'Suppliers'],
  //   left: 5,
  //   itemWidth: 10,
  //   itemHeight: 10,
  //   borderRadius: 0,
  //   icon: 'circle',
  //   inactiveColor: getColor('400'),
  //   textStyle: { color: getColor('700') },
  //   itemGap: 20
  // },
  xAxis: {
    type: 'category',
    // data: getPastDates(7).map(date => dayjs(date).format('DD MMM, YYYY')),
    data: fixedDays,
    axisLine: {
      show: false
    },
    splitLine: {
      lineStyle: {
        color: getColor('200')
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: getColor('600'),
      // formatter: value => dayjs(value).format('ddd')
    }
  },
  yAxis: {
    type: 'value',
    position: 'right',
    // interval: 5,
    splitLine: {
      lineStyle: {
        color: getColor('200')
      }
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      show: true,
      color: getColor('600'),
      margin: 15
    }
  },
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    axisPointer: {
      type: 'none'
    },
    backgroundColor: getColor('100'),
    borderColor: getColor('300'),
    textStyle: { color: getColor('700') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: tooltipFormatter
  },


  series: [
    {
      name: 'Visitors',
      type: 'bar',
      stack: 'total',
      barWidth: 20,
      data: [320, 302, 301, 334, 390, 330, 320]
    },
    {
      name: 'Guest',
      type: 'bar',
      stack: 'total',
      barWidth: 20,
      data: [120, 132, 101, 134, 90, 230, 210]
    },
    {
      name: 'Casuals',
      type: 'bar',
      stack: 'total',
      barWidth: 20,
      data: [220, 182, 191, 234, 290, 330, 310]
    },
    {
      name: 'Suppliers',
      type: 'bar',
      stack: 'total',
      barWidth: 20,
      data: [150, 212, 201, 154, 190, 330, 410]
    },
    // {
    //   name: 'Other',
    //   type: 'bar',
    //   stack: 'total',
    //   data: [820, 832, 901, 934, 1290, 1330, 1320],
    //   itemStyle: {
    //     borderRadius: [5, 5, 0, 0]
    //   }
    // }
  ],

  grid: {
    containLabel: true,
    right: '5px',
    left: 0,
    bottom: 0,
    top: '15%'
  }
});

const TrafficSourceChart = React.forwardRef((props, ref) => {

  const [chartOption, setChartOption] = useState({})
  useEffect(async () => {
    try {
      const chartoption = getOptions()
      const { data: weekly } = await weeklyoverviewdashboardlist()
      const guestcount = weekly?.data[0].weekly_chart_list?.map((data) => data?.guest_count)
      const visitorcount = weekly?.data[0].weekly_chart_list?.map((data) => data?.visitor_count)
      const casualcount = weekly?.data[0].weekly_chart_list?.map((data) => data?.casual_count)
      const suppliercount = weekly?.data[0].weekly_chart_list?.map((data) => data?.supplier_count)


      chartoption.series[0].data = chartoption.series[0].name == "Visitors" ? visitorcount : []
      chartoption.series[1].data = chartoption.series[1].name == "Guest" ? guestcount : []
      chartoption.series[2].data = chartoption.series[2].name == "Casuals" ? casualcount : []
      chartoption.series[3].data = chartoption.series[3].name == "Suppliers" ? suppliercount : []


      setChartOption(chartoption)
    } catch (error) {
      errorMessage(error)
    }
  }, [])

  // const chartRef = useRef(null);

  // const handleSaveAsImage = (imageType) => {
  //   if (chartRef.current) {
  //     const chartInstance = chartRef.current.getEchartsInstance();
  //     if (chartInstance) {
  //       const base64Image = chartInstance.getDataURL({
  //         type: `image/${imageType}`, // 'png' or 'jpeg'
  //         pixelRatio: 2 // Increase pixel ratio for better image quality
  //       });

  //       // Download the image
  //       download(base64Image, `chart.${imageType}`, `image/${imageType}`);
  //     }
  //   }
  // };
  return (
    <>

      {/* <div>
        <button className="btn mx-1 btn-primary btn-sm float-end" onClick={() => handleSaveAsImage('png')}>Export as PNG</button>
        <button className="btn btn-primary btn-sm float-end" onClick={() => handleSaveAsImage('jpeg')}>Export as jpg</button>
      </div> */}
      <ReactEChartsCore
        ref={ref}
        echarts={echarts}
        option={chartOption}
        style={{ height: '21.875rem' }}
      />

    </>
  );
});

export default TrafficSourceChart;
