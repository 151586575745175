import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import countryCodes, {
  defaultCountryCode
} from 'components/configuration/country-code';
import PhoneNumber from 'components/common/PhoneNumber';
import pattern from 'components/configuration/form-pattern';
import './css/visitor-registration.css';
import ReactDatePicker from 'components/common/ReactDatePicker';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { genderOptions } from 'components/configuration/common-options';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import AddAssetModel from './asset/AddAssetModal';
// import EditAssetModel from './asset/EditAssetModal';
// import AssetTableView from './asset/AssetTable';
// import sweetalertConfig from 'components/configuration/sweetalert-config';
// import {
//   getCompanies,
//   getDepartments,
//   getEmployees
// } from 'services/masterService';
// import { addVisitorRegistration, sendOTP } from 'services/frontDeskServices';
import BypassModal from './BypassModal';
import moment from 'moment';
import momenttz from 'moment-timezone'
// import { getAppointment } from 'services/adminService';
import { useLocation, useNavigate, useNavigation, useParams } from 'react-router-dom';
// import { getVisitorType } from 'services/parameterSetupService'
import { getDocType, listsupplier, verifyAccessCard } from 'services/commonService'
import { getListOfVisitorType, getListOfDepartment, getListOfEmployee, visitorRegister, getmanagefrontdeskfield, getListOfVisitorFrom, visitorRegistrationVerifyOTPbyDoc } from 'services/visitorRegistrationService'
import { getListOfIdentificationType } from 'services/guestRegistrationService'
import { visitorRegistrationVerifyOTP, } from 'services/visitorRegistrationService'
import CapturePhotoModal from 'components/common/CapturePhotoModal';
import CaptureDocumentModal from 'components/common/CaptureDocumentModal';
import { visitorRegisterData } from 'services/visitorAppointmentService'
import axios from 'axios';
import { commonScanDocEndPoints } from 'services/config';
import { loaderContext } from 'context/Context';
import { useContext } from 'react';
import PhoneNumberFrontdesk from 'components/common/PhoneNumberFrontdesk';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';



export default function VisitorRegistration() {
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const navigate = useNavigate()
  const [countryCode, setCountryCode] = useState(defaultCountryCode);
  // const [towersList, setTowersList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [visitorData, setVisitorData] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [visitorFromList, setVisitorFromList] = useState([]);
  const [showAssetModel, setShowAssetModel] = useState(false);
  const [showLpoNumber, setShowLpoNumber] = useState(false);
  const [showRefNumber, setShowRefNumber] = useState(false);
  const [showBypassModal, setShowBypassModal] = useState(false);
  const [showEditAssetModel, setEditShowAssetModel] = useState(false);
  const [editableAssetId, setEditableAssetId] = useState('');
  const [listOfAssets, setListOfAssets] = useState([]);
  const [selectCompany, setSelectCompany] = useState({});
  const [hostList, setHostList] = useState([]);
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [appointmentData, setAppointmentData] = useState({});
  const [employeeName, setEmployeeName] = useState([])
  const [filteredEmployeeName, setFilteredEmployeeName] = useState([])
  const [visitorType, setVisitorType] = useState([]);
  const [docType, setDocType] = useState([])
  const [identitySection, setIdentitySection] = useState(true);
  const [byPassType, setByPassType] = useState();
  const [submitPress, setSubmitPress] = useState(false)
  const [userPhoto, setUserPhoto] = useState("");
  const [frontDocPhoto, setFrontDocPhoto] = useState("");
  const [backDocPhoto, setBackDocPhoto] = useState("");
  const [isCaptureFrontDocModalOpen, setIsCaptureFrontDocModalOpen] = useState(false)
  const [isCaptureBackDocModalOpen, setIsCaptureBackDocModalOpen] = useState(false)
  const [isCapturePhotoModalOpen, setIsCapturePhotoModalOpen] = useState(false);
  const [isOTPPress, setIsOtpPress] = useState(false)
  const [appointmentId, setAppointmentId] = useState("");
  const [isVisible, setIsVisible] = useState(false)
  const [requiredField, setRequiredField] = useState([])
  const [visitorDisabled, setVisitorDisabled] = useState([])
  const [companyAllList, setCompanyAllList] = useState([]);
  const [frontdocerror, setFrontDocError] = useState(false)
  const [backdocerror, setBackDocError] = useState(false)
  const [clearVisitorFrom, setClearVisitorFrom] = useState(false)
  const [isPhoneRequired, setIsPhoneRequired]= useState(false)
  //selected host type for members encoding added by parth
  const [selectedHostType, setSelectedHostType] = useState();

  //handle selected host type for members encoding by parth
  const handleSelectHostType = (event) => {
    setSelectedHostType(event.target.value);
  };
  const isMobile = navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i) ? true : false
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  
  const assetTableHeaders = [
    {
      path: 'typeOfAsset',
      label: t('visitorRegistration.assetTableLabel.typeOfAsset')
    },
    {
      path: 'make',
      label: t('visitorRegistration.assetTableLabel.make')
    },
    {
      path: 'serialNo',
      label: t('visitorRegistration.assetTableLabel.serialNo')
    },
    {
      path: 'remark',
      label: t('visitorRegistration.assetTableLabel.remark')
    },
    {
      label: t('visitorRegistration.assetTableLabel.action'),
      content: dataId => (
        <div className="d-flex">
          <button
            className="btn p-0 me-2"
            title="Edit asset"
            type="button"
            onClick={() => handleOpenEditAssetModel(dataId)}
          >
            {' '}
            <FontAwesomeIcon icon="edit" />
          </button>
          <button
            className="btn p-0"
            type="button"
            onClick={() => onDeleteAsset(dataId)}
            title="Delete asset"
          >
            <FontAwesomeIcon icon="trash-alt" />
          </button>
        </div>
      )
    }
  ];

  console.log("location", location)

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
    control,
    setValue,
    clearErrors,
    setError,
    watch,
    unregister
  } = useForm();

  const departmentName = watch("department")
  const employeesName = watch("employee")
  const visitorDoc = watch("visitor_doc_number")
  console.log("Employee Name======>", employeesName)


  useEffect(() => {

    if (departmentName)
    {
      // setValue("employee", "")
      console.log("Employessss", employeeName)
      setFilteredEmployeeName(employeeName.filter(employee => employee?.department_id == departmentName?.value).map((emp => ({ label: emp?.name, value: emp?.id }))))
    }
  }, [departmentName])

  useEffect(async () => {
    checkModulePermission("guard")

    setIsLoading(true)
    try
    {
      const { data } = await getListOfVisitorType();
      const { data: departmentList } = await getListOfDepartment();
      const { data: visitorFromList } = await getListOfVisitorFrom();
      const { data: employeeList } = await getListOfEmployee();
      const { data: identificationType } = await getListOfIdentificationType();
      const { data: company } = await listsupplier();
      setCompanyAllList(company?.data?.filter((comp) => comp?.status == "Active"))
      setVisitorType(data?.data?.map((visitor) => ({ label: visitor.name, value: visitor.id })))
      setDepartmentList(departmentList?.data?.map((department) => ({ label: department.name, value: department.id })))
      setVisitorFromList(visitorFromList?.data?.map((visitorfrom) => ({ label: visitorfrom.company_name, value: visitorfrom.id })))
      setEmployeeName(employeeList?.data)
      setDocType(identificationType?.data?.map((doc) => ({ documentName: doc.name, id: doc.id })))

      if (location?.state?.isAppointment === true)
      {
        const editData = location?.state?.data
        console.log("editdataaaa====---", editData)
        setAppointmentId(location?.state?.id)
        console.log("editdataaaa", editData)
        setCountryCode((editData?.country_code && editData?.country_code != "") ? countryCodes.find(country => country?.dial_code == editData?.country_code) : defaultCountryCode)
        setValue('visitor_name', editData.name)
        setValue('doc_id_type', editData.identification_type_id?.toString())
        setValue('doc_id_number', editData.doc_id_number)
        setValue('visitor_from', editData.visitor_from)
        setValue('purpose', editData.purpose)
        setValue('remarks', editData.notes)
        setValue('phone', editData.phone)
        setValue('visitor_type', data?.data?.map((visitor) => ({ label: visitor.name, value: visitor.id })).find((visitorty) => visitorty.value == editData?.visitor_type_id))
        setValue("nationality", editData.nationality)
        setValue("dob", (editData.dob && editData.dob != "") ? moment(editData?.dob).toDate() : "")
        setValue('gender', genderOptions.find((gender) => gender.label == editData?.gender));
        setProfilePhoto(editData?.profile_photo ? `data:image/jpeg;base64,${editData?.profile_photo}` : "")
        setFrontDocPhoto(editData?.front ? `data:image/jpeg;base64,${editData?.front}` : "")
        setBackDocPhoto(editData?.back ? `data:image/jpeg;base64,${editData?.back}` : "")
      }
    } catch (e)
    {
      if (e.response.data)
      {
        if (Array.isArray(e.response.data.message))
        {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else
        {
          toast.error(e.response.data.message, toastConfig)
        }
      } else
      {
        toast.error(e.message, toastConfig)
      }
    }
    setIsLoading(false)
  }, [])

  const getAccessCardNumber = async () => {
    setIsLoading(true)
    try
    {
      const { data } = await verifyAccessCard({ host_type: selectedHostType })
      setValue("access_card_no", data?.data[0]?.access_card_no)
      clearErrors("access_card_no")
    } catch (e)
    {
      if (e.response.data)
      {
        if (Array.isArray(e.response.data.message))
        {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else
        {
          toast.error(e.response.data.message, toastConfig)
        }
      } else
      {
        toast.error(e.message, toastConfig)
      }
    }
    setIsLoading(false)
  }

  const visitorContactNo = watch('visitorContactNo');
  const companyId = watch('companyId');
  const departmentId = watch('departmentId');
  const selectedVisitor = watch("visitor_type");
  const documentType = watch("doc_id_type");
  const otpData = watch('otp');
  const phoneNumber = watch("phone")
  const lpoNumber = watch("lpo_number");
  const visitorfrom = watch("visitor_from");
  // const visitordoc = watch("visitor_doc_number");

 



  // useEffect(() => {
  //   if (documentType) {
  //     setFrontDocPhoto("");
  //     setBackDocPhoto("");
  //     setValue("visitor_name", "")
  //     setValue("doc_id_number", "")
  //     setValue("nationality", "")
  //     setValue("dob", "")
  //     setValue("gender", "")
  //   }
  // }, [documentType])

  // useEffect(() => {
  //   if (clearVisitorFrom) {
  //     setValue("visitor_from", "")
  //     setClearVisitorFrom(false)
  //   }
  // }, [clearVisitorFrom])

  // useEffect(() => {
  //   if (visitorfrom && visitorfrom != "") {
  //     const companyData = companyAllList.find((comp) => comp?.id == visitorfrom?.value)
  //     setValue("lpo_number", companyData?.lpo_number)
  //   }
  // }, [visitorfrom])

  // useEffect(() => {
  //   if (selectedVisitor?.label === "Supplier") {
  //     if (lpoNumber && lpoNumber != "") {
  //       console.log("lpo1==>", lpoNumber)
  //       const companyData = companyAllList.find((comp) => comp?.lpo_number == lpoNumber)
  //       console.log("company1==>", companyData)

  //       if (companyData) {
  //         setValue("visitor_from", { label: companyData?.company_name, value: companyData?.id })
  //         clearErrors("visitor_from")
  //       } else {
  //         setValue("visitor_from", "")
  //       }
  //     }
  //   }

  // }, [lpoNumber])


  useEffect(() => {
    if (selectedVisitor?.label === "Supplier")
    {
      setShowLpoNumber(true);
      clearErrors("visitor_from")
    } else
    {
      setValue("visitor_from", "")
      setValue("lpo_number", "")
      setShowLpoNumber(false);
    }
  }, [selectedVisitor])

  useEffect(() => {
    if (selectedVisitor?.label === "Casual Workers")
    {
      if (typeof visitorfrom == "object")
      {
        setValue("visitor_from", "")
      }
      setShowRefNumber(true);
    } else
    {
      setShowRefNumber(false);
    }
  }, [selectedVisitor])

  useEffect(() => {
    if (submitPress)
    {
      if (!otpData && otpData === "")
      {
        setError("otp", { type: "required" })
      }
    }
  }, [submitPress])

  const resetByPassVisitor = () => {
    if (location?.state?.isAppointment === true)
    {
      reset();
      setValue('phone', location?.state?.data.phone)
      setValue('department', '');
      setValue('visitor_from', '');
      setValue('dob', '');
      setValue('gender', '');
      setValue('visitor_type', '');
      setValue('employee', '');
      setProfilePhoto("")
      setFrontDocPhoto("")
      setBackDocPhoto("")
      clearErrors()
      setIsVisible(false)
      setCountryCode(defaultCountryCode)
    } else
    {
      reset();
      setValue('visitor_name', '')
      setValue('doc_id_type', '')
      setValue('doc_id_number', '')
      setValue('nationality', '')
      setValue('lpo_number', '')
      setValue('visitor_from', '')
      setValue('purpose', '')
      setValue('access_card_no', '')
      setValue('no_of_pax', '')
      setValue('vehicle_reg_no', '')
      setValue('remarks', '')
      setValue('otp', '')
      setValue('department', '');
      setValue('dob', '');
      setValue('gender', '');
      setValue('visitor_type', '');
      setValue('employee', '');
      setValue('phone', '')
      setEmail("");
      setCountryCode(defaultCountryCode)
      setProfilePhoto("")
      setFrontDocPhoto("")
      setBackDocPhoto("")
      clearErrors()
      setIsVisible(false)
    }
  };

  const getDataFrontDocument = async () => {
    setIsLoading(true)
    try
    {
      const frontImg = frontDocPhoto
      const backImg = backDocPhoto
      console.log("Front Image", frontImg)
      console.log("backPhoto", backImg)
      const { data } = await axios.post(commonScanDocEndPoints.imageIdentity, { front: frontImg.split(',')[1], back: backImg.split(',')[1], type: documentType }, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      const documentData = data?.data[0]?.document_data;
      setValue("gender", genderOptions.find((gender) => gender.value === documentData?.gender))
      setValue("visitor_name", documentData?.name)
      setValue("doc_id_number", documentData?.id_card_number)
      setValue("nationality", documentData?.nationality)
      { (documentData?.dob && documentData?.dob != "") && setValue("dob", moment(documentData?.dob, process.env.REACT_APP_DISPLAY_DATE_FORMAT).toDate()) }
      clearErrors("gender")
      clearErrors("visitor_name")
      clearErrors("doc_id_number")
      clearErrors("nationality")
      clearErrors("dob")
    } catch (e)
    {
      if (e.response.data)
      {
        if (Array.isArray(e.response.data.message))
        {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else
        {
          toast.error(e.response.data.message, toastConfig)
        }
      } else
      {
        toast.error(e.message, toastConfig)
      }
    }
    setIsLoading(false)
  }

  const getDataFromPassport = async () => {
    setIsLoading(true)
    try
    {
      const frontImg = frontDocPhoto
      const { data } = await axios.post(commonScanDocEndPoints.imageIdentity, { front: frontImg.split(',')[1], type: documentType }, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      const documentData = data?.data[0]?.document_data;
      setValue("gender", genderOptions.find((gender) => gender.value === documentData?.gender))
      setValue("visitor_name", documentData?.name)
      setValue("doc_id_number", documentData?.id_card_number)
      setValue("nationality", documentData?.nationality)
      setValue("dob", moment(documentData?.dob, process.env.REACT_APP_DISPLAY_DATE_FORMAT).toDate())
      clearErrors("gender")
      clearErrors("visitor_name")
      clearErrors("doc_id_number")
      clearErrors("nationality")
      clearErrors("dob")
    } catch (e)
    {
      if (e.response.data)
      {
        if (Array.isArray(e.response.data.message))
        {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else
        {
          toast.error(e.response.data.message, toastConfig)
        }
      } else
      {
        toast.error(e.message, toastConfig)
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (documentType != "4")
    {
      if (documentType != "3")
      {
        if (frontDocPhoto && frontDocPhoto != "" && backDocPhoto && backDocPhoto != "")
        {
          getDataFrontDocument()
        }
      } else
      {
        if (frontDocPhoto && frontDocPhoto != "")
        {
          getDataFromPassport()
        }
      }
    }

  }, [frontDocPhoto, backDocPhoto])
  // useEffect(() => {
  //   if (location?.state?.isAppointment === true) {
  //     const editData = location?.state?.data
  //     console.log("editdataaaa====---", editData)
  //     setAppointmentId(location?.state?.id)
  //     console.log("editdataaaa", editData)
  //     setCountryCode((editData?.country_code && editData?.country_code != "") ? countryCodes.find(country => country?.dial_code == editData?.country_code) : defaultCountryCode)
  //     setValue('visitor_name', editData.name)
  //     setValue('doc_id_type', editData.identification_type_id?.toString())
  //     setValue('doc_id_number', editData.doc_id_number)
  //     setValue('visitor_from', editData.visitor_from)
  //     setValue('purpose', editData.purpose)
  //     setValue('remarks', editData.notes)
  //     setValue('phone', editData.phone)
  //     setValue('visitor_type', visitorType.find((visitorty) => visitorty.value == editData?.visitor_type_id))
  //     setValue("nationality", editData.nationality)
  //     setValue("dob", (editData.dob && editData.dob != "") ? moment(editData?.dob).toDate() : "")
  //     setValue('gender', genderOptions.find((gender) => gender.label == editData?.gender));
  //     setProfilePhoto(editData?.profile_photo ? `data:image/jpeg;base64,${editData?.profile_photo}` : "")
  //     setFrontDocPhoto(editData?.front ? `data:image/jpeg;base64,${editData?.front}` : "")
  //     setBackDocPhoto(editData?.back ? `data:image/jpeg;base64,${editData?.back}` : "")
  //     // setCountryCode(countryCodes.find((country) => country.dial_code === editData.country_code))
  //     // setValue('', editData.country_code)
  //   }
  // }, [])


  useEffect(async () => {
    setIsLoading(true)
    try
    {
      const { data } = await getmanagefrontdeskfield();
      console.log("---====", data)
      setRequiredField(data.data)
    } catch (error)
    {
      console.log(error)
    }
    setIsLoading(false)
  }, [])

  console.log("required", requiredField)

  useEffect(() => {
    if(visitorDoc != ""){
      setIsPhoneRequired(false)
    }else{
      setIsPhoneRequired(true)
    }
   }, [visitorDoc])

  useEffect(() => {
    if (isOTPPress)
    {
      if (phoneNumber && phoneNumber != "")
      {
        if (!pattern.phoneNumber.test(phoneNumber))
        {
          setError("phone", { type: "pattern" })
        } else
        {
          clearErrors("phone")
        }
      }
      // else {
      //   setError("phone", { type: "required" })
      // }
    }
  }, [phoneNumber])
  useEffect(() => {
    if (isVisible)
    {
      setIsVisible(false)
    }
  }, [phoneNumber])

  const onSendOTP = async () => {
    setIsLoading(true)
    try
    {
      if (location?.state?.isAppointment === true)
      {
        if (phoneNumber && phoneNumber != "")
        {
          if (pattern.phoneNumber.test(phoneNumber))
          {
            clearErrors("phone")
            const { data } = await visitorRegistrationVerifyOTP({ phone: phoneNumber, country_code: countryCode.dial_code })
            setIsVisible(true)
            toast.success(data?.data[0]?.otp, toastConfig)
            toast.success(data?.message, toastConfig)
            if (!isVisible)
            {
              if (data?.data[0]?.visitor_details)
              {
                const newData = data?.data[0]?.visitor_details;
                const { otp, ...restData } = newData;

                console.log("new Data", newData)
                // restData.gender = genderOptions.find((gender) => gender.label == restData?.gender);
                restData.visitor_type = visitorType.find((visitorty) => visitorty.value == restData?.visitor_type_id)
                restData.department = departmentList.find((department) => department.value == restData?.department_type_id) ? departmentList.find((department) => department.value == restData?.department_type_id) : ""
                restData.employee = employeeName.find((employee) => employee.id == restData?.employee_id)
                restData.dob = (restData.dob && restData.dob != "") ? moment(restData?.dob).toDate() : "";
                restData.visitor_name = restData?.name
                restData.doc_id_type = restData?.identification_type_id
                restData.doc_id_number = restData?.doc_id_number
                console.log("Reset Data", restData)
                // reset(restData)

              }
            }
          } else
          {
            setError("phone", { type: "pattern" })
          }
        } else
        {
          setError("phone", { type: "required" })
        }
      } else
      {
        if (phoneNumber && phoneNumber != "")
        {
          if (pattern.phoneNumber.test(phoneNumber))
          {
            clearErrors("phone")
            const { data } = await visitorRegistrationVerifyOTP({ phone: phoneNumber, country_code: countryCode.dial_code })
            setIsVisible(true)
            toast.success(data?.data[0]?.otp, toastConfig)
            toast.success(data?.message, toastConfig)
            if (!isVisible)
            {
              if (data?.data[0]?.visitor_details)
              {
                const newData = data?.data[0]?.visitor_details;
                const { otp, ...restData } = newData;

                console.log("new Data", newData)
                console.log("Emplodsfjdsfdsjf===>", employeeName)
                const employeeNeedToSet = employeeName.find((employee) => employee.id == restData?.employee_id)
                console.log("Neeef To employee set", employeeNeedToSet)
                // setCountryCode((restData?.country_code && restData?.country_code != "") ? countryCodes.find(country => country?.dial_code == restData?.country_code) : defaultCountryCode)
                restData.gender = genderOptions.find((gender) => gender.label == restData?.gender);
                restData.visitor_type = visitorType.find((visitorty) => visitorty.value == restData?.visitor_type_id)
                restData.department = departmentList.find((department) => department.value == restData?.department_type_id) ? departmentList.find((department) => department.value == restData?.department_type_id) : ""
                restData.dob = (restData.dob && restData.dob != "") ? moment(restData?.dob).toDate() : "";
                restData.visitor_name = restData?.name
                restData.doc_id_type = restData.identification_type_id <= 4 ? restData?.identification_type_id?.toString() : "4"
                restData.doc_id_number = restData?.doc_id_number
                restData.visitor_from = (restData.visitor_type_id == "4" && !restData?.visitor_from) ? restData?.company_name : restData?.visitor_from
                if (employeeNeedToSet)
                {
                  restData.employee = { label: employeeNeedToSet?.name, value: employeeNeedToSet?.id }
                }
                console.log("Reset Data", restData)
                setVisitorDisabled(restData)
                reset(restData)
                setValue("access_card_no", '')
                setProfilePhoto(restData?.profile_photo ? `data:image/jpeg;base64,${restData?.profile_photo}` : "")
                setFrontDocPhoto(restData?.front ? `data:image/jpeg;base64,${restData?.front}` : "")
                setBackDocPhoto(restData?.back ? `data:image/jpeg;base64,${restData?.back}` : "")

              } else
              {
                setValue('visitor_name', '')
                setValue('doc_id_type', '')
                setValue('doc_id_number', '')
                setValue('nationality', '')
                setValue('lpo_number', '')
                setValue('visitor_from', '')
                setValue('purpose', '')
                setValue('access_card_no', '')
                setValue('no_of_pax', '')
                setValue('vehicle_reg_no', '')
                setValue('remarks', '')
                setValue('otp', '')
                setValue('department', '');
                setValue('dob', '');
                setValue('gender', '');
                setValue('visitor_type', '');
                setValue('employee', '');
                setProfilePhoto("")
                setFrontDocPhoto("")
                setBackDocPhoto("")
                setVisitorDisabled([''])
              }
            }
          } else
          {
            setError("phone", { type: "pattern" })
          }
        } else
        {
          setError("phone", { type: "required" })
        }
      }
    } catch (e)
    {
      if (e.response.data)
      {
        if (Array.isArray(e.response.data.message))
        {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else
        {
          toast.error(e.response.data.message, toastConfig)
        }
      } else
      {
        toast.error(e.message, toastConfig)
      }
    }
    setIsLoading(false)
  };

  const sendOTP = () => {
    console.log('click otp btn');
    setIsOtpPress(true)
    onSendOTP();
  };

  useEffect(() => {
    if (frontDocPhoto != "")
    {
      setFrontDocError(false)
    }
    if (backDocPhoto != "")
    {
      setBackDocError(false)
    }
  }, [frontDocPhoto, backDocPhoto])

  console.log("visitorData", visitorDisabled)
  console.log("timezone", momenttz.tz.guess())

  const onSubmit = async (formData) => {
    console.log("formDataaaaaaaaaaaaaaaa", formData)
    setIsLoading(true)
    let isError = false;
    if (documentType == 3 && frontDocPhoto == "")
    {
      setFrontDocError(true)
      isError = true
    }
    else if (documentType != 3)
    {
      if (frontDocPhoto == "")
      {
        setFrontDocError(true);
        isError = true
      }
      if (backDocPhoto == "")
      {
        setBackDocError(true);
        isError = true
      }
    }
   
    try
    {
      if (!isError)
      {
        if (location?.state?.isAppointment === true)
        {
          formData.department = formData?.department?.value
          formData.visitor_from = formData?.visitor_type?.value == 1 ? formData?.visitor_from?.label : formData?.visitor_from
          formData.employee = formData?.employee?.value
          formData.gender = formData?.gender?.label
          formData.lpo = formData?.lpo_number?.value
          formData.appointment_id = location?.state?.id
          formData.visitor_type = formData?.visitor_type?.value
          formData.email = null;
          formData.country_code = countryCode?.dial_code;
          formData.profile_photo = userPhoto;
          formData.front = frontDocPhoto;
          formData.back = backDocPhoto;
          formData.timezone = momenttz.tz.guess()
          formData.dob = moment(formData.dob).format(process.env.REACT_APP_DATE_FORMAT)
          console.log("Visitor Data Update======>", formData)
          const { data } = await visitorRegisterData(formData)
          if (data?.data[0]?.visited_count == 1)
          {
            toast.warning(data?.data[0]?.visited_message, toastConfig)
          } else
          {
            toast.success(data?.message, toastConfig)
          }
          resetByPassVisitor();
          navigate("/visitor-appointments")
          setIsVisible(false)
          console.log("fd", formData)
        } else
        {
          if (otpData && otpData != "")
          {
            clearErrors('otp')
            const { phone, ...visitorData } = formData
            console.log("========================", visitorData)
            visitorData.department = visitorData?.department?.value
            visitorData.visitor_from = visitorData?.visitor_type?.value == 1 ? visitorData?.visitor_from?.label : visitorData?.visitor_from
            visitorData.employee = visitorData?.employee?.value
            visitorData.gender = visitorData?.gender?.label
            visitorData.visitor_type = visitorData?.visitor_type?.value
            visitorData.email = null;
            // visitorData.phone = "2356897410"
            visitorData.phone = phoneNumber;
            visitorData.country_code = countryCode?.dial_code
            visitorData.dob = moment(visitorData?.dob)?.format(process.env.REACT_APP_DATE_FORMAT)
            visitorData.timezone = momenttz.tz.guess()
            visitorData.profile_photo = userPhoto;
            visitorData.front = frontDocPhoto;
            visitorData.back = backDocPhoto;
            const { data } = await visitorRegister(visitorData);
            if (data?.data[0]?.visited_count == 1)
            {
              toast.warning(data?.data[0]?.visited_message, toastConfig)
            } else
            {
              toast.success(data?.message, toastConfig)
            }
            setIsVisible(false)
            resetByPassVisitor();
            setCountryCode(defaultCountryCode)
            console.log("rd", visitorData)
          } else
          {
            setError("otp", { type: "required" })
          }
        }
      }
    } catch (e)
    {
      console.log(e)
      if (e.response.data)
      {
        if (Array.isArray(e.response.data.message))
        {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else
        {
          toast.error(e.response.data.message, toastConfig)
        }
      } else
      {
        toast.error(e.message, toastConfig)
      }
    }
    setIsLoading(false)
  };

  const onBypass = (visitorData) => {
    let isError = false;
    if (documentType == 3 && frontDocPhoto == "")
    {
      setFrontDocError(true)
      isError = true
    }
    else if (documentType != 3)
    {
      if (frontDocPhoto == "")
      {
        setFrontDocError(true);
        isError = true
      }
      if (backDocPhoto == "")
      {
        setBackDocError(true);
        isError = true
      }
    }
    if (!isError)
    {
      if (documentType === "3")
      {
        if (frontDocPhoto != "")
        {
          setShowBypassModal(true)
          visitorData.gender = visitorData?.gender?.label
          visitorData.department = visitorData?.department?.value
          visitorData.visitor_from = visitorData?.visitor_type?.value == 1 ? visitorData?.visitor_from?.label : visitorData?.visitor_from
          visitorData.employee = visitorData?.employee?.value
          visitorData.profile_photo = userPhoto
          visitorData.front = frontDocPhoto;
          visitorData.timezone = momenttz.tz.guess()
          visitorData.visitor_type = visitorData?.visitor_type?.value
          visitorData.dob = moment(visitorData.dob).format(process.env.REACT_APP_DATE_FORMAT)
          visitorData.country_code = countryCode?.dial_code
          setVisitorData(visitorData);
          setByPassType("OTP")
        }
      } else
      {
        if (frontDocPhoto != "" && backDocPhoto != "")
        {
          setShowBypassModal(true)
          visitorData.gender = visitorData?.gender?.label
          visitorData.department = visitorData?.department?.value
          visitorData.visitor_from = visitorData?.visitor_type?.value == 1 ? visitorData?.visitor_from?.label : visitorData?.visitor_from
          visitorData.employee = visitorData?.employee?.value
          visitorData.visitor_type = visitorData?.visitor_type?.value
          visitorData.profile_photo = userPhoto
          visitorData.front = frontDocPhoto;
          visitorData.back = backDocPhoto;
          visitorData.timezone = momenttz.tz.guess()
          visitorData.dob = moment(visitorData.dob).format(process.env.REACT_APP_DATE_FORMAT)
          visitorData.country_code = countryCode?.dial_code
          setVisitorData(visitorData);
          setByPassType("OTP")
        }
      }
    }

  }

  const setProfilePhoto = (data) => {
    setUserPhoto(data);
  }
  const setFrontDocImage = (data) => {
    setFrontDocPhoto(data);
  }
  const setBackDocImage = (data) => {
    setBackDocPhoto(data);
  }
  const handleCapture = (target) => {
    if (target.files)
    {
      if (target.files.length !== 0)
      {
        const file = target.files[0];
        const newUrl = URL.createObjectURL(file);
        setUserPhoto(newUrl);
      }
    }
  };
  const frontsideClick = () => {
    if (documentType && documentType != '')
    {
      setIsCaptureFrontDocModalOpen(true)
    } else
    {
      toast.error("Please select ID Type first.", toastConfig)
    }
  }
  const backsideClick = () => {
    if (documentType && documentType != '')
    {
      setIsCaptureBackDocModalOpen(true)
    } else
    {
      toast.error("Please select ID Type first.", toastConfig)
    }
  }

  const resetDocumentPhotos = () => {
    setFrontDocPhoto('');
    setBackDocPhoto('');
    setValue('dob', '');
    setValue('gender', '');
    setValue('doc_id_number', '')
    setValue('nationality', '')
    setValue('visitor_name', '')
  };

  const handleDocTypeChange = () => {
    resetDocumentPhotos();
  };

  const sendDocOTP=async()=>{ 
    
   if(visitorDoc != ""){
    setIsLoading(true)
    try
    {
      if (location?.state?.isAppointment === true)
      {
            const { data } = await visitorRegistrationVerifyOTPbyDoc({document_id_number:visitorDoc })
            setIsVisible(true)
            toast.success(data?.data[0]?.otp, toastConfig)
            toast.success(data?.message, toastConfig)
            if (!isVisible)
            {
              if (data?.data[0]?.visitor_details)
              {
                const newData = data?.data[0]?.visitor_details;
                const { otp, ...restData } = newData;

                console.log("new Data", newData)
                // restData.gender = genderOptions.find((gender) => gender.label == restData?.gender);
                restData.visitor_type = visitorType.find((visitorty) => visitorty.value == restData?.visitor_type_id)
                restData.department = departmentList.find((department) => department.value == restData?.department_type_id) ? departmentList.find((department) => department.value == restData?.department_type_id) : ""
                restData.employee = employeeName.find((employee) => employee.id == restData?.employee_id)
                restData.dob = (restData.dob && restData.dob != "") ? moment(restData?.dob).toDate() : "";
                restData.visitor_name = restData?.name
                restData.doc_id_type = restData?.identification_type_id
                restData.doc_id_number = restData?.doc_id_number
                restData.phone = restData?.phone
                console.log("Reset Data", restData)
                // reset(restData)

              }
            }
          
      } else
      {
            const { data } = await visitorRegistrationVerifyOTPbyDoc({document_id_number:visitorDoc })
            setIsVisible(true)
            toast.success(data?.data[0]?.otp, toastConfig)
            toast.success(data?.message, toastConfig)
            if (!isVisible)
            {
              if (data?.data[0]?.visitor_details)
              {
                const newData = data?.data[0]?.visitor_details;
                const { otp, ...restData } = newData;

                console.log("new Data", newData)
                console.log("Emplodsfjdsfdsjf===>", employeeName)
                const employeeNeedToSet = employeeName.find((employee) => employee.id == restData?.employee_id)
                console.log("Neeef To employee set", employeeNeedToSet)
                // setCountryCode((restData?.country_code && restData?.country_code != "") ? countryCodes.find(country => country?.dial_code == restData?.country_code) : defaultCountryCode)
                restData.gender = genderOptions.find((gender) => gender.label == restData?.gender);
                restData.visitor_type = visitorType.find((visitorty) => visitorty.value == restData?.visitor_type_id)
                restData.department = departmentList.find((department) => department.value == restData?.department_type_id) ? departmentList.find((department) => department.value == restData?.department_type_id) : ""
                restData.dob = (restData.dob && restData.dob != "") ? moment(restData?.dob).toDate() : "";
                restData.visitor_name = restData?.name
                restData.doc_id_type = restData.identification_type_id <= 4 ? restData?.identification_type_id?.toString() : "4"
                restData.doc_id_number = restData?.doc_id_number
                restData.phone = restData?.phone

                restData.visitor_from = (restData.visitor_type_id == "4" && !restData?.visitor_from) ? restData?.company_name : restData?.visitor_from
                if (employeeNeedToSet)
                {
                  restData.employee = { label: employeeNeedToSet?.name, value: employeeNeedToSet?.id }
                }
                console.log("Reset Data", restData)
                setVisitorDisabled(restData)
                reset(restData)
                setValue("access_card_no", '')
                setProfilePhoto(restData?.profile_photo ? `data:image/jpeg;base64,${restData?.profile_photo}` : "")
                setFrontDocPhoto(restData?.front ? `data:image/jpeg;base64,${restData?.front}` : "")
                setBackDocPhoto(restData?.back ? `data:image/jpeg;base64,${restData?.back}` : "")

              } else
              {
                setValue('visitor_name', '')
                setValue('doc_id_type', '')
                setValue('doc_id_number', '')
                setValue('nationality', '')
                setValue('lpo_number', '')
                setValue('visitor_from', '')
                setValue('purpose', '')
                setValue('access_card_no', '')
                setValue('no_of_pax', '')
                setValue('vehicle_reg_no', '')
                setValue('remarks', '')
                setValue('otp', '')
                setValue('department', '');
                setValue('dob', '');
                setValue('gender', '');
                setValue('visitor_type', '');
                setValue('employee', '');
                setProfilePhoto("")
                setFrontDocPhoto("")
                setBackDocPhoto("")
                setVisitorDisabled([''])
              }
            }
          } 
    } catch (e)
    {
      if (e.response.data)
      {
        if (Array.isArray(e.response.data.message))
        {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else
        {
          toast.error(e.response.data.message, toastConfig)
        }
      } else
      {
        toast.error(e.message, toastConfig)
      }
    }
    setIsLoading(false)
   }else{
    setError("visitor_doc_number", {type:"required"})
   }
  }
  
 

  return (
    <>
      <form
        encType="multipart/form-data"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        className="mainn"
      >
        <div className="g-3 mt-1" style={{display:"flex", justifyContent:"space-between", flexWrap:"wrap"}}>
          {/* <div> */}
            <div className="card overfolw-hidden mb-3 visitorcard" >
              <div className="card-body pb-0 ">
                <div className="row ">
                  <h3 className="heading-color-frontdesk fw-600">
                    {t('visitorRegistration.headingVerification')}
                  </h3>
                  <div className="col-12">
                    <hr />
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
              
                  <div className="row m-0 pt-3 pb-1">
                    <div className="form-group p-0 col-12 ">
                      <label>
                        {t('visitorRegistration.label.visitorPhoneNo')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <div className="d-flex flex-wrap p-0 col-12 gap-3">
                        <div className='d-flex' style={{ flexDirection: "column" }}>
                          <Controller
                            name="phone"
                            control={control}
                            rules={{ required: isPhoneRequired , pattern: pattern.phoneNumber }}
                            render={({ field: { onChange, value } }) => (
                              <PhoneNumberFrontdesk
                                phoneNumber={value}
                                email={email}
                                setPhoneNumber={onChange}
                                firstError={errors.phone}
                                countryCode={countryCode}
                                firstPlaceholder={t("visitorRegistration.placeholder.whatsAppNo")}
                                setCountryCode={setCountryCode}
                                dropdownDirection="bottom"
                                // error={errors.guestContactNo}
                                saperate={true}
                                wantemail={false}
                                disableboth={location?.state?.isAppointment === true ? true : false}
                                formFieldsDisabled={location?.state?.isAppointment === true ? true : false}
                                flagvisible={true}
                              />
                            )}
                          />
                          {errors.phone && (
                            <>
                              <small>
                                {errors.phone.type === 'required' && (
                                  <span className="text-danger">
                                    {t(
                                      'visitorRegistration.error.identityTypeReq'
                                    )}
                                  </span>
                                )}
                              </small>
                              <small>
                                {errors.phone.type === 'pattern' && (
                                  <span className="text-danger">
                                    {t(`visitorRegistration.error.phoneNoInvalid`)}
                                  </span>
                                )}
                              </small>
                            </>
                          )}
                        </div>
                        {
                          location?.state?.isVisible === true ? (
                            <button
                              className="btn btn-primary-frontdesk sendotp "
                              type="button"
                              onClick={() => sendOTP()}
                            >
                              {t('guestRegistration.guestVerification.label.resendOtp')}
                            </button>

                          ) : (
                            <button
                              className="btn btn-primary-frontdesk sendotp "
                              type="button"
                              onClick={() => sendOTP()}
                            >
                              {isVisible ? t('guestRegistration.guestVerification.label.resendOtp') : t('guestRegistration.guestVerification.label.sentOtp')}
                            </button>
                          )
                        }
                       
                      </div>

                    </div>

                  </div>
              </div>
            </div>

{/* new field add */}
<div className="card overfolw-hidden mb-3 visitorcard" >
              <div className="card-body pb-0 ">
                <div className="row ">
                  <h3 className="heading-color-frontdesk fw-600">
                    {t('visitorRegistration.repeatheadingVerification')}
                  </h3>
                  <div className="col-12">
                    <hr />
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <div>
                  <div className="row m-0 pt-3 pb-1">
                    <div className="form-group p-0 col-12 ">
                      <label>
                        {t('visitorRegistration.label.visitordocno')}
                        {/* <span className="text-danger ms-1">*</span> */}
                      </label>
                      <div className="d-flex flex-wrap p-0 col-12 gap-3">
                        <div className='d-flex' style={{ flexDirection: "column" }}>
                           <input 
                           style={{maxHeight:"2.5rem"}}
                          className={`form-control ${errors.visitor_doc_number && 'is-invalid'
                            }`}
                          type="text"
                          {...register('visitor_doc_number', {
                            required: true,
                            // pattern: pattern.fullName
                          })}
                        />
                          {errors.visitor_doc_number && (
                            <>
                              <small>
                                {errors.visitor_doc_number.type === 'required' && (
                                  <span className="text-danger">
                                    {t(
                                      'visitorRegistration.error.docno'
                                    )}
                                  </span>
                                )}
                              </small>
                              {/* <small>
                                {errors.visitor_doc_number.type === 'pattern' && (
                                  <span className="text-danger">
                                    {t(`visitorRegistration.error.docnoinvalid`)}
                                  </span>
                                )}
                              </small> */}
                            </>
                          )}
                        </div>
                        {
                          location?.state?.isVisible === true ? (
                            <button
                              className="btn btn-primary-frontdesk sendotp "
                              type="button"
                              onClick={() => sendDocOTP()}
                            >
                              {t('guestRegistration.guestVerification.label.resendOtp')}
                            </button>

                          ) : (
                            <button
                              className="btn btn-primary-frontdesk sendotp "
                              type="button"
                              onClick={() => sendDocOTP()}
                            >
                              {isVisible ? t('guestRegistration.guestVerification.label.resendOtp') : t('guestRegistration.guestVerification.label.sentOtp')}
                            </button>
                          )
                        }
                        
                      </div>
                     
                    </div>
                   
                  </div>
                </div>
            
              </div>
            </div>

          {/* </div> */}
        </div>
        <div className="row g-3 mb-3">
          <div>
            <div className="card overfolw-hidden mb-3">
              <div className="card-header pb-0">
                <div className="row">
                  <h3 className="heading-color-frontdesk fw-600">
                    {t('visitorRegistration.headingCreateVisitor')}
                  </h3>
                  <div className="col-12">
                    <hr />
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                {/* <form
                encType="multipart/form-data"
                autoComplete="off"
                onSubmit={handleSubmit(onSubmitVisitorData)}
              > */}
                <div className="row align-items-center">
                  {identitySection && <div
                    className="col-lg-12 col-xl-5 rounded-3"
                  // style={{ backgroundColor: '#f5f5f5' }}
                  >
                    <div className="documentid-data">
                      <div className="row align-items-center">
                        {<div className="col-md-12">
                          <label className="form-label">
                            {t('guestRegistration.guestVerification.label.idType')}
                            <span className="text-danger ms-1">*</span>
                          </label>
                          <div className='form-group mb-3 mb-sm-0'>
                            {docType.map((docType, index) => (
                              <div className="form-check form-check-inline" key={docType.id}>
                                <input
                                  className="form-check-input"
                                  // checked={index === 0 && true}
                                  type="radio"
                                  value={docType.id}
                                  onClick={() => handleDocTypeChange()}
                                  {...register('doc_id_type', {
                                    required: true
                                  })}
                                />
                                <label className="form-check-label">
                                  {docType.documentName}
                                </label>
                              </div>
                            ))}
                          </div>
                          {errors.doc_id_type && (
                            <small>
                              {errors.doc_id_type.type === 'required' && (
                                <span className="text-danger">
                                  {t(
                                    'visitorRegistration.error.selectidtype'
                                  )}
                                </span>
                              )}
                            </small>
                          )}
                        </div>}
                        {/* <div className="col-md-6 mb-3">
                          <label className="form-label">
                            {t('visitorRegistration.label.docIdNumber')}
                            <span className="text-danger ms-1">*</span>
                          </label>
                          <input
                            className={`form-control ${
                errors.documentNo && 'is-invalid'
              }`}
                            type="text"
                            {...register('documentNo', {
                              required: true,
                              pattern: pattern.notAllowSpaceInStart
                            })}
                          />
                          {errors.documentNo && (
                            <small>
                              {errors.documentNo.type === 'required' && (
                                <span className="text-danger">
                                  {t(
                                    'visitorRegistration.error.documentIdNumber'
                                  )}
                                </span>
                              )}
                              {errors.documentNo.type === 'pattern' && (
                                <span className="text-danger">
                                  {t(
                                    'visitorRegistration.error.documentIdNumberInvalid'
                                  )}
                                </span>
                              )}
                            </small>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            {t('visitorRegistration.label.nationality')}
                            <span className="text-danger ms-1">*</span>
                          </label>
                          <input

                            className={`form-control ${
                errors.nationality && 'is-invalid'
              }`}
                            type="text"
                            {...register('nationality', { required: true, pattern: pattern.notAllowSpaceInStart })}
                          />
                          {errors.nationality && (
                            <small>
                              {errors.nationality.type === 'required' && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.nationality')}
                                </span>
                              )}
                              {errors.nationality.type === 'pattern' && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.nationalityInvalid')}
                                </span>
                              )}
                            </small>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>}
                  {isMobile && <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="icon-button-file"
                    type="file"
                    capture="environment"
                    onChange={(e) => handleCapture(e.target)}
                  />}
                  <div className="col-lg-12 col-xl-7 mt-3 mt-sm-0">
                    <div className="row ">
                      <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-6 mt-3 mt-sm-0 d-flex justify-content-center">
                        <label htmlFor='icon-button-file'>
                          <div className="scan-box" onClick={() => setIsCapturePhotoModalOpen(true)}>
                            <img
                              src={`${userPhoto != "" ? userPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/user.png`} `}
                              className="img-fluid"
                              style={userPhoto === "" ? { maxWidth: '80px' } : { height: "100%", width: "100%" }}
                            />
                            <h4>{t('visitorRegistration.label.capturePhoto')}</h4>
                          </div>
                        </label>

                      </div>
                      <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-6 mt-3 mt-sm-0 d-flex justify-content-center">
                        <div>
                          <div className="scan-box" onClick={() => frontsideClick()}>
                            <img
                              src={`${frontDocPhoto != "" ? frontDocPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/scanner.png`} `}
                              className="img-fluid"
                              style={{ objectFit: "contain", aspectRatio: "auto", minWidth: "100%" }}
                            // style={frontDocPhoto === "" ? { maxWidth: '80px' } : { height: "100%", width: "100%" }}
                            />
                            <h4>{documentType != "3" ? t('visitorRegistration.label.scanfrontDoc') : t('visitorRegistration.label.scanDoc')}</h4>
                          </div>
                          {frontdocerror && (
                            <small className='frontdocerror'>
                              <span className="text-danger">
                                {t('commondocerror.frontdocerror')}
                              </span>
                            </small>
                          )}
                        </div>
                      </div>
                      {documentType != "3" && <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-6 mt-3 mt-sm-0 d-flex justify-content-center">
                        <div>
                          <div className="scan-box" onClick={() => backsideClick()}>
                            <img
                              src={`${backDocPhoto != "" ? backDocPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/scanner.png`} `}
                              className="img-fluid"
                              style={{ objectFit: "contain", aspectRatio: "auto", minWidth: "100%" }}
                            // style={backDocPhoto === "" ? { maxWidth: '80px' } : { height: "100%", width: "100%" }}
                            />
                            <h4>{t('visitorRegistration.label.scanbackDoc')}</h4>
                          </div>
                          {backdocerror && (
                            <small className='frontdocerror'>
                              <span className="text-danger">
                                {t('commondocerror.backdocerror')}
                              </span>
                            </small>
                          )}
                        </div>
                      </div>}
                    </div>
                  </div>



                  {/* <div className="col-md-6" style={{ marginLeft: "-1rem" }}>
                    <div className="row g-3">
                      <div className="col-lg-6 col-xxl-4">
                        <div className="scan-box-visitor">
                          <img
                            src=`${process.env.REACT_APP_BASEROUTE_URL}/admin-images/user.png`
                            style={{ maxWidth: '80px' }}
                          />
                          <h4>{t('guestRegistration.guestVerification.label.capturePhoto')}</h4>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xxl-4">
                        <div className="scan-box-visitor">
                          <img
                            src=`${process.env.REACT_APP_BASEROUTE_URL}/admin-images/scanner.png`
                            className="img-fluid"
                            style={{ maxWidth: '80px' }}
                          />
                          <h4>{t('guestRegistration.guestVerification.label.frontSide')}</h4>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xxl-4">
                        <div className="scan-box-visitor">
                          <img
                            src=`${process.env.REACT_APP_BASEROUTE_URL}/admin-images/scanner.png`
                            className="img-fluid"
                            style={{ maxWidth: '80px' }}
                          />
                          <h4>{t('guestRegistration.guestVerification.label.backSide')}</h4>
                        </div>
                      </div>
                    </div>
                  </div> */}





                </div>
                <div className="col-12">
                  <hr />
                </div>
                <div className="col-md-12 mb-3">
                  <div className='container-fluid'>
                    <div className="row">
                      <h4 className=" fw-600 mb-3">
                        {t('visitorRegistration.headingVisitorInformation')}
                      </h4>

                      {/* <div className="form-group col-md-3 mb-3">
                        <label>
                          {t('visitorRegistration.label.nationality')}
                          <span className="text-danger ms-1">*</span>
                        </label>
                        <input
                          className={`form-control`}
                          type="text"
                          disabled
                        // {...register('nationality', {
                        // required: true,
                        // pattern: pattern.nationality
                        // })}
                        />
                        {/* {errors.nationality && (
                          <small>
                            {errors.nationality.type === 'required' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.nationalityReq')}
                              </span>
                            )}
                            {errors.nationality.type === 'pattern' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.nationalityInvalid')}
                              </span>
                            )}
                          </small>
                        )} */}
                      {/* </div> */}

                      <div className="form-group col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>
                          {t('visitorRegistration.label.fullName')}
                          <span className="text-danger ms-1">*</span>
                        </label>
                        <input
                          className={`form-control ${errors.visitor_name && 'is-invalid'
                            }`}
                          type="text"
                          {...register('visitor_name', {
                            required: true,
                            pattern: pattern.fullName
                          })}
                        />
                        {errors.visitor_name && (
                          <small>
                            {errors.visitor_name.type === 'required' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.fullNameReq')}
                              </span>
                            )}
                            {errors.visitor_name.type === 'pattern' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.fullNameInvalid')}
                              </span>
                            )}
                          </small>
                        )}
                      </div>


                      <div className="form-group col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label className="form-label">
                          {t('visitorRegistration.label.docIdNumber')}
                          <span className="text-danger ms-1">*</span>
                        </label>
                        <input
                          className={`form-control ${errors.doc_id_number && 'is-invalid'
                            } `}
                          type="text"
                          {...register('doc_id_number', {
                            required: true,
                            pattern: pattern.notAllowSpaceInStartfront
                          })}
                        />
                        {errors.doc_id_number && (
                          <small>
                            {errors.doc_id_number.type === 'required' && (
                              <span className="text-danger">
                                {t(
                                  'visitorRegistration.error.documentIdNumber'
                                )}
                              </span>
                            )}
                            {errors.doc_id_number.type === 'pattern' && (
                              <span className="text-danger">
                                {t(
                                  'visitorRegistration.error.documentIdNumberInvalid'
                                )}
                              </span>
                            )}
                          </small>
                        )}
                      </div>
                      <div className="form-group col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label className="form-label">
                          {t('visitorRegistration.label.nationality')}
                          <span className="text-danger ms-1">*</span>
                        </label>
                        <input

                          className={`form-control ${errors.nationality && 'is-invalid'
                            } `}
                          type="text"
                          {...register('nationality', { required: true, pattern: pattern.notAllowSpaceInStartfront })}
                        />
                        {errors.nationality && (
                          <small>
                            {errors.nationality.type === 'required' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.nationality')}
                              </span>
                            )}
                            {errors.nationality.type === 'pattern' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.nationalityInvalid')}
                              </span>
                            )}
                          </small>
                        )}
                      </div>





                      <div className="form-group col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>
                          {t('visitorRegistration.label.dob')}
                          <span className="text-danger ms-1">*</span>
                        </label>
                        {/* <input
                          className={`form-control ${
              errors.dob && 'is-invalid'
            } `}
                          type="text"
                          {...register('dob', { required: true, pattern: pattern.notAllowSpaceInStart })}
                        /> */}
                        <Controller
                          name="dob"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <ReactDatePicker
                              value={value}
                              onChange={onChange}
                              placeholder={t(
                                'visitorRegistration.placeholder.dob'
                              )}
                              hasErrors={errors.dob}
                              maxDate={moment().toDate()}
                              dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                            />
                          )}
                        />
                        {errors.dob && (
                          <small>
                            {errors.dob.type === 'required' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.birthDateReq')}
                              </span>
                            )}
                          </small>
                        )}
                      </div>
                      <div className="form-group col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>
                          {t('visitorRegistration.label.gender')}
                          <span className="text-danger ms-1">*</span>
                        </label>
                        <Controller
                          name="gender"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <SearchableDropdown
                              value={value}
                              onChange={onChange}
                              options={genderOptions}
                              placeholder={t(
                                'visitorRegistration.placeholder.gender'
                              )}
                              hasError={errors.gender}
                            />
                          )}
                        />
                        {errors.gender && (
                          <small>
                            {errors.gender.type === 'required' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.genderReq')}
                              </span>
                            )}
                          </small>
                        )}
                      </div>

                      <div className="form-group col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>
                          {t('visitorRegistration.label.visitorType')}
                          <span className="text-danger ms-1">*</span>
                        </label>
                        <Controller
                          name="visitor_type"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <SearchableDropdown
                              value={value}
                              onChange={onChange}
                              options={visitorType}
                              placeholder={t(
                                'visitorRegistration.placeholder.visitorType'
                              )}
                              hasError={errors.visitor_type}
                            />
                          )}
                        />
                        {errors.visitor_type && (
                          <small>
                            {errors.visitor_type.type === 'required' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.visitorTypeReq')}
                              </span>
                            )}
                          </small>
                        )}
                      </div>
                      {showLpoNumber && <div className="form-group col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                        <label>
                          {t('visitorRegistration.label.lpoNumber')}
                          <span className="text-danger ms-1">*</span>
                        </label>
                        <input
                          className={`form-control ${errors.lpo_number && 'is-invalid'
                            } `}
                          type="text"
                          {...register('lpo_number', { required: true })}
                        />
                        {errors.lpo_number && (
                          <small>
                            {errors.lpo_number.type === 'required' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.lpoNumberReq')}
                              </span>
                            )}
                          </small>
                        )}
                      </div>}

                      {showRefNumber &&
                        <div className="form-group col-xl-3 col-lg-4 col-md-4 mb-3">
                          <label>
                            {t('casualworkers.casualworkerformlabel.refrenceno')}
                          </label>
                          <input
                            className={`form-control ${errors.referenceno && 'is-invalid'
                              } `}
                            type="text"
                            {...register('referenceno', { pattern: pattern.numberAndAlphabet })}
                          />

                          {errors.referenceno && (
                            <small>
                              {errors.referenceno.type === 'pattern' && (
                                <span className="text-danger">
                                  {t('casualworkers.casualworkererror.refrenceerror')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>
                      }

                      {showLpoNumber ?
                        <div className="form-group col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">
                          <label>
                            {t('visitorRegistration.label.visitorFrom')}
                            {
                              (requiredField?.find((require) => require?.label_value == "visitor_from")?.mandatory_status == 1 && !showLpoNumber) ? <span className="text-danger ms-1">*</span> : ''
                            }
                            {/* <span className="text-danger ms-1">*</span> */}
                          </label>
                          <Controller
                            name="visitor_from"
                            control={control}
                            rules={{ required: (requiredField?.find((require) => require?.label_value == "visitor_from")?.mandatory_status == 1 && !showLpoNumber) ? true : false, pattern: pattern.notAllowSpaceInStartfront }}
                            render={({ field: { value, onChange } }) => (
                              <SearchableDropdown
                                value={value}
                                onChange={onChange}
                                options={visitorFromList}
                                hasError={errors.visitor_from}
                                placeholder={t(
                                  'visitorRegistration.placeholder.department'
                                )}
                              />
                            )}
                          />

                          {errors.visitor_from && (
                            <small>
                              {errors.visitor_from.type === 'required' && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.visitorFromReq')}
                                </span>
                              )}
                              {errors.visitor_from.type === 'pattern' && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.visitorFromInvalid')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>
                        :
                        <div className="form-group col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">

                          <label>
                            {t('visitorRegistration.label.visitorFrom')}
                            {
                              (requiredField?.find((require) => require?.label_value == "visitor_from")?.mandatory_status == 1 && !showLpoNumber) ? <span className="text-danger ms-1">*</span> : ''
                            }
                            {/* <span className="text-danger ms-1">*</span>   */}
                          </label>
                          <input
                            className={`form-control ${errors.visitor_from && 'is-invalid'
                              } `}
                            type="text"
                            // disabled={showLpoNumber ? true : false}
                            {...register('visitor_from', { required: (requiredField?.find((require) => require?.label_value == "visitor_from")?.mandatory_status == 1 && !showLpoNumber) ? true : false, pattern: pattern.notAllowSpaceInStartfront })}
                          // disabled={visitorDisabled?.visitor_type?.label === 'Casual Workers'}
                          />
                          {errors.visitor_from && (
                            <small>
                              {errors.visitor_from.type === 'required' && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.visitorFromReq')}
                                </span>
                              )}
                              {errors.visitor_from.type === 'pattern' && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.visitorFromInvalid')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>
                      }

                    </div>

                    {/* <div className="form-group col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-3">

                         <label>
                          {t('visitorRegistration.label.visitorFrom')}
                          {
                            (requiredField?.find((require) => require?.label_value == "visitor_from")?.mandatory_status == 1 && !showLpoNumber) ? <span className="text-danger ms-1">*</span> : ''
                          } 
                        {/* <span className="text-danger ms-1">*</span>   */}
                    {/* </label>
                        <input
                          className={`form-control ${errors.visitor_from && 'is-invalid'
                            } `}
                          type="text"  */}
                    {/* // disabled={showLpoNumber ? true : false}
                          {...register('visitor_from', { required: (requiredField?.find((require) => require?.label_value == "visitor_from")?.mandatory_status == 1 && !showLpoNumber) ? true : false, pattern: pattern.notAllowSpaceInStartfront })}
                        // disabled={visitorDisabled?.visitor_type?.label === 'Casual Workers'} */}
                    {/* />  */}


                    {/* <label>
                          {t('visitorRegistration.label.visitorFrom')}
                          {
                            (requiredField?.find((require) => require?.label_value == "visitor_from")?.mandatory_status == 1 && !showLpoNumber) ? <span className="text-danger ms-1">*</span> : ''
                          }
                          {/* <span className="text-danger ms-1">*</span> */}
                    {/* </label>
                        <Controller
                          name="visitor_from"
                          control={control}
                          rules={{ required: (requiredField?.find((require) => require?.label_value == "visitor_from")?.mandatory_status == 1 && !showLpoNumber) ? true : false, pattern: pattern.notAllowSpaceInStartfront }}
                          render={({ field: { value, onChange } }) => (
                            <SearchableDropdown
                              value={value}
                              onChange={onChange}
                              options={visitorFromList}
                              hasError={errors.visitor_from}
                              placeholder={t(
                                'visitorRegistration.placeholder.department'
                              )}
                            />
                          )}
                        />

                        {errors.visitor_from && (
                          <small>
                            {errors.visitor_from.type === 'required' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.visitorFromReq')}
                              </span>
                            )}
                            {errors.visitor_from.type === 'pattern' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.visitorFromInvalid')}
                              </span>
                            )}
                          </small>
                        )}

                      </div>  */}





                    {/* <div className="row">
                      <div className="form-group col-md-3 mb-3">
                        <label>
                          {t('visitorRegistration.label.visitorType')}
                          <span className="text-danger ms-1">*</span>
                        </label>
                        <Controller
                          name="visitorType"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { value, onChange } }) => (
                            <SearchableDropdown
                              value={value}
                              onChange={onChange}
                              options={visitorType}
                              placeholder={t(
                                'visitorRegistration.placeholder.visitorType'
                              )}
                              hasError={errors.visitorType}
                            />
                          )}
                        />
                        {errors.visitorType && (
                          <small>
                            {errors.visitorType.type === 'required' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.visitorTypeReq')}
                              </span>
                            )}
                          </small>
                        )}
                      </div>
                      {showLpoNumber && <div className="form-group col-md-3 mb-3">
                        <label>
                          {t('visitorRegistration.label.lpoNumber')}
                          <span className="text-danger ms-1">*</span>
                        </label>
                        <input
                          className={`form-control ${
              errors.lpoNumber && 'is-invalid'
            } `}
                          type="text"
                          {...register('lpoNumber', { required: true })}
                        />
                        {errors.lpoNumber && (
                          <small>
                            {errors.lpoNumber.type === 'required' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.lpoNumberReq')}
                              </span>
                            )}
                          </small>
                        )}
                      </div>}
                      <div className="form-group col-md-3 mb-3">
                        <label>
                          {t('visitorRegistration.label.companyFrom')}
                          <span className="text-danger ms-1">*</span>
                        </label>
                        <input
                          className={`form-control ${
              errors.companyFrom && 'is-invalid'
            } `}
                          type="text"
                          {...register('companyFrom', { required: true, pattern: pattern.notAllowSpaceInStart })}
                        />
                        {errors.companyFrom && (
                          <small>
                            {errors.companyFrom.type === 'required' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.companyFromReq')}
                              </span>
                            )}
                            {errors.companyFrom.type === 'pattern' && (
                              <span className="text-danger">
                                {t('visitorRegistration.error.companyFromInvalid')}
                              </span>
                            )}
                          </small>
                        )}
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-12">
                  <hr />
                </div>
                <div className="col-12">
                  <div className='container-fluid'>
                    <div
                      className="row pt-3 for-office"
                      style={{ backgroundColor: '#f5f5f5' }}
                    >
                      <h5 className="mb-3 fw-600">
                        {t('visitorRegistration.forOfficePurpose')}
                      </h5>

                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="form-group mb-3">
                          <label>
                            {t('visitorRegistration.label.department')}
                            {
                              requiredField?.find((require) => require?.label_value == "department")?.mandatory_status == 1 ? <span className="text-danger ms-1">*</span> : ''
                            }
                            {/* <span className="text-danger ms-1">*</span> */}
                          </label>
                          <Controller
                            name="department"
                            control={control}
                            rules={{ required: requiredField?.find((require) => require?.label_value == "department")?.mandatory_status == 1 ? true : false, pattern: pattern.notAllowSpaceInStartfront }}
                            render={({ field: { value, onChange } }) => (
                              <SearchableDropdown
                                value={value}
                                onChange={onChange}
                                options={departmentList}
                                hasError={errors.department}
                                placeholder={t(
                                  'visitorRegistration.placeholder.department'
                                )}
                              />
                            )}
                          />
                          {errors.department && (
                            <small>
                              {errors.department && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.departmentReq')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="form-group mb-3">
                          <label>
                            {t('visitorRegistration.label.employeeName')}
                            {
                              requiredField?.find((require) => require?.label_value == "host")?.mandatory_status == 1 ? <span className="text-danger ms-1">*</span> : ''
                            }
                            {/* <span className="text-danger ms-1">*</span> */}
                          </label>
                          <Controller
                            name="employee"
                            control={control}
                            rules={{ required: requiredField?.find((require) => require?.label_value == "host")?.mandatory_status == 1 ? true : false, pattern: pattern.notAllowSpaceInStartfront }}
                            render={({ field: { value, onChange } }) => (
                              <SearchableDropdown
                                value={value}
                                onChange={onChange}
                                options={filteredEmployeeName}
                                placeholder={t(
                                  'visitorRegistration.placeholder.employeename'
                                )}
                                hasError={errors.employee}
                                noOptionsMessage={departmentName ? () => { return t("visitorRegistration.error.noOptions") } : () => { return t("visitorRegistration.error.selectDept") }}
                              />
                            )}
                          />

                          {/* <input
                            type="text"
                            className={`form-control ${
              errors.employeeName && 'is-invalid'
            } `}
                            placeholder={t(
                              'visitorRegistration.placeholder.employeeName'
                            )}
                            {...register('employeeName', { required: true, pattern: pattern.notAllowSpaceInStart })}
                          /> */}
                          {errors.employee && (
                            <small>
                              {errors.employee.type === 'required' && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.employeeNameReq')}
                                </span>
                              )}
                              {errors.employee.type === 'pattern' && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.employeeNameInvalid')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="form-group mb-3">
                          <label>
                            {t('visitorRegistration.label.purpose')}
                            {/* <span className="text-danger ms-1">*</span> */}
                            {
                              requiredField?.find((require) => require?.label_value == "purpose")?.mandatory_status == 1 ? <span className="text-danger ms-1">*</span> : ''
                            }
                          </label>
                          <input
                            type="text"
                            className={`form-control ${errors.purpose && 'is-invalid'
                              } `}
                            placeholder={t(
                              'visitorRegistration.placeholder.purpose'
                            )}
                            {...register('purpose', { required: requiredField?.find((require) => require?.label_value == "purpose")?.mandatory_status == 1 ? true : false, })}
                          />
                          {errors.purpose && (
                            <small>
                              {errors.purpose.type === 'required' && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.purposeReq')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="form-group mb-3">
                          <label>
                            {t('visitorRegistration.label.accessCardNo')}
                            {
                              requiredField?.find((require) => require?.label_value == "access_card_no")?.mandatory_status == 1 ? <span className="text-danger ms-1">*</span> : ''
                            }
                            {/* <span className="text-danger ms-1">*</span> */}
                          </label>
                          <input
                            type="text"
                            className={`form-control ${errors.access_card_no && 'is-invalid'
                              } `}
                            placeholder={t(
                              'visitorRegistration.placeholder.accessCardNo'
                            )}
                            {...register('access_card_no', { required: requiredField?.find((require) => require?.label_value == "access_card_no")?.mandatory_status == 1 ? true : false, pattern: pattern.notAllowSpaceInStartfront })}
                          />
                          {errors.access_card_no && (
                            <small>
                              {errors.access_card_no.type == "required" && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.accessCardNoReq')}
                                </span>
                              )}
                              {errors.access_card_no.type == "pattern" && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.accessCardNoInvalid')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="form-group mb-3">
                          <label>
                            {t('visitorRegistration.label.vrn')}
                            {
                              requiredField?.find((require) => require?.label_value == "vehicle_reg_no")?.mandatory_status == 1 ? <span className="text-danger ms-1">*</span> : ''
                            }
                            {/* <span className="text-danger ms-1">*</span> */}
                          </label>
                          <input
                            type="text"
                            className={`form-control ${errors.vehicle_reg_no && 'is-invalid'
                              } `}
                            placeholder={t('visitorRegistration.placeholder.vrn')}
                            {...register('vehicle_reg_no', { required: requiredField?.find((require) => require?.label_value == "vehicle_reg_no")?.mandatory_status == 1 ? true : false, pattern: pattern.notAllowSpaceInStartfront })}
                          />
                          {errors.vehicle_reg_no && (
                            <small>
                              {errors.vehicle_reg_no && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.vrnReq')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="form-group mb-3">
                          <label>
                            {t('visitorRegistration.label.numberOfPax')}
                            {/* <span className="text-danger ms-1">*</span> */}
                          </label>
                          <input
                            type="text"
                            className={`form-control ${errors.no_of_pax && 'is-invalid'
                              } `}
                            placeholder={t('visitorRegistration.placeholder.vrn')}
                            {...register('no_of_pax', {
                              // required: true
                            })}
                          />
                          {errors.no_of_pax && (
                            <small>
                              {errors.no_of_pax && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.vrnReq')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                      <div className="form-group mb-3">
                        <label>
                          {t('visitorRegistration.label.assetDetails')}
                          <span className="text-danger ms-1">*</span>
                        </label>
                        <SearchableDropdown
                          options={assetDetails}
                          placeholder={t(
                            'visitorRegistration.placeholder.assetDetails'
                          )}
                        />
                      </div>
                    </div> */}
                      <div className="col-md-12">
                        <hr />
                      </div>
                      {/* <form
                      encType="multipart/form-data"
                      autoComplete="off"
                      onSubmit={handleSubmit(onSubmitAssetData)}
                    >
                      <div className="row"> */}
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label>
                            {t('visitorRegistration.label.remark')}
                            {
                              requiredField?.find((require) => require?.label_value == "remarks")?.mandatory_status == 1 ? <span className="text-danger ms-1">*</span> : ''
                            }
                            {/* <span className="text-danger ms-1">*</span> */}
                          </label>
                          <textarea
                            type="text"
                            rows={5}
                            className={`form-control ${errors.remarks && 'is-invalid'
                              } `}
                            placeholder={t(
                              'visitorRegistration.placeholder.accessCardNo'
                            )}
                            {...register('remarks', { required: requiredField?.find((require) => require?.label_value == "remarks")?.mandatory_status == 1 ? true : false, pattern: pattern.notAllowSpaceInStartfront })}
                          />
                          {errors.remarks && (
                            <small>
                              {errors.remarks && (
                                <span className="text-danger">
                                  {t('visitorRegistration.error.remarkReq')}
                                </span>
                              )}
                            </small>
                          )}
                        </div>
                      </div>
                      <BypassModal
                        show={showBypassModal}
                        handleClose={() => setShowBypassModal(false)}
                        setIdentitySection={setIdentitySection}
                        byPassType={byPassType}
                        visitorData={visitorData}
                        resetbypass={resetByPassVisitor}
                        appointmentId={appointmentId}
                        isAppointment={location?.state?.isAppointment}
                      />
                      <CapturePhotoModal
                        isModalOpen={isMobile === true ? false : isCapturePhotoModalOpen}
                        setIsModalOpen={setIsCapturePhotoModalOpen}
                        setUserPhoto={setProfilePhoto}
                      />
                      <CaptureDocumentModal
                        isModalOpen={isCaptureFrontDocModalOpen}
                        setIsModalOpen={setIsCaptureFrontDocModalOpen}
                        setUserPhoto={setFrontDocImage}
                      />
                      <CaptureDocumentModal
                        isModalOpen={isCaptureBackDocModalOpen}
                        setIsModalOpen={setIsCaptureBackDocModalOpen}
                        setUserPhoto={setBackDocImage}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row justify-content-between">
                    <div className="col-md-6 col-12 form-group mb-3">
                      <label>
                        {t('visitorRegistration.label.enterOTP')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <div className="d-flex  flex-wrap gap-2">
                        <input
                          type="text"
                          style={{ maxWidth: "15rem" }}
                          className={`form-control ${errors.otp && 'is-invalid'
                            } `}
                          placeholder={t('visitorRegistration.placeholder.vrn')}
                          {...register('otp', {
                            // required: isBypassedClick ? false : true,
                            pattern: pattern.otp
                          })}
                        />
                        <div className="d-flex gap-2">
                          {/* <button
                            className="btn btn-primary btn-lg"
                            type="submit"
                            onClick={() => setSubmitPress(true)}
                          >
                            {t('visitorRegistration.label.save')}
                          </button> */}

                          {
                            location?.state?.isAppointment === true ? (
                              <button
                                className="btn btn-primary-frontdesk btn-lg"
                                type="submit"
                                onClick={() => setSubmitPress(true)}
                              >
                                {t('visitorRegistration.label.update')}
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary-frontdesk btn-lg"
                                type="submit"
                                onClick={() => setSubmitPress(true)}
                              >
                                {t('visitorRegistration.label.save')}
                              </button>
                            )
                          }


                          <button
                            className="btn btn-danger btn-lg"
                            type="reset"
                            onClick={resetByPassVisitor}
                          >
                            {t('visitorRegistration.label.reset')}
                          </button>
                        </div>

                      </div>
                      {errors.otp && (
                        <small>
                          {errors.otp.type == 'required' && (
                            <span className="text-danger">
                              {t('visitorRegistration.error.enterOTP')}
                            </span>
                          )}
                          {errors.otp.type == 'pattern' && (
                            <span className="text-danger">
                              {t('visitorRegistration.error.otpPattern')}
                            </span>
                          )}
                        </small>
                      )}
                    </div>
                    <div className="col-md-6 col-12 mb-3" style={{ display: "flex", alignSelf: "flex-end", justifyContent: "flex-end" }}>

                      <select value={selectedHostType} onChange={handleSelectHostType} className="form-control mt-auto mb-auto">
                        <option value="">Select Option</option>
                        <option value="1">Staff Gate</option>

                        <option value="2">Guest Gate</option>
                        <option value="3">Members Only Gate</option>
                        <option value="4">Serengeti Gate</option>
                        <option value="5">Control Room</option>
                        <option value="6">Mail Room</option>
                      </select>

                      <button
                        type="button"
                        onClick={() => getAccessCardNumber()}
                        className="btn btn-primary-frontdesk btn-lg ml-2"
                      >
                        {t('visitorRegistration.label.verifyCard')}
                      </button>
                      <button
                        type="button"
                        onClick={handleSubmit(onBypass)}
                        className="btn btn-primary-frontdesk btn-lg ml-3"
                      >
                        {t('visitorRegistration.label.byPass')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
