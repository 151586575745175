import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { checkModulePermission } from './helper-function/ModulePermissionCheck';

export default function AuthenticateAuthRoute() {
  const authToken = localStorage.getItem('authToken');
  const isGuard = localStorage.getItem('isGuard');
  return authToken && authToken != 'undefined' ? (
    isGuard == "true" ? <Navigate to="/guest-registration" replace /> : <Navigate to="/dashboard" replace />
  ) : (
    <Outlet />
  );
}
