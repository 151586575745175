export default {
  table: {
    dataNotFound: 'Data not found.'
  },
  header: {
    headertext: 'MUTHAIGA COUNTRY CLUB'
  },
  homePage: {
    heading: 'Visitor Managment System',
    description:
      'SecuViz is a future-ready Visitor Management System for modern-day businesses and commercial establishments as well as gated residential estates. It is a smart solution to record, track, and streamline the movement of visitors.',
    loginBtnLabel: 'Log in'
  },
  navbar: {
    logo: {
      logoName: 'MUTHAIGA COUNTRY CLUB'
    },
    navbarItems: {
      guestRegistration: 'Guest Registration',
      bookings: 'Guest Bookings',
      visitorRegistration: 'Visitor Registration',
      visitorAppointments: 'Visitor Appointments',
      events: 'Events',
      logout: 'Logout'
    }
  },
  navbarMenu: {
    profileDropdown: {
      logout: 'Logout',
      profile: 'Profile'
    },
    parent: {
      dashboard: 'Dashboard',
      masters: 'Masters',
      admin: 'Admin',
      manageevents: 'Manage Events',
      booking: 'Booking',
      reports: 'Reports',
      appointment: 'Appointment',
      applicationsettings: 'Application Settings',
      // appointment: 'Appointment',
      // parameterSetup: 'Parameter Setup',
      // logDetails: "Log Details"
    },
    masters: {
      department: 'Department',
      userroles: 'User Roles',
      accessmapping: 'Access Mapping',
      accesscontrol: 'Access Control',
      // towerMaster: 'Tower Master',
      // companyMaster: 'Company Master',
      // departmentMaster: 'Department Master',
      // employeeMaster: 'Employee Master',
      // deviceMaster: 'Device Master',
      // cameraMaster: 'IP Camera Master',
      // passTemplate: 'Pass Template',
      // accessControl: 'Access Control',
      // assetMaster: 'Asset Master'
    },
    admin: {
      systemusers: 'System Users',
      backupmanagement: 'Backup Management',
      messagetemplate: 'Message Template',
      broadcastmessage: 'Broadcast Message',
      sosmessage: 'SOS Message',
      blacklistvisitor: 'Blacklist Visitor',
      suppliercontractor: 'Supplier & Contractors',
      casualworkers: 'Casual Workers',
      members:'Add Members',
      aide: 'Aide & Resident',
      // vipVisits: 'VIP Visits',
      // bypassVisitor: 'Bypass Visitor',
      // groupVisit: 'Manage Group Visit',
      // blacklistVisitor: 'Blacklist Visitor',
      // messageTemplate: 'Message Template',
      // sosManager: 'SOS Manager',
      // backupManagement: 'Backup Management',
      // broadCast: 'BroadCast'
    },
    manageevents: {
      bookaguest: 'Book A Guest',
      createevent: 'Create Event',
      eventlocations: 'Event Locations',
      eventapproval: 'Events List',
      eventbooking: 'Event Booking'
    },
    booking: {
      singlebooking: 'Single Booking',
      eventsbooking: 'Events Booking',
      guestbooking: 'Partial Guest Booking',
      eventbooking: 'Event Booking',
      fullguestbooking:"Full Guest Booking"
    },
    reports: {
      summaryreport: 'Summary Report',
      visitsreport: 'Visits Report',
      visitorappointmentreport: 'Visitor Appointment Report',
      eventsreport: 'Events Report',
      blacklistreport: 'Blacklist Report',
      sosreport: 'SOS Report',
      broadcastreport: 'Broadcast Report',
      bypassreport: 'ByPass Report',
      visitordetailreport: 'Visitor Details Report',
      changelogsreport: 'Change Logs Report',
      timesvisitreport: '6 Times Visit Report',
      guestsummary: 'Guest Summary',
      guestvisitreport: 'Guest Visit Report',
      guestbookingreport: 'Guest Booking Report',
      visithoursexceeded: 'Visit Hours Exceeded',
      qrcodecounter: 'QR Code Counter Report'
      // summary: 'Summary',
      // visits: 'Visits',
      // appointments: 'Appointments',
      // blacklist: 'Blacklist',
      // systemUser: 'System User',
      // group: 'Group',
      // special: 'Special/VIP Visit',
      // sos: 'SOS',
      // visitorDump: 'Visitors Dump',
      // broadCast: 'Broadcast',
      // audit: 'Audit'
    },
    applicationsettings: {
      emailsettings: 'Email Settings',
      smssettings: 'SMS Settings',
      whatsappsettings: 'WhatsApp Settings',
      managefrontdesk: 'Manage FrontDesk',
      parametersettings: 'Parameter Settings',
    },
    docmodal: {
      heading: "Take Snapshot"
    }
    // parameterSetup: {
    //   configuration: 'Configuration Settings',
    //   mailling: 'Mailling Details',
    //   application: 'Application Settings',
    //   proxy: 'Proxy Settings',
    //   sms: 'SMS Gateway',
    //   whatsapp: 'WhatsApp',
    //   telegram: 'Telegram',
    //   addVisitorType: 'Add Visitor Type',
    //   manageFrontdesk: 'Manage Frontdesk',
    //   mapEncoding: 'Map Encoding'
    // }
  },
  loginPage: {
    heading: 'Log in',
    description: 'Please login to continue using our website.'
  },
  forgotPasswordPage: {
    heading: 'Forgot Password',
    description: "Enter your Email Address registered in the system."
  },
  passwordResetPage: {
    heading: 'Reset Password'
  },
  verifyOtpPage: {
    heading: 'Verify OTP',
    description: 'Enter your OTP.'
  },
  loginFormError: {
    emailRequired: 'Email is required.',
    emailPattern: 'Please enter valid email.',
    passwordRequired: 'Password is required.'
  },
  loginFormLabel: {
    emailLabel: 'Email address',
    passwordLabel: 'Password',
    loginbtnLabel: 'Log in',
    forgotPasswordLinkLabel: 'Forgot Password?'
  },
  loginFormPlaceholder: {
    emailPlaceholder: 'Email address',
    passwordPlaceholder: 'Password'
  },
  forgotPasswordFormError: {
    phoneNoRequired: 'Phone number is required.',
    phoneNoPattern: 'Please enter valid phone number.'
  },
  forgotPasswordFormLabel: {
    emailLabel: 'Enter Email Address',
    sendOtpBtnLabel: 'Send OTP'
  },
  forgotPasswordFormPlaceholder: {
    phoneNoPlaceholder: 'Enter your phone number'
  },
  passwordResetFormError: {
    passwordRequired: 'Password is required.',
    passwordPattern: 'Please enter valid password.',
    confirmPasswordRequired: 'Confirm password is required.',
    confirmPasswordPattern: 'Please enter valid confirm password.'
  },
  passwordResetFormLabel: {
    passwordLabel: 'New Password',
    confirmPasswordLabel: 'Confirm Password',
    setPasswordBtnLabel: 'Set Password'
  },
  passwordResetFormPlaceholder: {
    passwordPlaceholder: 'New Password',
    confirmPasswordPlaceholder: 'Confirm Password'
  },
  verifyOtpFormError: {
    otpRequired: 'Otp is required.',
    otpPattern: 'Please enter valid OTP.'
  },
  verifyOtpFormLabel: {
    otpLabel: 'Enter OTP',
    verifyOtpLabel: 'Verify OTP'
  },
  verifyOtpFormPlaceholder: {
    otpPlaceholder: 'Enter your OTP'
  },
  footer: {
    description:
      'Secuviz Visitor Management System, a subsidiary of Riana Group.'
  },
  profile: {
    heading: {
      profile: 'Profile',
      changePassword: 'Change Password'
    },
    label: {
      name: 'Name',
      birthday: 'Birthday',
      gender: 'Gender',
      email: 'Email',
      mobileNo: 'Mobile No',
      currentPassword: 'Current Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password'
    },
    placeholder: {
      currentPassword: 'Current Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password'
    },
    error: {
      currentPasswordError: 'Current password is required.',
      newPasswordError: 'New password is required.',
      confirmPasswordError: 'Confirm password is required.',
      passwordPatternError: 'Please enter valid password.',
      passwordError: "New password and confirm password doesn't match."
    },
    btnLabel: {
      submit: 'Submit'
    }
  },
  towerMaster: {
    heading: 'Tower Master',
    btnLabel: 'Create Tower',
    addTower: {
      heading: 'Add Tower',
      editHeading: 'Edit Tower',
      backBtn: 'Back',
      label: {
        towerName: 'Tower Name',
        noOfFloor: 'No Of Floors',
        address: 'Address',
        country: 'Country',
        city: 'City',
        adminName: 'Admin Name',
        adminEmail: 'Admin Email',
        phone: 'Phone',
        submitBtn: 'Submit',
        cancelBtn: 'Cancel',
        resetBtn: 'Reset'
      },
      placeholder: {
        profile: 'Upload your tower picture',
        towerName: 'Tower Name',
        noOfFloor: 'Floor No',
        country: 'Select Country',
        city: 'Select City',
        adminName: 'Admin Name',
        adminEmail: 'Admin Email'
      },
      error: {
        profileReq: 'Tower photo is required.',
        towerNameReq: 'Tower name is required.',
        towerNameInvalid: 'Please enter valid tower name.',
        noOfFloorReq: 'Floor number is required.',
        noOfFloorInvalid: 'Please enter valid floor number.',
        adminNameReq: 'Admin name is required.',
        adminNameInvalid: 'Please enter valid admin name.',
        addressReq: 'Address is required.',
        adminEmailReq: 'Admin email is required.',
        adminEmailInvalid: 'Please enter valid email.',
        phoneReq: 'Phone number is required.',
        phoneInvalid: 'Please enter valid phone number.',
        countryReq: 'Select your country.',
        cityReq: 'Select your city.',
        dropdownError: 'No options',
        selectCityMsg: 'Please select country first'
      }
    },
    towersTable: {
      logo: 'Logo',
      tower: 'Tower',
      address: 'Address',
      city: 'City',
      country: 'Country',
      adminName: 'Admin Name',
      adminEmail: 'Admin Email',
      mobileNo: 'Mobile No.',
      action: 'Action'
    },
    sweetAlert: {
      confirmationTitle: 'Are you sure?',
      confirmationText: "You won't be able to revert this.",
      confirmationBtnLabel: 'Yes, delete it.',
      simpleTitle: 'Deleted.',
      simpleText: 'Tower has been deleted.'
    }
  },
  departmentMaster: {
    heading: {
      mainHeading: 'Department Master',
      addHeading: 'Add Department Master',
      editHeading: 'Edit Department Master'
    },
    btnLabel: {
      createDepartment: 'Create Department',
      back: 'Back',
      add: 'Add',
      cancel: 'Cancel',
      reset: 'Reset',
      update: 'Update',
    },
    tableHead: {
      tower: 'Tower',
      company: 'Company',
      department: 'Department',
      action: 'Action'
    },
    formLabel: {
      towerName: 'Tower Name',
      status: 'Status',
      department: 'Department'
    },
    placeholder: {
      departmentName: 'Department Name',
      selectTower: 'Select Tower',
      selectCompany: 'Select Company',
      selectstatus: 'Select Status'
    },
    error: {
      towerNameError: 'Select your tower.',
      statusError: 'Select your Status.',
      departmentError: 'Department is required.',
      departmentinvalidError: 'Please enter valid Department.',
      dropdownError: 'No options',
      selectCompanyMsg: 'Please select tower first'
    },
    deleteDepartment: {
      sweetAlert: {
        confirmationTitle: 'Are you sure?',
        confirmationText: "You won't be able to revert this!",
        confirmationBtnLabel: 'Yes, delete it!',
        simpleTitle: 'Deleted!'
      }
    },
    deleteDepartment2: {
      sweetAlert: {
        confirmationTitle: 'Are you sure?',
        confirmationText: "You won't be able to revert this!",
        confirmationBtnLabel: 'Yes, WhiteList',
        simpleTitle: 'WhiteListed!'
      }
    }
  },
  dashboardchart: {
    todaysummary: {
      heading: "Today's Summary",
      weeklyheading: "Weekly Visitors Overview",
      guestin: 'Guests In',
      guestout: 'Guests Out',
      guestbalance: 'Guests Balance',
      visitorin: 'Visitors In',
      visitorout: 'Visitors Out',
      visitorbalance: 'Visitors Balance',
    },
    weeklyoverview: {
      visitors: 'Visitors',
      guest: 'Guest',
      casual: 'Casuals',
      supplier: 'Suppliers',
    },
    locationvisit: {
      heading: "Location Visits",
    },
    departmentsvisit: {
      heading: "Department Visits",
    },
    guestsummary: {
      heading: "Guest Summary",
    },
    visitorsummary: {
      heading: "Visitor Summary",
    },
    eventchart: {
      heading: "Events",
    }
  },
  accessmapping: {
    heading: 'Access Mapping',
    submit: 'Submit',
    reset: 'Reset',
    update: 'Update',
    addaccessmapping: 'Add Access Mapping',
    add: 'Add',
    back: 'Back',
    editaccessmapping: 'Edit Access Mapping',
    label: {
      accessname: 'Access Name',
      hosttype: 'Host Type',
      location: 'Location',
      department: 'Department',
      accesscontrol: 'Access Control',
      action: "Action",
      no: 'No.',
    },
    placeholder: {
      accessname: 'Enter Access Name',
      hosttype: 'Select Host Type',
      location: 'Select Location',
      department: 'Select Department',
      accesscontrol: 'Select Access Control'
    },
    error: {
      accessname: 'Access Name is required.',
      accessnameInvalid: "Please enter valid Access Name.",
      hosttype: 'Please select Host Type.',
      location: 'Please select Location.',
      department: 'Please select Department.',
      accesscontrol: 'Please select Access control.'
    },
  },
  employeeMaster: {
    heading: {
      mainHeading: 'Employee Master',
      addHeading: 'Add Employee',
      addUserRole: 'Add UserRole',
      notification: 'User Preferred Notification',
      editHeading: 'Edit Employee'
    },
    btnLabel: {
      createEmployee: 'Create Employee',
      back: 'Back',
      submit: 'Submit',
      cancel: 'Cancel',
      reset: 'Reset'
    },
    tableHead: {
      photo: 'Photo',
      company: 'Company',
      name: 'Name',
      departmentName: 'Department Name',
      email: 'Email',
      mobileNo: 'Mobile No.',
      action: 'Action'
    },
    formLabel: {
      towerName: 'Tower Name',
      companyName: 'Company Name',
      department: 'Department Name',
      name: 'Employee Name',
      email: 'Email',
      phone: 'Phone',
      username: 'Username',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      gender: 'Gender',
      status: 'Status',
      userRole: 'UserRole',
      expiryDate: 'Expiry Date'
    },
    notificationLabel: {
      email: 'Email',
      sms: 'SMS',
      whatsApp: 'WhatsApp',
      telegram: 'Telegram',
      all: 'All'
    },
    placeholder: {
      departmentName: 'Select Department',
      selectTower: 'Select Tower',
      selectCompany: 'Select Company',
      name: 'Employee Name',
      email: 'Email',
      username: 'Username',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      gender: 'Select Gender',
      status: 'Select Status',
      userRole: 'Select UserRole',
      expiryDate: 'dd/mm/yyyy'
    },
    error: {
      towerNameError: 'Select tower.',
      companyNameError: 'Select company.',
      departmentNameError: 'Select department.',
      nameError: 'Employee name is required.',
      namePatternError: 'Please enter valid employee name.',
      emailError: 'Email is required.',
      emailPatternError: 'Please enter valid email.',
      phoneNumberError: 'Phone number is required.',
      phoneNumberPatternError: 'Please enter valid number.',
      usernameError: 'Username is required.',
      passwordError: 'Password  is required.',
      passwordPatternError: 'Please enter valid password.',
      confirmPasswordError: 'Confirm password is required.',
      genderError: 'Select gender.',
      statusError: 'Select status.',
      userRoleError: 'Select user role.',
      expiryDateError: 'Select expiry date.',
      selectCompanyMessage: 'Please select tower first',
      selectDepartmentMessage: 'Please select tower and company first',
      dropdownError: 'No options',
      selectCompanyMsg: 'Please select tower first',
      selectDepartmentMsg: 'Please select company first',
      notificatioError: 'Select atleast one.',
      passwordMatchError: "Password and confirm password doesn't match."
    },
    deleteEmployee: {
      sweetAlert: {
        confirmationTitle: 'Are you sure?',
        confirmationText: "You won't be able to revert this!",
        confirmationBtnLabel: 'Yes, delete it!',
        simpleTitle: 'Deleted!'
      }
    }
  },
  ipCameraMaster: {
    heading: {
      mainHeading: 'IP Camera Master',
      addHeading: 'Add IP Camera',
      editHeading: 'Edit IP Camera'
    },
    btnLabel: {
      createIpCamera: 'Create IP Camera',
      back: 'Back',
      submit: 'Submit',
      cancel: 'Cancel',
      reset: 'Reset'
    },
    tableHead: {
      tower: 'Tower',
      cameraType: 'Camera Type',
      cameraName: 'Camera Name',
      cameraIP: 'Camera IP',
      cameraPort: 'Camera Port',
      action: 'Action'
    },
    formLabel: {
      towername: 'Tower Name',
      cameraType: 'Camera Type',
      cameraName: 'Camera Name',
      cameraIP: 'Camera IP',
      cameraPort: 'Camera Port'
    },
    placeholder: {
      selectTower: 'Select Tower',
      towername: 'Tower Name',
      cameraType: 'Camera Type',
      cameraName: 'Camera Name',
      cameraIP: 'Camera IP',
      cameraPort: 'Camera Port'
    },
    error: {
      towerNameError: 'Select tower.',
      cameraTypeError: 'Camera type is required.',
      cameraNameError: 'Camera name is required.',
      cameraIpError: 'Camera ip is required.',
      cameraIpPatternError: 'Please enter valid device ip.',
      cameraPortPatternError: 'Please enter valid device port.',
      cameraPortError: 'Camera port is required.',
      dropdownError: 'No options'
    },
    deleteIpCamera: {
      sweetAlert: {
        confirmationTitle: 'Are you sure?',
        confirmationText: "You won't be able to revert this!",
        confirmationBtnLabel: 'Yes, delete it!',
        simpleTitle: 'Deleted!'
      }
    }
  },
  passTemplate: {
    heading: {
      mainHeading: 'Pass Template'
    },
    btnLabel: {
      reset: 'Reset',
      print: 'Print',
      save: 'Save'
    },
    formLabel: {
      passSize: 'Pass size (Width :500, Height :500)',
      fieldSize: 'Field Size (Width, Height:)',
      fieldPos: 'Field Pos (Left, Top:)'
    }
  },
  accessControl: {
    heading: {
      mainHeading: 'Access Control',
      addHeading: 'Add Access Control',
      editHeading: 'Edit Access Control'
    },
    btnLabel: {
      createAccess: 'Create Access',
      back: 'Back',
      submit: 'Submit',
      cancel: 'Cancel',
      reset: 'Reset'
    },
    formLabel: {
      accessControl: 'Access Control'
    },
    tableHead: {
      accessControl: 'Access Control',
      action: 'Action'
    },
    placeholder: {
      accessControl: 'Access Control'
    },
    error: {
      accessError: 'Access control is required.'
    },
    deleteAccess: {
      sweetAlert: {
        confirmationTitle: 'Are you sure?',
        confirmationText: "You won't be able to revert this!",
        confirmationBtnLabel: 'Yes, delete it!',
        simpleTitle: 'Deleted!'
      }
    }
  },
  companyMaster: {
    heading: 'Company Master',
    btnLabel: 'Create Company',
    viewModelHeading: 'View Access Control',
    addCompany: {
      heading: 'Add Company',
      editHeading: 'Edit Company',
      backBtn: 'Back',
      label: {
        companyName: 'Company Name',
        towerName: 'Tower Name',
        address: 'Address',
        accessControl: 'Access Control',
        country: 'Country',
        city: 'City',
        adminName: 'Admin Name',
        adminEmail: 'Admin Email',
        phone: 'Phone',
        workingHours: 'Working Hours',
        startTime: 'Start Time',
        endTime: 'End Time',
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thrusday: 'Thrusday',
        friday: 'Friday',
        saturday: 'Saturday',
        publicHoliday: 'Public Holiday',
        date: 'Date',
        submitBtn: 'Submit',
        cancelBtn: 'Cancel',
        resetBtn: 'Reset',
        closeBtn: 'Close'
      },
      placeholder: {
        companyName: 'Company Name',
        towerName: 'Select Tower',
        accessControl: 'Select Access Control',
        country: 'Select Country',
        city: 'Select City',
        adminName: 'Admin Name',
        adminEmail: 'Admin Email',
        phone: 'Phone',
        time: 'hh:mm',
        date: 'dd/mm/yyyy',
        profile: 'Upload your company logo'
      },
      error: {
        profileReq: 'Company logo is required.',
        companyNameReq: 'Company name is required.',
        companyNameInvalid: 'Please enter valid company name.',
        towerNameReq: 'Select your tower.',
        addressReq: 'Address is required.',
        countryReq: 'Select country name.',
        cityReq: 'Select city name.',
        phoneNoReq: 'Phone number is required.',
        phoneNoInvalid: 'Please enter valid phone number.',
        accessControlReq: 'Select access control.',
        adminNameReq: 'Admin name is required.',
        adminNameInvalid: 'Please enter valid admin name.',
        adminEmailReq: 'Admin email is required.',
        adminEmailInvalid: 'Please enter valid email.',
        selectCountryMsg: 'Please select country first.',
        dropdownError: 'No options',
        viewAccessError: 'No rights found yet'
      }
    },
    companyTable: {
      logo: 'Logo',
      company: 'Company',
      tower: 'Tower',
      address: 'Address',
      city: 'City',
      country: 'Country',
      adminName: 'Admin Name',
      adminEmail: 'Admin Email',
      mobileNo: 'Mobile No.',
      action: 'Action'
    },
    sweetAlert: {
      confirmationTitle: 'Are you sure?',
      confirmationText: "You won't be able to revert this.",
      confirmationBtnLabel: 'Yes, delete it.',
      simpleTitle: 'Deleted.',
      simpleText: 'Company has been deleted.'
    }
  },
  deviceMaster: {
    heading: 'Device Master',
    btnLabel: 'Create Device',
    addDevice: {
      heading: 'Add Device',
      editHeading: 'Edit Device',
      backBtn: 'Back',
      label: {
        accessControl: 'Access Control',
        groundFloor: 'Ground Floor',
        lift: 'Lift',
        door: 'Door',
        towerName: 'Tower Name',
        companyName: 'Company Name',
        departmentName: 'Department Name',
        deviceName: 'Device Name',
        deviceIp: 'Device IP',
        devicePort: 'Device Port',
        deviceType: 'Device Type',
        submitBtn: 'Submit',
        cancelBtn: 'Cancel',
        resetBtn: 'Reset',
        all: "All"
      },
      placeholder: {
        towerName: 'Select Tower',
        companyName: 'Select Company',
        departmentName: 'Select Department',
        deviceName: 'Device Name',
        deviceIp: 'IP',
        devicePort: 'Port',
        deviceType: 'Select Device Type'
      },
      error: {
        accessControlReq: 'Select atleast one.',
        towerNameReq: 'Select tower name.',
        companyNameReq: 'Select company name.',
        departmentNameReq: 'Select department name.',
        deviceNameReq: 'Device name is required.',
        deviceNameInvalid: 'Please enter valid device name.',
        deviceIpReq: 'Device ip is required.',
        deviceIpInvalid: 'Please enter valid device ip.',
        devicePortReq: 'Device port is required.',
        devicePortInvalid: 'Please enter valid device port.',
        deviceTypeReq: 'Select device type.',
        selectCompanyMsg: 'Please select tower first.',
        selectDepartmentMsg: 'Please select company first.',
        dropdownError: 'No options'
      }
    },
    deviceTable: {
      companyName: 'Company',
      towerName: 'Tower',
      deviceName: 'Device Name',
      deviceIp: 'Device IP',
      devicePort: 'Device Port',
      deviceType: 'Device Type',
      action: 'Action'
    },
    sweetAlert: {
      confirmationTitle: 'Are you sure?',
      confirmationText: "You won't be able to revert this.",
      confirmationBtnLabel: 'Yes, delete it.',
      simpleTitle: 'Deleted.',
      simpleText: 'Device has been deleted.'
    }
  },
  assetMaster: {
    heading: 'Asset Master',
    btnLabel: 'Create Asset',
    addAsset: {
      heading: 'Add Asset',
      editHeading: 'Edit Asset',
      backBtn: 'Back',
      label: {
        assetName: 'Asset Name',
        submitBtn: 'Submit',
        cancelBtn: 'Cancel',
        resetBtn: 'Reset'
      },
      placeholder: {
        assetName: 'Asset Name'
      },
      error: {
        assetNameReq: 'Asset name is required.'
      }
    },
    assetTable: {
      assetName: 'Asset Name',
      action: 'Action'
    },
    sweetAlert: {
      confirmationTitle: 'Are you sure?',
      confirmationText: "You won't be able to revert this.",
      confirmationBtnLabel: 'Yes, delete it.',
      simpleTitle: 'Deleted.',
      simpleText: 'Asset has been deleted.'
    }
  },
  vipVisits: {
    heading: 'VIP Visits',
    btnLabel: 'Create Vip Visit',
    addVipVisit: {
      heading: 'Add Vip Visit',
      editHeading: 'Edit Vip Visit',
      backBtn: 'Back',
      label: {
        visitorName: "Visitor's Name",
        companyName: 'Company Name',
        host: 'Host',
        date: 'Date',
        timeIn: 'Time In',
        timeOut: 'Time Out',
        designation: 'Designation',
        addRemark: 'Add Remark',
        submitBtn: 'Submit',
        cancelBtn: 'Cancel',
        resetBtn: 'Reset'
      },
      placeholder: {
        visitorName: "Visitor's Name",
        companyName: 'Select Company',
        host: 'Select Host...',
        date: 'dd/mm/yyyy',
        timeIn: 'hh:mm',
        timeOut: 'hh:mm',
        designation: 'Designation'
      },
      error: {
        visitorNameReq: "Visitor's name is required.",
        visitorNameInvalid: "Please enter valid visitor's name.",
        companyReq: 'Select your company.',
        selectCompanyMsg: 'Please select company first.',
        dropdownError: 'No options',
        hostReq: 'Select your host.',
        dateReq: 'Date is required.',
        timeInReq: 'Time-in is required.',
        timeOutReq: 'Time-out is required.',
        designationReq: 'Designation is required.',
        designationInvalid: 'Please enter valid designation.'
      }
    },
    vipVisitsTable: {
      visitorName: "Visitor's Name",
      host: 'Host',
      date: 'Date',
      timeIn: 'Time In',
      timeOut: 'Time Out',
      tower: 'Tower',
      company: 'Company',
      action: 'Action'
    },
    sweetAlert: {
      confirmationTitle: 'Are you sure?',
      confirmationText: "You won't be able to revert this.",
      confirmationBtnLabel: 'Yes, delete it.',
      simpleTitle: 'Deleted.',
      simpleText: 'VIP visit has been deleted.'
    }
  },
  manageGroupVisit: {
    heading: {
      mainHeading: 'Manage Group Visit',
      addHeading: 'Add Group Visit',
      testGroup: 'Test Group'
    },
    btnLabel: {
      createGroupVisit: 'Create Group Visit',
      back: 'Back',
      upload: 'Upload',
      sampleDownload: 'Sample Download',
      register: 'Register',
      reset: 'Reset',
      addMembers: 'Add Members'
    },
    formLabel: {
      groupName: 'Group Name',
      orgUniversity: 'Name of Org/University',
      groupLeader: 'Name of Group leader',
      purposeOfVisit: 'Purpose of visit',
      tower: 'Tower',
      company: 'Company',
      host: 'Host',
      dateAndTime: 'Date and time of Visit'
    },
    groupDetails: {
      company: 'Company',
      tower: 'Tower',
      orgUniversity: 'Org/University',
      leader: 'Leader'
    },
    tableHead: {
      dateAndTime: 'Date and Time',
      groupName: 'Group Name',
      orgUniversity: 'Org/University',
      leader: 'Leader',
      purpose: 'Purpose',
      host: 'Host',
      tower: 'Tower',
      company: 'Company',
      name: 'Name',
      email: 'Email',
      mobileNo: 'Mobile No',
      action: 'Action'
    },
    placeholder: {
      groupName: 'Group Name',
      orgUniversity: 'Name of Org/University',
      groupLeader: 'Name of Group leader',
      purposeOfVisit: 'Purpose of visit',
      selectTower: 'Select Tower',
      selectCompany: 'Select Company',
      host: 'Host',
      dateAndTime: 'Date and time of Visit',
      uploadcsv: 'Upload CSV file',
      dateAndTime: 'dd/mm/yyyy  hh:mm'
    },
    error: {
      fileError: 'File is required.',
      groupNameError: 'Group name is required.',
      orgUniversityError: 'Org/university is required.',
      groupLeaderError: 'Group leader name is required.',
      groupLeaderPatternError: 'Please enter valid group leader name.',
      purposeOfVisitError: 'Purpose of visit is required.',
      towerError: 'Select tower name.',
      companyError: 'Select company name.',
      hostError: 'Host is required.',
      dateAndTimeError: 'Date and time is required.',
      dropdownError: 'No options',
      selectCompanyMsg: 'Please select tower first',
      selectHostError: 'Please select company first.',
      dropdownError: 'No options'
    },
    view: {
      tableHead: {
        name: 'Name',
        email: 'Email',
        mobileNo: 'Mobile No',
        company: 'Company',
        action: 'Action'
      },
      reportLabel: {
        company: 'Company: ',
        orgUniversity: 'Org/University: ',
        leader: 'Leader: '
      },
      deleteMember: {
        sweetAlert: {
          confirmationTitle: 'Are you sure?',
          confirmationText: "You won't be able to revert this!",
          confirmationBtnLabel: 'Yes, delete it!',
          simpleTitle: 'Deleted!'
        }
      }
    }
  },
  bypassVisitor: {
    headingBypass: 'Bypass Visitor',
    headingCreateVisitor: 'Create New Visitor',
    forOfficePurpose: 'For Office Purpose',
    assetListTableName: 'Assets List',
    label: {
      visitorPhoneNo: 'Enter Visitor Mobile Number',
      verifyBtn: 'Verify',
      verifiedBtn: 'Verified',
      capturePhoto: 'Capture Photo',
      scanfrontDoc: 'Scan Front Document',
      scanbackDoc: 'Scan Back Document',
      nationalId: 'National ID',
      alienId: 'Alien ID',
      passport: 'Passport',
      drivingLicense: 'Driving License',
      companyId: 'Company ID',
      other: 'Other Documents',
      docIdNumber: 'Document Id Number',
      nationality: 'Nationality',
      fullName: 'Full Name',
      dob: 'Date Of Birth',
      gender: 'Gender',
      visitorType: 'Visitor Type',
      visitorFrom: 'Visitor From',
      tower: 'Tower',
      company: 'Company',
      department: 'Department',
      host: 'Host',
      purpose: 'Purpose',
      accessCardNo: 'Access Card Number',
      numberOfPax: 'Number of PAX',
      vrn: 'Vehicle Registration Number',
      assetDetails: 'Asset Details',
      addAssetBtn: 'Add Assets',
      typeOfAssets: 'Type Of Assets',
      make: 'Make',
      serialNo: 'Serial No.',
      remark: 'Remarks',
      verifyCard: 'Verify Card',
      bioMetric: 'Biometric',
      facialRecognition: 'Facial Recognition',
      save: 'Save',
      reset: 'Reset',
      add: 'Add',
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
      addAsset: 'Add Asset',
      editAsset: 'Edit Asset'
    },
    assetTableLabel: {
      typeOfAsset: 'Type Of Assets',
      make: 'Make',
      serialNo: 'Serial Number',
      remark: 'Remark',
      action: 'Action'
    },
    placeholder: {
      dob: 'dd-mm-yyyy',
      gender: '',
      visitorType: '',
      tower: '',
      company: '',
      department: '',
      host: '',
      purpose: '',
      accessCardNo: '',
      numberOfPax: '',
      vrn: '',
      assetDetails: '',
      typeOfAssets: '',
      make: '',
      serialNo: '',
      remark: ''
    },
    error: {
      phoneNoReq: "Visitor's mobile number is required.",
      phoneNoInvalid: "Please enter valid visitor's mobile number.",
      documentTypeReq: 'Select any one of them.',
      documentIdNumber: 'Document id number is required.',
      nationality: 'Nationality is required.',
      fullNameReq: 'Full name is required.',
      fullNameInvalid: 'Please enter valid full name.',
      birthDateReq: 'Birthdate is required.',
      genderReq: "Select visitor's gender.",
      visitorTypeReq: 'Select visitor type.',
      visitorFromReq: 'Visitor from is required.',
      towerReq: 'Select tower name.',
      selectCompanyMsg: 'Please select tower first.',
      dropdownError: 'No options',
      companyReq: 'Select company name.',
      selectDepartmentMsg: 'Please select company first.',
      selectHostMsg: 'Please select company and department first.',
      departmentReq: 'Select department name.',
      hostReq: 'Select host name.',
      purposeReq: 'Purpose is required.',
      accessCardNoReq: 'Access card number is required.',
      numberOfPaxReq: 'Number of pax is required.',
      vrnReq: 'Vehicle registration number is required.',
      assetTypeReq: 'Select asset type.',
      makeReq: 'Make is required.',
      serialNoReq: 'Serial number is required.',
      remarkReq: 'Remark is required.',
      noAssetsAdded: 'No asset added.',
      verifyPhoneNumber: 'Please verify phone number first.'
    },
    sweetAlert: {
      confirmationTitle: 'Are you sure?',
      confirmationText: "You won't be able to revert this.",
      confirmationBtnLabel: 'Yes, delete it.',
      simpleTitle: 'Deleted.',
      simpleText: 'Asset has been deleted.'
    }
  },
  blackListVisitor: {
    heading: {
      mainHeading: 'Blacklist Visitor'
    },
    btnLabel: {
      addBlackListVisitor: 'Add Black List Visitor',
      uploadVisitor: 'Upload Visitor'
    },
    tableHead: {
      visitorName: "Visitor's Name",
      visitorType: 'Visitor Type',
      accessCardNo: 'Access Card No.',
      mobileNo: 'Mobile No.',
      reason: 'Reason',
      blockedBy: 'Blocked By',
      moreinfo: 'More Info',
      action: 'Action'
    },
    unblockVisitor: {
      sweetAlert: {
        confirmationTitle: 'Are you sure?',
        confirmationText: 'You want to unblock this visitor!',
        confirmationBtnLabel: 'Yes, unblock it!',
        simpleTitle: 'Unblocked!'
      }
    },
    blacklistdropdown: {
      viewblacklist: 'View Blacklist Reason',
      whitelist: 'WhiteList'
    },
    modal: {
      tittle: {
        addBlacklistVisitor: 'Add Blacklist',
        uploadBlacklistVisitor: 'Upload Blacklist',
        viewBlacklistVisitor: 'View Blacklist Reason'
      },
      text: {
        para1: '1. Before uploading the blacklist file, please ensure the Document No., Email Address or the visitor Mobile Number(with Country Code) is included.',
        para2: '2. The Visitors Name is optional and the Reason is mandatory.',
        para3: '3. Click to Download sample File here.'
      },
      btnLabel: {
        close: 'Close',
        addBlackList: 'Add Black List',
        sampleDownload: 'Sample Download'
      },
      placeholder: {
        fileDrop: 'Drop your file here',
        reason: 'Reason',
        search: 'Search by: Name, Document No., Email Address, or Mobile No.',
        blackListType: 'Select Black List Type',
        blackListDate: 'dd/mm/yyyy'
      },
      formLabel: {
        reason: 'Reason',
        blackListType: 'Black List Type',
        blackListDate: 'Black List Date'
      },
      error: {
        reasonError: 'Reason is required.',
        reasoninvalidError: 'Please enter valid Reason.',
        data: 'This field is required.',
        fileError: 'Please select file first.',
        blackListType: 'Black List Type is required.',
        blackListDate: 'Black List Date is required.'
      },
      tableHead: {
        visitorName: "Visitor's Name",
        visitorType: 'Visitor Type',
        accessCardNo: 'Access Card No.',
        mobileNo: 'Mobile No.'
      }
    }
  },
  appointment: {
    heading: {
      mainHeading: 'Appointment',
      addHeading: 'Add Appointment',
      editHeading: 'Edit Appointment'
    },
    btnLabel: {
      createAppointment: 'Create Appointment',
      back: 'Back',
      reset: 'Reset',
      cancel: 'Cancel',
      submit: 'Submit',
      filter: 'Filter'
    },
    tableHead: {
      id: 'Id',
      staffname: 'Staff Name',
      visitorname: 'Visitor Name',
      from: 'From',
      bookedby: 'Booked By',
      appointmentdate: 'Appointment Date',
      appointmenttime: 'Appointment Time',
      status: 'Status',
      action: 'Action'
    },
    formLabel: {
      onBehalfOf: 'Staff Name',
      countrycode: 'Country Code.',
      staffmobileno: 'Staff Mobile No.',
      hostMobileNo: 'Host Mobile No.',
      guestMobileNo: 'Visitor Mobile No.',
      identityType: 'Identification Type',
      identificationNo: 'Identification No',
      firstName: 'Visitor First Name',
      lastName: 'Visitor Last Name',
      email: 'Visitor Email',
      companyFrom: 'Visitor From',
      dateOfAppointment: 'Date Of Appointment',
      startTime: 'Start Time',
      endTime: 'End Time',
      purpose: 'Purpose',
      notes: 'Notes',
      imageUpload: 'Image Upload'
    },
    placeholder: {
      staffname: 'Select Staff Name',
      hostMobileNo: 'Host Mobile No.',
      identityType: 'Select Identity Type',
      identificationNo: 'Identification No',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      companyFrom: 'Company From',
      dateOfAppointment: 'dd/mm/yyyy',
      startTime: 'hh:mm',
      endTime: 'hh:mm',
      purpose: 'Purpose',
      notes: 'Notes',
      imageUpload: 'Upload Image Here',
      visitorMobileNo: 'Staff Mobile No.',
    },
    error: {
      dropdownError: 'No options',
      addStaffName: "Add staff member first.",
      staffname: 'Staff Name is required.',
      staffnameinvalid: 'Please enter valid Staff Name',
      hostMobileNoError: 'Host Mobile No. is required.',
      hostMobileNoPatternError: 'Please enter valid phone number.',
      visitorMobileNoError: 'Visitor Mobile No. is required.',
      visitorMobileNoPatternError: 'Please enter valid phone number.',
      identityTypeError: 'Please select Identification Type.',
      identificationNoError: 'Identification No. is required.',
      firstNameError: 'Visitor First Name is required.',
      firstNameInvalidError: 'Please enter valid Visitor First Name.',
      lastNameError: 'Visitor Last Name is required.',
      emailError: 'Visitor Email is required.',
      emailPatternError: 'Please enter valid Email.',
      companyFromError: 'Visitor From is required.',
      dateOfAppointmentError: 'Date Of Appointment is required.',
      startTimeError: 'Start Time is required.',
      endTimeError: 'End Time is required.',
      purposeError: 'Purpose is required.',
      notesError: 'Notes is required.',
      imageUploadError: 'Image is required.',
      staffmbno: 'Staff Mobile No. is required.',
      staffmbnoinvalid: 'Please enter valid Staff Mobile No.',
      visitorMobileNoError: 'Visitor Mobile No. is required.',
      visitorMobileNoPatternError: 'Please enter valid Visitor Mobile No.',
      countrycode: 'Please select Country Code',
      staffMobileNoError: 'Staff Mobile No. is required.',
      staffMobileNoPatternError: 'Please enter valid Staff mobile No.'
    },
    deleteAppointment: {
      sweetAlert: {
        confirmationTitle: 'Are you sure?',
        confirmationText: "You won't be able to revert this!",
        confirmationBtnLabel: 'Yes, cancel it!',
        simpleTitle: 'Canceled!'
      }
    },
    calender: {
      btnLabel: {
        addSchedule: 'Add Schedule'
      }
    },
    scheduleModal: {
      heading: {
        createSchedule: 'Create Schedule',
        editSchedule: 'Edit Schedule'
      },
      btnLabel: {
        save: 'Save',
        moreOptions: 'More Options',
        edit: 'Edit',
        seeMoreDetails: 'See more details'
      },
      formLabel: {
        title: 'Title',
        startDate: 'Start Date',
        endDate: 'End Date',
        allDay: 'All Day',
        scheduleMeeting: 'Schedule Meeting',
        description: 'Description',
        label: 'Label',
        description: 'Description',
        dateAndTime: 'Date and Time'
      },
      placeholder: {
        title: 'Title',
        startDate: 'dd/mm/yyyy  hh:mm',
        endDate: 'dd/mm/yyyy  hh:mm',
        addVideoLink: 'Add video conference link',
        description: 'Description',
        label: 'Label'
      },
      error: {
        titleError: 'Title is required.',
        startDateError: 'Start date is required.',
        endDateError: 'End date is required.',
        allDayError: 'Select all day.',
        descriptionError: 'Description is required.',
        labelError: 'Select Label.'
      }
    }
  },
  members:{
heading:"Add Members",
editheading:"Edit Members",
table:{
no:"No.",
membername:"Member Name",
memberno:"Member No.",
phone:"Mobile No.",
email:"Email Address",
expiry:"Expiry Date",
accesscard:"Access Card No.",
category:"Member Category",
action:"Action"
},
label:{
memberno:"Member No.",
membername:"Member Name",
countrycode:"Country Code",
mobileno:"Mobile No.",
expirydate:"Expiry Date",
email:"Email Address",
category:"Member Category",
accesscard:"Access Card No."
},
placeholder:{
  memberno:"Member No.",
  membername:"Member Name",
  mobileno:"Mobile No.",
  expirydate:"dd-mm-yyyy",
  email:"Email Address",
  category:"Select Member Category",
  accesscard:"Access Card No."
},
error:{
  memberno:"Member No. is required.",
  membernoinvalid:"Please enter valid Member No.",
  membername:"Member Name is required",
  membernameinvalid:"Please enter valid Member Name.",
  mobileno:"Mobile No. is required.",
  mobilenoinvalid:"Please enter valid Mobile No.",
  expirydate:"Expiry Date is required.",
  email:"Email Address is required",
  emailinvalid:"Please enter valid Email Address.",
  category:"Please Select Member Category",
  accesscard:"Access Card No is required.",
  accesscardinvalid:"Please enter valid Access Card No."
},
btnLabel: {
  add: 'Add',
  reset: 'Reset',
  update:"Update"
}
  },
  smsSettings: {
    heading: {
      smsSettings: 'SMS Settings'
    },
    label: {
      senderid: 'Sender ID',
      secretKey: 'Secret Key',
      url: 'Url',
      apikey: 'Api Key'
    },
    placeholder: {
      senderid: 'Sender ID',
      secretKey: 'Secret Key',
      url: 'Url',
      apikey: 'Api Key'
    },
    error: {
      senderError: 'Sender ID is required.',
      secretKeyError: 'Secret Key is required.',
      urlError: 'Url is required.',
      mobileNoPattern: 'Please enter valid mobile number.',
      apikey: 'Api Key is required.'
    },
    btnLabel: {
      add: 'Add',
      reset: 'Reset'
    }
  },
  manageFrontDesk: {
    heading: "Manage FrontDesk",
    visitorRegistration: "Visitor Registration",
    guestRegistration: "Guest Registration",
    label: "Label",
    mandatoryField: "Mandatory Field",
    visitorFrom: "Visitor From",
    department: "Department",
    host: "Host",
    accessCardNo: "Access Card No.",
    purpose: "Purpose",
    vehicleRegister: "Vehicle Registration",
    remarks: "Remarks",
    destination: "Destination"
  },
  parameterSettings: {
    heading: "Parameter Settings",
    generalSettings: 'General Settings',
    emailReminders: "Email Reminders",
    notification: "Notification",
    notifications: "Notifications",
    workingHours: "Working Hours",
    section: "Section",
    input: "Input",
    maximumNo: "Maximum No. of visit per year/12 months",
    maximumPeriod: "Maximum Period/Months per visit",
    theReport: "The report will be sent to the Security Manager's Email",
    system: "System Generated Report",
    sendNotification: "Send Notification",
    dailyVisit: "Daily Visits Report",
    notLoggedOut: "Visitors Not Logged Out",
    timesVisit: "6 Times Visit Report",
    sendNotification: "Send Notification",
    notifyMember: "Notify Member on Guest Arrival",
    notifyStaff: "Notify Staff on Visitor Arrival",
    day: "Day",
    startTime: "Start Time",
    endTime: "End Time",
    monDay: "Monday",
    tuesDay: "Tuesday",
    wednesDay: "Wednesday",
    thrusDay: "Thrusday",
    friDay: "Friday",
    saturDay: "Saturday",
    sunDay: "Sunday",
    time: "H:i",
    submitBtn: "Submit"
  },
  visitorType: {
    heading: {
      addVisitorType: 'Add Visitor Type'
    },
    label: {
      visitorType: 'Visitor Type'
    },
    placeholder: {
      visitorType: 'Visitor type...'
    },
    error: {
      visitorTypeError: 'Visitor type is required.'
    },
    btnLabel: {
      add: 'Add'
    }
  },
  emailSettings: {
    heading: {
      emailSettings: 'Email Settings'
    },
    label: {
      mailServer: 'Mail Server',
      outport: 'Outport',
      fromId: 'From Id',
      userName: 'User Name',
      password: 'Password',
      confirmpassword: 'Confirm Password',
      driver: "Driver",
      encryption: "Encryption",
      senderName: "SenderName",
      email: "Email",
      sslId: 'SSL ID',
      requiredMailAuthentication: 'Required Mail Authentication',
      enableSSL: 'Enable SSL'
    },
    placeholder: {
      mailServer: '',
      outport: '',
      fromId: '',
      userName: '',
      password: '',
      confirmpassword: '',
      driver: "",
      encryption: "",
      senderName: "",
      email: "",
      sslId: 'SSL ID'
    },
    error: {
      mailServerError: 'Mail server is required.',
      outportError: 'Outport is required.',
      fromIdError: 'From id is required.',
      userNameError: 'User Name is required.',
      passwordError: 'Password is required.',
      confirmpasswordError: 'Confirm Password is required.',
      passwordPattern: 'Please enter valid password.',
      driverError: "Driver is required.",
      encryptionError: "Encryption is required.",
      senderNameError: "SenderName is required.",
      emailError: "Email is required.",
      emailPattern: "Please enter valid email.",
      sslIdError: 'SSL id is required.',
      passwordMatchError: "Password and confirm password doesn't match."
    },
    btnLabel: {
      save: 'Save',
      reset: 'Reset'
    }
  },
  configurationSettings: {
    heading: {
      configurationSettings: 'Configuration Settings'
    },
    label: {
      installationName: 'Select type of installation'
    },
    placeholder: {
      installationName: 'Select type of installation'
    },
    error: {
      installationNameError: 'Select type of installation is required.'
    },
    btnLabel: {
      save: 'Save',
      reset: 'Reset'
    }
  },
  applicationSettings: {
    heading: {
      applicationSettings: 'Application Settings'
    },
    label: {
      accessControl: 'Access Control',
      notification: 'Notification',
      all: 'All',
      LDAPURL: 'LDAP URL',
      LDAPUserName: 'LDAP User Name',
      LDAPPassword: 'LDAP Password '
    },
    placeholder: {
      LDAPURL: 'LDAP URL',
      LDAPUserName: 'LDAP User Name',
      LDAPPassword: 'LDAP Password'
    },
    error: {
      customSettingError: 'Select any one of them.',
      accessControlError: 'Select any one of them.',
      notificationError: 'Select any one of them.',
      LDAPURLError: 'LDAP url is required.',
      LDAPUserNameError: 'LDAP user Name is required.',
      LDAPPasswordError: 'LDAP password is required.',
    },
    btnLabel: {
      add: 'Add',
      reset: 'Reset'
    }
  },
  proxySettings: {
    heading: {
      proxySettings: 'Proxy Settings'
    },
    label: {
      proxyServer: 'Proxy Server',
      proxyUsername: 'Proxy Username',
      proxyPassword: 'Proxy Password'
    },
    placeholder: {
      proxyServer: 'Proxy Server',
      proxyUsername: 'Proxy Username',
      proxyPassword: 'Proxy Password'
    },
    error: {
      proxyServerError: 'Proxy Server is required.',
      proxyUsernameError: 'Proxy Username is required.',
      proxyPasswordError: 'Proxy Password is required.'
    },
    btnLabel: {
      add: 'Add',
      reset: 'Reset'
    }
  },
  whatsAppSettings: {
    heading: {
      whatsAppSettings: 'WhatsApp Settings'
    },
    label: {
      senderid: 'Sender ID',
      url: 'Url',
      mobileNo: 'Mobile Number'
    },
    placeholder: {
      senderid: 'Sender ID',
      url: 'Url',
      mobileNo: 'Mobile Number'
    },
    error: {
      senderError: 'Sender ID is required.',
      urlError: 'Url is required.',
      mobileNoError: 'Mobile Number is required.',
      mobileNoPattern: 'Please enter valid mobile number.'
    },
    btnLabel: {
      add: 'Add',
      reset: 'Reset'
    }
  },
  telegramSettings: {
    heading: {
      telegramSettings: 'Telegram  Settings'
    },
    label: {
      key: 'Key',
      secretKey: 'Secret Key',
      mobileNo: 'Mobile Number'
    },
    placeholder: {
      key: 'Key',
      secretKey: 'Secret Key',
      mobileNo: 'Mobile Number'
    },
    error: {
      keyError: 'Key is required.',
      secretKeyError: 'Secret Key is required.',
      mobileNoError: 'Mobile Number is required.',
      mobileNoPattern: 'Please enter valid mobile number.'
    },
    btnLabel: {
      add: 'Add',
      reset: 'Reset'
    }
  },
  manageFrontdesk: {
    heading: {
      manageFrontdesk: 'Manage Frontdesk',
      FRMON: 'Field Required Mandatory Or Not'
    },
    btnLabel: {
      add: 'Add',
      reset: 'Reset'
    },
    error: {
      manageFrontdeskError: 'Select any one of them.'
    },
  },
  mapEncoding: {
    heading: {
      mapEncoding: 'Map Encoding',
      addMapEncoding: 'Add Map Encoding',
      editMapEncoding: 'Edit Map Encoding'
    },
    btnLabel: {
      addEncoding: 'Add Encoding',
      back: 'Back',
      add: 'Add',
      cancel: 'Cancel',
      reset: 'Reset'
    },
    tableHead: {
      department: 'Department',
      accessControl: 'Access Control',
      action: 'Action'
    },
    formLabel: {
      department: 'Department',
      accessControl: 'Access Control'
    },
    placeholder: {
      department: 'Select Department'
    },
    error: {
      departmentError: 'Department is required.',
      accessControlError: 'Access Control is required.',
      dropdownError: 'No options'
    },
    deleteMapEncoding: {
      sweetAlert: {
        confirmationTitle: 'Are you sure?',
        confirmationText: "You won't be able to revert this!",
        confirmationBtnLabel: 'Yes, delete it!',
        simpleTitle: 'Deleted!'
      }
    }
  },
  logDetails: {
    heading: {
      logDetails: "Log Details"
    },
    tableHead: {
      moduleName: "Module Name",
      transactionType: "Log Type",
      userName: "User Name",
      logDate: "Log Date",
      action: " "
    },
    remarksTableHead: {
      oldData: "Old Data"
    },
    label: {
      dateRange: "Date Range",
      moduleName: "Module Name",
      userName: "User Name",
      oldData: "Old Data",
      newData: "New Data"
    },
    btnLabel: {
      submit: "Submit",
      reset: "Reset",
      cancle: "Back"
    },
    placeholder: {
      dateRange: "d/m/y to d/m/y",
      moduleName: "Module Name",
      userName: "User Name"
    },
    error: {
      commonError: "Select one of them."
    },
    accordingTableHead: {
      transactionType: "Transation Type",
      date: "Date",
      details: "Details"
    },
    accordingTableRowExpand: {
      old: "Old",
      new: "New",
      oldDelete: "Old Delete",
      newAdd: "New Add"
    }
  },

  masters: {
    departments: {
      departmentheading: 'Department Master',
      departmentcreatebtn: 'Create Department',
      departmentshow: 'Show',
      departmententries: 'entries',
      departmentsearch: 'Search:'
    },
    departmentstable: {
      no: 'No.',
      departmentname: 'Department Name',
      status: 'Status',
      createdby: 'Created By',
      createdon: 'Created On',
      action: 'Action'
    }
  },
  userroles: {
    userrolesheading: 'User Roles Master',
    userrolestable: {
      no: 'No.',
      rollname: 'Role Name',
      status: 'Status',
      action: 'Action',
      permission: 'Permission'
    },
    userroleedit: {
      userroleeditheading: "Roles >> Assign to",
      applicationsetting: 'Application Settings',
      dashboard: 'Dashboard',
      master: 'Masters',
      admin: 'Admin',
      manageevent: 'Manage Events',
      booking: 'Booking',
      reports: 'Reports',
      appointment: 'Appointment',
    },
    btnlabel: {
      back: "Back"
    }
  },
  accesscontrol: {
    accesscontrolheading: 'User Access Control',
    addheading: 'Add Access Control',
    editheading: 'Edit Access Control',
    add: 'Add',
    back: 'Back',
    update: 'Update',
    reset: 'Reset',
    accesscontroltable: {
      no: 'No.',
      doorname: 'Door Name',
      doorip: 'Door IP',
      status: 'Status',
      action: 'Action',
    },
    label: {
      doorname: 'Door Name',
      devicename: 'Device Name',
      doortype: 'Door Type',
    },
    placeholder: {
      doorname: 'Enter Door Name',
      devicename: 'Select Device Name',
      doortype: 'Select Door Type',
    },
    error: {
      doorname: 'Door Name is required.',
      doornameinvalid: 'Please enter valid Door Name.',
      devicename: 'Please select Device Name.',
      doortype: 'Please select Door Type.',
    },
  },
  systemuser: {
    systemuserheading: 'System Users',
    createsystemuserbtn: 'Create System User',
    createsystemuserheading: 'Create System Users',
    editsystemuserheading: 'Edit System Users',

    createsystemuserform: {
      departmentname: 'Department Name',
      fullname: 'Full Name',
      emailaddress: 'Email Address',
      countrycode: 'Country Code',
      mobileno: 'Mobile No.',
      username: 'Username',
      password: 'Password',
      confirmpassword: 'Confirm Password',
      gender: 'Gender',
      status: 'Status',
      employeerole: 'Employee Role',
      expirydate: 'Expiry Date',
      preferednotification: 'Prefered Notification',
      adduser: 'Add User',
      update: 'Update',
      cancel: 'Cancel',
      reset: 'Reset',
    },
    createsystemuserformplaceholder: {
      selectdepartment: 'Select Department',
      name: 'Name',
      email: 'Email',
      mobileno: 'Mobile Number',
      username: 'Username',
      password: 'Password',
      confirmpassword: 'Confirm Password',
      selectgender: 'Select Gender',
      selectrole: 'Select Role',
      selectstatus: 'Select Status',
      selectdate: 'dd/mm/yyyy',
    },
    createsystemuserformerror: {
      departmenterror: 'Please select Department Name.',
      fullnameerror: 'Full Name is required.',
      fullNameInvalid: "Please enter valid Full Name.",
      emailerror: 'Email Address is required.',
      emailinvalid: 'Please enter valid Email.',
      countryerror: 'Please select Country Code.',
      mobileerror: 'Mobile No. is required.',
      mobileinvalid: 'Please enter valid Mobile No.',
      usernameerror: 'Username is required.',
      usernameinvaliderror: 'Please enter valid Username.',
      passworderror: 'Password is required.',
      confirmpassworderror: 'Confirm Password is required.',
      gendererror: 'Please select Gender.',
      statuserror: 'Please select Status.',
      employeeroleerror: 'Please select Employee Role.',
      expirydateerror: 'Expiry Date is required.',
      preferederror: 'Please select prefered Notification.',
      passwordinvaliderror: 'Please enter valid Password.',
      confirmpasswordinvaliderror: 'Please enter valid Confirm Password.',
    },
    systemusertable: {
      no: 'No',
      name: 'Name',
      email: 'Email',
      mobileno: 'Mobile No.',
      role: 'Role',
      status: 'Status',
      createdby: 'Created By',
      createdon: 'Created On',
      action: 'Action',
    }
  },
  backupmanagement: {
    backupmanagementheading: 'Backup Management',
    backupmanagementbtn: 'Manual Backup',
    backuptextmain: 'By clicking on Save button you can save your database in your local machine folder.',
    backuptext: 'Note*if given path does not exists, then backup file will be in project/public/backup folder.',
    backupbasedon: 'Backup based on',
    path: 'Path',
    day: 'Day',
    time: 'Time',
    error: 'Backup based on is required.',
    timeerror: 'Time is required.',
    dayerror: 'Day is required.',
    patherror: 'Path is required.',
    backuptable: {
      no: 'No',
      backup: 'Backup',
      createdby: 'Created By',
      createdon: 'Created On',
      action: 'Action',
      savebtn: "Save",
      cancelbtn: 'Cancel',
      reset: 'Reset',
    },
  },
  sosmessage: {
    heading: 'SOS Message',
    message: "Message",
    send: 'Send',
    reset: 'Reset',
    error: 'Message is required.'
  },
  suppliercontractor: {
    heading: "Suppliers & Contractors",
    createsupplierbtn: 'Create Supplier/Contractor',
    contactperson: 'Contact Person',
    editsuppliercontractor: 'Edit Supplier/Contractor',
    update: 'Update',
    suppliercontractortable: {
      no: 'No',
      companyname: 'Company Name',
      companytype: 'Company Type',
      lpo: 'LPO',
      status: 'Status',
      createdby: 'Created by',
      createdon: 'Created On',
      action: 'Action',
    },
    createsupplier: {
      heading: 'Add Supplier/Contractor',
      contactperson: 'Contact Person',
      companyname: 'Company Name',
      companytype: 'Company Type',
      status: 'Status',
      fullname: 'Full Name',
      mobileno: 'Mobile No.',
      email: 'Email Address',
      add: "Add",
      lpoNumber: 'LPO Number'
    },
    createsuppliererror: {
      companyerror: 'Company Name is required.',
      companyseconderror: 'Please enter valid Company Name.',
      companytypeerror: 'Please select Company Type.',
      statuserror: 'Please select Status.',
      fullnameerror: 'Full Name is required.',
      fullnameseconderror: 'Please enter valid Full Name.',
      mobilenoerror: 'Mobile No. is required.',
      secondmobilenoerror: 'Please enter valid Mobile No.',
      emailerror: 'Email Address is required.',
      emailseconderror: 'Please enter valid Email Address.',
      lpoerror: 'LPO number is required.'
    },
    createsupplierplaceholder: {
      companyname: 'Company Name',
      fullname: 'Full Name',
      mobileno: 'Mobile No.',
      selectcompanytype: 'Select Company Type',
      selectstatus: ' Select Status',
      emailaddress: 'Email Address',
      lponumber: 'LPO Number',
    },
  },
  casualworkers: {
    heading: 'Register Casual Worker',
    phone: 'Casual Worker Mobile No.',
    editheading: 'Edit Register Casual Worker',
    mobileno: 'Enter Mobile No.',
    date: 'dd/mm/yyyy',
    companytype: 'Company Name',
    department: 'Select Department',
    casualworkerformlabel: {
      selectidtype: 'Select ID Type',
      companytype: 'Company From',
      documentid: 'Document ID Number',
      nationality: 'Nationality',
      fullname: 'Full Name',
      dateofbirth: 'Date Of Birth',
      gender: 'Gender',
      accesscardno: 'Access Card No.',
      refrenceno: 'Reference Card No.',
      visitortype: 'Visitor Type',
      expirydate: 'Expiry date',
      verifyCard: "Verify Card",
      save: 'Save',
      reset: 'Reset',
      update: 'Update',
      capture: 'Capture Photo',
      scanfront: 'Scan Front Side',
      scanDoc: "Scan Document",
      scanback: 'Scan Back Document',
      department: 'Department'
    },
    casualworkertable: {
      no: 'No.',
      fullname: 'Full Name',
      mobileno: 'Mobile No.',
      idnumber: 'ID Number',
      idtype: 'ID Type',
      accesscardno: 'Access Card No.',
      status: 'Status',
      createdby: 'Created By',
      createdon: 'Created On',
      action: 'Action',
    },
    casualworkererror: {
      mobileno: 'Mobile no. is required.',
      mobileinvalid: 'Please enter valid Mobile No.',
      documentiderror: 'Document ID Number is required.',
      iderror: 'Please Select ID Type.',
      seconddociderror: 'Please enter valid Document ID Number.',
      nationalityerror: 'Nationality is required.',
      secondnationalityerror: 'Please enter valid Nationality.',
      fullnameerror: 'Full Name is required.',
      secondfullnameerror: 'Please enter valid Full Name.',
      dateofbirtherror: 'Date Of Birth is required.',
      seconddoberror: 'Please select valid Date Of Birth.',
      gendererror: 'Please select Gender.',
      companyerror: 'Please select Company From.',
      departmenterror: 'Please select Department Name.',
      refrenceerror: 'Reference Card No. is invalid.'
    },
  },
  bookGuest: {
    heading: "Book a Guest",
    editbookaguestheading: "Edit Book a Guest",
    bookguesttable: {
      no: 'No.',
      fullname: 'Full Name',
      mobileno: 'Mobile No.',
      idnumber: 'ID Number',
      idtype: 'ID Type',
      email: 'Email',
      status: 'Status',
      arrival: 'Arrival Time',
      action: 'Action',
    },
    label: {
      guestFirstName: "Guest First Name",
      guestLastName: "Guest Last Name",
      countryCode: "Country Code",
      guestMobileNo: "Guest Mobile No.",
      email: "Email",
      identificationType: "Identification Type",
      identificationNo: "Identification No",
      dateOfMeeting: "Date of Meeting",
      memberName: "Member Name",
      memberNo: "Member No.",
      submitBtn: "Submit",
      resetBtn: "Reset",
      dob: "Date Of Birth",
      nationality: "Nationality",
      gender: "Gender",
      destination: "Destination",
      vehicleRegistration: "Vehicle Registration No.",
      idtype: "Select ID Type",
      capturePhoto: "Capture Photo",
      frontSide: "Scan Front Side",
      backSide: "Scan Back Side",
      scanDoc: "Scan Document",
    },
    placeholder: {
      guestFirstName: "First Name",
      guestLastName: "Last Name",
      guestMobileNo: "Guest Mobile No",
      email: "Email",
      identificationType: "Select Id",
      identificationNo: "ID card number",
      dateOfMeeting: "dd/mm/yyyy",
      memberName: "Member Name",
      memberNo: "Member No.",
      gender: "Select Gender",
      nationality: "Enter Nationality",
      dob: "dd-mm-yyyy",
      destination: "Select Destination",
      vehicle: "Enter Vehicle Reg No."
    },
    error: {
      guestFirstNameReq: "Guest First Name is required.",
      guestFirstNameInvalid: "Please enter valid Guest First Name.",
      guestLastNameReq: "Guest Last Name is required.",
      guestLastNameInvalid: "Please enter valid Guest Last Name.",
      countryCodeReq: "Country Code is required.",
      guestMobileNoReq: "Guest Mobile No. is required.",
      guestMobileNoInvalid: "Please enter valid Guest Mobile No.",
      emailReq: "Email is required.",
      emailInvalid: "Please enter valid Email.",
      identificationTypeReq: "Identification Type is required.",
      identificationNoReq: "Identification No is required.",
      dateOfMeetingReq: "Date of Meeting is required.",
      memberNameReq: "Member Name is required.",
      memberNameinvalid: "Please enter valid Member Name.",
      memberNoReq: "Member No. required.",
      memberNoinvalid: "Please enter valid Member No.",
      dob: "Date Of Birth is required.",
      gender: "Gender is required.",
      nationality: "Nationality is required.",
      nationalityInvalid: "Please enter valid Nationality.",
      destination: "Destination is required.",
      vehicle: 'Vehicle Registration No. is required.',
      vehicleinvalid: 'Please enter valid Vehicle Registration No.',
      idType: "ID Type is required."
    }
  },
  blacklist: {
    blacklistheading: 'Blacklist',
    addblacklistheading: 'Add Blacklist',
    uploadblacklistheading: 'Upload Blacklist',
    blacklistform: {
      no: 'No.',
      fullname: 'Full Name',
      type: 'Type',
      phoneno: 'Phone Number',
      idno: 'ID No.',
      idtype: 'ID Type',
      blacklisttype: 'Blacklisted Type',
      blacklistdate: 'Blacklisted Date',
      blacklistby: 'Blacklisted By',
      blackliston: 'Blacklisted On',
      action: 'Action',
    },
  },
  createEvent: {
    heading: "Create Event",
    view: 'View Event',
    label: {
      step1: "Step 1: Download Sample Template",
      step2: "Step 2: Upload Event Members",
      eventName: "Event Name",
      eventNumber: "Event Number",
      memberName: "Member Name",
      memberNo: "Member No.",
      eventLocation: "Event Location",
      dateAndTime: "Date and Time of Event",
      noOfGuest: "No. of Guests"
    },
    placeholder: {
      uploadCSV: "Upload CSV file",
      eventName: "Event Name",
      eventNumber: 'Event Number',
      memberName: "Member Name",
      memberNo: "Member No.",
      eventLocation: "Select Event Location",
      dateAndTime: "dd/mm/yyyy --:--",
      noOfGuest: "Enter No. of Guests"
    },
    error: {
      uploadCSVReq: "Please upload csv file.",
      eventNameReq: "Event Name is required.",
      eventNameInvalid: "Please enter valid Event Name.",
      memberNameReq: "Member Name is required.",
      memberNameInvalid: "Please enter valid Member Name.",
      memberNoReq: "Member No. is required.",
      memberNoinvalid: "Please enter valid Member No.",
      eventLocationReq: "Event Location is required.",
      dateAndTimeReq: "Date and Time of Event is required.",
      noOfGuestReq: "No. of Guests is required.",
      noOfGuestInvalid: "Please enter valid No. of Guests."
    },
    btnLabel: {
      sampleDownload: "Sample Download",
      register: "Register",
      reset: "Reset"
    }
  },
  editEvent: {
    heading: "Edit Event",
    label: {
      eventLocation: "Event Location",
      dateAndTime: "Date and Time of Event",
      eventName: "Event Name",
      noOfGuest: "No. of Guests",
      submitBtn: "Submit",
      resetBtn: "Reset",
    },
    placeholder: {
      eventName: "Event Name",
      eventLocation: "Select Event Location",
      dateAndTime: "dd/mm/yyyy --:--",
      noOfGuest: "Enter No. of Guests"
    },
    error: {
      eventNameReq: "Event Name is required.",
      eventNameInvalid: "Please enter valid Event Name.",
      eventLocationReq: "Event Location is required.",
      dateAndTimeReq: "Date and Time of Event is required.",
      noOfGuestReq: "No. of Guests is required.",
      noOfGuestInvalid: "Please enter valid No. of Guests."
    }
  },
  eventLocation: {
    heading: "Event Locations",
    view: "Events List",
    addEventLocation: "Add Event Location",
    editEventLocation: "Edit Event Location",
    label: {
      no: "No.",
      eventLocation: "Event Location",
      status: "Status",
      eventManager: "Event Manager",
      createdby: "Created By",
      createdOn: "Created On",
      action: "Action",
    },
    placeholder: {
      eventLocation: "Event Location",
      status: "Select Status",
    },
    error: {
      eventLocationReq: "Event Location is required.",
      eventLocationinvalid: 'Please enter valid Event Location.',
      statusReq: "Status is required.",
    },
    btnLabel: {
      add: "Add",
      cancel: "Cancel",
      back: "Back",
      addEventLocation: "Add Event location",
      update: "Update",
      reset: 'Reset',
    }
  },
  eventsApproval: {
    heading: "Manage Events",
    guestList: "Guest List",
    label: {
      no: "No.",
      memberName: "Member Name",
      memberNo: "Member No.",
      eventLocation: "Event Location",
      eventname: "Event Name",
      noOfPeople: "No. Of People",
      eventDate: "Event Date",
      status: "Status",
      createdOn: "Created On",
      action: "Action",
      name: "Name",
      approval: 'Approved',
      decline: 'Decline',
      pending: 'Pending Approval',
    }
  },
  messagetemplate: {
    mainheading: "Message Template",
    visitorheading: 'Visitor Appointment',
    otp: 'Visitor (Guest/Visitor) OTP',
    visitorlogout: 'Visitor LogOut',
    hostappointment: 'Host Appointment',
    visitorcancelappointment: 'Visitor Cancel Appointment',
    hostcancelappointment: 'Host Cancel Appointment',
    visitorreschedule: 'Visitor Reschedule',
    hostreschedule: 'Host Reschedule',
    visitorarrival: 'Staff Visitor Arrival Notification',
    visitornotlogout: 'Visitor Not Logout',
    employeecredential: 'New System User',
    guestarrival: 'Guest Arrival Notification',
    eventbookingconfirmation: 'Event Booking Confirmation',
    eventcancelconfirmation: 'Event Cancellation Notification',
    notification5: '5th Visit Notification',
    notification6: '6th Guest Visit Notification',
    notification7: '7th Super Admin/Security Manager Visit Notification',
    notification6hour: 'Event Notification 6hours Prior',
    notification6admin: '6th Admin/Security Manager Visit Notification',
    guestlogout: "Guest Logout",
    btn: 'Save',
    sms: 'SMS',
    email: 'Email',
    whatsapp: 'Whatsapp',
    text: {
      heading: 'You can mention below variables to replace the real value.',
      variable: 'Variable',
      visitor: '#visitor',
      hostname: '#hostname',
      date: '#date',
      time: '#time',
      otp: '#otp',
      company: '#company',
      appoint: '#appoint',
      username: '#username',
      employee: '#employee',
      guestname: '#guestname',
      membername: '#membername',
      eventname: '#eventname',
      datetime: '#datetime',
      destination: '#destination',
      count: '#count',
      visitorname: '#visitorname',

      description: 'Description',
      descvisitor: 'This will represent the name of the visitor.',
      deschostname: 'This will represent the name of the host.',
      descdate: 'This will represent the date of an appointment.',
      desctime: 'This will represent the time of an appointment.',
      descotp: 'This will represent the One Time Pin/Password.',
      desccompany: 'This will represent the visitor’s company.',
      descappoint: 'This will represent the Appointment ID/No.',
      descusername: 'This will represent the Username of employee.',
      descemployee: 'This will represent the name of employee.',
      descguestname: 'This will represent the guestname of guest name.',
      descmembername: 'This will represent the membername of member name.',
      desceventname: 'This will represent the eventname of event name.',
      descdatetime: 'This will represent the datetime of event datetime.',
      descdestination: 'This will represent the destination of event location.',
      desccount: 'This will represent the count of visitor visit. ',
      descvisitorname: 'This will represent the visitorname of visitor/guest name.',
    }
  },
  summaryreport: {
    visitorsummaryheading: 'Visitor Summary Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    summaryreporttable: {
      department: 'Department',
      loggedin: 'Logged In',
      loggedout: 'Logged Out',
      date: 'Date',
    },
    filterform: {
      department: 'Department',
      visitortype: 'Visitor Type',
      daterange: 'Date Range',
      filterbtn: 'Filter',
    },
    // error: {
    //   departmenterror: 'Please select Department.',
    //   visitortypeerror: 'Please select Visitor Type.',
    //   dateerror: 'Please select Date Range.'
    // },
    placeholder: {
      visitortype: '---',
      date: 'dd/mm/yyyy',
      startdate: 'Start Date',
      enddate: 'End Date'
    }
  },
  visitorLogout: {
    search: {
      search: 'Search:',
      show: 'Show',
      entries: 'entries',
      showing: 'Showing',
      to: 'to',
      of: 'of',
    },
    pagination: {
      paginationBtn: 'Previous',
      paginationbtn: 'Next'
    },
  },
  visitsreport: {
    heading: 'Visits Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    visitorreporttable: {
      visitorname: 'Visitor Name',
      mobileno: 'Mobile No',
      idno: 'ID No',
      idtype: 'ID Type',
      department: 'Department',
      host: 'Host',
      accesscardno: 'Access Card No.',
      lponumber: 'LPO Number',
      vehicleregno: 'Vehicle Reg No.',
      date: 'Date',
      timein: 'Time In',
      timeout: 'Time Out',
      duration: 'Duration',
    },
    filterform: {
      department: 'Department',
      visitortype: 'Visitor Type',
      visitorstatus: 'Visitor Status',
      registerby: 'Registered By',
      vehicleregno: 'Vehicle Reg No.',
      daterange: 'Date Range',
      filterbtn: 'Filter',
      lponumber: 'LPO Number'
    },
    // error: {
    //   departmenterror: 'Please select Department.',
    //   visitortypeerror: 'Please select Visitor Type.',
    //   visitorstatuserror: 'Please select Visitor Status.',
    //   registerbyerror: 'Please select Registered By.',
    //   dateerror: 'Please select Date Range.'
    // },
    placeholder: {
      visitortype: '---',
      visitorstatus: '---',
      registerby: '---',
      date: 'dd/mm/yyyy',
    }
  },
  bookingreport: {
    heading: 'Visitor Appointment Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    bookingreporttable: {
      visitorname: 'Visitor Name',
      mobileno: 'Mobile No',
      idno: 'ID No',
      idtype: 'ID Type',
      department: 'Department',
      host: 'Host',
      accesscardno: 'Access Card No.',
      appointmentid: 'Appointment ID',
      date: 'Date',
      timein: 'Time In',
      timeout: 'Time Out',
      duration: 'Duration',
    },
    filterform: {
      department: 'Department',
      visitortype: 'Visitor Type',
      visitorstatus: 'Visitor Status',
      registerby: 'Registered By',
      daterange: 'Date Range',
      filterbtn: 'Filter',
    },
    // error: {
    //   departmenterror: 'Please select Department.',
    //   visitortypeerror: 'Please select Visitor Type.',
    //   visitorstatuserror: 'Please select Visitor Status.',
    //   registerbyerror: 'Please select Registered By.',
    //   dateerror: 'Please select Date Range.'
    // },
    placeholder: {
      visitortype: '---',
      visitorstatus: '---',
      registerby: '---',
      date: 'dd/mm/yyyy',
    }
  },

  qrcodereport: {
    heading: 'QR Code Counter Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    view: 'View',
    // eventnestedtableheading: 'Guest List',
    qrcodereporttable: {
      name: 'Name',
      contactnumber: 'Contact Number',
      idtype: 'ID Type',
      idno: 'ID No.',
      qrcode: 'QR Code',
      counter: 'Counter',
      count: "Count"
    },
    filterform: {
      idtype: 'ID Type',
      idno: 'ID No.',
      qrcode: 'QR Code',
      daterange: "Date Range"
    },
    placeholder: {
      idtype: 'ID Type',
      idno: 'ID No.',
      qrcode: 'QR Code'
    },
    qrnestedtable: {
      no: '#',
      devicename: "Device Name",
      scantime: "Scan Time"
    },
  },

  eventsreport: {
    heading: 'Events Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    viewguests: 'View Guests',
    eventnestedtableheading: 'Guest List',
    eventreporttable: {
      eventname: 'Event Name',
      membername: 'Member Name',
      memberno: 'Member No',
      eventlocation: 'Event Location',
      date: 'Date',
      starttime: 'Start Time',
      action: 'Action',
    },
    filterform: {
      eventlocation: 'Event Location',
      eventstatus: 'Event Status',
      daterange: 'Date Range',
      filterbtn: 'Filter',
    },
    // error: {
    //   eventlocationerror: 'Please select Event Location.',
    //   eventstatuserror: 'Please select Event Status.',
    //   dateerror: 'Please select Date Range.'
    // },
    placeholder: {
      eventstatus: '---',
      date: 'dd/mm/yyyy',
    },
    eventsnestedtable: {
      no: '#',
      guestname: 'Guest Name',
      guestidno: 'Guest ID No.',
      guestmobileno: 'Guest Mobile No.',
      guestemail: 'Guest Email Address',
      timein: 'Time In',
      timeout: 'Time Out',
      action: 'Action',
      status: 'Status',
      arrived: 'Arrived',
      notarrived: 'Not Arrived',
      vehicleno: "Vehicle Reg No."
    },
  },
  blacklistreport: {
    heading: 'Blacklist Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    blacklistreporttable: {
      visitorguestname: 'Visitor/Guest Name',
      visitortype: 'Visitor Type',
      mobileno: 'Mobile No.',
      idno: 'ID No.',
      idtype: 'ID Type',
      blacklistedon: 'Blacklisted On',
      blacklistedby: 'Blacklisted By',
      reason: 'Reason',
    },
    filterform: {
      blacklistedby: 'Blacklisted By',
      daterange: 'Date Range',
      filterbtn: 'Filter',
      updatebtn: 'Update'
    },
    // error: {
    //   blacklisterror: 'Please select Blacklisted By.',
    //   dateerror: 'Please select Date Range.'
    // },
    placeholder: {
      blacklist: '---',
      date: 'dd/mm/yyyy',
    },
  },
  sosreport: {
    heading: 'SOS Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    viewrecipient: 'View Recipient',
    nestedsosheading: 'Recipients List',
    sosreporttable: {
      datesent: 'Date Sent',
      noofaudience: 'No. of Audience',
      sentby: 'Sent By',
      sosmessage: 'SOS Message',
      action: 'Action',
    },
    filterform: {
      sentby: 'Sent By',
      daterange: 'Date Range',
      filterbtn: 'Filter',
      updatebtn: 'Update'
    },
    // error: {
    //   sentbyerror: 'Please select Sent By.',
    //   dateerror: 'Please select Date Range.'
    // },
    placeholder: {
      sentby: '---',
      date: 'dd/mm/yyyy',
    },
    sosnestedtable: {
      no: '#',
      name: 'Name',
      idno: 'ID No.',
      mobileno: 'Mobile No.',
      emailaddress: 'Email Address',
      timedelivered: 'Time Delivered',
      status: 'Status',
      delivered: 'Delivered',
      queued: 'Queued',
    },
  },
  bypassreport: {
    heading: 'Bypass Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    bypassreporttable: {
      name: 'Visitor Name',
      mobileno: 'Visitor Mobile No.',
      idno: 'Visitor ID No.',
      idtype: 'ID Type',
      visitorby: 'Visitor Type',
      bypassedby: 'Bypassed By',
      bypassedon: 'Bypassed On'
    },
    filterform: {
      bypassby: 'Bypassed By',
      visitortype: 'Visitor Type',
      visitoridtype: 'Visitor ID Type',
      daterange: 'Date Range',
      filterbtn: 'Filter',
    },
    // error: {
    //   bypasserror: 'Please select Sent By.',
    //   visitortypeerror: 'Please select Visitor Type.',
    //   visitoridtype: 'Please select Visitor ID Type.',
    //   dateerror: 'Please select Date Range.'
    // },
    placeholder: {
      bypassby: '---',
      visitortype: '---',
      visitoridtype: '---',
      date: 'dd/mm/yyyy',
    },
  },
  visitordetailreport: {
    heading: 'Visitor Details Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    visitordetailreporttable: {
      visitorname: 'Visitor Name',
      visitormbno: 'Visitor Mobile No.',
      visitoremail: 'Visitor Email Address',
      visitoridno: 'Visitor ID No.',
      visitoridtype: 'ID Type',
      gender: 'Gender',
      dob: 'Date of Birth',
    },
    filterform: {
      idtype: 'ID Type',
      gender: 'Gender',
      filterbtn: 'Filter',
    },
    placeholder: {
      idtype: '---',
      gender: '---',
    },
  },
  changelogreport: {
    heading: 'Change Logs Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    changelogreporttable: {
      module: 'Module',
      submodule: 'Sub-Module',
      action: 'Action',
      column: 'Column',
      oldrecord: 'Old Record(s)',
      newrecord: 'New Record(s)',
      changedby: 'Changed By',
      date: 'Date'
    },
    filterform: {
      menu: 'Menu',
      submenu: 'Submenu',
      action: 'Action',
      filterbtn: 'Filter',
    },
    placeholder: {
      submenu: '---',
      action: '---',
      noOptions: "No options.",
      selectmenuFirst: "Please select menu first."
    },
  },
  timesvisitreport: {
    heading: '6 Times Visit Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    viewdetail: 'View Details',
    timesvisitreporttable: {
      guestname: 'Guest Name',
      mobileno: 'Mobile No.',
      emailaddress: 'Email Address',
      noofvisit: 'No. of Visits',
      action: 'Action',
    },
    label: {
      noofvisit: "Number Of Visit"
    },
    filterform: {
      date: 'Year',
      filterbtn: 'Filter',
    },
    placeholder: {
      date: 'yyyy',
      noofvisit: 'Number Of Visit',
      startdate: "Start Date",
      enddate: "End Date"
    },
    nestedtimevisittable: {
      guestname: 'Guest Name',
      membername: 'Member Name',
      memberno: 'Member No.',
      location: 'Location',
      timein: 'Time In',
      timeout: 'Time Out',
      duration: 'Duration',
    },
  },
  guestsummaryreport: {
    heading: 'Guest Summary Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    guestsummaryreporttable: {
      location: 'Location',
      loggedin: 'Logged In',
      loggedout: 'Logged Out',
      date: 'Date',
    },
    filterform: {
      location: 'Location',
      date: 'Date Range',
      filterbtn: 'Filter',
    },
    placeholder: {
      date: 'dd/mm/yyyy',
    },
  },
  guestvisitreport: {
    heading: 'Guest Visit Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    modalheading: "Documents",
    guestvisitreporttable: {
      guestname: 'Guest Name',
      membername: 'Member Name',
      memberno: 'Member No.',
      location: 'Location',
      vehicleregno: 'Vehicle Reg No.',
      timein: 'Time In',
      timeout: 'Time Out',
      duration: 'Duration',
      action: 'Action'
    },
    filterform: {
      location: 'Location',
      registeredby: 'Registered By',
      vehicleregno: 'Vehicle Reg No.',
      date: 'Date Range',
      filterbtn: 'Filter',
    },
    placeholder: {
      date: 'dd/mm/yyyy',
    },
  },
  guestbookingreport: {
    heading: 'Guest Booking Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    guestbookingreporttable: {
      guestname: 'Guest Name',
      membername: 'Member Name',
      memberno: 'Member No.',
      location: 'Location',
      vehicleregno: 'Vehicle Reg No.',
      timein: 'Time In',
      timeout: 'Time Out',
      duration: 'Duration',
    },
    filterform: {
      location: 'Location',
      registeredby: 'Registered By',
      vehicleregno: 'Vehicle Reg No.',
      date: 'Date Range',
      filterbtn: 'Filter',
    },
    placeholder: {
      date: 'dd/mm/yyyy',
    },
  },
  visithoursexceededreport: {
    heading: 'Visit Hours Exceeded Report',
    exportcsvbtn: 'Export CSV',
    exportpdfbtn: 'Export PDF',
    filterheading: 'Filter',
    loggedout: 'Logged Out',
    notloggedout: 'Not Logged Out',
    visithoursexceededreporttable: {
      visitorname: 'Visitor Name',
      mobileno: 'Mobile No.',
      visitorid: 'Visitor ID',
      idtype: 'ID Type',
      accesscardno: 'Access Card No.',
      department: 'Department',
      host: 'Host',
      timein: 'Time In',
      timeout: 'Time Out',
      status: 'Status',
    },
    filterform: {
      visitoridtype: 'Visitor ID Type',
      department: 'Department',
      date: 'Date Range',
      filterbtn: 'Filter',
    },
    placeholder: {
      visitoridtype: '---',
      date: 'dd/mm/yyyy',
    },
  },
  fullguestbooking:{
    guestbooking: {
      heading: 'Full Guest Booking',
      guestbookingform: {
        countrycode: 'Country Code.',
        guestmbno: 'Guest Mobile No.',
        guestfirstname: 'Guest First Name',
        guestlastname: 'Guest Last Name',
        email: 'Email',
        identificationtype: 'Identification Type',
        identificationno: 'Identification No',
        dateofmeeting: 'Date of Meeting',
        submit: 'Submit',
        reset: 'Reset',
        dob: "Date Of Birth",
        nationality: "Nationality",
        gender: "Gender",
        destination: "Destination",
        vehicleRegistration: "Vehicle Registration No.",
        idtype: "Select ID Type",
        capturePhoto: "Capture Photo",
        frontSide: "Scan Front Side",
        backSide: "Scan Back Side",
        scanDoc: "Scan Document",
      },
      placeholder: {
        guestmbno: 'Guest Mobile No',
        guestfirstname: 'First Name',
        guestlastname: 'Last Name',
        email: 'Email',
        identificationtype: 'Select Id',
        identificationno: 'ID card number',
        gender: "Select Gender",
        nationality: "Enter Nationality",
        dob: "dd-mm-yyyy",
        destination: "Select Destination",
        vehicle: "Enter Vehicle Reg No."
      },
      error: {
        guestFirstNameReq: "Guest First Name is required.",
        guestFirstNameInvalid: "Please enter valid Guest First Name.",
        guestLastNameReq: "Guest Last Name is required.",
        guestLastNameInvalid: "Please enter valid Guest Last Name.",
        countryCodeReq: "Country Code is required.",
        guestMobileNoReq: "Guest Mobile No. is required.",
        guestMobileNoInvalid: "Please enter valid Guest Mobile No.",
        emailReq: "Email is required.",
        emailInvalid: "Please enter valid Email.",
        identificationTypeReq: "Identification Type is required.",
        identificationNoReq: "Identification No is required.",
        dateOfMeetingReq: "Date of Meeting is required.",
        dob: "Date Of Birth is required.",
        gender: "Gender is required.",
        nationality: "Nationality is required.",
        nationalityInvalid: "Please enter valid Nationality.",
        destination: "Destination is required.",
        vehicle: 'Vehicle Registration No. is required.',
        vehicleinvalid: 'Please enter valid Vehicle Registration No.',
        idType: "ID Type is required."
      }
    },
  },
  guestbooking: {
    heading: 'Partial Guest Booking',
    guestbookingform: {
      countrycode: 'Country Code.',
      guestmbno: 'Guest Mobile No.',
      guestfirstname: 'Guest First Name',
      guestlastname: 'Guest Last Name',
      email: 'Email',
      identificationtype: 'Identification Type',
      identificationno: 'Identification No',
      dateofmeeting: 'Date of Meeting',
      submit: 'Submit',
      reset: 'Reset',
      dob: "Date Of Birth",
      nationality: "Nationality",
      gender: "Gender",
      destination: "Destination",
      vehicleRegistration: "Vehicle Registration No.",
      idtype: "Select ID Type",
      capturePhoto: "Capture Photo",
      frontSide: "Scan Front Side",
      backSide: "Scan Back Side",
      scanDoc: "Scan Document",
    },
    placeholder: {
      guestmbno: 'Guest Mobile No',
      guestfirstname: 'First Name',
      guestlastname: 'Last Name',
      email: 'Email',
      identificationtype: 'Select Id',
      identificationno: 'ID card number',
      gender: "Select Gender",
      nationality: "Enter Nationality",
      dob: "dd-mm-yyyy",
      destination: "Select Destination",
      vehicle: "Enter Vehicle Reg No."
    },
    error: {
      guestFirstNameReq: "Guest First Name is required.",
      guestFirstNameInvalid: "Please enter valid Guest First Name.",
      guestLastNameReq: "Guest Last Name is required.",
      guestLastNameInvalid: "Please enter valid Guest Last Name.",
      countryCodeReq: "Country Code is required.",
      guestMobileNoReq: "Guest Mobile No. is required.",
      guestMobileNoInvalid: "Please enter valid Guest Mobile No.",
      emailReq: "Email is required.",
      emailInvalid: "Please enter valid Email.",
      identificationTypeReq: "Identification Type is required.",
      identificationNoReq: "Identification No is required.",
      dateOfMeetingReq: "Date of Meeting is required.",
      dob: "Date Of Birth is required.",
      gender: "Gender is required.",
      nationality: "Nationality is required.",
      nationalityInvalid: "Please enter valid Nationality.",
      destination: "Destination is required.",
      vehicle: 'Vehicle Registration No. is required.',
      vehicleinvalid: 'Please enter valid Vehicle Registration No.',
      idType: "ID Type is required."
    }
  },
  eventbooking: {
    heading: 'Event Booking',
    events: "Events",
    eventGuests: "Guests",
    label: {
      no: "No.",
      memberName: "Member Name",
      memberNo: "Member No.",
      eventLocation: "Event Location",
      eventname: "Event Name",
      noOfPeople: "No. Of People",
      eventDate: "Event Date",
      status: "Status",
      createdOn: "Created On",
      action: "Action",
      approval: 'Approved',
      decline: 'Decline',
      pending: 'Pending Approval',
      status: "Status",
      eventDate: "Event Date",
      arrived: "Arrived",
      notarrived: "Not Arrived"
    },
    eventbookingform: {
      membername: 'Member Name',
      memberno: 'Member No.',
      eventname: 'Event Name',
      noofguest: 'No.of Guests',
      eventlocation: 'Event Location',
      dateandtimeofevent: 'Date and Time of Event',
      submit: 'Submit',
      reset: 'Reset',
      next: 'Next',
    },
    placeholder: {
      membername: 'Member Name',
      memberno: 'Member No.',
      eventname: 'Event Name',
      noofguest: 'No. of Guests',
      eventlocation: 'Event Location',
      dateandtimeofevent: 'dd/mm/yyyy',
    },
    error: {
      dateandtimeofevent: 'Date and Time of Event is required.',
      memberNameReq: "Member Name is required.",
      memberNameInvalid: "Please enter valid Member Name.",
      memberNumberReq: "Member No. is required.",
      memberNumberInvalid: "Please enter valid Member No.",
      nameReq: "Event Name is required.",
      nameInvalid: "Please enter valid Event Name.",
      locationIdReq: "Event Location is required.",
      noGuestReq: "No.of Guests is required.",
      noGuestInvalid: "Please enter valid No.of Guests."
    },
    eventdetail: {
      no: 'No',
      guestname: 'Guest Name',
      guestidno: 'Guest ID No.',
      guestmbno: 'Guest Mobile No.',
      guestemail: 'Guest Email Address',
      vehicleno: "Vehicle Reg No.",
      status: 'Status',
    },
  },
  eventapproval: {
    heading: 'Approve Event',
    submit: 'Submit',
    reset: 'Reset',
    placeholder: 'Comment(This comment will go to the member as a notification.)',
  },
  eventGuestModal: {
    heading: 'Add Guest',
    label: {
      guestname: 'Guest Name',
      guestno: 'Guest Number',
      guestemail: 'Guest Email'
    },
    placeholder: {
      guestname: 'Enter Guest Name',
      guestno: 'Enter Guest Number',
      guestemail: 'Enter Guest Email'
    },
    error: {
      guestname: 'Guest Name is required.',
      guestno: 'Guest Number is required.',
      guestemail: 'Guest Email is required.',
      guestnameInvalid: 'Please enter valid Guest Name.',
      guestnoInvalid: 'Please enter valid Guest Number.',
      guestemailInvalid: 'Please enter valid Guest Email.'
    }
  },
  dashboard: {
    welcomeheading: 'Welcome to',
    secuvizheading: 'SecuViz',
    systemheading: 'System Overview',
    exportpng: 'Export JPG',
    exportjpg: 'Export PNG',
  },
  guestRegistration: {
    guest7timearrivebypass:{
      heading:"Guest ByPass",
      label:{
        resendOtp:"Resend OTP",
        sentOtp:"Send OTP",
        phone:"Mobile No.",
         otp:"Enter OTP",
         cancel:"Cancel",
         bypass:"ByPass",
         addbypass:"Add & ByPass"
      },
      placeholder:{
       phone:"Enter Mobile No.",
       otp:"Enter OTP"
      },
      error:{
        phone:"Mobile No. is required.",
        phoneNoInvalid:"Please enter valid Mobile No.",
        otp:"OTP is required.",
        otpinvalid:"Please enter valid OTP"
      }
    },
    editGuestModal: {
      heading: "Edit Guest Details",
      cancel: "Cancel",
      update: "Update"
    },
    memberVerification: {
      title: "Member's Verification",
      memberSummary: "Member's Summary",
      guestssummary: "Guests Summary",
      success: "Member verified successfully.",
      label: {
        numberName: "Membership number/name",
        name: "Member Name",
        number: "Member Number",
        membersProfile: "Member's Profile",
        guests: "Number Of Guests",
        memberstatus: "Member Status"
      },
      placeholder: {
        numberName: "Enter Membership name/number"
      },
      error: {
        memberShipNameNumberReq: "Membership number/name is required.",
        memberShipNameNumberInvalid: "Please enter valid Membership number/name."
      },
      verify: "Verify"
    },
    guestVerification: {
      title: "Guest's Verification",
      newEntry: "Create New Entry",
      guestInfo: "Guest Information",
      or: "OR",
      label: {
        docno:"Repeat Visitor : Document No.",
        waNumber: "Guests WhatsApp Number",
        idType: "Select ID Type",
        docId: "Document ID Number",
        nationality: "Nationality",
        capturePhoto: "Capture Photo",
        frontSide: "Scan Front Side",
        backSide: "Scan Back Side",
        scanDoc: "Scan Document",
        name: "Guest Full Name",
        dob: "Date Of Birth",
        nationality: "Nationality",
        gender: "Gender",
        destination: "Destination",
        vehicleRegistration: "Vehicle Registration No.",
        accessCard: "Access Card No.",
        otp: "OTP",
        sentOtp: "Send OTP",
        resentOtp: "Resend OTP",
        resendOtp: 'Resend OTP',
        verifyAccessCard: "Verify Card",
        addGuest: "Add Guest",
        submit: "Submit"
      },
      placeholder: {
        waNumber: "Enter WhatsApp Number",
        email: "Enter E-mail",
        dob: "dd-mm-yyyy",
        selectdestination: "Select Destination",
       
      },
      error: {
        emailReq: "Please enter visitors Mobile No. or their email or Visitor Document No., either field is required",
        idType: "ID Type is required.",
        guestContactNoReq: "Guest's WhatsApp Number or E-mail or Visitor Document No. is required.",
        phoneNoInvalid: "Please enter valid visitor's mobile number.",
        emailInvalid: "Please enter valid visitor's email.",
        docId: "Document ID Number is required.",
        docIdInvalid: "Please enter valid Document Id Number.",
        nationality: "Nationality is required.",
        nationalityInvalid: "Please enter valid Nationality.",
        name: "Guest Full Name is required.",
        validName: "Please enter valid Full Name.",
        dob: "Date Of Birth is required.",
        gender: "Gender is required.",
        nationality: "Nationality is required.",
        destination: "Destination is required.",
        otp: "OTP is required.",
        validOtp: "Please enter valid OTP.",
        accesscard: "Access Card is required.",
        accesscardinvalid: 'Please enter valid Access Card No.',
        vehicle: 'Vehicle Registration No. is required.',
        vehicleinvalid: 'Please enter valid Vehicle Registration No.',
        docno:"Please enter Document No.",
        docnoinvalid:"Please enter Valid Document No.",
      }
    },
    guestDetails: {
      title: "Guest Details",
      label: {
        no: "No",
        name: "Guest Name",
        number: "Guest Mobile No.",
        guestemailaddress: "Guest Email Address",
        guestidentificationno: "Guest Identification No.",
        action: "Action"
      }
    }
  },
  commondocerror: {
    frontdocerror: "Front Doc is required.",
    backdocerror: "Back Doc is required.",
  },
  visitorRegistration: {
    headingVerification: 'Visitor Verification',
    repeatheadingVerification:"Repeat Visitor",
    headingCreateVisitor: 'Create New Entry',
    headingVisitorInformation: 'Visitor Information',
    forOfficePurpose: 'For Office Purpose',
    label: {
      visitorPhoneNo: 'Visitor Mobile No.',
      sendOtpBtn: 'Send OTP',
      bypass: 'By Pass',
      capturePhoto: 'Capture Photo',
      scanfrontDoc: 'Scan Front Document',
      scanbackDoc: 'Scan Back Document',
      scanDoc: "Scan Document",
      nationalId: 'National ID',
      alienId: 'Alien ID',
      passport: 'Passport',
      drivingLicense: 'Driving License',
      companyId: 'Company ID',
      other: 'Other Documents',
      docIdNumber: 'Document ID No.',
      nationality: 'Nationality',
      fullName: 'Full Name',
      dob: 'Date Of Birth',
      gender: 'Gender',
      visitorType: 'Visitor Type',
      lpoNumber: "LPO Number",
      companyFrom: "Company From",
      visitorFrom: 'Visitor From',
      tower: 'Tower',
      company: 'Company',
      department: 'Department',
      employeeName: "Employee Name",
      host: 'Host',
      purpose: 'Purpose',
      accessCardNo: 'Access Card Number',
      numberOfPax: 'Number of PAX',
      vrn: 'Vehicle Registration Number',
      assetDetails: 'Asset Details',
      addAssetBtn: 'Add Assets',
      typeOfAssets: 'Type Of Assets',
      make: 'Make',
      serialNo: 'Serial No.',
      remark: 'Remarks',
      verifyCard: 'Verify Card',
      bioMetric: 'Biometric',
      facialRecognition: 'Facial Recognition',
      save: 'Save',
      submit: 'Submit',
      reset: 'Reset',
      add: 'Add',
      edit: 'Edit',
      cancel: 'Cancel',
      emSave: 'Save',
      addAsset: 'Add Asset',
      editAsset: 'Edit Asset',
      enterOTP: 'Enter OTP',
      otp: 'OTP',
      byPass: 'By Pass',
      bpManagerLogin: 'Manager Login',
      bpEmail: 'Email',
      bpPassword: 'Password',
      bpClose: 'Close',
      bpLogin: 'Login',
      update: 'Update',
      visitordocno:"Visitor Document No."
    },
    assetTableLabel: {
      typeOfAsset: 'Type Of Assets',
      make: 'Make',
      serialNo: 'Serial Number',
      remark: 'Remark',
      action: 'Action'
    },
    placeholder: {
      dob: 'dd-mm-yyyy',
      gender: '',
      visitorType: 'Select visitor Type',
      tower: '',
      company: '',
      department: 'Select Department',
      whatsAppNo: "Enter Mobile No.",
      docno:"Enter Document No.",
      email: "Enter E-mail",
      employeeName: "",
      host: '',
      purpose: '',
      accessCardNo: '',
      numberOfPax: '',
      vrn: '',
      assetDetails: '',
      typeOfAssets: '',
      make: '',
      serialNo: '',
      remark: '',
      selectdestination: 'Select Destination',
      employeename: "Select Employee Name"
    },
    error: {
      emailReq: "Please enter visitors Mobile No. or their email, either field is required",
      lpoWarning: "if the visitor type for Supplier is selected another field for 'LPO Number' should render",
      lpoNumberReq: "LPO Number is required.",
      selectidtype: 'Please select ID Type',
      docno:"Visitor Document No. is required.",
      docnoinvalid:"Please enter Valid Document No.",
      phoneNoReq: "Visitor's mobile number is required.",
      phoneNoInvalid: "Please enter valid visitor's mobile number.",
      emailInvalid: "Please enter valid visitor's Email.",
      identityTypeReq: 'Please enter Mobile No.',
      documentIdNumber: 'Document ID No. is required.',
      documentIdNumberInvalid: "Please enter valid Document Id Number.",
      nationality: 'Nationality is required.',
      nationalityInvalid: "Please enter valid Nationality.",
      fullNameReq: 'Full Name is required.',
      fullNameInvalid: 'Please enter valid Full Name.',
      birthDateReq: 'Date Of Birth is required.',
      genderReq: "Select Gender.",
      visitorTypeReq: 'Select Visitor Type.',
      visitorFromReq: 'Visitor From is required.',
      visitorFromInvalid: 'Please enter valid Visitor From.',
      employeeNameReq: "Select Employee Name.",
      employeeNameInvalid: "Please enter valid Employee Name.",
      companyFromReq: "Company From is required.",
      companyFromInvalid: "Please enter valid Company From.",
      towerReq: 'Select tower name.',
      selectCompanyMsg: 'Please select tower first.',
      dropdownError: 'No options',
      companyReq: 'Select company name.',
      selectDepartmentMsg: 'Please select company first.',
      selectHostError: 'Please select company and department first.',
      departmentReq: 'Select Department.',
      hostReq: 'Select host name.',
      purposeReq: 'Purpose is required.',
      accessCardNoReq: 'Access Card Number is required.',
      accessCardNoInvalid: "Please enter valid Access Card Number.",
      numberOfPaxReq: 'Number of pax is required.',
      vrnReq: 'Vehicle registration number is required.',
      assetTypeReq: 'Select asset type.',
      makeReq: 'Make is required.',
      serialNoReq: 'Serial number is required.',
      remarkReq: 'Remark is required.',
      noAssetsAdded: 'No asset added.',
      enterOTP: 'OTP is required.',
      otpPattern: 'Please enter valid OTP.',
      bpEmail: 'Email is required.',
      bpEmailPattern: 'Please enter valid email.',
      bpPassword: 'Password is required.',
      bpPasswordPattern: 'Please enter valid password.',
      destination: 'Destination is required.',
      noOptions: "No options",
      selectDept: "Please select department first"
    },
    sweetAlert: {
      confirmationTitle: 'Are you sure?',
      confirmationText: "You won't be able to revert this.",
      confirmationBtnLabel: 'Yes, delete it.',
      simpleTitle: 'Deleted.',
      simpleText: 'Asset has been deleted.',
      bpconfirmationTitle: 'Are you sure you want to bypass?',
      bpBtnYes: 'Yes',
      bpBtnNo: 'No'
    }
  },
  visitorLogout: {
    heading: {
      visitorLog: 'Visitor Log'
    },
    tableHead: {
      sNo: 'No',
      visitorName: 'Visitor Name',
      accessCardNo: 'Access Card No',
      visitorType: 'Visitor Type',
      timeIn: 'Time In',
      timeOut: 'Time Out'
    },
    btnLabel: {
      logout: 'Logout'
    },
    label: {
      totalIn: 'Total In',
      totalOut: 'Total Out',
      todaysVisitor: "Today's Total visitors"
    },
    search: {
      search: 'Search:',
      show: 'Show',
      entries: 'entries',
      showing: 'Showing',
      to: 'to',
      of: 'of',
    },
    pagination: {
      paginationBtn: 'Previous',
      paginationbtn: 'Next'
    },
  },
  event: {
    tableheading: 'Events',
    id: 'ID',
    dateandtime: 'Date and Time',
    eventname: 'Event Name',
    member: 'Member',
    destination: 'Destination',
    action: 'Action',
    vehheading: 'Add Vehicle Registration No.',
    label: {
      vehicleno: 'Vehicle Reg No.',
    },
    error: {
      vehicleno: "Vehicle Reg No. is required.",
      vehiclenoinvalid: "Please enter valid Vehicle Reg No."
    },
    placeholder: {
      vehicleno: 'Vehicle Reg No.'
    }
  },
  bookings: {
    tableheading: "Today's Booking's",
    no: 'No',
    guestname: 'Guest Name',
    invitedby: 'Invited By',
    bookingtime: 'Booking Time',
    action: 'Action',
    btnregister: 'Register',
    bookingsmodal: {
      modalheading: 'Guest Verification',
      destination: 'Destination',
      accesscardno: 'Access Card No.',
      otp: 'OTP',
      submitbtn: 'Submit',
      accessfield: 'Enter Access Card No.',
      otpfield: 'Enter OTP'
    },
    registerguestbooking: {
      registerguestbookingheading: 'Register Guest Booking'
    },
    label: {
      idtype: 'Select ID Type',
      capturePhoto: 'Capture Photo',
      scanfrontDoc: 'Scan Front Document',
      scanbackDoc: 'Scan Back Document',
      scanDoc: "Scan Document",
      submit: 'Submit',
      reset: 'Reset',
      otp: 'OTP',
      destination: 'Destination',
      gender: 'Gender',
      dob: 'Date Of Birth',
      fullname: 'Full Name',
      nationality: 'Nationality',
      docidnumber: 'Document ID Number',
    },
    error: {
      accesserrorrequired: "Access Card No. is required.",
      accesserrorpattern: "Please enter valid Access Card No.",
      doctypeerror: 'Please select ID Type.',
      otprequired: "OTP is required.",
      otppattern: "Please enter valid OTP.",
      documentIdNumber: 'Document ID Number is required.',
      documentIdNumberInvalid: "Please enter valid Document Id Number.",
      nationality: 'Nationality is required.',
      nationalityInvalid: "Please enter valid Nationality.",
      fullNameReq: 'Full Name is required.',
      fullNameInvalid: 'Please enter valid Full Name.',
      birthDateReq: 'Date Of Birth is required.',
      genderReq: "Please select Gender.",
      destination: 'Please select Destination.'
    },
    placeholder: {
      selectdestination: 'Select Destination'
    }
  },
  nestedtable: {
    searchbox: 'Search by name',
    tableheading: "Guest List",
    no: "No",
    name: "Name",
    timein: "Time In",
    action: "Action",
    arrived: "Arrived",
    markarrived: "Mark Arrived",
    vehicleno: "Vehicle Registraion"
  },
  visitorappointments: {
    tableheading: "Today's Appointments",
    no: 'No',
    visitorname: 'Visitor Name',
    hostname: 'Host Name',
    appointmentdate: 'Appointment Date',
    appointmenttime: 'Appointment Time',
    action: 'Action'
  }
};
