import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OutsideClickHandler from 'react-outside-click-handler';
import allCountryCodes from 'components/configuration/country-code';
import { defaultCountryCode } from 'components/configuration/country-code';
// import '../../assets/css/custom-dropdown.css';
import _ from 'lodash';


export default function CountryCode({
    countryCode,
    setCountryCode,
    error,
    direction
}) {
    const [dropdownStatus, setDropdownStatus] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [countryCodes, setCountryCodes] = useState();
    useEffect(() => {
        setCountryCodes(allCountryCodes);
        setCountryCode({
            name: "Kenya",
            code: "KE",
            dial_code: "+254"
        })
    }, []);
    useEffect(() => {
        setCountryCodes(
            _.filter(allCountryCodes, country => {
                if (
                    country.dial_code.toString().includes(searchQuery.toLowerCase()) ||
                    country.name.toLowerCase().includes(searchQuery.toLowerCase())
                ) {
                    return true;
                }
            })
        );
    }, [searchQuery]);
    const changeCountry = data => {
        setCountryCode(data);
        setSearchQuery('');
        setDropdownStatus(false);
    };
    const handleOutsideClick = () => {
        setSearchQuery('');
        setDropdownStatus(false);
    };
    return (
        <div className="col-12" role="button">
            <div
                className="form-control w-100 d-flex align-items-center form-control py-0 px-2"
                onClick={() => setDropdownStatus(!dropdownStatus)}
            >
                <div
                    className="d-flex align-items- w-100 justify-content-between"
                    title={`${countryCode.name} : ${countryCode.dial_code}`}
                >
                    <div className="d-flex align-items-center">
                        <img className="me-1 flag-image" src={`${process.env.REACT_APP_BASEROUTE_URL}/admin-images/country-flag/4x3/${countryCode?.code?.toLowerCase()}.svg`} />
                        <span className="me-1">{countryCode.dial_code}</span>
                    </div>
                    <div className='d-flex align-items-center'>
                        <FontAwesomeIcon
                            icon={`${dropdownStatus ? 'caret-up' : 'caret-down'}`}
                            className="ms-1"
                        />
                    </div>
                </div>
            </div>

            {dropdownStatus && (
                <OutsideClickHandler onOutsideClick={() => handleOutsideClick()}>
                    <div
                        className={`dropdown-options position-absolute p-2 ${direction === 'top'
                            ? 'bottom-100'
                            : direction === 'bottom' && ''
                            }`}
                    >
                        <input
                            className="form-control search-bar"
                            type="text"
                            placeholder="Search country"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                        />
                        {countryCodes.length > 0 ? (
                            countryCodes.map((country, index) => (
                                <div
                                    key={index}
                                    onClick={() => changeCountry(country)}
                                    className="country-name"
                                    role="button"
                                >
                                    <img className="me-1 flag-image" src={`${process.env.REACT_APP_BASEROUTE_URL}/admin-images/country-flag/4x3/${country.code.toLowerCase()}.svg`} />
                                    <span className="me-1">{country.name}</span>
                                    <span className="me-4">{country.dial_code}</span>
                                </div>
                            ))
                        ) : (
                            <div className="text-center">
                                <h5 className="text-danger">Data not found.</h5>
                            </div>
                        )}
                    </div>
                </OutsideClickHandler>
            )}
        </div>

    )
}
