import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import Footer from 'components/footer/Footer';
import classNames from 'classnames';
import NavbarExternal from 'components/navbar/vertical/NavbarExternal';

const UiLayout = () => {
    const { hash, pathname } = useLocation();

    const {
        config: { isFluid, navbarPosition }
    } = useContext(AppContext);

    useEffect(() => {
        setTimeout(() => {
            if (hash) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
            }
        }, 0);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className={isFluid ? 'container-fluid ' : 'container'} style={{ padding: "0rem 2rem 0rem 0rem" }}>
            {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
                <NavbarExternal />
            )}
            <div className={classNames('content')}>
                <NavbarTop />
                {/*------ Main Routes ------*/}
                <Outlet />
                <Footer />
            </div>
        </div>
    );
};

export default UiLayout;
