import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

export default function ReactDatePicker({
  value,
  onChange,
  hasErrors,
  placeholder,
  disabled,
  dateFormat,
  showMonthDropdown,
  showYearDropdown,
  minDate,
  maxDate,
  startDate,
  endDate,
  isClearable,
  selectsRange,
  formFieldsDisabled,
  portalId = "root-portal"
}) {
  const isDisabled = formFieldsDisabled;
 

  return (
    <div className="position-relative">
      <DatePicker
        value={value}
        selected={value}
        onChange={e => onChange(e)}
        formatWeekDay={day => day.slice(0, 3)}
        dateFormat={dateFormat}
        className={`form-control col-12 ${hasErrors && 'is-invalid'}`}
        placeholderText={placeholder}
        disabled={isDisabled}
        onKeyDown={e => {
          e.preventDefault();
        }}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        peekNextMonth
        dropdownMode="select"
        minDate={minDate}
        maxDate={maxDate}
        portalId={portalId}
        selectsRange={selectsRange}
        startDate={startDate}
        endDate={endDate}
        isClearable={isClearable}
      />
      <span className={`form-icon ${hasErrors && 'me-4'}`}>
        <FontAwesomeIcon icon="calendar-alt" />
      </span>
    </div>

  );
}
ReactDatePicker.defaultProps = {
  disabled: false,
  dateFormat: 'MM/dd/yyyy',
  hasErrors: false,
  showMonthDropdown: true,
  showYearDropdown: true,
};
