import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import formPattern from 'components/configuration/form-pattern';
import InputPassword from 'components/common/InputPassword';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactTimePicker from 'components/common/ReactTimePicker';
import ReactDatePicker from 'components/common/ReactDatePicker';
import CountryCode from 'components/common/CountryCode';
import { useState } from 'react';
import ReactTable from 'components/common/ReactTable';
import { AuthWizardContext, eventBooking, loaderContext } from 'context/Context';
import ReactDateAndTimePicker from 'components/common/ReactDateAndTimePicker';
import moment from 'moment';
import { getExternalEventById } from 'services/bookingService';
import { Modal, Button, Form, } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { guestdelete } from 'services/manageEventsService'
import Swalcolor from 'components/configuration/sweetalert-config';
import Swal from 'sweetalert2';
import EventDetailAddModal from './EventDetailAddModal';




function EventDetails({ register, errors, setValue, handleSubmit, reset, control, Controller }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const { locationList } = useContext(eventBooking)
    const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
    const { user, setUser, step, setStep, } = useContext(AuthWizardContext);
    const [countryCode, setCountryCode] = useState({});
    // const [eventdetailData, setEventDetailData] = useState([{ no: '1', guestname: 'john doe', guestidno: '009', guestmobileno: '---', guestemail: 'john@45.com', status: 'Not Arrived' }, { no: '2', guestname: 'john doe', guestidno: '009', guestmobileno: '+232 3454 434 345', guestemail: '---', status: ' Arrived' }]);
    const [eventdetailData, setEventDetailData] = useState([]);
    const [id, setId] = useState([])
    // const {
    //     register,
    //     handleSubmit,
    //     reset,
    //     formState: { errors },
    //     control,
    //     setValue,
    //     clearErrors,
    //     setError,
    //     watch,
    // } = useForm();



    useEffect(async () => {
        if (step === 3) {
            setIsLoading(true)
            try {
                const { data } = await getExternalEventById({ event_id: user?.eventData?.event_id })
                setId(user?.eventData?.event_id)
                setValue("member_name", data?.data[0]?.event_details?.member_name)
                setValue("member_number", data?.data[0]?.event_details?.member_number)
                setValue("name", data?.data[0]?.event_details?.name)
                setValue("no_of_guest", data?.data[0]?.event_details?.guest_no)
                setValue("location_id", locationList.find((event) => event?.value == data?.data[0]?.event_details?.location_id)?.label)
                setValue("date", moment(data?.data[0]?.event_details?.date).toDate())
                setEventDetailData(data?.data[0]?.event_details?.guest_list)
            } catch (error) {
                errorMessage(error)
            }
            setIsLoading(false)
        }
    }, [step])

    console.log("-------------user", user.members)


    const columns = [

        {
            Header: t('eventbooking.eventdetail.no'),
            accessor: (row, index) => index + 1
        },
        {
            Header: t('eventbooking.eventdetail.guestname'),
            accessor: 'name',
            Cell: ({ row }) => <div style={{ color: row?.original?.status == 1 ? "var(--warning)" : "" }}>{row?.original?.name}</div>
        },
        {
            Header: t('eventbooking.eventdetail.guestmbno'),
            Cell: ({ row }) => row.original.phone && row.original.country_code ? `${row.original.country_code} ${row.original.phone}` : "--"
        },
        {
            Header: t('eventbooking.eventdetail.guestemail'),
            accessor: 'email'
        }, {
            Header: t('eventbooking.label.status'),
            accessor: 'status',
            Cell: ({ row }) => {
                return (
                    <div>{`${row.original.status === 0 ? t('eventbooking.label.notarrived') : t('eventbooking.label.arrived')}`}</div>
                );
            }
        },
        {
            Header: t('eventsApproval.label.action'),
            Cell: ({ row }) => (
                <div>
                    <span
                        className="btn p-0 me-2"
                        title="Delete"
                        onClick={() => DeleteGuest(row.original.event_guest_id, row.original.event_id)}
                    >
                        <FontAwesomeIcon icon="trash-alt" />
                    </span>
                </div>
            )
        }
    ];

    const handleOpen = () => {
        console.log("handleopen")
        setIsApprovalModalOpen(true)
    }


    // const onSubmit = (data) => {
    //     console.log(data)
    // }
    const DeleteGuest = async (id, eventId) => {
        Swal.fire({
            title: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationTitle'
            ),
            text: t('departmentMaster.deleteDepartment.sweetAlert.confirmationText'),
            icon: 'warning',
            showCancelButton: Swalcolor.confirmation.showCancelButton,
            confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
            cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
            confirmButtonText: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationBtnLabel'
            )
        }).then(async result => {
            if (result.isConfirmed) {
                setIsLoading(true)
                try {
                    setEventDetailData(eventdetailData?.filter((event) => event.event_guest_id !== id))
                    const { data } = await guestdelete({ event_id: eventId, event_guest_id: id })
                    toast.success(data.message, toastConfig)
                    Swal.fire({
                        icon: 'success',
                        title: t(
                            'departmentMaster.deleteDepartment.sweetAlert.simpleTitle'
                        ),
                        text: data.data.result,
                        confirmButtonColor: Swalcolor.simple.confirmButtonColor
                    });
                } catch (e) {
                    errorMessage(e)
                }
                setIsLoading(false)
            }
        });

    }
    // const handleReset = () => {
    //     reset();
    // }

    console.log("event", eventdetailData)
    return (
        <>
            {/* <div className="mb-3 row g-3">
                <div className="card overflow-hidden mb-3">
                    <div className="card-header"> */}
            <div className="row">
                {/* <div className="col-12">
                                <h3 className="heading-color fw-600">
                                    {t('eventbooking.heading')}
                                </h3>
                            </div>
                            <div className="col-12 mb-3">
                                <hr />
                            </div> */}
                <form autoComplete="off">
                    <div className='row'>
                        <div className="form-group col-md-3 mb-3">
                            <label className="form-label">
                                {t('eventbooking.eventbookingform.membername')}
                            </label>
                            <input
                                type='text'
                                className="form-control"
                                disabled
                                placeholder={t('eventbooking.placeholder.membername')}
                                {...register('member_name')}
                            />
                            {/* {errors.guestFirstName && (
                                            <small>
                                                {errors.guestFirstName.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('guestbooking.error.guestFirstNameReq')}
                                                    </span>
                                                )}
                                                {errors.guestFirstName.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('guestbooking.error.guestFirstNameInvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )} */}
                        </div>
                        <div className="form-group col-md-3 mb-3">
                            <label className="form-label">
                                {t('eventbooking.eventbookingform.memberno')}

                            </label>
                            <input
                                type='text'
                                className="form-control"
                                disabled
                                placeholder={t('eventbooking.placeholder.memberno')}
                                {...register('member_number')}
                            />
                            {/* {errors.guestLastName && (
                                            <small>
                                                {errors.guestLastName.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('guestbooking.error.guestLastNameReq')}
                                                    </span>
                                                )}
                                                {errors.guestLastName.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('guestbooking.error.guestLastNameInvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )} */}
                        </div>

                        <div className="form-group col-md-3">
                            <label className="form-label">
                                {t('eventbooking.eventbookingform.eventname')}
                                {/* <span className="text-danger ms-1">*</span> */}
                            </label>
                            <input
                                type='text'
                                className="form-control"
                                disabled
                                placeholder={t('eventbooking.placeholder.eventname')}
                                {...register('name')}
                            />
                            {/* {errors.email && (
                                            <small>
                                                {errors.email.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('bookGuest.error.emailInvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )} */}
                        </div>
                        <div className="form-group col-md-3">
                            <label className="form-label">
                                {t('eventbooking.eventbookingform.noofguest')}
                                {/* <span className="text-danger ms-1">*</span> */}
                            </label>
                            <input
                                type='text'
                                className="form-control"
                                disabled
                                placeholder={t('eventbooking.placeholder.noofguest')}
                                {...register('no_of_guest')}
                            />
                            {/* {errors.email && (
                                            <small>
                                                {errors.email.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('bookGuest.error.emailInvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )} */}
                        </div>
                        <div className="form-group col-md-3 mb-3">
                            <label className="form-label">
                                {t('eventbooking.eventbookingform.eventlocation')}
                                {/* <span className="text-danger ms-1">*</span> */}
                            </label>
                            {/* <Controller
                                name="location_id"
                                control={control}
                                // rules={{ required: true }}
                                render={({ field: { value, onChange } }) => {
                                    return (
                                        <SearchableDropdown
                                            value={value}
                                            onChange={onChange}
                                            options={locationList}
                                            placeholder={t(
                                                'createEvent.placeholder.eventLocation'
                                            )}
                                            disabled={true}
                                            isClearable={true}
                                        />
                                    )
                                }}
                            /> */}
                            <input
                                type='text'
                                className="form-control"
                                disabled
                                placeholder={t('createEvent.placeholder.eventLocation')}
                                {...register('location_id')}
                            />
                            {/* {errors.identificationNo && (
                                            <small className="text-danger">
                                                {errors.identificationNo.type === 'required' && (
                                                    <span>{t('bookGuest.error.identificationNoReq')}</span>
                                                )}
                                            </small>
                                        )} */}
                        </div>
                        <div className="form-group col-md-3 mb-3">
                            <label className="form-label">
                                {t('eventbooking.eventbookingform.dateandtimeofevent')}
                                <span className="text-danger ms-1">*</span>
                            </label>
                            <Controller
                                name="date"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { value, onChange } }) => (
                                    <ReactDateAndTimePicker
                                        value={value}
                                        onChange={onChange}
                                        hasErrors={errors.date}
                                        placeholder={t("eventbooking.placeholder.dateandtimeofevent")}
                                        dateFormat="dd/MM/yyyy hh:mm aa"
                                        minDate={moment().toDate()}
                                        disabled={true}
                                    />
                                )}
                            />
                            {/* <input
                                className={`form-control ${errors.dateandtimeofevent ? 'is-invalid' : ''
                                    }`}
                                type="text"
                                placeholder={t('eventbooking.placeholder.dateandtimeofevent')}
                                {...register('dateandtimeofevent', {
                                    required: true
                                })}
                            /> */}
                            {errors.date && (
                                <small className="text-danger">
                                    {errors.date.type === 'required' && (
                                        <span>{t('eventbooking.error.dateandtimeofevent')}</span>
                                    )}
                                </small>
                            )}
                        </div>

                    </div>
                </form>
                {/* <div className="mb-3 mt-3 text-end">
                                    <button type="submit" className="btn btn-primary me-1">
                                        {t('eventbooking.eventbookingform.submit')}
                                    </button>
                                    <button
                                        type="reset"
                                        className="btn btn-danger"
                                        onClick={onReset}
                                    >
                                        {t('eventbooking.eventbookingform.reset')}
                                    </button>
                                </div> */}







                <div className="pt-4">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="heading-color fw-600 mb-0 ps-0">
                                {t('eventbooking.eventGuests')}
                            </h3>
                        </div>
                        <div className="col-12" >
                            <hr />
                        </div>
                    </div>
                    <ReactTable
                        columns={columns.filter((col) => { return (col.Header == "Action" && user?.eventData?.event_notEdit) ? false : true })}
                        data={eventdetailData ? eventdetailData : []}
                        pageSizeOption={[5, 10, 15, 20]}
                        headerClass="btn-primary"
                        isSearchBoxVisible
                        ispaginationvisible={true}
                        component={
                            user?.eventData?.event_notEdit ? null : <>
                                <div className="col-4 table-btn" style={{ marginLeft: "34rem", marginTop: "-.7rem" }}>
                                    <button
                                        className="btn btn-primary btn-sm float-end"
                                        type='button'
                                        onClick={() => handleOpen()}
                                    >
                                        <span className="ms-1 d-sm-inline d-none">
                                            {t('eventLocation.btnLabel.add')}
                                        </span>
                                    </button>
                                </div>
                            </>
                        }
                    />
                </div>

            </div>


            <EventDetailAddModal
                isModalVisible={isApprovalModalOpen}
                setIsModalVisible={setIsApprovalModalOpen}
                event={eventdetailData}
                setevent={setEventDetailData}
                eventid={id}
                handleSubmit={handleSubmit}
                reset={reset}
                register={register}
                errors={errors}
                setValue={setValue}
            />
            {/* </div>
                </div>
            </div> */}
        </>
    )
}

export default EventDetails