import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import ReactTable from 'components/common/ReactTable';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';
import Swalcolor from 'components/configuration/sweetalert-config';
import { encodeData } from 'components/common/encodeParams';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { departmentlist, departmentdelete } from 'services/masterModuleService'
import SearchableDropdown from 'components/common/SearchableDropdown';
import { useLocation } from 'react-router';
import { accessmappingupdate, accessmappingdropdownlist } from 'services/accessMappingService'
import { getlocation } from 'services/manageEventsService'
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import pattern from 'components/configuration/form-pattern';
import { getListOfDepartment } from 'services/adminmoduleService'

export default function EditAccessMapping() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation()
    const { isLoading, setIsLoading } = useContext(loaderContext)

    const [showLocation, setShowLocation] = useState(false);
    const [showDepartment, setShowDepartment] = useState(false);
    const [hostType, setHostType] = useState([])
    const [locations, setLocations] = useState([])
    const [department, setDepartment] = useState([])
    const [accessControl, setAccessControl] = useState([])
    const [filterAccessControl, setFilterAccessControl] = useState([]);
    const [isDeptAndLocReq, setIsDeptAndLocReq] = useState(true)

    console.log("accesslocation", location)


    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        clearErrors,
        setError,
        watch,
        formState: { errors }
    } = useForm();

    const selectedHost = watch("host_type");
    const accessControlData = watch("access_control");
    useEffect(() => {
        if (accessControlData) {
            console.log("Access COntrol Data", accessControlData)
            const allAccessControl = accessControlData.map((access) => access?.doorType)
            if (allAccessControl.includes("Encoder")) {
                setFilterAccessControl(accessControl.filter((access) => access.doorType == "Encoder"))
                setIsDeptAndLocReq(false)
                clearErrors("department_id")
                clearErrors("location_id")
            } else if (allAccessControl.includes("Entry") || allAccessControl.includes("Exit") || allAccessControl.includes("Normal")) {
                setFilterAccessControl(accessControl.filter((access) => access.doorType != "Encoder"))
                setIsDeptAndLocReq(true)
            } else {
                setFilterAccessControl(accessControl)
                setIsDeptAndLocReq(true)
            }
        }
    }, [accessControlData])
    useEffect(async () => {
        checkModulePermission("Access Mapping")
        setIsLoading(true)
        try {
            const { data } = await accessmappingdropdownlist();
            const { data: department } = await getListOfDepartment()
            const { data: locationData } = await getlocation()
            const findHostType = data?.data[0]?.host_type?.find((hos) => hos.id == location?.state?.host_type)
            const findDepartment = department?.data?.find((dep) => dep.id == location?.state?.department_id)
            const findlocation = locationData?.data?.find((loc) => loc.id == location?.state?.location_id)
            const findAccessControl = data?.data[0]?.door_list?.filter((acc) => location?.state?.accesscontrol.split(",").includes(acc.door_name)).map((dor) => ({ label: dor.door_name, value: dor.biostar_door_id, doorType: dor.door_type }))
            console.log(findAccessControl, findHostType, findDepartment, findlocation)
            setValue("name", location?.state?.name)
            setValue("host_type", { label: findHostType?.name, value: findHostType?.id })
            setValue("department_id", { label: findDepartment?.name, value: findDepartment?.id })
            setValue("location_id", { label: findlocation?.name, value: findlocation?.id })
            setValue("access_control", findAccessControl)
            setLocations(locationData?.data?.map((loc) => ({ label: loc.name, value: loc.id })))
            setDepartment(department?.data?.map((dep) => ({ label: dep.name, value: dep.id })))
            setHostType(data?.data[0]?.host_type?.map((hos) => ({ label: hos.name, value: hos.id })))
            const allAccessControlList = data?.data[0]?.door_list?.map((dor) => ({ label: dor.door_name, value: dor.biostar_door_id, doorType: dor.door_type }))
            setAccessControl(allAccessControlList)
            const allAccessControl = findAccessControl.map((access) => access?.doorType)
            if (allAccessControl.includes("Encoder")) {
                setFilterAccessControl(allAccessControlList.filter((access) => access.doorType == "Encoder"))
            } else if (allAccessControl.includes("Entry") || allAccessControl.includes("Exit") || allAccessControl.includes("Normal")) {
                setFilterAccessControl(allAccessControlList.filter((access) => access.doorType != "Encoder"))
            }
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, []);

    useEffect(() => {
        if (selectedHost?.label == 'Staff') {
            setShowDepartment(true)
        } else {
            setShowDepartment(false)
        }
    }, [selectedHost])

    useEffect(() => {
        if (selectedHost?.label == "Member") {
            setShowLocation(true)
        } else {
            setShowLocation(false)
        }
    }, [selectedHost])


    // useEffect(() => {
    //     console.log("department", department?.find((dep) => dep.id == location?.state?.department_id))
    //     const findHostType = hostType?.find((hos) => hos.id == location?.state?.host_type)
    //     const findDepartment = department?.find((dep) => dep.id == location?.state?.department_id)
    //     const findlocation = locations?.find((loc) => loc.id == location?.state?.location_id)
    //     const findAccessControl = accessControl?.find((acc) => acc.value == location?.state?.biostar_accesslevel_id)
    //     console.log(findAccessControl, findHostType, findDepartment, findlocation)
    //     setValue("name", location?.state?.name)
    //     setValue("host_type", { label: findHostType?.name, value: findHostType?.id })
    //     setValue("department", { label: findDepartment?.name, value: findDepartment?.id })
    //     setValue("location", { label: findlocation?.name, value: findlocation?.id })
    //     setValue("accesscontrol", { label: findAccessControl?.door_name, value: findAccessControl?.biostar_door_id })
    // }, [])


    const onSubmit = async (newData) => {
        console.log("newData", newData)
        setIsLoading(true)
        try {
            if (newData?.host_type == "Staff") {
                newData.department_id = newData?.department_id ? newData.department_id.value : ''
            } else {
                newData.location_id = newData?.location_id ? newData.location_id.value : ''
            }
            newData.access_control = newData.access_control.map((access) => access?.value).join(",")
            newData.department_id = newData.department_id.value
            newData.host_type = newData.host_type.value
            const { data } = await accessmappingupdate({ id: location?.state?.id, ...newData })
            toast.success(data?.message, toastConfig)
            navigate('/masters/access-mapping')
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    };
    const resetValue = () => {
        reset();
        setValue("hosttype", "")
    };

    return (
        <>
            <div className="row g-3 mb-3">
                <div className="">
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-8">
                                    <h3 className="heading-color fw-600 mb-0">
                                        {t('accessmapping.editaccessmapping')}
                                    </h3>
                                </div>
                                <div className="col-4 table-btn">
                                    <button
                                        className="btn btn-primary float-end btn-sm"
                                        onClick={() => navigate('/masters/access-mapping')}
                                    >
                                        <FontAwesomeIcon icon="chevron-left" />
                                        <span className="ms-1">
                                            {t('accessmapping.back')}
                                        </span>
                                    </button>
                                </div>
                                <div className="col-12" >
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                {t('accessmapping.label.accessname')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                className={`form-control ${errors.name ? 'is-invalid' : ''
                                                    }`}
                                                type="text"
                                                placeholder={t(
                                                    'accessmapping.placeholder.accessname'
                                                )}
                                                {...register('name', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                            />
                                            {errors.name && (
                                                <small className="text-danger">
                                                    {errors.name.type == 'required' && (
                                                        <span>
                                                            {t('accessmapping.error.accessname')}
                                                        </span>
                                                    )}
                                                    {errors.name.type == 'pattern' && (
                                                        <span>
                                                            {t('accessmapping.error.accessnameInvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                {t('accessmapping.label.accesscontrol')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                control={control}
                                                rules={{ required: true }}
                                                name="access_control"
                                                render={({ field: { value, onChange } }) => (
                                                    <SearchableDropdown
                                                        options={filterAccessControl}
                                                        isClearable={false}
                                                        onChange={onChange}
                                                        hasError={errors.access_control}
                                                        placeholder={t(
                                                            'accessmapping.placeholder.accesscontrol'
                                                        )}
                                                        value={value}
                                                        isMulti={true}
                                                    // noOptionsMessage={() =>
                                                    //   t('departmentMaster.error.selectCompanyMsg')
                                                    // }
                                                    />
                                                )}
                                            />
                                            {errors.access_control && (
                                                <small className="text-danger">
                                                    {errors.access_control.type == 'required' && (
                                                        <span>
                                                            {t('accessmapping.error.accesscontrol')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label">
                                                {t('accessmapping.label.hosttype')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                control={control}
                                                rules={{ required: true }}
                                                name="host_type"
                                                render={({ field: { value, onChange } }) => (
                                                    <SearchableDropdown
                                                        options={hostType}
                                                        isClearable={false}
                                                        onChange={onChange}
                                                        hasError={errors.host_type}
                                                        placeholder={t(
                                                            'accessmapping.placeholder.hosttype'
                                                        )}
                                                        value={value}
                                                    // noOptionsMessage={() =>
                                                    //   t('departmentMaster.error.selectCompanyMsg')
                                                    // }
                                                    />
                                                )}
                                            />
                                            {errors.host_type && (
                                                <small className="text-danger">
                                                    {errors.host_type.type == 'required' && (
                                                        <span>
                                                            {t('accessmapping.error.hosttype')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                    </div>


                                    {showLocation &&
                                        <div className="col-12 col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    {t('accessmapping.label.location')}
                                                    {isDeptAndLocReq && <span className="text-danger ms-1">*</span>}
                                                </label>
                                                <Controller
                                                    control={control}
                                                    rules={{ required: isDeptAndLocReq }}
                                                    name="location_id"
                                                    render={({ field: { value, onChange } }) => (
                                                        <SearchableDropdown
                                                            options={locations}
                                                            isClearable={false}
                                                            onChange={onChange}
                                                            hasError={errors.location_id}
                                                            placeholder={t(
                                                                'accessmapping.placeholder.location'
                                                            )}
                                                            value={value}
                                                        // noOptionsMessage={() =>
                                                        //   t('departmentMaster.error.selectCompanyMsg')
                                                        // }
                                                        />
                                                    )}
                                                />
                                                {errors.location_id && (
                                                    <small className="text-danger">
                                                        {errors.location_id.type == 'required' && (
                                                            <span>
                                                                {t('accessmapping.error.location')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    }

                                    {showDepartment &&
                                        <div className="col-12 col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">
                                                    {t('accessmapping.label.department')}
                                                    {isDeptAndLocReq && <span className="text-danger ms-1">*</span>}
                                                </label>
                                                <Controller
                                                    control={control}
                                                    rules={{ required: isDeptAndLocReq }}
                                                    name="department_id"
                                                    render={({ field: { value, onChange } }) => (
                                                        <SearchableDropdown
                                                            options={department}
                                                            isClearable={false}
                                                            onChange={onChange}
                                                            hasError={errors.department_id}
                                                            placeholder={t(
                                                                'accessmapping.placeholder.department'
                                                            )}
                                                            value={value}
                                                        // noOptionsMessage={() =>
                                                        //   t('departmentMaster.error.selectCompanyMsg')
                                                        // }
                                                        />
                                                    )}
                                                />
                                                {errors.department_id && (
                                                    <small className="text-danger">
                                                        {errors.department_id.type == 'required' && (
                                                            <span>
                                                                {t('accessmapping.error.department')}
                                                            </span>
                                                        )}
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    }



                                    <div className="text-start col-12">
                                        <div className="mb-3 text-end">
                                            <button type="submit" className="btn btn-primary me-1">
                                                {t('accessmapping.update')}
                                            </button>
                                            <button
                                                type="reset"
                                                className="btn btn-danger"
                                                onClick={resetValue}
                                            >
                                                {t('accessmapping.reset')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* <div className="card-body pt-0">
                            <ReactTable
                                columns={columns}
                                data={departmentData}
                                pageSizeOption={[5, 10, 15, 20]}
                                headerClass="btn-primary"
                                isSearchBoxVisible={false}
                            />
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}
