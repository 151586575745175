import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { Controller, useForm } from 'react-hook-form';
import { resetServerContext } from 'react-beautiful-dnd';
import pattern from 'components/configuration/form-pattern';
// import { authenticateUser } from 'services/frontDeskServices';
import Swal from 'sweetalert2';
import sweetalertConfig from 'components/configuration/sweetalert-config';
import '../visitorRegistration/bypass-alert.css';
import InputPassword from 'components/common/InputPassword';
import { bypassVisitor, guestbypass } from 'services/visitorRegistrationService'
import { visitorBypassData } from 'services/visitorAppointmentService'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { loaderContext } from 'context/Context';
import { useContext } from 'react';
import PhoneNumberFrontdesk from 'components/common/PhoneNumberFrontdesk';
import countryCodes, {
    defaultCountryCode
  } from 'components/configuration/country-code';
import { bypassadminotp, getGuestRegistrationVerifyOTPbyDoc } from 'services/guestRegistrationService';

export default function Guest7TimeBypassModal({hidesubmit,hideaddbypass, show, handleClose, setIdentitySection, byPassType, guestData,bypassGuest, resetbypass,allguest,setAllGuest, isAppointment, appointmentId }) {
  const { t } = useTranslation();
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const [countryCode, setCountryCode] = useState(defaultCountryCode);
  const [isVisible, setIsVisible] = useState(false)

 


  const navigate = useNavigate();
  const {
    register,
    handleSubmit: handleSubmitBypass,
    formState: { errors},
    reset,
    setValue,
    control,
    setError,
    clearErrors,
    watch
  } = useForm();

  console.log("guestdata====>", guestData)

  const phoneNumber = watch("phone")
  useEffect(()=>{
setValue("phone", 700000000)
  },[])

  const onBypassSubmit = async bypassData => {
    
    Swal.fire({
      title: t('visitorRegistration.sweetAlert.bpconfirmationTitle'),
      ...sweetalertConfig.confirmation,
      cancelButtonText: t('visitorRegistration.sweetAlert.bpBtnNo'),
      confirmButtonText: t('visitorRegistration.sweetAlert.bpBtnYes')
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        try {
          let { otp, ...restData } = guestData
          bypassData = { ...bypassData, ...restData }
          const {phone,otp:adminotp, ...otherData}= bypassData
          otherData.admin_country_code = countryCode?.dial_code
          otherData.admin_phone = phone
          otherData.admin_otp = adminotp
          console.log("bypassDataaaaaaaaaaaaaaaa", otherData)
          const { data } = await guestbypass(otherData);
          toast.success(data.message, toastConfig);
          byPassType === "indentity" && setIdentitySection(false)
          onClose()
          resetbypass()
          navigate('/guest-registration')
        } catch (e) {
          if (e.response.data) {
            if (Array.isArray(e.response.data.message)) {
              e.response.data.message.map(errors => toast.error(errors, toastConfig))
            } else {
              toast.error(e.response.data.message, toastConfig)
            }
          } else {
            toast.error(e.message, toastConfig)
          }
        }
        setIsLoading(false)
      }
    });

    console.log('click');
  };

  const onBypassAddGuestSubmit = async bypassData => {
    console.log("bypassdatawithfield===>", bypassData)
    Swal.fire({
      title: t('visitorRegistration.sweetAlert.bpconfirmationTitle'),
      ...sweetalertConfig.confirmation,
      cancelButtonText: t('visitorRegistration.sweetAlert.bpBtnNo'),
      confirmButtonText: t('visitorRegistration.sweetAlert.bpBtnYes')
    }).then(async result => {
      if (result.isConfirmed) {
        setIsLoading(true)
        try {
          const {phone,otp:adminotp, ...restData2}= bypassData
          const otherData = { ...restData2, ...bypassGuest }
          console.log("bypass---------------------------->",otherData )
          otherData.admin_country_code = countryCode?.dial_code
          otherData.admin_phone = phone
          otherData.admin_otp = adminotp
          otherData.is_bypass = 1

          console.log("bypassDataaaaaaaaaaaaaaaa", otherData)
          const { data } = await getGuestRegistrationVerifyOTPbyDoc(otherData);
          setAllGuest([...allguest, otherData])
          toast.success(data.message, toastConfig);
          byPassType === "indentity" && setIdentitySection(false)
          onClose()
          resetbypass()
          navigate('/guest-registration')
        } catch (e) {
          if (e.response.data) {
            if (Array.isArray(e.response.data.message)) {
              e.response.data.message.map(errors => toast.error(errors, toastConfig))
            } else {
              toast.error(e.response.data.message, toastConfig)
            }
          } else {
            toast.error(e.message, toastConfig)
          }
        }
        setIsLoading(false)
      }
    });

    console.log('click');
  };

  useEffect(() => {
    if (isVisible) {
        setIsVisible(false)
    }
}, [phoneNumber])

// useEffect(()=>{
//   if(phoneNumber != "" || phoneNumber){
//     clearErrors("phone")
//   }
// },[phoneNumber])
  const sendOTP=async()=>{
    console.log("phonenumber===>1")
   
    if(phoneNumber == "" || !phoneNumber){
        console.log("phonenumber===>2")
       setError("phone", { type: "required" })
    }else{
        try
        {
            setIsLoading(true)
            setIsVisible(true)
            const { data } = await bypassadminotp({ admin_phone: 700000000, admin_country_code: countryCode.dial_code })
            toast.success(data?.data[0]?.admin_otp, toastConfig)
        } catch (e)
        {
          if (e.response.data)
          {
            if (Array.isArray(e.response.data.message))
            {
              e.response.data.message.map(errors => toast.error(errors, toastConfig))
            } else
            {
              toast.error(e.response.data.message, toastConfig)
            }
          } else
          {
            toast.error(e.message, toastConfig)
          }
        }
        setIsLoading(false)
    // }
    

      }  
    }
  const onClose = () => {
    resetbypass();
    handleClose();
    reset();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <form autoComplete="off">
        <Modal.Header
          closeVariant='white'
          style={{ backgroundColor: 'var(--secondary)' }}
          closeButton
        >
          <Modal.Title>
            <h5 className='text-white'>{t('guestRegistration.guest7timearrivebypass.heading')}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-3">
          <label>
                        {t('guestRegistration.guest7timearrivebypass.label.phone')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <div className="d-flex flex-wrap p-0 col-12 gap-3">
                        <div className='d-flex' style={{ flexDirection: "column" }}>
                          <Controller
                            name="phone"
                            control={control}
                            // rules={{ required:true , pattern: pattern.phoneNumber }}
                            render={({ field: { onChange, value } }) => (
                              <PhoneNumberFrontdesk
                                phoneNumber={value}
                                // email={email}
                                setPhoneNumber={onChange}
                                // firstError={errors.phone}
                                countryCode={countryCode}
                                firstPlaceholder={t("guestRegistration.guest7timearrivebypass.placeholder.phone")}
                                setCountryCode={setCountryCode}
                                dropdownDirection="bottom"
                                // error={errors.phone}
                                saperate={true}
                                wantemail={false}
                                disableboth={true}
                                // disableboth={location?.state?.isAppointment === true ? true : false}
                                // formFieldsDisabled={location?.state?.isAppointment === true ? true : false}
                                flagvisible={true}
                              />
                            )}
                          />
                          {/* {errors.phone && (
                            <>
                              <small>
                                {errors.phone.type === 'required' && (
                                  <span className="text-danger">
                                    {t(
                                      'guestRegistration.guest7timearrivebypass.error.phone'
                                    )}
                                  </span>
                                )}
                              </small>
                              <small>
                                {errors.phone.type === 'pattern' && (
                                  <span className="text-danger">
                                    {t(`guestRegistration.guest7timearrivebypass.error.phoneNoInvalid`)}
                                  </span>
                                )}
                              </small>
                            </>
                          )} */}
                        </div>
                        {/* <button
                              className="btn btn-primary-frontdesk sendotp "
                              type="button"
                              onClick={() => sendOTP()}
                            >
                              {isVisible ? t('guestRegistration.guest7timearrivebypass.label.resendOtp') : t('guestRegistration.guest7timearrivebypass.label.sentOtp')}
                            </button> */}
                             {
                                                    isVisible ? (
                                                        <button
                                                            className="btn btn-primary-frontdesk sendotp "
                                                            onClick={() => sendOTP()}
                                                           type="button"
                                                        >
                                                            {t('guestRegistration.guest7timearrivebypass.label.resendOtp')}
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className="btn btn-primary-frontdesk sendotp "
                                                            onClick={() => sendOTP()}
                                                          type="button"
                                                        >
                                                            {t('guestRegistration.guest7timearrivebypass.label.sentOtp')}
                                                        </button>
                                                    )
                                                }
          </div>
        
          </div>
          <div className="form-group mb-3">
          <label>
                        {t('guestRegistration.guest7timearrivebypass.label.otp')}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <div className="d-flex  flex-wrap gap-2">
                        <input
                          type="text"
                          style={{ maxWidth: "15rem" }}
                          className={`form-control ${errors.otp && 'is-invalid'
                            } `}
                          placeholder={t('guestRegistration.guest7timearrivebypass.placeholder.otp')}
                          {...register('otp', {
                            required:true,
                            pattern: pattern.otp
                          })}
                        />
                        
          </div>
          {errors.otp && (
                        <small>
                          {errors.otp.type == 'required' && (
                            <span className="text-danger">
                              {t('guestRegistration.guest7timearrivebypass.error.otp')}
                            </span>
                          )}
                          {errors.otp.type == 'pattern' && (
                            <span className="text-danger">
                              {t('guestRegistration.guest7timearrivebypass.error.otpinvalid')}
                            </span>
                          )}
                        </small>
                      )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-danger"
              onClick={onClose}
            >
              {t('guestRegistration.guest7timearrivebypass.label.cancel')}
            </button>
            {
              hideaddbypass && (
<button
              type="button"
              className="btn btn-primary ms-2"
              onClick={handleSubmitBypass(onBypassAddGuestSubmit)}
            >
              {t('guestRegistration.guest7timearrivebypass.label.addbypass')}
            </button>
              )
            }
            
            {
              hidesubmit && (
<button
              type="button"
              className="btn btn-primary ms-2"
              onClick={handleSubmitBypass(onBypassSubmit)}
            >
              {t('guestRegistration.guest7timearrivebypass.label.bypass')}
            </button>
              )
            }
            
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}