import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import ReactTable from 'components/common/ReactTable';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Swalcolor from 'components/configuration/sweetalert-config';
import { encodeData } from 'components/common/encodeParams';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import moment from 'moment';
import { getlocation, deletelocation } from 'services/manageEventsService'
import { useForm, Controller, set } from 'react-hook-form';
import { accessmappinglist, accessmappingdelete } from 'services/accessMappingService'
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';




export default function AccessMapping() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [AccessData, setAccessData] = useState([]);
    const { isLoading, setIsLoading } = useContext(loaderContext)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        watch
    } = useForm();


    useEffect(async () => {
        checkModulePermission("Access Mapping")
        setIsLoading(true)
        try {
            const { data } = await accessmappinglist();
            setAccessData(data?.data)
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, [])



    const onEditRow = (id) => {
        const editdata = AccessData.find((access) => access.id === id)
        navigate('/masters/edit-access-mapping', { state: editdata });
    };
    const onDeleteRow = (id) => {
        Swal.fire({
            title: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationTitle'
            ),
            text: t('departmentMaster.deleteDepartment.sweetAlert.confirmationText'),
            icon: 'warning',
            showCancelButton: Swalcolor.confirmation.showCancelButton,
            confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
            cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
            confirmButtonText: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationBtnLabel'
            )
        }).then(async result => {
            if (result.isConfirmed) {
                setIsLoading(true)
                try {
                    const { data } = await accessmappingdelete({ id });
                    console.log(data);
                    setAccessData(AccessData.filter(data => data.id != id));
                    Swal.fire({
                        icon: 'success',
                        title: t(
                            'departmentMaster.deleteDepartment.sweetAlert.simpleTitle'
                        ),
                        text: "Delete successfull.",
                        confirmButtonColor: Swalcolor.simple.confirmButtonColor
                    });
                } catch (e) {
                    errorMessage(e)
                }
                setIsLoading(false)
            }
        });
    };

    const columns = [
        {
            Header: t('accessmapping.label.no'),
            // accessor: 'id'
            accessor: (row, index) => index + 1,
        },
        {
            Header: t('accessmapping.label.accessname'),
            accessor: 'name'
        },
        {
            Header: t('accessmapping.label.hosttype'),
            accessor: 'host_type',
            Cell: ({ row }) => {
                return row?.original?.host_type == 1 ? 'Staff' : 'Member'
            }
        },
        {
            Header: t('accessmapping.label.department'),
            accessor: 'department',
            Cell: ({ row }) => {
                return row?.original?.host_type === 1 ? row?.original?.department : '--'
            }
        },
        {
            Header: t('accessmapping.label.location'),
            accessor: 'location',
            Cell: ({ row }) => {
                return row?.original?.host_type === 2 ? row?.original?.location : '--'
            }
        },
        {
            Header: t('accessmapping.label.accesscontrol'),
            accessor: 'accesscontrol',
            // Cell: ({ row }) => (
            //     <span>{row?.original?.accesscontrol?.join(', ')}</span>
            // ),
        },
        {
            Header: t('accessmapping.label.action'),
            Cell: ({ row }) => (
                <div>
                    <span
                        className="btn p-0 me-2"
                        title="Edit"
                        onClick={() => onEditRow(row.original.id)}
                    >
                        <FontAwesomeIcon icon="edit" />
                    </span>
                    <span
                        className="btn p-0"
                        title="Delete"
                        onClick={() => onDeleteRow(row.original.id)}
                    >
                        <FontAwesomeIcon icon="trash-alt" />
                    </span>
                </div>
            ),
            disableSortBy: true
        }
    ];

    return (
        <>
            <div className="row g-3 mb-3">
                <div className="">
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-xl-8 col-lg-6 col-md-6 heading-text ">
                                    <h3 className="heading-color fw-600 mb-0">
                                        {t('accessmapping.heading')}
                                    </h3>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 table-btn" >
                                    <button
                                        className="btn btn-primary btn-sm float-end"
                                        onClick={() => {
                                            navigate('/masters/add-access-mapping');
                                        }}
                                    >
                                        <span className="ms-1 d-sm-inline">
                                            {t('accessmapping.add')}
                                        </span>
                                    </button>
                                </div>
                                <div className="col-12" >
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <ReactTable
                                columns={columns}
                                data={AccessData}
                                pageSizeOption={[5, 10, 15, 20]}
                                headerClass="btn-primary"
                                isSearchBoxVisible={true}
                                isPaginationRequired={true}
                                ispaginationvisible={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
