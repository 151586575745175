import httpServices from './httpServices';
import { common } from './config';
import { commonEndpoints } from './config';

//======================================================

export const getListOfIdentificationType = async () => {
  return await httpServices.get(commonEndpoints.identificationTypeList)
}

export const getMemberVerify = async (MemberData) => {
  return await httpServices.post(commonEndpoints.getMemberDetails, MemberData)
}

export const verifyAccessCard = async (data) => {
  return await httpServices.post(commonEndpoints.verifyAccessCard, data)
}
//verifyMemberAccessCard function added by parth
export const verifyMemberAccessCard = async (data) => {
  return await httpServices.post(commonEndpoints.verifyMemberAccessCard, data)
}
export const listsupplier = async () => {
  return await httpServices.get(common.listsupplier)
}