import React, { useContext, useRef, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { t } from 'i18next';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { listmessage, updatemessage } from 'services/adminmoduleService'
import CommonEditor from '../messagetemplate/CommonEditor';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import MessageTemplateTable from './MessageTemplateTable';

export default function MessageTemplate({ initialValue }) {
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const [visitorappointment, setVisitorAppointment] = useState([])
    const [visitorlogout, setVisitorLogout] = useState([])
    const [otp, setOtp] = useState([])
    const [hostappointment, setHostAppointment] = useState([])
    const [visitorcancelappointment, setVisitorCancelAppointment] = useState([])
    const [hostcancelappointment, setHostCancelAppointment] = useState([])
    const [visitorreschedule, setVisitorReschedule] = useState([])
    const [hostreschedule, setHostReschedule] = useState([])
    const [visitorarrival, setVisitorArrival] = useState([])
    const [visitornotlogout, setVisitorNotLogout] = useState([])
    const [employeecredentials, setEmployeeCredentials] = useState([])
    const [guestarrival, setGuestArrival] = useState([])
    const [eventbookingconfirmation, setEventBookingConfirmation] = useState([])
    const [eventcancellationnotification, setEventCancellationNotification] = useState([])
    const [visitnotification5, setVisitNotification5] = useState([])
    const [visitnotification6, setVisitNotification6] = useState([])
    const [visitnotification7, setVisitNotification7] = useState([])
    const [notification6hour, setNotification6Hours] = useState([])
    const [visitnotification6admin, setVisitNotification7Admin] = useState([])
    const [guestLogout, setGuestLogout] = useState([])







    const [smsCheckbox, setSmsCheckbox] = useState(false)
    const [emailCheckbox, setEmailCheckbox] = useState(false)
    const [whatsappCheckbox, setWhatsappCheckbox] = useState(false)

    const [otpsmsCheckbox, setOtpSmsCheckbox] = useState(false)
    const [otpemailCheckbox, setOtpEmailCheckbox] = useState(false)
    const [otpwhatsappCheckbox, setOtpWhatsappCheckbox] = useState(false)

    const [logsmsCheckbox, setLogSmsCheckbox] = useState(false)
    const [logemailCheckbox, setLogEmailCheckbox] = useState(false)
    const [logwhatsappCheckbox, setLogWhatsappCheckbox] = useState(false)

    const [hostappointmentsmsCheckbox, setHostAppointmentSmsCheckbox] = useState(false)
    const [hostappointmentemailCheckbox, setHostAppointmentEmailCheckbox] = useState(false)
    const [hostappointmentwhatsappCheckbox, setHostAppointmentWhatsappCheckbox] = useState(false)

    const [viscancellogoutsmsCheckbox, setVisCancelLogoutSmsCheckbox] = useState(false)
    const [viscancellogoutemailCheckbox, setVisCancelLogoutEmailCheckbox] = useState(false)
    const [viscancellogoutwhatsappCheckbox, setVisCancelLogoutWhatsappCheckbox] = useState(false)

    const [hostcancelappointmentsmsCheckbox, setHostCancelAppointmentSmsCheckbox] = useState(false)
    const [hostcancelappointmentemailCheckbox, setHostCancelAppointmentEmailCheckbox] = useState(false)
    const [hostcancelappointmentwhatsappCheckbox, setHostCancelAppointmentWhatsappCheckbox] = useState(false)

    const [visitorreschedulesmsCheckbox, setVisitorRescheduleSmsCheckbox] = useState(false)
    const [visitorrescheduleemailCheckbox, setVisitorRescheduleEmailCheckbox] = useState(false)
    const [visitorreschedulewhatsappCheckbox, setVisitorRescheduleWhatsappCheckbox] = useState(false)

    const [hostreschedulesmsCheckbox, setHostRescheduleSmsCheckbox] = useState(false)
    const [hostrescheduleemailCheckbox, setHostRescheduleEmailCheckbox] = useState(false)
    const [hostreschedulewhatsappCheckbox, setHostRescheduleWhatsappCheckbox] = useState(false)

    const [visitorarrivalsmsCheckbox, setVisitorArrivalSmsCheckbox] = useState(false)
    const [visitorarrivalemailCheckbox, setVisitorArrivalEmailCheckbox] = useState(false)
    const [visitorarrivalwhatsappCheckbox, setVisitorArrivalWhatsappCheckbox] = useState(false)

    const [visitornotlogoutsmsCheckbox, setVisitorNotLogoutSmsCheckbox] = useState(false)
    const [visitornotlogoutemailCheckbox, setVisitorNotLogoutEmailCheckbox] = useState(false)
    const [visitornotlogoutwhatsappCheckbox, setVisitorNotLogoutWhatsappCheckbox] = useState(false)

    const [employeecredentialssmsCheckbox, setEmployeeCredentialsSmsCheckbox] = useState(false)
    const [employeecredentialsemailCheckbox, setEmployeeCredentialsEmailCheckbox] = useState(false)
    const [employeecredentialswhatsappCheckbox, setEmployeeCredentialsWhatsappCheckbox] = useState(false)

    const [guestarrivalsmsCheckbox, setGuestArrivalSmsCheckbox] = useState(false)
    const [guestarrivalemailCheckbox, setGuestArrivalEmailCheckbox] = useState(false)
    const [guestarrivalwhatsappCheckbox, setGuestArrivalWhatsappCheckbox] = useState(false)

    const [eventbookingconfirmationsmsCheckbox, setEventBookingConfirmationSmsCheckbox] = useState(false)
    const [eventbookingconfirmationemailCheckbox, setEventBookingConfirmationEmailCheckbox] = useState(false)
    const [eventbookingconfirmationwhatsappCheckbox, setEventBookingConfirmationWhatsappCheckbox] = useState(false)

    const [eventcancellationnotificationsmsCheckbox, setEventCancellationNotificationSmsCheckbox] = useState(false)
    const [eventcancellationnotificationemailCheckbox, setEventCancellationNotificationEmailCheckbox] = useState(false)
    const [eventcancellationnotificationwhatsappCheckbox, setEventCancellationNotificationWhatsappCheckbox] = useState(false)

    const [notification5smsCheckbox, setNotification5SmsCheckbox] = useState(false)
    const [notification5emailCheckbox, setNotification5EmailCheckbox] = useState(false)
    const [notification5whatsappCheckbox, setNotification5WhatsappCheckbox] = useState(false)

    const [notification6smsCheckbox, setNotification6SmsCheckbox] = useState(false)
    const [notification6emailCheckbox, setNotification6EmailCheckbox] = useState(false)
    const [notification6whatsappCheckbox, setNotification6WhatsappCheckbox] = useState(false)

    const [notification7smsCheckbox, setNotification7SmsCheckbox] = useState(false)
    const [notification7emailCheckbox, setNotification7EmailCheckbox] = useState(false)
    const [notification7whatsappCheckbox, setNotification7WhatsappCheckbox] = useState(false)

    const [notification6hoursmsCheckbox, setNotification6HourSmsCheckbox] = useState(false)
    const [notification6houremailCheckbox, setNotification6HourEmailCheckbox] = useState(false)
    const [notification6hourwhatsappCheckbox, setNotification6HourWhatsappCheckbox] = useState(false)

    const [notification6adminsmsCheckbox, setNotification6AdminSmsCheckbox] = useState(false)
    const [notification6adminemailCheckbox, setNotification6AdminEmailCheckbox] = useState(false)
    const [notification6adminwhatsappCheckbox, setNotification6AdminWhatsappCheckbox] = useState(false)

    const [guestlogoutsmsCheckbox, setGuestLogoutSmsCheckbox] = useState(false)
    const [guestlogoutemailCheckbox, setGuestLogoutEmailCheckbox] = useState(false)
    const [guestlogoutwhatsappCheckbox, setGuestLogoutWhatsappCheckbox] = useState(false)


    useEffect(async () => {
        checkModulePermission("Message Template")
        setIsLoading(true)
        try {
            const { data } = await listmessage();
            setVisitorAppointment(data?.data?.find((visit) => visit.template_name == "Visitor Appointment"))
            setVisitorLogout(data?.data?.find((log) => log.template_name == "Visitor Logout"))
            setOtp(data?.data?.find((otp) => otp.template_name == "Registration OTP"))
            setHostAppointment(data?.data?.find((visit) => visit.template_name == "Host Appointment"))
            setVisitorCancelAppointment(data?.data?.find((visit) => visit.template_name == "Visitor Cancel Appointment"))
            setHostCancelAppointment(data?.data?.find((visit) => visit.template_name == "Host Cancel Appointment"))
            setVisitorReschedule(data?.data?.find((visit) => visit.template_name == "Visitor Reschedule"))
            setHostReschedule(data?.data?.find((visit) => visit.template_name == "Host Reschedule"))
            setVisitorArrival(data?.data?.find((visit) => visit.template_name == "Visitor Arrival"))
            setVisitorNotLogout(data?.data?.find((visit) => visit.template_name == "Visitor Not Logout"))
            setEmployeeCredentials(data?.data?.find((visit) => visit.template_name == "Employee Credentials"))
            setGuestArrival(data?.data?.find((visit) => visit.template_name == "Guest Arrival"))
            setEventBookingConfirmation(data?.data?.find((visit) => visit.template_name == "Event Booking Confirmation"))
            setEventCancellationNotification(data?.data?.find((visit) => visit.template_name == "Event Cancellation Notification"))
            setVisitNotification5(data?.data?.find((visit) => visit.template_name == "5th Visit Notification"))
            setVisitNotification6(data?.data?.find((visit) => visit.template_name == "6th Guest Visit Notification"))
            setVisitNotification7(data?.data?.find((visit) => visit.template_name == "7th Admin Visit Notification"))
            setNotification6Hours(data?.data?.find((visit) => visit.template_name == "Event Notification 6hours Prior"))
            setVisitNotification7Admin(data?.data?.find((visit) => visit.template_name == "6th Admin\/Security Manager Visit Notification"))
            setGuestLogout(data?.data?.find((visit) => visit.template_name == "Guest Logout"))


            setSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Appointment")?.key_value.includes('sms') ? true : false)
            setEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Appointment")?.key_value.includes('email') ? true : false)
            setWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Appointment")?.key_value.includes('whatsapp') ? true : false)

            setOtpSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Registration OTP")?.key_value.includes('sms') ? true : false)
            setOtpEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Registration OTP")?.key_value.includes('email') ? true : false)
            setOtpWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Registration OTP")?.key_value.includes('whatsapp') ? true : false)


            setLogSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Logout")?.key_value.includes('sms') ? true : false)
            setLogEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Logout")?.key_value.includes('email') ? true : false)
            setLogWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Logout")?.key_value.includes('whatsapp') ? true : false)


            setHostAppointmentSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Host Appointment")?.key_value.includes('sms') ? true : false)
            setHostAppointmentEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Host Appointment")?.key_value.includes('email') ? true : false)
            setHostAppointmentWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Host Appointment")?.key_value.includes('whatsapp') ? true : false)

            setVisCancelLogoutSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Cancel Appointment")?.key_value.includes('sms') ? true : false)
            setVisCancelLogoutEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Cancel Appointment")?.key_value.includes('email') ? true : false)
            setVisCancelLogoutWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Cancel Appointment")?.key_value.includes('whatsapp') ? true : false)

            setHostCancelAppointmentSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Host Cancel Appointment")?.key_value.includes('sms') ? true : false)
            setHostCancelAppointmentEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Host Cancel Appointment")?.key_value.includes('email') ? true : false)
            setHostCancelAppointmentWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Host Cancel Appointment")?.key_value.includes('whatsapp') ? true : false)

            setVisitorRescheduleSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Reschedule")?.key_value.includes('sms') ? true : false)
            setVisitorRescheduleEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Reschedule")?.key_value.includes('email') ? true : false)
            setVisitorRescheduleWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Reschedule")?.key_value.includes('whatsapp') ? true : false)

            setHostRescheduleSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Host Reschedule")?.key_value.includes('sms') ? true : false)
            setHostRescheduleEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Host Reschedule")?.key_value.includes('email') ? true : false)
            setHostRescheduleWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Host Reschedule")?.key_value.includes('whatsapp') ? true : false)

            setVisitorArrivalSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Arrival")?.key_value.includes('sms') ? true : false)
            setVisitorArrivalEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Arrival")?.key_value.includes('email') ? true : false)
            setVisitorArrivalWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Arrival")?.key_value.includes('whatsapp') ? true : false)

            setVisitorNotLogoutSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Not Logout")?.key_value.includes('sms') ? true : false)
            setVisitorNotLogoutEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Not Logout")?.key_value.includes('email') ? true : false)
            setVisitorNotLogoutWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Visitor Not Logout")?.key_value.includes('whatsapp') ? true : false)

            setEmployeeCredentialsSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Employee Credentials")?.key_value.includes('sms') ? true : false)
            setEmployeeCredentialsEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Employee Credentials")?.key_value.includes('email') ? true : false)
            setEmployeeCredentialsWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Employee Credentials")?.key_value.includes('whatsapp') ? true : false)

            setGuestArrivalSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Guest Arrival")?.key_value.includes('sms') ? true : false)
            setGuestArrivalEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Guest Arrival")?.key_value.includes('email') ? true : false)
            setGuestArrivalWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Guest Arrival")?.key_value.includes('whatsapp') ? true : false)

            setEventBookingConfirmationSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Event Booking Confirmation")?.key_value.includes('sms') ? true : false)
            setEventBookingConfirmationEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Event Booking Confirmation")?.key_value.includes('email') ? true : false)
            setEventBookingConfirmationWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Event Booking Confirmation")?.key_value.includes('whatsapp') ? true : false)

            setEventCancellationNotificationSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Event Cancellation Notification")?.key_value.includes('sms') ? true : false)
            setEventCancellationNotificationEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Event Cancellation Notification")?.key_value.includes('email') ? true : false)
            setEventCancellationNotificationWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Event Cancellation Notification")?.key_value.includes('whatsapp') ? true : false)

            setNotification5SmsCheckbox(data?.data?.find((visit) => visit?.template_name === "5th Visit Notification")?.key_value.includes('sms') ? true : false)
            setNotification5EmailCheckbox(data?.data?.find((visit) => visit?.template_name === "5th Visit Notification")?.key_value.includes('email') ? true : false)
            setNotification5WhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "5th Visit Notification")?.key_value.includes('whatsapp') ? true : false)

            setNotification6SmsCheckbox(data?.data?.find((visit) => visit?.template_name === "6th Guest Visit Notification")?.key_value.includes('sms') ? true : false)
            setNotification6EmailCheckbox(data?.data?.find((visit) => visit?.template_name === "6th Guest Visit Notification")?.key_value.includes('email') ? true : false)
            setNotification6WhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "6th Guest Visit Notification")?.key_value.includes('whatsapp') ? true : false)

            setNotification7SmsCheckbox(data?.data?.find((visit) => visit?.template_name === "7th Admin Visit Notification")?.key_value.includes('sms') ? true : false)
            setNotification7EmailCheckbox(data?.data?.find((visit) => visit?.template_name === "7th Admin Visit Notification")?.key_value.includes('email') ? true : false)
            setNotification7WhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "7th Admin Visit Notification")?.key_value.includes('whatsapp') ? true : false)

            setNotification6HourSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Event Notification 6hours Prior")?.key_value.includes('sms') ? true : false)
            setNotification6HourEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Event Notification 6hours Prior")?.key_value.includes('email') ? true : false)
            setNotification6HourWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Event Notification 6hours Prior")?.key_value.includes('whatsapp') ? true : false)

            setNotification6AdminSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "6th Admin\/Security Manager Visit Notification")?.key_value.includes('sms') ? true : false)
            setNotification6AdminEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "6th Admin\/Security Manager Visit Notification")?.key_value.includes('email') ? true : false)
            setNotification6AdminWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "6th Admin\/Security Manager Visit Notification")?.key_value.includes('whatsapp') ? true : false)

            setGuestLogoutSmsCheckbox(data?.data?.find((visit) => visit?.template_name === "Guest Logout")?.key_value.includes('sms') ? true : false)
            setGuestLogoutEmailCheckbox(data?.data?.find((visit) => visit?.template_name === "Guest Logout")?.key_value.includes('email') ? true : false)
            setGuestLogoutWhatsappCheckbox(data?.data?.find((visit) => visit?.template_name === "Guest Logout")?.key_value.includes('whatsapp') ? true : false)


        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }, [])


    const handleVisitorMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...visitorappointment }
        visitorMsg[msgType] = msg;
        setVisitorAppointment(visitorMsg)
    }
    const handleOtpMsgChange = ({ msg, msgType }) => {
        const otpMsg = { ...otp }
        otpMsg[msgType] = msg;
        setOtp(otpMsg)
    }
    const handleVisitorLogoutMsgChange = ({ msg, msgType }) => {
        const visitorLogoutMsg = { ...visitorlogout }
        visitorLogoutMsg[msgType] = msg;
        setVisitorLogout(visitorLogoutMsg)
    }
    const handleHostAppointmentMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...hostappointment }
        visitorMsg[msgType] = msg;
        setHostAppointment(visitorMsg)
    }
    const handlevisitorcancelappointmentMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...visitorcancelappointment }
        visitorMsg[msgType] = msg;
        setVisitorCancelAppointment(visitorMsg)
    }
    const handlehostcancelappointmentMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...hostcancelappointment }
        visitorMsg[msgType] = msg;
        setHostCancelAppointment(visitorMsg)
    }
    const handlevisitorrescheduleMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...visitorreschedule }
        visitorMsg[msgType] = msg;
        setVisitorReschedule(visitorMsg)
    }
    const handlehostrescheduleMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...hostreschedule }
        visitorMsg[msgType] = msg;
        setHostReschedule(visitorMsg)
    }
    const handlevisitorarrivalMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...visitorarrival }
        visitorMsg[msgType] = msg;
        setVisitorArrival(visitorMsg)
    }
    const handlevisitornotlogoutMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...visitornotlogout }
        visitorMsg[msgType] = msg;
        setVisitorNotLogout(visitorMsg)
    }
    const handleemployeecredentialsMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...employeecredentials }
        visitorMsg[msgType] = msg;
        setEmployeeCredentials(visitorMsg)
    }
    const handleguestarrivalMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...guestarrival }
        visitorMsg[msgType] = msg;
        setGuestArrival(visitorMsg)
    }
    const handleeventbookingconfirmationMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...eventbookingconfirmation }
        visitorMsg[msgType] = msg;
        setEventBookingConfirmation(visitorMsg)
    }
    const handleeventcancellationnotificationMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...eventcancellationnotification }
        visitorMsg[msgType] = msg;
        setEventCancellationNotification(visitorMsg)
    }
    const handlenotification5MsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...visitnotification5 }
        visitorMsg[msgType] = msg;
        setVisitNotification5(visitorMsg)
    }
    const handlenotification6MsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...visitnotification6 }
        visitorMsg[msgType] = msg;
        setVisitNotification6(visitorMsg)
    }
    const handlenotification7MsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...visitnotification7 }
        visitorMsg[msgType] = msg;
        setVisitNotification7(visitorMsg)
    }
    const handlenotification6hourMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...notification6hour }
        visitorMsg[msgType] = msg;
        setNotification6Hours(visitorMsg)
    }
    const handlenotification6adminMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...visitnotification6admin }
        visitorMsg[msgType] = msg;
        setVisitNotification7Admin(visitorMsg)
    }
    const handleguestlogoutMsgChange = ({ msg, msgType }) => {
        const visitorMsg = { ...guestLogout }
        visitorMsg[msgType] = msg;
        setGuestLogout(visitorMsg)
    }




    const handleVisitorAppointment = async (visitorappointmentdata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(visitorappointmentdata?.checkbox || {}).filter(key => visitorappointmentdata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: visitorappointment?.message_templete_id, sms_content: visitorappointment?.sms_content, email_content: visitorappointment?.email_content, whatsapp_content: visitorappointment?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Appointment")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Appointment")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Appointment")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handleOtp = async (otpdata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(otpdata?.checkbox || {}).filter(key => otpdata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: otp?.message_templete_id, sms_content: otp?.sms_content, email_content: otp?.email_content, whatsapp_content: otp?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })
            setOtpSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Registration OTP")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setOtpEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Registration OTP")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setOtpWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Registration OTP")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)
            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)

    }
    const handleVisitorLogout = async (visitorlogoutdata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(visitorlogoutdata?.checkbox || {}).filter(key => visitorlogoutdata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: visitorlogout?.message_templete_id, sms_content: visitorlogout?.sms_content, email_content: visitorlogout?.email_content, whatsapp_content: visitorlogout?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })
            setLogSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Logout")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setLogEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Logout")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setLogWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Logout")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)
            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)

    }
    const handlehostappointment = async (hostappointmentdata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(hostappointmentdata?.checkbox || {}).filter(key => hostappointmentdata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: hostappointment?.message_templete_id, sms_content: hostappointment?.sms_content, email_content: hostappointment?.email_content, whatsapp_content: hostappointment?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setHostAppointmentSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Host Appointment")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setHostAppointmentEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Host Appointment")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setHostAppointmentWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Host Appointment")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handlevisitorcancelappointment = async (visitorcancelappointmentdata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(visitorcancelappointmentdata?.checkbox || {}).filter(key => visitorcancelappointmentdata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: visitorcancelappointment?.message_templete_id, sms_content: visitorcancelappointment?.sms_content, email_content: visitorcancelappointment?.email_content, whatsapp_content: visitorcancelappointment?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setVisCancelLogoutSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Cancel Appointment")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setVisCancelLogoutEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Cancel Appointment")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setVisCancelLogoutWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Cancel Appointment")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handlehostcancelappointment = async (hostcancelappointmentdata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(hostcancelappointmentdata?.checkbox || {}).filter(key => hostcancelappointmentdata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: hostcancelappointment?.message_templete_id, sms_content: hostcancelappointment?.sms_content, email_content: hostcancelappointment?.email_content, whatsapp_content: hostcancelappointment?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setHostCancelAppointmentSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Host Cancel Appointment")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setHostCancelAppointmentEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Host Cancel Appointment")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setHostCancelAppointmentWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Host Cancel Appointment")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handlevisitorreschedule = async (visitorrescheduledata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(visitorrescheduledata?.checkbox || {}).filter(key => visitorrescheduledata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: visitorreschedule?.message_templete_id, sms_content: visitorreschedule?.sms_content, email_content: visitorreschedule?.email_content, whatsapp_content: visitorreschedule?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setVisitorRescheduleSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Reschedule")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setVisitorRescheduleEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Reschedule")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setVisitorRescheduleWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Reschedule")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handlehostreschedule = async (hostrescheduletdata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(hostrescheduletdata?.checkbox || {}).filter(key => hostrescheduletdata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: hostreschedule?.message_templete_id, sms_content: hostreschedule?.sms_content, email_content: hostreschedule?.email_content, whatsapp_content: hostreschedule?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setHostRescheduleSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Host Reschedule")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setHostRescheduleEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Host Reschedule")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setHostRescheduleWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Host Reschedule")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handlevisitorarrival = async (visitorarrivaldata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(visitorarrivaldata?.checkbox || {}).filter(key => visitorarrivaldata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: visitorarrival?.message_templete_id, sms_content: visitorarrival?.sms_content, email_content: visitorarrival?.email_content, whatsapp_content: visitorarrival?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setVisitorArrivalSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Arrival")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setVisitorArrivalEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Arrival")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setVisitorArrivalWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Arrival")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handlevisitornotlogout = async (visitornotlogoutdata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(visitornotlogoutdata?.checkbox || {}).filter(key => visitornotlogoutdata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: visitornotlogout?.message_templete_id, sms_content: visitornotlogout?.sms_content, email_content: visitornotlogout?.email_content, whatsapp_content: visitornotlogout?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setVisitorNotLogoutSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Not Logout")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setVisitorNotLogoutEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Not Logout")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setVisitorNotLogoutWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Visitor Not Logout")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handleemployeecredentials = async (employeecredentialsdata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(employeecredentialsdata?.checkbox || {}).filter(key => employeecredentialsdata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: employeecredentials?.message_templete_id, sms_content: employeecredentials?.sms_content, email_content: employeecredentials?.email_content, whatsapp_content: employeecredentials?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setEmployeeCredentialsSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Employee Credentials")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setEmployeeCredentialsEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Employee Credentials")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setEmployeeCredentialsWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Employee Credentials")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handleguestarrival = async (guestarrivaldata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(guestarrivaldata?.checkbox || {}).filter(key => guestarrivaldata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: guestarrival?.message_templete_id, sms_content: guestarrival?.sms_content, email_content: guestarrival?.email_content, whatsapp_content: guestarrival?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setGuestArrivalSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Guest Arrival")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setGuestArrivalEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Guest Arrival")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setGuestArrivalWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Guest Arrival")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handleeventbookingconfirmation = async (eventbookingconfirmationdata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(eventbookingconfirmationdata?.checkbox || {}).filter(key => eventbookingconfirmationdata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: eventbookingconfirmation?.message_templete_id, sms_content: eventbookingconfirmation?.sms_content, email_content: eventbookingconfirmation?.email_content, whatsapp_content: eventbookingconfirmation?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setEventBookingConfirmationSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Event Booking Confirmation")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setEventBookingConfirmationEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Event Booking Confirmation")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setEventBookingConfirmationWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Event Booking Confirmation")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handleeventcancellationnotification = async (eventcancellationnotificationdata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(eventcancellationnotificationdata?.checkbox || {}).filter(key => eventcancellationnotificationdata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: eventcancellationnotification?.message_templete_id, sms_content: eventcancellationnotification?.sms_content, email_content: eventcancellationnotification?.email_content, whatsapp_content: eventcancellationnotification?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setEventCancellationNotificationSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Event Cancellation Notification")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setEventCancellationNotificationEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Event Cancellation Notification")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setEventCancellationNotificationWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Event Cancellation Notification")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handlenotification5 = async (notification5data) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(notification5data?.checkbox || {}).filter(key => notification5data.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: visitnotification5?.message_templete_id, sms_content: visitnotification5?.sms_content, email_content: visitnotification5?.email_content, whatsapp_content: visitnotification5?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setNotification5SmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "5th Visit Notification")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setNotification5EmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "5th Visit Notification")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setNotification5WhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "5th Visit Notification")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handlenotification6 = async (notification6data) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(notification6data?.checkbox || {}).filter(key => notification6data.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: visitnotification6?.message_templete_id, sms_content: visitnotification6?.sms_content, email_content: visitnotification6?.email_content, whatsapp_content: visitnotification6?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setNotification6SmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "6th Guest Visit Notification")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setNotification6EmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "6th Guest Visit Notification")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setNotification6WhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "6th Guest Visit Notification")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handlenotification7 = async (notification7data) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(notification7data?.checkbox || {}).filter(key => notification7data.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: visitnotification7?.message_templete_id, sms_content: visitnotification7?.sms_content, email_content: visitnotification7?.email_content, whatsapp_content: visitnotification7?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setNotification7SmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "7th Admin Visit Notification")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setNotification7EmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "7th Admin Visit Notification")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setNotification7WhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "7th Admin Visit Notification")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handlenotification6hour = async (notification6hourdata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(notification6hourdata?.checkbox || {}).filter(key => notification6hourdata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: notification6hour?.message_templete_id, sms_content: notification6hour?.sms_content, email_content: notification6hour?.email_content, whatsapp_content: notification6hour?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setNotification6HourSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Event Notification 6hours Prior")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setNotification6HourEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Event Notification 6hours Prior")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setNotification6HourWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Event Notification 6hours Prior")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const handlenotification6admin = async (notification6admindata) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(notification6admindata?.checkbox || {}).filter(key => notification6admindata.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: visitnotification6admin?.message_templete_id, sms_content: visitnotification6admin?.sms_content, email_content: visitnotification6admin?.email_content, whatsapp_content: visitnotification6admin?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setNotification6AdminSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "6th Admin\/Security Manager Visit Notification")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setNotification6AdminEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "6th Admin\/Security Manager Visit Notification")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setNotification6AdminWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "6th Admin\/Security Manager Visit Notification")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }

    const handleguestlogout = async (guestlogout) => {
        setIsLoading(true)
        try {
            const selectedCheckboxes = Object.keys(guestlogout?.checkbox || {}).filter(key => guestlogout.checkbox[key]);
            const { data } = await updatemessage({
                message_templete_id: guestLogout?.message_templete_id, sms_content: guestLogout?.sms_content, email_content: guestLogout?.email_content, whatsapp_content: guestLogout?.whatsapp_content,
                key_value: selectedCheckboxes.join(','),
            })

            setGuestLogoutSmsCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Guest Logout")?.message_templete_detail?.key_value.includes('sms') ? true : false)
            setGuestLogoutEmailCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Guest Logout")?.message_templete_detail?.key_value.includes('email') ? true : false)
            setGuestLogoutWhatsappCheckbox(data?.data?.find((visit) => visit?.message_templete_detail?.template_name === "Guest Logout")?.message_templete_detail?.key_value.includes('whatsapp') ? true : false)

            toast.success(data?.message, toastConfig)
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }





    return (
        <>

            <div className="row g-3 mb-3">
                <div className='table-body-admin'>
                    <div className="card overfolw-hidden mb-3">
                        <div className="card-header pb-0">
                            <div className="row">
                                <div className='col-8'>
                                    <h3 className="heading-color fw-600">
                                        {t('messagetemplate.mainheading')}
                                    </h3>
                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: "1rem" }}>
                            <MessageTemplateTable />
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.visitorheading')}</p>
                                {/* <p className='messagetemplate'>{visitorappointment?.template_name}</p> */}
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={smsCheckbox}
                                            onChange={() => setSmsCheckbox(!smsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorappointment?.sms_content}
                                        onChange={(e) => handleVisitorMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={emailCheckbox}
                                            onChange={() => setEmailCheckbox(!emailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorappointment?.email_content}
                                        onChange={(e) => handleVisitorMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={whatsappCheckbox}
                                            onChange={() => setWhatsappCheckbox(!whatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorappointment?.whatsapp_content}
                                        onChange={(e) => handleVisitorMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handleVisitorAppointment({
                                    checkbox: {
                                        sms: smsCheckbox,
                                        email: emailCheckbox,
                                        whatsapp: whatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.otp')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={otpsmsCheckbox}
                                            onChange={() => setOtpSmsCheckbox(!otpsmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={otp?.sms_content}
                                        onChange={(e) => handleOtpMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={otpemailCheckbox}
                                            onChange={() => setOtpEmailCheckbox(!otpemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={otp?.email_content}
                                        onChange={(e) => handleOtpMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={otpwhatsappCheckbox}
                                            onChange={() => setOtpWhatsappCheckbox(!otpwhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={otp?.whatsapp_content}
                                        onChange={(e) => handleOtpMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handleOtp({
                                    checkbox: {
                                        sms: otpsmsCheckbox,
                                        email: otpemailCheckbox,
                                        whatsapp: otpwhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.visitorlogout')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={logsmsCheckbox}
                                            onChange={() => setLogSmsCheckbox(!logsmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorlogout?.sms_content}
                                        onChange={(e) => handleVisitorLogoutMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={logemailCheckbox}
                                            onChange={() => setLogEmailCheckbox(!logemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorlogout?.email_content}
                                        onChange={(e) => handleVisitorLogoutMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={logwhatsappCheckbox}
                                            onChange={() => setLogWhatsappCheckbox(!logwhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorlogout?.whatsapp_content}
                                        onChange={(e) => handleVisitorLogoutMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handleVisitorLogout({
                                    checkbox: {
                                        sms: logsmsCheckbox,
                                        email: logemailCheckbox,
                                        whatsapp: logwhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.hostappointment')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={hostappointmentsmsCheckbox}
                                            onChange={() => setHostAppointmentSmsCheckbox(!hostappointmentsmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={hostappointment?.sms_content}
                                        onChange={(e) => handleHostAppointmentMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={hostappointmentemailCheckbox}
                                            onChange={() => setHostAppointmentEmailCheckbox(!hostappointmentemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={hostappointment?.email_content}
                                        onChange={(e) => handleHostAppointmentMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={hostappointmentwhatsappCheckbox}
                                            onChange={() => setHostAppointmentWhatsappCheckbox(!hostappointmentwhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={hostappointment?.whatsapp_content}
                                        onChange={(e) => handleHostAppointmentMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handlehostappointment({
                                    checkbox: {
                                        sms: hostappointmentsmsCheckbox,
                                        email: hostappointmentemailCheckbox,
                                        whatsapp: hostappointmentwhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.visitorcancelappointment')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={viscancellogoutsmsCheckbox}
                                            onChange={() => setVisCancelLogoutSmsCheckbox(!viscancellogoutsmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorcancelappointment?.sms_content}
                                        onChange={(e) => handlevisitorcancelappointmentMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={viscancellogoutemailCheckbox}
                                            onChange={() => setVisCancelLogoutEmailCheckbox(!viscancellogoutemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorcancelappointment?.email_content}
                                        onChange={(e) => handlevisitorcancelappointmentMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={viscancellogoutwhatsappCheckbox}
                                            onChange={() => setVisCancelLogoutWhatsappCheckbox(!viscancellogoutwhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorcancelappointment?.whatsapp_content}
                                        onChange={(e) => handlevisitorcancelappointmentMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handlevisitorcancelappointment({
                                    checkbox: {
                                        sms: viscancellogoutsmsCheckbox,
                                        email: viscancellogoutemailCheckbox,
                                        whatsapp: viscancellogoutwhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.hostcancelappointment')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={hostcancelappointmentsmsCheckbox}
                                            onChange={() => setHostCancelAppointmentSmsCheckbox(!hostcancelappointmentsmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={hostcancelappointment?.sms_content}
                                        onChange={(e) => handlehostcancelappointmentMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={hostcancelappointmentemailCheckbox}
                                            onChange={() => setHostCancelAppointmentEmailCheckbox(!hostcancelappointmentemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={hostcancelappointment?.email_content}
                                        onChange={(e) => handlehostcancelappointmentMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={hostcancelappointmentwhatsappCheckbox}
                                            onChange={() => setHostCancelAppointmentWhatsappCheckbox(!hostcancelappointmentwhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={hostcancelappointment?.whatsapp_content}
                                        onChange={(e) => handlehostcancelappointmentMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handlehostcancelappointment({
                                    checkbox: {
                                        sms: hostcancelappointmentsmsCheckbox,
                                        email: hostcancelappointmentemailCheckbox,
                                        whatsapp: hostcancelappointmentwhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.visitorreschedule')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={visitorreschedulesmsCheckbox}
                                            onChange={() => setVisitorRescheduleSmsCheckbox(!visitorreschedulesmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorreschedule?.sms_content}
                                        onChange={(e) => handlevisitorrescheduleMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={visitorrescheduleemailCheckbox}
                                            onChange={() => setVisitorRescheduleEmailCheckbox(!visitorrescheduleemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorreschedule?.email_content}
                                        onChange={(e) => handlevisitorrescheduleMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={visitorreschedulewhatsappCheckbox}
                                            onChange={() => setVisitorRescheduleWhatsappCheckbox(!visitorreschedulewhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorreschedule?.whatsapp_content}
                                        onChange={(e) => handlevisitorrescheduleMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handlevisitorreschedule({
                                    checkbox: {
                                        sms: visitorreschedulesmsCheckbox,
                                        email: visitorrescheduleemailCheckbox,
                                        whatsapp: visitorreschedulewhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.hostreschedule')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={hostreschedulesmsCheckbox}
                                            onChange={() => setHostRescheduleSmsCheckbox(!hostreschedulesmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={hostreschedule?.sms_content}
                                        onChange={(e) => handlehostrescheduleMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={hostrescheduleemailCheckbox}
                                            onChange={() => setHostRescheduleEmailCheckbox(!hostrescheduleemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={hostreschedule?.email_content}
                                        onChange={(e) => handlehostrescheduleMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={hostreschedulewhatsappCheckbox}
                                            onChange={() => setHostRescheduleWhatsappCheckbox(!hostreschedulewhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={hostreschedule?.whatsapp_content}
                                        onChange={(e) => handlehostrescheduleMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handlehostreschedule({
                                    checkbox: {
                                        sms: hostreschedulesmsCheckbox,
                                        email: hostrescheduleemailCheckbox,
                                        whatsapp: hostreschedulewhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.visitorarrival')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={visitorarrivalsmsCheckbox}
                                            onChange={() => setVisitorArrivalSmsCheckbox(!visitorarrivalsmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorarrival?.sms_content}
                                        onChange={(e) => handlevisitorarrivalMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={visitorarrivalemailCheckbox}
                                            onChange={() => setVisitorArrivalEmailCheckbox(!visitorarrivalemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorarrival?.email_content}
                                        onChange={(e) => handlevisitorarrivalMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={visitorarrivalwhatsappCheckbox}
                                            onChange={() => setVisitorArrivalWhatsappCheckbox(!visitorarrivalwhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitorarrival?.whatsapp_content}
                                        onChange={(e) => handlevisitorarrivalMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handlevisitorarrival({
                                    checkbox: {
                                        sms: visitorarrivalsmsCheckbox,
                                        email: visitorarrivalemailCheckbox,
                                        whatsapp: visitorarrivalwhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.visitornotlogout')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={visitornotlogoutsmsCheckbox}
                                            onChange={() => setVisitorNotLogoutSmsCheckbox(!visitornotlogoutsmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitornotlogout?.sms_content}
                                        onChange={(e) => handlevisitornotlogoutMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={visitornotlogoutemailCheckbox}
                                            onChange={() => setVisitorNotLogoutEmailCheckbox(!visitornotlogoutemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitornotlogout?.email_content}
                                        onChange={(e) => handlevisitornotlogoutMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={visitornotlogoutwhatsappCheckbox}
                                            onChange={() => setVisitorNotLogoutWhatsappCheckbox(!visitornotlogoutwhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitornotlogout?.whatsapp_content}
                                        onChange={(e) => handlevisitornotlogoutMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handlevisitornotlogout({
                                    checkbox: {
                                        sms: visitornotlogoutsmsCheckbox,
                                        email: visitornotlogoutemailCheckbox,
                                        whatsapp: visitornotlogoutwhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.employeecredential')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={employeecredentialssmsCheckbox}
                                            onChange={() => setEmployeeCredentialsSmsCheckbox(!employeecredentialssmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={employeecredentials?.sms_content}
                                        onChange={(e) => handleemployeecredentialsMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={employeecredentialsemailCheckbox}
                                            onChange={() => setEmployeeCredentialsEmailCheckbox(!employeecredentialsemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={employeecredentials?.email_content}
                                        onChange={(e) => handleemployeecredentialsMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={employeecredentialswhatsappCheckbox}
                                            onChange={() => setEmployeeCredentialsWhatsappCheckbox(!employeecredentialswhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={employeecredentials?.whatsapp_content}
                                        onChange={(e) => handleemployeecredentialsMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handleemployeecredentials({
                                    checkbox: {
                                        sms: employeecredentialssmsCheckbox,
                                        email: employeecredentialsemailCheckbox,
                                        whatsapp: employeecredentialswhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.guestarrival')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={guestarrivalsmsCheckbox}
                                            onChange={() => setGuestArrivalSmsCheckbox(!guestarrivalsmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={guestarrival?.sms_content}
                                        onChange={(e) => handleguestarrivalMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={guestarrivalemailCheckbox}
                                            onChange={() => setGuestArrivalEmailCheckbox(!guestarrivalemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={guestarrival?.email_content}
                                        onChange={(e) => handleguestarrivalMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={guestarrivalwhatsappCheckbox}
                                            onChange={() => setGuestArrivalWhatsappCheckbox(!guestarrivalwhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={guestarrival?.whatsapp_content}
                                        onChange={(e) => handleguestarrivalMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handleguestarrival({
                                    checkbox: {
                                        sms: guestarrivalsmsCheckbox,
                                        email: guestarrivalemailCheckbox,
                                        whatsapp: guestarrivalwhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.eventbookingconfirmation')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={eventbookingconfirmationsmsCheckbox}
                                            onChange={() => setEventBookingConfirmationSmsCheckbox(!eventbookingconfirmationsmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={eventbookingconfirmation?.sms_content}
                                        onChange={(e) => handleeventbookingconfirmationMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={eventbookingconfirmationemailCheckbox}
                                            onChange={() => setEventBookingConfirmationEmailCheckbox(!eventbookingconfirmationemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={eventbookingconfirmation?.email_content}
                                        onChange={(e) => handleeventbookingconfirmationMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={eventbookingconfirmationwhatsappCheckbox}
                                            onChange={() => setEventBookingConfirmationWhatsappCheckbox(!eventbookingconfirmationwhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={eventbookingconfirmation?.whatsapp_content}
                                        onChange={(e) => handleeventbookingconfirmationMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handleeventbookingconfirmation({
                                    checkbox: {
                                        sms: eventbookingconfirmationsmsCheckbox,
                                        email: eventbookingconfirmationemailCheckbox,
                                        whatsapp: eventbookingconfirmationwhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.eventcancelconfirmation')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={eventcancellationnotificationsmsCheckbox}
                                            onChange={() => setEventCancellationNotificationSmsCheckbox(!eventcancellationnotificationsmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={eventcancellationnotification?.sms_content}
                                        onChange={(e) => handleeventcancellationnotificationMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={eventcancellationnotificationemailCheckbox}
                                            onChange={() => setEventCancellationNotificationEmailCheckbox(!eventcancellationnotificationemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={eventcancellationnotification?.email_content}
                                        onChange={(e) => handleeventcancellationnotificationMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={eventcancellationnotificationwhatsappCheckbox}
                                            onChange={() => setEventCancellationNotificationWhatsappCheckbox(!eventcancellationnotificationwhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={eventcancellationnotification?.whatsapp_content}
                                        onChange={(e) => handleeventcancellationnotificationMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handleeventcancellationnotification({
                                    checkbox: {
                                        sms: eventcancellationnotificationsmsCheckbox,
                                        email: eventcancellationnotificationemailCheckbox,
                                        whatsapp: eventcancellationnotificationwhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.notification5')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification5smsCheckbox}
                                            onChange={() => setNotification5SmsCheckbox(!notification5smsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitnotification5?.sms_content}
                                        onChange={(e) => handlenotification5MsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification5emailCheckbox}
                                            onChange={() => setNotification5EmailCheckbox(!notification5emailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitnotification5?.email_content}
                                        onChange={(e) => handlenotification5MsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification5whatsappCheckbox}
                                            onChange={() => setNotification5WhatsappCheckbox(!notification5whatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitnotification5?.whatsapp_content}
                                        onChange={(e) => handlenotification5MsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handlenotification5({
                                    checkbox: {
                                        sms: notification5smsCheckbox,
                                        email: notification5emailCheckbox,
                                        whatsapp: notification5whatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.notification6')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification6smsCheckbox}
                                            onChange={() => setNotification6SmsCheckbox(!notification6smsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitnotification6?.sms_content}
                                        onChange={(e) => handlenotification6MsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification6emailCheckbox}
                                            onChange={() => setNotification6EmailCheckbox(!notification6emailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitnotification6?.email_content}
                                        onChange={(e) => handlenotification6MsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification6whatsappCheckbox}
                                            onChange={() => setNotification6WhatsappCheckbox(!notification6whatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitnotification6?.whatsapp_content}
                                        onChange={(e) => handlenotification6MsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handlenotification6({
                                    checkbox: {
                                        sms: notification6smsCheckbox,
                                        email: notification6emailCheckbox,
                                        whatsapp: notification6whatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.notification6admin')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification6adminsmsCheckbox}
                                            onChange={() => setNotification6AdminSmsCheckbox(!notification6adminsmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitnotification6admin?.sms_content}
                                        onChange={(e) => handlenotification6adminMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification6adminemailCheckbox}
                                            onChange={() => setNotification6AdminEmailCheckbox(!notification6adminemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitnotification6admin?.email_content}
                                        onChange={(e) => handlenotification6adminMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification6adminwhatsappCheckbox}
                                            onChange={() => setNotification6AdminWhatsappCheckbox(!notification6adminwhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitnotification6admin?.whatsapp_content}
                                        onChange={(e) => handlenotification6adminMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handlenotification6admin({
                                    checkbox: {
                                        sms: notification6adminsmsCheckbox,
                                        email: notification6adminemailCheckbox,
                                        whatsapp: notification6adminwhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.notification7')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification7smsCheckbox}
                                            onChange={() => setNotification7SmsCheckbox(!notification7smsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitnotification7?.sms_content}
                                        onChange={(e) => handlenotification7MsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification7emailCheckbox}
                                            onChange={() => setNotification7EmailCheckbox(!notification7emailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitnotification7?.email_content}
                                        onChange={(e) => handlenotification7MsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification7whatsappCheckbox}
                                            onChange={() => setNotification7WhatsappCheckbox(!notification7whatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={visitnotification7?.whatsapp_content}
                                        onChange={(e) => handlenotification7MsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handlenotification7({
                                    checkbox: {
                                        sms: notification7smsCheckbox,
                                        email: notification7emailCheckbox,
                                        whatsapp: notification7whatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.notification6hour')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification6hoursmsCheckbox}
                                            onChange={() => setNotification6HourSmsCheckbox(!notification6hoursmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={notification6hour?.sms_content}
                                        onChange={(e) => handlenotification6hourMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification6houremailCheckbox}
                                            onChange={() => setNotification6HourEmailCheckbox(!notification6houremailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={notification6hour?.email_content}
                                        onChange={(e) => handlenotification6hourMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={notification6hourwhatsappCheckbox}
                                            onChange={() => setNotification6HourWhatsappCheckbox(!notification6hourwhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={notification6hour?.whatsapp_content}
                                        onChange={(e) => handlenotification6hourMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handlenotification6hour({
                                    checkbox: {
                                        sms: notification6hoursmsCheckbox,
                                        email: notification6houremailCheckbox,
                                        whatsapp: notification6hourwhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                        <div className="card-body pt-0">
                            <div className='row g-2 mb-3'>
                                <p className='messagetemplate'>{t('messagetemplate.guestlogout')}</p>
                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={guestlogoutsmsCheckbox}
                                            onChange={() => setGuestLogoutSmsCheckbox(!guestlogoutsmsCheckbox)}
                                        />  <span>{t('messagetemplate.sms')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={guestLogout?.sms_content}
                                        onChange={(e) => handleguestlogoutMsgChange(e)}
                                        msgType="sms_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={guestlogoutemailCheckbox}
                                            onChange={() => setGuestLogoutEmailCheckbox(!guestlogoutemailCheckbox)}
                                        />  <span>{t('messagetemplate.email')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={guestLogout?.email_content}
                                        onChange={(e) => handleguestlogoutMsgChange(e)}
                                        msgType="email_content"
                                    />
                                </div>

                                <div className='col-xl-4 col-lg-4 col-xs-12'>
                                    <div className='mb-2'>
                                        <input type='checkbox'
                                            className="form-check-input"
                                            checked={guestlogoutwhatsappCheckbox}
                                            onChange={() => setGuestLogoutWhatsappCheckbox(!guestlogoutwhatsappCheckbox)}
                                        />  <span>{t('messagetemplate.whatsapp')}</span>
                                    </div>
                                    <CommonEditor
                                        initialValue={guestLogout?.whatsapp_content}
                                        onChange={(e) => handleguestlogoutMsgChange(e)}
                                        msgType="whatsapp_content"
                                    />
                                </div>

                            </div>
                            <div className="mb-3 text-end">
                                <button type="submit" className="btn btn-primary me-1" onClick={() => handleguestlogout({
                                    checkbox: {
                                        sms: guestlogoutsmsCheckbox,
                                        email: guestlogoutemailCheckbox,
                                        whatsapp: guestlogoutwhatsappCheckbox,
                                    },
                                })}>
                                    {t('messagetemplate.btn')}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}