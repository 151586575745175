import React, { useState, useEffect, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';
import ReactTable from 'components/common/ReactTable';
import { useForm, Controller } from 'react-hook-form';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactDatePicker from 'components/common/ReactDatePicker';
import Dropdown from 'react-bootstrap/Dropdown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import OutsideClickHandler from 'react-outside-click-handler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EventsnestedTable from './EventsnestedTable';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import '../report.css'
import { getlocation } from 'services/manageEventsService'
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { loaderContext } from 'context/Context';
import { eventsreportlist } from 'services/reportsService'
import moment from 'moment';




function EventsReport() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [eventdata, setEventData] = useState([]);
    const [eventOption, setEventOption] = useState([]);
    const [eventstatusOption, setEventStatusOption] = useState([{ label: "Pending", value: 0 }, { label: "Approved", value: 1 }, { label: "Decline", value: 2 }]);
    // const [dateRange, setDateRange] = useState([null, null]);
    // const [startDate, endDate] = dateRange;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isOpen, setIsOpen] = useState({ id: null, open: false });


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        watch
    } = useForm();

    useEffect(async () => {
        checkModulePermission("Events Report")
        setIsLoading(true)
        try {
            const { data } = await getlocation()
            const { data: event } = await eventsreportlist({})
            setEventOption(data?.data?.map((event) => ({ label: event?.name, value: event?.id })))
            setEventData(event?.data)
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }, [])




    const columns = [

        {
            Header: t('eventsreport.eventreporttable.eventname'),
            accessor: 'event_name',
            Cell: ({ row }) => {
                return row?.original?.event_name ? row?.original?.event_name : "--"
            }
        },
        {
            Header: t('eventsreport.eventreporttable.membername'),
            accessor: 'member_name',
            Cell: ({ row }) => {
                return row?.original?.member_name ? row?.original?.member_name : "--"
            }
        },
        {
            Header: t('eventsreport.eventreporttable.memberno'),
            accessor: 'member_number',
            Cell: ({ row }) => {
                return row?.original?.member_number ? row?.original?.member_number : "--"
            }
        },
        {
            Header: t('eventsreport.eventreporttable.eventlocation'),
            accessor: 'event_location',
            Cell: ({ row }) => {
                return row?.original?.event_location ? row?.original?.event_location : "--"
            }
        },
        {
            Header: t('eventsreport.eventreporttable.date'),
            accessor: 'date',
            Cell: ({ row }) => {
                return row?.original?.date ? moment.utc(row?.original?.date).local().format(process.env.REACT_APP_FILTER_DATE) : "--"
            }
        },
        {
            Header: t('eventsreport.eventreporttable.starttime'),
            accessor: 'start_time',
            Cell: ({ row }) => {
                return row?.original?.start_time ? moment.utc(row?.original?.start_time, "HH:mm:ss").local().format(process.env.REACT_APP_TIME_FORMAT_24) : "--"
            },
        },
        {
            Header: t('eventsreport.eventreporttable.action'),
            className: 'text-end',
            isExpand: true,
            Cell: ({ row }) => {

                return (
                    <div >
                        <span
                            className="btn p-0 me-2"
                            onClick={() => {
                                setIsOpen({ id: row.original.id, open: true });
                            }}
                        >
                            <FontAwesomeIcon icon="ellipsis-h" />
                        </span>
                        {
                            (isOpen.open && row.original.id == isOpen.id) &&
                            <OutsideClickHandler onOutsideClick={() => setIsOpen({ id: row.original.id, open: false })}>
                                <div className='card position-absolute p-2' style={{ right: "6%", minWidth: "15%", zIndex: "999", boxShadow: "0 0 6px 0 rgba(0,0,0,0.45) inset" }}>
                                    <div className='px-3' style={{ cursor: "pointer" }}  {...row.getToggleRowExpandedProps()}>
                                        <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{t('eventsreport.viewguests')}</span>
                                    </div>
                                </div >
                            </OutsideClickHandler>
                        }
                    </div>
                );
            },
            // Cell: ({ row }) => {
            //     const [isOpen, setIsOpen] = useState(false);
            //     return (<div>
            //         <span
            //             className="btn p-0 me-2"
            //             // title="Edit"
            //             onClick={() => {
            //                 setIsOpen(!isOpen);
            //                 setOpenRowId(row.original.id);
            //             }}
            //         // onClick={() => setIsOpen(!isOpen)}
            //         >
            //             <FontAwesomeIcon icon="ellipsis-h" />
            //         </span>
            //         {
            //             isOpen &&
            //             <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
            //                 <div className='card position-absolute p-2' style={{ right: "6%", minWidth: "15%", zIndex: "999", boxShadow: "0 0 6px 0 rgba(0,0,0,0.45) inset" }}>
            //                     <div className='px-3' style={{ cursor: "pointer" }}  {...row.getToggleRowExpandedProps()}>
            //                         <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{t('eventsreport.viewguests')}</span>
            //                     </div>
            //                 </div >
            //             </OutsideClickHandler>
            //         }
            //     </div >)
            // },

            disableSortBy: true
        },

    ];

    const onSubmit = async (eventdata) => {
        console.log("eventdata", eventdata)
        setIsLoading(true)

        if (eventdata?.event_status || eventdata?.location_id || (startDate && endDate)) {
            try {
                eventdata.event_status = eventdata.event_status?.value
                eventdata.location_id = eventdata.location_id?.value
                if (startDate && endDate) {
                    eventdata.date_range = `${moment(startDate).format(process.env.REACT_APP_FILTER_DATE)}-${moment(endDate).format(process.env.REACT_APP_FILTER_DATE)}`;
                }
                const { data } = await eventsreportlist(eventdata)
                setEventData(data?.data)
            } catch (e) {
                if (e.response.data) {
                    if (Array.isArray(e.response.data.message)) {
                        e.response.data.message.map(errors => toast.error(errors, toastConfig))
                    } else {
                        toast.error(e.response.data.message, toastConfig)
                    }
                } else {
                    toast.error(e.message, toastConfig)
                }
            }
        }
        setIsLoading(false)


    };


    // const downloadPDF = () => {
    //     const selectedRowData = eventdata?.find(row => row.id == isOpen.id);
    //     if (selectedRowData) {
    //         const docDefinition = {
    //             pageOrientation: 'landscape',
    //             pageSize: { width: 800, height: 600 },
    //             content: [
    //                 { text: 'User Data', style: 'header' },
    //                 { text: '\n' },
    //                 { text: 'event table', style: 'tableHeading' },
    //                 {
    //                     table: {
    //                         headerRows: 1,
    //                         widths: Array.from({ length: columns.length }, () => 'auto'),
    //                         body: [
    //                             columns.map((column) => column.Header),
    //                             ...eventdata.map((row) => columns.map((column) => {
    //                                 if (column.accessor === 'start_time') {
    //                                     return row[column.accessor] && row[column.accessor] != "" ? moment.utc(row[column.accessor], "HH:mm:ss").local().format(process.env.REACT_APP_TIME_FORMAT_24) : '--';
    //                                 } else {
    //                                     return row[column.accessor] !== undefined ? row[column.accessor] : '';
    //                                 }

    //                             })),

    //                         ],
    //                     },
    //                 },

    //                 { text: '\n' },
    //                 { text: 'guest_list', style: 'tableHeading' },
    //                 {
    //                     table: {
    //                         headerRows: 1,
    //                         widths: Array.from({ length: subColumns.length }, () => 'auto'),
    //                         body: [
    //                             subColumns.map((column) => column.Header),
    //                             ...selectedRowData.guest_list?.map((row) => subColumns.map((column) => {
    //                                 if (column.accessor === 'time_in' || column.accessor === 'time_out') {
    //                                     return row[column.accessor] && row[column.accessor] !== "" ? moment.utc(row[column.accessor]).local().format(process.env.REACT_APP_TIME_FORMAT_24) : '--';
    //                                 } else if (column.accessor === 'phone_number') {
    //                                     const phoneNumber = row['phone_number'];
    //                                     const countryCode = row['country_code'];
    //                                     return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
    //                                 } else if (column.accessor === 'status') {
    //                                     return row[column.accessor] === 1 ? 'Arrived' :
    //                                         row[column.accessor] === 0 ? 'Not Arrived' : '';
    //                                 } else {
    //                                     return row[column.accessor] !== undefined ? row[column.accessor] : '';
    //                                 }
    //                             })),
    //                         ],
    //                     },
    //                 },

    //             ],
    //             defaultStyle: {
    //                 actionHeader: false,
    //             },
    //             styles: {
    //                 header: {
    //                     fontSize: 18,
    //                     bold: true,
    //                 },
    //             },
    //         };


    //         const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    //         pdfDocGenerator.download('event_data.pdf');
    //     } else {
    //         const docDefinition = {
    //             pageOrientation: 'landscape',
    //             pageSize: { width: 800, height: 600 },
    //             content: [
    //                 { text: 'User Data', style: 'header' },
    //                 { text: '\n' },
    //                 { text: 'event table', style: 'tableHeading' },
    //                 {
    //                     table: {
    //                         headerRows: 1,
    //                         widths: Array.from({ length: columns.length }, () => 'auto'),
    //                         body: [
    //                             columns.map((column) => column.Header),
    //                             ...eventdata.map((row) => columns.map((column) => {
    //                                 if (column.accessor === 'start_time') {
    //                                     return row[column.accessor] && row[column.accessor] != "" ? moment.utc(row[column.accessor], "HH:mm:ss").local().format(process.env.REACT_APP_TIME_FORMAT_24) : '--';
    //                                 } else {
    //                                     return row[column.accessor] !== undefined ? row[column.accessor] : '';
    //                                 }
    //                                 // return row[column.accessor] !== undefined ? row[column.accessor] : '';
    //                             })),
    //                         ],
    //                     },
    //                 },

    //                 { text: '\n' },
    //             ],
    //             defaultStyle: {
    //                 actionHeader: false,
    //             },
    //             styles: {
    //                 header: {
    //                     fontSize: 18,
    //                     bold: true,
    //                 },
    //             },
    //         };


    //         const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    //         pdfDocGenerator.download('event_data.pdf');
    //     }
    // };




    const downloadPDF = () => {
        const docDefinition = {
            pageOrientation: 'landscape',
            pageSize: { width: 800, height: 600 },
            content: [
                { text: 'User Data', style: 'header' },
                { text: '\n' }
            ],
            defaultStyle: {
                actionHeader: false,
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                },
                tableHeading: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 10]
                }
            },
        };

        eventdata.forEach((mainRow, index) => {
            const mainRowContent = [
                { text: `Event - ${index + 1}`, style: 'tableHeading' },
                {
                    table: {
                        headerRows: 1,
                        widths: Array.from({ length: columns.length }, () => 'auto'),
                        body: [
                            columns.map((column) => column.Header),
                            columns.map((column) => {
                                if (column.accessor === 'start_time') {
                                    return mainRow[column.accessor] && mainRow[column.accessor] !== "" ? moment.utc(mainRow[column.accessor], "HH:mm:ss").local().format(process.env.REACT_APP_TIME_FORMAT_24) : '--';
                                } else {
                                    return mainRow[column.accessor] !== undefined ? mainRow[column.accessor] : '';
                                }
                            }),
                        ],
                    },
                },
                { text: '\n' },
                { text: 'Guest List', style: 'tableHeading' },
                {
                    table: {
                        headerRows: 1,
                        widths: Array.from({ length: subColumns.length }, () => 'auto'),
                        body: [
                            subColumns.map((column) => column.Header),
                            ...(mainRow.guest_list || []).map((nestedRow) => subColumns.map((column) => {
                                if (column.accessor === 'time_in' || column.accessor === 'time_out') {
                                    return nestedRow[column.accessor] && nestedRow[column.accessor] !== "" ? moment.utc(nestedRow[column.accessor]).local().format(process.env.REACT_APP_TIME_FORMAT_24) : '--';
                                } else if (column.accessor === 'phone_number') {
                                    const phoneNumber = nestedRow['phone_number'];
                                    const countryCode = nestedRow['country_code'];
                                    return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
                                } else if (column.accessor === 'status') {
                                    return nestedRow[column.accessor] === 1 ? 'Arrived' :
                                        nestedRow[column.accessor] === 0 ? 'Not Arrived' : '';
                                } else {
                                    return nestedRow[column.accessor] !== undefined ? nestedRow[column.accessor] : '';
                                }
                            })),
                        ],
                    },
                },
                { text: '\n' },
                { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 700, y2: 0, lineWidth: 1 }] } // Add a line here
            ];
            docDefinition.content.push(...mainRowContent);
        });

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.download('event_data.pdf');
    };



    const downloadCSV = () => {
        let csvMainData = [
            columns.map((column) => column.Header)
        ];

        eventdata.forEach((row) => {
            const rowData = columns.map((column) => {
                if (column.accessor === 'start_time') {
                    return row[column.accessor] && row[column.accessor] !== "" ? moment.utc(row[column.accessor], "HH:mm:ss").local().format(process.env.REACT_APP_TIME_FORMAT_24) : '--';
                } else {
                    return row[column.accessor] !== undefined ? row[column.accessor] : '';
                }
            });

            csvMainData.push(rowData);

            if (row.guest_list && row.guest_list.length > 0) {
                const nestedHeader = subColumns.map((subColumn) => {
                    if (subColumn.Header != t('eventsreport.eventsnestedtable.no')) return subColumn.Header;
                });
                csvMainData.push(nestedHeader);

                row.guest_list.forEach((guest) => {
                    const nestedRowData = subColumns.map((subColumn) => {
                        if (subColumn.accessor === 'time_in' || subColumn.accessor === 'time_out') {
                            return guest[subColumn.accessor] && guest[subColumn.accessor] !== "" ? moment.utc(guest[subColumn.accessor]).local().format(process.env.REACT_APP_TIME_FORMAT_24) : '--';
                        } else if (subColumn.accessor === 'phone_number') {
                            const phoneNumber = guest['phone_number'];
                            const countryCode = guest['country_code'];
                            return phoneNumber && phoneNumber !== "" ? `${countryCode} ${phoneNumber}` : '--';
                        } else if (subColumn.accessor === 'status') {
                            return guest[subColumn.accessor] === 1 ? 'Arrived' : guest[subColumn.accessor] === 0 ? 'Not Arrived' : '';
                        } else {
                            return guest[subColumn.accessor] !== undefined ? guest[subColumn.accessor] : '';
                        }
                    });
                    csvMainData.push(nestedRowData);
                });
            }

            csvMainData.push([]);
        });

        const csvLines = csvMainData.map((row) => row.join(',')).join('\n');

        const blob = new Blob([csvLines], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'events_data.csv');
    };

    const subColumns = [

        {
            Header: t('eventsreport.eventsnestedtable.no'),
            accessor: (row, index) => index + 1
        },
        {
            Header: t('eventsreport.eventsnestedtable.guestname'),
            accessor: 'name',
            Cell: ({ row }) => {
                return row?.original?.name ? row?.original?.name : "--"
            }
        },
        {
            Header: t('eventsreport.eventsnestedtable.guestidno'),
            accessor: 'document_id_number',
            Cell: ({ row }) => {
                return row?.original?.document_id_number ? row?.original?.document_id_number : "--"
            }
        },
        {
            Header: t('eventsreport.eventsnestedtable.guestmobileno'),
            accessor: 'phone_number',
            Cell: ({ row }) => {
                return row?.original?.phone_number ? `${row?.original?.country_code}-${row?.original?.phone_number}` : '--'
            }
        },
        {
            Header: t('eventsreport.eventsnestedtable.guestemail'),
            accessor: 'email',
            Cell: ({ row }) => {
                return row?.original?.email ? row?.original?.email : "--"
            }
        },
        {
            Header: t('eventsreport.eventsnestedtable.vehicleno'),
            accessor: 'vehicle_reg_no',
            Cell: ({ row }) => {
                return row?.original?.vehicle_reg_no ? row?.original?.vehicle_reg_no : "--"
            }
        },
        {
            Header: t('eventsreport.eventsnestedtable.timein'),
            accessor: 'time_in',
            Cell: ({ row }) => {
                return row?.original?.time_in ? moment.utc(row?.original?.time_in).local().format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : "--"
            }
        },
        {
            Header: t('eventsreport.eventsnestedtable.timeout'),
            accessor: 'time_out',
            Cell: ({ row }) => {
                return row?.original?.time_out ? moment.utc(row?.original?.time_out).local().format(process.env.REACT_APP_SET_DATE_TIME_FORMAT) : "--"
            }
        },
        {
            Header: t('eventsreport.eventsnestedtable.action'),
            accessor: 'status',
            className: 'text-end',
            Cell: ({ row }) => (
                <>
                    {row.original.status === 1 ? (
                        <div className='text-center' style={{ backgroundColor: "#81C784", border: '1px solid #81C784', borderRadius: "3px", color: "#2E7D32", fontWeight: "bold", margin: "0rem 0rem" }}> {t('eventsreport.eventsnestedtable.arrived')}</div>
                    ) : <div className='text-center'
                        style={{
                            backgroundColor: "#E0E0E0",
                            color: "#424242",
                            border: "1px solid #E0E0E0",
                            borderRadius: "3px"
                        }}
                    >
                        {t('eventsreport.eventsnestedtable.notarrived')}
                    </div>}
                </>
            ),
        },

    ];



    const renderRowSubComponent = React.useCallback(({ row }) => {
        const newGuestData = row.original?.guest_list
        return (
            <div className="bg-img card nested-table-body overflow-hidden" >
                <ReactTable
                    columns={subColumns}
                    data={newGuestData ? newGuestData : []}
                    headerClass="btn-primary"
                    innerTableSearch="innertable"
                    hedingName="nestedtable.tableheading"
                    showHeader={false}
                    ispaginationvisible={true}

                />
            </div>
        )
    }, [])

    return (
        <div className="row g-3 mb-3">
            {/* <div className=""> */}
            <div className="card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-9 data-table" style={{ height: "fit-content" }}>
                <div className="card-header">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-4 heading-text">
                            <h3 className="heading-color fw-600 mb-0">
                                {t('eventsreport.heading')}
                            </h3>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-8 table-btn" >
                            <button
                                className="btn btn-primary btn-sm float-end"
                                onClick={() => downloadPDF()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline">
                                    {t('eventsreport.exportpdfbtn')}
                                </span>
                            </button>

                            <button
                                className="btn mx-1 btn-primary btn-sm float-end"
                                onClick={() => downloadCSV()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline">
                                    {t('eventsreport.exportcsvbtn')}
                                </span>
                            </button>
                        </div>

                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <ReactTable
                        columns={columns}
                        data={eventdata}
                        pageSizeOption={[5, 10, 15, 20]}
                        headerClass="btn-primary"
                        isSearchBoxVisible
                        RenderRowSubComponent={
                            renderRowSubComponent
                        }
                        ispaginationvisible={true}
                    />
                </div>
            </div>


            <div className='card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-2 filter-table' style={{ padding: "0rem .8rem 0rem .8rem", height: "fit-content" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row' style={{ backgroundColor: "rgb(247, 247, 247)", paddingTop: ".8rem", height: "3rem", color: "black", marginBottom: '1rem' }}>
                        <span>{t('eventsreport.filterheading')}</span>
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('eventsreport.filterform.eventlocation')}
                        </label>
                        <Controller
                            name="location_id"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={eventOption}
                                    hasError={errors.event_location}
                                // placeholder={t('suppliercontractor.createsupplierplaceholder.selectcompanytype')}
                                />
                            )}
                        />
                        {/* {errors.event_location && (
                            <small>
                                {errors.event_location.type === 'required' && (
                                    <span className="text-danger">
                                        {t('eventsreport.error.eventlocationerror')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>
                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('eventsreport.filterform.eventstatus')}
                        </label>
                        <Controller
                            name="event_status"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={eventstatusOption}
                                    hasError={errors.event_status}
                                    placeholder={t('eventsreport.placeholder.eventstatus')}
                                />
                            )}
                        />
                        {/* {errors.event_status && (
                            <small>
                                {errors.event_status.type === 'required' && (
                                    <span className="text-danger">
                                        {t('eventsreport.error.eventstatuserror')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>
                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('eventsreport.filterform.daterange')}
                        </label>
                        <Controller
                            name="date_range"
                            control={control}
                            // rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <>
                                    {/* <ReactDatePicker
                                    value={value}
                                    // onChange={onChange}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    placeholder={t(
                                        'eventsreport.placeholder.date'
                                    )}
                                    hasErrors={errors.date_range}
                                    dateFormat="dd-MM-yyyy"
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange={true}
                                // isClearable={true}
                                /> */}

                                    <ReactDatePicker
                                        selected={startDate}
                                        value={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        placeholder={t('summaryreport.placeholder.startdate')}
                                        dateFormat="dd/MM/yyyy"
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxDate={endDate || undefined}
                                    />
                                    {/* <span className="mx-2">-</span> */}
                                    <br />
                                    <ReactDatePicker
                                        selected={endDate}
                                        value={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        placeholder={t('summaryreport.placeholder.enddate')}
                                        dateFormat="dd/MM/yyyy"
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate || undefined}
                                    />
                                </>
                            )}
                        />
                        {/* {errors.date && (
                            <small>
                                {errors.date.type === 'required' && (
                                    <span className="text-danger">
                                        {t('eventsreport.error.dateerror')}
                                    </span>
                                )}
                            </small>
                        )} */}
                    </div>

                    <div className="text-start col-12">
                        <div className="mb-3 mt-4 text-start">
                            <button type="submit" className="col-lg-12 btn btn-primary me-1">
                                {t('eventsreport.filterform.filterbtn')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {/* </div> */}
        </div>
    )
}

export default EventsReport