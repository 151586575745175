import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import avatar from 'assets/img/team/avatar.png';
import { useTranslation } from 'react-i18next';
import { logout } from 'services/authenticationService'
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { windows } from 'is_js';
import { useContext } from 'react';
import { loaderContext } from 'context/Context';

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [userName, setUserName] = useState("");
  const userData = localStorage.getItem("userName");
  const { isLoading, setIsLoading } = useContext(loaderContext)

  useEffect(() => {
    if (userData) {
      setUserName(userData)
    }
  }, [])

  const logOut = async () => {
    setIsLoading(true)
    try {
      const { data } = await logout()
      toast.success(data?.message, toastConfig)
      localStorage.removeItem('authToken');
      localStorage.removeItem('userName');
      localStorage.removeItem('userPrmission');
      navigation('/login', { replace: true });
      // window.location.reload()
    } catch (e) {
      errorMessage(e)
    }
    setIsLoading(false)
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <div className="d-flex align-items-center">
          <Avatar src={userData && userData.profile && userData.profile != "" ? userData.profile : avatar} />
          <span className="ms-2 d-none d-sm-block fw-600 profile-text">{userName && userName}</span>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {/* <Dropdown.Item as={Link} to="/user/profile">
            {t('navbarMenu.profileDropdown.profile')}
          </Dropdown.Item> */}
          <Dropdown.Item onClick={logOut}>{t('navbarMenu.profileDropdown.logout')}</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
