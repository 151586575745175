import React from 'react';
import { useTranslation } from 'react-i18next';
import '../messagetemplate/message.css'


const MessageTemplateTable = () => {
    const { t } = useTranslation();
    return (
        <div>
            <h2 className='heading-text'>{t('messagetemplate.text.heading')}</h2>
            <table border="1" className='mes-table'>
                <thead>
                    <tr>
                        <th className='mes-th'>{t('messagetemplate.text.variable')}</th>
                        <th className='mes-th'>{t('messagetemplate.text.description')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.visitor')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.descvisitor')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.hostname')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.deschostname')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.date')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.descdate')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.time')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.desctime')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.otp')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.descotp')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.company')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.desccompany')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.appoint')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.descappoint')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.username')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.descusername')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.employee')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.descemployee')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.guestname')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.descguestname')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.membername')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.descmembername')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.eventname')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.desceventname')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.datetime')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.descdatetime')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.destination')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.descdestination')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.count')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.desccount')}</td>
                    </tr>
                    <tr>
                        <td className='mes-td'>{t('messagetemplate.text.visitorname')}</td>
                        <td className='mes-td'>{t('messagetemplate.text.descvisitorname')}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default MessageTemplateTable;