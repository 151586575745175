export const dashboard = {
  label: 'navbarMenu.parent.dashboard',
  labelDisable: true,
  children: [
    {
      name: 'navbarMenu.parent.dashboard',
      to: '/dashboard',
      active: true,
    }
  ]
};
export const navbarmenu = {
  label: 'navbarmenu.parent.masters',
  labelDisable: true,
  children: [
    {
      name: 'navbarMenu.parent.masters',
      active: true,
      children: [
        // {
        //   name: 'navbarMenu.master.towerMaster',
        //   to: '/master/tower-master',
        //   active: true
        // },
        // {
        //   name: 'Tower Master 2',
        //   to: '/master/create-tower-2',
        //   active: true
        // },
        {
          name: 'navbarMenu.masters.department',
          to: '/masters/department-master',
          active: true
        },
        {
          name: 'navbarMenu.masters.userroles',
          to: '/masters/user-roles',
          active: true
        },
        {
          name: 'navbarMenu.masters.accessmapping',
          to: '/masters/access-mapping',
          active: true
        },
        {
          name: 'navbarMenu.masters.accesscontrol',
          to: '/masters/access-control',
          active: true
        },
        // {
        //   name: 'navbarMenu.master.deviceMaster',
        //   to: '/master/device-master',
        //   active: true
        // },
        // {
        //   name: 'navbarMenu.master.cameraMaster',
        //   to: '/master/ip-camera-master',
        //   active: true
        // },
        // {
        //   name: 'navbarMenu.master.passTemplate',
        //   to: '/master/pass-template',
        //   active: true
        // },
        // {
        //   name: 'navbarMenu.master.accessControl',
        //   to: '/master/access-control',
        //   active: true
        // },
        // {
        //   name: 'navbarMenu.master.assetMaster',
        //   to: '/master/asset-master',
        //   active: true
        // }
      ]
    },
    {
      name: 'navbarMenu.parent.admin',
      active: true,
      children: [
        {
          name: 'navbarMenu.admin.systemusers',
          to: '/admin/system-users',
          active: true
        },
        {
          name: 'navbarMenu.admin.backupmanagement',
          to: '/admin/backup-management',
          active: true
        },
        {
          name: 'navbarMenu.admin.messagetemplate',
          to: '/admin/message-template',
          active: true
        },
        // {
        //   name: 'navbarMenu.admin.broadcastmessage',
        //   to: '/admin/broadcast-message',
        //   active: true
        // },
        {
          name: 'navbarMenu.admin.sosmessage',
          to: '/admin/sos-message',
          active: true
        },
        {
          name: 'navbarMenu.admin.blacklistvisitor',
          to: '/admin/listblack-visitor',
          active: true
        },
        {
          name: 'navbarMenu.admin.suppliercontractor',
          to: '/admin/supplier-contractor',
          active: true
        },
        {
          name: 'navbarMenu.admin.casualworkers',
          to: '/admin/casual-workers',
          active: true
        },
        {
          name: 'navbarMenu.admin.members',
          to: '/admin/members',
          active: true
        },
        {
          name: 'navbarMenu.admin.aide',
          to: '/admin/aide-masters',
          active: true
        }
      ]
    },
    {
      name: 'navbarMenu.parent.manageevents',
      active: true,
      children: [
        {
          name: 'navbarMenu.manageevents.bookaguest',
          to: '/manageevents/book-guest',
          active: true
        },
        {
          name: 'navbarMenu.manageevents.createevent',
          to: '/manageevents/create-event',
          active: true
        },
        {
          name: 'navbarMenu.manageevents.eventapproval',
          to: '/manageevents/event-approval',
          active: true
        },
        {
          name: 'navbarMenu.manageevents.eventlocations',
          to: '/manageevents/event-locations',
          active: true
        },
        {
          name: 'navbarMenu.manageevents.eventbooking',
          to: '/manageevents/event-booking',
          active: true
        },


        // {
        //   name: 'navbarMenu.reports.visits',
        //   to: '/report/visits',
        //   active: true
        // },
        // {
        //   name: 'navbarMenu.reports.appointments',
        //   to: '/report/appointments',
        //   active: true
        // },
        // {
        //   name: 'navbarMenu.reports.blacklist',
        //   to: '/report/blacklist',
        //   active: true
        // },

        // {
        //   name: 'navbarMenu.reports.systemUser',
        //   to: '/report/system-user',
        //   active: true
        // },
        // {
        //   name: 'navbarMenu.reports.group',
        //   to: '/report/group',
        //   active: true
        // },
        // {
        //   name: 'navbarMenu.reports.special',
        //   to: '/report/special-vip-visit',
        //   active: true
        // },
        // {
        //   name: 'navbarMenu.reports.sos',
        //   to: '/report/sos',
        //   active: true
        // },
        // {
        //   name: 'navbarMenu.reports.visitorDump',
        //   to: '/report/visitors-dump',
        //   active: true
        // },
        // {
        //   name: 'navbarMenu.reports.broadCast',
        //   to: '/report/broadcast',
        //   active: true
        // },
        // {
        //   name: 'navbarMenu.reports.audit',
        //   to: '/report/audit',
        //   active: true
        // }
      ]
    },
    {
      name: 'navbarMenu.parent.booking',
      active: true,
      children: [
        {
          name: 'navbarMenu.booking.singlebooking',
          to: '/booking/single-booking',
          active: true
        },
        {
          name: 'navbarMenu.booking.eventsbooking',
          to: '/booking/events-booking',
          active: true
        },
        // {
        //   name: 'navbarMenu.booking.guestbooking',
        //   to: '/booking/guest-booking/0100120B',
        //   active: true
        // },
        // {
        //   name: 'navbarMenu.booking.eventbooking',
        //   to: '/booking/event-booking/0100120B',
        //   active: true
        // },
      ]
    },
    {
      name: 'navbarMenu.parent.reports',
      active: true,
      children: [
        {
          name: 'navbarMenu.reports.summaryreport',
          to: '/reports/summary-report',
          active: true
        },
        {
          name: 'navbarMenu.reports.visitsreport',
          to: '/reports/visits-report',
          active: true
        },
        {
          name: 'navbarMenu.reports.visitorappointmentreport',
          to: '/reports/visitor-appointmentreport',
          active: true
        },
        {
          name: 'navbarMenu.reports.eventsreport',
          to: '/reports/events-report',
          active: true
        },
        {
          name: 'navbarMenu.reports.blacklistreport',
          to: '/reports/blacklist-report',
          active: true
        },
        {
          name: 'navbarMenu.reports.sosreport',
          to: '/reports/sos-report',
          active: true
        },
        // {
        //   name: 'navbarMenu.reports.broadcastreport',
        //   to: '/reports/broadcast-report',
        //   active: true
        // },
        {
          name: 'navbarMenu.reports.bypassreport',
          to: '/reports/bypass-report',
          active: true
        },
        {
          name: 'navbarMenu.reports.visitordetailreport',
          to: '/reports/visitordetail-report',
          active: true
        },
        {
          name: 'navbarMenu.reports.changelogsreport',
          to: '/reports/changelogs-report',
          active: true
        },
        {
          name: 'navbarMenu.reports.timesvisitreport',
          to: '/reports/timesvisit-report',
          active: true
        },
        {
          name: 'navbarMenu.reports.guestsummary',
          to: '/reports/guest-summary',
          active: true
        },
        {
          name: 'navbarMenu.reports.guestbookingreport',
          to: '/reports/guest-bookingreport',
          active: true
        },
        {
          name: 'navbarMenu.reports.guestvisitreport',
          to: '/reports/guest-visitreport',
          active: true
        },

        {
          name: 'navbarMenu.reports.visithoursexceeded',
          to: '/reports/visit-hoursexceeded',
          active: true
        },
        {
          name: 'navbarMenu.reports.qrcodecounter',
          to: '/reports/qr-code',
          active: true
        },
      ]
    },
    {
      name: 'navbarMenu.parent.appointment',
      to: '/appointment',
      active: true,
    },
    {
      name: 'navbarMenu.parent.applicationsettings',
      active: true,
      children: [
        {
          name: 'navbarMenu.applicationsettings.emailsettings',
          to: '/applicationsettings/email-settings',
          active: true
        },
        {
          name: 'navbarMenu.applicationsettings.smssettings',
          to: '/applicationsettings/sms-settings',
          active: true
        },
        {
          name: 'navbarMenu.applicationsettings.whatsappsettings',
          to: '/applicationsettings/whatsapp-settings',
          active: true
        },
        {
          name: 'navbarMenu.applicationsettings.managefrontdesk',
          to: '/applicationsettings/manage-frontdesk',
          active: true
        },
        {
          name: 'navbarMenu.applicationsettings.parametersettings',
          to: '/applicationsettings/parameter-settings',
          active: true
        },
      ]
    }
  ]
}

// export const externalevent = [
//   {
//     label: 'navbarMenu.booking.guestbooking',
//     to: '/booking/guest-booking',
//     active: true
//   },
//   {
//     label: 'navbarMenu.booking.eventbooking',
//     to: '/booking/event-booking',
//     active: true
//   },
// ]

export const externalevent = {
  label: 'Booking',
  labelDisable: true,
  children: [
    {
      name: 'navbarMenu.booking.guestbooking',
      to: '/booking/guest-booking',
      active: true
    },
    {
      name: 'navbarMenu.booking.fullguestbooking',
      to: '/booking/fullguest-booking',
      active: true
    },
    {
      name: 'navbarMenu.booking.eventbooking',
      to: '/booking/event-booking',
      active: true
    },
  ]
};


// export const dashboard = {
//   label: 'navbarMenu.parent.dashboard',
//   labelDisable: true,
//   children: [
//     {
//       name: 'navbarMenu.parent.dashboard',
//       to: '/dashboard',
//       active: true,
//       //icon: 'chart-pie'
//     }
//   ]
// };


// export const appointment = {
//   label: 'Appointment',
//   labelDisable: true,
//   children: [
//     {
//       name: 'navbarMenu.parent.appointment',
//       to: '/appointment',
//       active: true,
//       //icon: 'user-plus'
//     }
//   ]
// };
// export const setup = {
//   label: 'setup',
//   labelDisable: true,
//   children: [
//     {
//       name: 'navbarMenu.parent.parameterSetup',
//       active: true,
//       //icon: 'cog',
//       children: [
//         {
//           name: 'navbarMenu.parameterSetup.configuration',
//           to: '/parameter-setup/configuration-settings',
//           active: true
//         },
//         {
//           name: 'navbarMenu.parameterSetup.mailling',
//           to: '/parameter-setup/mailling-details',
//           active: true
//         },
//         {
//           name: 'navbarMenu.parameterSetup.application',
//           to: '/parameter-setup/application-settings',
//           active: true
//         },
//         {
//           name: 'navbarMenu.parameterSetup.proxy',
//           to: '/parameter-setup/proxy-settings',
//           active: true
//         },
//         {
//           name: 'navbarMenu.parameterSetup.sms',
//           to: '/parameter-setup/sms-gateway',
//           active: true
//         },
//         {
//           name: 'navbarMenu.parameterSetup.whatsapp',
//           to: '/parameter-setup/whatsapp',
//           active: true
//         },
//         {
//           name: 'navbarMenu.parameterSetup.telegram',
//           to: '/parameter-setup/telegram',
//           active: true
//         },
//         {
//           name: 'navbarMenu.parameterSetup.addVisitorType',
//           to: '/parameter-setup/add-visitor-type',
//           active: true
//         },
//         {
//           name: 'navbarMenu.parameterSetup.manageFrontdesk',
//           to: '/parameter-setup/manage-frontdesk',
//           active: true
//         },
//         {
//           name: 'navbarMenu.parameterSetup.mapEncoding',
//           to: '/parameter-setup/map-encoding',
//           active: true
//         }
//       ]
//     }
//   ]
// };
// export const logDetails = {
//   label: 'navbarMenu.parent.logDetails',
//   labelDisable: true,
//   children: [
//     {
//       name: 'navbarMenu.parent.logDetails',
//       to: '/logDetails',
//       active: true,
//       //icon: 'info'
//     }
//   ]
// };
export default [dashboard, navbarmenu, externalevent];
// , appointment, setup, logDetails