import { accesscontrolEndPoints } from "./config"
import httpServices from "./httpServices"

export const accesslist = async () => {
    return await httpServices.get(accesscontrolEndPoints.listaccess)
}
export const dropdownlist = async () => {
    return await httpServices.get(accesscontrolEndPoints.listdropdown)
}
export const accessadd = async (accessData) => {
    return await httpServices.post(accesscontrolEndPoints.addaccess, accessData)
}
export const accessdelete = async (id) => {
    return await httpServices.post(accesscontrolEndPoints.deleteaccess, id)
}
export const accessupdate = async (updatedData) => {
    return await httpServices.post(accesscontrolEndPoints.updateaccess, updatedData)
}