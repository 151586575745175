import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import formPattern from 'components/configuration/form-pattern';
import InputPassword from 'components/common/InputPassword';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactTimePicker from 'components/common/ReactTimePicker';
import ReactDatePicker from 'components/common/ReactDatePicker';
import CountryCode from 'components/common/CountryCode';
import { useState } from 'react';
import { useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { getListOfIdentificationType, getMemberVerify } from 'services/commonService';
import { externalFullGuestbooking, externalGuestbooking, getExternalIdentificationType } from 'services/bookingService';
import moment from 'moment';
import pattern from 'components/configuration/form-pattern';
import { loaderContext } from 'context/Context';
import { defaultCountryCode } from 'components/configuration/country-code';
import CapturePhotoModal from 'components/common/CapturePhotoModal';
import CaptureDocumentModal from 'components/common/CaptureDocumentModal';
import { genderOptions } from 'components/configuration/common-options';
import { getDestinationList } from 'services/guestRegistrationService';
import { commonScanDocEndPoints } from 'services/config';
import axios from 'axios';


function FullGuestBooking() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { isLoadings, setIsLoading, setMemberNumber } = useContext(loaderContext)
    const [countryCode, setCountryCode] = useState({});
    // const [indentificationType, setIndentificationType] = useState([]);
    const [isLinkInvalid, setIsLinkInvalid] = useState(false);
    const [memberData, setMemberData] = useState({})
    const [userPhoto, setUserPhoto] = useState("");
    const [frontDocPhoto, setFrontDocPhoto] = useState("");
    const [backDocPhoto, setBackDocPhoto] = useState("");
    const [frontdocerror, setFrontDocError] = useState(false)
    const [backdocerror, setBackDocError] = useState(false)
    const [isCaptureFrontDocModalOpen, setIsCaptureFrontDocModalOpen] = useState(false)
    const [isCaptureBackDocModalOpen, setIsCaptureBackDocModalOpen] = useState(false)
    const [isCapturePhotoModalOpen, setIsCapturePhotoModalOpen] = useState(false);
    const [docType, setDocType] = useState([])
    const [destinations, setDestinations] = useState([]);



    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        watch,
        clearErrors,
        formState: { errors }
    } = useForm();

    const documentType = watch("document_type");
    const isMobile = navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i) ? true : false

        console.log("params123===>", params?.memberId)

        const verifyMember = async () => {
            setIsLoading(true)
            if (params?.memberId) {
    
                try {
                    const hash = CryptoJS.SHA256(`${process.env.REACT_APP_SECRET_KEY}${params?.memberId}`).toString();
                    const { data: memberData } = await getMemberVerify({ Search: params?.memberId, Hash: hash })
                    const { data } = await getDestinationList();
                    setDestinations(data?.data?.map((destination) => ({ label: destination.name, value: destination.id })))
                    const { data: identificationType } = await getListOfIdentificationType();
                    setDocType(identificationType?.data?.map((doc) => ({ documentName: doc.name, id: doc.id })))
                    if (memberData?.MemberNumber != "") {
                        setMemberData(memberData)
                        setMemberNumber(params?.memberId)
                    } else {
                        setIsLinkInvalid(true)
                    }
                } catch (error) {
                    errorMessage(error)
                }
            } else {
                setIsLinkInvalid(true)
            }
            setIsLoading(false)
        }
        useEffect(() => {
            verifyMember()
        }, [])

        const getDataFrontDocument = async () => {
            setIsLoading(true)
            try {
                const frontImg = frontDocPhoto
                const backImg = backDocPhoto
                console.log("front img", frontImg)
                const { data } = await axios.post(commonScanDocEndPoints.imageIdentity, { front: frontImg.split(',')[1], back: backImg.split(',')[1], type: documentType }, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                const documentData = data?.data[0]?.document_data;
                setValue("gender", genderOptions.find((gender) => gender?.value === documentData?.gender))
                const fullName = documentData?.name || "";
                const [firstName, lastName] = fullName.split(" ");
                setValue("first_name", firstName);
                setValue("last_name", lastName);
                // setValue("name", documentData?.name)
                setValue("document_id_number", documentData?.id_card_number)
                setValue("nationality", documentData?.nationality)
                { (documentData?.dob && documentData?.dob != "") && setValue("dob", moment(documentData?.dob, process.env.REACT_APP_DISPLAY_DATE_FORMAT).toDate()) }
                clearErrors("gender")
                clearErrors("name")
                clearErrors("document_id_number")
                clearErrors("nationality")
                clearErrors("dob")
            } catch (e) {
                if (e?.response?.data) {
                    if (Array.isArray(e.response.data.message)) {
                        e.response.data.message.map(errors => toast.error(errors, toastConfig))
                    } else {
                        toast.error(e.response.data.message, toastConfig)
                    }
                } else {
                    toast.error(e.message, toastConfig)
                }
            }
            setIsLoading(false)
        }
        const getDataFromPassport = async () => {
            setIsLoading(true)
            try {
                const frontImg = frontDocPhoto
                const { data } = await axios.post(commonScanDocEndPoints.imageIdentity, { front: frontImg.split(',')[1], type: documentType }, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                const documentData = data?.data[0]?.document_data;
                console.log(documentData)
    
                const dob = documentData?.dob
                    ? moment(documentData?.dob, process.env.REACT_APP_DISPLAY_DATE_FORMAT).toDate()
                    : moment('1980-01-01').toDate();
                setValue("gender", genderOptions.find((gender) => gender?.value === documentData?.gender))
                const fullName = documentData?.name || "";
            const [firstName, lastName] = fullName.split(" ");
            setValue("first_name", firstName);
            setValue("last_name", lastName);
                // setValue("name", documentData?.name)
                setValue("document_id_number", documentData?.id_card_number)
                setValue("nationality", documentData?.nationality)
                setValue("dob", dob)
                clearErrors("gender")
                clearErrors("name")
                clearErrors("document_id_number")
                clearErrors("nationality")
                clearErrors("dob")
            } catch (e) {
                if (e?.response?.data) {
                    if (Array.isArray(e.response.data.message)) {
                        e.response.data.message.map(errors => toast.error(errors, toastConfig))
                    } else {
                        toast.error(e.response.data.message, toastConfig)
                    }
                } else {
                    toast.error(e.message, toastConfig)
                }
            }
            setIsLoading(false)
        }

        useEffect(() => {
            if (documentType != "4") {
                if (documentType != "3") {
                    if (frontDocPhoto && frontDocPhoto != "" && backDocPhoto && backDocPhoto != "") {
                        getDataFrontDocument()
                    }
                } else {
                    if (frontDocPhoto && frontDocPhoto != "") {
                        getDataFromPassport()
                    }
                }
            }
        }, [frontDocPhoto, backDocPhoto])

        useEffect(() => {
            if (frontDocPhoto != "") {
                setFrontDocError(false)
            }
            if (backDocPhoto != "") {
                setBackDocError(false)
            }
        }, [frontDocPhoto, backDocPhoto])

    const onSubmit = async formData => {
        let isError = false;
        setIsLoading(true)
        try {
            if (documentType == 3 && frontDocPhoto == "") {
                setFrontDocError(true)
                isError = true
            }
            else if (documentType != 3) {
                if (frontDocPhoto == "") {
                    setFrontDocError(true);
                    isError = true
                }
                if (backDocPhoto == "") {
                    setBackDocError(true);
                    isError = true
                }
            }
            if (!isError) {
                formData.document_type = formData?.document_type?.value
                formData.meeting_date = moment(formData.meeting_date).format(process.env.REACT_APP_DATE_FORMAT)
                formData.member_name = `${memberData?.FirstName} ${memberData?.lastName}`
                formData.member_number = memberData?.MemberNumber
                formData.member_email = memberData?.Email
                formData.member_phone = memberData?.Mobile
                formData.member_profile = memberData?.Photourl
                formData.member_country_code = ""
                formData.country_code = countryCode?.dial_code
                formData.destination_id = formData?.destination_id?.value;
                formData.gender = formData?.gender?.label;
                formData.dob = moment(formData.dob).format(process.env.REACT_APP_DATE_FORMAT)
                formData.profile_photo = userPhoto
                formData.front = frontDocPhoto
                formData.back = backDocPhoto
                const { data } = await externalFullGuestbooking(formData)
                toast.success(data?.message, toastConfig);
                onReset()
            }
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    };
    const onReset = () => {
        reset();
        setValue('document_type', '')
        setValue('gender', '')
        setValue('destination_id', '')

        setCountryCode(defaultCountryCode)
        setFrontDocPhoto('');
        setBackDocPhoto('');
        setProfilePhoto('');
    };

    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                const newUrl = URL.createObjectURL(file);
                setUserPhoto(newUrl);
            }
        }
    };
    const setProfilePhoto = (data) => {
        setUserPhoto(data);
    }
    const setFrontDocImage = async (data) => {
        setFrontDocPhoto(data);
    }
    const setBackDocImage = async (data) => {
        setBackDocPhoto(data)
    }

    const frontsideClick = () => {
        if (documentType && documentType != '') {
            setIsCaptureFrontDocModalOpen(true)
        } else {
            toast.error("Please select ID Type first.", toastConfig)
        }
    }
    const backsideClick = () => {
        if (documentType && documentType != '') {
            setIsCaptureBackDocModalOpen(true)
        } else {
            toast.error("Please select ID Type first.", toastConfig)
        }
    }

    const resetDocumentPhotos = () => {
        setFrontDocPhoto('');
        setBackDocPhoto('');
        setValue('dob', '');
        setValue('gender', '');
        setValue("document_id_number", "")
        setValue('nationality', '')
        setValue("first_name", "")
        setValue("last_name", "")
    };

    const handleDocTypeChange = () => {
        resetDocumentPhotos();
    };

    return (
        <>
            <div className="mb-3 row g-3">
                {!isLinkInvalid ?
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-12">
                                    <h3 className="heading-color fw-600">
                                        {t('fullguestbooking.guestbooking.heading')}
                                    </h3>
                                </div>
                                <div className="col-12 mb-3">
                                    <hr />
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

                                    <div className="row nationality-section">
                                        <div
                                            className="col-lg-12 col-xl-4 col-xxl-6 mt-xl-3 rounded-3"
                                        >
                                            <div className="documentid-data">
                                                <div className="row align-items-center">
                                                    {<div className="col-lg-12 col-xl-12">
                                                        <label className="form-label">
                                                            {t('fullguestbooking.guestbooking.guestbookingform.idtype')}
                                                            <span className="text-danger ms-1">*</span>
                                                        </label>
                                                        <div className='form-group mb-3 mb-sm-0'>
                                                            {docType.map((docType, index) => (
                                                                <div className="form-check form-check-inline" key={docType.id}>
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        value={docType.id}
                                                                        onClick={() => handleDocTypeChange()}

                                                                        {...register('document_type', {
                                                                            required: true
                                                                        })}
                                                                    />
                                                                    <label className="form-check-label">
                                                                        {docType.documentName}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {errors.document_type && (
                                                            <small>
                                                                {errors.document_type.type === 'required' && (
                                                                    <span className="text-danger">
                                                                        {t(
                                                                            'bookGuest.error.idType'
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </small>
                                                        )}
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                        {isMobile && <input
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            id="icon-button-file"
                                            type="file"
                                            capture="environment"
                                            onChange={(e) => handleCapture(e.target)}
                                        />}
                                        <div className="col-lg-12 col-xl-8 col-xxl-6" style={{ marginLeft: "-1rem" }}>
                                            <div className="row g-3">
                                                <div className=" col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-4 mt-3 mt-sm-0 mt-xl-4 d-flex justify-content-center">
                                                    <label htmlFor='icon-button-file'>
                                                        <div className="scan-box-visitor" onClick={() => setIsCapturePhotoModalOpen(true)}>
                                                            <img
                                                                src={`${userPhoto != "" ? userPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/user.png`}`}
                                                                style={{ maxWidth: '80px', height: "100%", width: "100%", aspectRatio: "auto" }}
                                                            />
                                                            <h4>{t('fullguestbooking.guestbooking.guestbookingform.capturePhoto')}</h4>
                                                        </div>
                                                    </label>
                                                </div>


                                                <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-4 mt-3 mt-sm-0 mt-xl-4 d-flex justify-content-center">
                                                    <div>
                                                        <div className="scan-box-visitor" onClick={() => frontsideClick()}>
                                                            <img
                                                                src={`${frontDocPhoto != "" ? frontDocPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/scanner.png`}`}
                                                                style={{ objectFit: "contain", aspectRatio: "auto" }}
                                                            />
                                                            <h4>{documentType != "3" ? t('fullguestbooking.guestbooking.guestbookingform.frontSide') : t('fullguestbooking.guestbooking.guestbookingform.scanDoc')}</h4>

                                                        </div>
                                                        {frontdocerror && (
                                                            <small className='frontdocerror'>
                                                                <span className="text-danger">
                                                                    {t('commondocerror.frontdocerror')}
                                                                    {/* Front Doc is required. */}
                                                                </span>
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>


                                                {documentType != "3" && <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-4 mt-3 mt-sm-0 mt-xl-4 d-flex justify-content-center">
                                                    <div>
                                                        <div className="scan-box-visitor" onClick={() => backsideClick()}>
                                                            <img
                                                                src={`${backDocPhoto != "" ? backDocPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/scanner.png`}`}
                                                                style={{ objectFit: "contain", aspectRatio: "auto" }}
                                                            />
                                                            <h4>{t('fullguestbooking.guestbooking.guestbookingform.backSide')}</h4>
                                                        </div>
                                                        {backdocerror && (
                                                            <small className='frontdocerror'>
                                                                <span className="text-danger">
                                                                    {t('commondocerror.backdocerror')}
                                                                    {/* Back Doc is required. */}
                                                                </span>
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <hr />
                                    </div>


                                    <div className='row'>
                                        <div className='form-group col-md-2 '>
                                            <label className="form-label">
                                                {t('fullguestbooking.guestbooking.guestbookingform.countrycode')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <CountryCode
                                                countryCode={countryCode}
                                                setCountryCode={setCountryCode}
                                                direction="bottom"
                                            />
                                        </div>
                                        <div className='form-group col-md-2'>
                                            <label className="form-label">
                                                {t('fullguestbooking.guestbooking.guestbookingform.guestmbno')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.phone && 'is-invalid'
                                                    }`}
                                                placeholder={t('fullguestbooking.guestbooking.placeholder.guestmbno')}
                                                {...register('phone', { required: true, pattern: formPattern.phoneNumber })}
                                            />
                                            {errors.phone && (
                                                <small>
                                                    {errors.phone.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('fullguestbooking.guestbooking.error.guestMobileNoReq')}
                                                        </span>
                                                    )}
                                                    {errors.phone.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('fullguestbooking.guestbooking.error.guestMobileNoInvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>

                                        <div className="form-group col-md-4 mb-3">
                                            <label className="form-label">
                                                {t('fullguestbooking.guestbooking.guestbookingform.guestfirstname')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.first_name && 'is-invalid'
                                                    }`}
                                                placeholder={t('fullguestbooking.guestbooking.placeholder.guestfirstname')}
                                                {...register('first_name', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                            />
                                            {errors.first_name && (
                                                <small>
                                                    {errors.first_name.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('fullguestbooking.guestbooking.error.guestFirstNameReq')}
                                                        </span>
                                                    )}
                                                    {errors.first_name.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('fullguestbooking.guestbooking.error.guestFirstNameInvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-4 mb-3">
                                            <label className="form-label">
                                                {t('fullguestbooking.guestbooking.guestbookingform.guestlastname')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.last_name && 'is-invalid'
                                                    }`}
                                                placeholder={t('fullguestbooking.guestbooking.placeholder.guestlastname')}
                                                {...register('last_name', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                            />
                                            {errors.last_name && (
                                                <small>
                                                    {errors.last_name.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('fullguestbooking.guestbooking.error.guestLastNameReq')}
                                                        </span>
                                                    )}
                                                    {errors.last_name.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('fullguestbooking.guestbooking.error.guestLastNameInvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>

                                        <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                            <label className="form-label">
                                                {t('fullguestbooking.guestbooking.guestbookingform.nationality')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                className={`form-control ${errors.nationality && 'is-invalid'
                                                    }`}
                                                type="text"
                                                placeholder={t('fullguestbooking.guestbooking.placeholder.nationality')}
                                                // disabled={formFieldsDisabled}
                                                {...register('nationality', { required: true, pattern: pattern.notAllowSpaceInStartfront })}
                                            />
                                            {errors.nationality && (
                                                <small>
                                                    {errors.nationality.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('fullguestbooking.guestbooking.error.nationality')}
                                                        </span>
                                                    )}
                                                    {errors.nationality.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('fullguestbooking.guestbooking.error.nationalityInvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>

                                        <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                            <label>
                                                {t('fullguestbooking.guestbooking.guestbookingform.dob')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            {/* <input
                                                    className={`form-control ${errors.visitorBirthDate && 'is-invalid'
                                                        }`}
                                                    type="text"
                                                    {...register('visitorBirthDate', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                                /> */}
                                            <Controller
                                                name="dob"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange } }) => (
                                                    <ReactDatePicker
                                                        value={value}
                                                        onChange={onChange}
                                                        placeholder={t(
                                                            'fullguestbooking.guestbooking.placeholder.dob'
                                                        )}
                                                        hasErrors={errors.dob}
                                                        maxDate={moment().toDate()}
                                                        dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                                                    // formFieldsDisabled={formFieldsDisabled}
                                                    />
                                                )}
                                            />
                                            {errors.dob && (
                                                <small>
                                                    {errors.dob.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('fullguestbooking.guestbooking.error.dob')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                            <label>
                                                {t('fullguestbooking.guestbooking.guestbookingform.gender')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                name="gender"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange } }) => (
                                                    <SearchableDropdown
                                                        value={value}
                                                        onChange={onChange}
                                                        options={genderOptions}
                                                        hasError={errors.gender}
                                                        placeholder={t('fullguestbooking.guestbooking.placeholder.gender')}
                                                    // formFieldsDisabled={formFieldsDisabled}
                                                    />
                                                )}
                                            />
                                            {errors.gender && (
                                                <small>
                                                    {errors.gender.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('fullguestbooking.guestbooking.error.gender')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-1">
                                            <label>
                                                {t('fullguestbooking.guestbooking.guestbookingform.destination')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                name="destination_id"
                                                control={control}
                                                rules={{ required: true, pattern: pattern.notAllowSpaceInStartfront }}
                                                render={({ field: { value, onChange } }) => (
                                                    <SearchableDropdown
                                                        value={value}
                                                        onChange={onChange}
                                                        options={destinations}
                                                        hasError={errors.destination_id}
                                                        placeholder={t('fullguestbooking.guestbooking.placeholder.destination')}
                                                    // formFieldsDisabled={formFieldsDisabled}
                                                    />
                                                )}
                                            />
                                            {errors.destination_id && (
                                                <small>
                                                    {errors.destination_id.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('fullguestbooking.guestbooking.error.destination')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-1">
                                            <label>
                                                {t('fullguestbooking.guestbooking.guestbookingform.vehicleRegistration')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                className={`form-control ${errors.vehicle_reg_no && 'is-invalid'
                                                    }`}
                                                type="text"
                                                // disabled={formFieldsDisabled}
                                                placeholder={t('fullguestbooking.guestbooking.placeholder.vehicle')}
                                                {...register('vehicle_reg_no', { required: true, pattern: pattern.notAllowSpaceInStartfront })}
                                            />
                                            {errors.vehicle_reg_no && (
                                                <small>
                                                    {errors.vehicle_reg_no.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('fullguestbooking.guestbooking.error.vehicle')}
                                                        </span>
                                                    )}
                                                    {errors.vehicle_reg_no.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('fullguestbooking.guestbooking.error.vehicleinvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>

                                        <div className="form-group col-md-4">
                                            <label className="form-label">
                                                {t('fullguestbooking.guestbooking.guestbookingform.email')}
                                                {/* <span className="text-danger ms-1">*</span> */}
                                            </label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.email && 'is-invalid'
                                                    }`}
                                                placeholder={t('fullguestbooking.guestbooking.placeholder.email')}
                                                {...register('email', { pattern: formPattern.email })}
                                            />
                                            {errors.email && (
                                                <small>
                                                    {errors.email.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('bookGuest.error.emailInvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        {/* <div className="form-group col-md-4">
                                            <label className="form-label">
                                                {t('guestbooking.guestbookingform.identificationtype')}
                                             
                                            </label>
                                            <Controller
                                                name="document_type"
                                                control={control}
                                            
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <SearchableDropdown
                                                            value={value}
                                                            onChange={onChange}
                                                            options={indentificationType}
                                                            placeholder={t(
                                                                'guestbooking.placeholder.identificationtype'
                                                            )}
                                                            isClearable={true}
                                                            hasError={errors.document_type}
                                                        />
                                                    )
                                                }}
                                            />
                                            {errors.document_type && (
                                                <small>
                                                    {errors.document_type.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('bookGuest.error.identificationTypeReq')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div> */}
                                        <div className="form-group col-md-4 mb-3">
                                            <label className="form-label">
                                                {t('fullguestbooking.guestbooking.guestbookingform.identificationno')}
                                                {/* <span className="text-danger ms-1">*</span> */}
                                            </label>
                                            <input
                                                className={`form-control ${errors.document_id_number ? 'is-invalid' : ''
                                                    }`}
                                                type="text"
                                                placeholder={t('fullguestbooking.guestbooking.placeholder.identificationno')}
                                                {...register('document_id_number', {
                                                    // required: true
                                                })}
                                            />
                                            {errors.document_id_number && (
                                                <small className="text-danger">
                                                    {errors.document_id_number.type === 'required' && (
                                                        <span>{t('bookGuest.error.identificationNoReq')}</span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-4 mb-3">
                                            <label className="form-label">
                                                {t('fullguestbooking.guestbooking.guestbookingform.dateofmeeting')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                name='meeting_date'
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange } }) => (
                                                    <ReactDatePicker
                                                        value={value}
                                                        selected={value}
                                                        onChange={onChange}
                                                        hasErrors={errors.meeting_date}
                                                        placeholder={t(
                                                            'bookGuest.placeholder.dateOfMeeting'
                                                        )}
                                                        dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                                                        minDate={moment().toDate()}
                                                    />
                                                )}
                                            />
                                            {errors.meeting_date && (
                                                <small className="text-danger">
                                                    {errors.meeting_date.type === 'required' && (
                                                        <span>{t('fullguestbooking.guestbooking.error.dateOfMeetingReq')}</span>
                                                    )}
                                                </small>
                                            )}
                                        </div>

                                    </div>

                                    <div className="mb-3 mt-3 text-end">
                                        <button type="submit" className="btn btn-primary me-1">
                                            {t('fullguestbooking.guestbooking.guestbookingform.submit')}
                                        </button>
                                        <button
                                            type="reset"
                                            className="btn btn-danger"
                                            onClick={onReset}
                                        >
                                            {t('fullguestbooking.guestbooking.guestbookingform.reset')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> :
                    <div className="card overflow-hidden mb-3">
                        <p>This link is invalid.</p>
                    </div>
                }

                <CapturePhotoModal
                    isModalOpen={isMobile === true ? false : isCapturePhotoModalOpen}
                    setIsModalOpen={setIsCapturePhotoModalOpen}
                    setUserPhoto={setProfilePhoto}
                />
                <CaptureDocumentModal
                    isModalOpen={isCaptureFrontDocModalOpen}
                    setIsModalOpen={setIsCaptureFrontDocModalOpen}
                    setUserPhoto={setFrontDocImage}
                />
                <CaptureDocumentModal
                    isModalOpen={isCaptureBackDocModalOpen}
                    setIsModalOpen={setIsCaptureBackDocModalOpen}
                    setUserPhoto={setBackDocImage}
                />
            </div>
        </>
    )
}

export default FullGuestBooking