import httpServices from "./httpServices";
import { guestBookingEndPoint } from "./config";
import { HttpsSharp } from "@mui/icons-material";

export const externalGuestbooking = async (data) => {
    return await httpServices.post(guestBookingEndPoint.bookingGuest, data)
}

export const externalFullGuestbooking = async (data) => {
    return await httpServices.post(guestBookingEndPoint.fullbookingGuest, data)
}

export const createNewEvent = async (data) => {
    return await httpServices.post(guestBookingEndPoint.addEvents, data)
}

export const getExternalLocations = async () => {
    return await httpServices.get(guestBookingEndPoint.getLocationsExternal)
}

export const getExternalIdentificationType = async () => {
    return await httpServices.get(guestBookingEndPoint.getExternalIdentificationType)
}

export const getExternalEventList = async (data) => {
    console.log("Dsata", data)
    return await httpServices.post(guestBookingEndPoint.getExternalEventList, data)
}

export const updateExternalEvent = async (data) => {
    return await httpServices.post(guestBookingEndPoint.updateExternalEvent, data)
}

export const uploadGuestCsv = async (data) => {
    return await httpServices.post(guestBookingEndPoint.uploadCsv, data)
}

export const getExternalEventById = async (data) => {
    return await httpServices.post(guestBookingEndPoint.getExternalEventData, data)
}

export const addExternalGuest = async (data) => {
    return await httpServices.post(guestBookingEndPoint.addExternalGuest, data)
}