import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import formPattern from 'components/configuration/form-pattern';
import InputPassword from 'components/common/InputPassword';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactTimePicker from 'components/common/ReactTimePicker';
import ReactDatePicker from 'components/common/ReactDatePicker';
import CountryCode from 'components/common/CountryCode';
import { useState } from 'react';
import { bookaguest, editbookaguest, listidenti } from 'services/manageEventsService'
import moment from 'moment';
import countryCodes, {
    defaultCountryCode
} from 'components/configuration/country-code';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import pattern from 'components/configuration/form-pattern';
import CryptoJS from 'crypto-js';
import { getListOfIdentificationType, getMemberVerify } from 'services/commonService';
import ReactTable from 'components/common/ReactTable';
import { casualworkerslist } from 'services/adminmoduleService';
import Swal from 'sweetalert2';
import CapturePhotoModal from 'components/common/CapturePhotoModal';
import CaptureDocumentModal from 'components/common/CaptureDocumentModal';
import { genderOptions, destinationOptions } from 'components/configuration/common-options';
import { getDestinationList } from 'services/guestRegistrationService';
import axios from 'axios';
import { commonScanDocEndPoints } from 'services/config';




function EditBookAGuest() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [countryCode, setCountryCode] = useState(defaultCountryCode);
    const [docType, setDocType] = useState([])
    const [memberData, setMemberData] = useState({})
    const [casualworkerData, setCasualWorkerData] = useState([]);
    const [userPhoto, setUserPhoto] = useState("");
    const [frontDocPhoto, setFrontDocPhoto] = useState("");
    const [backDocPhoto, setBackDocPhoto] = useState("");
    // const [frontdocerror, setFrontDocError] = useState(false)
    // const [backdocerror, setBackDocError] = useState(false)
    const [isCaptureFrontDocModalOpen, setIsCaptureFrontDocModalOpen] = useState(false)
    const [isCaptureBackDocModalOpen, setIsCaptureBackDocModalOpen] = useState(false)
    const [isCapturePhotoModalOpen, setIsCapturePhotoModalOpen] = useState(false);
    const [destinations, setDestinations] = useState([]);





    const { isLoading, setIsLoading } = useContext(loaderContext)
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        watch,
        clearErrors,
        formState: { errors }
    } = useForm();
    const location = useLocation();
    console.log("editbookaguest=======>", location)

    const documentType = watch("document_type");
    const isMobile = navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i) ? true : false

    const memberName = watch("member_name")
    useEffect(async () => {
        checkModulePermission("Book A Guest")
        setIsLoading(true)
        try {
            // const { data: identi } = await listidenti()
            const { data: desti } = await getDestinationList();
            const { data: identificationType } = await getListOfIdentificationType();
            const destinate = desti?.data?.map((destination) => ({ label: destination.name, value: destination.id }))
            setDestinations(destinate)
            setDocType(identificationType?.data?.map((doc) => ({ documentName: doc.name, id: doc.id })))

            const { data } = await casualworkerslist();
            // setDocType(identi?.data?.map((list) => ({ label: list.name, value: list.id })))
            setCasualWorkerData(data?.data);


            setValue("document_type", location?.state?.doc_id_type?.toString())
            const fullName = location?.state?.guest_name || "";
            const [firstName, lastName] = fullName.split(" ");

            setValue("first_name", firstName);
            setValue("last_name", lastName);
            setValue("phone", location?.state?.guest_phone)
            setValue("nationality", location?.state?.nationality)
            { (location?.state?.dob && location?.state?.dob != "") && setValue("dob", moment(location?.state?.dob).toDate()) }
            setValue("gender", genderOptions.find((gender) => gender.label == location?.state?.gender))
            setValue("destination_id", destinate.find((desti) => desti.value == location?.state?.destination_id))
            setValue("vehicle_reg_no", location?.state?.vehicle_reg_no)
            setValue("email", location?.state?.guest_email)
            setValue("document_id_number", location?.state?.doc_id_number)
            { (location?.state?.arrival_time && location?.state?.arrival_time != "") && setValue("meeting_date", moment(location?.state?.arrival_time).toDate()) }
            setValue("member_number", location?.state?.member_details?.number)
            setValue("member_name", location?.state?.member_details?.name)
            setCountryCode(countryCodes?.find((country) => country?.dial_code === location?.state?.guest_country_code))
            setProfilePhoto(location?.state?.profile_photo ? `data:image/jpeg;base64,${location?.state?.profile_photo}` : "")
            setFrontDocPhoto(location?.state?.front ? `data:image/jpeg;base64,${location?.state?.front}` : "")
            setBackDocPhoto(location?.state?.back ? `data:image/jpeg;base64,${location?.state?.back}` : "")
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (documentType != "4") {
            if (documentType != "3") {
                if (frontDocPhoto && frontDocPhoto != "" && backDocPhoto && backDocPhoto != "") {
                    getDataFrontDocument()
                }
            } else {
                if (frontDocPhoto && frontDocPhoto != "") {
                    getDataFromPassport()
                }
            }
        }
    }, [frontDocPhoto, backDocPhoto])

    const getDataFrontDocument = async () => {
        setIsLoading(true)
        try {
            const frontImg = frontDocPhoto
            const backImg = backDocPhoto
            console.log("front img", frontImg)
            const { data } = await axios.post(commonScanDocEndPoints.imageIdentity, { front: frontImg.split(',')[1], back: backImg.split(',')[1], type: documentType }, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            const documentData = data?.data[0]?.document_data;
            setValue("gender", genderOptions.find((gender) => gender.value === documentData?.gender))
            const fullName = documentData?.name || "";
            const [firstName, lastName] = fullName.split(" ");
            setValue("first_name", firstName);
            setValue("last_name", lastName);
            // setValue("name", documentData?.name)
            setValue("document_id_number", documentData?.id_card_number)
            setValue("nationality", documentData?.nationality)
            { (documentData?.dob && documentData?.dob != "") && setValue("dob", moment(documentData?.dob, process.env.REACT_APP_DISPLAY_DATE_FORMAT).toDate()) }
            clearErrors("gender")
            clearErrors("name")
            clearErrors("document_id_number")
            clearErrors("nationality")
            clearErrors("dob")
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    const getDataFromPassport = async () => {
        setIsLoading(true)
        try {
            const frontImg = frontDocPhoto
            const { data } = await axios.post(commonScanDocEndPoints.imageIdentity, { front: frontImg.split(',')[1], type: documentType }, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            const documentData = data?.data[0]?.document_data;
            console.log(documentData)

            const dob = documentData?.dob
                ? moment(documentData.dob, process.env.REACT_APP_DISPLAY_DATE_FORMAT).toDate()
                : moment('1980-01-01').toDate();
            setValue("gender", genderOptions.find((gender) => gender.value === documentData?.gender))
            const fullName = documentData?.name || "";
            const [firstName, lastName] = fullName.split(" ");
            setValue("first_name", firstName);
            setValue("last_name", lastName);
            // setValue("name", documentData?.name)
            setValue("document_id_number", documentData?.id_card_number)
            setValue("nationality", documentData?.nationality)
            setValue("dob", dob)
            clearErrors("gender")
            clearErrors("name")
            clearErrors("document_id_number")
            clearErrors("nationality")
            clearErrors("dob")
        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }

    // useEffect(() => {
    //     setValue("document_type", location?.state?.doc_id_type?.toString())
    //     const fullName = location?.state?.guest_name || "";
    //     const [firstName, lastName] = fullName.split(" ");

    //     setValue("first_name", firstName);
    //     setValue("last_name", lastName);
    //     setValue("phone", location?.state?.guest_phone)
    //     setValue("nationality", location?.state?.nationality)
    //     { (location?.state?.dob && location?.state?.dob != "") && setValue("dob", moment(location?.state?.dob).toDate()) }
    //     setValue("gender", genderOptions.find((gender) => gender.label == location?.state?.gender))
    //     setValue("destination_id", destinations.find((desti) => desti.value == location?.state?.destination_id))
    //     setValue("vehicle_reg_no", location?.state?.vehicle_reg_no)
    //     setValue("email", location?.state?.guest_email)
    //     setValue("document_id_number", location?.state?.doc_id_number)
    //     { (location?.state?.arrival_time && location?.state?.arrival_time != "") && setValue("meeting_date", moment(location?.state?.arrival_time).toDate()) }
    //     setValue("member_number", location?.state?.member_details?.number)
    //     setValue("member_name", location?.state?.member_details?.name)
    //     setCountryCode(countryCodes?.find((country) => country?.dial_code === location?.state?.guest_country_code))
    //     setProfilePhoto(location?.state?.profile_photo ? `data:image/jpeg;base64,${location?.state?.profile_photo}` : "")
    //     setFrontDocPhoto(location?.state?.front ? `data:image/jpeg;base64,${location?.state?.front}` : "")
    //     setBackDocPhoto(location?.state?.back ? `data:image/jpeg;base64,${location?.state?.back}` : "")
    // }, [])

    // useEffect(() => {
    //     if (frontDocPhoto != "") {
    //         setFrontDocError(false)
    //     }
    //     if (backDocPhoto != "") {
    //         setBackDocError(false)
    //     }
    // }, [frontDocPhoto, backDocPhoto])

    const onSubmit = async (accessData) => {
        // let isError = false;
        console.log(accessData);
        setIsLoading(true)
        try {
            // if (documentType == 3 && frontDocPhoto == "") {
            //     setFrontDocError(true)
            //     isError = true
            // }
            // else if (documentType != 3) {
            //     if (frontDocPhoto == "") {
            //         setFrontDocError(true);
            //         isError = true
            //     }
            //     if (backDocPhoto == "") {
            //         setBackDocError(true);
            //         isError = true
            //     }
            // }
            // if (!isError) {
                accessData.meeting_date = moment(accessData?.meeting_date).format(process.env.REACT_APP_DATE_FORMAT)
                accessData.document_type = accessData?.document_type?.value
                accessData.destination_id = accessData?.destination_id?.value;
                accessData.gender = accessData?.gender?.label;
                accessData.dob = moment(accessData.dob).format(process.env.REACT_APP_DATE_FORMAT)
                accessData.member_phone = memberData?.phone
                accessData.member_email = memberData?.Email
                accessData.profile_photo = userPhoto
                accessData.front = frontDocPhoto
                accessData.back = backDocPhoto

                const { data } = await editbookaguest({ country_code: countryCode.dial_code, id: location?.state?.id, ...accessData });
                toast.success(data.message, toastConfig);
                reset();
                setValue("document_type", '')
                setUserPhoto("");
                setFrontDocPhoto("");
                setBackDocPhoto("");
                // setBackDocError(false)
                setFrontDocError(false)
                setCountryCode(defaultCountryCode)
                navigate('/manageevents/book-guest')
            // }


        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)

    };
    const onReset = () => {
        reset();
        setValue('document_type', '');
        setCountryCode(defaultCountryCode)
        setUserPhoto("");
        setFrontDocPhoto("");
        setBackDocPhoto("");
    };
    const verifyMemberDetails = async () => {
        setIsLoading(true)
        const memberId = watch("member_number")
        if (memberId && memberId != "") {
            try {
                const hash = CryptoJS.SHA256(`${process.env.REACT_APP_SECRET_KEY}${memberId}`).toString();
                const { data: memberData } = await getMemberVerify({ Search: memberId, Hash: hash })
                if (memberData?.MemberNumber != "") {
                    setValue("member_name", `${memberData.FirstName} ${memberData.lastName}`)
                    setMemberData(memberData)
                } else {
                    toast.error("Invalid", toastConfig)
                }
            } catch (error) {
                errorMessage(error)
            }
        }
        setIsLoading(false)
    }


    const handleCapture = (target) => {
        if (target.files) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                const newUrl = URL.createObjectURL(file);
                setUserPhoto(newUrl);
            }
        }
    };
    const setProfilePhoto = (data) => {
        setUserPhoto(data);
    }
    const setFrontDocImage = async (data) => {
        setFrontDocPhoto(data);
    }
    const setBackDocImage = async (data) => {
        setBackDocPhoto(data)
    }

    const frontsideClick = () => {
        if (documentType && documentType != '') {
            setIsCaptureFrontDocModalOpen(true)
        } else {
            toast.error("Please select ID Type first.", toastConfig)
        }
    }
    const backsideClick = () => {
        if (documentType && documentType != '') {
            setIsCaptureBackDocModalOpen(true)
        } else {
            toast.error("Please select ID Type first.", toastConfig)
        }
    }

    const resetDocumentPhotos = () => {
        setFrontDocPhoto('');
        setBackDocPhoto('');
        setValue('dob', '');
        setValue('gender', '');
        setValue("document_id_number", "")
        setValue('nationality', '')
        setValue("first_name", "")
        setValue("last_name", "")
    };

    const handleDocTypeChange = () => {
        resetDocumentPhotos();
    };

    return (
        <>
            <div className="mb-3 row g-3">
                <div className="card overflow-hidden mb-3">
                    <div className="card-header">
                        <div className="row">
                            {/* <div className="col-12"> */}
                            <div className='col-8'>
                                <h3 className="heading-color fw-600">
                                    {t('bookGuest.editbookaguestheading')}
                                </h3>
                                </div>
                                <div className="col-4 table-btn">
                                    <button
                                        className="btn btn-primary float-end btn-sm"
                                        onClick={() => navigate('/manageevents/book-guest')}
                                    >
                                        <FontAwesomeIcon icon="chevron-left" />
                                        <span className="ms-1">
                                            {t('eventLocation.btnLabel.back')}
                                        </span>
                                    </button>
                                </div>
                            {/* </div> */}
                            <div className="col-12 mb-3">
                                <hr />
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

                                <div className="row nationality-section">
                                    <div
                                        className="col-lg-12 col-xl-4 col-xxl-6 mt-xl-3 rounded-3"
                                    >
                                        <div className="documentid-data">
                                            <div className="row align-items-center">
                                                {<div className="col-lg-12 col-xl-12">
                                                    <label className="form-label">
                                                        {t('bookGuest.label.idtype')}
                                                        {/* <span className="text-danger ms-1">*</span> */}
                                                    </label>
                                                    <div className='form-group mb-3 mb-sm-0'>
                                                        {docType.map((docType, index) => (
                                                            <div className="form-check form-check-inline" key={docType.id}>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    value={docType.id}
                                                                    onClick={() => handleDocTypeChange()}

                                                                    {...register('document_type', {
                                                                        // required: true
                                                                    })}
                                                                />
                                                                <label className="form-check-label">
                                                                    {docType.documentName}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {/* {errors.document_type && (
                                                        <small>
                                                            {errors.document_type.type === 'required' && (
                                                                <span className="text-danger">
                                                                    {t(
                                                                        'bookGuest.error.idType'
                                                                    )}
                                                                </span>
                                                            )}
                                                        </small>
                                                    )} */}
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    {isMobile && <input
                                        accept="image/*"
                                        style={{ display: "none" }}
                                        id="icon-button-file"
                                        type="file"
                                        capture="environment"
                                        onChange={(e) => handleCapture(e.target)}
                                    />}
                                    <div className="col-lg-12 col-xl-8 col-xxl-6" style={{ marginLeft: "-1rem" }}>
                                        <div className="row g-3">
                                            <div className=" col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-4 mt-3 mt-sm-0 mt-xl-4 d-flex justify-content-center">
                                                <label htmlFor='icon-button-file'>
                                                    <div className="scan-box-visitor" onClick={() => setIsCapturePhotoModalOpen(true)}>
                                                        <img
                                                            src={`${userPhoto != "" ? userPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/user.png`}`}
                                                            style={{ maxWidth: '80px', height: "100%", width: "100%", aspectRatio: "auto" }}
                                                        />
                                                        <h4>{t('bookGuest.label.capturePhoto')}</h4>
                                                    </div>
                                                </label>
                                            </div>


                                            <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-4 mt-3 mt-sm-0 mt-xl-4 d-flex justify-content-center">
                                                <div>
                                                    <div className="scan-box-visitor" onClick={() => frontsideClick()}>
                                                        <img
                                                            src={`${frontDocPhoto != "" ? frontDocPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/scanner.png`}`}
                                                            style={{ objectFit: "contain", aspectRatio: "auto" }}
                                                        />
                                                        <h4>{documentType != "3" ? t('bookGuest.label.frontSide') : t('bookGuest.label.scanDoc')}</h4>

                                                    </div>
                                                    {/* {frontdocerror && (
                                                        <small className='frontdocerror'>
                                                            <span className="text-danger">
                                                                {t('commondocerror.frontdocerror')}
                                                               
                                                            </span>
                                                        </small>
                                                    )} */}
                                                </div>
                                            </div>


                                            {documentType != "3" && <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-4 mt-3 mt-sm-0 mt-xl-4 d-flex justify-content-center">
                                                <div>
                                                    <div className="scan-box-visitor" onClick={() => backsideClick()}>
                                                        <img
                                                            src={`${backDocPhoto != "" ? backDocPhoto : `${process.env.REACT_APP_BASEROUTE_URL}/admin-images/scanner.png`}`}
                                                            style={{ objectFit: "contain", aspectRatio: "auto" }}
                                                        />
                                                        <h4>{t('bookGuest.label.backSide')}</h4>
                                                    </div>
                                                    {/* {backdocerror && (
                                                        <small className='frontdocerror'>
                                                            <span className="text-danger">
                                                                {t('commondocerror.backdocerror')}
                                                               
                                                            </span>
                                                        </small>
                                                    )} */}
                                                </div>
                                            </div>}
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12">
                                    <hr />
                                </div>


                                <div className='row'>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('bookGuest.label.guestFirstName')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.first_name && 'is-invalid'
                                                }`}
                                            placeholder={t('bookGuest.placeholder.guestFirstName')}
                                            {...register('first_name', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                        />
                                        {errors.first_name && (
                                            <small>
                                                {errors.first_name.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('bookGuest.error.guestFirstNameReq')}
                                                    </span>
                                                )}
                                                {errors.first_name.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('bookGuest.error.guestFirstNameInvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('bookGuest.label.guestLastName')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.last_name && 'is-invalid'
                                                }`}
                                            placeholder={t('bookGuest.placeholder.guestLastName')}
                                            {...register('last_name', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                        />
                                        {errors.last_name && (
                                            <small>
                                                {errors.last_name.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('bookGuest.error.guestLastNameReq')}
                                                    </span>
                                                )}
                                                {errors.last_name.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('bookGuest.error.guestLastNameInvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="col-md-4 row pe-0 mb-3">
                                        <div className='form-group pe-0 col-md-6'>
                                            <label className="form-label">
                                                {t('bookGuest.label.countryCode')}
                                                {/* <span className="text-danger ms-1">*</span> */}
                                            </label>
                                            <CountryCode
                                                countryCode={countryCode}
                                                setCountryCode={setCountryCode}
                                                direction="bottom"
                                            />
                                        </div>
                                        <div className='form-group pe-0 col-md-6'>
                                            <label className="form-label">
                                                {t('bookGuest.label.guestMobileNo')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.phone && 'is-invalid'
                                                    }`}
                                                placeholder={t('bookGuest.placeholder.guestMobileNo')}
                                                {...register('phone', { required: true, pattern: formPattern.phoneNumber })}
                                            />
                                            {errors.phone && (
                                                <small>
                                                    {errors.phone.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('bookGuest.error.guestMobileNoReq')}
                                                        </span>
                                                    )}
                                                    {errors.phone.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('bookGuest.error.guestMobileNoInvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                    </div>


                                    <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                        <label className="form-label">
                                            {t('bookGuest.label.nationality')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.nationality && 'is-invalid'
                                                }`}
                                            type="text"
                                            placeholder={t('bookGuest.placeholder.nationality')}
                                            // disabled={formFieldsDisabled}
                                            {...register('nationality', { required: true, pattern: pattern.notAllowSpaceInStartfront })}
                                        />
                                        {errors.nationality && (
                                            <small>
                                                {errors.nationality.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('bookGuest.error.nationality')}
                                                    </span>
                                                )}
                                                {errors.nationality.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('bookGuest.error.nationalityInvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>

                                    <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                        <label>
                                            {t('bookGuest.label.dob')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>

                                        <Controller
                                            name="dob"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <ReactDatePicker
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder={t(
                                                        'bookGuest.placeholder.dob'
                                                    )}
                                                    hasErrors={errors.dob}
                                                    maxDate={moment().toDate()}
                                                    dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                                                // formFieldsDisabled={formFieldsDisabled}
                                                />
                                            )}
                                        />
                                        {errors.dob && (
                                            <small>
                                                {errors.dob.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('bookGuest.error.dob')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-3">
                                        <label>
                                            {t('bookGuest.label.gender')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <Controller
                                            name="gender"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <SearchableDropdown
                                                    value={value}
                                                    onChange={onChange}
                                                    options={genderOptions}
                                                    hasError={errors.gender}
                                                    placeholder={t('bookGuest.placeholder.gender')}
                                                // formFieldsDisabled={formFieldsDisabled}
                                                />
                                            )}
                                        />
                                        {errors.gender && (
                                            <small>
                                                {errors.gender.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('bookGuest.error.gender')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-1">
                                        <label>
                                            {t('bookGuest.label.destination')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <Controller
                                            name="destination_id"
                                            control={control}
                                            rules={{ required: true, pattern: pattern.notAllowSpaceInStartfront }}
                                            render={({ field: { value, onChange } }) => (
                                                <SearchableDropdown
                                                    value={value}
                                                    onChange={onChange}
                                                    options={destinations}
                                                    hasError={errors.destination_id}
                                                    placeholder={t('bookGuest.placeholder.destination')}
                                                // formFieldsDisabled={formFieldsDisabled}
                                                />
                                            )}
                                        />
                                        {errors.destination_id && (
                                            <small>
                                                {errors.destination_id.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('bookGuest.error.destination')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-lg-4  col-md-6 col-sm-6 mb-1">
                                        <label>
                                            {t('bookGuest.label.vehicleRegistration')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.vehicle_reg_no && 'is-invalid'
                                                }`}
                                            type="text"
                                            // disabled={formFieldsDisabled}
                                            placeholder={t('bookGuest.placeholder.vehicle')}
                                            {...register('vehicle_reg_no', { required: true, pattern: pattern.notAllowSpaceInStartfront })}
                                        />
                                        {errors.vehicle_reg_no && (
                                            <small>
                                                {errors.vehicle_reg_no.type === 'required' && (
                                                    <span className="text-danger">
                                                        {t('bookGuest.error.vehicle')}
                                                    </span>
                                                )}
                                                {errors.vehicle_reg_no.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('bookGuest.error.vehicleinvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>




                                    <div className="form-group col-md-4">
                                        <label className="form-label">
                                            {t('bookGuest.label.email')}
                                            {/* <span className="text-danger ms-1">*</span> */}
                                        </label>
                                        <input
                                            type='text'
                                            className={`form-control ${errors.email && 'is-invalid'
                                                }`}
                                            placeholder={t('bookGuest.placeholder.email')}
                                            {...register('email', { pattern: formPattern.email })}
                                        />
                                        {errors.email && (
                                            <small>
                                                {errors.email.type === 'pattern' && (
                                                    <span className="text-danger">
                                                        {t('bookGuest.error.emailInvalid')}
                                                    </span>
                                                )}
                                            </small>
                                        )}
                                    </div>

                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('bookGuest.label.identificationNo')}
                                            {/* <span className="text-danger ms-1">*</span> */}
                                        </label>
                                        <input
                                            className={`form-control ${errors.document_id_number ? 'is-invalid' : ''
                                                }`}
                                            type="text"
                                            placeholder={t('bookGuest.placeholder.identificationNo')}
                                            {...register('document_id_number', {
                                                // required: true
                                            })}
                                        />
                                        {errors.document_id_number && (
                                            <small className="text-danger">
                                                {errors.document_id_number.type === 'required' && (
                                                    <span>{t('bookGuest.error.identificationNoReq')}</span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('bookGuest.label.dateOfMeeting')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <Controller
                                            name='meeting_date'
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange } }) => (
                                                <ReactDatePicker
                                                    value={value}
                                                    selected={value}
                                                    onChange={onChange}
                                                    hasErrors={errors.meeting_date}
                                                    placeholder={t(
                                                        'bookGuest.placeholder.dateOfMeeting'
                                                    )}
                                                    dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                                                    minDate={moment().toDate()}
                                                />
                                            )}
                                        />
                                        {errors.meeting_date && (
                                            <small className="text-danger">
                                                {errors.meeting_date.type === 'required' && (
                                                    <span>{t('bookGuest.error.dateOfMeetingReq')}</span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('bookGuest.label.memberNo')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.member_number ? 'is-invalid' : ''
                                                }`}
                                            type="text"
                                            placeholder={t('bookGuest.placeholder.memberNo')}
                                            {...register('member_number', {
                                                required: true,
                                                pattern: pattern.numberAndAlphabet
                                            })}
                                            onBlur={() => verifyMemberDetails()}
                                        />
                                        {errors.member_number && (
                                            <small className="text-danger">
                                                {errors.member_number.type === 'required' && (
                                                    <span>{t('bookGuest.error.memberNoReq')}</span>
                                                )}
                                                {errors.member_number.type === 'pattern' && (
                                                    <span>{t('bookGuest.error.memberNoinvalid')}</span>
                                                )}
                                            </small>
                                        )}
                                    </div>
                                    <div className="form-group col-md-4 mb-3">
                                        <label className="form-label">
                                            {t('bookGuest.label.memberName')}
                                            <span className="text-danger ms-1">*</span>
                                        </label>
                                        <input
                                            className={`form-control ${errors.member_name ? 'is-invalid' : ''
                                                }`}
                                            type="text"
                                            disabled={true}
                                            placeholder={t('bookGuest.placeholder.memberName')}
                                            {...register('member_name')}
                                        />

                                    </div>

                                </div>

                                <div className="mb-3 mt-3 text-end">
                                    <button type="submit" disabled={(memberName && memberName != "") ? false : true} className="btn btn-primary me-1">
                                        {t('bookGuest.label.submitBtn')}
                                    </button>
                                    <button
                                        type="reset"
                                        className="btn btn-danger"
                                        onClick={onReset}
                                    >
                                        {t('bookGuest.label.resetBtn')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* <div style={{ marginTop: "8rem", marginLeft: ".3rem", padding: ".6rem" }}>
                        <ReactTable
                            columns={columns}
                            data={casualworkerData}
                            pageSizeOption={[5, 10, 15, 20]}
                            headerClass="btn-primary"
                            isSearchBoxVisible
                            ispaginationvisible={true}
                        />
                    </div> */}
                </div>

                <CapturePhotoModal
                    isModalOpen={isMobile === true ? false : isCapturePhotoModalOpen}
                    setIsModalOpen={setIsCapturePhotoModalOpen}
                    setUserPhoto={setProfilePhoto}
                />
                <CaptureDocumentModal
                    isModalOpen={isCaptureFrontDocModalOpen}
                    setIsModalOpen={setIsCaptureFrontDocModalOpen}
                    setUserPhoto={setFrontDocImage}
                />
                <CaptureDocumentModal
                    isModalOpen={isCaptureBackDocModalOpen}
                    setIsModalOpen={setIsCaptureBackDocModalOpen}
                    setUserPhoto={setBackDocImage}
                />

            </div>


        </>
    )
}

export default EditBookAGuest