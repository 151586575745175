import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import ReactTable from 'components/common/ReactTable';
import toastConfig from 'components/configuration/toast-config';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Swalcolor from 'components/configuration/sweetalert-config';
import { encodeData } from 'components/common/encodeParams';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { systemuserlist, systemuserdelete } from 'services/adminmoduleService'
import { rolelist } from 'services/masterModuleService'
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import moment from 'moment';

export default function SystemUsers() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [systemuserData, setSystemUserData] = useState([]);
    const [role, setRoles] = useState([])
    const { isLoading, setIsLoading } = useContext(loaderContext)


    useEffect(async () => {
        checkModulePermission("System Users")
        setIsLoading(true)
        try {
            const { data } = await systemuserlist();
            const { data: rolelists } = await rolelist();
            setSystemUserData(data?.data);
            setRoles(rolelists?.data)
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }, []);

    const onEditRow = (id) => {
        const newData = systemuserData?.find((system) => system?.id === id)
        navigate(`/editsystemuser`, { state: newData });
    };
    const onDeleteRow = (id) => {
        Swal.fire({
            title: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationTitle'
            ),
            text: t('departmentMaster.deleteDepartment.sweetAlert.confirmationText'),
            icon: 'warning',
            showCancelButton: Swalcolor.confirmation.showCancelButton,
            confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
            cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
            confirmButtonText: t(
                'departmentMaster.deleteDepartment.sweetAlert.confirmationBtnLabel'
            )
        }).then(async result => {
            if (result.isConfirmed) {
                setIsLoading(true)
                try {
                    const { data } = await systemuserdelete({ id });
                    console.log(data);
                    setSystemUserData(systemuserData.filter(data => data.id != id));
                    Swal.fire({
                        icon: 'success',
                        title: t(
                            'departmentMaster.deleteDepartment.sweetAlert.simpleTitle'
                        ),
                        text: data.data.result,
                        confirmButtonColor: Swalcolor.simple.confirmButtonColor
                    });
                } catch (e) {
                    errorMessage(e)
                }
                setIsLoading(false)
            }
        });
    };

    console.log("role", role)
    const getStatusBackGrounColor = (row) => {
        if (moment().startOf("day").toDate() <= moment(row?.original?.expiry_date).startOf("day").toDate() && moment().add(3, "days").startOf("day").toDate() >= moment(row?.original?.expiry_date).startOf("day").toDate()) {
            return "var(--warning)"
        } else if (row?.original?.status == 1) {
            return "var(--success)"
        } else {
            return "var(--danger)"
        }
    }

    const columns = [

        {
            Header: t('systemuser.systemusertable.no'),
            accessor: (row, index) => index + 1
        },
        {
            Header: t('systemuser.systemusertable.name'),
            accessor: 'name'
        },
        {
            Header: t('systemuser.systemusertable.email'),
            accessor: 'email'
        },
        {
            Header: t('systemuser.systemusertable.mobileno'),
            accessor: 'phone',
            Cell: ({ row }) => {
                return row?.original?.phone ? `${row?.original?.country_code}-${row?.original?.phone}` : '--'
            }
        },
        {
            Header: t('systemuser.systemusertable.role'),
            accessor: 'role'
        },
        {
            Header: t('systemuser.systemusertable.status'),
            accessor: 'status',
            Cell: ({ row }) => {
                return <div className='text-center' style={{ backgroundColor: getStatusBackGrounColor(row), paddingLeft: "5px", paddingRight: "5px", borderRadius: "12px", color: "var(--white)" }}>{row?.original?.status == 1 ? "Active" : "In Active"}</div>
            },
        },
        {
            Header: t('systemuser.systemusertable.createdby'),
            accessor: 'create_by'
        },
        {
            Header: t('systemuser.systemusertable.createdon'),
            accessor: 'created_on',
            Cell: ({ row }) => (
                <div>
                    {moment.utc(row?.original?.created_on).local().format(process.env.REACT_APP_DATE_TIME_FORMAT)}
                </div>
            )
        },
        {
            Header: t('systemuser.systemusertable.action'),
            Cell: ({ row }) => (
                <div>
                    <span
                        className="btn p-0 me-2"
                        title="Edit"
                        onClick={() => onEditRow(row.original.id)}
                    >
                        <FontAwesomeIcon icon="edit" />
                    </span>
                    <span
                        className="btn p-0"
                        title="Delete"
                        onClick={() => onDeleteRow(row.original.id)}
                    >
                        <FontAwesomeIcon icon="trash-alt" />
                    </span>
                </div>
            ),
            disableSortBy: true
        },
        // {
        //     Header: t('accesscontrol.accesscontroltable.action'),
        //     content: data => (
        //         <div>
        //             <span
        //                 className="btn p-0 me-2" style={{ display: 'flex' }}
        //                 title="Edit"
        //                 onClick={() => onEditRow(data)}
        //             >
        //                 <FontAwesomeIcon icon="edit" />
        //                 <p>{t('userroles.userrolestable.permission')}</p>
        //             </span>
        //             <span
        //                 className="btn p-0"
        //                 title="Delete"
        //                 onClick={() => onDeleteRow(data)}
        //             >
        //                 <FontAwesomeIcon icon="trash-alt" />
        //             </span>
        //         </div>
        //     ),
        //     disableSortBy: true
        // }
    ];
    const setTrProps = (row) => {
        console.log("Current Date", moment().startOf("day").toDate())
        console.log("Expiry Date", moment().add(3, "days").startOf("day").toDate())
        return {
            style: (moment().startOf("day").toDate() <= moment(row?.original?.expiry_date).startOf("day").toDate() && moment().add(3, "days").startOf("day").toDate() >= moment(row?.original?.expiry_date).startOf("day").toDate()) ? {
                backgroundColor: "var(--warning)",
                color: "var(--white)"
            } : {}
        }
    }

    return (
        <>
            <div className="row g-3 mb-3">
                <div className="">
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-xl-8 col-lg-6 col-md-4 heading-text">
                                    <h3 className="heading-color fw-600 mb-0">
                                        {t('systemuser.systemuserheading')}
                                    </h3>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-8 table-btn" >
                                    <button
                                        className="btn btn-primary btn-sm float-end"
                                        onClick={() => {
                                            navigate('/createsystemuser');
                                        }}
                                    >
                                        {/* <FontAwesomeIcon icon="plus" /> */}
                                        <span className="ms-1 d-sm-inline">
                                            {t('systemuser.createsystemuserbtn')}
                                        </span>
                                    </button>
                                </div>
                                {/* <div className="col-4">
                                    <button
                                        className="btn btn-primary btn-sm float-end"
                                        onClick={() => {
                                            navigate('/master/department-master/create-department');
                                        }}
                                    >
                                        <FontAwesomeIcon icon="plus" />
                                        <span className="ms-1 d-sm-inline d-none">
                                            {t('masters.departments.departmentcreatebtn')}
                                        </span>
                                    </button>
                                </div> */}
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <ReactTable
                                columns={columns}
                                data={systemuserData}
                                pageSizeOption={[5, 10, 15, 20]}
                                headerClass="btn-primary"
                                isSearchBoxVisible
                                ispaginationvisible={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
