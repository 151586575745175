import React from 'react';
import { useTranslation } from 'react-i18next';
import VerifyOtpForm from '../VerifyOtpForm';

export default function VerifyOtp() {
  const {t}=useTranslation();
  return (
    <div>
      <div className="mb-2 d-flex flex-column align-items-center">
        <h2 className="fw-bold mt-4 heading-color">{t("verifyOtpPage.heading")}</h2>
        <p>{t("verifyOtpPage.description")}</p>
      </div>
      <VerifyOtpForm />
    </div>
  );
}
