import React, { useContext, useRef, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { t } from 'i18next';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import { loaderContext } from 'context/Context';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { listmessage } from 'services/adminmoduleService'

export default function CommonEditor({ initialValue, onChange, msgType }) {

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    return (
        <>

            <div>
                <Editor
                    apiKey={process.env.REACT_APP_EDITORAPI_KEY}
                    onInit={(evt, editor) => editorRef.current = editor}
                    value={initialValue}
                    onEditorChange={(e) => onChange({ msg: e, msgType })}
                    init={{
                        menubar: true,
                        plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                        ],
                        toolbar: 'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                />
            </div>
        </>
    );
}