import { applicationSettingEndPoints } from "./config"
import httpServices from "./httpServices"

export const smsgateway = async (smsData) => {
    return await httpServices.post(applicationSettingEndPoints.smsgateway, smsData)
}
export const whatsappgateway = async (whatsappData) => {
    return await httpServices.post(applicationSettingEndPoints.whatsappgateway, whatsappData)
}
export const emailgateway = async (emailData) => {
    return await httpServices.post(applicationSettingEndPoints.emailgateway, emailData)
}
export const getfrontdesk = async () => {
    return await httpServices.get(applicationSettingEndPoints.getmanagefrontdesk)
}
export const frontdeskupdate = async (updateData) => {
    return await httpServices.post(applicationSettingEndPoints.updatefrontdesk, updateData)
}