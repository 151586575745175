import React, { useContext, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext, { loaderContext } from 'context/Context';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import routes, { dashboard, navbarmenu } from 'routes/routes';
import { capitalize } from 'helpers/utils';
import bgNavbar from 'assets/img/generic/bg-navbar.png';
import 'assets/css/navbar.css';
import { useTranslation } from 'react-i18next';
import UiLayout from 'layouts/UiLayout';
import { useState } from 'react';



const NavbarVertical = () => {
  const { t } = useTranslation();
  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu
    }
  } = useContext(AppContext);
  const [permissionRoutes, setPermissionRoutes] = useState([])
  const [render, setRender] = useState(false)
  const muRoutes = [...routes]
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  console.log("My routes", routes)
  // useEffect(() => {
  //   if (isNavbarVerticalCollapsed) {
  //     HTMLClassList.add('navbar-vertical-collapsed');
  //   } else {
  //     HTMLClassList.remove('navbar-vertical-collapsed');
  //   }
  //   return () => {
  //     HTMLClassList.remove('navbar-vertical-collapsed-hover');
  //   };
  // }, [isNavbarVerticalCollapsed, HTMLClassList]);

  // //Control mouseEnter event
  // let time = null;
  // const handleMouseEnter = () => {
  //   if (isNavbarVerticalCollapsed) {
  //     time = setTimeout(() => {
  //       HTMLClassList.add('navbar-vertical-collapsed-hover');
  //     }, 100);
  //   }
  // };
  // const handleMouseLeave = () => {
  //   clearTimeout(time);
  //   HTMLClassList.remove('navbar-vertical-collapsed-hover');
  // };

  const permission = localStorage.getItem("userPrmission")
  useEffect(() => {
    if (permission) {
      getPermissiondRoute()
    } else {
      setRender(!render)
    }

  }, [permission])


  const getPermissiondRoute = () => {
    const userPermissions = JSON.parse(localStorage.getItem("userPrmission")).permission.map((perm) => {
      let newPerms = { name: "", child: [] };
      newPerms.name = perm?.display_name;
      newPerms.child = perm?.child.map((child) => child?.display_name)
      return newPerms

    })
    console.log("permission", userPermissions)
    const newRoutes = muRoutes?.map((route) => {
      const routeVar = { ...route };
      if (routeVar?.label != "Booking") {
        if (routeVar?.label?.includes("masters")) {
          routeVar.children = routeVar?.children?.map((subChild) => {
            const subChildVar = { ...subChild };
            const relatedPerm = userPermissions?.find((per, index) => per?.name == t(subChildVar?.name))
            if (relatedPerm) {
              subChildVar.children = subChildVar?.children?.map((smallChild) => {
                const smallChildVar = { ...smallChild }
                if (relatedPerm?.child?.find((chi) => chi == t(smallChildVar?.name))) {
                  return smallChildVar
                }
              })
              return subChildVar
            }
          })
        }
        else if (routeVar?.label?.includes("dashboard")) {
          return routeVar;
        }
        else {
          return userPermissions?.find((per, index) => per?.name == t(routeVar?.label)) ? routeVar : false
        }
        return routeVar
      }
    })
    console.log("New Routes", newRoutes)
    setPermissionRoutes(newRoutes)
  }

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant="light"
      style={{ zIndex: "1050" }}
    >
      <Flex alignItems="center">
        <ToggleButton />
        <Logo at="navbar-vertical" width={40} />
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        className='nav-colapse'
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === 'vibrant'
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : 'none'
        }}
      >
        <div className="navbar-vertical-content scrollbar media-nav" >
          <Nav className="flex-column" as="ul">
            {permissionRoutes.map(route => (
              <>
                {route && <Fragment key={route.label}>
                  {!route.labelDisable && (
                    <NavbarLabel label={capitalize(t(route.label))} />
                  )}
                  <NavbarVerticalMenu routes={route?.children ? route?.children : []} />
                </Fragment>}
              </>
            ))}
          </Nav>
        </div>

        {/* <UiLayout /> */}


      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string
};

export default NavbarVertical;
