import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import formPattern from 'components/configuration/form-pattern';
import InputPassword from 'components/common/InputPassword';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactTimePicker from 'components/common/ReactTimePicker';
import ReactDatePicker from 'components/common/ReactDatePicker';
import CountryCode from 'components/common/CountryCode';
import { useState } from 'react';
import ReactTable from 'components/common/ReactTable';
import { AuthWizardContext, eventBooking, loaderContext } from 'context/Context';
import ReactDateAndTimePicker from 'components/common/ReactDateAndTimePicker';
import moment from 'moment';
import { addExternalGuest, getExternalEventById } from 'services/bookingService';
import { Modal, Button, Form, } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { guestdelete } from 'services/manageEventsService'
import Swalcolor from 'components/configuration/sweetalert-config';
import Swal from 'sweetalert2';
import { singleguestadd } from 'services/manageEventsService'
import pattern from 'components/configuration/form-pattern';
import { defaultCountryCode } from 'components/configuration/country-code';





function EventDetailAddModal({ isModalVisible, setIsModalVisible, event, setevent, eventid }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const { locationList } = useContext(eventBooking)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { user, setUser, step, setStep, } = useContext(AuthWizardContext);
    const [countryCode, setCountryCode] = useState({});
    // const [eventdetailData, setEventDetailData] = useState([{ no: '1', guestname: 'john doe', guestidno: '009', guestmobileno: '---', guestemail: 'john@45.com', status: 'Not Arrived' }, { no: '2', guestname: 'john doe', guestidno: '009', guestmobileno: '+232 3454 434 345', guestemail: '---', status: ' Arrived' }]);
    const [eventdetailData, setEventDetailData] = useState([]);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        setError,
        watch,
    } = useForm();



    // useEffect(async () => {
    //     if (step === 3) {
    //         try {
    //             const { data } = await getExternalEventById({ event_id: user?.eventData?.event_id })
    //             setValue("member_name", data?.data[0]?.event_details?.member_name)
    //             setValue("member_number", data?.data[0]?.event_details?.member_number)
    //             setValue("name", data?.data[0]?.event_details?.name)
    //             setValue("no_of_guest", data?.data[0]?.event_details?.guest_no)
    //             setValue("location_id", locationList.find((event) => event?.value == data?.data[0]?.event_details?.location_id)?.label)
    //             setValue("date", moment(data?.data[0]?.event_details?.date).toDate())
    //             setEventDetailData(data?.data[0]?.event_details?.guest_list)
    //         } catch (error) {
    //             errorMessage(error)
    //         }
    //     }
    // }, [step])

    console.log("-------------user", user.members)


    // const columns = [

    //     {
    //         Header: t('eventbooking.eventdetail.no'),
    //         accessor: (row, index) => index + 1
    //     },
    //     {
    //         Header: t('eventbooking.eventdetail.guestname'),
    //         accessor: 'name',
    //     },
    //     {
    //         Header: t('eventbooking.eventdetail.guestmbno'),
    //         accessor: (row) => `${row.country_code} ${row.phone}`,
    //     },
    //     {
    //         Header: t('eventbooking.eventdetail.guestemail'),
    //         accessor: 'email'
    //     }, {
    //         Header: t('eventbooking.label.status'),
    //         accessor: 'status',
    //         Cell: ({ row }) => {
    //             return (
    //                 <div>{`${row.original.status === 0 ? t('eventbooking.label.notarrived') : t('eventbooking.label.arrived')}`}</div>
    //             );
    //         }
    //     },
    //     {
    //         Header: t('eventsApproval.label.action'),
    //         Cell: ({ row }) => (
    //             <div>
    //                 <span
    //                     className="btn p-0 me-2"
    //                     title="Delete"
    //                     onClick={() => DeleteGuest(row.original.event_guest_id, row.original.event_id)}
    //                 >
    //                     <FontAwesomeIcon icon="trash-alt" />
    //                 </span>
    //             </div>
    //         )
    //     }
    // ];

    // const handleOpen = () => {
    //     console.log("handleopen")
    //     setIsModalOpen(true);
    // }

    useEffect(() => {
        setValue("name", "")
        setValue("phone", "")
        setValue("email", "")
    }, [isModalVisible])


    const onSubmit = async (eventdata) => {
        setIsLoading(true)
        try {
            eventdata.country_code = countryCode?.dial_code
            eventdata.event_id = eventid
            const { data } = await addExternalGuest(eventdata)
            // setStep(3);
            setevent([...event, eventdata]);
            toast.success(data.message, toastConfig)
            setIsModalVisible(false)

        } catch (e) {
            if (e.response.data) {
                if (Array.isArray(e.response.data.message)) {
                    e.response.data.message.map(errors => toast.error(errors, toastConfig))
                } else {
                    toast.error(e.response.data.message, toastConfig)
                }
            } else {
                toast.error(e.message, toastConfig)
            }
        }
        setIsLoading(false)
    }
    // const DeleteGuest = async (id, eventId) => {
    //     Swal.fire({
    //         title: t(
    //             'departmentMaster.deleteDepartment.sweetAlert.confirmationTitle'
    //         ),
    //         text: t('departmentMaster.deleteDepartment.sweetAlert.confirmationText'),
    //         icon: 'warning',
    //         showCancelButton: Swalcolor.confirmation.showCancelButton,
    //         confirmButtonColor: Swalcolor.confirmation.confirmButtonColor,
    //         cancelButtonColor: Swalcolor.confirmation.cancelButtonColor,
    //         confirmButtonText: t(
    //             'departmentMaster.deleteDepartment.sweetAlert.confirmationBtnLabel'
    //         )
    //     }).then(async result => {
    //         if (result.isConfirmed) {
    //             try {
    //                 setEventDetailData(eventdetailData?.filter((event) => event.event_guest_id !== id))
    //                 const { data } = await guestdelete({ event_id: eventId, event_guest_id: id })
    //                 toast.success(data.message, toastConfig)
    //                 Swal.fire({
    //                     icon: 'success',
    //                     title: t(
    //                         'departmentMaster.deleteDepartment.sweetAlert.simpleTitle'
    //                     ),
    //                     text: data.data.result,
    //                     confirmButtonColor: Swalcolor.simple.confirmButtonColor
    //                 });
    //             } catch (e) {
    //                 console.log(e)
    //             }
    //         }
    //     });

    // }
    const handleReset = () => {
        reset();
        setCountryCode(defaultCountryCode)
    }
    return (
        <>

            <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)} size="xl" centered={true}>
                <Modal.Header closeButton className="bg-light border-bottom-0">
                    <Modal.Title>
                        <h2>{t('eventGuestModal.heading')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <div className='row'>
                            <div className="form-group col-md-4 mb-3">
                                <label className="form-label">
                                    {t('eventGuestModal.label.guestname')}
                                    <span className="text-danger ms-1">*</span>
                                </label>
                                <input
                                    type='text'
                                    className={`form-control ${errors.name && 'is-invalid'
                                        }`}
                                    placeholder={t('eventGuestModal.placeholder.guestname')}
                                    {...register('name', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                />
                                {errors.name && (
                                    <small>
                                        {errors.name.type === 'required' && (
                                            <span className="text-danger">
                                                {t('eventGuestModal.error.guestname')}
                                            </span>
                                        )}
                                        {errors.name.type === 'pattern' && (
                                            <span className="text-danger">
                                                {t('eventGuestModal.error.guestnameInvalid')}
                                            </span>
                                        )}
                                    </small>
                                )}
                            </div>


                            <div className='form-group pe-0 col-md-4'>
                                <label className="form-label">
                                    {t('bookGuest.label.countryCode')}
                                    {/* <span className="text-danger ms-1">*</span> */}
                                </label>
                                <CountryCode
                                    countryCode={countryCode}
                                    setCountryCode={setCountryCode}
                                    direction="bottom"
                                />
                            </div>

                            <div className="form-group col-md-4 mb-3">
                                <label className="form-label">
                                    {t('eventGuestModal.label.guestno')}
                                    {/* <span className="text-danger ms-1">*</span> */}
                                </label>
                                <input
                                    type='text'
                                    className={`form-control ${errors.phone && 'is-invalid'
                                        }`}
                                    placeholder={t('eventGuestModal.placeholder.guestno')}
                                    {...register('phone', { pattern: formPattern.phoneNumber })}
                                />
                                {errors.phone && (
                                    <small>
                                        {/* {errors.phone.type === 'required' && (
                                            <span className="text-danger">
                                                {t('eventGuestModal.error.guestno')}
                                            </span>
                                        )} */}
                                        {errors.phone.type === 'pattern' && (
                                            <span className="text-danger">
                                                {t('eventGuestModal.error.guestnoInvalid')}
                                            </span>
                                        )}
                                    </small>
                                )}
                            </div>

                            <div className="form-group col-md-4 mb-3">
                                <label className="form-label">
                                    {t('eventGuestModal.label.guestemail')}
                                    {/* <span className="text-danger ms-1">*</span> */}
                                </label>
                                <input
                                    type='text'
                                    className={`form-control ${errors.email && 'is-invalid'
                                        }`}
                                    placeholder={t('eventGuestModal.placeholder.guestemail')}
                                    {...register('email', { pattern: formPattern.email })}
                                />
                                {errors.email && (
                                    <small>
                                        {/* {errors.email.type === 'required' && (
                                            <span className="text-danger">
                                                {t('eventGuestModal.error.guestemail')}
                                            </span>
                                        )} */}
                                        {errors.email.type === 'pattern' && (
                                            <span className="text-danger">
                                                {t('eventGuestModal.error.guestemailInvalid')}
                                            </span>
                                        )}
                                    </small>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="bg-light px-card border-top-0">
                        <button
                            className='btn btn-primary'
                            type='button'
                            onClick={handleSubmit(onSubmit)}
                        >
                            {t('eventapproval.submit')}
                        </button>
                        <button
                            className="btn btn-danger "
                            type="reset"
                            onClick={handleReset}
                        >
                            {t('eventapproval.reset')}
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>
    )
}

export default EventDetailAddModal