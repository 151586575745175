import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import formPattern from 'components/configuration/form-pattern';
import InputPassword from 'components/common/InputPassword';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactTimePicker from 'components/common/ReactTimePicker';
import ReactDatePicker from 'components/common/ReactDatePicker';
import CountryCode from 'components/common/CountryCode';
import { useState } from 'react';
import Wizard from 'components/common/Wizard';
import { eventBooking, loaderContext } from 'context/Context';
import { getMemberVerify } from 'services/commonService';
import CryptoJS from 'crypto-js';
import { getlocation } from 'services/manageEventsService';
import { getExternalLocations } from 'services/bookingService';


function EventBooking() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams()
    const { isLoading, setIsLoading, setMemberNumber } = useContext(loaderContext)
    const [countryCode, setCountryCode] = useState({});
    const [memberData, setMemberData] = useState({})
    const [locationList, setLocationList] = useState([])
    const [isLinkInvalid, setIsLinkInvalid] = useState(false)
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        formState: { errors }
    } = useForm();
    const verifyMember = async () => {
        setIsLoading(true)
        if (params?.memberId) {

            try {
                const hash = CryptoJS.SHA256(`${process.env.REACT_APP_SECRET_KEY}${params?.memberId}`).toString();
                const { data: memberData } = await getMemberVerify({ Search: params?.memberId, Hash: hash })
                const { data } = await getExternalLocations();
                setLocationList(data?.data)
                if (memberData?.MemberNumber != "") {
                    setMemberData(memberData)
                    setMemberNumber(params?.memberId)
                } else {
                    setIsLinkInvalid(true)
                }
            } catch (error) {
                errorMessage(error)
            }
        } else {
            setIsLinkInvalid(true)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        verifyMember()
    }, [])

    // const onSubmit = async accessData => {
    //     try {
    //         const { data } = await addAccess(accessData);
    //         toast.success(data.data.result, toastConfig);
    //         navigate('/master/access-control');
    //     } catch (e) {
    //         errorMessage(e)
    //     }
    //     console.log(accessData);
    // };

    const onSubmit = (data) => {
        console.log(data)
        navigate('/booking/upload-event')
    }
    const onReset = () => {
        reset();
        setValue('accessControlName', '');
    };
    return (
        <>
            <div className="mb-3 row g-3">
                <div className="card overflow-hidden mb-3">
                    <div className="card-header">
                        {!isLinkInvalid ?
                            <div className="row">
                                <div className="col-12">
                                    <h3 className="heading-color fw-600">
                                        {t('eventbooking.heading')}
                                    </h3>
                                </div>
                                <div className="col-12 mb-3">
                                    <hr />
                                </div>
                                {/* <form onSubmit={handleSubmit(onSubmit)} autoComplete="off"> */}
                                <div>
                                    <eventBooking.Provider value={{ memberData, locationList: locationList.map((location) => ({ label: location?.name, value: location?.id })) }}>
                                        <Wizard />
                                    </eventBooking.Provider>
                                </div>

                                {/* </form> */}
                            </div> :
                            <div className="row">
                                <p>Link is invalid.</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventBooking