import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import toastConfig from 'components/configuration/toast-config';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import formPattern from 'components/configuration/form-pattern';
import InputPassword from 'components/common/InputPassword';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactTimePicker from 'components/common/ReactTimePicker';
import ReactDatePicker from 'components/common/ReactDatePicker';
import CountryCode from 'components/common/CountryCode';
import { useState } from 'react';
import { useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { getListOfIdentificationType, getMemberVerify } from 'services/commonService';
import { externalGuestbooking, getExternalIdentificationType } from 'services/bookingService';
import moment from 'moment';
import pattern from 'components/configuration/form-pattern';
import { loaderContext } from 'context/Context';
import { defaultCountryCode } from 'components/configuration/country-code';
import CapturePhotoModal from 'components/common/CapturePhotoModal';
import CaptureDocumentModal from 'components/common/CaptureDocumentModal';
import { genderOptions } from 'components/configuration/common-options';
import { getDestinationList } from 'services/guestRegistrationService';


function GuestBooking() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { isLoadings, setIsLoading, setMemberNumber } = useContext(loaderContext)
    const [countryCode, setCountryCode] = useState({});
    const [indentificationType, setIndentificationType] = useState([]);
    const [isLinkInvalid, setIsLinkInvalid] = useState(false);
    const [memberData, setMemberData] = useState({})
    const [destinations, setDestinations] = useState([]);



    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        watch,
        formState: { errors }
    } = useForm();

    const documentType = watch("document_type");
    const isMobile = navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i) ? true : false

    const setIntialData = async () => {
        console.log("Params", params)
        setIsLoading(true)
        try {
            if (params?.memberId) {
                const hash = CryptoJS.SHA256(`${process.env.REACT_APP_SECRET_KEY}${params?.memberId}`).toString();
                const { data } = await getExternalIdentificationType();
                setIndentificationType(data?.data?.map(identification => ({ label: identification?.name, value: identification?.id })))
               

                const { data: memberData } = await getMemberVerify({ Search: params?.memberId, Hash: hash })
                if (memberData?.MemberNumber != "") {
                    setMemberData(memberData)
                    setMemberNumber(params?.memberId)
                } else {
                    setIsLinkInvalid(true)
                }
            } else {
                setIsLinkInvalid(true)
            }
        } catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    }
    useEffect(() => {
        setIntialData()
    }, [])

    const onSubmit = async formData => {
        setIsLoading(true)
        try {
                formData.document_type = formData?.document_type?.value
                formData.meeting_date = moment(formData.meeting_date).format(process.env.REACT_APP_DATE_FORMAT)
                formData.member_name = `${memberData?.FirstName} ${memberData?.lastName}`
                formData.member_number = memberData?.MemberNumber
                formData.member_email = memberData?.Email
                formData.member_phone = memberData?.Mobile
                formData.member_profile = memberData?.Photourl
                formData.member_country_code = ""
                formData.country_code = countryCode?.dial_code
                const { data } = await externalGuestbooking(formData)
                toast.success(data?.message, toastConfig);
                onReset()
            }
         catch (e) {
            errorMessage(e)
        }
        setIsLoading(false)
    };
    const onReset = () => {
        reset();
        setValue('document_type', '')
        setCountryCode(defaultCountryCode)
    };
    return (
        <>
            <div className="mb-3 row g-3">
                {!isLinkInvalid ?
                    <div className="card overflow-hidden mb-3">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-12">
                                    <h3 className="heading-color fw-600">
                                        {t('guestbooking.heading')}
                                    </h3>
                                </div>
                                <div className="col-12 mb-3">
                                    <hr />
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

                                    <div className='row'>
                                        <div className='form-group col-md-2 '>
                                            <label className="form-label">
                                                {t('guestbooking.guestbookingform.countrycode')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <CountryCode
                                                countryCode={countryCode}
                                                setCountryCode={setCountryCode}
                                                direction="bottom"
                                            />
                                        </div>
                                        <div className='form-group col-md-2'>
                                            <label className="form-label">
                                                {t('guestbooking.guestbookingform.guestmbno')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.phone && 'is-invalid'
                                                    }`}
                                                placeholder={t('guestbooking.placeholder.guestmbno')}
                                                {...register('phone', { required: true, pattern: formPattern.phoneNumber })}
                                            />
                                            {errors.phone && (
                                                <small>
                                                    {errors.phone.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('guestbooking.error.guestMobileNoReq')}
                                                        </span>
                                                    )}
                                                    {errors.phone.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('guestbooking.error.guestMobileNoInvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>

                                        <div className="form-group col-md-4 mb-3">
                                            <label className="form-label">
                                                {t('guestbooking.guestbookingform.guestfirstname')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.first_name && 'is-invalid'
                                                    }`}
                                                placeholder={t('guestbooking.placeholder.guestfirstname')}
                                                {...register('first_name', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                            />
                                            {errors.first_name && (
                                                <small>
                                                    {errors.first_name.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('guestbooking.error.guestFirstNameReq')}
                                                        </span>
                                                    )}
                                                    {errors.first_name.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('guestbooking.error.guestFirstNameInvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-4 mb-3">
                                            <label className="form-label">
                                                {t('guestbooking.guestbookingform.guestlastname')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.last_name && 'is-invalid'
                                                    }`}
                                                placeholder={t('guestbooking.placeholder.guestlastname')}
                                                {...register('last_name', { required: true, pattern: pattern.notAllowSpaceInStart })}
                                            />
                                            {errors.last_name && (
                                                <small>
                                                    {errors.last_name.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('guestbooking.error.guestLastNameReq')}
                                                        </span>
                                                    )}
                                                    {errors.last_name.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('guestbooking.error.guestLastNameInvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="form-group col-md-4">
                                            <label className="form-label">
                                                {t('guestbooking.guestbookingform.email')}
                                                {/* <span className="text-danger ms-1">*</span> */}
                                            </label>
                                            <input
                                                type='text'
                                                className={`form-control ${errors.email && 'is-invalid'
                                                    }`}
                                                placeholder={t('guestbooking.placeholder.email')}
                                                {...register('email', { pattern: formPattern.email })}
                                            />
                                            {errors.email && (
                                                <small>
                                                    {errors.email.type === 'pattern' && (
                                                        <span className="text-danger">
                                                            {t('bookGuest.error.emailInvalid')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label className="form-label">
                                                {t('guestbooking.guestbookingform.identificationtype')}
                                             
                                            </label>
                                            <Controller
                                                name="document_type"
                                                control={control}
                                            
                                                render={({ field: { value, onChange } }) => {
                                                    return (
                                                        <SearchableDropdown
                                                            value={value}
                                                            onChange={onChange}
                                                            options={indentificationType}
                                                            placeholder={t(
                                                                'guestbooking.placeholder.identificationtype'
                                                            )}
                                                            isClearable={true}
                                                            hasError={errors.document_type}
                                                        />
                                                    )
                                                }}
                                            />
                                            {errors.document_type && (
                                                <small>
                                                    {errors.document_type.type === 'required' && (
                                                        <span className="text-danger">
                                                            {t('bookGuest.error.identificationTypeReq')}
                                                        </span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-4 mb-3">
                                            <label className="form-label">
                                                {t('guestbooking.guestbookingform.identificationno')}
                                                {/* <span className="text-danger ms-1">*</span> */}
                                            </label>
                                            <input
                                                className={`form-control ${errors.document_id_number ? 'is-invalid' : ''
                                                    }`}
                                                type="text"
                                                placeholder={t('guestbooking.placeholder.identificationno')}
                                                {...register('document_id_number', {
                                                    // required: true
                                                })}
                                            />
                                            {errors.document_id_number && (
                                                <small className="text-danger">
                                                    {errors.document_id_number.type === 'required' && (
                                                        <span>{t('bookGuest.error.identificationNoReq')}</span>
                                                    )}
                                                </small>
                                            )}
                                        </div>
                                        <div className="form-group col-md-4 mb-3">
                                            <label className="form-label">
                                                {t('guestbooking.guestbookingform.dateofmeeting')}
                                                <span className="text-danger ms-1">*</span>
                                            </label>
                                            <Controller
                                                name='meeting_date'
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: { value, onChange } }) => (
                                                    <ReactDatePicker
                                                        value={value}
                                                        selected={value}
                                                        onChange={onChange}
                                                        hasErrors={errors.meeting_date}
                                                        placeholder={t(
                                                            'bookGuest.placeholder.dateOfMeeting'
                                                        )}
                                                        dateFormat={process.env.REACT_APP_DATE_PICKER_FORMAT}
                                                        minDate={moment().toDate()}
                                                    />
                                                )}
                                            />
                                            {errors.meeting_date && (
                                                <small className="text-danger">
                                                    {errors.meeting_date.type === 'required' && (
                                                        <span>{t('guestbooking.error.dateOfMeetingReq')}</span>
                                                    )}
                                                </small>
                                            )}
                                        </div>

                                    </div>

                                    <div className="mb-3 mt-3 text-end">
                                        <button type="submit" className="btn btn-primary me-1">
                                            {t('guestbooking.guestbookingform.submit')}
                                        </button>
                                        <button
                                            type="reset"
                                            className="btn btn-danger"
                                            onClick={onReset}
                                        >
                                            {t('guestbooking.guestbookingform.reset')}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> :
                    <div className="card overflow-hidden mb-3">
                        <p>This link is invalid.</p>
                    </div>
                }

                
            </div>
        </>
    )
}

export default GuestBooking