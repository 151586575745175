import React, { useState, useEffect, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';
import ReactTable from 'components/common/ReactTable';
import { useForm, Controller } from 'react-hook-form';
import SearchableDropdown from 'components/common/SearchableDropdown';
import ReactDatePicker from 'components/common/ReactDatePicker';
import Dropdown from 'react-bootstrap/Dropdown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import OutsideClickHandler from 'react-outside-click-handler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkModulePermission } from 'components/common/helper-function/ModulePermissionCheck';
import './report.css'
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';
import { loaderContext } from 'context/Context';
import { qrreportlist } from 'services/reportsService'
import moment from 'moment';
import { getListOfIdentificationType } from 'services/commonService';




function QrCodeCounterReport() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const { isLoading, setIsLoading } = useContext(loaderContext)
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [qrdata, setQrData] = useState([]);
    const [docOption, setDocOption] = useState([]);
    const [isOpen, setIsOpen] = useState({ id: null, open: false });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        watch
    } = useForm();

    useEffect(async () => {
        checkModulePermission("QR Code Counter Report")
        setIsLoading(true)
        try {
            // const { data } = await getlocation()
            const { data: qr } = await qrreportlist({})
            const { data: identificationType } = await getListOfIdentificationType();
            setDocOption(identificationType?.data?.map((ident) => ({ label: ident?.name, value: ident?.id })))
            setQrData(qr?.data)
        } catch (error) {
            errorMessage(error)
        }
        setIsLoading(false)
    }, [])




    const columns = [

        {
            Header: t('qrcodereport.qrcodereporttable.name'),
            accessor: 'name',
            Cell: ({ row }) => {
                return row?.original?.name ? row?.original?.name : "--"
            }
        },
        {
            Header: t('qrcodereport.qrcodereporttable.contactnumber'),
            accessor: 'phone',
            Cell: ({ row }) => {
                return row?.original?.phone ? row?.original?.phone : "--"
            }
        },
        {
            Header: t('qrcodereport.qrcodereporttable.idtype'),
            accessor: 'document_id_type',
            Cell: ({ row }) => {
                return row?.original?.document_id_type ? row?.original?.document_id_type : "--"
            }
        },
        {
            Header: t('qrcodereport.qrcodereporttable.idno'),
            accessor: 'doc_id_number',
            Cell: ({ row }) => {
                return row?.original?.doc_id_number ? row?.original?.doc_id_number : "--"
            }
        },
        {
            Header: t('qrcodereport.qrcodereporttable.qrcode'),
            accessor: 'qr_code',
            Cell: ({ row }) => {
                return row?.original?.qr_code ? row?.original?.qr_code : "--"
            }
        },
        {
            Header: t('qrcodereport.qrcodereporttable.count'),
            accessor: 'qr_count',
            Cell: ({ row }) => {
                return row?.original?.qr_count ? row?.original?.qr_count : "--"
            }
        },
        {
            Header: t('qrcodereport.qrcodereporttable.counter'),
            className: 'text-end',
            isExpand: true,
            Cell: ({ row }) => {

                return (
                    <div >
                        <span
                            className="btn p-0 me-2"
                            onClick={() => {
                                setIsOpen({ id: row.original.id, open: true });
                            }}
                        >
                            <FontAwesomeIcon icon="ellipsis-h" />
                        </span>
                        {
                            (isOpen.open && row.original.id == isOpen.id) &&
                            <OutsideClickHandler onOutsideClick={() => setIsOpen({ id: row.original.id, open: false })}>
                                <div className='card position-absolute p-2' style={{ right: "6%", minWidth: "15%", zIndex: "999", boxShadow: "0 0 6px 0 rgba(0,0,0,0.45) inset" }}>
                                    <div className='px-3' style={{ cursor: "pointer" }}  {...row.getToggleRowExpandedProps()}>
                                        <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{t('qrcodereport.view')}</span>
                                    </div>
                                </div >
                            </OutsideClickHandler>
                        }
                    </div>
                );
            },


            disableSortBy: true
        },

    ];

    const onSubmit = async (qrdata) => {
        console.log("qrDataaa", qrdata)
        setIsLoading(true)

        if (qrdata?.doc_id_number || qrdata?.document_type_id || qrdata?.qr_code || qrdata?.date_range) {
            try {
                qrdata.document_type_id = qrdata.document_type_id?.value
                if (startDate && endDate) {
                    qrdata.date_range = `${moment(startDate).format(process.env.REACT_APP_FILTER_DATE)}-${moment(endDate).format(process.env.REACT_APP_FILTER_DATE)}`;
                }
                const { data } = await qrreportlist(qrdata)
                setQrData(data?.data)
            } catch (e) {
                if (e.response.data) {
                    if (Array.isArray(e.response.data.message)) {
                        e.response.data.message.map(errors => toast.error(errors, toastConfig))
                    } else {
                        toast.error(e.response.data.message, toastConfig)
                    }
                } else {
                    toast.error(e.message, toastConfig)
                }
            }
        }
        setIsLoading(false)


    };















    const downloadPDF = () => {
        const docDefinition = {
            pageOrientation: 'landscape',
            pageSize: { width: 800, height: 600 },
            content: [
                { text: 'QR Code Counter Report', style: 'header' },
                { text: '\n' }
            ],
            defaultStyle: {
                actionHeader: false,
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                },
                tableHeading: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 10]
                }
            },
        };

        qrdata.forEach((mainRow, index) => {
            const mainRowContent = [
                { text: `QR Code`, style: 'tableHeading' },
                {
                    table: {
                        headerRows: 1,
                        widths: Array.from({ length: columns.length }, () => 'auto'),
                        body: [
                            columns.map((column) => column.Header),
                            columns.map((column) => {
                                return mainRow[column.accessor] !== undefined ? mainRow[column.accessor] : '';
                            }),
                        ],
                    },
                },
                { text: '\n' },
                { text: 'Scan Device List', style: 'tableHeading' },
                {
                    table: {
                        headerRows: 1,
                        widths: Array.from({ length: subColumns.length }, () => 'auto'),
                        body: [
                            subColumns.map((column) => column.Header),
                            ...(mainRow.scan_device_list || []).map((nestedRow) => subColumns.map((column) => {
                                return nestedRow[column.accessor] !== undefined ? nestedRow[column.accessor] : '';
                            })),
                        ],
                    },
                },
                { text: '\n' },
                { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 700, y2: 0, lineWidth: 1 }] }
            ];

            docDefinition.content.push(...mainRowContent);

            if (index !== qrdata.length - 1) {
                docDefinition.content.push({ canvas: [{ type: 'line', x1: 0, y1: 0, x2: 700, y2: 0, lineWidth: 1 }] });
            }
        });

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.download('qr_code_counter_report.pdf');
    };

    const downloadCSV = () => {
        let csvMainData = [
            columns.map((column) => column.Header)
        ];

        qrdata.forEach((row) => {
            const rowData = columns.map((column) => {
                return row[column.accessor] !== undefined ? row[column.accessor] : '';
            });
            csvMainData.push(rowData);

            const nestedRows = [];
            if (row.scan_device_list && row.scan_device_list.length > 0) {
                const nestedHeader = subColumns.map((subColumn) => {
                    if (subColumn.Header !== t('sosreport.sosnestedtable.no')) return subColumn.Header;
                });
                csvMainData.push(nestedHeader);
                row.scan_device_list.forEach((device) => {
                    const nestedRowData = subColumns.map((subColumn) => {
                        return device[subColumn.accessor] !== undefined ? device[subColumn.accessor] : '';
                    });
                    nestedRows.push(nestedRowData);
                });
            }

            csvMainData.push(...nestedRows);
            csvMainData.push([]);
        });

        const csvLines = csvMainData.map((row) => row.join(',')).join('\n');

        const blob = new Blob([csvLines], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'qr_code_counter_report.csv');
    };





    const subColumns = [

        {
            Header: t('qrcodereport.qrnestedtable.no'),
            accessor: (row, index) => index + 1
        },
        {
            Header: t('qrcodereport.qrnestedtable.devicename'),
            accessor: 'device_name',
            Cell: ({ row }) => {
                return row?.original?.device_name ? row?.original?.device_name : "--"
            }
        },
        {
            Header: t('qrcodereport.qrnestedtable.scantime'),
            accessor: 'scan_date_time',
            Cell: ({ row }) => {
                return row?.original?.scan_date_time ? row?.original?.scan_date_time : "--"
            }
        },
    ];



    const renderRowSubComponent = React.useCallback(({ row }) => {
        const newGuestData = row.original?.scan_device_list
        return (
            <div className="bg-img card nested-table-body overflow-hidden" >
                <ReactTable
                    columns={subColumns}
                    data={newGuestData ? newGuestData : []}
                    headerClass="btn-primary"
                    innerTableSearch="innertable"
                    hedingName="nestedtable.tableheading"
                    showHeader={false}
                    ispaginationvisible={true}

                />
            </div>
        )
    }, [])

    return (
        <div className="row g-3 mb-3">
            {/* <div className=""> */}
            <div className="card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-9 data-table" style={{ height: "fit-content" }}>
                <div className="card-header">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-4 heading-text">
                            <h3 className="heading-color fw-600 mb-0">
                                {t('qrcodereport.heading')}
                            </h3>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-8 table-btn" >
                            <button
                                className="btn btn-primary btn-sm float-end"
                                onClick={() => downloadPDF()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline">
                                    {t('qrcodereport.exportpdfbtn')}
                                </span>
                            </button>

                            <button
                                className="btn mx-1 btn-primary btn-sm float-end"
                                onClick={() => downloadCSV()}
                            >
                                <OpenInNewSharpIcon />
                                {/* <FontAwesomeIcon icon="plus" /> */}
                                <span className="ms-1 d-sm-inline">
                                    {t('qrcodereport.exportcsvbtn')}
                                </span>
                            </button>
                        </div>

                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <ReactTable
                        columns={columns}
                        data={qrdata}
                        pageSizeOption={[5, 10, 15, 20]}
                        headerClass="btn-primary"
                        isSearchBoxVisible
                        RenderRowSubComponent={
                            renderRowSubComponent
                        }
                        ispaginationvisible={true}
                    />
                </div>
            </div>


            <div className='card overflow-hidden mb-3 col-sm-12 col-md-12 col-lg-2 filter-table' style={{ padding: "0rem .8rem 0rem .8rem", height: "fit-content" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row' style={{ backgroundColor: "rgb(247, 247, 247)", paddingTop: ".8rem", height: "3rem", color: "black", marginBottom: '1rem' }}>
                        <span>{t('qrcodereport.filterheading')}</span>
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('qrcodereport.filterform.idtype')}
                        </label>
                        <Controller
                            name="document_type_id"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={docOption}
                                    placeholder={t('qrcodereport.placeholder.idtype')}
                                />
                            )}
                        />

                    </div>

                    <div className="form-group col-12 mb-3">
                        <label className="form-label">
                            {t('qrcodereport.filterform.idno')}
                            {/* <span className="text-danger ms-1">*</span> */}
                        </label>
                        <input
                            type='text'
                            className="form-control"
                            placeholder={t('qrcodereport.placeholder.idno')}
                            {...register('doc_id_number')}
                        />
                    </div>

                    <div className="form-group col-12 mb-3">
                        <label className="form-label">
                            {t('qrcodereport.filterform.qrcode')}
                            {/* <span className="text-danger ms-1">*</span> */}
                        </label>
                        <input
                            type='text'
                            className="form-control"
                            placeholder={t('qrcodereport.placeholder.qrcode')}
                            {...register('qr_code')}
                        />
                    </div>

                    {/* <div className="form-group col-12 mb-3">
                        <label>
                            {t('eventsreport.filterform.eventlocation')}
                        </label>
                        <Controller
                            name="location_id"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <SearchableDropdown
                                    value={value}
                                    onChange={onChange}
                                    options={eventOption}
                                    hasError={errors.event_location}
                                />
                            )}
                        />

                    </div> */}

                    <div className="form-group col-12 mb-3">
                        <label>
                            {t('qrcodereport.filterform.daterange')}
                        </label>
                        <Controller
                            name="date_range"
                            control={control}

                            render={({ field: { value, onChange } }) => (
                                <>
                                    <ReactDatePicker
                                        selected={startDate}
                                        value={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        placeholder={t('summaryreport.placeholder.startdate')}
                                        dateFormat="dd/MM/yyyy"
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        maxDate={endDate || undefined}
                                    />

                                    <br />
                                    <ReactDatePicker
                                        selected={endDate}
                                        value={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        placeholder={t('summaryreport.placeholder.enddate')}
                                        dateFormat="dd/MM/yyyy"
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate || undefined}
                                    />
                                </>
                            )}
                        />

                    </div>

                    <div className="text-start col-12">
                        <div className="mb-3 mt-4 text-start">
                            <button type="submit" className="col-lg-12 btn btn-primary me-1">
                                {t('qrcodereport.filterheading')}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {/* </div> */}
        </div>
    )
}

export default QrCodeCounterReport