import { dashboardEndPoints } from "./config"
import httpServices from "./httpServices"

export const todaysummarydashboardlist = async () => {
    return await httpServices.get(dashboardEndPoints.listdashboardtodaysummary)
}
export const listyeardashboard = async () => {
    return await httpServices.get(dashboardEndPoints.listdashboardyear)
}
export const listmonthdashboard = async () => {
    return await httpServices.get(dashboardEndPoints.listdashboardmonth)
}
export const guestsummarydashboardlist = async (data) => {
    return await httpServices.post(dashboardEndPoints.listdashboardguestsummary, data)
}
export const visitorsummarydashboardlist = async (data) => {
    return await httpServices.post(dashboardEndPoints.listdashboardvisitorsummary, data)
}
export const eventsdashboardlist = async (data) => {
    return await httpServices.post(dashboardEndPoints.listeventdashboard, data)
}
export const weeklyoverviewdashboardlist = async (data) => {
    return await httpServices.get(dashboardEndPoints.listdashboardweeklyoverview, data)
}
export const locationdashboardlist = async () => {
    return await httpServices.get(dashboardEndPoints.listdashboardlocation)
}
export const departmentdashboardlist = async () => {
    return await httpServices.get(dashboardEndPoints.listdashboarddepartment)
}