import React, { useEffect, useState, useContext } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, rgbaColor } from 'helpers/utils';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { visitorsummarydashboardlist, listyeardashboard } from 'services/dashboardService'
import { loaderContext } from 'context/Context';




import { LineChart } from 'echarts/charts';

import * as echarts from 'echarts/core';

import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';

import { CanvasRenderer } from 'echarts/renderers';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { errorMessage } from 'components/common/helper-function/catchErrorMessage';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const tooltipFormatter = params => {
  return `
<div>
    <h6 class="fs--1 text-700 mb-0">
    <span class="dot me-1 d-inline-block" style="background-color:${params[0].borderColor ? params[0].borderColor : params[0].color
    }"></span>
      ${params[0].name} : ${params[0].value}
    </h6>
</div>
`;
};





const ChartCode = function ChartOptions({ visitordata, chartRef }) {
  console.log("visitordata", visitordata)

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const data = visitordata?.map((visitor) => visitor.count)

  console.log("data", data)

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: getColor('dark'),
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'category',
      data: months,
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: getColor('300')
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('400'),
        formatter: value => value.substring(0, 3),
        margin: 15
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: getColor('200')
        }
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: getColor('400'),
        margin: 15,
      },
      axisTick: { show: false },
      axisLine: { show: false },
      min: 10,
    },

    series: [
      {
        type: 'line',
        data,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('primary'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('primary')
        },
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 10,
        smooth: false,
        emphasis: {
          scale: true
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: rgbaColor(getColor('primary'), 0.5)
              },
              {
                offset: 1,
                color: rgbaColor(getColor('primary'), 0)
              }
            ]
          }
        }
      }
    ],
    grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
  });
  return (
    <ReactEChartsCore
      echarts={echarts}
      ref={chartRef}
      option={getOption()}
      style={{ height: '18.75rem' }}
    />
  );
}
  ;
const BasicAreaLineChart1 = ({ chartRef }) => {
  const [yearOption, setYearOption] = useState([]);
  const currentYear = new Date().getFullYear();

  const [originaldata, setOriginalData] = useState([])

  const [selectedyear, setSelectedYear] = useState({ label: currentYear.toString(), value: currentYear.toString() });
  const { isLoading, setIsLoading } = useContext(loaderContext)





  useEffect(async () => {
    setIsLoading(true)
    try {
      const { data } = await listyeardashboard()
      const { data: visitor } = await visitorsummarydashboardlist({ year: selectedyear?.value })
      setYearOption(data?.data[0]?.year?.map((ye) => ({ label: ye, value: ye })))
      setOriginalData(visitor?.data[0]?.visitor)
    } catch (error) {
      errorMessage(error)
    }
    setIsLoading(false)
  }, [])

  const handleYear = async (selectedOption) => {
    console.log("selectedoption", selectedOption)
    setIsLoading(true)
    try {
      setSelectedYear(selectedOption)
      const { data } = await visitorsummarydashboardlist({ year: selectedOption?.value })
      setOriginalData(data?.data[0]?.visitor)
    } catch (error) {
      errorMessage(error)
    }
    setIsLoading(false)
  }
  return (
    <FalconComponentCard>
      {/* <FalconComponentCard.Header title="Basic area line chart" light={false} /> */}
      <div className="bg-light py-2">
        <Row className="g-0 justify-content-end px-1">

          <Col xs="auto">
            <span>year</span>

            <SearchableDropdown
              value={selectedyear}
              options={yearOption.length > 0 ? yearOption : []}
              onChange={(selectedOption) => handleYear(selectedOption)}
            // placeholder={t('suppliercontractor.createsupplierplaceholder.selectcompanytype')}
            />
            {/* <Form.Select size="sm">
              <option>2020</option>
              <option>2021</option>
              <option>2022</option>
              <option>2023</option>
            </Form.Select> */}
          </Col>
          {/* <Col xs="auto">
            <FalconLink title="Acquisition overview" className="px-0 fw-medium" />
          </Col> */}
        </Row>
      </div>
      <FalconComponentCard.Body
        // code={chartCode}
        ref={chartRef}
        children={<ChartCode visitordata={originaldata} chartRef={chartRef} />}
        language="jsx"
        scope={{
          ReactEChartsCore,
          echarts,
          getColor,
          tooltipFormatter,
          rgbaColor
        }}
      />
    </FalconComponentCard>
  );
};

export default BasicAreaLineChart1;
