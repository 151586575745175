import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams, useRoutes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import toastConfig from 'components/configuration/toast-config';
import pattern from 'components/configuration/form-pattern';
import { verifyOTP } from 'services/authenticationService';
import { loaderContext } from 'context/Context';

function SendOtpForm({ hasLabel }) {
  const { t } = useTranslation();
  const { isLoading, setIsLoading } = useContext(loaderContext)
  const location = useLocation();
  const {
    handleSubmit,
    formState: { errors },
    register
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async formData => {
    setIsLoading(true)
    try {
      formData.email = location?.state?.email;
      const { data } = await verifyOTP(formData);
      toast.success(data?.message, toastConfig)
      navigate('/reset-password', { replace: true, state: { email: location?.state?.email, otp: formData?.otp } });
      console.log("Form Data", formData)
    } catch (e) {
      if (e.response.data) {
        if (Array.isArray(e.response.data.message)) {
          e.response.data.message.map(errors => toast.error(errors, toastConfig))
        } else {
          toast.error(e.response.data.message, toastConfig)
        }
      } else {
        toast.error(e.message, toastConfig)
      }
    }
    setIsLoading(false)
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="form-group mb-3">
        {hasLabel && (
          <div className="form-label">{t('verifyOtpFormLabel.otpLabel')}</div>
        )}
        <input
          className={`form-control ${errors.otp && 'is-invalid'}`}
          placeholder={
            !hasLabel ? t('verifyOtpFormPlaceholder.otpPlaceholder') : ''
          }
          type="text"
          {...register('otp', { required: true, pattern: pattern.otp })}
        />
        {errors.otp && (
          <small className="text-danger text-align-start">
            {errors.otp.type === 'required' && (
              <span>{t('verifyOtpFormError.otpRequired')}</span>
            )}
            {errors.otp.type === 'pattern' && (
              <span>{t('verifyOtpFormError.otpPattern')}</span>
            )}
          </small>
        )}
      </div>

      <div className="mb-3 form-group">
        <Button className="w-100 btn-lg" type="submit">
          {t('verifyOtpFormLabel.verifyOtpLabel')}
        </Button>
      </div>
    </form>
  );
}
SendOtpForm.propTypes = {
  hasLabel: PropTypes.bool
};

SendOtpForm.defaultProps = {
  hasLabel: false
};
export default SendOtpForm;
